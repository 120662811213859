import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountyData } from "../../../components/CountyData";
import {
  getExpressionDemandData,
  setPagination,
} from "../../../features/expression_demand/expressionDemandSlice";

import SearchField from "../../../components/search_field/SearchField";

import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { PositionData, filterDataComparisons, filterDataConnections } from "../../../PositionData";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Input } from "@mui/joy";

const FilterSection = ({ start, limit }) => {
  const { filterParam, pagination } = useSelector(
    (state) => state.expressionDemandData
  );
  const dispatch = useDispatch();
  const [activeConn, setActiveConn] = useState(
    "projIndividualActiveConnGreater"
  );
  const [loanAmount, setLoanAmount] = useState("projTotalSumGreater");

  const [scores, setScores] = useState("sctTotalScoreGreater");

  const [formData, setFormData] = useState({
    projIndividualActiveConnGreater: "",
    projIndividualActiveConnLess: "",
    projTotalSumGreater: "",
    projTotalSumLess: "",
    projCounty: "",
    sctTotalScoreLess: "",
    sctTotalScoreGreater: "",
  });
  const { projCounty } = formData;



  const handleChangeSelect = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const paramHandler = () => {
    if (filterParam === "complete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
      }));
    } else if (filterParam === "incomplete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "ACTIVE",
      }));
    } else if (filterParam === "Viable") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableGreater: 90,
      }));
    } else if (filterParam === "Assistance") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 90,
        sctViableGreater: 70,
      }));
    } else if (filterParam === "poor") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 70,
      }));
    }
  };

  useEffect(() => {
    paramHandler();
  }, []);

  useEffect(() => {
    clearDataHandler();
  }, []);



  const clearDataHandler = () => {

    setFormData({
      projIndividualActiveConnGreater: "",
      projIndividualActiveConnLess: "",
      projTotalSumGreater: "",
      projTotalSumLess: "",
      projCounty: "",
      sctTotalScoreLess: "",
      sctTotalScoreGreater: "",
    });

    if (filterParam === "complete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
      }));
    } else if (filterParam === "incomplete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "ACTIVE",
      }));
    } else if (filterParam === "Viable") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableGreater: 90,
      }));
    } else if (filterParam === "Assistance") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 90,
        sctViableGreater: 70,
      }));
    } else if (filterParam === "poor") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 70,
      }));
    }

  };
  const handleSelector = (event) => {
    console.log("reached-------------")
    const { value, name } = event.target;
    console.log("name---------", name)
    console.log("value---------", value)
    if (name === "activeConnections") {
      setActiveConn(value);
    } else if (name === "totalScores") {
      setScores(value);
    } else {
      setLoanAmount(value);
    }
  };


  const handleSubmit = useCallback(() => {
    const filteredObj = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );

    dispatch(getExpressionDemandData(filteredObj)); // wait for data to load
  }, [dispatch, formData]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (name === "projCounty") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        if (name === "connections") {
          if (activeConn === "projIndividualActiveConnGreater") {
            const { projIndividualActiveConnLess: _, ...remainingValue } =
              formData;
            setFormData({ ...remainingValue, [activeConn]: value });
          } else if (activeConn === "projIndividualActiveConnLess") {
            const { projIndividualActiveConnGreater: _, ...remainingValue } =
              formData;
            setFormData({ ...remainingValue, [activeConn]: value });
          }
        } else if (name === "loans") {
          if (loanAmount === "projTotalSumGreater") {
            const { projTotalSumLess: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [loanAmount]: value });
          } else if (loanAmount === "projTotalSumLess") {
            const { projTotalSumGreater: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [loanAmount]: value });
          }
        } else if (name === "scores") {
          if (scores === "sctTotalScoreGreater") {
            const { sctTotalScoreLess: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [scores]: value });
          } else if (scores === "sctTotalScoreLess") {
            const { sctTotalScoreGreater: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [scores]: value });
          }
        }
      }
    },
    [activeConn, formData, loanAmount, scores]
  );

  useEffect(() => {
    handleSubmit();
  }, [
    formData.projIndividualActiveConnGreater,
    formData.projIndividualActiveConnLess,
    formData.sctTotalScoreGreater,
    formData.sctTotalScoreLess,
    formData.projCounty,
    formData.projTotalSumGreater,
    formData.projTotalSumLess,
  ]);


  return (

    <Box sx={{ display: "flex", flexDirection: "column", fontSize: "12px" }}>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pr: 1,
          mb: 2,
          fontWeight: "bold",
        }}
      >
        Filters
        <Button
          onClick={clearDataHandler}
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
        >
          Clear FiltershandleSelector
        </Button>
      </Typography>
      <Divider sx={{ mb: 2 }} />

      <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>

        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>Where</Typography>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            value="Total scores"
            placeholder="Enter value"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            className='fmw-select-search'
            name="totalScores"
            onChange={(e, newval) => {
              handleSelector({
                target: {
                  value: newval,
                  name: 'totalScores',
                },
              });
            }}
            size="lg"
            placeholder="Select comparison"
            indicator={<KeyboardArrowDown />}
            sx={{
              width: '100%',
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }}
          >
            <Option value="sctTotalScoreGreater">Greater than</Option>
            <Option value="sctTotalScoreLess">Less than</Option>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Input
            size="lg"
            className="fmw-input-joy-search"
            name="scores"
            type="number"
            value={formData.sctTotalScoreGreater || formData.sctTotalScoreLess}
            onChange={handleChange}
            placeholder="Enter value"
          />
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>Where</Typography>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            value="Active Connections"
            placeholder="Enter value"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="activeConnections"
            size="lg"
            onChange={(e, newval) => {
              handleSelector({
                target: {
                  value: newval,
                  name: 'activeConnections',
                },
              });
            }}
            placeholder="Select a active connection"
            indicator={<KeyboardArrowDown />}
            className='fmw-select-search'
            sx={{
              width: '100%',
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }}
          >
            <Option value="projIndividualActiveConnGreater">Greater than</Option>
            <Option value="projIndividualActiveConnLess">Less than</Option>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            name="connections"
            type="number"
            value={
              formData.projIndividualActiveConnGreater ||
              formData.projIndividualActiveConnLess
            }
            onChange={handleChange}
            placeholder="Enter value"
          />
        </Grid>


        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>Where</Typography>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            value="Loan amount"
            placeholder="Enter value"
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            name="loanAmount"
            size="lg"
            onChange={(e, newval) => {
              handleSelector({
                target: {
                  value: newval,
                  name: 'loanAmount',
                },
              });
            }}
            placeholder="Select loan amount"
            indicator={<KeyboardArrowDown />}
            className='fmw-select-search'
            sx={{
              width: '100%',
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }}
          >
            <Option value="projTotalSumGreater">Greater than</Option>
            <Option value="projTotalSumLess">Less than</Option>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            name="loans"
            type="number"
            value={formData.projTotalSumGreater || formData.projTotalSumLess}
            onChange={handleChange}
            placeholder="Enter value"

          />
        </Grid>

        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }}>Where</Typography>
          <Input
            fullWidth={true}
            size="lg"
            className="fmw-input-joy-search"
            value="Region"
            placeholder="Enter value"
          />
        </Grid>
        <Grid item xs={6}>

          <Select
            name="projCounty"
            size="lg"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: 'projCounty',
                },
              });
            }}
            placeholder="Select loan amount"
            indicator={<KeyboardArrowDown />}
            className='fmw-select-search'
            sx={{
              width: '100%',
              [`& .${selectClasses.indicator}`]: {
                transition: '0.2s',
                [`&.${selectClasses.expanded}`]: {
                  transform: 'rotate(-180deg)',
                },
              },
            }}
          >
            {CountyData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterSection;
