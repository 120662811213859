import React from 'react'
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { KeyboardArrowDown } from '@mui/icons-material';
import { formatImgPath, handleOpenDoc } from '../../../utils';


const QuotationButton = () => {

    const handleChangeSelect = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setAssigned(true);
        setIsUpdating(true)
        // handleSaveAssignee();
    };


    const getSelectBorder = () => {
        let borderColor = '1px dashed var(--color-technical) !important';
        // if (projAdequacy !== '') {
        //     borderColor = '1px solid var(--color-technical) !important';
        // }
        return `${borderColor}`;
    };

    const getSelectBackground = () => {
        let backgroundColor = '1px dashed var(--color-technical) !important';
        // if (projAdequacy !== '') {
        //     backgroundColor = '#E8FAFF !important';
        // }
        return `${backgroundColor}`;
    };

    const options = ["Technical.pdf", "Pipes Quotation.pdf", "labour Quotation.pdf"];
    return (
        <>

            <Select
                onChange={(e) => {
                    handleOpenDoc(e.target.value)
                    console.log(e.target.value, "=============================CHANGES=======================================================")


                }}

                name='projAdequacy'
                placeholder="View File"
                // startDecorator={!projAdequacy ? <PersonAdd sx={{ color: 'var(--color-technical)' }} /> : ''}
                slotProps={{
                    listbox: {
                        sx: {
                            '--ListItemDecorator-size': '44px',
                        },
                    },
                }}
                sx={{
                    '--ListItemDecorator-size': '44px',
                    // minWidth: 24,
                    width: 200,
                    borderRadius: '25px',
                    border: getSelectBorder(),
                    backgroundColor: getSelectBackground(),
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
                indicator={<KeyboardArrowDown />}
            // renderValue={renderValue}
            >
                <Option value={"./myimages/OTP_IMAGES/FUND_MY_WATER/1689592947205_Test.pdf"}>Technical.pdf</Option>
                <Option value={"./myimages/OTP_IMAGES/FUND_MY_WATER/1689592947205_sample.pdf"}>Pipes Quotation.pdf</Option>
                <Option value={"./myimages/OTP_IMAGES/FUND_MY_WATER/1689592947205_sample2.pdf"}>labour Quotation.pdf</Option>
            </Select>

        </>
    )
}

export default QuotationButton