import React, { useEffect, useState } from 'react';
import { FormHelperText } from '@mui/joy';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Grid,
	MenuItem,
	Paper,
	TextField,
} from '@mui/material';
import { CloudUpload, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Checkbox, Link, Typography, Button } from '@mui/joy';
import './signin.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { login, reset } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import VerifyLogin from '../verify_login/VerifyLogin';
import VerifyButton from '../../components/button/VerifyButton';
import axios from 'axios';
import { toSentenceCase } from '../../utils';
import ShowPassword from './ShowPassword';

const url = process.env.REACT_APP_API_BASE_URL;

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& .MuiFilledInput-root': {
		// border: "1px solid var(--color-primary-light)",
		overflow: 'hidden',
		borderRadius: 0,
		color: '#646464',
		fontSize: '15px !important',
		backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			// borderColor: theme.palette.primary.main,
			//   borderLeft: "3px solid var(--color-info)",
		},
	},
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		maxWidth: 'md',
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;
	return (
		<DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
			<IconButton
				aria-label='close'
				onClick={onClose}
				sx={{ position: 'absolute', left: 22, top: 15, color: '#2C2C2C' }}
			>
				<ArrowBackIcon />
			</IconButton>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 15,
						top: 10,
						color: (theme) => theme.palette.grey[500],
						display: 'none',
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const Signin = ({ open, handleClickOpen, handleClose,hasForgot ,sethasForgot , handleForgotChange, setOpenSignin }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [timer, setTimer] = useState(false);
	// const [hasForgot, sethasForgot] = useState(false);
	const [forgotValue, setforgotValue] = useState('');
	const [codeSentMessage, setcodeSentMessage] = useState('');
	const [showOtpForm, setshowOtpForm] = useState(false);
	const [OtpClose, setOtpClose] = useState(false);
	const [usrOtp, setusrOtp] = useState('');
	const [counter, setCounter] = useState(0);
	const [loadingResend, setLoadingResend] = useState(false);
	const [otpOpen, setotpOpen] = useState(false);
	const [showChangeForm, setshowChangeForm] = useState(false);
	const [userId, setuserId] = useState(null);
	const [usrEncryptedPassword2, setusrEncryptedPassword2] = useState('');
	const [passError, setpassError] = useState('');
	const [accountError, setaccountError] = useState({
		emailError: false,
		passError: false,
		emailMsg: '',
		passMsg: '',
		genMsg: '',
	});

	var format = /[@]/;

	const { user, isLoginProcessing, isLoginError, isLoginSuccess, message } =
		useSelector((state) => state.auth);
	const [formData, setFormData] = useState({
		usrUsername: '',
		usrEncryptedPassword: '',
	});

	const { usrUsername, usrEncryptedPassword } = formData;

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	// function handleForgotChange() {
	// 	sethasForgot(!hasForgot);
	// }

	const [openVerifySignin, setOpenVerifySignin] = React.useState(false);

	const handleClickOpenVerifySignin = () => {
		setOpenVerifySignin(true);
		setTimeout(() => {
			setTimer(true);
		}, 60000);
	};
	const handleCloseVerifySignin = () => {
		setOpenVerifySignin(false);
	};

	const signIn = async (e) => {
		e.preventDefault();
		if (!usrUsername || !usrEncryptedPassword) {
			setaccountError({
				passError: true,
				emailError: true,
				genMsg: 'Please fill in your credentials',
			});
		} else {
			const res = await dispatch(
				login({
					usrUsername: usrUsername,
					usrEncryptedPassword: usrEncryptedPassword,
				}),
			);
			console.log(res,"*****************************RESPONSE**************************************");
			
			if (res?.payload == 'FAILED LOGIN NO RECORD FOUND') {
				await setaccountError({
					passError: true,
					emailError: true,
					genMsg:
						'This email does not match our records. Please try again or register',
				});
			} else if (res?.payload == ' FAILED LOGIN WRONG PASSWORD') {
				console.log(res?.payload);
				await setaccountError({
					passError: true,
					emailError: true,
					genMsg:
						"Email and password do not match. Try again or click 'Forgot password'",
				});
			} else if (res?.payload?.usrId){
				if (res?.payload?.usrJbrnId == 0) {
					navigate("/support-project");
				} else if (res?.payload?.usrJbrnId == 1) {
					navigate("/wsp-home");
				}
				else if (res?.payload?.usrJbrnId == 3) {
					window.location.href = `https://impactfunder.fundmywater.com/#/impact-funder-account/${user?.usrId}/${user?.usrAccId}`
					// navigate("/impact-funder-account");
				}
				else {
					navigate("/expression-demand/complete");
				}
			}
			else {
				await setaccountError({
					passError: false,
					emailError: false,
					genMsg: '',
				});
			}
		}
	};

	function hideEmail(target) {
		var email = target;
		var hiddenEmail = '';
		for (let i = 0; i < email.length; i++) {
			if (i > 2 && i < email.indexOf('@')) {
				hiddenEmail += '*';
			} else {
				hiddenEmail += email[i];
			}
		}
		return hiddenEmail;
	}

	const sendForgotOtp = async (e) => {
		e.preventDefault();
		if (!forgotValue) {
			setaccountError({
				passError: false,
				emailError: false,
				emailMsg: 'Please fill in your email address',
			});
		} else {
			setaccountError({
				passError: false,
				emailError: false,
				emailMsg: '',
			});

			fetch(`${url}/usr/reset_password.action?phone=${forgotValue}`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.success) {
						setshowOtpForm(true);
						setotpOpen(true);
						setCounter(60);
					} else {
						setaccountError({
							passError: false,
							emailError: false,
							emailMsg: 'Account does not exist. Please try again.',
						});
						setshowOtpForm(false);
						setotpOpen(false);
					}
				});
		}
	};

	function verifyChangePwdOtp(e) {
		e.preventDefault();
		setLoadingResend(true);
		fetch(`${url}/usr/authenticate_otp.action`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				usrUsername: forgotValue,
				usrEncryptedPassword: usrOtp,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				setLoadingResend(false);
				if (data.success) {
					setshowChangeForm(true);
					setusrOtp('');
					setuserId(data?.jsonData?.usrId);
					toast.success('Verification successfull!');
				} else {
					setshowChangeForm(false);
					toast.error('The code you entered is not valid!');
				}
			});
	}

	function handleOtpClose() {
		setotpOpen(false);
	}

	const resend = () => {
		setLoadingResend(true);
		fetch(`${url}/usr/reset_password.action?phone=${forgotValue}`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setLoadingResend(false);
					toast.success(`We have resent the code. Check your device again.`);
					setCounter(60);
				} else {
					setLoadingResend(false);
					toast.error('Error! Could not resend code. Try agin later.');
				}
			});
	};

	function handleChangeNewPassword(e) {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		// let numbers = /[0-9]/g;
		let numbers = /\d/g;

		e.preventDefault();
		if (!usrEncryptedPassword2) {
			setpassError('Please confirm your new password');
			return false;
		} else if (usrEncryptedPassword?.length < 6) {
			return false;
		} else if (usrEncryptedPassword !== usrEncryptedPassword2) {
			return false;
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			return false;
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			return false;
		} else if (!usrEncryptedPassword?.test(numbers)) {
			return false;
		} else {
			setpassError('');
			setLoadingResend(true);
			fetch(`${url}/usr/updateUser.action`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					usrId: userId,
					usrEncryptedPassword: usrEncryptedPassword,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					toast.success(`New password has been set. Log in to continue.`);
					usrEncryptedPassword = '';
					setusrEncryptedPassword2('');
					setLoadingResend(false);
					setshowChangeForm(false);
					setshowOtpForm(false);
					sethasForgot(false);
					setotpOpen(false);
				});
		}
	}

	async function handlePassResetValidation() {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		let numbers = /[0-9]/g;

		if (!usrEncryptedPassword) {
			setpassError('');
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			await setpassError(
				'Password must contain atleast a lowercase character!',
			);
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			await setpassError(
				'Password must contain atleast an UPPERCASE character!',
			);
		} else if (!usrEncryptedPassword?.match(numbers)) {
			await setpassError('Password must contain atleast a number!');
		} else if (usrEncryptedPassword?.length < 6) {
			await setpassError('Passwords must be atleast 6 characters long!');
		} else if (
			usrEncryptedPassword2 &&
			usrEncryptedPassword !== usrEncryptedPassword2
		) {
			await setpassError('Password does not match');
		} else {
			setpassError('');
		}
	}

	function closeAllForms() {
		setLoadingResend(false);
		setshowChangeForm(false);
		setshowOtpForm(false);
		sethasForgot(false);
		setotpOpen(false);
		setforgotValue('');
	}

	const [show, setShow] = useState(false)
	const handleShowPassowrd = () => {
		setShow(!show)
	}

	useEffect(() => {
		if (isLoginSuccess) {
			handleClickOpenVerifySignin();
			toast.success({ toastId: 'verify' });
		}

		if (codeSentMessage) {
			toast.error(toSentenceCase(codeSentMessage));
		}

		dispatch(reset());
	}, [
		user,
		isLoginSuccess,
		isLoginError,
		message,
		open,
		dispatch,
		codeSentMessage,
	]);

	useEffect(() => { }, [hasForgot]);

	useEffect(() => {
		if (counter > 0) {
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [counter]);

	useEffect(() => {
		handlePassResetValidation();
	}, [usrEncryptedPassword, usrEncryptedPassword2, passError]);

	useEffect(() => { }, [userId]);

	useEffect(() => {
		if (usrUsername || usrEncryptedPassword || forgotValue) {
			setaccountError({
				passError: false,
				emailError: false,
				genMsg: '',
				emailMsg: '',
			});
		}
	}, [usrUsername, usrEncryptedPassword, forgotValue]);

	return (
		<>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				fullWidth={true}
				maxWidth='xs'
			>
				<BootstrapDialogTitle
					id='customized-dialog-title'
					onClose={handleClose}
				/>

				<DialogContent sx={{ mx: 5, my: 3 }} className='signin-dialog'>
					<Typography component='h4' className='meta-title signin-title'>
						{hasForgot ? 'Forgot Password' : 'Log in'}
					</Typography>

					<Typography component='body2' className='highlighter signin-h' />

					{hasForgot ? (
						<Typography component='p' className='forgot-tagline'>
							Enter your email to proceed.
						</Typography>
					) : (
						<Typography component='p' className='signin-tagline'>
							Welcome back, please log in to your account
						</Typography>
					)}

					{hasForgot ? (
						<form onSubmit={sendForgotOtp}>
							{accountError?.emailMsg ? (
								<p style={{ color: 'red' }}>{accountError?.emailMsg}</p>
							) : null}
							<RedditTextField
								name='usrEmail'
								value={forgotValue}
								onChange={(e) => setforgotValue(e.target.value)}
								label='Email'
								placeholder='Enter email address'
								type='text'
								id='reddit-input-2'
								variant='filled'
								style={{ marginTop: 11 }}
								fullWidth
								className='reddit-input'
								autoFocus
							/>

							<Grid container spacing={3} sx={{ mt: 1 }}>
								<Grid item xs={5}>
									<Link 
									onClick={()=>{setOpenSignin(!open);sethasForgot(false)}}
										// onClick={handleForgotChange}
										color='primary'
										underline='none'
										className='fmw-link'
										sx={{ fontSize: '15px', textAlign: 'right' }}
									>
										<ArrowBackIcon /> Back to Login
									</Link>
								</Grid>
							</Grid>

							<Grid container spacing={3} sx={{ mt: 0.75 }}>
								<Grid item xs={12}>
									{!loadingResend ? (
										<Button
											className='sign-btn'
											type='submit'
										//  onClick={handleClickOpenVerifySignin}
										>
											Submit
										</Button>
									) : (
										<Button
											className='sign-btn'
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Submitting...
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					) : (
						<form onSubmit={signIn}>
							{accountError?.genMsg ? (
								<p style={{ color: 'red' }}>{accountError?.genMsg}</p>
							) : null}
							<RedditTextField
								name='usrUsername'
								value={usrUsername}
								onChange={handleChange}
								label='Email address'
								placeholder='John@doe.com'
								type='email'
								id='reddit-input-log1'
								variant='filled'
								style={{ marginTop: 11 }}
								fullWidth
								className={
									accountError?.emailError
										? 'reddit-input-error'
										: 'reddit-input'
								}
								autoFocus
							/>


							<ShowPassword show={show} handleShowPassowrd={handleShowPassowrd} />

							<RedditTextField
								name='usrEncryptedPassword'
								value={usrEncryptedPassword}
								onChange={handleChange}
								label='Password'
								placeholder=''
								type={show ? 'text' : 'password'}
								id='reddit-input-log2'
								style={{ position: 'relative', marginTop: 11 }}
								variant='filled'
								fullWidth
								className={
									accountError?.passError
										? 'reddit-input-error'
										: 'reddit-input'
								}
							/>

							<Grid container spacing={3} sx={{ mt: 1 }}>
								<Grid item xs={7}>
									<Checkbox
										color='primary'
										label='Remember me'
										size='md'
										variant='soft'
										className='fmw-checkbox-label'
									/>
								</Grid>

								<Grid item xs={5}>
									<Link
										onClick={handleForgotChange}
										color='primary'
										underline='none'
										className='fmw-link'
										sx={{ fontSize: '15px', textAlign: 'right' }}
									>
										Forgot password?
									</Link>
								</Grid>
							</Grid>

							<Grid container spacing={3} sx={{ mt: 0.75 }}>
								<Grid item xs={12}>
									{!isLoginProcessing ? (
										<Button className='sign-btn' type='submit'>
											Log in
										</Button>
									) : (
										<Button
											className='sign-btn'
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Loading...
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					)}
				</DialogContent>
				<DialogActions></DialogActions>
			</BootstrapDialog>

			<CssVarsProvider />

			{/* <VerifyLogin
				open={openVerifySignin}
				loginSucess={isLoginSuccess}
				handleClickOpen={handleClickOpenVerifySignin}
				user={user}
				isError={isLoginError}
				isVerifySuccess={isLoginSuccess}
				message={message}
				handleClose={handleCloseVerifySignin}
				timer={timer}
			/> */}

			<BootstrapDialog
				onClose={handleOtpClose}
				aria-labelledby='customized-dialog-title'
				open={otpOpen}
				fullWidth={true}
				maxWidth='sm'
			>
				<BootstrapDialogTitle
					id='customized-dialog-title'
					onClose={handleOtpClose}
				/>

				<DialogContent sx={{ mx: 5, my: 3 }} className='signin-dialog'>
					<Typography component='h4' className='meta-title signin-title'>
						{showChangeForm ? 'Change Password' : 'Verify Your Account'}
					</Typography>

					<Typography component='body2' className='highlighter signin-h' />

					<Typography component='p' className='verify-signin-tagline'>
						{showChangeForm
							? 'Please fill in your new password'
							: 'We have sent an email with instructions on how to reset your password. If you cannot find an email in your inbox, check your spam.'}
					</Typography>

					{showChangeForm ? (
						<form onSubmit={(e) => handleChangeNewPassword(e)}>
							{passError ? (
								<FormHelperText className='upload-sub-label-error'>
									{passError}
								</FormHelperText>
							) : null}
							<RedditTextField
								name='usrEncryptedPassword'
								value={usrEncryptedPassword}
								onChange={handleChange}
								label='New password'
								placeholder=''
								type='password'
								id='reddit-input-new'
								variant='filled'
								style={{ marginTop: 11 }}
								fullWidth
								className='reddit-input'
								autoFocus
							/>

							<RedditTextField
								name='usrEncryptedPassword2'
								value={usrEncryptedPassword2}
								onChange={(e) => setusrEncryptedPassword2(e.target.value)}
								label='Confirm password'
								placeholder=''
								type='password'
								id='reddit-input-conf'
								variant='filled'
								style={{ marginTop: 11 }}
								fullWidth
								className='reddit-input'
							/>

							<Grid container spacing={3} sx={{ mt: 0.75 }}>
								<Grid item xs={12}>
									{!loadingResend ? (
										<Button className='sign-btn' type='submit'>
											Change Password
										</Button>
									) : (
										<Button
											className='sign-btn'
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Loading...
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					) : (
						<form onSubmit={verifyChangePwdOtp}>
							<Typography component='p'>
								Resend email in:{' '}
								<span style={{ fontWeight: 'bold' }}>{counter}</span>
							</Typography>
							<Grid container spacing={3} style={{ marginTop: '3rem' }}>
								<Grid item xs={6}>
									{!loadingResend ? (
										<Button
											className='sign-btn'
											onClick={() => resend()}
											disabled={counter > 0}
										>
											Resend
										</Button>
									) : null}
								</Grid>
								<Grid
									item
									xs={6}
									style={{ display: 'flex', justifyContent: 'flex-end' }}
								>
									{loadingResend ? (
										<Button
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Loading...
										</Button>
									) : (
										<Button
											onClick={closeAllForms}
											className='sign-btn'
											type='button'
										>
											Close
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
					)}
				</DialogContent>
				<DialogActions></DialogActions>
			</BootstrapDialog>
		</>
	);
};

export default Signin;