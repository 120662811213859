import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import chatUserService from './chatUserService';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	loading: false,
	loadingChatHeads: false,
	uploadSuccess: false,
	uploading: false,
	error: '',
	chatArray: [],
	chats: [],
	users: [],
	currentChat: {
		chAccId: null,
		chCode: 'CH',
		chCreatedBy: null,
		chCreatedDate: null,
		chCreatedTime: null,
		chDesc: null,
		chId: null,
		chInstId: null,
		chProjId: null,
		chStatus: '',
		chUpdatedBy: null,
		chUpdatedDate: null,
		chUpdatedTime: null,
		withParty: '',
	},
};

export const fetchAllChatSuggestions = createAsyncThunk(
	'chatUserSlice/fetchAllChatSuggestions',
	(data) => {
		if (data) {
			return axios
				.get(
					`${url}/usr/fetchAccounts.action?criteria=accTradeName&txtSearch=${data}`,
				)
				.then((res) => res.data);
		}
		return null;
	},
);

export const fetchAllChatHeads = createAsyncThunk(
	'chatUserSlice/fetchAllChatHeads',
	(id) => {
		return axios
			.get(`${url}/usr/fetchChats.action?accId=${id}`)
			.then((res) => res.data);
	},
);


export const sendMessage = createAsyncThunk(
	'chatUserSlice/sendMessage',
	(data) => {
		return axios
			.post(`${url}/usr/saveMsgMessageQueue.action`, data)
			.then((res) => res.data);
	},
);

export const uploadMessageFile = createAsyncThunk(
	'chatUserSlice/uploadMessageFile',
	(data) => {
		return axios
			.post(`${url}/usr/postImages.action`, data, {
				headers: {
				  "Content-Type": "multipart/form-data",
				},
			  })
			.then((res) => res.data);
	},
);



const chatUserSlice = createSlice({
	name: 'messages',
	initialState,
	reducers: {
		reset: (state) => {
			state.chatArray = [];
			state.currentChat = {
				chAccId: null,
				chCode: 'CH',
				chCreatedBy: null,
				chCreatedDate: null,
				chCreatedTime: null,
				chDesc: null,
				chId: null,
				chInstId: null,
				chProjId: null,
				chStatus: '',
				chUpdatedBy: null,
				chUpdatedDate: null,
				chUpdatedTime: null,
				withParty: '',
			};
			state.users = [];
			state.chats = [];
		},
		setSelectedUser: (state, action) => {
			state.currentChat = { ...state.currentChat, ...action.payload};
			state.chatArray.map(chatHead => {
				if(chatHead.chId === state.currentChat.chId ){
					return chatHead.active  = true
				}else{
					return chatHead.active = false
				}
			});
		},
		handleNewChat: (state, action) => {
			state.chats = [...state.chats, action.payload];
		},
		handleChats: (state, action) => {
			state.chats = action.payload;
		},
		resetChat: state => {
			state.chats = [];
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchAllChatSuggestions.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchAllChatSuggestions.fulfilled, (state, action) => {
			state.loading = false;
			state.users = action.payload.data.result;
			state.error = '';
		});
		builder.addCase(fetchAllChatSuggestions.rejected, (state, action) => {
			state.loading = false;
			state.users = [];
			state.error = action.error.messages;
		});



		builder.addCase(fetchAllChatHeads.pending, (state) => {
			state.loadingChatHeads = true;
		});
		builder.addCase(fetchAllChatHeads.fulfilled, (state, action) => {
			state.loadingChatHeads = false;
			state.chatArray = action.payload.data.result.map(ch => ({...ch, active: false}))
			state.error = '';
		});
		builder.addCase(fetchAllChatHeads.rejected, (state, action) => {
			state.loadingChatHeads = false;
			state.chatArray = [];
			state.error = action.error.message;
		});



		builder.addCase(sendMessage.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(sendMessage.fulfilled, (state, action) => {
			state.loading = false;
			state.chats = [...state.chats, action.payload.jsonData];
			state.error = '';
		});
		builder.addCase(sendMessage.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
			state.chats = [];
		});



		builder.addCase(uploadMessageFile.pending, (state) => {
			state.uploading = true;
		});
		builder.addCase(uploadMessageFile.fulfilled, (state, action) => {
			state.uploading = false;
			if (action.payload.success) {
				state.uploadSuccess = true;
			}else{
				state.uploadSuccess = false;
			}
		});
		builder.addCase(uploadMessageFile.rejected, (state, action) => {
			state.uploading = false;
			state.uploadSuccess = false;
		});
	},
});

export default chatUserSlice.reducer;
export const { reset, setSelectedUser, handleChats, handleNewChat, resetChat } =
	chatUserSlice.actions;
