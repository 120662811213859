import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MenuItem, Select } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from 'react-router-dom';

import { Input, Typography } from '@mui/joy';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchBusinessPlan,
	saveBusinessPlan,
} from '../../../features/committed_project/committedSlice';
import FileHandler from '../../../components/custom_components/FileHandler';

export default function BusinessPlanTable({ viewOnly }) {
	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => handleBusinessPlan(data);
	let { id } = useParams();
	const { businessPlan } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(businessPlan);
	const [filename, setfilename] = useState(businessPlan?.bspPlanFile);

	async function fetchBsPlans() {
		if (id) {
			const resp = await dispatch(
				fetchBusinessPlan({
					bspProjId: id,
				}),
			);
			await setFormData(resp?.payload[0]);
			await setfilename(resp?.payload[0].bspPlanFile);
		}
	}

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			bspProjId: id,
			[e.target.name]: e.target.value,
		}));
	};

	function getFileUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			bspPlanFile: data,
		}));
	}

	async function handleBusinessPlan() {
		await dispatch(
			saveBusinessPlan({
				formData: formData,
			}),
		);
		const resp = await dispatch(
			fetchBusinessPlan({
				bspProjId: id,
			}),
		);
		await setFormData(resp?.payload[0]);
		await setfilename(resp?.payload[0].bspPlanFile);
	}

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	useEffect(() => {}, [formData, businessPlan, id, filename]);

	useEffect(() => {
		fetchBsPlans();
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TableContainer
					component={Paper}
					elevation={0}
					sx={{ background: 'inherit' }}
				>
					<Typography className='font-[500] text-[16px] pl-[16px] !mb-3'>
						Term Sheet
					</Typography>

					<Table sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableHead>
							<TableRow className='table'>
								<TableCell align='left'>Item</TableCell>
								<TableCell align='left'>Details</TableCell>
							</TableRow>
						</TableHead>
						<TableBody
							className='basic'
							sx={{
								borderBottom: '2px solid var(--color-light-blue) !important',
							}}
						>
							<TableRow>
								<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
									Loan (KES)
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '50%' }}>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspLoan'
										// {...register('bspLoan')}
										value={formData?.bspLoan}
										className='fmw-input-joy-mitigation'
									/>
									{/* <ImageInput /> */}
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
									Interest Rate (%)
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '50%' }}>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspRateAve'
										// {...register('bspRateAve')}
										value={formData?.bspRateAve}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
									Loan Term (months)
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '50%' }}>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspLoanTerm'
										// {...register('bsp_int_ave')}
										value={formData?.bspLoanTerm}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
									Attach Business Plan
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '50%' }}>
									<FileHandler
										getFileUrl={getFileUrl}
										title={threeValues?.length ? threeValues[1] : 'Attach plan'}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<TableContainer
					component={Paper}
					elevation={0}
					sx={{ background: 'inherit', my: 5, overflowY: 'hidden' }}
				>
					<Typography className='font-[500] text-[16px] pl-[16px] !mb-3'>
						Scenarios evaluated
					</Typography>

					<Table
						sx={{ minWidth: '100vw', overflowY: 'hidden' }}
						aria-label='simple table'
					>
						<TableHead>
							<TableRow className='table'>
								<TableCell align='left'>No</TableCell>
								<TableCell align='left'>1</TableCell>
								<TableCell align='left'>2</TableCell>
								<TableCell align='left'>3</TableCell>
								<TableCell align='left'>4</TableCell>
								<TableCell align='left'>5</TableCell>
							</TableRow>
						</TableHead>
						<TableBody
							className='basic'
							sx={{
								borderBottom: '2px solid var(--color-light-blue) !important',
							}}
						>
							<TableRow>
								<TableCell component='th' scope='row'>
									Scenario Description
								</TableCell>

								<TableCell
									className='!border-2 !border-[#cff0fc]'
									component='th'
									scope='row'
									sx={{ width: '10%' }}
								>
									2nd portion of Subsidy = 0
								</TableCell>

								<TableCell
									className='!border-2 !border-[#cff0fc]'
									component='th'
									scope='row'
									sx={{ width: '10%' }}
								>
									Low revenue
								</TableCell>

								<TableCell
									className='!border-2 !border-[#cff0fc]'
									component='th'
									scope='row'
									sx={{ width: '10%' }}
								>
									High interest rate
								</TableCell>

								<TableCell
									className='!border-2 !border-[#cff0fc]'
									component='th'
									scope='row'
									sx={{ width: '10%' }}
								>
									Moderate interest rate
								</TableCell>
								<TableCell
									className='!border-2 !border-[#cff0fc]'
									component='th'
									scope='row'
									sx={{ width: '10%' }}
								>
									High Inflation rate
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Item
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnItemOne'
										// {...register('bspScnItemOne')}
										value={formData?.bspScnItemOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnItemTwo'
										// {...register('bspScnItemTwo')}
										value={formData?.bspScnItemTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnItemThree'
										// {...register('bspScnItemThree')}
										value={formData?.bspScnItemThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnItemFour'
										// {...register('bspScnItemFour')}
										value={formData?.bspScnItemFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnItemFive'
										// {...register('bspScnItemFive')}
										value={formData?.bspScnItemFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Loan Sum (KES)
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnLoanSumOne'
										// {...register('bspScnLoanSumOne')}
										value={formData?.bspScnLoanSumOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnLoanSumTwo'
										// {...register('bspScnLoanSumTwo')}
										value={formData?.bspScnLoanSumTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnLoanSumThree'
										// {...register('bspScnLoanSumThree')}
										value={formData?.bspScnLoanSumThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnLoanSumFour'
										// {...register('bspScnLoanSumFour')}
										value={formData?.bspScnLoanSumFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnLoanSumFive'
										// {...register('bspScnLoanSumFive')}
										value={formData?.bspScnLoanSumFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Interest Rate (%)
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInterestRateOne'
										// {...register('bspInterestRateOne')}
										value={formData?.bspInterestRateOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInterestRateTwo'
										// {...register('bspInterestRateTwo')}
										value={formData?.bspInterestRateTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInterestRateThree'
										// {...register('bspInterestRateThree')}
										value={formData?.bspInterestRateThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInterestRateFour'
										// {...register('bspInterestRateFour')}
										value={formData?.bspInterestRateFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInterestRateFive'
										// {...register('bspInterestRateFive')}
										value={formData?.bspInterestRateFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Inflation Rate (%)
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInflationRateOne'
										// {...register('bspInflationRateOne')}
										value={formData?.bspInflationRateOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInflationRateTwo'
										// {...register('bspInflationRateTwo')}
										value={formData?.bspInflationRateTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInflationRateThree'
										// {...register('bspInflationRateThree')}
										value={formData?.bspInflationRateThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInflationRateFour'
										// {...register('bspInflationRateFour')}
										value={formData?.bspInflationRateFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspInflationRateFive'
										// {...register('bspInflationRateFive')}
										value={formData?.bspInflationRateFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Loan Term (months)
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTermSumOne'
										// {...register('bspScnTermSumOne')}
										value={formData?.bspScnTermSumOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTermSumTwo'
										// {...register('bspScnTermSumTwo')}
										value={formData?.bspScnTermSumTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTermSumThree'
										// {...register('bspScnTermSumThree')}
										value={formData?.bspScnTermSumThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTermSumFour'
										// {...register('bspScnTermSumFour')}
										value={formData?.bspScnTermSumFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTermSumFive'
										// {...register('bspScnTermSumFive')}
										value={formData?.bspScnTermSumFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row' sx={{ width: '18%' }}>
									Monthly Loan Repayment with 15% collateral savings
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnMnthRepayOne'
										// {...register('bspScnMnthRepayOne')}
										value={formData?.bspScnMnthRepayOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnMnthRepayTwo'
										// {...register('bspScnMnthRepayTwo')}
										value={formData?.bspScnMnthRepayTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnMnthRepayThree'
										// {...register('bspScnMnthRepayThree')}
										value={formData?.bspScnMnthRepayThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnMnthRepayFour'
										// {...register('bspScnMnthRepayFour')}
										value={formData?.bspScnMnthRepayFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnMnthRepayFive'
										// {...register('bspScnMnthRepayFive')}
										value={formData?.bspScnMnthRepayFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Baseline IC
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnBaselineOne'
										// {...register('bspScnBaselineOne')}
										value={formData?.bspScnBaselineOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnBaselineTwo'
										// {...register('bspScnBaselineTwo')}
										value={formData?.bspScnBaselineTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnBaselineThree'
										// {...register('bspScnBaselineThree')}
										value={formData?.bspScnBaselineThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnBaselineFour'
										// {...register('bspScnBaselineFour')}
										value={formData?.bspScnBaselineFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnBaselineFive'
										// {...register('bspScnBaselineFive')}
										value={formData?.bspScnBaselineFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Target IC
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTargetOne'
										// {...register('bspScnTargetOne')}
										value={formData?.bspScnTargetOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTargetTwo'
										// {...register('bspScnTargetTwo')}
										value={formData?.bspScnTargetTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTargetThree'
										// {...register('bspScnTargetThree')}
										value={formData?.bspScnTargetThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTargetFour'
										// {...register('bspScnTargetFour')}
										value={formData?.bspScnTargetFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTargetFive'
										// {...register('bspScnTargetFive')}
										value={formData?.bspScnTargetFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Average Tariff (Ksh/m3)
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTariffOne'
										// {...register('bspScnTariffOne')}
										value={formData?.bspScnTariffOne}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTariffTwo'
										// {...register('bspScnTariffTwo')}
										value={formData?.bspScnTariffTwo}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTariffThree'
										// {...register('bspScnTariffThree')}
										value={formData?.bspScnTariffThree}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTariffFour'
										// {...register('bspScnTariffFour')}
										value={formData?.bspScnTariffFour}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Input
										type='number'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnTariffFive'
										// {...register('bspScnTariffFive')}
										value={formData?.bspScnTariffFive}
										className='fmw-input-joy-mitigation'
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Is there a cash flow shortage?
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '15%' }}>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnCashShortOne'
										// {...register('bspScnCashShortOne')}
										value={
											formData?.bspScnCashShortOne
												? formData?.bspScnCashShortOne
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnCashShortTwo'
										// {...register('bspScnCashShortTwo')}
										value={
											formData?.bspScnCashShortTwo
												? formData?.bspScnCashShortTwo
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnCashShortThree'
										// {...register('bspScnCashShortThree')}
										value={
											formData?.bspScnCashShortThree
												? formData?.bspScnCashShortThree
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnCashShortFour'
										// {...register('bspScnCashShortFour')}
										value={
											formData?.bspScnCashShortFour
												? formData?.bspScnCashShortFour
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Select
										class='block w-[30px] text-sm text-gray-500 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnCashShortFive'
										// {...register('bspScnCashShortFive')}
										value={
											formData?.bspScnCashShortFive
												? formData?.bspScnCashShortFive
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell component='th' scope='row'>
									Is tariff affordable?
								</TableCell>

								<TableCell component='th' scope='row' sx={{ width: '15%' }}>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnAffordOne'
										// {...register('bspScnAffordOne')}
										value={
											formData?.bspScnAffordOne
												? formData?.bspScnAffordOne
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnAffordTwo'
										// {...register('bspScnAffordTwo')}
										value={
											formData?.bspScnAffordTwo
												? formData?.bspScnAffordTwo
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnAffordThree'
										// {...register('bspScnAffordThree')}
										value={
											formData?.bspScnAffordThree
												? formData?.bspScnAffordThree
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>

								<TableCell component='th' scope='row'>
									<Select
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnAffordFour'
										// {...register('bspScnAffordFour')}
										value={
											formData?.bspScnAffordFour
												? formData?.bspScnAffordFour
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>
								<TableCell component='th' scope='row'>
									<Select
										class='block w-[30px] text-sm text-gray-500 bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer'
										onChange={handleChange}
										onBlur={handleBusinessPlan}
										disabled={viewOnly}
										name='bspScnAffordFive'
										// {...register('bspScnAffordFive')}
										value={
											formData?.bspScnAffordFive
												? formData?.bspScnAffordFive
												: 'No'
										}
										sx={{
											padding: '6.5px 14px !important',
											height: '30px !important',
										}}
									>
										<MenuItem selected value={'No'}>
											No
										</MenuItem>
										<MenuItem value={'Yes'}>Yes</MenuItem>
									</Select>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				{/* 
				<div className='my-7 mx-10'>
					<button
						type='submit'
						className='btn btn-sm bg-teal-600 text-white p-2 hover:border-2'
					>
						Submit
					</button>
				</div> */}
			</form>
		</>
	);
}
