import { Typography } from '@mui/joy'
import React from 'react'

export default function TypographySub({ title, sub ,superS}) {
    return (
        <>
            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                {title}
                {
                    superS?( <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                    ({sub}) </Typography>):(null)
                }
               
            </Typography>
        </>
    )
}
