import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  MenuItem,
  FormControl,
  Select,
  Chip,
  Box,
  OutlinedInput,
} from "@mui/material";
import "./multipleselect.css";
import { ProjectPurposeData } from "../../Step3Data";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, projectPurpose, theme) {
  return {
    fontWeight:
      projectPurpose.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultipleSelectProjectPurpose = ({ projectPurpose, handleChange }) => {
  const theme = useTheme();

  return (
    <>
      <FormControl
        sx={{ width: "100%" }}
        size="small"
        className="fmw-form-control"
      >
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={projectPurpose}
          onChange={handleChange}
          MenuProps={MenuProps}
          placeholder="Select county"
          className="fmw-multiple-select"
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {ProjectPurposeData.map(({ label, value }) => (
            <MenuItem
              key={label}
              value={value}
              style={getStyles(value, projectPurpose, theme)}
              className="fmw-ms-menu-item"
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultipleSelectProjectPurpose;
