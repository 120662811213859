import { Input, Typography } from '@mui/joy';
import { Box, Divider, Grid, Menu, Paper } from '@mui/material';
import React, { useState } from 'react';
import './riskbp.css';
import { Add, DragHandle } from '@mui/icons-material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';
import { useParams } from 'react-router-dom';

function RiskBpTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);
	const [filename, setfilename] = useState(committedd?.wrBtTransitionPlanFile);

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleRiskMitigation() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	useEffect(() => {
		handleRiskMitigation();
	}, [formData]);

	useEffect(() => {
		if (committedd?.wrBtTransitionPlanFile) {
			setfilename(committedd?.wrBtTransitionPlanFile);
		}
	}, [committedd?.wrBtTransitionPlanFile]);

	useEffect(() => {}, [formData, id, filename, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);
	return (
		<>
			<Paper
				sx={{
					p: 0,
					display: 'flex',
					flexDirection: 'column',
					borderTop: 'none',
				}}
				elevation={0}
				variant='outlined'
			>
				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4} style={{}}>
							<Typography className='mitigation-title' sx={{ padding: '16px' }}>
								Risk
							</Typography>
						</Grid>
						<Grid item xs={4} style={{}}>
							<Typography
								sx={{ padding: '16px', display: 'flex', alignItems: 'center' }}
								className='mitigation-title gap-2'
							>
								<DragHandle sx={{}} />
								<span>Likelihood</span>
							</Typography>
						</Grid>
						<Grid item xs={4} style={{}}>
							<Typography
								sx={{ padding: '16px', display: 'flex', alignItems: 'center' }}
								className='mitigation-title gap-2'
							>
								<DragHandle sx={{}} />
								<span>Mitigation measure</span>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>

				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk1'
									value={formData?.wrRisk1}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk1Likelyhood'
									value={formData?.wrRisk1Likelyhood}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk1Mitigation'
									value={formData?.wrRisk1Mitigation}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>

				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk2'
									value={formData?.wrRisk2}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk2Likelyhood'
									value={formData?.wrRisk2Likelyhood}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk2Mitigation'
									value={formData?.wrRisk2Mitigation}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>
				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk3'
									value={formData?.wrRisk3}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk3Likelyhood'
									value={formData?.wrRisk3Likelyhood}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk3Mitigation'
									value={formData?.wrRisk3Mitigation}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>

				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk4'
									value={formData?.wrRisk4}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk4Likelyhood'
									value={formData?.wrRisk4Likelyhood}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk4Mitigation'
									value={formData?.wrRisk4Mitigation}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>

				<Box>
					<Grid container spacing={4}>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk5'
									value={formData?.wrRisk5}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk5Likelyhood'
									value={formData?.wrRisk5Likelyhood}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography
								className='typo-paragraph'
								sx={{
									padding: '8px',
									height: '100%',
									borderRight: '1px solid var(--color-border-table)',
								}}
							>
								<Input
									disabled={viewOnly}
									onChange={handleChange}
									name='wrRisk5Mitigation'
									value={formData?.wrRisk5Mitigation}
									className='fmw-input-joy-mitigation'
								/>
							</Typography>
						</Grid>
					</Grid>
					<Divider sx={{ borderColor: 'var(--color-border-table)' }} />
				</Box>
			</Paper>
		</>
	);
}

export default RiskBpTable;
