import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPdfInteractions,
  getToolTipData,
  setOpenPDfDialog,
  setPdfData,
} from "../../../features/expression_demand/expressionDemandSlice";
import { useEffect } from "react";
import ToolTipTableCell from "./ToolTipTableCell";
import PdfModal from "./pdfAnnotations/PdfModal";
import { Input } from "@mui/joy";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NoTableDataComponent from "../tables/NoTableDataComponent";

const AddFilesTable = ({ dataId, status }) => {
  const { loading, toolTipData } = useSelector(
    (state) => state.expressionDemandData
  );

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [projData, setProjData] = useState([]);
  const [tableEntries, settableEntries] = useState([]);
  const [fileName, setfileName] = useState("");
  const [hasFileName, sethasFileName] = useState(false);

  const callToolTipData = () => {
    dispatch(getToolTipData(dataId));
  };

  // function handleTableEntriesChange(e, index) {
  // 	let { name, value } = e.target;
  // 	const inputList = [...tableEntries];
  // 	inputList[index][name] = value;
  // 	settableEntries([inputList]);
  // }

  function handleNewTableRow() {
		if (!fileName) {
			sethasFileName(false);
			toast.info('File name is required');
		} else {
			sethasFileName(true);
		}
	}

	function resetFileName() {
		setfileName('');
	}

	const handleFileView = async (file) => {
		// console.log('Handle File View Called', proposalData.projaFile);
		const msgs = await dispatch(getPdfInteractions(file.projaId));
		const pdf = await dispatch(setPdfData(file));
		const open = await dispatch(setOpenPDfDialog(true));
		// setDialog(true);
		setProjData(file);
	};

	function handleFileNameChange(e) {
		setfileName(e.target.value);
		if (e.target.value) {
			sethasFileName(true);
		} else {
			sethasFileName(false);
		}
	}

	//   const handleFileView = async (file) => {
	//     // console.log('Handle File View Called', proposalData.projaFile);
	//     const msgs = await dispatch(getPdfInteractions(file.projaId));
	//     const pdf = await dispatch(setPdfData(file));
	//     const open = await dispatch(setOpenPDfDialog(true));
	//     // setDialog(true);
	//     setProjData(file);
	//   };

	useEffect(() => {
		callToolTipData();
	}, []);

	return (
		<>
			<TableContainer>
				{status !== 'COMMITTED' && toolTipData.length === 0 ? (
					<NoTableDataComponent message='No PDF uploaded yet' />
				) : (
					<Table aria-label='customized table'>
						{toolTipData?.length
							? toolTipData
									?.map((item, index) => {
										return (
											<TableRow
												key={`${item?.projaId}${item?.projaFile}`}
												sx={{
													borderLeft: '1px solid #E4E4E4',
													borderRight: '1px solid #E4E4E4',
													borderTop: '1px solid #E4E4E4',
													width: '50%',
												}}
												style={{ borderBottom: '1px solid #E4E4E4' }}
											>
												<TableCell
													component='th'
													scope='row'
													sx={{
														borderLeft: '1px solid #E4E4E4',
														borderRight: '1px solid #E4E4E4',
														color: '#0069DF',
														fontWeight: 'bold',
														p: '8px',
														pr: 0,
													}}
												>
													<input
														disabled
														name='file-input'
														type='text'
														className='view-file-input'
														value={item?.projaDesc}
														style={{ width: '80%' }}
													/>
												</TableCell>
												<TableCell
													sx={{
														borderRight: '1px solid #E4E4E4', // add this style to create the right border for the last cell in each row
														color: '#646464',
														p: '8px',
													}}
												>
													<Button onClick={() => handleFileView(item)}>
														<Typography
															sx={{
																display: 'flex',
																gap: 1,
																alignItems: 'center',
																fontSize: '14px',
																color: '#00BABA',
																textTransform: 'capitalize',
															}}
														>
															<RemoveRedEyeIcon fontSize='small' />
															View
														</Typography>
													</Button>
												</TableCell>
											</TableRow>
										);
									})
									.reverse()
							: null}
						{status === 'COMMITTED' && (
							<TableRow
								key={1897797}
								sx={{
									borderLeft: '1px solid #E4E4E4',
									borderRight: '1px solid #E4E4E4',
									borderTop: '1px solid #E4E4E4',
									width: '50%',
								}}
								style={{ borderBottom: '1px solid #E4E4E4' }}
							>
								<TableCell
									component='th'
									scope='row'
									sx={{
										borderLeft: '1px solid #E4E4E4',
										borderRight: '1px solid #E4E4E4',
										color: '#0069DF',
										fontWeight: 'bold',
										p: '8px',
										pr: 0,
									}}
								>
									<Input
										name='file-input'
										onChange={handleFileNameChange}
										type='text'
										value={fileName}
										className='add-file-input'
										placeholder='Enter file name'
									/>
								</TableCell>
								<TableCell
									sx={{
										borderRight: '1px solid #E4E4E4', // add this style to create the right border for the last cell in each row
										color: '#646464',
										p: '8px',
									}}
								>
									<ToolTipTableCell
										setProjData={setProjData}
										setDialog={setOpenDialog}
										dataId={dataId}
										PROJATYPE=''
										hasFileName={hasFileName}
										fileName={fileName}
										projaProjId={dataId}
										resetFileName={resetFileName}
									/>
								</TableCell>
							</TableRow>
						)}
					</Table>
				)}
			</TableContainer>
			{/* <Button

				onClick={handleNewTableRow}
				style={{
					backgroundColor: '#0069DF',
					fontWeight: 'bold',
					textTransform: 'capitalize',
					width: '100%',
					marginTop: '15px',
				}}
				variant='contained'
			>
				Add new entry
			</Button> */}
			<PdfModal
				open={openDialog}
				setDialog={setOpenDialog}
				projData={projData}
			/>
		</>
	);
};

export default AddFilesTable;

// <TableRow
//           key={2}
//           sx={{
//             borderLeft: "1px solid #E4E4E4",
//             borderRight: "1px solid #E4E4E4",
//             borderTop: "1px solid #E4E4E4",
//           }}
//         >
//           <TableCell
//             component="th"
//             scope="row"
//             sx={{
//               borderLeft: "1px solid #E4E4E4",
//               borderRight: "1px solid #E4E4E4",
//               color: "#0069DF",
//               fontWeight: "bold",
//               p: "8px",
//             }}
//           >
//             Feasibility report
//           </TableCell>
//           <TableCell
//             sx={{
//               borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
//               color: "#646464",
//               p: "8px",
//             }}
//           >
//             <ToolTipTableCell
//             setProjData={setProjData}
//             setDialog={setOpenDialog} dataId={dataId} PROJATYPE="FEASIBILITY_REPORT" />
//           </TableCell>
//         </TableRow>
//         <TableRow
//           key={3}
//           sx={{
//             borderLeft: "1px solid #E4E4E4",
//             borderRight: "1px solid #E4E4E4",
//           }}
//           style={{ borderBottom: "1px solid #E4E4E4" }}
//         >
//           <TableCell
//             component="th"
//             scope="row"
//             sx={{
//               borderLeft: "1px solid #E4E4E4",
//               borderRight: "1px solid #E4E4E4",
//               color: "#0069DF",
//               fontWeight: "bold",
//               p: "8px",
//             }}
//           >
//             Bill of Quantities
//           </TableCell>
//           <TableCell
//             sx={{
//               borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
//               color: "#646464",
//               p: "8px",
//             }}
//           >
//             <ToolTipTableCell
//             setProjData={setProjData}
//             setDialog={setOpenDialog} dataId={dataId} PROJATYPE="BILL_OF_QUANTITIES" />
//           </TableCell>
//         </TableRow>
//         <TableRow
//           key={4}
//           sx={{
//             borderLeft: "1px solid #E4E4E4",
//             borderRight: "1px solid #E4E4E4",
//             borderTop: "1px solid #E4E4E4",
//           }}
//         >
//           <TableCell
//             component="th"
//             scope="row"
//             sx={{
//               borderLeft: "1px solid #E4E4E4",
//               borderRight: "1px solid #E4E4E4",
//               color: "#0069DF",
//               fontWeight: "bold",
//               p: "8px",
//               width: "65%",
//             }}
//           >
//             Quotations from suppliers
//           </TableCell>
//           <TableCell
//             sx={{
//               borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
//               color: "#646464",
//               p: "8px",
//             }}
//           >
//             <ToolTipTableCell
//             setProjData={setProjData}
//             setDialog={setOpenDialog}
//               dataId={dataId}
//               PROJATYPE="QUOTATION_FROM_SUPPLIERS"
//             />
//           </TableCell>
//         </TableRow>
//         <TableRow
//           key={5}
//           sx={{
//             borderLeft: "1px solid #E4E4E4",
//             borderRight: "1px solid #E4E4E4",
//             borderTop: "1px solid #E4E4E4",
//           }}
//         >
//           <TableCell
//             component="th"
//             scope="row"
//             sx={{
//               borderLeft: "1px solid #E4E4E4",
//               borderRight: "1px solid #E4E4E4",
//               color: "#0069DF",
//               fontWeight: "bold",
//               p: "8px",
//             }}
//           >
//             Other Documentation
//           </TableCell>
//           <TableCell
//             sx={{
//               borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
//               color: "#646464",
//               p: "8px",
//             }}
//           >
//             <ToolTipTableCell setProjData={setProjData} setDialog={setOpenDialog} dataId={dataId} PROJATYPE="OTHER_DOCUMENTATION" />
//           </TableCell>
//         </TableRow>
