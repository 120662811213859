import { Typography } from '@mui/joy'
import OfferCardList from '../../components/wsp_offers_list/OfferCardList'

export default function Offer(){
    return (
        <>
            <Typography component="h3" className="fmw-page-title">
                Offers
            </Typography>

            <OfferCardList />
        </>
    )
}