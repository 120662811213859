import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import ImageInput from '../../../components/committed_project/ImageInput';
import ChoiceSelector from '../ChoiceSelector';
import { Input, Textarea, Typography } from '@mui/joy';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';

export default function DelegatedTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);
	const [filename, setfilename] = useState(committedd?.wrDsCountyGovLetterFile);

	const handleChange = async (e) => {
		await setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function getCountyUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			wrDsCountyGovLetterFile: data,
		}));
	}

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleDelegatedServices() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	useEffect(() => {
		handleDelegatedServices();
	}, [formData]);

	useEffect(() => {
		if (committedd?.wrDsCountyGovLetterFile) {
			setfilename(committedd?.wrDsCountyGovLetterFile);
		}
	}, [committedd?.wrDsCountyGovLetterFile]);

	useEffect(() => {}, [formData, id, filename, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
							<TableCell align='left'>File</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className='basic'>
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Letter of No Objection from County Government
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrDsCountyGovLetter'}
									choiceValue={formData?.wrDsCountyGovLetter}
									// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getCountyUrl}
									title={threeValues?.length ? threeValues[1] : 'Attach letter'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Is SSWP in WSP area?
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									choiceName={'wrDsWsspPresent'}
									choiceValue={formData?.wrDsWsspPresent}
									getChoiceData={handleChange}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Letter of No Objection from WSP?
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									choiceName={'wrDsWspLetter'}
									choiceValue={formData?.wrDsWspLetter}
									getChoiceData={handleChange}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Delegated SPA
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									choiceName={'wrDsDelegatedSpa'}
									choiceValue={formData?.wrDsDelegatedSpa}
									getChoiceData={handleChange}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
