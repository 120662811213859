import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import FileButton from '../button/FileButton';
import QuotationButton from '../../pages/components/step2/quotation_button';
import PdfButton from '../button/PdfButton';

const CustomTable = ({ data, isMultiple }) => {
    return (
        <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow sx={{
                        borderLeft: "1px solid #E8F8FE",
                        borderRight: "1px solid #E8F8FE",
                        borderTop: "1px solid #E8F8FE",
                    }}>
                        <TableCell sx={{
                            borderLeft: "1px solid #E8F8FE",
                            borderRight: "1px solid #E8F8FE",
                            borderBottom: "1px solid #E8F8FE",
                        }}>Item</TableCell>
                        <TableCell sx={{
                            borderLeft: "1px solid #E8F8FE",
                            borderRight: "1px solid #E8F8FE",
                            borderBottom: "1px solid #E8F8FE",
                        }} align="left">Details</TableCell>
                        <TableCell sx={{
                            borderLeft: "1px solid #E8F8FE",
                            borderRight: "1px solid #E8F8FE",
                            borderBottom: "1px solid #E8F8FE",
                        }} align="left">File</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                borderLeft: "1px solid #E8F8FE",
                                borderRight: "1px solid #E8F8FE",
                            }}
                        >
                            <TableCell component="th" scope="row" sx={{ borderLeft: "1px solid #E8F8FE", borderBottom: "1px solid #E8F8FE", borderRight: "1px solid #E8F8FE", }}>
                                {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ borderLeft: "1px solid #E8F8FE", borderBottom: "1px solid #E8F8FE", borderRight: "1px solid #E8F8FE", }}>
                                {row.desc}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ borderLeft: "1px solid #E8F8FE", borderBottom: "1px solid #E8F8FE", borderRight: "1px solid #E8F8FE", width: '25%' }}>
                                {isMultiple ? <QuotationButton /> :
                                    <FileButton title={row.file} titlePreview={row.file} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CustomTable;
