import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios"

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	saving: false,
	invData:[]
};

export const save = createAsyncThunk('saveSlice/save', async (data) => {
	let saveUrl = data.url;
	delete data.url;
	const res = await axios
		.post(`${url}${saveUrl}`, data)
		.then((res) => res.data);
	return res;
});
 
export const fetchInvoice = createAsyncThunk(
	"bankSlice/fetchInvoice",
	async (data) => {
	  const response = await axios.get(
		`${url}/dash/fetchProjectDevelopment.action?projId=${data?.projId}`
	  ); 
	  return response.data?.data?.result;
	}
  );

  

export const saveSlice = createSlice({
	name: 'save',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(save.pending, (state) => {
				state.saving = true;
			})
			.addCase(save.fulfilled, (state, action) => {
				state.saving = false;
			})
			.addCase(save.rejected, (state) => {
				state.saving = false;
			})

			.addCase(fetchInvoice.pending, (state) => {
				state.loading = true;
			  })
			  .addCase(fetchInvoice.fulfilled, (state, action) => {
				state.loading = false;
				state.invData = action.payload;
			  })
			  .addCase(fetchInvoice.rejected, (state) => {
				state.loading = false;
			  })

			
	},
});

export default saveSlice.reducer;
export const {} = saveSlice.actions;
