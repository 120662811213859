export const ProblemSolve = [
    {
        value: 'Water source reliability',
        label: 'Water source reliability',
    },
    {
        value: 'Additional water source needed ',
        label: 'Additional water source needed ',
    },
    {
        value: 'Water quality',
        label: 'Water quality',
    },
    {
        value: 'Extension of distribution area',
        label: 'Extension of distribution area',
    },
    {
        value: 'Reduce leaks',
        label: 'Reduce leaks',
    },
    {
        value: 'Metering data',
        label: 'Metering data',
    },
    {
        value: 'Additional kiosks',
        label: 'Additional kiosks',
    },
    {
        value: 'Reduce pumping costs',
        label: 'Reduce pumping costs',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]
