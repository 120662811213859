import {
  Checkbox,
  Chip,
  Select,
  Option,
  Input,
  Typography,
  FormHelperText,
} from "@mui/joy";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  registerPartner,
  setInstitutionDetails,
} from "../../../features/partner/partnerSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Delete,
  KeyboardArrowDown,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { setPhoneErrorStep1 } from "../../../features/onboard_partner/onboardPartnerSlice";
import { selectClasses } from "@mui/joy/Select";
import { TypeOfRegistration } from "../data/TypeOfRegistration";
import { PeriodsOrg } from "../data/PeriodsOrg";
import {
  fetchWspApplications,
  setBasicDetails,
  setBusinessDetails,
  setEmailErrorDetails,
  setPhoneAltErrorDetails,
  setPhoneErrorDetails,
} from "../../../features/wsp/wspSlice";
import { StateWp } from "../../../stateWp";
import { WaterProject } from "../../../WaterProject";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountyData } from "../../../components/CountyData";
import { SubCountyData } from "../../../SubCountyData";
import FormPhoneError from "../../../components/FormPhoneError";
import { searchPhoneNumber } from "../../../features/auth/authSlice";

const Step1Partner = () => {
  const dispatch = useDispatch();
  const { step1, errorPhoneStep1 } = useSelector(
    (state) => state.onboardPartner
  );
  const { user } = useSelector((state) => state.auth);
  const [isUpdating, setIsUpdating] = useState(false);

  const {
    wspProject,
    wsp,
    wspApplications,
    businessDetails,
    basicDetails,
    errorEmail,
    errorPhone,
    errorAltPhone,
  } = useSelector((state) => state.wsp);
  const { messagePhone } = useSelector(state => state.auth)

  const [isUpdatingBasic, setIsUpdatingBasic] = useState(false);
  const [formDataBasic, setFormDataBasic] = useState(basicDetails);
  const {
    usrEmail,
    usrFirstName,
    usrLastName,
    usrNationalId,
    usrSalt,
    usrMobileNumber,
    usrAltFirstName,
    usrAltLastName,
    usrAltPhoneNo,
  } = formDataBasic;

  const [isUpdatingBusiness, setIsUpdatingBusiness] = useState(false);
  const [formDataBusiness, setFormDataBusiness] = useState(businessDetails);
  const {
    accTradeName,
    accRegNo,
    accLocation,
    accZipCode,
    accBizType,
    accServices,
    accCounty,
    accSubCounty,
    accRole,
  } = formDataBusiness;

  const [error, setError] = useState(errorEmail);
  const [count, setCount] = useState("");
  const sub = SubCountyData.filter((counts) => counts.name == count);
  const lot = sub[0]?.sub_counties;

  const [phoneNo, setPhoneNo] = useState(usrMobileNumber);
  const [isValidPhone, setIsValidPhone] = useState(errorPhone);
  const [focus, setFocus] = useState(false);


  const [altPhoneNo, setAltPhoneNo] = useState(usrAltPhoneNo);
  const [isValidAltPhone, setIsValidAltPhone] = useState(errorAltPhone);
  const [focusAlt, setFocusAlt] = useState(false);

  const handlePhone = (e) => {
    const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(e);
    setPhoneNo(e);
    setIsValidPhone(isKenyanPhoneNumber);
    setIsUpdating(true);
  }

  const validatePhoneNumber = () => {
    const kenyanPhoneNumberRegex = /^254[17]\d{8}$|^2547\d{8}$/;
    setIsValidPhone(kenyanPhoneNumberRegex.test(phoneNo));
    setIsUpdating(true);
    setFocus(false);
    dispatch(searchPhoneNumber({
      userPhone: phoneNo
    }))
  };
  const handleChangePhone = (e) => {
    setPhoneNo(e);
  }
  const handleFocus = (e) => {
    setFocus(true);
  }


  const validateAltPhoneNumber = () => {
    const kenyanPhoneNumberRegex = /^254[17]\d{8}$|^2547\d{8}$/;
    setIsValidAltPhone(kenyanPhoneNumberRegex.test(altPhoneNo));
    setIsUpdating(true);
    setFocusAlt(false);
  };
  const handleChangeAltPhone = (e) => {
    setAltPhoneNo(e);
  }
  const handleFocusAlt = (e) => {
    setFocusAlt(true);
  }

  const [focusEmail, setFocusEmail] = useState('')
  const isValidEmail = (usrEmail) => {
    return /\S+@\S+\.\S+/.test(usrEmail);
  }
  const handleValidateEmail = (e) => {
    if (!isValidEmail(usrEmail)) {
      setError(true)
    } else {
      setError(false)
    }
    setIsUpdatingBasic(true);
    setFocusEmail(false);
  }
  const handleFocusEmail = (e) => {
    setFocusEmail(true);
  }

  const handleChangeBasic = (e) => {
    setFormDataBasic((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdatingBasic(true);
  };

  const handleChangeBusiness = (e) => {
    setFormDataBusiness((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdatingBusiness(true);
  };

  const usrAltFullNames = `${user.usrAltFirstName} ${user.usrAltLastName}`;

  useEffect(() => {
    if (!isUpdatingBasic) {
      // TODO::
    } else {
      const data = {
        ...formDataBasic,
        usrMobileNumber: phoneNo,
        usrAltPhoneNo: altPhoneNo,
      };
      dispatch(setBasicDetails(data));
      dispatch(setEmailErrorDetails(error));
      dispatch(setPhoneErrorDetails(isValidPhone));
      dispatch(setPhoneAltErrorDetails(isValidAltPhone));
    }
  }, [
    dispatch,
    isUpdatingBasic,
    formDataBasic,
    error,
    isValidPhone,
    isValidAltPhone,
  ]);

  useEffect(() => {
    if (!isUpdatingBusiness) {
      // TODO::
    } else {
      dispatch(setBusinessDetails(formDataBusiness));
    }
  }, [dispatch, isUpdatingBusiness, formDataBusiness]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(fetchWspApplications());
  //   }, 800);
  // }, [dispatch, wsp]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Particulars of the applicant
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Name of water project
          </Typography>
          <Input
            label=""
            type="text"
            value={accTradeName}
            name="accTradeName"
            onChange={handleChangeBusiness}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            First name of contact person
          </Typography>
          <Input
            name="usrFirstName"
            value={usrFirstName}
            onChange={handleChangeBasic}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Last name of contact person
          </Typography>
          <Input
            name="usrLastName"
            value={usrLastName}
            onChange={handleChangeBasic}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Email contact
          </Typography>
          <Input
            name="usrEmail"
            value={usrEmail}
            onChange={handleChangeBasic}
            onBlur={handleValidateEmail}
            onFocus={handleFocusEmail}
            label=""
            type="email"
            size="lg"
            className="fmw-input-joy"
          />
          {usrEmail && error && !focusEmail && (
            <FormHelperText className="upload-sub-label-error">
              Invalid email address
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-onboard-partner-sub-label-error" : "fmw-onboard-partner-sub-label"}
            sx={{ mb: ".5rem" }}
          >
            Telephone contact (Mobile)
          </Typography>
          {phoneNo && messagePhone == "OK" && !focus &&
            <FormPhoneError
              title="Account already exists" />
          }
          <ReactPhoneInput
            defaultCountry="ke"
            country="ke"
            className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "flags-error" : "flags"}
            inputClass={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-phone-error" : "fmw-phone flags"}
            required
            searchClass="search-class"
            searchStyle={{
              margin: "0",
              width: "100% !important",
              height: "80px !important",
            }}
            enableSearchField
            disableSearchIcon
            value={phoneNo}
            onChange={handleChangePhone}
            onBlur={validatePhoneNumber}
            onFocus={handleFocus}
          />
          {phoneNo && !isValidPhone && !focus && (
            <FormPhoneError
              title="Enter a valid phone number" />
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Position in Water Project
          </Typography>
          <Input
            name="accRole"
            value={accRole}
            onChange={handleChangeBusiness}
            label=""
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={6}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "1.5rem" }}
          >
            Postal address & alternate contact details
          </Typography>
          <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Post code
            </Typography>
            <Input
              name="accZipCode"
              value={accZipCode}
              onChange={handleChangeBusiness}
              label=""
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              First name of alternate contact person
            </Typography>
            <Input
              name="usrAltFirstName"
              value={usrAltFirstName}
              onChange={handleChangeBasic}
              label=""
              size="lg"
              type="text"
              className="fmw-input-joy"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Last name of alternate contact person
            </Typography>
            <Input
              name="usrAltLastName"
              value={usrAltLastName}
              onChange={handleChangeBasic}
              label=""
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Mobile of Alternate Contact Person
            </Typography>
            <ReactPhoneInput
              placeholder="Enter phone number"
              defaultCountry="ke"
              country="ke"
              className={!isValidAltPhone && !focusAlt ? "flags-error" : "flags"}
              inputClass={!isValidAltPhone && !focusAlt ? "fmw-phone-error" : "fmw-phone flags"}
              required
              searchClass="search-class"
              searchStyle={{
                margin: "0",
                width: "100% !important",
                height: "80px !important",
              }}
              enableSearchField
              disableSearchIcon
              value={altPhoneNo}
              onChange={handleChangeAltPhone}
              onBlur={validateAltPhoneNumber}
              onFocus={handleFocusAlt}
            />

            {altPhoneNo && !isValidAltPhone && !focusAlt && (
              <FormPhoneError
                title="Enter a valid phone number" />
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "1.5rem" }}
          >
            Physical address
          </Typography>

          <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              County
            </Typography>
            <Select
              className="fmw-select"
              onChange={(e, newval) => {
                handleChangeBusiness(
                  {
                    target: {
                      value: newval,
                      name: "accCounty",
                    },
                  },
                  setCount(newval)
                );
              }}
              placeholder="Select county *"
              indicator={<KeyboardArrowDown />}
              size="lg"
              name="accCounty"
              value={accCounty}
              sx={{
                width: "100%",
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              {CountyData.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Sub-county
            </Typography>
            <Select
              className="fmw-select"
              onChange={(e, newval) => {
                handleChangeBusiness({
                  target: {
                    value: newval,
                    name: "accSubCounty",
                  },
                });
              }}
              placeholder="Select sub county *"
              indicator={<KeyboardArrowDown />}
              size="lg"
              name="accSubCounty"
              value={accSubCounty}
              sx={{
                width: "100%",
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              {lot?.map((option, index) => (
                <Option key={index} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Enter your location
            </Typography>
            <Input
              name="accLocation"
              value={accLocation}
              onChange={handleChangeBusiness}
              label=""
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Step1Partner;
