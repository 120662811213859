import {
  Box,
  Checkbox,
  Chip,
  Option,
  Select,
  Radio,
  radioClasses,
  Textarea,
  Input,
  Typography,
  Button,
} from "@mui/joy";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  registerPartner,
  setInstitutionDetails,
} from "../../../features/partner/partnerSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Article,
  AttachFile,
  CloudUploadOutlined,
  Create,
  Delete,
  Image,
  KeyboardArrowDown,
  PictureAsPdf,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  cleanupGovernment,
  deleteBankInfo,
  deleteBudgetInfo,
  fetchBankInfos,
  fetchBudgetInfo,
  onbaordGovFile,
  reset,
  saveBankInfo,
  saveBudgetInfo,
  setStep6,
  setStep6File,
  setStep6ProjProbSolved,
  setStep6ProjPurpose,
} from "../../../features/onboard_partner/onboardPartnerSlice";
import NumericFormat from "react-number-format";
import { ProjectCategory } from "../data/ProjectCategory";
import { selectClasses } from "@mui/joy/Select";
import { ProblemSolve } from "../data/ProblemSolve";
import { Benefit } from "../data/Benefit";
import { GenFunds } from "../data/GenFunds";
import { SourceParty } from "../data/SourceParty";
import { GovInformed } from "../data/GovInformed";
import {
  formatPath,
  numberWithCommas,
  previewPaper,
  subString,
} from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import FinancingProject from "../../../components/Tables/FinancingProject";
import { MainBudgetItems } from "../data/MainBudgetItems";
import { fetchWspApplications } from "../../../features/wsp/wspSlice";
import BudgetEdit from "../../../components/onboard_partner_modals/BudgetEdit";
import WspObjLetter from "../../../components/onboard_wsp_files/WspObjLetter";
import MultipleSelectProjectPurpose from "../../../components/multipleselect_water_source/MultipleSelectProjectPurpose";
import MultipleSelectProblemSolved from "../../../components/multipleselect_water_source/MultipleSelectProblemSolved";

function Step6() {
  const dispatch = useDispatch();
  const {
    step6,
    step6File,
    budgetInfo,
    stepGovFile,
    isSuccessDelete,
    isLoadingBudget,
    isSuccessUpdateBudget,
  } = useSelector((state) => state.onboardPartner);
  const { wspApplications } = useSelector((state) => state.wsp);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState(wspApplications[0] || step6);
  const [isChecked, setIsChecked] = useState(false);
  const {
    projProjCategory,
    projDescSubProj,
    projProbSolved,
    projOtherProblemSolved,
    projWhoBenefit,
    projOtherBenefit,
    projOtherPartyFinance,
    projDonorPartyFinance,
    projInfrastSubsidy,
    projMoneySource,
    projOtherFunds,
    projInformGov,
    projInformGovYesFile,
    projLoanSum,
    projSswpSum,
    proj3rdpartySum,
    proj3rdpartyCalculated,
    projSswpCalculated,
    projLoanCalculated,
    projTotalSum,
    projTotalCalculated,
    projBudgetItem,
    projBudgetMoney,
    projBudgetComment,
    projPayCapable,
    projMonthlyPay,
    projWspInformed,
  } = formData;

  const [budgett, setBudgett] = useState();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [budgetData, setBudgetData] = useState({
    biItem: null,
    biComment: "",
  });
  const [biBudget, setBiBudget] = useState("");

  const { biItem, biComment } = budgetData;

  const [reduce, setReduce] = useState(step6.projMonthlyPay);

  const reducingLoan = () => {
    const P = projEstCost;
    const y = projLoanYears;
    const n = Number(y * 12);
    const r = 0.015 * 12;
    const R = 1 + r / 100;
    const T = Math.pow(R, n).toFixed(2);
    const Q = (P * T * (R - 1)) / (T - 1);
    setReduce(Q.toFixed(2));
  };

  // const [projPayCapable, setProjPayCapable] = React.useState(step6.projPayCapable);
  // const handleRadioChange = (event) => {
  //     setProjPayCapable(event.target.value);
  // };

  const handleChangeBudget = (e) => {
    e.target.value < 0
      ? (e.target.value = 0)
      : setBudgetData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    setIsUpdating(true);
  };
  const handleChangeBudgetKes = (ev) => {
    ev.floatValue < 0
      ? setBiBudget((ev.floatValue = 0))
      : setBiBudget(ev.floatValue);
    setIsUpdating(true);
  };

  const saveBudget = (e) => {
    if (!biItem || !biBudget || !biComment) {
      toast.warning("Please fill all required fields");
    } else {
      dispatch(
        saveBudgetInfo({
          biProjId: wspApplications[0].projId,
          biItem: biItem,
          biBudget: biBudget,
          biComment: biComment,
        })
      );
      setBiBudget("");
      setBudgetData({
        biItem: null,
        biBudget: "",
        biComment: "",
      });
    }
  };

  const handleDelete = (id) => {
    dispatch(
      deleteBudgetInfo({
        biId: id,
      })
    );
    setIsUpdating(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  // const [fileArr, setFileArr] = useState(step6.projInformGovYesFile);

  // const [fileId, setFileId] = useState(step6File)

  // const { nameStep6, dateStep6, file_base64Step6, typeStep6 } = fileArr;
  const [errorId, setErrorId] = useState("");
  const [idPdf, setIdPdf] = useState(undefined);
  const [fileId, setFileId] = useState(step6File);
  const { nameStep6, dateStep6, file_base64Step6, typeStep6 } = fileId;

  const current = new Date();
  const year = current.getFullYear();
  const month = current.toLocaleString("default", { month: "short" });
  const day = ("0" + current.getDate()).slice(-2);
  const _date = `${month} ${day},${year}`;

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };

  const handleSelectChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    // setIsChecked(isChecked);

    setIsUpdating(true);
  };

  const materialUiInputProps = {
    required: true,
    fullWidth: true,
  };

  const [projEstCost, setProjEstCost] = React.useState(
    step6.projEstCost || wspApplications[0].projEstCost
  );
  const handleEstCost = (ev) => {
    setProjEstCost(ev.floatValue);
    setIsUpdating(true);
  };

  const [projLoanAmount, setProjLoanAmount] = React.useState(
    step6.projLoanAmount || wspApplications[0].projLoanAmount
  );
  const handleLoanAmount = (ev) => {
    setProjLoanAmount(ev.floatValue);
    setIsUpdating(true);
  };

  const [projLoanYears, setProjLoanYears] = React.useState(
    step6.projLoanYears || wspApplications[0].projLoanYears
  );
  const handleLoanYears = (ev) => {
    if (ev.floatValue > "5") {
      setProjLoanYears("0");
      toast.error("Please select less than 5 years");
    } else {
      setProjLoanYears(ev.floatValue);
    }

    setIsUpdating(true);
  };

  const handleId = (e) => {
    const _imgArr = [];
    const _file = e.target.files[0];
    if (!_file) return;
    const allowedExtensions = /(\.pdf)$/i;
    const MAX_FILE_SIZE = 1e7; //10mb
    if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
      setErrorId('Please upload a PDF file not more than 10mb ');
      setIdPdf("");
      return;
    }
    setFile(_file);
    setErrorId("");
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      let imageObj = {
        nameStep6: _file.name,
        dateStep6: _date,
        typeStep6: _file.type,
        file_base64Step6: base64String.toString(),
      };
      setFileId(imageObj);
      setIsUpdating(true);
    };
    reader.readAsDataURL(_file);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(onbaordGovFile(formData));
  };

  const removeId = () => {
    setFileId({
      nameStep6: "",
      dateStep6: "",
      typeStep6: "",
      file_base64Step6: undefined,
    });
    setIsUpdating(true);
    dispatch(cleanupGovernment());
  };

  const [fetchedCountyGov, setFetchedCountyGov] = useState(
    wspApplications[0].projInformGovYesFile
  );

  const removeFetchedCountyGov = () => {
    setFetchedCountyGov("");
  };

  const [waterSrc, setwaterSrc] = React.useState(
    wspApplications[0].projWaterSources
      ? wspApplications[0].projWaterSources.split(",")
      : []
  );
  const handleWtrSrcChange = (event) => {
    const {
      target: { value },
    } = event;
    setwaterSrc(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setIsUpdating(true);
  };

  const [projectPurpose, setProjectPurpose] = React.useState(
    wspApplications[0].projPurpose
      ? wspApplications[0].projPurpose.split(",")
      : []
  );
  const handleProjPurposeChange = (event) => {
    const {
      target: { value },
    } = event;
    setProjectPurpose(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setIsUpdating(true);
  };
  const [problemSolved, setProblemSolved] = React.useState(
    wspApplications[0].projProbSolved
      ? wspApplications[0].projProbSolved.split(",")
      : []
  );
  const handleProblemSolved = (event) => {
    const {
      target: { value },
    } = event;
    setProblemSolved(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setIsUpdating(true);
  };
  useEffect(() => {
    if (!isUpdating || isSuccessDelete || isSuccessUpdateBudget) {
      setTimeout(() => {
        dispatch(
          fetchBudgetInfo({
            projId: wspApplications[0].projId,
          })
        );
      }, 800);

      setTimeout(() => {
        dispatch(fetchWspApplications());
      }, 800);
    } else {
      dispatch(
        setStep6({
          projId: wspApplications[0].projId,
          projProjCategory: projProjCategory,
          projDescSubProj: projDescSubProj,
          projProbSolved: projProbSolved,
          projOtherProblemSolved: projOtherProblemSolved,
          projWhoBenefit: projWhoBenefit,
          projOtherBenefit: projOtherBenefit,
          projOtherPartyFinance: projOtherPartyFinance,
          projDonorPartyFinance: projDonorPartyFinance,
          projEstCost: projEstCost,
          projLoanAmount: projLoanAmount,
          projLoanYears: projLoanYears,
          projInfrastSubsidy: projInfrastSubsidy,
          projMoneySource: projMoneySource,
          projOtherFunds: projOtherFunds,
          projInformGov: projInformGov,
          projWspInformed: projWspInformed,
          projInformGovYesFile: stepGovFile.length > 0 ? stepGovFile : null,
          projLoanSum: projLoanSum,
          projSswpSum: projSswpSum,
          proj3rdpartySum: proj3rdpartySum,
          proj3rdpartyCalculated: proj3rdpartyCalculated,
          projSswpCalculated: projSswpCalculated,
          projLoanCalculated: projLoanCalculated,
          projTotalSum: projTotalSum,
          projTotalCalculated: projTotalCalculated,
          projPayCapable: projPayCapable,
          projMonthlyPay: reduce,
        })
      );
      dispatch(setStep6File(fileId));
      // dispatch(setStep3ProjWaterSources(waterSrc));
      dispatch(setStep6ProjProbSolved(problemSolved));
      dispatch(setStep6ProjPurpose(projectPurpose));
    }

    return () => {
      dispatch(reset());
    };
  }, [
    dispatch,
    isSuccessDelete,
    isUpdating,
    formData,
    projProjCategory,
    projEstCost,
    projLoanAmount,
    waterSrc,
    problemSolved,
    projectPurpose,
    projLoanYears,
    fileId,
    isSuccessUpdateBudget,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Financing
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Description of sub-project for which the loan is applied
          </Typography>
          <Textarea
            name="projDescSubProj"
            value={formData.projDescSubProj}
            onChange={handleChange}
            minRows={3}
            size="sm"
            variant="outlined"
            placeholder="Write here"
            className="fmw-textarea"
          />
        </Grid>
        {/* <Grid item xs={6}>
                    <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                        What is the problem being solved ?
                    </Typography>
                    <Select
                        className="fmw-select"
                        onChange={(e, newval) => {
                            handleChange({
                                target: {
                                    value: newval,
                                    name: "projProbSolved"
                                }
                            })
                        }}
                        placeholder="Select solution..."
                        multiple
                        indicator={<KeyboardArrowDown />}
                        size='lg'
                        name="projProbSolved"
                        value={formData.projProbSolved}

                        sx={{
                            width: '100%',
                            [`& .${selectClasses.indicator}`]: {
                                transition: '0.2s',
                                [`&.${selectClasses.expanded}`]: {
                                    transform: 'rotate(-180deg)',
                                },
                            },
                        }}
                    >
                        {ProblemSolve.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Grid> */}
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            What is the problem being solved ?
          </Typography>
          <MultipleSelectProblemSolved
            problemSolved={problemSolved}
            handleChange={handleProblemSolved}
          />
        </Grid>
        {problemSolved != "Other" ? (
          <></>
        ) : (
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              If Other, Please specify
            </Typography>
            <Input
              label=""
              type="text"
              value={formData.projOtherProblemSolved}
              name="projOtherProblemSolved"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Who will benefit ?
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projWhoBenefit",
                },
              });
            }}
            placeholder="Select benefit..."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projWhoBenefit"
            value={formData.projWhoBenefit}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {Benefit.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            What is the purpose of project
          </Typography>
          <MultipleSelectProjectPurpose
            projectPurpose={projectPurpose}
            handleChange={handleProjPurposeChange}
          />
        </Grid>

        {formData.projWhoBenefit != "Specific Customers" ? (
          <></>
        ) : (
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Please specify customer
            </Typography>
            <Input
              label=""
              type="text"
              value={formData.projOtherBenefit}
              name="projOtherBenefit"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Total estimated project cost
          </Typography>
          <NumericFormat
            value={projEstCost}
            customInput={Input}
            placeholder="KES"
            className="fmw-input-joy fmw-step4"
            onValueChange={handleEstCost}
            onKeyUp={() => reducingLoan(projEstCost)}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiInputProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Amount of loan required
          </Typography>
          <NumericFormat
            value={projLoanAmount}
            customInput={Input}
            placeholder="KES"
            className="fmw-input-joy fmw-step4"
            onValueChange={handleLoanAmount}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiInputProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Number of years for which loan is required (max 5)
          </Typography>
          <NumericFormat
            value={projLoanYears}
            customInput={Input}
            placeholder="Years"
            className="fmw-input-joy fmw-step4"
            onValueChange={handleLoanYears}
            onKeyUp={() => reducingLoan(projLoanYears)}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiInputProps}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Briefly choose how funds will be generated to repay the loan
          </Typography>
          {/*<Textarea*/}
          {/*    onChange={handleChange}*/}
          {/*    value={projMoneySource}*/}
          {/*    name="projMoneySource"*/}
          {/*    minRows={3}*/}
          {/*    size="lg"*/}
          {/*    variant="outlined"*/}
          {/*    className="fmw-textarea"*/}
          {/*/>*/}
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projMoneySource",
                },
              });
            }}
            placeholder="Select funds..."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projMoneySource"
            value={formData.projMoneySource}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {GenFunds.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>

        {formData.projMoneySource != "Other" ? (
          <></>
        ) : (
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              If Other, Please specify
            </Typography>
            <Input
              label=""
              type="text"
              value={formData.projOtherFunds}
              name="projOtherFunds"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Source of 3rd party financing
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projOtherPartyFinance",
                },
              });
            }}
            placeholder="Select 3rd Party source.."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projOtherPartyFinance"
            value={formData.projOtherPartyFinance}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {SourceParty.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>

        {formData.projOtherPartyFinance != "Donor" ? (
          <></>
        ) : (
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              If Donor, Please specify your donor
            </Typography>
            <Input
              label=""
              type="text"
              value={formData.projDonorPartyFinance}
              name="projDonorPartyFinance"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Has county government been informed ?
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projInformGov",
                },
              });
            }}
            placeholder="Select choice..."
            multiple
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projInformGov"
            value={projInformGov}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {GovInformed.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
        {formData.projInformGov != "Yes" ? (
          <></>
        ) : (
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                If yes, Please upload Letter of No Objection
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {fetchedCountyGov ? (
                <>
                  <Chip
                    variant="soft"
                    className="file-chip-primary"
                    color="primary"
                    size="sm"
                    startDecorator={<AttachFile size="sm" />}
                    onClick={() => previewPaper(fetchedCountyGov)}
                  >
                    {formatPath(fetchedCountyGov)}
                  </Chip>
                  {/* {formatPath(fetchedCountyGov)} */}
                  <IconButton
                    aria-label="close"
                    onClick={removeFetchedCountyGov}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      mr: "6px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  {!nameStep6 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "none",
                          // border: '1px solid var(--color-primary-light)',
                          width: "337px",
                          height: "46px",
                        }}
                      >
                        <label
                          className=""
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            className="mb-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#F7F7F7",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#A0A0A0",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                marginRight: "20px",
                                width: "52px",
                              }}
                            >
                              <CloudUploadOutlined
                                sx={{
                                  display: "flex",
                                  fontSize: "27px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mx: "12px",
                                  color: "#ffff",
                                  backgroundColor: "#A0A0A0 !important",
                                }}
                              />
                            </div>
                            {errorId ? (
                              <Typography
                                component="h6"
                                className="upload-sub-label-error"
                              >
                                {errorId}
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                className="upload-sub-label"
                              >
                                Click here to upload
                              </Typography>
                            )}
                          </div>
                          <input
                            type="file"
                            name="file"
                            value={nameStep6}
                            accept=".pdf"
                            webkitdirectory
                            onChange={handleId}
                            style={{ width: "0px", height: "0px" }}
                          />
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          border: "1px solid var(--color-primary-light)",
                          width: "337px",
                          height: "46px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "inherit",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                            width: "52px",
                          }}
                        >
                          <span style={{ margin: "0px 6px" }}>
                            {typeStep6 === "application/pdf" ? (
                              <PictureAsPdf
                                sx={{
                                  fontSize: "40px",
                                  color: "#f44336",
                                }}
                              />
                            ) : typeStep6 === "image/png" ||
                              "image/jpeg" ||
                              "image/gif" ? (
                              <Image
                                sx={{
                                  fontSize: "40px",
                                  color: "#263238",
                                }}
                              />
                            ) : (
                              <Article
                                sx={{
                                  fontSize: "40px",
                                  color: "#1976d2",
                                }}
                              />
                            )}
                          </span>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              backgroundColor:
                                "var(--color-primary-light) !important",
                            }}
                          />
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            component="h6"
                            className="fmw-upload-typography"
                          >
                            {subString(nameStep6, 25)}
                          </Typography>

                          <Typography
                            variant="caption"
                            className="upload-sub-label"
                          >
                            uploaded on {_date}
                          </Typography>
                        </div>

                        <div>
                          <IconButton
                            aria-label="close"
                            onClick={removeId}
                            sx={{
                              color: (theme) => theme.palette.grey[500],
                              mr: "6px",
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Has WSP been informed ? 
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projWspInformed",
                },
              });
            }}
            placeholder="Select ..."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projWspInformed"
            value={formData.projWspInformed}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {GovInformed.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <WspObjLetter formData={formData} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Financing for project{" "}
          </Typography>
          <Typography component="h6" className="fmw-onboard-partner-sub-label">
            {" "}
            Press enter when done writing sum
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FinancingProject />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Main budget items{" "}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Item
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChangeBudget({
                target: {
                  value: newval,
                  name: "biItem",
                },
              });
            }}
            // onChange={handleChangeBudget}
            value={biItem}
            name="biItem"
            placeholder="Select item *"
            indicator={<KeyboardArrowDown />}
            size="lg"

            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {MainBudgetItems.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Budget (Kes)
          </Typography>
          <NumericFormat
            value={biBudget}
            customInput={Input}
            placeholder="KES"
            className="fmw-input-joy fmw-step4"
            onValueChange={handleChangeBudgetKes}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiInputProps}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Comment
          </Typography>
          <Input
            label=""
            type="text"
            value={biComment}
            name="biComment"
            onChange={handleChangeBudget}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item md={6} xs={12}></Grid>
        <Grid item md={6} xs={12} sx={{ mt: 2 }}>
          <FormControl fullWidth variant={"standard"}>
            <FormLabel htmlFor="my-input" variant={"subtitle"}>
              NOTE: You can add as many budget items as possible*
            </FormLabel>
            {!isLoadingBudget ? (
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--bg-primary) !important",
                }}
                onClick={saveBudget}
              >
                Add Item
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                <CircularProgress size={17} color="inherit" />
                &nbsp;Adding...
              </Button>
            )}
          </FormControl>
        </Grid>

        {isLoadingBudget ? (
          <>
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
          </>
        ) : (
          <>
            {budgetInfo?.length ? (
              <>
                <Divider sx={{ my: 2 }} />
                <TableContainer sx={{ mt: 3 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Item</TableCell>
                        <TableCell align="left">Budget (Kes)</TableCell>
                        <TableCell align="left">Comment</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {budgetInfo.length &&
                        budgetInfo.map((bud) => (
                          <TableRow
                            key={bud.biId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {bud.biItem}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {numberWithCommas(bud.biBudget)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {bud.biComment}
                            </TableCell>

                            <TableCell align="left">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ mr: 0.5 }}
                                onClick={() => setBudgett(bud)}
                              >
                                <Create
                                  fontSize="small"
                                  onClick={() => handleClickOpen()}
                                />
                              </IconButton>

                              <IconButton aria-label="delete" size="small">
                                <Delete
                                  fontSize="small"
                                  onClick={() => handleDelete(bud.biId)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <Grid item></Grid>

        <Grid item xs={12}>
          <Typography>
            Are you capable/comfortable of making a monthly loan repayment of
            amount: Ksh.
            {numberWithCommas(
              reduce || wspApplications[0].projMonthlyPay
            )} for {step6.projLoanYears || wspApplications[0].projLoanYears}{" "}
            year(s) to service loan amount: Kshs.
            {numberWithCommas(
              step6.projLoanAmount || wspApplications[0].projLoanAmount
            )}{" "}
            ? This is based on approx. the interest rate of 15%
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", flexDirection: "row", mx: 8 }}>
            <Checkbox
              disabled={isChecked}
              name="projPayCapable"
              checked={projPayCapable == "Yes"}
              value="Yes"
              onChange={handleSelectChange}
              color="primary"
              label="Yes"
              size="lg"
              variant="soft"
              className="fmw-checkbox-label"
              sx={{ mr: 8 }}
            />
            <Checkbox
              disabled={isChecked}
              name="projPayCapable"
              checked={projPayCapable == "No"}
              value="No"
              onChange={handleSelectChange}
              color="primary"
              label="No"
              size="lg"
              variant="soft"
              className="fmw-checkbox-label"
            />
          </Box>
        </Grid>
      </Grid>
      <BudgetEdit
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        budgetEdited={budgett}
      />
    </>
  );
}

export default Step6;
