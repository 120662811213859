import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from 'react-toastify';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FormHelperText, Grid, Input, Typography } from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PositionData } from '../../PositionData';
import { cleanupNationalId, fetchDirectorsById, onbaordCopyIdStep3, reset, updateDirector } from '../../features/onboard_partner/onboardPartnerSlice';
import NationalId from './NationalId';
import { useEffect } from 'react';
import { formDataFromObject } from '../../utils';
import { CircularProgress } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const DirectorEdit = ({ open, handleClickOpen, handleClose, directorEdited }) => {

    const dispatch = useDispatch();
    const { isLoading, isSuccessUpdateDirector, directorEdit, isLoadingDirectorUpdate,step3CopyId,isLoadingCopyId } = useSelector((state) => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState('')

    // const { dirName, dirPosition, dirPhone, dirDuration } = formData
   
    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };

    const [phoneNo, setPhoneNo] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);

    const handlePhone = (e) => {
        const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(e);
        setPhoneNo(e);
        setIsValidPhone(isKenyanPhoneNumber);
        setIsUpdating(true);
    }

    const updateDirectorer = () => {
			// let directorInfo =  formDataFromObject(formData)
			if (phoneNo && !isValidPhone) {
				toast.warning('Enter a valid phone number ');
			} else {
				dispatch(
					updateDirector({
						dirId: formData.dirId,
						dirName: formData.dirName,
						dirPosition: formData.dirPosition,
						dirPhone: formData.dirPhone,
						dirDuration: formData.dirDuration,
						dirCopyOfId: step3CopyId.length > 0 ? step3CopyId : null,
					}),
				);
			}

			// dispatch(updateDirector(directorInfo));
		};

		useEffect(() => {
			setFormData(directorEdited);
			if (isSuccessUpdateDirector) {
				toast.success('Director updated successfully ');
				handleClose();
			}
			return () => {
				dispatch(reset());
			};
		}, [dispatch, directorEdited, isSuccessUpdateDirector]);
		// useEffect(()=>{
		//     dispatch(fetchDirectorsById({
		//         dirId: directorEdited
		//     }));
		//     setFormData(directorEdit)
		// },[directorEdited,formData])

		return (
			<div>
				<BootstrapDialog
					onClose={handleClose}
					aria-labelledby='customized-dialog-title'
					open={open}
					fullWidth={true}
					maxWidth='md'
				>
					{isLoading ? (
						<>
							<p>loading...</p>
						</>
					) : (
						<>
							<BootstrapDialogTitle
								id='customized-dialog-title'
								onClose={handleClose}
								style={{ display: 'flex', alignItems: 'center' }}
							>
								<Typography
									component='h6'
									className='fmw-checkbox-label'
									style={{ display: 'flex', alignItems: 'center' }}
								>
									Editing:
									<Typography
										sx={{ ml: 1 }}
										component='p'
										className='fmw-onboard-partner-sub-label-modal'
									>
										{formData?.dirName}
									</Typography>
								</Typography>
							</BootstrapDialogTitle>
							<DialogContent dividers>
								<Grid item xs={6}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Name of member
									</Typography>
									<Input
										label=''
										type='text'
										value={formData?.dirName}
										name='dirName'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>
								<Grid item xs={6}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Position of committee member
									</Typography>
									<Select
										className='fmw-select'
										onChange={(e, newval) => {
											handleChange({
												target: {
													value: newval,
													name: 'dirPosition',
												},
											});
										}}
										placeholder='Select position *'
										indicator={<KeyboardArrowDown />}
										size='lg'
										name='dirPosition'
										value={formData?.dirPosition ? formData?.dirPosition : ''}
										sx={{
											width: '100%',
											[`& .${selectClasses.indicator}`]: {
												transition: '0.2s',
												[`&.${selectClasses.expanded}`]: {
													transform: 'rotate(-180deg)',
												},
											},
										}}
									>
										{PositionData?.map((option) => (
											<Option key={option.value} value={option.value}>
												{option.label}
											</Option>
										))}
									</Select>
								</Grid>

								<Grid item xs={6}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Telephone of commitee member
									</Typography>
									<ReactPhoneInput
										defaultCountry='ke'
										country='ke'
										inputClass='fmw-phone'
										required
										searchClass='search-class'
										searchStyle={{
											margin: '0',
											width: '100% !important',
											height: '80px !important',
										}}
										enableSearchField
										disableSearchIcon
										value={phoneNo ? phoneNo : formData?.dirPhone}
										onChange={handlePhone}
									/>
									{phoneNo && !isValidPhone && (
										<FormHelperText className='upload-sub-label-error'>
											Enter a valid phone number
										</FormHelperText>
									)}
								</Grid>
								<Grid item xs={6}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Duration in committee in years
									</Typography>
									<Input
										label=''
										type='number'
										value={formData?.dirDuration}
										name='dirDuration'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<NationalId formData={directorEdited} />
								</Grid>
							</DialogContent>
							<DialogActions>
								{!isLoadingDirectorUpdate ? (
									<Button
										variant='outlined'
										disabled={isLoadingCopyId}
										color='primary'
										sx={{
											textTransform: 'capitalize',
											backgroundColor: 'var(--bg-primary) !important',
										}}
										onClick={updateDirectorer}
									>
										Update Director
									</Button>
								) : (
									<Button
										disabled
										variant='contained'
										sx={{ textTransform: 'none' }}
									>
										<CircularProgress size={17} color='inherit' />
										&nbsp;Updating Director...
									</Button>
								)}
							</DialogActions>
						</>
					)}
				</BootstrapDialog>
			</div>
		);
}
export default DirectorEdit