import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box } from '@mui/joy'
import React, { useState } from 'react'

function ShowPassword({ show, handleShowPassowrd }) {
    return (
        <>
            <Box sx={{ position: 'absolute', zIndex: 10, left: '340px', top: '281px' }} onClick={handleShowPassowrd}>
                {
                    show ? (<Visibility sx={{ fontSize: '1.7rem' }} />) : (<VisibilityOff sx={{ fontSize: '1.7rem' }} />)
                }
            </Box>
        </>
    )
}

export default ShowPassword
