import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TableContainer, Paper } from '@mui/material';
import { ExpandMore, DragIndicator } from '@mui/icons-material';

const CustomAccordion = ({ expanded, handleChange, title, children }) => {
    return (
        <Accordion expanded={expanded} onChange={handleChange} elevation={0} sx={{ backgroundColor: expanded ? '#fff' : 'inherit', mt: "60px" }} className='fmw-accrdion fmw-accrd'>
            <AccordionSummary className='accordion' expandIcon={<ExpandMore sx={{ color: expanded ? '#7E93AE' : 'var(--color-technical)' }} className='fmw-accrdion-svg' />} aria-controls="panel1bh-content" id="panel1bh-header">
                <Typography className='gap-5 accordion-title' sx={{ width: '50%', display: "flex", gap: "16px", flexShrink: 0 }}>
                    <DragIndicator className='fmw-accrdion-drag-svg' />
                    <Typography sx={{ fontSize: "18px", fontWeight: 500 }}> {title}</Typography>
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingLeft: '4rem', paddingTop: '0px !important', display: 'flex', flexDirection: 'column', mb: 0 }}>
                <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
                    {children}
                </TableContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;
