export const PeriodsOrg = [
    {
        value: 'Less than 12 months ',
        label: 'Less than 12 months ',
    },
    {
        value: '1- 2 Year',
        label: '1- 2 Year',
    },
    {
        value: '3- 4 Years',
        label: '3- 4 Years',
    },
    {
        value: 'Above 5 years',
        label: 'Above 5 years',
    },
]
