import { AttachFile } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UploadAction } from "../../features/global/globalSlice";
import { useEffect } from "react";

function FileHandler({ title, getFileUrl, file }) {
  const [fileName, setfileName] = useState("");

  const dispatch = useDispatch();

  const handleFile = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setfileName(e.target.files[0].name);
    const resp = await dispatch(UploadAction(formData));
    getFileUrl(resp?.payload?.jsonData);
    console.log(
      resp?.payload?.jsonData,
      "****************FILE UPLOADED********************"
    );
  };

  useEffect(() => {}, [fileName]);

  console.log(title, "*****************TITLE DATA********************", file);

  return (
    <>
      <label
        className=''
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <AttachFile
          sx={{ fontSize: "1.5rem", mr: 0.7, color: "var(--color-technical)" }}
        />
        <span
          style={{
            textDecoration: "underline",
            color: "var(--color-technical)",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {fileName ? fileName : title ? title : "Attach file"}
        </span>

        <input
          // multiple
          type='file'
          accept='pdf/*'
          onChange={handleFile}
          style={{ width: "0px", height: "0px" }}
        />
      </label>
    </>
  );
}
export default FileHandler;
