import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAllChatHeads,
} from '../../features/message/chatUserSlice';
import MessageList from './messageList';
import './messages.css';

export default function Messages() {
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.auth.user);

	useEffect(() => { dispatch(fetchAllChatHeads(currentUser.usrAccId)) }, []);

	return (
		<>
			<MessageList />
		</>
	);
}
