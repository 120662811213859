import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import SignupPartner from "./pages/signup_partner/SignupPartner";
import SignupWsp from "./pages/signup_wsp/SignupWsp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Timelines from "./pages/timelines/Timelines";
import WithoutNav from "./layout/WithoutNav";
import WithNav from "./layout/WithNav";
import WspHome from "./pages/home/WspHome";
import Account from "./pages/my_account/Account";
import LoanPerfection from "./pages/projects/LoanPerfection";
// import OnboardPartner from "./pages/onboard_partner/OnboardPartner";
import ProjectPreparation from "./pages/pipelines/ProjectPreparation";
import RefillStations from "./components/loan_perfection/RefillStations";
import UpdateTermsheet from "./components/loan_perfection/UpdateTermsheet";
import Termsheet from "./components/loan_perfection/Termsheet";
// import ProjectDevelopment from "./pages/projects/ProjectDevelopment";
import PostImplementation from "./pages/projects/PostImplementation";
import Performance from "./pages/performance/Performance";
import SupportMyProject from "./pages/pipelines/SupportMyProject";
import OnboardWsp from "./pages/onboard_wsp/OnboardWsp";
import VerifyLogin from "./pages/verify_login/VerifyLogin";
import VerifyEmail from "./pages/verify_login/VerifyEmail";
import SignUpTechAdv from "./pages/technical_advisors/SignUpTechAdv";
import AdvisorAccount from "./pages/my_account/AdvisorAccount";
// import ExpressionDetailPage from "./pages/expression_demand/ExpressionDetailPage";
import Messages from "./pages/messages/Messages";
import ExpressionDemand from "./pages/expression_demand/ExpressionDemand";
import ViableProject from "./pages/deal-room/ViableProject";
import Bids from "./pages/deal-room/Bids";
import Deals from "./pages/deal-room/Deals";
import DealRoomRefillStations from "./components/deal_room_list/DealRoomRefill";
import ExpressionDetailPage from "./pages/expression_demand/ExpressionDetailPage";
// import record from "./assets/record.gif"
import SubmitTermsheet from "./components/deal_room_list/SubmitTermsheet";
import ViewTermsheet from "./components/deal_room_list/ViewTermsheet";
import Offer from "./pages/offers/Offer";
import internetloss from "./assets/img/internet2.jpg";
import Support from "./pages/support";
import LetterOfIntent from "./pages/offers/LetterOfIntent";
import OnboardPartner from "./pages/onboard_partner/OnboardPartner";
import Step1Partner from "./pages/onboard_wsp/components/Step1Partner";
import Home from "./pages/home/Home";
import DealSourcing from "./pages/pipelines/DealSourcing";
import NewsEvents from "./pages/news_events/NewsEvents";
import Publication from "./pages/publication/Publication";
import SetPassword from "./pages/signin/SetPassword";
import TestPass from "./pages/signin/TestPass";
import RedirectToHTTPS from "./RedirectToHTTPS";
import ErrorPage from "./components/error_page/ErrorPage";
import FilterSection from "./pages/expression_demand/components/FilterSection";
import IntroPage from "./pages/technical/intro_page";
import Screening from "./pages/technical/Screening";
import IntroductionCommitted from "./pages/committedProjects/IntroductionCommitted";
import CommittedProjects from "./pages/committedProjects/CommittedProjects";
import LoanPerfectionDetails from "./pages/projects/LoanPerfectionDetails";
import SignupImpactFunder from "./pages/signup_impact_funder/SignupImpactFunder";
import Login from "./pages/authentication/Login";
import PasswordField from "./pages/authentication/PasswordField";
import BasicDetailsStepper from "./pages/signup_wsp/signup_stepper/BasicDetails";
import ProjectDescription from "./pages/authentication/ProjectDescription";
import BusinessDetailsStepper from "./pages/signup_wsp/signup_stepper/BusinessDetails";
import WspNewHome from "./pages/wsp_home/WspHome";
import ManagementDetails from "./pages/authentication/ManagementDetails";
import VerifyOtp from "./pages/authentication/VerifyOtp";
import ViewProject from "./components/global/ViewProject";
import NewProject from "./pages/new-project/NewProject";
import ProjectDevelopmentWsp from "./pages/project-development/ProjectDevelopmentWsp";
import InvoiceList from "./pages/project-development/InvoiceList";
import InvoiceView from "./pages/project-development/InvoiceView";
// import CommitedProjects from "./pages/project_preparation/ProjectPreparation";

function App() {
  if (!navigator.onLine) {
    return (
      <>
        <div
          style={{ padding: "0px 50px", margin: "0px 50px" }}
          className='container text-center'
        >
          <h1 style={{ color: "red", fontWeight: 700 }}>
            Oops! Something went wrong...
          </h1>
          <p>You appear to be offline. Turn on your Wi-Fi to continue</p>
          <img
            src={internetloss}
            style={{ objectFit: "cover", width: "100%" }}
          />
        </div>
      </>
    );
  }
  return (
    <div class='max-h-screen max-w-screen overflow-x-hidden fmw-font'>
      <Router>
        <Routes component={RedirectToHTTPS}>
          <Route element={<WithoutNav />}>
            {/* Wsp routes */}
            <Route path='/' element={<Home />} />
            <Route path='/signup-wsp/getting-started' element={<SignupWsp />} />
            <Route
              path='/signup-wsp/basic-details'
              element={<BasicDetailsStepper />}
            />
            <Route
              path='/signup-wsp/business-details'
              element={<BusinessDetailsStepper />}
            />
            <Route path='/signup-advisors' element={<SignUpTechAdv />} />
            <Route
              path='/signup-impact-funder'
              element={<SignupImpactFunder />}
            />
            <Route path='/signup-partner' element={<SignupPartner />} />
            <Route path='/verify-login' element={<VerifyLogin />} />
            <Route path='/verify-email' element={<VerifyEmail />} />
            <Route path='/news-events' element={<NewsEvents />} />
            <Route path='/publications' element={<Publication />} />
            <Route path='/test-pass' element={<TestPass />} />
            <Route path='/login' element={<Login />} />
            <Route
              path='/signup-wsp/password-setup'
              element={<PasswordField />}
            />
            <Route
              path='/signup-wsp/project-description'
              element={<ProjectDescription />}
            />
            <Route
              path='/signup-wsp/management-details'
              element={<ManagementDetails />}
            />
            <Route path='/signup-wsp/verify-otp' element={<VerifyOtp />} />
            {/* <Route path='/home' element={<WspNewHome />} /> */}

            <Route
              path='/set-password/:userName/:userId'
              element={<SetPassword />}
            />
            {/* Partner routes */}
          </Route>

          <Route element={<WithNav />}>
            {/* Wsp routes */}

            <Route path='/error-page' element={<ErrorPage />} />
            <Route path='/step1-partner' element={<Step1Partner />} />
            <Route path='/wsp-home' element={<WspHome />} />
            <Route path='/onboard-wsp' element={<OnboardWsp />} />
            <Route path='/messages' element={<Messages />} />
            <Route path='/loan-offers' element={<Offer />} />
            <Route path='/letter-of-intent' element={<LetterOfIntent />} />
            {/* Partner routes */}
            <Route path='/loan-perfection' element={<LoanPerfection />} />
            <Route
              path='/loan-perfection/:projName'
              element={<LoanPerfectionDetails />}
            />
            <Route path='/deal-sourcing' element={<DealSourcing />} />
            <Route
              path='/project-preparation'
              element={<ProjectPreparation />}
            />
            <Route path='/timelines' element={<Timelines />} />
            <Route path='/my-account' element={<Account />} />
            <Route path='/refill-stations' element={<RefillStations />} />
            <Route path='/update-termsheet' element={<UpdateTermsheet />} />
            <Route path='/termsheet' element={<Termsheet />} />
            <Route path='/new-project' element={<NewProject />} />
            {/* <Route
              path='/project-development'
              element={<ProjectDevelopment />}
            /> */}
            <Route
              path='/post-implementation'
              element={<PostImplementation />}
            />
            <Route path='/performance' element={<Performance />} />
            <Route path='/support-project' element={<SupportMyProject />} />
            <Route path='/advisor-account' element={<AdvisorAccount />} />
            <Route
              path='/expression-demand/:category'
              element={<ExpressionDemand />}
            />

            <Route
              path='/expression-details/:id/:status'
              element={<ExpressionDetailPage />}
            />
            <Route path='/intro-page/:id/:status' element={<IntroPage />} />
            <Route path='/viable-projects' element={<ViableProject />} />
            <Route path='/bids' element={<Bids />} />
            <Route path='/deals' element={<Deals />} />
            <Route
              path='/deal-refill-stations/:id'
              element={<DealRoomRefillStations />}
            />

            <Route path='/submit-termsheet/:id' element={<SubmitTermsheet />} />
            <Route path='/view-termsheet/:id' element={<ViewTermsheet />} />
            <Route path='/:url/:id/:name' element={<ViewProject />} />
            <Route path='/support' element={<Support />} />

            <Route
              path='/intro-committed-projects/:category'
              element={<IntroductionCommitted />}
            />
            <Route
              path='/committed-projects/:id/:status'
              element={<CommittedProjects />}
            />

            <Route path='/filter' element={<FilterSection />} />
            <Route path='/screening/:id/:status' element={<Screening />} />

            <Route path='/project-development' element={<ProjectDevelopmentWsp />} />
            <Route path='/invoice-list' element={<InvoiceList />} />
            <Route path='/invoice-view/:id' element={<InvoiceView />} />
            
            

            
          </Route>
        </Routes>
      </Router>

      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </div>
  );
}

export default App;
