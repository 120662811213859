import React, { useEffect } from 'react';
import { Article, AttachFile, CloudUploadOutlined, PictureAsPdf } from '@mui/icons-material';
import { Chip, Grid, TextField, Typography } from '@mui/joy';
import { Divider, IconButton } from '@mui/material';

import { formatPath, previewPaper, subString } from '../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupRegFilePdf, onboardRegFile, setRegFile } from '../../../features/tech_adv/techAdvSlice'
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

function CertificateOfReg() {
  const { regFile, taxFile, orgDetails, isSuccessRegFile } = useSelector((state) => state.techAdv)
  const [fileReg, setFileReg] = useState(regFile);
  const { nameReg, dateReg, file_base64Reg, typeReg } = fileReg;
  const [fileTax, setFileTax] = useState(taxFile);
  const { nameTax, dateTax, file_base64Tax, typeTax } = fileTax;
  const [file, setFile] = useState("");
  const [errorCert, setErrorCert] = useState('')
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth);
  const current = new Date();
  const year = current.getFullYear();
  const month = current.toLocaleString('default', { month: 'short' });
  const day = ("0" + current.getDate()).slice(-2);
  const _date = `${month} ${day},${year}`;

  const handleReg = (e) => {
    const _imgArr = [];
    const _file = e.target.files[0];
    if (!_file) return;
    const MAX_FILE_SIZE = 1e+7  //10mb 
    const allowedExtensions = /(\.pdf)$/i;
    if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
      setErrorCert('Please upload a PDF file not more than 10mb ');
      setFileReg('');
      return;
    }
    setFile(_file);
    setErrorCert('');
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace('data:', '')
        .replace(/^.+,/, '');

      // Create an object containing image information.
      let imageObj = {
        nameReg: _file.name,
        dateReg: _date,
        typeReg: _file.type,
        file_base64Reg: base64String.toString()
      };

      setFileReg(imageObj)
      setIsUpdating(true)
    };
    reader.readAsDataURL(_file);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    dispatch(onboardRegFile(formData));
  }


  const removeReg = () => {
    setFileReg({
      nameReg: '',
      dateReg: '',
      typeReg: '',
      file_base64Reg: undefined,
    });
    setIsUpdating(true);
    dispatch(cleanupRegFilePdf());
  };



  const [fetchedRegCert, setFetchedRegCert] = useState(user.accRegCert)
  const removeFetchedRegCert = () => {
    setFetchedRegCert('')
  }

  const isMobile = useMediaQuery({ query: '(max-width:480px)' })

  useEffect(() => {
    if (!isUpdating) {
      // TODO::
    } else {
      // if (isSuccessRegFile) {
      //   toast.success("File Uploaded Successfully")
      // }
      dispatch(setRegFile(fileReg))

    }
  }, [dispatch, isUpdating, isSuccessRegFile, fileReg])
  return (
    <>

      {
        !nameReg ? (
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center',
              border: 'none',
              // border: '1px solid var(--color-primary-light)',
              width: isMobile ? 'inherit' : '337px',
              height: '50px'
            }}>
              <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                  <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                    <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                  </div>

                  {
                    errorCert ? (
                      <Typography component="h6" className="upload-sub-label-error">
                        {errorCert}
                      </Typography>
                    ) : (
                      <Typography variant="caption" className="upload-sub-label">
                        Click here to upload
                      </Typography>
                    )
                  }
                </div>
                <input
                  type="file"
                  name='file'
                  accept=".pdf"
                  webkitdirectory
                  onChange={handleReg}
                  style={{ width: '0px', height: '0px' }}
                />

              </label>
            </div>
          </>
        ) : (
          <>
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
              border: '1px solid var(--color-primary-light)',
              width: isMobile ? 'inherit' : '337px',
              height: '55px'
            }}>
              <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                <span style={{ margin: "0px 6px" }}>
                  {
                    typeReg === "application/pdf" ? (
                      <PictureAsPdf sx={{
                        fontSize: '40px',
                        color: 'red'
                      }} />
                    ) : (
                      <Article sx={{
                        fontSize: '40px',
                        color: '#1976d2'
                      }} />
                    )
                  }

                </span>
                <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>

                <Typography component="h6" className="fmw-upload-typography">
                  {subString(nameReg, 25)}
                </Typography>


                <Typography variant="caption" className="upload-sub-label">
                  uploaded on  {_date}
                </Typography>

                {/* <img src={`data:${name};base64,${fileArr}`} alt="jobu" /> */}
                {/* { fileArr && <>{fileArr}</>} */}
              </div>

              <div>
                <IconButton
                  aria-label="close"
                  onClick={removeReg}
                  sx={{
                    color: (theme) => theme.palette.grey[500], mr: '6px'
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </>
        )
      }


    </>
  )
}

export default CertificateOfReg
