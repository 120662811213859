import { Typography, Box, Divider, Card } from "@mui/material";
import React from "react";
import MessageSection from "./MessageSection";
import HistoryCard from "./HistoryCard";
const Support = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#17C0C0",
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "capitalize",
            ml: 3,
          }}
        >
          Support
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          width: "100%",
          maxHeight: "80vh",
          mt: 1,
        }}
      >
        <Box sx={{ width: "70%", mr: 2, position: "sticky" }}>
          <MessageSection />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          style={{
            minHeight: "inherit",
          }}
        ></Divider>
        <Box
          container
          rowSpacing={5}
          columnSpacing={2}
          sx={{ width: "35%", ml: 2, position: "sticky" }}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
              my: 2,
              p: 1,
              border: 1,
              borderColor: "#E4E4E4",
              borderRadius: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                my: 2,
                width: "100%",
                borderBottom: 1,
                pb: 0.5,
                borderColor: "#E4E4E4",
              }}
            >
              Ticket History
            </Typography>
            <Divider orientation="horizontal"></Divider>

            <HistoryCard />
            <HistoryCard />
            <HistoryCard />
            <HistoryCard />
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Support;
