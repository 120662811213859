import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthSideImage from "../../../components/auth/AuthSideImage";
import Logo from "../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../../assets/chevron-left.svg";
import {
  Form,
  Input,
  Select,
  Divider,
  Upload,
  Checkbox,
  Space,
  message,
} from "antd";
import { SubCountyData } from "../../../SubCountyData";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessDetails } from "../../../features/wsp_registration/wspRegSlice";
import ScrollToTop from "../../../ScrollToTop";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

const selectBefore = (
  <Select defaultValue='+254'>
    <Option value='+254'>+254</Option>
    <Option value='+255'>+255</Option>
    <Option value='+256'>+256</Option>
  </Select>
);

export default function BusinessDetailsStepper() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { businessDetails } = useSelector((state) => state.wspReg);

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");

  const [fileUrl2, setfileUrl2] = useState("");
  const [fileName2, setfileName2] = useState("");

  const [subCounty, setsubCounty] = useState([]);
  const [defaultValue, setdefaultValue] = useState("");

  function selectedCounty(value) {
    const subCountyArray = SubCountyData.find((city) => city.name === value);
    setsubCounty(subCountyArray.sub_counties);
    setdefaultValue(subCountyArray.sub_counties[0]);
  }

  function selectedSubCounty(value) {
    setdefaultValue(value);
  }

  const props = {
    name: "file",
    multiple: false,
    // accept: 'image/png, image/jpeg, .pdf',
    accept: ".pdf",
    action: `${url}/usr/postImages.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const props2 = {
    name: "file",
    multiple: false,
    // accept: 'image/png, image/jpeg, .pdf',
    accept: ".pdf",
    action: `${url}/usr/postImages.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl2(info?.file?.response?.jsonData);
          setfileName2(info?.file?.name);
        } else {
          setfileUrl2("");
          setfileName2("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  function handleBack() {
    navigate("/signup-wsp/basic-details");
  }

  const [data, setdata] = useState({});

  const handleChange = (e) => {
    seteditedItem((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  function handleCountyChange(value) {
    seteditedItem((prevData) => ({
      ...prevData,
      conCounty: value,
    }));
  }

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      conMobileNumber: "254" + String(e),
    }));
  };

  const onFinish = async (values) => {
    values.projRegCertFile = fileUrl;
    values.projBankStatementFile = fileUrl2;
    values.accServices = values.accServices.join(",");
    // delete values.attFile;
    // delete values.attFile2;

    console.log(values);
    await dispatch(setBusinessDetails(values));
    navigate("/signup-wsp/management-details");
  };

  return (
    <>
      <ScrollToTop />
      <div className='lg:grid lg:grid-cols-6 p-3 lg:p-0'>
        <div className='bg-[#226cbf] w-full col-span-2 relative hidden lg:block'>
          <AuthSideImage hideStepper={false} hideText={false} current={1} />
        </div>
        <div className='w-full h-[100vh] lg:col-span-4'>
          <div className='flex flex-col'>
            <button
              onClick={handleBack}
              className='border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex'
            >
              <img src={leftArrow} alt='backarrow' />
            </button>
            <div className='flex justify-center flex-col items-center'>
              <img
                src={Logo}
                alt='logo'
                className='w-[211px] h-[73px] object-cover'
              />
              <h3 className='signup-start mt-2'>Let’s get started</h3>
              <span className='signup-start-text w-[383px] mt-[10px]'>
                All we need is a few company details.
              </span>
              <span className='signup-start-text w-[383px] mt-1'>
                Then you'll be set up and able to apply for financing.
              </span>

              <div className=' mt-10 p-6 w-fit'>
                <Form
                  layout='vertical'
                  ref={formRef}
                  name='control-ref'
                  onFinish={onFinish}
                  style={{
                    maxWidth: "100%",
                    width: "700px",
                    marginTop: 20,
                    padding: 5,
                  }}
                  form={form}
                  initialValues={{
                    requiredMarkValue: false,
                    ...businessDetails,
                  }}
                  requiredMark={false}
                >
                  <Form.Item
                    extra='The name of your water project'
                    name='accTradeName'
                    className='w-full'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder='Name of water project'
                      className='reg-input'
                    />
                  </Form.Item>

                  <Form.Item
                    extra='For example, ‘Director’, ‘Oversight’'
                    name='accRole'
                    className='w-full'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Input
                      placeholder='Role on the project'
                      className='reg-input'
                    />
                  </Form.Item>

                  <Form.Item
                    name='attFile'
                    extra='Upload your business certificate or permit as .pdf document'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Dragger {...props}>
                      <div className='flex items-center justify-between'>
                        <p className='ant-upload-text'>
                          Registration certificate
                        </p>

                        {fileName ? (
                          <span className='ant-upload-text'>{fileName}</span>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='25'
                            viewBox='0 0 24 25'
                            fill='none'
                          >
                            <g clip-path='url(#clip0_311_2606)'>
                              <path
                                d='M16 16.2514L12 12.2514M12 12.2514L7.99996 16.2514M12 12.2514V21.2514M20.39 18.6414C21.3653 18.1097 22.1358 17.2683 22.5798 16.2501C23.0239 15.2318 23.1162 14.0947 22.8422 13.0181C22.5682 11.9416 21.9434 10.987 21.0666 10.3049C20.1898 9.62283 19.1108 9.25218 18 9.25145H16.74C16.4373 8.08069 15.8731 6.99378 15.0899 6.07244C14.3067 5.1511 13.3248 4.4193 12.2181 3.93206C11.1113 3.44481 9.90851 3.21481 8.70008 3.25933C7.49164 3.30385 6.30903 3.62175 5.24114 4.18911C4.17325 4.75648 3.24787 5.55855 2.53458 6.53503C1.82129 7.51151 1.33865 8.63699 1.12294 9.82684C0.90723 11.0167 0.964065 12.24 1.28917 13.4047C1.61428 14.5694 2.1992 15.6453 2.99996 16.5514'
                                stroke='#565E6C'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_311_2606'>
                                <rect
                                  width='24'
                                  height='24'
                                  fill='white'
                                  transform='translate(0 0.25)'
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </Dragger>
                  </Form.Item>

                  <Form.Item
                    name='attFile2'
                    extra='Upload your latest statements as .pdf document'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Dragger {...props2}>
                      <div className='flex items-center justify-between'>
                        <p className='ant-upload-text'>
                          Upload Bank/SACCO/Till statements of the last 12
                          months
                        </p>

                        {fileName2 ? (
                          <span className='ant-upload-text'>{fileName2}</span>
                        ) : (
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='25'
                            viewBox='0 0 24 25'
                            fill='none'
                          >
                            <g clip-path='url(#clip0_311_2606)'>
                              <path
                                d='M16 16.2514L12 12.2514M12 12.2514L7.99996 16.2514M12 12.2514V21.2514M20.39 18.6414C21.3653 18.1097 22.1358 17.2683 22.5798 16.2501C23.0239 15.2318 23.1162 14.0947 22.8422 13.0181C22.5682 11.9416 21.9434 10.987 21.0666 10.3049C20.1898 9.62283 19.1108 9.25218 18 9.25145H16.74C16.4373 8.08069 15.8731 6.99378 15.0899 6.07244C14.3067 5.1511 13.3248 4.4193 12.2181 3.93206C11.1113 3.44481 9.90851 3.21481 8.70008 3.25933C7.49164 3.30385 6.30903 3.62175 5.24114 4.18911C4.17325 4.75648 3.24787 5.55855 2.53458 6.53503C1.82129 7.51151 1.33865 8.63699 1.12294 9.82684C0.90723 11.0167 0.964065 12.24 1.28917 13.4047C1.61428 14.5694 2.1992 15.6453 2.99996 16.5514'
                                stroke='#565E6C'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_311_2606'>
                                <rect
                                  width='24'
                                  height='24'
                                  fill='white'
                                  transform='translate(0 0.25)'
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </Dragger>
                  </Form.Item>

                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <Form.Item
                      extra={"Choose county of operation"}
                      name='accCounty'
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      <Select
                        style={{
                          height: "52px",
                        }}
                        className='reg-input'
                        placeholder='County'
                        onChange={selectedCounty}
                        size='large'
                      >
                        {SubCountyData.map((city) => {
                          return (
                            <Select.Option key={city.name} value={city.name}>
                              {city.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      extra={"Choose sub county of operation"}
                      name='accSubCounty'
                      rules={[
                        {
                          required: true,
                          message: "Required field",
                        },
                      ]}
                    >
                      <Select
                        style={{
                          height: "52px",
                        }}
                        name='sub_county'
                        onChange={selectedSubCounty}
                        value={defaultValue}
                        disabled={subCounty?.length ? false : true}
                        placeholder={"Sub-county"}
                        size='large'
                        className='reg-input'
                      >
                        {subCounty.map((city) => {
                          return (
                            <Select.Option required key={city} value={city}>
                              {city}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <Form.Item
                    extra={"You can choose more than one service"}
                    name='accServices'
                    rules={[
                      {
                        required: true,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      allowClear
                      style={{
                        height: "52px",
                      }}
                      placeholder={"Select services offered"}
                      size='large'
                      className='reg-input'
                      dropdownRender={(menu) => (
                        <>
                          <Space
                            style={{
                              padding: "8px 8px 4px",
                            }}
                          >
                            <span className='select-info mt-1'>
                              Choose one or more of the following
                            </span>
                          </Space>
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          {menu}
                        </>
                      )}
                      menuItemSelectedIcon={({ isSelected }) => (
                        <>
                          {isSelected ? (
                            <Checkbox checked={true}></Checkbox>
                          ) : (
                            <Checkbox checked={false}></Checkbox>
                          )}
                        </>
                      )}
                    >
                      <Select.Option
                        onClick={() => console.log("YES KIOSK")}
                        value={"Water Kiosk"}
                      >
                        Water kiosk
                      </Select.Option>
                      <Select.Option value={"Refill stations"}>
                        Refill stations
                      </Select.Option>
                      <Select.Option value={"Yard taps"}>
                        Yard taps
                      </Select.Option>
                      <Select.Option value={"Connections"}>
                        Connections
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <div className='w-full flex justify-center'>
                    <button type='submit' className='nxt-btn'>
                      Continue
                    </button>
                  </div>
                </Form>
              </div>

              <span className='already mt-[38px] mb-[10%]'>
                Already have an account?{" "}
                <Link to='/login' className='already-text'>
                  Log in
                </Link>
              </span>

              <div className='sticky mt-10'>
                <span className='already-text !no-underline'>
                  Privacy Policy • Terms and Conditions
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
