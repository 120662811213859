import { Checkbox, Typography } from '@mui/joy';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { setWaterRisks } from '../../features/committed_project/committedSlice';
import { useDispatch, useSelector } from 'react-redux';

function WaterRisks() {
    const { waterRisks, committedd,waterResources,
    } = useSelector((state) => state.committedProj)
    const dispatch = useDispatch()
    const [isUpdating, setIsUpdating] = useState()
    
    const [waterRiskData, setWaterRiskData] = useState(waterResources?.wrWaterResourceRisk ? waterResources?.wrWaterResourceRisk?.split(",") : [])
    // const { none, pollution, upstream, drought } = waterRiskData
    // const handleChangeCheckbox = (e) => {
    //     if (e.target.checked) {
    //         setWaterRiskData((prevState) => ({
    //             ...prevState,
    //             [e.target.name]: e.target.value
    //         }))
    //         // console.log("e.target",e.target.checked)
    //         setIsUpdating(true);
    //     } else {
    //         setWaterRiskData((prevState) => ({
    //             ...prevState,
    //             [e.target.name]: ''
    //         }))

    //     }
    // };

    const handleChangeCheckbox = (e) => {
        if (findWaterQualityItem(e.target.value)) {
          const newArr = waterRiskData?.filter(item => item != e.target.value)
          setWaterRiskData(newArr)
          setIsUpdating(true);
        }else{
          const newArr = [...waterRiskData]
          newArr?.push(e.target.value)
          setWaterRiskData(newArr)
          setIsUpdating(true);
        }
      };


      const findWaterQualityItem = str => {
        const data = waterRiskData?.length && waterRiskData?.find(item => item == str)
        if (data) {
          return true
        } else {
          return false
        }
      }
    

    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(setWaterRisks(waterRiskData))
        }
    }, [dispatch, isUpdating, waterRiskData]);

    useEffect(() => {
        setWaterRiskData(waterResources?.wrWaterResourceRisk?.split(",") ?? [])
      }, [committedd])

    useEffect(() => {
        setWaterRiskData(waterResources?.wrWaterResourceRisk?.split(",") ?? [])
      }, [committedd, waterResources])

    return (
        <>

            <Grid item xs={12} sx={{ mt: '1rem' }}>
                <Typography component="h6" className="fmw-checkbox-label-committed" sx={{ mb: '.8rem' }}>
                    Water resource risks
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <Checkbox
                    color="primary"
                    name="none"
                    checked={findWaterQualityItem('none')}
                    value="none"
                    onChange={handleChangeCheckbox}
                    label="None"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>
            <Grid item xs={3}>
                <Checkbox
                    color="primary"
                    name="pollution"
                    checked={findWaterQualityItem("Unexpected pollution from upstream")}
                    value="Unexpected pollution from upstream"
                    onChange={handleChangeCheckbox}
                    label="Unexpected pollution from upstream"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={3}>
                <Checkbox
                    color="primary"
                    name="upstream"
                    checked={findWaterQualityItem("Over abstraction upstream")}
                    value="Over abstraction upstream"
                    onChange={handleChangeCheckbox}
                    label="Over abstraction upstream"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={3}>
                <Checkbox
                    color="primary"
                    name="drought"
                    checked={findWaterQualityItem("drought")}
                    value="drought"
                    onChange={handleChangeCheckbox}
                    label="Drought"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />


            </Grid>

        </>
    )
}

export default WaterRisks
