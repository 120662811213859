import { Box, Card, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";

const NoTableDataComponent = ({ message }) => {
	return (
		<Box sx={{ width: '100%' }}>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 2,
					alignItems: 'center',
					justifyContent: 'center',
					width: '80%',
					margin: 'auto',
					padding: 2,
					my: 2,
					backgroundColor: '#F9F9F9',
					border: '1px solid #E0E0E0',
					borderRadius: '4px',
					boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
				}}
			>
				<InfoIcon sx={{ fontSize: 40, color: '#0873B9' }} />
				<Typography variant='body1' sx={{ color: '#616161' }}>
					{message ?? 'The table has no data'}
				</Typography>
			</Card>
		</Box>
	);
};

export default NoTableDataComponent;
