import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  AnnotationLayer,
} from "react-pdf/dist/esm/entry.webpack";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Comments from "./Comments";
import "./pdfAnnotations.css";
import { Box, Button, Divider, Hidden } from "@mui/material";
import { formatPdfPath } from "../../../../utils";
import { useSelector } from "react-redux";
import ScrollToBottom from "../../../messages/scrollToBottom";
import { Typography } from "@mui/joy";

const PdfAnnotations = ({ scale }) => {
  const { pdfData } = useSelector((state) => state.expressionDemandData);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Add currentPage state

  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setCurrentPage(1);
  }

  function changePage(offset) {
    setCurrentPage((prevPageNumber) => prevPageNumber + offset);
  }
  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxHeight: "74vh",
        justifyContent: "space-between",
        position: "relative",
        height: "fit-content",
      }}
    >
      <Box sx={{ width: "70%", overflow: "hidden", overflowY: "auto" }}>
        <ScrollToBottom />
        <Box className="Example__container">
          <div className="Example__container__document">
            <Document
              file={formatPdfPath(pdfData?.projaFile)}
              // file={{
              // url: formatPdfPath(pdfData?.projaFile),
              // httpHeaders: {
              //   "Access-Control-Allow-Headers": "*",
              //   "Access-Control-Allow-Origin": "*",
              //   "Referrer-Policy": "unsafe-url",
              //   "Referrer-Policy": "no-referrer-when-downgrade",
              // },
              // }}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <Page pageNumber={currentPage} scale={scale} />
            </Document>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                justifyItems: "start",
                gap: 1,
              }}
            >
              <Button
                type="button"
                disabled={currentPage <= 1}
                onClick={previousPage}
                sx={{ textTransform: "capitalize" }}
              >
                <KeyboardArrowLeftIcon /> Prev
              </Button>
              <Typography
                sx={{
                  justifyItems: "center",
                  display: "flex",
                  m: "auto",
                  width: "20%",
                }}
              >
                Page {currentPage || (numPages ? 1 : "--")} of{" "}
                {numPages || "--"}
              </Typography>
              <Button
                type="button"
                disabled={currentPage >= numPages}
                onClick={nextPage}
                sx={{ textTransform: "capitalize" }}
              >
                Next <KeyboardArrowRightIcon />
              </Button>
            </Box>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          width: "32%",
          height: "75vh",
          overflow: "hidden",
          overflowY: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            width: "30%",
            height: "72vh",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <Comments setCurrentPage={setCurrentPage} currentPage={currentPage} />
        </Box>
      </Box>
    </Box>
  );
};

export default PdfAnnotations;
