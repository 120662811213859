import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import partnerReducer from '../features/partner/partnerSlice';
import wspReducer from '../features/wsp/wspSlice';
import onboardPartnerReducer from '../features/onboard_partner/onboardPartnerSlice';
import pipelinesReducer from '../features/pipelines/pipelinesSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import techAdvReducer from '../features/tech_adv/techAdvSlice';
import expressionDemandReducer from '../features/expression_demand/expressionDemandSlice';
import chatUserReducer from '../features/message/chatUserSlice';
import dealSourcingReducer from '../features/deal_sourcing/dealSourcingSlice';
import offerReducer from '../features/wsp_offers/offerSlice';
import supportReducer from '../features/support/supportSlice';
import coommittedReducer from '../features/committed_project/committedSlice';
import globalReducer from '../features/global/globalSlice';
import projectReducer from '../features/project/projectSlice';
import wspRegReducer from '../features/wsp_registration/wspRegSlice'
import saveReducer from "../features/save/saveSlice";

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
	partner: partnerReducer,
	wsp: wspReducer,
	pipelines: pipelinesReducer,
	onboardPartner: onboardPartnerReducer,
	techAdv: techAdvReducer,
	expressionDemandData: expressionDemandReducer,
	messages: chatUserReducer,
	dealSourcing: dealSourcingReducer,
	offer: offerReducer,
	support: supportReducer,
	committedProj: coommittedReducer,
	global: globalReducer,
	project: projectReducer,
	wspReg: wspRegReducer,
	save: saveReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk],
});

export const persistor = persistStore(store);
