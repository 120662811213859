// import { Button, Divider, Grid, LinearProgress } from "@mui/material";
// import record from "../../assets/record.gif";
// import Typography from "@mui/material/Typography";
// import { Box, Container } from "@mui/system";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import {
//   getExpressionDemandData,
//   setFilterParams,
// } from "../../features/expression_demand/expressionDemandSlice";
// import FilterSection from "./components/FilterSection";
// import ProjectCard from "./components/ProjectCard";
// import ExportModal from "./components/ExportModal";
// import { useState } from "react";
// import ProjectPagination from "./ProjectPagination";
// import ExpressionDemandLoader from "./components/ExpressionDemandLoader";
// import SearchField from "../../components/search_field/SearchField";
// import ExpressionClassification from "../expression_classification/ExpressionClassification";
// import ClassificationSkeleton from "../../components/classification/ClassificationSkeleton";

// const ExpressionDemand = () => {
//   const { category } = useParams();
//   const [openExportDialog, setExportDialog] = useState(false);
//   const [limit, setLimit] = useState(10);
//   const [start, setStart] = useState(0);
//   const dispatch = useDispatch();
//   const { expressionData,loading } = useSelector(
//     (state) => state.expressionDemandData
//   );



//   function handleDataTrigger() {
//     dispatch(setFilterParams(category));
//     if (category === "complete") {
//       dispatch(getExpressionDemandData({ projStatus: "PENDING_APPROVAL" }));
//     } else if (category === "incomplete") {
//       dispatch(getExpressionDemandData({ projStatus: "ACTIVE" }));
//     } else if (category === "Viable") {
//       dispatch(
//         getExpressionDemandData({
//           sctViableGreater: 90,
//           projStatus: "PENDING_APPROVAL",
//         })
//       );
//     } else if (category === "Assistance") {
//       dispatch(
//         getExpressionDemandData({
//           projStatus: "PENDING_APPROVAL",
//           sctViableGreater: 70,
//           sctViableLess: 90,
//         })
//       );
//     } else if (category === "poor") {
//       dispatch(
//         getExpressionDemandData({
//           sctViableLess: 70,
//           projStatus: "PENDING_APPROVAL",
//         })
//       );
//     }
//   }

//   useEffect(() => {
//     handleDataTrigger();
//   }, [category]);

//   useEffect(() => {
//     setTimeout(() => {
//       handleDataTrigger();
//     }, 50);
//   }, [category]);

//   const ProjectElement = expressionData?.map((item) => {
//     return (
//       <ProjectCard key={item.projId} projectItem={item} category={category} />
//     );
//   });



//   return (
//     <>

//     {
//       loading ? (
//        <ClassificationSkeleton/>
//       ):(
//         <>
//         <ExpressionClassification category={category}/>
//         {
//           expressionData.length === 0 ? (
//             <p>No data found</p>
//           ):(

//             <FilterSection  category={category}/>
//             // <SearchField expressionData={expressionData} category={category}/>
//           )
//         }
//         </>
//       )
//     }

//       <ExportModal open={openExportDialog} setDialog={setExportDialog} />
//     </>
//   );
// };

// export default ExpressionDemand;


import { Button, Divider, Grid, LinearProgress } from "@mui/material";
import record from "../../assets/record.gif";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getExpressionDemandData,
  setFilterParams,
} from "../../features/expression_demand/expressionDemandSlice";
import FilterSection from "./components/FilterSection";
import ProjectCard from "./components/ProjectCard";
import ExportModal from "./components/ExportModal";
import { useState } from "react";
import ClassificationSkeleton from "../../components/classification/ClassificationSkeleton";
import SearchField from "../../components/search_field/SearchField";

const ExpressionDemand = () => {
  const { category } = useParams();
  const [openExportDialog, setExportDialog] = useState(false);
  const [limit, setLimit] = useState(10);
  const [start, setStart] = useState(0);
  const dispatch = useDispatch();
  const { expressionData } = useSelector(
    (state) => state.expressionDemandData
  );
  const [loading, setLoading] = useState(false)
  function handleDataTrigger() {
    dispatch(setFilterParams(category));
    if (category === "complete") {
      dispatch(getExpressionDemandData({ projStatus: "PENDING_APPROVAL" }));
    } else if (category === "incomplete") {
      dispatch(getExpressionDemandData({ projStatus: "ACTIVE" }));
    } else if (category === "draft") {
      dispatch(getExpressionDemandData({ projStatus: "DRAFT" }));
    } else if (category === "Viable") {
      dispatch(
        getExpressionDemandData({
          sctViableGreater: 90,
          projStatus: "PENDING_APPROVAL",
        })
      );
    } else if (category === "Assistance") {
      dispatch(
        getExpressionDemandData({
          projStatus: "PENDING_APPROVAL",
          sctViableGreater: 70,
          sctViableLess: 90,
        })
      );
    } else if (category === "poor") {
      dispatch(
        getExpressionDemandData({
          sctViableLess: 70,
          projStatus: "PENDING_APPROVAL",
        })
      );
    }
    else if (category === "wash") {
      dispatch(getExpressionDemandData({ projStatus: "WASH" }));
    }
  }

  useEffect(() => {
    handleDataTrigger();
  }, [category]);

  useEffect(() => {
    setTimeout(() => {
      handleDataTrigger();
    }, 50);
  }, [category]);

  const ProjectElement = expressionData?.map((item) => {
    return (
      <ProjectCard key={item.projId} projectItem={item} category={category} />
    );
  });

  return (
    <>
      {
        loading ? (
          <ClassificationSkeleton />
        ) : (
          <>

            <SearchField expressionData={expressionData} category={category} />

            {/* {
          expressionData.length === 0 ? (
            <p>No data found</p>
          ):(
          
             <SearchField expressionData={expressionData} category={category}/>
          )
        } */}
          </>
        )
      }
      <ExportModal open={openExportDialog} setDialog={setExportDialog} />
    </>
  );
};

export default ExpressionDemand;

