import { Divider, Textarea, FormHelperText, Input, Typography } from "@mui/joy";
import { DialogTitle, Grid, InputAdornment, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Article,
  CloudUpload,
  CloudUploadOutlined,
  CloudUploadTwoTone,
  Image,
  PictureAsPdf,
} from "@mui/icons-material";
import { subString } from "../../../utils";
import {
  onboardMandateFileCor,
  onboardMandateFileCorpStrategy,
  onboardMandateFileFinStatement,
  onboardMandateFileRegCert,
  setMandate,
  setMandateFile,
  setMandateFileCor,
  setMandateFileCorporate,
  setMandateFileFinancial,
  setMandateFileRegCert,
  setPasswordErrorDetails,
} from "../../../features/partner/partnerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";

function Mandate() {
  const dispatch = useDispatch();
  const {
    mandate,
    institution,
    mandateCor,
    mandateRegCert,
    mandateFinStatement,
    mandateCorpStrategy,
    errorPassword,
  } = useSelector((state) => state.partner);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState(mandate);
  const {
    motherCompany,
    accMandate,
    accWebsite,
    accSdgs,
    usrEncryptedPassword,
    confirmPassword,
  } = formData;
  const [file, setFile] = useState(undefined);

  const handleValidation = (evnt) => {
		const passwordInputValue = evnt.target.value.trim();
		const passwordInputFieldName = evnt.target.name;

		//for password
		if (passwordInputFieldName === 'usrEncryptedPassword') {
			const uppercaseRegExp = /(?=.*?[A-Z])/;
			const lowercaseRegExp = /(?=.*?[a-z])/;
			const digitsRegExp = /(?=.*?[0-9])/;
			const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
			const minLengthRegExp = /.{8,}/;

			const passwordLength = passwordInputValue.length;
			const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
			const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
			const digitsPassword = digitsRegExp.test(passwordInputValue);
			const specialCharPassword = specialCharRegExp.test(passwordInputValue);
			const minLengthPassword = minLengthRegExp.test(passwordInputValue);

			let errMsg = '';
			if (passwordLength === 0) {
				errMsg = 'Password is empty';
			} else if (!uppercasePassword) {
				errMsg = 'At least one uppercase';
			} else if (!lowercasePassword) {
				errMsg = 'At least one lowercase';
			} else if (!digitsPassword) {
				errMsg = 'At least one digit';
			} else if (!specialCharPassword) {
				errMsg = 'At least one special character';
			} else if (!minLengthPassword) {
				errMsg = 'Minumum of 8 characters';
			} else {
				errMsg = '';
			}
			setPasswordErr(errMsg);
		}

		// for confirm password
		if (
			passwordInputFieldName === 'confirmPassword' ||
			(passwordInputFieldName === 'usrEncryptedPassword' &&
				confirmPassword.length > 0)
		) {
			if (confirmPassword.length >= usrEncryptedPassword.length && confirmPassword !== usrEncryptedPassword) {
				setConfirmPasswordError('Password does not match');
			} else {
				setConfirmPasswordError('');
			}
		}
	};

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);
	const handleMouseDownConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);
	const [passwordError, setPasswordErr] = useState(errorPassword);
	const [confirmPasswordError, setConfirmPasswordError] = useState('');

	const [fileCor, setFileCor] = useState(mandateCor);
	const { nameCor, dateCor, file_base64Cor, typeCor } = fileCor;

	const [fileRegCert, setFileRegCert] = useState(mandateRegCert);
	const { nameRegCert, dateRegCert, file_base64RegCert, typeRegCert } =
		fileRegCert;

	const [fileFinStatement, setFileFinStatement] = useState(mandateFinStatement);
	const {
		nameFinStatement,
		dateFinStatement,
		file_base64FinStatement,
		typeFinStatement,
	} = fileFinStatement;

	const [fileCorpStrategy, setFileCorpStrategy] = useState(mandateCorpStrategy);
	const {
		nameCorpStrategy,
		dateCorpStrategy,
		file_base64CorpStrategy,
		typeCorpStrategy,
	} = fileCorpStrategy;

	const current = new Date();
	const year = current.getFullYear();
	const month = current.toLocaleString('default', { month: 'short' });
	const day = ('0' + current.getDate()).slice(-2);
	const _date = `${month} ${day},${year}`;

	// typeA
	const [error, setError] = useState('');
	const [corCert, setCorCert] = useState(undefined);
	const handleCor = (e) => {
		const _imgArr = [];
		const _file = e.target.files[0];
		if (!_file) return;
		const allowedExtensions = /(\.pdf|\.(jpe?g|png|gif))$/i;
		const MAX_FILE_SIZE = 1e7; //10mb
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setError('Please upload a PDF file or Image not more than 10mb ');
			setCorCert('');
			return;
		}
		setFile(_file);
		setError('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameCor: _file.name,
				dateCor: _date,
				typeCor: _file.type,
				file_base64Cor: base64String.toString(),
			};
			setFileCor(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onboardMandateFileCor(formData));
	};

	const removeCor = () => {
		setFileCor({
			nameCor: '',
			dateCor: '',
			typeCor: '',
			file_base64Cor: undefined,
		});
		setIsUpdating(true);
	};

	// type B
	const [regCert, setRegCert] = useState('');
	const [financialStmnt, setFinancialStmnt] = useState(undefined);
	const [corpStrategy, setCorpStrategy] = useState(undefined);
	const [errorFinancialStmnt, setErrorFinancialStmnt] = useState('');
	const [errorCorpStrategy, setErrorCorpStrategy] = useState('');

	const handleFile = (e) => {
		setFile(e.target.files[0]);
		setIsUpdating(true);
	};

	const removeSelectedImage = () => {
		setFile('');
	};

	const handleRegCert = (e) => {
		const _imgArr = [];
		const _file = e.target.files[0];
		if (!_file) return;
		const allowedExtensions = /(\.pdf|\.(jpe?g|png|gif))$/i;
		const MAX_FILE_SIZE = 1e7; //10mb
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setError('Please upload a PDF file or Image not more than 10mb ');
			setRegCert('');
			return;
		}
		setFile(_file);
		setError('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameRegCert: _file.name,
				dateRegCert: _date,
				typeRegCert: _file.type,
				file_base64RegCert: base64String.toString(),
			};
			setFileRegCert(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onboardMandateFileRegCert(formData));
	};

	const removeRegCert = () => {
		setFileRegCert({
			nameRegCert: '',
			dateRegCert: '',
			typeRegCert: '',
			file_base64RegCert: undefined,
		});
		setIsUpdating(true);
	};

	const handleFinancialStmnt = (e) => {
		const _imgArr = [];
		const _file = e.target.files[0];
		if (!_file) return;
		const allowedExtensions = /(\.xlsx|\.csv|\.xls)$/i;
		const MAX_FILE_SIZE = 1e7; //10mb
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setErrorFinancialStmnt(
				'Please upload a CSV or Excel file not more than 10mb ',
			);
			setFinancialStmnt('');
			return;
		}
		setFile(_file);
		setError('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameFinStatement: _file.name,
				dateFinStatement: _date,
				typeFinStatement: _file.type,
				file_base64FinStatement: base64String.toString(),
			};
			setFileFinStatement(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onboardMandateFileFinStatement(formData));
	};

  const removeFinancialStmnt = () => {
    setFileFinStatement({
      nameFinStatement: "",
      dateFinStatement: "",
      typeFinStatement: "",
      file_base64FinStatement: undefined,
    });
    setIsUpdating(true);
  };

  const handleCorpStrategy = (e) => {
    const _imgArr = [];
    const _file = e.target.files[0];
    if (!_file) return;
    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.ppt|\.pptx)$/i;
    const MAX_FILE_SIZE = 1e7; //10mb
    if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
      setErrorCorpStrategy(
        "Please upload a PDF , doc or PowerPoint file not more than 10mb |"
      );
      setCorpStrategy("");
      return;
    }
    setFile(_file);
    setError("");
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      let imageObj = {
        nameCorpStrategy: _file.name,
        dateCorpStrategy: _date,
        typeCorpStrategy: _file.type,
        file_base64CorpStrategy: base64String.toString(),
      };
      setFileCorpStrategy(imageObj);
      setIsUpdating(true);
    };
    reader.readAsDataURL(_file);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(onboardMandateFileCorpStrategy(formData));
  };

  const removeCorpStrategy = () => {
    setFileCorpStrategy({
      nameCorpStrategy: "",
      dateCorpStrategy: "",
      typeCorpStrategy: "",
      file_base64CorpStrategy: undefined,
    });
    setIsUpdating(true);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };

  const isMobile = useMediaQuery({ query: "(max-width:480px)" });

  useEffect(() => {
    if (!isUpdating) {
    } else {
      dispatch(setMandate(formData));
      dispatch(setMandateFileCor(fileCor));
      dispatch(setMandateFileRegCert(fileRegCert));
      dispatch(setMandateFileFinancial(fileFinStatement));
      dispatch(setMandateFileCorporate(fileCorpStrategy));
      dispatch(setPasswordErrorDetails(passwordError));
    }
  }, [
    dispatch,
    isUpdating,
    formData,
    fileCor,
    fileRegCert,
    fileFinStatement,
    fileCorpStrategy,
    passwordError,
  ]);

  return (
    <>
      {institution.institutionType == "Foundations" ||
        institution.institutionType == "Trust" ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                label=""
                onChange={handleChange}
                value={motherCompany}
                name="motherCompany"
                placeholder="Name of the mother company *"
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                What is the mandate of the institution{" "}
                <span className="star">*</span>
              </Typography>
              <Textarea
                onChange={handleChange}
                value={accMandate}
                name="accMandate"
                minRows={3}
                size="lg"
                variant="outlined"
                placeholder="Write here"
                className="fmw-textarea"
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                onChange={handleChange}
                value={accWebsite}
                name="accWebsite"
                label=""
                placeholder="Website of the mother company *"
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                Describe the corporate strategy highlighting your social
                development goals (SDGs) <span className="star">*</span>
              </Typography>
              <Textarea
                onChange={handleChange}
                value={accSdgs}
                name="accSdgs"
                minRows={3}
                size="lg"
                variant="outlined"
                placeholder="Write here"
                className="fmw-textarea"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                Please attach the registration certificate{" "}
                <span className="star">*</span>
              </Typography>
              {!nameCor ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      // border: '1px solid var(--color-primary-light)',
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <label
                      className=""
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#A0A0A0",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                            width: "52px",
                          }}
                        >
                          <CloudUploadOutlined
                            sx={{
                              display: "flex",
                              fontSize: "27px",
                              justifyContent: "center",
                              alignItems: "center",
                              mx: "12px",
                              color: "#ffff",
                              backgroundColor: "#A0A0A0 !important",
                            }}
                          />
                        </div>
                        {error ? (
                          <Typography
                            component="h6"
                            className="upload-sub-label-error"
                          >
                            {error}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            className="upload-sub-label"
                          >
                            Click here to upload
                          </Typography>
                        )}
                      </div>
                      <input
                        type="file"
                        name="file"
                        value={nameCor}
                        accept=".pdf,image/*"
                        webkitdirectory
                        onChange={handleCor}
                        style={{ width: "0px", height: "0px" }}
                      />
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid var(--color-primary-light)",
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "inherit",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                        width: "52px",
                      }}
                    >
                      <span style={{ margin: "0px 6px" }}>
                        {typeCor === "application/pdf" ? (
                          <PictureAsPdf
                            sx={{
                              fontSize: "40px",
                              color: "#f44336",
                            }}
                          />
                        ) : typeCor === "image/png" ||
                          "image/jpeg" ||
                          "image/gif" ? (
                          <Image
                            sx={{
                              fontSize: "40px",
                              color: "#263238",
                            }}
                          />
                        ) : (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#1976d2",
                            }}
                          />
                        )}
                      </span>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          backgroundColor:
                            "var(--color-primary-light) !important",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        component="h6"
                        className="fmw-upload-typography"
                      >
                        {subString(nameCor, 25)}
                      </Typography>

                      <Typography
                        variant="caption"
                        className="upload-sub-label"
                      >
                        uploaded on {_date}
                      </Typography>
                    </div>

                    <div>
                      <IconButton
                        aria-label="close"
                        onClick={removeCor}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          mr: "6px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                What is the mandate of the institution{" "}
                <span className="star">*</span>
              </Typography>
              <Textarea
                onChange={handleChange}
                value={accMandate}
                name="accMandate"
                minRows={3}
                size="lg"
                variant="outlined"
                placeholder="Write here"
                className="fmw-textarea"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                Please attach the registration certificate{" "}
                <span className="star">*</span>
              </Typography>
              {!nameRegCert ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      // border: '1px solid var(--color-primary-light)',
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <label
                      className=""
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#A0A0A0",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                            width: "52px",
                          }}
                        >
                          <CloudUploadOutlined
                            sx={{
                              display: "flex",
                              fontSize: "27px",
                              justifyContent: "center",
                              alignItems: "center",
                              mx: "12px",
                              color: "#ffff",
                              backgroundColor: "#A0A0A0 !important",
                            }}
                          />
                        </div>

                        {error ? (
                          <Typography
                            component="h6"
                            className="upload-sub-label-error"
                          >
                            {error}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            className="upload-sub-label"
                          >
                            Click here to upload
                          </Typography>
                        )}
                      </div>
                      <input
                        type="file"
                        name="RegCert"
                        accept=".pdf,image/*"
                        webkitdirectory
                        onChange={handleRegCert}
                        style={{ width: "0px", height: "0px" }}
                      />
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid var(--color-primary-light)",
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "inherit",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                        width: "52px",
                      }}
                    >
                      <span style={{ margin: "0px 6px" }}>
                        {typeRegCert.type === "application/pdf" ? (
                          <PictureAsPdf
                            sx={{
                              fontSize: "40px",
                              color: "#f44336",
                            }}
                          />
                        ) : typeRegCert.type === "image/png" ||
                          "image/jpeg" ||
                          "image/gif" ? (
                          <Image
                            sx={{
                              fontSize: "40px",
                              color: "#263238",
                            }}
                          />
                        ) : (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#1976d2",
                            }}
                          />
                        )}
                      </span>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          backgroundColor:
                            "var(--color-primary-light) !important",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        component="h6"
                        className="fmw-upload-typography"
                      >
                        {subString(nameRegCert, 25)}
                      </Typography>

                      <Typography
                        variant="caption"
                        className="upload-sub-label"
                      >
                        uploaded on {_date}
                      </Typography>
                    </div>

                    <div>
                      <IconButton
                        aria-label="close"
                        onClick={removeRegCert}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          mr: "6px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                Please attach audited financial statements for the last 3 years{" "}
                <span className="star">*</span>
              </Typography>
              {!nameFinStatement ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      // border: '1px solid var(--color-primary-light)',
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <label
                      className=""
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#A0A0A0",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                            width: "52px",
                          }}
                        >
                          <CloudUploadOutlined
                            sx={{
                              display: "flex",
                              fontSize: "27px",
                              justifyContent: "center",
                              alignItems: "center",
                              mx: "12px",
                              color: "#ffff",
                              backgroundColor: "#A0A0A0 !important",
                            }}
                          />
                        </div>

                        {errorFinancialStmnt ? (
                          <Typography
                            component="h6"
                            className="upload-sub-label-error"
                          >
                            {errorFinancialStmnt}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            className="upload-sub-label"
                          >
                            Click here to upload
                          </Typography>
                        )}
                      </div>
                      <input
                        type="file"
                        name="financialStmnt"
                        accept=".xlsx,.csv,.xls"
                        webkitdirectory
                        onChange={handleFinancialStmnt}
                        style={{ width: "0px", height: "0px" }}
                      />
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid var(--color-primary-light)",
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "inherit",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                        width: "52px",
                      }}
                    >
                      <span style={{ margin: "0px 6px" }}>
                        {typeFinStatement === "text/csv" ? (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#4caf50",
                            }}
                          />
                        ) : (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#4caf50",
                            }}
                          />
                        )}
                      </span>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          backgroundColor:
                            "var(--color-primary-light) !important",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        component="h6"
                        className="fmw-upload-typography"
                      >
                        {subString(nameFinStatement, 25)}
                      </Typography>

                      <Typography
                        variant="caption"
                        className="upload-sub-label"
                      >
                        uploaded on {_date}
                      </Typography>
                    </div>

                    <div>
                      <IconButton
                        aria-label="close"
                        onClick={removeFinancialStmnt}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          mr: "6px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                sx={{ mb: ".5rem" }}
              >
                Please attach a corporate strategy highlighting your social
                development goals (SDGs) <span className="star">*</span>
              </Typography>
              {!nameCorpStrategy ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      // border: '1px solid var(--color-primary-light)',
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <label
                      className=""
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <div
                        className="mb-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "#F7F7F7",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#A0A0A0",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                            width: "52px",
                          }}
                        >
                          <CloudUploadOutlined
                            sx={{
                              display: "flex",
                              fontSize: "27px",
                              justifyContent: "center",
                              alignItems: "center",
                              mx: "12px",
                              color: "#ffff",
                              backgroundColor: "#A0A0A0 !important",
                            }}
                          />
                        </div>

                        {errorCorpStrategy ? (
                          <Typography
                            component="h6"
                            className="upload-sub-label-error"
                          >
                            {errorCorpStrategy}
                          </Typography>
                        ) : (
                          <Typography
                            variant="caption"
                            className="upload-sub-label"
                          >
                            Click here to upload
                          </Typography>
                        )}
                      </div>
                      <input
                        type="file"
                        name="corpStrategy"
                        accept=".pdf,.doc,.docx,.ppt,.pptx"
                        webkitdirectory
                        onChange={handleCorpStrategy}
                        style={{ width: "0px", height: "0px" }}
                      />
                    </label>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid var(--color-primary-light)",
                      width: isMobile ? "inherit" : "337px",
                      height: "46px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "inherit",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                        width: "52px",
                      }}
                    >
                      <span style={{ margin: "0px 6px" }}>
                        {typeCorpStrategy ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#bf360c",
                            }}
                          />
                        ) : typeCorpStrategy === "application/pdf" ? (
                          <PictureAsPdf
                            sx={{
                              fontSize: "40px",
                              color: "#f44336",
                            }}
                          />
                        ) : (
                          <Article
                            sx={{
                              fontSize: "40px",
                              color: "#1976d2",
                            }}
                          />
                        )}
                      </span>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                          backgroundColor:
                            "var(--color-primary-light) !important",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        component="h6"
                        className="fmw-upload-typography"
                      >
                        {subString(nameCorpStrategy, 25)}
                      </Typography>

                      <Typography
                        variant="caption"
                        className="upload-sub-label"
                      >
                        uploaded on {_date}
                      </Typography>
                    </div>

                    <div>
                      <IconButton
                        aria-label="close"
                        onClick={removeCorpStrategy}
                        sx={{
                          color: (theme) => theme.palette.grey[500],
                          mr: "6px",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={0} sx={{ mt: "2rem" }}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Password <span className="star">*</span>
          </Typography>
          <Input
            label=""
            value={usrEncryptedPassword}
            name="usrEncryptedPassword"
            placeholder="Create password *"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            onKeyUp={handleValidation}
            size="lg"
            className="fmw-input-joy"
            endDecorator={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {usrEncryptedPassword && passwordError && (
            <FormHelperText className="upload-sub-label-error">
              {passwordError}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>.
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Confirm password <span className="star">*</span>
          </Typography>
          <Input
            label=""
            value={confirmPassword}
            name="confirmPassword"
            placeholder="Confirm password *"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleChange}
            onKeyUp={handleValidation}
            size="lg"
            className="fmw-input-joy"
            endDecorator={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {confirmPassword && confirmPasswordError && (
            <FormHelperText className="upload-sub-label-error">
              {confirmPasswordError}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Mandate;
