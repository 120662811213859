import { Box, Button, Typography } from '@mui/joy'
import { Divider, Grid, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Termsheet() {
    const navigate = useNavigate();
    const handleProcessLoan = (e) => {
        navigate(`/project-development`)
    }
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography component="h3" style={{ fontSize: '20px' }}>
                        Refill stations: Update termsheet
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Divider />
                </Grid>

                <Grid item xs={8}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                        <Box sx={{ backgroundColor: '#475459' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Conditions
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Borrower
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        John Doe
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Loan amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Kes. 1,000,000
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Purpose
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Maximum loan amount as a percentage of cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        80%
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Minimum equity amount as a percentage of cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        20%
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Maximum loan tenure
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        1yr grace period + 5yr term loan
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Interest rate
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        20%
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Interest during grace period
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Basis of charging interest
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Loan repayment installations
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Mode of payment (loan repayment)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Loan disbursement
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Loan appraisal fee
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        KES. 5000
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Mode of payment (appraisal fee)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Other terms
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Contractual savings
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%' }}>
                                        Security of financial institutions
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Lorem ipsum dolor sit amet,
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                    </Paper>

                    <Grid item xs={12} style={{ paddingTop: '1rem', }}>
                        <Button className="submit-btn" sx={{ my: 2, font: 500 }} onClick={handleProcessLoan}>
                            Process loan
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Termsheet