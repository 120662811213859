import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import TarrifTable from "./TarrifTable";
import { useEffect } from "react";
import { useCallback } from "react";
import {
  fetchProjectScore,
  getbankInformation,
  postScoreStep,
  setProjectScore,
} from "../../../features/expression_demand/expressionDemandSlice";
import {
  cashConverter,
  dateConverter,
  formatImgPath,
  handleOpenDoc,
} from "../../../utils";
import { Textarea } from "@mui/joy";
import MatrixInput from "../components/MatrixInput";
import FileButton from "../../../components/button/FileButton";
import { toast } from "react-toastify";
import NoTableDataComponent from "./NoTableDataComponent";
import UserMatrix from "../components/UserMatrix";
import SingleComment from "../components/SingleComment";

const FinancialSummary = ({ data, status }) => {
  const { scores } = useSelector((state) => state.expressionDemandData);
  const stepName = "FINANCIAL_SUMMARY";
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");

  useEffect(() => {}, [matrixInfo]);

  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);
  const dispatch = useDispatch();

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };
  useEffect(() => {
    handleTotalScores();
  }, [total]);
  const {
    projHandCash,
    projBankCash,
    projTotalDebtors,
    projLiquidAssets,
    projLiabilities,
    projNetFinances,
    projFinDate,
    projAuditorName,
    projAuditorFirm,
    projAuditorTelephone,
    projAuditorEmail,
    projIncomeExpenditureFile,
    projBankStatementFile,
    projAudiAccFile3,
    projAudiAccFile,
    projAudiAccFile2,
    projAssets,
    projBillingSystem,
    projTariffSystem,
    projId,
  } = data;

  const { bankInformation, loading } = useSelector(
    (state) => state.expressionDemandData
  );

  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }
  // function createTable(bank, branch, accountNo, accountName) {
  //   return { bank, branch, accountNo, accountName };
  // }

  const fetchReduxData = () => {
    dispatch(getbankInformation(projId));
  };

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  // const updateMatrix = () => {
  //   setMatrixInfo({});
  //   setComment("Hi!");
  //   scores?.forEach((score) => {
  //     const { scScore, scMethod } = score;
  //     setMatrixInfo((prevMatrixInfo) => ({
  //       ...prevMatrixInfo,
  //       [scMethod]: scScore,
  //     }));
  //   });
  //   scores[0]?.sctComment === null
  //     ? setComment("")
  //     : setComment(scores[0]?.sctComment);
  // };
  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const tableOneData = [
    createData(
      "As on",
      projFinDate ? dateConverter(projFinDate) : "N/A",
      "projFinDate"
    ),
    createData(
      "Cash in hand (petty cash)",
      cashConverter(projHandCash),
      "projHandCash"
    ),
    createData(
      "Cash in bank account(s)",
      cashConverter(projBankCash),
      "projBankCash"
    ),
    createData(
      "Total debtors (Money owed to project)",
      cashConverter(projTotalDebtors),
      "projTotalDebtors"
    ),
    createData(
      "Total liquid assets [(a) + (b) + (c)]",
      cashConverter(projLiquidAssets),
      "projLiquidAssets"
    ),
    createData(
      "Liabilities(Money owed by project to others)",
      cashConverter(projLiabilities),
      "projLiabilities"
    ),
    createData(
      "Net financial position [(d) - (e)]",
      cashConverter(projNetFinances),
      "projNetFinances"
    ),
    createData(
      "Type of billing system",
      projBillingSystem,
      "projBillingSystem",
      5
    ),
  ];
  const auditorInfo = [
    createData("Name", projAuditorName ?? "N/A", "projAuditorName"),
    createData("Firm", projAuditorFirm ?? "N/A", "projAuditorFirm"),
    createData(
      "Telephone",
      projAuditorTelephone ?? "N/A",
      "projAuditorTelephone"
    ),
    createData("Email", projAuditorEmail ?? "N/A", "projAuditorEmail"),
  ];

  const matrixFields = [
    "projIndividualActiveConn",
    "projInstitutionCommercialConn",
    "projNumberWaterKisosks",
    "projIncomeExpenditureFile",
    "projAudiAccFile3",
    "projAudiAccFile2",
    "projAudiAccFile",
    "projBillingSystem",
  ];

  useEffect(() => {
    fetchReduxData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchReduxData();
    }, 1000);
  }, []);

  const attachments = [
    createData(
      "Most recent months financial data",
      projIncomeExpenditureFile,
      "projIncomeExpenditureFile",
      10
    ),
    createData("List of all assets owned", projAssets, "projAssets"),
    createData(
      "PDF of bank statements for last 12 months",
      projBankStatementFile,
      "projBankStatementFile"
    ),
    createData("First Audit Report", projAudiAccFile, "projAudiAccFile", 1),
    createData("Second audit report", projAudiAccFile2, "projAudiAccFile2", 2),
    createData("Third audit report", projAudiAccFile3, "projAudiAccFile3", 2),
  ];

  return (
    <>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Summary of financial positions
      </Typography>
      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {tableOneData.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                  textTransform: "capitalize",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                row.variableName === "projBillingSystem" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {status === "Viable" &&
                row.variableName === "projBillingSystem" && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {(status === "Viable" || status === "Partner") &&
                row.variableName === "projBillingSystem" && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Tariff system in place -{" "}
        {projTariffSystem === "Volumetric"
          ? "Volumetric (Based on volume consumed)"
          : "Flat rate (same rate every month)"}
      </Typography>
      <TarrifTable projectData={data} />
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Auditor contacts
      </Typography>

      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {auditorInfo?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
          {status === "Viable" && (
            <TableRow
              key={"AuditMatrix"}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                Audit report scores
              </TableCell>
              {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={"auditScores"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
              {status === "Viable" && (
                <UserMatrix
                  setMatrixInfo={setUserMatrixInfo}
                  matrixInfo={userMatrixInfo}
                  name={"auditScores"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
              {(status === "Viable" || status === "Partner") && (
                <SingleComment
                  setMatrixInfo={setUserComment}
                  matrixInfo={userComment}
                  name={"auditScores"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Bank information
      </Typography>
      {loading && bankInformation?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Bank
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Branch
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Account number
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Account name
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Attachment
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {bankInformation?.map((row) => (
              <TableRow
                key={row.dirId}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.bName}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.bBranch}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.bAccNo}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.bAccName}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                    textTransform: "capitalize",
                  }}
                >
                  <FileButton file={row.bFile} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && bankInformation?.length === 0 && <NoTableDataComponent />}

      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Financial attachements
      </Typography>
      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {attachments.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                  textTransform: "capitalize",
                }}
              >
                <FileButton file={row.description} />
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {(status === "Viable" || status === "Partner") &&
                matrixFields.includes(row.variableName) && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" ||
        status === "DEAL_SOURCING" ||
        status === "Viable" ||
        status === "Partner") && (
        <>
          <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
            Comments on above information
          </Typography>
          <Textarea
            value={comment}
            disabled={status !== "Viable"}
            onChange={(e) => setComment(e.target.value)}
            minRows={3}
            name="projSummary"
            placeholder={status !== "Viable" ? "N/A" : "write here..."}
            size="lg"
            color={`${
              status === "Partner"
            } ? "red !important" : "#646464 !important"`}
            className="fmw-textarea"
            sx={{ width: "100%" }}
          />
          {status === "Viable" && (
            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default FinancialSummary;
