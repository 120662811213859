import { useDispatch, useSelector } from 'react-redux';
import WspApplicationStep from './WspApplicationStep';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';
import OnboardWsp from '../onboard_wsp/OnboardWsp';
import WspAppSkeleton from '../../components/wsp_application/WspAppSkeleton';

export default function WspNewHome() {
	const { user } = useSelector((state) => state.auth);
	const { wspApplications } = useSelector((state) => state.wsp);
	const dispatch =  useDispatch()

	
 
	const processedWspApplications = wspApplications[0]
	// async function fetchWspDetails() {
	// 	await dispatch(fetchWspApplications());
	// }
	// useEffect(()=>{
	// 	if (user.usrJbrnId === 1) {
	// 		fetchWspDetails();
	// 	  }
	// },[])
	
	return (
		<> 
		{
			wspApplications && wspApplications?.length > 0	? (
				<>
				{
					processedWspApplications?.projStatus === 'DRAFT'  ? 
					( 
						<OnboardWsp/>
						):(
							<div className='px-[35px]'>
							<div className='p-3'>
								<h1 className='home-header'>Welcome, {user?.usrFullNames?.split(" ")[0]}.</h1>
			
								<div className='w-full h-[1px] bg-[#F4F4F4] my-[33px]'></div>
			
								<h3 className='home-header-text'>Your application status</h3>
							</div>
			
							<div className='grid grid-cols-3 mt-[28px] h-[90vh] mb-24'>
								<div className='bg-[#226CBF] h-full'>
									<WspApplicationStep  wspApplications={processedWspApplications}/>
								</div>
			
								<div className='col-span-2 flex flex-col justify-center items-center bg-[#F0F5FF]'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='171'
										height='162'
										viewBox='0 0 171 162'
										fill='none'
									>
										<path
											d='M0 80.7357C0 102.148 8.50606 122.684 23.6469 137.824C38.7878 152.965 59.3233 161.471 80.7357 161.471C102.148 161.471 122.684 152.965 137.824 137.824C152.965 122.684 161.471 102.148 161.471 80.7357C161.471 59.3233 152.965 38.7878 137.824 23.6469C122.684 8.50606 102.148 0 80.7357 0C59.3233 0 38.7878 8.50606 23.6469 23.6469C8.50606 38.7878 0 59.3233 0 80.7357Z'
											fill='#D9EDFF'
										/>
										<path
											d='M118.813 131.813H43.5267C42.1787 131.813 40.886 131.278 39.9329 130.325C38.9798 129.372 38.4443 128.079 38.4443 126.731V29.267C38.4443 27.8618 39.0025 26.5142 39.9962 25.5206C40.9898 24.527 42.3374 23.9688 43.7426 23.9688H118.585C119.99 23.9687 121.337 24.527 122.331 25.5206C123.325 26.5142 123.883 27.8618 123.883 29.267V126.731C123.883 128.077 123.35 129.368 122.399 130.321C121.449 131.274 120.159 131.811 118.813 131.813Z'
											fill='#B0D9FF'
										/>
										<path
											d='M118.813 131.813H43.5267C42.1787 131.813 40.886 131.278 39.9329 130.325C38.9798 129.372 38.4443 128.079 38.4443 126.731V29.267C38.4443 27.8618 39.0025 26.5142 39.9962 25.5206C40.9898 24.527 42.3374 23.9688 43.7426 23.9688H118.585C119.99 23.9687 121.337 24.527 122.331 25.5206C123.325 26.5142 123.883 27.8618 123.883 29.267V126.731C123.883 128.077 123.35 129.368 122.399 130.321C121.449 131.274 120.159 131.811 118.813 131.813Z'
											stroke='#020064'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M46.8525 32.3711H115.482V123.411H46.8525V32.3711Z'
											fill='white'
										/>
										<path
											d='M115.481 63.5547L95.5517 85.1515C94.2948 86.5171 93.6314 88.3257 93.7071 90.1801C93.7828 92.0345 94.5914 93.783 95.9553 95.0416C96.8112 95.8306 97.8527 96.3902 98.9829 96.6684L92.5039 103.149L93.4949 104.141L84.7432 112.89C84.3513 113.28 84.0835 113.778 83.9733 114.319C83.8631 114.861 83.9154 115.423 84.1237 115.936C84.332 116.448 84.687 116.887 85.1441 117.198C85.6012 117.509 86.1401 117.678 86.6929 117.684C86.8964 117.683 87.0992 117.663 87.2985 117.621C94.9684 115.991 98.8699 113.635 98.9143 110.611L99.4431 110.081L101.425 112.063L106.3 107.174C106.645 107.451 107.015 107.695 107.406 107.903C108.695 108.589 110.166 108.855 111.613 108.664C113.061 108.473 114.413 107.834 115.479 106.837L115.481 63.5547Z'
											fill='#B0D9FF'
										/>
										<path
											d='M46.8525 32.3711H115.482V123.411H46.8525V32.3711Z'
											stroke='#020064'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M56.6328 54.7812H99.4288'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M56.6328 67.3828H99.4288'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M56.6328 81.3906H86.4606'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M61.9972 19.7656H100.335C101.007 19.7678 101.651 20.0364 102.126 20.5128C102.601 20.9891 102.868 21.6342 102.868 22.3068V35.4263C102.868 36.1008 102.6 36.7477 102.123 37.2246C101.646 37.7016 100.999 37.9695 100.324 37.9695H61.9992C61.3247 37.9695 60.6779 37.7016 60.2009 37.2246C59.724 36.7477 59.4561 36.1008 59.4561 35.4263V22.3068C59.4561 21.6328 59.7238 20.9865 60.2003 20.5099C60.6769 20.0334 61.3233 19.7656 61.9972 19.7656Z'
											fill='white'
										/>
										<path
											d='M61.9972 19.7656H100.335C101.007 19.7678 101.651 20.0364 102.126 20.5128C102.601 20.9891 102.868 21.6342 102.868 22.3068V35.4263C102.868 36.1008 102.6 36.7477 102.123 37.2246C101.646 37.7016 100.999 37.9695 100.324 37.9695H61.9992C61.3247 37.9695 60.6779 37.7016 60.2009 37.2246C59.724 36.7477 59.4561 36.1008 59.4561 35.4263V22.3068C59.4561 21.6328 59.7238 20.9865 60.2003 20.5099C60.6769 20.0334 61.3233 19.7656 61.9972 19.7656V19.7656Z'
											stroke='#020064'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M69.2598 32.3711H93.0707'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M74.8623 18.3671C74.8623 19.2869 75.0435 20.1976 75.3954 21.0473C75.7474 21.8971 76.2633 22.6692 76.9137 23.3196C77.564 23.9699 78.3361 24.4858 79.1859 24.8378C80.0356 25.1898 80.9464 25.3709 81.8661 25.3709C82.7859 25.3709 83.6966 25.1898 84.5464 24.8378C85.3961 24.4858 86.1682 23.9699 86.8186 23.3196C87.4689 22.6692 87.9848 21.8971 88.3368 21.0473C88.6888 20.1976 88.87 19.2869 88.87 18.3671C88.87 17.4473 88.6888 16.5366 88.3368 15.6869C87.9848 14.8371 87.4689 14.065 86.8186 13.4147C86.1682 12.7643 85.3961 12.2484 84.5464 11.8964C83.6966 11.5444 82.7859 11.3633 81.8661 11.3633C80.9464 11.3633 80.0356 11.5444 79.1859 11.8964C78.3361 12.2484 77.564 12.7643 76.9137 13.4147C76.2633 14.065 75.7474 14.8371 75.3954 15.6869C75.0435 16.5366 74.8623 17.4473 74.8623 18.3671Z'
											fill='white'
										/>
										<path
											d='M74.8623 18.3671C74.8623 19.2869 75.0435 20.1976 75.3954 21.0473C75.7474 21.8971 76.2633 22.6692 76.9137 23.3196C77.564 23.9699 78.3361 24.4858 79.1859 24.8378C80.0356 25.1898 80.9464 25.3709 81.8661 25.3709C82.7859 25.3709 83.6966 25.1898 84.5464 24.8378C85.3961 24.4858 86.1682 23.9699 86.8186 23.3196C87.4689 22.6692 87.9848 21.8971 88.3368 21.0473C88.6888 20.1976 88.87 19.2869 88.87 18.3671C88.87 17.4473 88.6888 16.5366 88.3368 15.6869C87.9848 14.8371 87.4689 14.065 86.8186 13.4147C86.1682 12.7643 85.3961 12.2484 84.5464 11.8964C83.6966 11.5444 82.7859 11.3633 81.8661 11.3633C80.9464 11.3633 80.0356 11.5444 79.1859 11.8964C78.3361 12.2484 77.564 12.7643 76.9137 13.4147C76.2633 14.065 75.7474 14.8371 75.3954 15.6869C75.0435 16.5366 74.8623 17.4473 74.8623 18.3671V18.3671Z'
											stroke='#020064'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M110.652 80.521L135.186 89.6724L145.506 73.1216L118.831 65.918L110.652 80.521Z'
											fill='#D9EDFF'
										/>
										<path
											d='M149.766 108.446L168.767 89.4453C168.842 89.3737 168.92 89.3064 169.002 89.2435C162.642 83.7252 161.073 72.0347 148.842 65.6485C132.741 57.2398 112.828 59.2461 95.3363 81.0892C94.9358 81.5646 94.6342 82.1151 94.4492 82.7085C94.2641 83.3018 94.1993 83.9262 94.2586 84.5449C94.3178 85.1637 94.4999 85.7644 94.7942 86.3119C95.0885 86.8594 95.4891 87.3427 95.9725 87.7334C96.4559 88.1242 97.0124 88.4145 97.6094 88.5875C98.2065 88.7605 98.832 88.8127 99.4494 88.7409C100.067 88.6691 100.664 88.4749 101.205 88.1695C101.747 87.8642 102.222 87.4539 102.602 86.9627C103.762 85.5283 104.928 84.1928 106.098 82.9562C106.51 83.3955 107.003 83.7506 107.55 84.0017C125.85 97.858 129.55 99.618 134.027 101.36C140.95 104.05 144.006 105.35 149.096 109.958C149.127 109.389 149.365 108.851 149.766 108.446ZM137.228 78.871C135.686 80.7367 133.54 82.0048 131.162 82.4559C128.784 82.9071 126.323 82.5128 124.205 81.3415L113.383 76.3924C119.698 71.7702 126.07 69.8407 132.416 70.6682C137.815 71.3625 139.065 76.1381 137.228 78.871Z'
											fill='#B06C49'
										/>
										<path
											d='M50.3418 148.027C50.3418 148.957 53.542 149.848 59.2384 150.505C64.9347 151.162 72.6607 151.531 80.7166 151.531C88.7725 151.531 96.4984 151.162 102.195 150.505C107.891 149.848 111.091 148.957 111.091 148.027C111.091 147.098 107.891 146.207 102.195 145.55C96.4984 144.893 88.7725 144.523 80.7166 144.523C72.6607 144.523 64.9347 144.893 59.2384 145.55C53.542 146.207 50.3418 147.098 50.3418 148.027Z'
											fill='#B0D9FF'
										/>
										<path
											d='M60.9096 95.9453C60.1854 96.669 59.6509 97.5601 59.3535 98.5398C59.056 99.5194 59.0048 100.557 59.2043 101.561C59.4038 102.566 59.8479 103.505 60.4973 104.296C61.1467 105.088 61.9812 105.707 62.927 106.099C63.8729 106.491 64.9007 106.643 65.9196 106.543C66.9385 106.443 67.9168 106.093 68.7681 105.524C69.6193 104.955 70.3171 104.185 70.7996 103.282C71.2822 102.379 71.5345 101.371 71.5344 100.347V101.591C71.5211 102.253 71.64 102.912 71.8843 103.528C72.1286 104.144 72.4933 104.705 72.9571 105.178C73.4209 105.651 73.9745 106.027 74.5854 106.284C75.1962 106.54 75.8522 106.672 76.5148 106.672C77.1774 106.672 77.8333 106.54 78.4442 106.284C79.0551 106.027 79.6086 105.651 80.0724 105.178C80.5362 104.705 80.9009 104.144 81.1452 103.528C81.3895 102.912 81.5085 102.253 81.4952 101.591C81.4952 102.911 82.0198 104.178 82.9536 105.112C83.8874 106.046 85.1539 106.57 86.4745 106.57H90.2086'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M122.668 82.4285C123.281 82.4613 123.882 82.6145 124.436 82.8795C124.989 83.1445 125.486 83.5159 125.896 83.9727C126.306 84.4295 126.623 84.9626 126.827 85.5416C127.031 86.1207 127.119 86.7342 127.086 87.3473L126.265 102.514C126.231 103.126 126.078 103.727 125.813 104.28C125.547 104.833 125.176 105.329 124.719 105.739C124.262 106.149 123.729 106.465 123.15 106.668C122.571 106.872 121.958 106.96 121.345 106.927C120.732 106.893 120.132 106.74 119.578 106.475C119.025 106.209 118.529 105.838 118.119 105.381C117.71 104.924 117.394 104.391 117.19 103.812C116.986 103.233 116.898 102.62 116.932 102.007L117.753 86.8427C117.82 85.6058 118.375 84.4459 119.297 83.6182C120.218 82.7904 121.431 82.3625 122.668 82.4285Z'
											fill='#D9EDFF'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M117.841 80.974C118.934 81.5569 119.752 82.5503 120.113 83.7357C120.475 84.9211 120.351 86.2016 119.768 87.2956L114.191 98.1505C113.609 99.2444 112.615 100.062 111.43 100.424C110.244 100.785 108.964 100.661 107.87 100.078C106.776 99.4954 105.958 98.502 105.597 97.3164C105.235 96.1309 105.36 94.8503 105.942 93.7564L111.519 82.9015C112.102 81.8079 113.095 80.9905 114.281 80.629C115.466 80.2676 116.747 80.3916 117.841 80.974Z'
											fill='#D9EDFF'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M168.767 89.4453C168.842 89.3737 168.92 89.3064 169.002 89.2435C162.642 83.7252 161.073 72.0347 148.842 65.6485C132.741 57.2398 112.828 59.2461 95.3363 81.0892C94.9358 81.5646 94.6342 82.1151 94.4492 82.7085C94.2641 83.3018 94.1993 83.9262 94.2586 84.5449C94.3178 85.1637 94.4999 85.7644 94.7942 86.3119C95.0885 86.8594 95.4891 87.3427 95.9725 87.7334C96.4559 88.1242 97.0124 88.4145 97.6094 88.5875C98.2065 88.7605 98.832 88.8127 99.4494 88.7409C100.067 88.6691 100.664 88.4749 101.205 88.1695C101.747 87.8642 102.222 87.4539 102.602 86.9627C103.762 85.5283 104.928 84.1928 106.098 82.9562C106.51 83.3955 107.003 83.7506 107.55 84.0017C125.85 97.858 129.55 99.618 134.027 101.36C140.95 104.05 144.006 105.35 149.096 109.958L168.767 89.4453ZM137.236 78.875C135.693 80.742 133.546 82.0106 131.166 82.461C128.786 82.9115 126.324 82.5154 124.205 81.3415L113.383 76.3924C119.698 71.7702 126.07 69.8407 132.416 70.6682C137.815 71.3625 139.065 76.1381 137.228 78.871L137.236 78.875Z'
											stroke='#2F1A15'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M133.179 79.8011L127.088 71.229L146.061 57.7522C146.571 57.3895 147.148 57.1309 147.759 56.9912C148.369 56.8515 149.001 56.8335 149.618 56.9381C150.236 57.0426 150.827 57.2678 151.357 57.6008C151.887 57.9337 152.347 58.3679 152.709 58.8785L153.277 59.6757C153.639 60.1862 153.898 60.7631 154.038 61.3736C154.177 61.984 154.195 62.6159 154.091 63.2333C153.986 63.8508 153.761 64.4415 153.428 64.9719C153.095 65.5022 152.661 65.9618 152.15 66.3243L133.179 79.8011Z'
											fill='#B0D9FF'
										/>
										<path
											d='M133.179 79.8011L127.088 71.229L146.061 57.7522C146.571 57.3895 147.148 57.1309 147.759 56.9912C148.369 56.8515 149.001 56.8335 149.618 56.9381C150.236 57.0426 150.827 57.2678 151.357 57.6008C151.887 57.9337 152.347 58.3679 152.709 58.8785L153.277 59.6757C153.639 60.1862 153.898 60.7631 154.038 61.3736C154.177 61.984 154.195 62.6159 154.091 63.2333C153.986 63.8508 153.761 64.4415 153.428 64.9719C153.095 65.5022 152.661 65.9618 152.15 66.3243L133.179 79.8011Z'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M127.92 66.3412L141.255 56.8689C141.627 56.5926 142.051 56.3945 142.501 56.2863C142.951 56.1782 143.419 56.1623 143.875 56.2396C144.332 56.3169 144.768 56.4858 145.158 56.7362C145.547 56.9866 145.882 57.3132 146.142 57.6964'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M97.2572 95.2812L87.8899 101.942C87.6829 102.086 87.5282 102.293 87.4489 102.533C87.3695 102.772 87.3699 103.031 87.4499 103.27C87.5299 103.509 87.6853 103.716 87.8927 103.859C88.1001 104.002 88.3483 104.075 88.6003 104.065C92.5281 103.904 98.1413 103.191 98.0585 100.45L99.9639 99.098L97.2572 95.2812Z'
											fill='#B0D9FF'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M100.647 100.049L97.2642 95.2851C97.085 95.0324 97.0135 94.7189 97.0653 94.4135C97.1172 94.108 97.2882 93.8357 97.5408 93.6563L102.302 90.2734L107.039 96.9341L102.262 100.315C102.011 100.491 101.702 100.561 101.4 100.512C101.098 100.462 100.828 100.295 100.647 100.049Z'
											fill='white'
										/>
										<path
											d='M100.647 100.049L97.2642 95.2851C97.085 95.0324 97.0135 94.7189 97.0653 94.4135C97.1172 94.108 97.2882 93.8357 97.5408 93.6563L102.302 90.2734L107.039 96.9341L102.262 100.315C102.011 100.491 101.702 100.561 101.4 100.512C101.098 100.462 100.828 100.295 100.647 100.049V100.049Z'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M106.359 95.9862L102.976 91.2309C102.798 90.9808 102.672 90.6982 102.603 90.3991C102.534 90.1 102.525 89.7904 102.577 89.4878C102.628 89.1853 102.738 88.8958 102.901 88.6359C103.064 88.376 103.277 88.1508 103.527 87.9732L127.09 71.2266L133.179 79.7987L109.617 96.5392C109.367 96.717 109.084 96.8437 108.785 96.9121C108.486 96.9805 108.176 96.9892 107.873 96.9379C107.571 96.8865 107.281 96.776 107.021 96.6127C106.762 96.4494 106.537 96.2365 106.359 95.9862Z'
											fill='#B0D9FF'
										/>
										<path
											d='M106.359 95.9862L102.976 91.2308C102.798 90.9808 102.672 90.6982 102.603 90.3991C102.534 90.1 102.525 89.7904 102.577 89.4878C102.628 89.1853 102.738 88.8958 102.901 88.6359C103.064 88.376 103.277 88.1508 103.527 87.9732L127.09 71.2266L133.179 79.7987L109.617 96.5392C109.367 96.717 109.084 96.8437 108.785 96.9121C108.486 96.9805 108.176 96.9892 107.873 96.9379C107.571 96.8865 107.281 96.776 107.021 96.6127C106.762 96.4494 106.537 96.2365 106.359 95.9862Z'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M137.225 78.8715C135.684 80.7373 133.538 82.0054 131.16 82.4565C128.782 82.9076 126.321 82.5134 124.203 81.342L111.426 75.4887C110.867 75.2224 110.26 75.07 109.64 75.0405C109.021 75.0109 108.402 75.1049 107.82 75.3168C107.237 75.5287 106.703 75.8543 106.247 76.2748C105.792 76.6952 105.424 77.2021 105.167 77.7658C104.909 78.3296 104.766 78.9389 104.746 79.5585C104.726 80.1781 104.829 80.7954 105.05 81.3747C105.27 81.9539 105.604 82.4834 106.032 82.9325C106.459 83.3815 106.971 83.741 107.539 83.9902C125.84 97.8485 129.539 99.6065 134.016 101.348'
											fill='white'
										/>
										<path
											d='M137.225 78.8715C135.684 80.7373 133.538 82.0054 131.16 82.4565C128.782 82.9076 126.321 82.5134 124.203 81.342L111.426 75.4887C110.867 75.2224 110.26 75.07 109.64 75.0405C109.021 75.0109 108.402 75.1049 107.82 75.3168C107.237 75.5287 106.703 75.8543 106.247 76.2748C105.792 76.6952 105.424 77.2021 105.167 77.7658C104.909 78.3296 104.766 78.9389 104.746 79.5585C104.726 80.1781 104.829 80.7954 105.05 81.3747C105.27 81.9539 105.604 82.4834 106.032 82.9325C106.459 83.3815 106.971 83.741 107.539 83.9902C125.84 97.8485 129.539 99.6065 134.016 101.348'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M137.225 78.8715C135.684 80.7373 133.538 82.0054 131.16 82.4565C128.782 82.9076 126.321 82.5134 124.203 81.342L111.426 75.4887C110.867 75.2224 110.26 75.07 109.64 75.0405C109.021 75.0109 108.402 75.1049 107.82 75.3168C107.237 75.5287 106.703 75.8543 106.247 76.2748C105.792 76.6952 105.424 77.2021 105.167 77.7658C104.909 78.3296 104.766 78.9389 104.746 79.5585C104.726 80.1781 104.829 80.7954 105.05 81.3747C105.27 81.9539 105.604 82.4834 106.032 82.9325C106.459 83.3815 106.971 83.741 107.539 83.9902C125.84 97.8485 129.539 99.6065 134.016 101.348'
											fill='#B06C49'
										/>
										<path
											d='M137.225 78.8715C135.684 80.7373 133.538 82.0054 131.16 82.4565C128.782 82.9076 126.321 82.5134 124.203 81.342L111.426 75.4887C110.867 75.2224 110.26 75.07 109.64 75.0405C109.021 75.0109 108.402 75.1049 107.82 75.3168C107.237 75.5287 106.703 75.8543 106.247 76.2748C105.792 76.6952 105.424 77.2021 105.167 77.7658C104.909 78.3296 104.766 78.9389 104.746 79.5585C104.726 80.1781 104.829 80.7954 105.05 81.3747C105.27 81.9539 105.604 82.4834 106.032 82.9325C106.459 83.3815 106.971 83.741 107.539 83.9902C125.84 97.8485 129.539 99.6065 134.016 101.348'
											stroke='#020064'
											stroke-width='4'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>
			
									<p className='review mt-10'>Your application is under review</p>
									<p className='review-text mt-2'>
										We will notify you once it has been approved
									</p>
								</div>
							</div>
						</div>
						)
					} 
					</>
			):(
				<WspAppSkeleton/>
				
			)
		}
		
		</>
	);
}
