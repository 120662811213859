import { Button, Card } from "@mui/joy";
import React, { useState } from "react";
import HistoryIcon from "@mui/icons-material/History";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box } from "@mui/system";
import Events from "./Events";

const Interactions = () => {
  const [tab, setTab] = useState(1);
  return (
    <Card
      sx={{
        display: "flex",
        width: "100%",
        my: 3,
        border: 1,
        borderColor: "#E4E4E4",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Button
          sx={{
            display: "flex",
            width: "50%",
            gap: 1,
            justifyContent: "start",
            borderRadius: 0,
            borderBottom: tab === 1 ? 2 : 0,
            color: tab === 1 ? "#0069DF" : "#475459",
          }}
          variant="plain"
          onClick={() => setTab(1)}
        >
          <HistoryIcon />
          Events
        </Button>
        <Button
          sx={{
            display: "flex",
            width: "50%",
            gap:1,
            borderRadius: 0,
            justifyContent: "start",
            color: tab === 2 ? "#0069DF" : "#475459",
            borderBottom: tab === 2 ? 2 : 0,
          }}
          variant="plain"
          onClick={() => setTab(2)}
        >
          <GroupAddIcon />
          Interactions
        </Button>
      </Box>
      {tab === 1 ? (
        <Events />
      ) : (
        <div className="flex  items-center justify-center h-[50vh]">
          Here are the Interactions
        </div>
      )}
    </Card>
  );
};

export default Interactions;
