import * as React from 'react';
import {
	People,
	BarChart,
	Layers,
	Campaign,
	Home,
	Chat,
  Circle,
  ExpandLess,
  ExpandMore,
  Dashboard,
} from '@mui/icons-material';
import {
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
  List,
  Collapse,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import ExtensionIcon from '@mui/icons-material/Extension';
import { useState } from 'react';
import { useEffect } from 'react';

const MainListItems = () => {
	const [nlOpen1, setnlOpen1] = useState(false);
	const toggleNestedList1 = () => {
		setnlOpen1(!nlOpen1);
	};

    const nlOpen1Urls = ['/offers', '/Letter of Intent']


	const toggleList = () => {
		setnlOpen1(false);
	  };

    useEffect(() => {
        if (nlOpen1Urls.includes(window.location.pathname)) {
            setnlOpen1(!nlOpen1);
        }
        else{
            setnlOpen1(false);
        }
    }, [])

	return (
		<React.Fragment>
			<NavLink
				to='/wsp-home'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton className='lib-active'>
					<ListItemIcon>
						<Home />
					</ListItemIcon>
					<ListItemText primary='Applications' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>

			<NavLink
				to='/timelines'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton>
					<ListItemIcon>
						<People />
					</ListItemIcon>
					<ListItemText primary='Timelines' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>

			<NavLink
				to='/messages'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton className=''>
					<ListItemIcon>
						<Chat />
					</ListItemIcon>
					<ListItemText primary='Messages' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>

      <ListItemButton
				className={nlOpen1 ? `is-expandable lib-active  ${nlOpen1 && 'expanded'}` : `is-expandable  ${nlOpen1 && 'expanded'}`}
				onClick={toggleNestedList1}
			>
				<ListItemIcon>
					<Dashboard />
				</ListItemIcon>
				<ListItemText primary='Offers' />
				{nlOpen1 ? <ExpandLess /> : <ExpandMore />}
				<Typography variant='span' className='indicator' />
			</ListItemButton>
			<Collapse
				in={nlOpen1}
				timeout='auto'
				unmountOnExit
				className={`collapsible-menu ${nlOpen1 && 'open'}`}
			>
				<List component='div' disablePadding>
					<NavLink
						to='/letter-of-intent'
						className={({ isActive }) => (isActive ? 'lib-active' : '')}
					>
						<ListItemButton className='' sx={{ pl: 2 }}>
							<ListItemIcon>
								<Circle />
							</ListItemIcon>
							<ListItemText primary='Letter of intent' />
						</ListItemButton>
					</NavLink>
					<NavLink
						to='/loan-offers'
						className={({ isActive }) => (isActive ? 'lib-active' : '')}
					>
						<ListItemButton>
							<ListItemIcon>
								<Circle />
							</ListItemIcon>
							<ListItemText primary='Loan offers' />
						</ListItemButton>
					</NavLink>
				</List>
			</Collapse>


			<NavLink
				to='/invoice-list'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton>
					<ListItemIcon>
						<People />
					</ListItemIcon>
					<ListItemText primary='Project Development' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>

			<NavLink
				to='/wsp-home'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton className=''>
					<ListItemIcon>
						<BarChart />
					</ListItemIcon>
					<ListItemText primary='Reports' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>

			{/* <NavLink to="/my-account" className={({ isActive }) => isActive ? 'lib-active' : ''}> */}
			<NavLink
				to='/advisor-account'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton className=''>
					<ListItemIcon>
						<Layers />
					</ListItemIcon>
					<ListItemText primary='My account' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>
			<NavLink
				to='/support'
				className={({ isActive }) => (isActive ? 'lib-active' : '')}
				onClick={toggleList}
			>
				<ListItemButton className='' sx={{ pl: 2 }}>
					<ListItemIcon>
						<Campaign />
					</ListItemIcon>
					<ListItemText primary='Support' />
					<Typography variant='span' className='indicator' />
				</ListItemButton>
			</NavLink>
		</React.Fragment>
	);
};

export default MainListItems;

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//     <Box
//           sx={{
//             mb: 1, mt: 1.5,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Typography
//             className="nav-list-text"
//             textColor="neutral.500"
//             fontWeight={700}
//             sx={{
//               fontSize: '10px',
//               textTransform: 'uppercase',
//               letterSpacing: '.1rem',
//             }}
//           >
//             Browse
//           </Typography>
//           <IconButton
//             size="sm"
//             variant="plain"
//             color="primary"
//             sx={{ '--IconButton-size': '24px' }}
//           >
//             <KeyboardArrowDownRounded fontSize="small" color="primary" />
//           </IconButton>
//         </Box>
//     </ListSubheader>

//     <ListItemButton>
//       <ListItemIcon>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );
