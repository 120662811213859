import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Skeleton,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useCallback } from "react";
import {
  fetchProjectScore,
  getBugetItemInformations,
  postScoreStep,
  setProjectScore,
} from "../../../features/expression_demand/expressionDemandSlice";
import { cashConverter, formatImgPath, handleOpenDoc } from "../../../utils";
import ScrollToBottom from "../../messages/scrollToBottom";
import { Textarea } from "@mui/joy";
import MatrixInput from "../components/MatrixInput";
import { toast } from "react-toastify";
import { fetchBudgetInfo } from "../../../features/onboard_partner/onboardPartnerSlice";
import NoTableDataComponent from "./NoTableDataComponent";
import UserMatrix from "../components/UserMatrix";
import SingleComment from "../components/SingleComment";

const LoanInfo = ({ data, status, loading }) => {
  const scores = useSelector((state) => state.expressionDemandData.scores);
  const { budgetInfo, isLoadingBudget } = useSelector(
    (state) => state.onboardPartner
  );

  const stepName = "LOAN_INFO";
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});

  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {}, [matrixInfo, scores, budgetInfo]);

  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const matrixFields = ["projOtherPartyFinance", "projBillingSystem"];

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  // const updateMatrix = () => {
  //   setMatrixInfo({});

  //   scores?.forEach((score) => {
  //     const { scScore, scMethod } = score;
  //     setMatrixInfo((prevMatrixInfo) => ({
  //       ...prevMatrixInfo,
  //       [scMethod]: scScore,
  //     }));
  //   });

  //   scores[0]?.sctComment === null
  //     ? setComment("")
  //     : setComment(scores[0]?.sctComment);
  // };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const {
    projProjCategory,
    projDescSubProj,
    projProbSolved,
    projWhoBenefit,
    projEstCost,
    projLoanAmount,
    projLoanYears,
    projMoneySource,
    projOtherPartyFinance,
    projInformGovYesFile,
    projInformGov,
    projWspInformed,
    projLoanSum,
    projLoanCalculated,
    projSswpSum,
    projSswpCalculated,
    proj3rdpartySum,
    proj3rdpartyCalculated,
    projTotalSum,
    projTotalCalculated,
    projWspInformFile,
    projId,
    projPurpose,
    projBillingSystem,
  } = data;
  function createTable(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  function budgetTable(item, budget, comment, variableName) {
    return { item, budget, comment, variableName };
  }

  const finacialDetails = [
    createTable(
      "Description of sub-project for which the loan is applied",
      projDescSubProj,
      "projDescSubProj"
    ),
    createTable("Problem being solved", projProbSolved, "projProbSolved"),
    createTable("Beneficiaries", projWhoBenefit, "projWhoBenefit"),
    createTable(
      "Total estimated project cost",
      projEstCost ? cashConverter(projEstCost) : "N/A",
      "projEstCost"
    ),
    createTable(
      "Amount of loan required",
      projLoanAmount ? cashConverter(projLoanAmount) : "N/A",
      "projLoanAmount"
    ),
    createTable("Purpose of the Project", projPurpose, "projPurpose"),
    // createTable(
    //   "Number of years for which loan is required ",
    //   `${projLoanYears ? projLoanYears` years` : "N/A"}`,
    //   "projLoanYears"
    // ),
    createTable(
      "How funds will be generated to repay the loan",
      projMoneySource,
      "projMoneySource"
    ),
    createTable(
      "Source of 3rd party financing",
      projOtherPartyFinance,
      "projOtherPartyFinance",
      10
    ),
    createTable(
      "Has county government been informed",
      projInformGov,
      "projInformGov"
    ),
  ];

  const fetchReduxData = () => {
    dispatch(
      fetchBudgetInfo({
        projId: projId,
      })
    );
  };

  console.log("projBillingSystem", projBillingSystem);

  useEffect(() => {
    fetchReduxData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchReduxData();
    }, 1000);
  }, []);

  useEffect(() => {
    console.log(
      "We have successfully found data =================, ",
      budgetInfo,
      "=================Budget data===============",
      scores
    );
  }, [budgetInfo, scores]);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const typeOfFinancing = [
    budgetTable("Loans", projLoanSum, projLoanCalculated, "projLoanSum"),
    budgetTable("SSP", projSswpSum, projSswpCalculated, "projSswpSum"),
    budgetTable(
      "3rd Party",
      proj3rdpartySum,
      proj3rdpartyCalculated,
      "proj3rdpartySum"
    ),
    budgetTable("Total", projTotalSum, projTotalCalculated, "projTotalSum"),
  ];

  return (
    <>
      {/* <ScrollToBottom /> */}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Type of financing
      </Typography>
      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {finacialDetails.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}

              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {(status === "Viable" || status === "Partner") &&
                matrixFields.includes(row.variableName) && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
          {projInformGov === "Yes" && (
            <TableRow
              key={"wwewecfKKKKKK"}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                county government Information File
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={() =>
                    handleOpenDoc(formatImgPath(projInformGovYesFile))
                  }
                >
                  <Typography sx={{ display: "flex", gap: 1 }}>
                    <AttachFileIcon
                      sx={{
                        transform: "rotate(45deg)",
                      }}
                    />
                    View File
                  </Typography>
                </Button>
              </TableCell>
              {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={"projInformGovYesFile"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                />
              )} */}
            </TableRow>
          )}
          {projWspInformed === "Yes" && (
            <TableRow
              key={"wwewecf"}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                WSP Informed
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={() =>
                    handleOpenDoc(formatImgPath(projWspInformFile))
                  }
                >
                  <Typography sx={{ display: "flex", gap: 1 }}>
                    <AttachFileIcon
                      sx={{
                        transform: "rotate(45deg)",
                      }}
                    />
                    View File
                  </Typography>
                </Button>
              </TableCell>
              {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={"projWspInformFile"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}

                />

              )} */}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Capital sources
      </Typography>
      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Source
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Amount
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Percentage(%)
            </TableCell>
            {/* {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )} */}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {typeOfFinancing.map((row) => (
            <TableRow
              key={row.item}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.item}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {cashConverter(row.budget)}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.comment} {row.comment == null ? "N/A" : "%"}
              </TableCell>
              {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                />
              )} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Main budget items
      </Typography>
      {isLoadingBudget && budgetInfo?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Item
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Budget
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
              {/* {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )} */}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {budgetInfo?.map((row) => (
              <TableRow
                key={row.biId}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.biItem}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {cashConverter(row.biBudget)}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.biComment}
                </TableCell>
                {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                />
              )} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!isLoadingBudget && budgetInfo?.length === 0 && <NoTableDataComponent />}

      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" ||
        status === "DEAL_SOURCING" ||
        status === "Viable" ||
        status === "Partner") && (
        <>
          <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
            Comments on above information
          </Typography>

          <Textarea
            value={comment}
            disabled={status !== "Viable"}
            onChange={(e) => setComment(e.target.value)}
            minRows={3}
            name="projSummary"
            placeholder={status !== "Viable" ? "N/A" : "write here..."}
            size="lg"
            className="fmw-textarea"
            color={`${
              status === "Partner"
            } ? "red !important" : "#646464 !important"`}
            sx={{ width: "100%", color: "#646464" }}
          />
          {status === "Viable" && (
            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default LoanInfo;
