import { Box, Typography } from '@mui/material'
import React from 'react'
import ManagementCard from '../components/ManagementCard'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDirectors } from '../../../features/onboard_partner/onboardPartnerSlice';
import { useEffect } from 'react';

const ManagementDetails = ({ data }) => {
    const dispatch = useDispatch();
    const { director,
    } = useSelector((state) => state.onboardPartner)

    console.log(director);

    const fetchData = async () => {
        const res = await dispatch(
            fetchDirectors({
                projId: data
            })
        );
        console.log(res);
    };

    console.log(data, "********************************");

    useEffect(() => {
        fetchData();
    }, [data]);


    const ManagementElement = director.map(card => <ManagementCard data={card} />);



    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '80%', height: '100%', ml: 5 }}>
            <Typography sx={{ fontWeight: 500, fontSize: '1.125rem' }}> Management Details</Typography>

            <Box sx={{ overflow: 'auto' }}>

                {ManagementElement}
            </Box>
        </Box>
    )
}

export default ManagementDetails