import { Avatar, Box } from "@mui/joy";
import placeholder from "../../assets/placeholder.jpg";
import React from "react";
import { Button, Typography } from "@mui/material";
import { formatImgPath } from "../../utils";

const SearchTile = ({
  icon,
  name,
  setFormData,
  id,
  accountType,
  setDialog,
}) => {
  const image = formatImgPath(icon);

  const onClickHandler = () => {
    if (accountType) {
      console.log(
        "Why aew whe Here======================================>>>>>>>=========================="
      );
      setFormData((prevState) => ({
        ...prevState,
        accTradeName: name,
        accId: id,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        projName: name,
        projId: id,
      }));
    }
    setDialog(false);
  };
  return (
    <Button
      onClick={onClickHandler}
      sx={{
        display: "flex",
        justifyContent: "center",
        textTransform: "capitalize",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "80%",
          gap: 4,
          alignItems: "center",
          my: 1.1,
          justifyContent: "start",
        }}
      >
        <Avatar
          src={image ?? placeholder}
          alt="equityBank"
          sx={{ objectFit: "contained" }}
        />
        <Typography>{name}</Typography>
      </Box>
    </Button>
  );
};

export default SearchTile;
