import { Select, Option, Input, Typography, FormHelperText } from "@mui/joy";
import { Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import { setStep4 } from "../../../features/onboard_partner/onboardPartnerSlice";
import { selectClasses } from "@mui/joy/Select";
import { fetchWspApplications } from "../../../features/wsp/wspSlice";
import { WaterProject } from "../../../WaterProject";
import { styled } from "@mui/material/styles";
import "react-phone-input-2/lib/style.css";
import MembersList from "../../../components/onboard_wsp_files/MembersList";
import ActiveContract from "../../../components/onboard_wsp_files/ActiveContract";
import { tooltipClasses } from "@mui/material/Tooltip";
import InstitutionContract from "../../../components/onboard_wsp_files/InstitutionContract";
import KioskContract from "../../../components/onboard_wsp_files/KioskContract";
import MapPdf from "../../../components/onboard_wsp_files/MapPdf";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F7F7F7",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function Step4() {
  const dispatch = useDispatch();
  const { step1 } = useSelector((state) => state.onboardPartner);
  const { wspApplications } = useSelector((state) => state.wsp);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState(wspApplications[0] || step1);
  const {
    projNumberWaterKiosks,
    projIndividualActiveConn,
    projIndividualClosedConn,
    projInstitutionCommercialConn,
    projNumberHouseholdsServed,
    projArea,
    projTotalPopulation,
    projAproxPopulationServed,
    projLat,
    projLong,
  } = formData;

  const handleChange = (e) => {
    e.target.value < 0
      ? (e.target.value = 0)
      : setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
    setIsUpdating(true);
  };

  useEffect(() => {
    if (!isUpdating) {
      setTimeout(() => {
        dispatch(fetchWspApplications());
      }, 800);
    } else {
      dispatch(setStep4(formData));
    }
  }, [dispatch, isUpdating, formData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Particulars of the applicant
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Individual active connections
          </Typography>
          <Input
            label=""
            type="text"
            value={formData.projIndividualActiveConn}
            name="projIndividualActiveConn"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={4}>
          <MembersList formData={formData} />
        </Grid>
        <Grid item xs={4}>
          <ActiveContract formData={formData} />
        </Grid>

        <Grid item xs={12}>
          <LightTooltip
            title="closed means the number of customers who have closed their accounts"
            arrow
          >
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Individual closed connections
            </Typography>
          </LightTooltip>
          <Input
            label=""
            type="number"
            value={formData.projIndividualClosedConn}
            name="projIndividualClosedConn"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Institution or commercial connections
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projInstitutionCommercialConn",
                },
              });
            }}
            placeholder="Select water connection..."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projInstitutionCommercialConn"
            value={formData.projInstitutionCommercialConn}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {WaterProject.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InstitutionContract formData={formData} />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Number of water kiosks
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projNumberWaterKiosks}
            name="projNumberWaterKiosks"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={6}>
          <KioskContract formData={formData} />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Number of households served
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projNumberHouseholdsServed}
            name="projNumberHouseholdsServed"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Approximate population served
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projAproxPopulationServed}
            name="projAproxPopulationServed"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Approximate total population in service area
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projTotalPopulation}
            name="projTotalPopulation"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Service provision area (km2)
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projArea}
            name="projArea"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography component="p" className="fmw-onboard-partner-sub-label">
            Grid reference of office / infrastructure
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Latitude
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projLat}
            name="projLat"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={4}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Longitude
          </Typography>
          <Input
            label=""
            type="number"
            value={formData.projLong}
            name="projLong"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={4}>
          <MapPdf formData={formData} />
        </Grid>
      </Grid>
    </>
  );
}

export default Step4;
