import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-input-2/lib/style.css";
import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountyData } from "../../../components/CountyData";
import { getExpressionDemandData } from "../../../features/expression_demand/expressionDemandSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogContent>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ExportModal = ({ open, setDialog, projData }) => {
  const handleClose = () => {
    setDialog(false);
  };
  const [query, setQuery] = useState("");
  const { filterParam, expressionData } = useSelector(
    (state) => state.expressionDemandData
  );

  const dispatch = useDispatch();
  const [activeConn, setActiveConn] = useState(
    "projIndividualActiveConnGreater"
  );
  const [loanAmount, setLoanAmount] = useState("projTotalSumGreater");

  const [scores, setScores] = useState("sctTotalScoreGreater");
  const [formData, setFormData] = useState({
    projIndividualActiveConnGreater: "",
    projIndividualActiveConnLess: "",
    projTotalSumGreater: "",
    projTotalSumLess: "",
    projCounty: "",
    sctTotalScoreLess: "",
    sctTotalScoreGreater: "",
  });

  const downloadFile = (filters) => {
   
    window.location.href = `${API_URL}/usr/fetchFormGenerateXlsx.action?${filters}`;
  };

   

  const paramHandler = () => {
    clearDataHandler();
    if (filterParam === "complete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
      }));
    } else if (filterParam === "incomplete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "ACTIVE",
      }));
    } else if (filterParam === "Viable") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableGreater: 50,
      }));
    } else if (filterParam === "Assistance") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 50,
      }));
    } else if (filterParam === "poor") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 50,
      }));
    }
  };

  useEffect(() => {
    paramHandler();
  }, [filterParam]);

  useEffect(() => {
    setTimeout(() => {
      paramHandler();
    }, 1000);
  }, []);

  const clearDataHandler = () => {
    setFormData({
      projDateGreater: "",
      projDateLess: "",
      projIndividualActiveConnGreater: "",
      projIndividualActiveConnLess: "",
      projTotalSumGreater: "",
      projTotalSumLess: "",
      projCounty: "",
      sctTotalScoreLess: "",
      sctTotalScoreGreater: "",
    });
    if (filterParam === "complete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
      }));
    } else if (filterParam === "incomplete") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "ACTIVE",
      }));
    } else if (filterParam === "Viable") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableGreater: 50,
      }));
    } else if (filterParam === "Assistance") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 50,
      }));
    } else if (filterParam === "poor") {
      setFormData((prevState) => ({
        ...prevState,
        projStatus: "PENDING_APPROVAL",
        sctViableLess: 50,
      }));
    }
  };
  const handleSelector = (event) => {
    const { value, name } = event.target;

    if (name === "activeConnections") {
      setActiveConn(value);
    } else if (name === "totalScores") {
      setScores(value);
    } else {
      setLoanAmount(value);
    }
  };
  const handleSubmit = useCallback(() => {
    //filter fields with no values
    const filteredObj = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );

    // create url Query params
    const queryString = `?${Object.entries(filteredObj)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")}`;
    // initiate filtering
    // dispatch(getExpressionDemandData(queryString));
    setQuery(queryString);
   
  }, [dispatch, formData]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      if (name === "projCounty") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      if (name === "projDateGreater") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
      if (name === "projDateLess") {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } 
      else {
        if (name === "connections") {
          if (activeConn === "projIndividualActiveConnGreater") {
            const { projIndividualActiveConnLess: _, ...remainingValue } =
              formData;
            setFormData({ ...remainingValue, [activeConn]: value });
          } else if (activeConn === "projIndividualActiveConnLess") {
            const { projIndividualActiveConnGreater: _, ...remainingValue } =
              formData;
            setFormData({ ...remainingValue, [activeConn]: value });
          }
        } else if (name === "loans") {
          if (loanAmount === "projTotalSumGreater") {
            const { projTotalSumLess: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [loanAmount]: value });
          } else if (loanAmount === "projTotalSumLess") {
            const { projTotalSumGreater: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [loanAmount]: value });
          }
        } else if (name === "scores") {
          if (scores === "sctTotalScoreGreater") {
            const { sctTotalScoreLess: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [scores]: value });
          } else if (loanAmount === "sctTotalScoreLess") {
            const { sctTotalScoreGreater: _, ...remainingValue } = formData;
            setFormData({ ...remainingValue, [scores]: value });
          }
        }
      }
    },
    [activeConn, formData, loanAmount, filterParam]
  );
  useEffect(() => {
    handleSubmit();
  }, [activeConn, formData, loanAmount, filterParam]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        padding="20"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid container spacing={2} sx={{ p: 5 }}>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="date-from">Date From</label>
              <TextField
                onChange={handleChange}
                htmlFor="date-from"
                name="projDateGreater"
                type="date"
                placeholder=" "
                InputLabelProps={{ shrink: true }}
                style={{
                  border: "none",
                  outline: "none",
                  paddingLeft: 2,
                  width: "100%",
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="date-to">Date To</label>
              <TextField
                onChange={handleChange}
                htmlFor="date-to"
                name="projDateLess"
                type="date"
                placeholder=" "
                InputLabelProps={{ shrink: true }}
                style={{
                  border: "none",
                  outline: "none",
                  paddingLeft: 2,
                  width: "100%",
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="activeConn">Total scores</label>
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid #C6CED0",
                  outline: "none",
                  borderRadius: "4px",
                  padding: "8px 10px",
                  margin: "10px 0",
                  color: "#313C40",
                }}
              >
                <select
                  name="totalScores"
                  onChange={handleSelector}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingRight: 4,
                    color: "#313C40",
                    background: "inherit",
                  }}
                >
                  <option value="sctTotalScoreGreater">Greater than</option>
                  <option value="sctTotalScoreLess">Less than</option>
                </select>
                <input
                  name="scores"
                  type="number"
                  value={
                    formData.sctTotalScoreGreater || formData.sctTotalScoreLess
                  }
                  onChange={handleChange}
                  placeholder="Enter value"
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: 2,
                    width: "100px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="activeConn">Active connections</label>
              <div
                style={{
                  width: "fit-content",
                  border: "1px solid #C6CED0",
                  outline: "none",
                  borderRadius: "4px",
                  padding: "8px 10px",
                  margin: "10px 0",
                  color: "#313C40",
                }}
              >
                <select
                  name="activeConnections"
                  onChange={handleSelector}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingRight: 4,
                    color: "#313C40",
                    background: "inherit",
                  }}
                >
                  <option value="projIndividualActiveConnGreater">
                    Greater than
                  </option>
                  <option value="projIndividualActiveConnLess">
                    Less than
                  </option>
                </select>
                <input
                  name="connections"
                  type="number"
                  value={
                    formData.projIndividualActiveConnGreater ||
                    formData.projIndividualActiveConnLess
                  }
                  onChange={handleChange}
                  placeholder="Enter value"
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: 2,
                    width: "100px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="loanAmount">Loan amount</label>
              <div
                htmlFor="loanAmount"
                style={{
                  width: "fit-content",
                  border: "1px solid #C6CED0",
                  outline: "none",
                  borderRadius: "4px",
                  padding: "8px 10px",
                  margin: "5px 0",
                  color: "#313C40",
                }}
              >
                <select
                  name="loanAmount"
                  onChange={handleSelector}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingRight: 4,
                    color: "#313C40",
                    background: "inherit",
                  }}
                >
                  <option value="projTotalSumGreater">Greater than</option>
                  <option value="projTotalSumLess">Less than</option>
                </select>
                <input
                  name="loans"
                  type="number"
                  value={
                    formData.projTotalSumGreater || formData.projTotalSumLess
                  }
                  onChange={handleChange}
                  placeholder="Enter value"
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: 2,
                    width: "100px",
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6} sx={{ mb: 1 }}>
              <label htmlFor="regions">Regions</label>
              <div
                htmlFor="regions"
                style={{
                  width: "100%",
                  border: "1px solid #C6CED0",
                  outline: "none",
                  borderRadius: "4px",
                  padding: "8px 10px",
                  margin: "5px 0",
                  color: "#313C40",
                }}
              >
                <select
                  value={formData.projCounty}
                  name="projCounty"
                  onChange={handleChange}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingRight: 8,
                    color: "#313C40",
                    background: "inherit",
                    width: "100%",
                  }}
                >
                  <option value="" selected>
                    All regions
                  </option>
                  {CountyData.map((region) => (
                    <option value={region.value}>{region.label}</option>
                  ))}
                </select>
              </div>
            </Grid>
            <Grid item xs={12} margin="auto" sx={{ textAlign: "center" }}>
           
            </Grid>
            <Grid item xs={6} margin="auto">
              <Button
                onClick={() => downloadFile(query)}
                size="large"
                variant="contained"
              >
                DownLoad Now
              </Button>
            </Grid>
          </Grid>
        </BootstrapDialogTitle>
      </BootstrapDialog>
    </div>
  );
};

export default ExportModal;
