import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SingleAttachment from '../../components/single_attachment';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
 
 
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('PDF of water quality analysis of source water', 159),
  createData('PDF of 12 months meter readings', 237 ),
  createData('PDF of picture of pump- if pumped or hybrid', 237 ),
];



export default function Attachments({attachments}) {
 console.log("attachments==",attachments)
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic'>
          {attachments?.map((row) => (
            <TableRow
              key={row?.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important',width:'50%' }}>
                 {row?.name}
              </TableCell>
              <TableCell align="left" sx={{width:'50%'}}> 
              <SingleAttachmentComplete
                  title={row?.description}
                  tableFile="true"
                />
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    
    </TableContainer>
  );
}