import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getExpressionDemandData } from "../../features/expression_demand/expressionDemandSlice";
import PageTitle from "../../components/page_title/PageTitle";
import SearchField from "../../components/search_field/SearchField";
// import ProjectTable from "../../components/new_projects/ProjectTable";


export default function NewProject() {
    const dispatch = useDispatch()

    const [search, setsearch] = useState('');
    const [data, setdata] = useState([])

    function getSearchValue(val) {
        setsearch(val);
    }

    async function fetchCompleteExpressions() {
        const resp = await dispatch(getExpressionDemandData({ projStatus: null }))
        await setdata(resp?.payload?.data?.result)
    }

    useEffect(() => {
        fetchCompleteExpressions()
    }, [])

    useEffect(() => {

    }, [data])

    return (
        <>
            <PageTitle
                currentPage='New Projects'
            />
            <SearchField expressionData={data} category='active' />
        </>
    );
}
