import React, { useEffect } from "react";
import { Box, Grid, Container, Button } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import {
  Input,
  Typography,
  Select,
  Textarea,
  Divider,
  MenuItem,
  Option,
} from "@mui/joy";
import { useState } from "react";
import SupportModal from "./supportModal";
import { CloudUploadOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  fetchSupportAccounts,
  fetchSupportProjects,
} from "../../features/support/supportSlice";
import PdfButton from "../../components/button/PdfButton";
const MessageSection = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [open, setDialog] = useState(false);
  const [accountType, setAccountType] = useState(true);

  const accountHandler = async () => {
    const data = await dispatch(fetchSupportAccounts());
    setDialog(true);
  };

  const projectHandler = async () => {
    console.log(formData.accId);
    const data = await dispatch(fetchSupportProjects(formData.accId));
    console.log(
      "Check the Projects here+++++==========PROJECT=======+++++++=======>",
      data
    );
    setAccountType(false);
    setDialog(true);
  };

  const onChange = async () => {};

  useEffect(() => {}, [formData]);

  console.log(formData, "Here is the form data");
  return (
    <Container sx={{ width: "100%" }}>
      <Grid container spacing={2} sx={{ flexDirection: "column" }}>
        <Grid item sx={{ mb: 1 }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem", color: "#646464" }}
          >
            Account
          </Typography>
          <Input
            onClick={accountHandler}
            name="accTradeName"
            value={formData.accTradeName}
            placeholder="Choose the Name of organization"
            size="lg"
            className="fmw-input-joy"
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sx={{ mb: "" }}>
          <Typography
            component="h5"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem", color: "#646464" }}
          >
            Projects
          </Typography>
          <Input
            disabled={!formData.accId}
            onClick={projectHandler}
            value={formData.projName}
            name="accTradeName"
            placeholder="Select project name"
            size="lg"
            className="fmw-input-joy"
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sx={{ mb: "" }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem", color: "#646464" }}
          >
            Category
          </Typography>
          <Select
            onChange={onChange}
            name="accTradeName"
            placeholder="Choose Issue Category"
            size="lg"
            className="fmw-select"
            sx={{ width: "100%" }}
          >
            <Option key={1} value={1}>
              Technical
            </Option>
            <Option key={2} value={2}>
              Bank Issue
            </Option>
            <Option key={3} value={3}>
              Loan Issue
            </Option>
            <Option key={4} value={4}>
              Account Issue
            </Option>
            <Option key={5} value={5}>
              Others
            </Option>
          </Select>
        </Grid>
        <Grid item sx={{ mb: "" }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem", color: "#646464" }}
          >
            Title
          </Typography>
          <Input
            onChange={onChange}
            name="accTradeName"
            placeholder="Enter the title"
            size="lg"
            className="fmw-input-joy"
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item sx={{ mb: "" }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem", color: "#646464" }}
          >
            Message
          </Typography>
          <Textarea
            minRows={3}
            name="accTradeName"
            placeholder="Write here..."
            size="lg"
            className="fmw-textarea"
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item sx={{ mb: "" }}>
          <PdfButton />
        </Grid>
        <Grid item sx={{ mb: "" }}>
          <Button
            sx={{ my: 2, width: "30%", textTransform: "capitalize" }}
            variant="contained"
          >
            submit
          </Button>
        </Grid>
      </Grid>
      <SupportModal
        open={open}
        setDialog={setDialog}
        accountType={accountType}
        setFormData={setFormData}
      />
    </Container>
  );
};

export default MessageSection;
