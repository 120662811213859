import React, { useState } from "react";
import { ManagementToolData } from "../../Step3Data";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import {
  AttachFile,
  Create,
  Delete,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Chip, Input, Typography } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteManagementTool,
  fetchManagementTool,
  reset,
  saveManagementTool,
} from "../../features/onboard_partner/onboardPartnerSlice";
import { fetchWspApplications } from "../../features/wsp/wspSlice";
import { useEffect } from "react";
import ManagementToolFile from "../onboard_wsp_files/ManagementToolFile";
import { formatPath, previewPaper } from "../../utils";
import ManagementToolEdit from "../onboard_partner_modals/ManagementToolEdit";

function ManagementTool() {
  const dispatch = useDispatch();
  const {
    step2MgmtToolPdf,
    isLoadingMgmtTool,
    isLoadingManagementTool,
    managementTool,
    isSuccessDelete,
    isSuccessUpdateManagementTool,
  } = useSelector((state) => state.onboardPartner);
  const { wspApplications, sendProjId, isLoadingWsp } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState(wspApplications[0]);
  const [mgmtData, setMgmtData] = useState({
    mtType: null,
  });
  const { mtType } = mgmtData;

  const saveMtTool = (e) => {
    if (!mtType) {
      toast.warning("Please fill all details");
    } else {
      dispatch(
        saveManagementTool({
          mtProjId: wspApplications[0].projId,
          mtType: mtType,
          mtFile: step2MgmtToolPdf.length > 0 ? step2MgmtToolPdf : null,
        })
      );
      setMgmtData({ mtType: null });
    }
  };
  const handleChangeTool = (e) => {
    e.target.value < 0
      ? (e.target.value = 0)
      : setMgmtData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    setIsUpdating(true);
  };
  const handleDeleteTool = (id) => {
    dispatch(
      deleteManagementTool({
        mtId: id,
      })
    );
    setIsUpdating(false);
  };
  const [tool, setTool] = useState();
  const [openTool, setOpenTool] = React.useState(false);

  const handleClickOpenTool = () => {
    setOpenTool(true);
  };
  const handleCloseTool = () => {
    setOpenTool(false);
  };
  useEffect(() => {
    if (!isUpdating && isSuccessDelete) {
      if (user.usrJbrnId === 1) {
        dispatch(
          fetchManagementTool({
            projId: wspApplications[0].projId,
          })
        );
      } else {
        dispatch(
          fetchManagementTool({
            projId: sendProjId,
          })
        );
      }
    } else if (!isUpdating || isSuccessUpdateManagementTool) {
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch(
            fetchManagementTool({
              projId: wspApplications[0].projId,
            })
          );
        }, 800);
      } else {
        setTimeout(() => {
          dispatch(
            fetchManagementTool({
              projId: sendProjId,
            })
          );
        }, 800);
      }
    } else if (!isUpdating) {
      if (user.usrJbrnId === 1) {
        dispatch(fetchWspApplications());
        setTimeout(() => {
          dispatch(
            fetchManagementTool({
              projId: wspApplications[0].projId,
            })
          );
        }, 800);
      } else {
        dispatch(
          fetchWspApplications({
            projId: sendProjId,
          })
        );
        setTimeout(() => {
          dispatch(
            fetchManagementTool({
              projId: sendProjId,
            })
          );
        }, 800);
      }
    } else {
    }
    return () => {
      dispatch(reset());
    };
  }, [
    dispatch,
    isUpdating,
    isSuccessDelete,
    isSuccessUpdateManagementTool,
    sendProjId,
  ]);

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Management tools
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Type of tool
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChangeTool({
                target: {
                  value: newval,
                  name: "mtType",
                },
              });
            }}
            placeholder="Select tool *"
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="mtType"
            value={mtType}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {ManagementToolData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>
        <ManagementToolFile formData={formData} />

        <Grid item md={6} xs={12}></Grid>
        <Grid item md={6} xs={12} sx={{ mt: 1 }}>
          <FormControl fullWidth variant={"standard"}>
            <FormLabel htmlFor="my-input" variant={"subtitle"}>
              NOTE: You can add as many Management tools as possible*
            </FormLabel>
            {!isLoadingManagementTool ? (
              <Button
                disabled={isLoadingMgmtTool}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--bg-primary) !important",
                }}
                onClick={saveMtTool}
              >
                {
                  managementTool?.length ? ' Add Management Tool' : 'Save Management Tool'
                }
               
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                <CircularProgress size={17} color="inherit" />
                &nbsp;Adding...
              </Button>
            )}
          </FormControl>
        </Grid>

        {isLoadingManagementTool ? (
          <>
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
          </>
        ) : (
          <>
            {managementTool?.length ? (
              <>
                <Divider sx={{ my: 2 }} />
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Type of tool</TableCell>
                        <TableCell align="left">File</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {managementTool.length &&
                        managementTool.map((mgmt) => (
                          <TableRow
                            key={mgmt.mtId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {mgmt.mtType}
                            </TableCell>
                            <TableCell align="left">
                              <Chip
                                variant="soft"
                                className="file-chip-primary"
                                color="primary"
                                size="sm"
                                startDecorator={<AttachFile size="sm" />}
                                onClick={() => previewPaper(mgmt.mtFile)}
                              >
                                {formatPath(mgmt.mtFile)}
                              </Chip>
                            </TableCell>

                            <TableCell align="left">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ mr: 0.5 }}
                                onClick={() => setTool(mgmt)}
                              >
                                <Create
                                  fontSize="small"
                                  onClick={() => handleClickOpenTool()}
                                />
                              </IconButton>

                              <IconButton aria-label="delete" size="small">
                                <Delete
                                  fontSize="small"
                                  onClick={() => handleDeleteTool(mgmt.mtId)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <></>
            )}
          </>
        )}
        <ManagementToolEdit
          open={openTool}
          handleClickOpen={handleClickOpenTool}
          handleClose={handleCloseTool}
          toolEdited={tool}
        />
      </Grid>
    </>
  );
}

export default ManagementTool;
