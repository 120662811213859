import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleFailure } from "../utils";
import authService from "./authService";
import wspService from "../wsp/wspService";
import { arrCleanUp } from "../../utils";

const initialState = {
  user: {
    usrUsername: "",
    usrEncryptedPassword: "",
  },
  userDetails: {},

  isLoginError: false,
  isLoginSuccess: false,
  isLoginProcessing: false,

  isDetailsError: false,
  isDetailsSuccess: false,
  isDetailsProcessing: false,

  isError: false,
  isProcessing: false,
  isLoadingNewUser: false,
  isSuccessNewUser: false,
  isErrorNewUser: false,
  isSuccess: false,
  isSuccessResendOtp: false,
  message: "",
  messageNewUser: "",
  sendOtpCode: {
    usrId: "",
    usrSalt: "",
  },
  OTP: "",
  resetOtpCode: "",
  newUsers: [],
  isErrorDelete: false,
  isLoadingDelete: false,
  isSuccessDelete: false,

  messagePhone: "",
  isErrorPhone: false,
  isLoadingPhone: false,
  isSuccessPhone: false,
};

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message = handleFailure(error);
    return thunkAPI.rejectWithValue(message);
  }
});

export const loginDetails = createAsyncThunk(
  "auth/loginDetails",
  async (_, thunkAPI) => {
    try {
      const id = thunkAPI.getState().auth.user.usrId;
      // const accId = thunkAPI.getState().auth.user.usrAccId;
      return await authService.loginDetails(id);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendLoginOTP = createAsyncThunk(
  "auth/Login/sendOtp",
  async (formData, thunkAPI) => {
    try {
      return await authService.sendLoginOTP(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendLoginOTP = createAsyncThunk(
  "auth/Login/resendOtp",
  async (usrId, thunkAPI) => {
    try {
      return await authService.resendLoginOTP(usrId);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// addUser
export const addUser = createAsyncThunk(
  "auth/addUser",
  async (newUser, thunkAPI) => {
    try {
      return await authService.addUser(newUser);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//fetchNewUser
export const fetchNewUser = createAsyncThunk(
  "partner/fetchNewUser",
  async (formData, thunkAPI) => {
    try {
      return await authService.fetchNewUser(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//deleteNewUser
export const deleteNewUser = createAsyncThunk(
  "partner/deleteNewUser",
  async (usrId, thunkAPI) => {
    try {
      return await authService.deleteNewUser(usrId);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//searchPhoneNumber
export const searchPhoneNumber = createAsyncThunk(
  "partner/searchPhoneNumber",
  async (formData, thunkAPI) => {
    try {
      return await authService.searchPhoneNumber(formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isProcessing = false;
      state.isLoadingNewUser = false;
      state.isSuccessNewUser = false;
      state.isErrorNewUser = false;
      state.isError = false;
      state.isSuccess = false;
      state.isSuccessResendOtp = false;
      state.message = "";
      state.messagePhone = "";
      state.messageNewUser = "";

      state.isErrorDelete = false;
      state.isLoadingDelete = false;
      state.isSuccessDelete = false;

      state.isErrorPhone = false;
      state.isLoadingPhone = false;
      state.isSuccessPhone = false;

      state.isLoginError = false;
      state.isLoginSuccess = false;
      state.isLoginProcessing = false;

      state.isDetailsError = false;
      state.isDetailsSuccess = false;
      state.isDetailsProcessing = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //  login
      .addCase(login.pending, (state) => {
        state.isLoginProcessing = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoginProcessing = false;
        state.isLoginSuccess = true;
        state.message = "Sign in successfull login";
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoginProcessing = false;
        state.isLoginError = true;
        state.message = action.payload;
        state.user = {};
      })

      //user_details

      .addCase(loginDetails.pending, (state) => {
        state.isDetailsProcessing = true;
      })
      .addCase(loginDetails.fulfilled, (state, action) => {
        state.isDetailsProcessing = false;
        state.isDetailsSuccess = true;
        // state.message = ""
        state.userDetails = action.payload;
      })
      .addCase(loginDetails.rejected, (state, action) => {
        state.isDetailsProcessing = false;
        state.isDetailsError = true;
        state.message = "Can't fetch user";
        state.userDetails = {};
      })

      //login_OTP
      .addCase(sendLoginOTP.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(sendLoginOTP.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.isSuccess = true;
        state.sendOtpCode = action.payload;
        state.message = "OTP verified.";
      })
      .addCase(sendLoginOTP.rejected, (state, action) => {
        state.isProcessing = false;
        state.isError = true;
        state.message = action.payload;
      })

      // resend_OTP
      .addCase(resendLoginOTP.pending, (state) => {
        state.isProcessing = true;
      })
      .addCase(resendLoginOTP.fulfilled, (state, action) => {
        state.isProcessing = false;
        state.isSuccessResendOtp = true;
        // state.resetOtpCode=action.payload
        state.message = "OTP sent.";
      })
      .addCase(resendLoginOTP.rejected, (state, action) => {
        state.isProcessing = false;
        state.isError = true;
        state.message = action.payload;
        // state.resetOtpCode={}
      })

      //addUser
      .addCase(addUser.pending, (state) => {
        state.isLoadingNewUser = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoadingNewUser = false;
        state.isSuccessNewUser = true;
        state.messageNewUser = "Created.";
        state.newUsers.unshift(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoadingNewUser = false;
        state.isErrorNewUser = true;
        state.messageNewUser = action.payload;
      })

      //fetchNewUser
      .addCase(fetchNewUser.pending, (state) => {
        state.isLoadingNewUser = true;
      })
      .addCase(fetchNewUser.fulfilled, (state, action) => {
        state.isLoadingNewUser = false;
        state.isSuccess = true;
        state.newUsers = arrCleanUp(action.payload, "usrId");
      })
      .addCase(fetchNewUser.rejected, (state, action) => {
        state.isLoadingNewUser = false;
        state.isError = true;
        state.message = action.payload;
      })

      // deleteNewUser
      .addCase(deleteNewUser.pending, (state) => {
        state.isLoadingDelete = true;
      })
      .addCase(deleteNewUser.fulfilled, (state, action) => {
        state.isLoadingDelete = false;
        state.isSuccessDelete = true;
        state.newUsers = action.payload;
      })
      .addCase(deleteNewUser.rejected, (state, action) => {
        state.isLoadingDelete = false;
        state.isErrorDelete = true;
        state.message = action.payload;
      })

      //searchPhoneNumber
      .addCase(searchPhoneNumber.pending, (state) => {
        state.isLoadingPhone = true;
      })
      .addCase(searchPhoneNumber.fulfilled, (state, action) => {
        state.isLoadingPhone = false;
        state.isSuccessPhone = true;
        state.messagePhone = action.payload;
      })
      .addCase(searchPhoneNumber.rejected, (state, action) => {
        state.isLoadingPhone = false;
        state.isErrorPhone = true;
        state.messagePhone = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
