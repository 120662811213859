import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';

import ChoiceSelector from '../ChoiceSelector';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';


export default function EngineeringTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);

	const handleChange = async (e) => {
		await setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function getFile1(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngMapFile: data,
		}));
	}

	function getFile2(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngDesignReportFile: data,
		}));
	}

	function getFile3(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngDrawingFile: data,
		}));
	}

	function getFile4(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngSpecificationFile: data,
		}));
	}

	function getFile5(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngBillOfQuantityFile: data,
		}));
	}

	function getFile6(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngConstructionPlanFile: data,
		}));
	}

	function getFile7(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEngTenderMaterialFile: data,
		}));
	}

	let file1 = formData?.wrEngMapFile
		?.substring(formData?.wrEngMapFile?.lastIndexOf('/'))
		.split('_');

	let file2 = formData?.wrEngDesignReportFile
		?.substring(formData?.wrEngDesignReportFile?.lastIndexOf('/'))
		.split('_');

	let file3 = formData?.wrEngDrawingFile
		?.substring(formData?.wrEngDrawingFile?.lastIndexOf('/'))
		.split('_');

	let file4 = formData?.wrEngSpecificationFile
		?.substring(formData?.wrEngSpecificationFile?.lastIndexOf('/'))
		.split('_');

	let file5 = formData?.wrEngBillOfQuantityFile
		?.substring(formData?.wrEngBillOfQuantityFile?.lastIndexOf('/'))
		.split('_');

	let file6 = formData?.wrEngConstructionPlanFile
		?.substring(formData?.wrEngConstructionPlanFile?.lastIndexOf('/'))
		.split('_');

	let file7 = formData?.wrEngTenderMaterialFile
		?.substring(formData?.wrEngTenderMaterialFile?.lastIndexOf('/'))
		.split('_');

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleFileChnages() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	useEffect(() => {
		handleFileChnages();
	}, [formData]);

	useEffect(() => {}, [formData, id, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
							<TableCell align='left'>File</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className='basic'>
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Map of supply area
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngMap'}
									choiceValue={formData?.wrEngMap}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile1}
									title={file1?.length ? file1[1] : 'Attach supply map'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Engineering design report
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngDesignReport'}
									choiceValue={formData?.wrEngDesignReport}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile2}
									title={file2?.length ? file2[1] : 'Attach report'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Drawings
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngDrawing'}
									choiceValue={formData?.wrEngDrawing}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile3}
									title={file3?.length ? file3[1] : 'Attach drawings'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Specifications
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngSpecification'}
									choiceValue={formData?.wrEngSpecification}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile4}
									title={file4?.length ? file4[1] : 'Attach specifications'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Costed Bills of Quantities
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngBillOfQuantity'}
									choiceValue={formData?.wrEngBillOfQuantity}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile5}
									title={file5?.length ? file5[1] : 'Attach bill'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Construction Plan
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngConstructionPlan'}
									choiceValue={formData?.wrEngConstructionPlan}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile6}
									title={file6?.length ? file6[1] : 'Attach plan'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Tender materials
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEngTenderMaterial'}
									choiceValue={formData?.wrEngTenderMaterial}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile7}
									title={file7?.length ? file7[1] : 'Attach tender'}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
