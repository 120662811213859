import { Box, Button, Grid, TableCell, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { postProjectScore } from "../../../features/expression_demand/expressionDemandSlice";
import { Input, Textarea } from "@mui/joy";
import { useParams } from "react-router-dom";

const SingleComment = ({
  userComment,
  setUserComment,
  setOnblur,
  name,
  stepName,
  onBlur,
  id,
  maxScores,
  system,
  status,
  setEditing
}) => {
  const dispatch = useDispatch();
  const {id:paramId} = useParams()
 
  const changeHandler = (e) => {
    const inputValue = parseInt(e.target.value);
    const data = {
      scProjId: paramId,
      scUsrComment: e.target.value,
      scMethod: name,
    };
    const formData = { data, stepName };

    const res = dispatch(postProjectScore(formData));
    setUserComment((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
    setEditing(true);
  };

 

  return (
    <>
    <Grid container>
      <Grid item xs={6}>
        <Textarea
          minRows={1}
          type="text"
          value={userComment[name]}
          name="projSummary"
          onChange={changeHandler}
          placeholder="Add a comment..."
          size="lg"
          className="fmw-input-joy-comment"
          onBlur={()=>setEditing(false)}
        />
        </Grid>
        <Grid item xs={6}></Grid>
        </Grid>
        </>
  );
};

export default SingleComment;

