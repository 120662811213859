import { Button, Chip, FormHelperText, TextField, Typography } from "@mui/joy";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Step,
  StepButton,
  Stepper,
} from "@mui/material";
import React from "react";
import Header from "../../components/header/Header";
import RegisterWsp from "../../assets/water_kiosk_kogweno.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BasicDetails from "./components/BasicDetails";
import AccountDetails from "./components/AccountDetails";
import { toast } from "react-toastify";
import {
  registerTech,
  registerTechDetails,
  reset,
  cleanupForm,
} from "../../features/tech_adv/techAdvSlice";
import { useEffect } from "react";
import VerifyOTP from "./components/VerifyOTP";
import Signin from "../signin/Signin";
import { useMediaQuery } from "react-responsive";
import { toSentenceCase } from "../../utils";

const steps = [
  {
    label: "Step 1",
    description: "Basic details",
  },
  {
    label: "Step 2",
    description: "Account details",
  },
];

const ActiveStepContent = ({ step }) => {
  switch (step) {
    case 0:
      return <BasicDetails />;
    case 1:
      return <AccountDetails />;
    default:
      throw new Error("Unknown step");
  }
};

const SignUpTechAdv = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stateChecker, setStateChecker] = useState(false);
  const {
    techDetails,
    technicalDetails,
    orgDetails,
    regCertFile,
    isProcessingBasic,
    isSuccessBasic,
    isErrorBasic,
    isProcessing,
    isSuccess,
    isProcessingAcc,
    isError,
    message,
    messageAcc,
    isErrorAcc,
    isProcessingRegFile,
    isSuccessAcc,
    errorEmail,
    errorPhone,
    messageBaic,
  } = useSelector((state) => state.techAdv);
  const {messagePhone} = useSelector(state =>state.auth)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const [openOtp, setopenOtp] = React.useState(false);

  // console.log("Details", technicalDetails);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);

    // little customization
    if (step === 0) {
      handleReset();
    } else if (step === 1) {
      setCompleted({ 0: true, 1: true });
    } else {
      handleComplete();
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();

    // console.log("newCompleted", newCompleted);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  // console.log("User ==> ", techDetails);

  const handleOpenOTP = () => {
    setopenOtp(true);
  };

  const handleCloseOTP = () => {
    setopenOtp(false);
  };

  const handleBasicDetails = () => {
		if (
			!techDetails.usrEmail ||
			!techDetails.usrFirstName ||
			!techDetails.usrLastName
		) {
			toast.warning('Please fill all required basic details ');
		} else if (errorEmail) {
			toast.warning('Invalid email address ');
		} else if (!errorPhone) {
			toast.warning('Enter a valid phone number');
		} else {
			handleComplete();
			// dispatch(registerTech({
			//     ...techDetails,
			//     accTypeId: 2,
			//     usrFullNames: `${techDetails.usrFirstName} ${techDetails.usrLastName}`,
			// }));
		}
	};

	const handleAccountDetails = () => {
		let ref_file_arr = [];
		Object.keys(regCertFile).forEach(
			(key) => regCertFile[key] && ref_file_arr.push(regCertFile[key]),
		);
		console.log('ref_file_arr.length', ref_file_arr.length);
		if (
			!orgDetails.accTradeName ||
			!orgDetails.accRegNo ||
			!orgDetails.accWebsite ||
			!orgDetails.accPinCert ||
			!regCertFile ||
			!orgDetails.usrEncryptedPassword ||
			!orgDetails.usrConfirmPassword
		) {
			//step2
			toast.warning('Please fill all required account details ');
		} else if (
			orgDetails.usrEncryptedPassword !== orgDetails.usrConfirmPassword
		) {
			toast.warning('confirm password is not matched');
		} else if (!ref_file_arr.length) {
			toast.warning('Certificate of Registration is required');
		} else if (
			!techDetails.usrEmail ||
			!techDetails.usrFirstName ||
			!techDetails.usrLastName
		) {
			//step1
			toast.warning("'Step 1' details required");
		} else {
			dispatch(
				registerTech({
					...techDetails,
					accTypeId: 2,
					usrFullNames: `${techDetails.usrFirstName} ${techDetails.usrLastName}`,
					usrEncryptedPassword: orgDetails.usrEncryptedPassword,
					usrConfirmPassword: orgDetails.usrConfirmPassword,
				}),
			);
			setStateChecker(true);
			// dispatch(registerTechDetails({
			//     ...orgDetails,
			//     accId: technicalDetails.usrAccId,
			//     accRegCert: regFilePdf,

			// }));
		}
	};

  const [openLogIn, setOpenLogIn] = React.useState(false);
  const handleClickOpenLogIn = () => {
    setOpenLogIn(true);
  };
  const handleCloseLogIn = () => {
    setOpenLogIn(false);
  };

  const isMobile = useMediaQuery({ query: "(max-width:480px)" });
  useEffect(() => {
    if (isSuccessBasic && messageBaic) {
      // toast.success(messageBaic);
      // handleOpenOTP();
    }

    if (isErrorBasic && messageBaic) {
      setStateChecker(false);
      toast.error(toSentenceCase(messageBaic));
      handleClickOpenLogIn();
    }
    if (
      isSuccessBasic &&
      orgDetails.accTradeName &&
      orgDetails.accRegNo &&
      orgDetails.accWebsite &&
      orgDetails.accPinCert &&
      orgDetails.usrEncryptedPassword &&
      orgDetails.usrConfirmPassword
    ) {
      dispatch(
        registerTechDetails({
          ...orgDetails,
          accId: technicalDetails.usrAccId,
          accRegCert: regCertFile,
        })
      );
    }

    if (isSuccessAcc && messageAcc) {
      toast.success(toSentenceCase(messageAcc));
      setStateChecker(false);
      handleOpenOTP();
      // navigate("/")
      // dispatch(cleanupForm());
    }

    if (isErrorAcc && messageAcc) {
      setStateChecker(false);
      toast.error(toSentenceCase(messageAcc));
    }

    //   if (isCleanupForm) {
    //     console.log("isCleanupForm", isCleanupForm);
    //     navigate("/")
    //     dispatch(cleanupForm());
    //   }
    dispatch(reset());
  }, [
    dispatch,
    isSuccessBasic,
    isSuccessAcc,
    isErrorBasic,
    isErrorAcc,
    messageAcc,
    message,
    messageBaic,
  ]);

  return (
    <>
      <Header />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container className="main" maxWidth="lg" sx={{ my: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} sx={{ pt: 0, mt: "16px" }}>
              <Typography component="body2" className="highlighter" />
              <Typography component="h5" className="meta-title">
                Get your project financed
              </Typography>
              <Typography component="h4" className="main-title">
                Register here
              </Typography>

              <Box sx={{ mt: 4 }}>
                <img
                  src={RegisterWsp}
                  alt="register-wsp"
                  style={{
                    height: isMobile ? "200px" : "582px",
                    width: "100%",
                    borderRadius: "2px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={7}
              style={{
                backgroundColor: "#ffff",
                paddingTop: 0,
                marginTop: "20px",
              }}
            >
              <Box
                sx={{ width: "100%", paddingTop: "24px" }}
                className="custom-stepper"
              >
                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                  {steps.map((step, index) => (
                    <Step key={step.label} completed={completed[index]}>
                      <StepButton
                        color="primary"
                        onClick={handleStep(index)}
                        className={`stepper-btn  ${
                          activeStep === index || completed[index]
                            ? "sb-active"
                            : ""
                        }`}
                      >
                        <Typography component="body2" className="step-label">
                          {step.label}
                        </Typography>
                        <Typography component="h5" className="step-description">
                          {step.description}
                        </Typography>
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
                <div style={{ margin: "4rem 1.5rem" }}>
                  <React.Fragment>
                    <Box sx={{ mt: 4, mb: 2, py: 1 }}>
                      <ActiveStepContent step={activeStep} />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {activeStep == 0 && (
                        <Button
                          onClick={handleBasicDetails}
                          className="next-btn"
                          disabled={messagePhone == "OK"}
                        >
                          Next
                        </Button>
                      )}
                      {activeStep == 1 ? (
                        <>
                          {stateChecker ? (
                            <Button
                              disabled
                              variant="contained"
                              sx={{ textTransform: "none" }}
                            >
                              <CircularProgress size={17} color="inherit" />
                              &nbsp;Registering Technical Advisor...
                            </Button>
                          ) : (
                            <Button
                              onClick={handleAccountDetails}
                              className="next-btn"
                              disabled={messagePhone == "OK"}
                            >
                              Register
                            </Button>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </React.Fragment>
                </div>
              </Box>

              {/* <CssVarsProvider /> */}
            </Grid>
          </Grid>
          <Signin
            open={openLogIn}
            handleClickOpen={handleClickOpenLogIn}
            handleClose={handleCloseLogIn}
          />
          <VerifyOTP
            open={openOtp}
            handleClickOpen={handleOpenOTP}
            handleClose={handleCloseOTP}
            num={technicalDetails.usrSalt}
            iD={technicalDetails.usrId}
            handleComplete={handleComplete}
          />
        </Container>
      </Box>
    </>
  );
};

export default SignUpTechAdv;
