import axios from "axios"

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const registerPartner = async (formData) => {

    const response = await axios.post(`${API_URL}/usr/create_account.action`, formData)


    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const savePartner = async (formData) => {
    const response = await axios.post(`${API_URL}/usr/save_account.action`, formData)
    console.log("response", response.data.success)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const onboardFiles = async (formData) => {
    const response = await axios.post(`${API_URL}/usr/postImages.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const sendOTP =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/verify_User.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    let can = response.data.jsonData

    console.log("Can ===> ", can);

    return response.data.jsonData;
}


const partnerService = {
    registerPartner,
    savePartner,
    onboardFiles,
    sendOTP,

}
export default partnerService
