import { Checkbox, Typography } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { setNemaCompliance } from '../../features/committed_project/committedSlice';
import { useDispatch, useSelector } from 'react-redux';
import ImageInput from './ImageInput';

function StatutoryNema() {
    const { nemaCompliance, committedd,waterResources
    } = useSelector((state) => state.committedProj)
    const dispatch = useDispatch()
    const [isUpdating, setIsUpdating] = useState()
 
    const [waterQualityData, setWaterQualityData] = useState(waterResources?.wrNemaComliance ? waterResources?.wrNemaComliance?.split(",") : [])


    // const handleChangeCheckbox = (e) => {
    //     if (e.target.checked) {
    //         setWaterQualityData((prevState) => ({
    //             ...prevState,
    //             [e.target.name]: e.target.value
    //         }))
    //         setIsUpdating(true);
    //     } else {
    //         setWaterQualityData((prevState) => ({
    //             ...prevState,
    //             [e.target.name]: ''
    //         }))

    //     }
    // };

    const handleChangeCheckbox = (e) => {
        if (findWaterQualityItem(e.target.value)) {
          const newArr = waterQualityData?.filter(item => item != e.target.value)
          setWaterQualityData(newArr)
          setIsUpdating(true);
          console.log(waterQualityData);
        }else{
          const newArr = [...waterQualityData]
          newArr?.push(e.target.value)
          setWaterQualityData(newArr)
          setIsUpdating(true);
        }
      };


      const findWaterQualityItem = str => {
        const data = waterQualityData?.length && waterQualityData?.find(item => item == str)
        if (data) {
          return true
        } else {
          return false
        }
      }
    
    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(setNemaCompliance(waterQualityData))
        }
    }, [dispatch, isUpdating, waterQualityData]);

    useEffect(() => {
        setWaterQualityData(waterResources?.wrNemaComliance?.split(",") ?? [])
      }, [committedd])

    useEffect(() => {
        setWaterQualityData(waterResources?.wrNemaComliance?.split(",") ?? [])
      }, [committedd, waterResources])

    return (
        <>

            <Grid item xs={12} sx={{ mt: '1rem' }}>
                <Typography component="h6" className="fmw-checkbox-label-committed" sx={{ mb: '.8rem' }}>
                    Statutory compliance - NEMA
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="complianceWra"
                    checked={findWaterQualityItem('Full compliance with WRA')}
                    value="Full compliance with WRA"
                    onChange={handleChangeCheckbox}
                    label="Full compliance with WRA"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="complianceWraProcess"
                    checked={findWaterQualityItem("Compliance with WRA in process")}
                    value="Compliance with WRA in process"
                    onChange={handleChangeCheckbox}
                    label="Compliance with WRA in process"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="complianceWraOutstanding"
                    checked={findWaterQualityItem("Compliance with WRA outstanding")}
                    value="Compliance with WRA outstanding"
                    onChange={handleChangeCheckbox}
                    label="Compliance with WRA outstanding"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="nemaFull"
                    checked={findWaterQualityItem("Full compliance with NEMA")}
                    value="Full compliance with NEMA"
                    onChange={handleChangeCheckbox}
                    label="Full compliance with NEMA"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="nemaProcess"
                    checked={findWaterQualityItem("Compliance with NEMA in process")}
                    value="Compliance with NEMA in process"
                    onChange={handleChangeCheckbox}
                    label="Compliance with NEMA in process"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={4}>
                <Checkbox
                    color="primary"
                    name="nemaOutstanding"
                    checked={findWaterQualityItem("Compliance with NEMA outstanding")}
                    value="Compliance with NEMA outstanding"
                    onChange={handleChangeCheckbox}
                    label="Compliance with NEMA outstanding"
                    size="lg"
                    variant="soft"
                    className="fmw-checkbox-label"
                />
            </Grid>

            <Grid item xs={4}>
                <Box sx={{ mt: '10px' }}>
                    <ImageInput title="Attach NEMA authorization"/>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box sx={{ mt: '10px' }}>
                    <ImageInput title="Attach NEMA permit"/>
                </Box>
            </Grid>

        </>
    )
}

export default StatutoryNema
