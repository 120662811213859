import { Box } from '@mui/joy';
import { Typography, Button } from '@mui/joy'
import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { useNavigate, useParams } from 'react-router-dom';
import { getExpressionDemandData, getPdfInteractions, getToolTipData, setOpenPDfDialog, setPdfData } from '../../features/expression_demand/expressionDemandSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { cashConverter, formatImgPath, handleOpenDoc } from '../../utils';
import PdfModal from '../../pages/expression_demand/components/pdfAnnotations/PdfModal';
import ScrollToBottom from '../../pages/messages/scrollToBottom';



function DealRoomRefillStations() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
const {id} = useParams();
 
const { toolTipData,expressionData } = useSelector(
    (state) => state.expressionDemandData
  );

  

  function createData(name, description) {
    return { name, description };
  }
  const singleProjectData = expressionData.find(
    (item) => item.projId === Number(id)
  );
  console.log("singleProjectData",singleProjectData)

  // const projectCost = cashConverter(singleProjectData.projCost)
	function navigateToSheet() {
		navigate(`/submit-termsheet/${id}`);
	}

	const proposalData = toolTipData?.find((item) => item.projaType === "PROPOSAL");
	const feasibilityData = toolTipData?.find((item) => item.projaType === "FEASIBILITY_REPORT");

	const tableData = [
		createData("Name",singleProjectData?.accTradeName),
		createData("Description",singleProjectData?.projDesc),
        // createData("Valuation of the project",projectCost),
        createData("Scale of repayment (Lowest 1, Highest 5) ",singleProjectData?.projLoanYears),
        createData("Recommendation",singleProjectData?.projLoanYears),
	];


  const handlePdfDialog= async(data)=>{
    const msgs = await dispatch(getPdfInteractions(data?.projaId));
    const pdf= await dispatch(setPdfData(data ));
    const open =  await dispatch(setOpenPDfDialog(true))

  };

	const callToolTipData = () => {
		dispatch(getToolTipData(id));
	  };
	  useEffect(()=>{
		callToolTipData();
	  },[]);


	return (
		<>
				<Box
					sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}
				>
					<Typography sx={{fontWeight: 'bold', color: '#2C2C2C'}} level='h4' className=''>
						{singleProjectData?.accTradeName}
					</Typography>

					<Box
						sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}
					>

						<Button 
							onClick={()=>handlePdfDialog(feasibilityData)}
							className="fmw-btn-noBg">

							<FilePresentIcon sx={{color: "#0069df"}} /> <span style={{ margin: "0px 10px", color: "#0069df", fontSize: "16px", fontWeight: "bold" }}>Feasibility</span>
						</Button>

						<Button className="fmw-btn-noBg"
            onClick={()=>handlePdfDialog(proposalData)}>
							<FilePresentIcon sx={{color: "#0069df"}} /> <span style={{ margin: "0px 10px", color: "#0069df", fontSize: "16px", fontWeight: "bold" }}>Proposal</span>

						</Button>

                        <Button 
						className='fmw-btn-primary'
						variant="contained" 
						sx={{ mx: 4, color: 'white', textTransform: 'capitalize'}} 
						onClick={() => navigateToSheet()}>
							Offer termsheet
							</Button>
					</Box>

					
				</Box>

                <Grid container spacing={4}>

				<Grid item xs={12} style={{ paddingTop: 0 }}>
					<Divider />
				</Grid>

				<Grid item xs={9}>
				<TableContainer>
				<Table aria-label="customized table">
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {tableData.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
				</TableContainer>
				</Grid>
			</Grid>
      <PdfModal/>
		</>
	);
}

export default DealRoomRefillStations;
