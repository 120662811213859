import { Box, Button, CircularProgress, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CloudUploadOutlined } from "@mui/icons-material";

const PdfButton = ({}) => {
  const [open, setOPen] = useState(false);
  const [cellName, setCellName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  /**
   * Function that uploads a file
   * @param {*} e
   */
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    console.log(
      "Here is the type of File==========================>>>>>>=========",
      file.type
    );
    if (file.type !== "application/pdf") {
      return toast.error("Please select a PDF file");
    }
    if (file.size > 1024 * 1024 * 10) {
      // limit file size to 10MB
      return toast.error("File exceed 10 MBs");
    }
    setLoading(true);
    setCellName(e.target.name);
    const projType = e.target.name;
    const formData = new FormData();
    const data = {
      projaProjId: 1,
      projaType: projType,
    };

    formData.append("data", JSON.stringify(data));
    formData.append("file", file);
  };

  /**
   * Function that Trigger window to view file upload
   */

  //   const handleFileView = async () => {
  //     console.log("Handle File View Called", proposalData.projaFile);
  //     const msgs = await dispatch(getPdfInteractions(proposalData.projaId));
  //     const pdf = await dispatch(setPdfData(proposalData));
  //     const open = await dispatch(setOpenPDfDialog(true));
  //     setDialog(true);
  //     setProjData(proposalData);
  //   };

  //   useEffect(() => {
  //     dispatch(getToolTipData(dataId));

  //     if (cellName && isSuccess) {
  //       toast.success("File uploaded successfully");
  //       setCellName("");
  //     } else if (cellName && isError) {
  //       setCellName("");
  //       setOPen(true);
  //     }
  //   }, [isSuccess, isError, cellName]);
  return (
    <>
      {true ? (
        <Button sx={{ display: "flex", my: 2 }}>
          <div
            className="mb-2"
            style={{
              display: "flex",
              alignItems: "center",
              width: "337px",
              height: "46px",
              backgroundColor: "#F7F7F7",
            }}
          >
            <div
              style={{
                backgroundColor: "#A0A0A0",
                height: "100%",
                display: "flex",
                alignItems: "center",
                marginRight: "20px",
                width: "52px",
              }}
            >
              <CloudUploadOutlined
                sx={{
                  display: "flex",
                  fontSize: "27px",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: "12px",
                  color: "#ffff",
                  backgroundColor: "#A0A0A0 !important",
                }}
              />
            </div>

            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="caption"
              className="upload-sub-label"
            >
              Click here to upload
            </Typography>
          </div>
        </Button>
      ) : (
        <Typography
          sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
        >
          <input
            onChange={handleFileUpload}
            type="file"
            accept="application/pdf"
            style={{ opacity: 0, position: "absolute", left: "-9999px" }}
          />
          <label style={{ display: "flex", alignItems: "center" }}>
            <AttachFileIcon
              sx={{
                transform: "rotate(45deg)",
              }}
              fontSize="small"
            />
            Add
          </label>
        </Typography>
      )}
    </>
  );
};

export default PdfButton;
