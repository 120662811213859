import { Box, Button, Divider, Input, Typography } from '@mui/joy'
import { Grid, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function UpdateTermsheet() {
    const navigate = useNavigate();
    const handleSubmitTs = (e) => {
        navigate(`/termsheet`)
    }
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography component="h3" style={{ fontSize: '20px' }}>
                        Refill stations: Update termsheet
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                        <Box sx={{ backgroundColor: '#475459' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Items & Conditions
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={2} sx={{ px: 3, py: 2, display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={2}>
                                <Grid item xs={2}></Grid>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Borrower
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Loan amount
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Purpose
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Maximum loan amount as a percentage of cost
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Minimum equity amount as a percentage of cost
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Maximum loan tenure
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Interest rate
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Interest during grace period
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Basis of charging interest
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Loan repayment installations
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Mode of payment (loan repayment)
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Loan disbursement
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Loan appraisal fee
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Mode of payment (appraisal fee)
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Other terms
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>



                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Contractual savings
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Security of financial institutions
                                </Typography>
                                <Input
                                    label=""
                                    type="text"
                                    // value={wspApplications[0].accTradeName}
                                    name="waterProject"
                                    // onChange={handleChange}
                                    size="lg"
                                    className="fmw-input-joy"
                                />
                            </Grid>

                            <Grid item xs={12} style={{ paddingTop: '1rem', }}>
                                <Button className="submit-btn" sx={{ my: 2, font: 500 }} onClick={handleSubmitTs}>
                                    Submit termsheet
                                </Button>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default UpdateTermsheet