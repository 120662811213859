import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Individual', 159,159),
  createData('Kiosk', 237,159 ),
  createData('Institution(school, HCF)', 262 ,159),
  createData('Commerical', 159,159),
  createData('Others', 237,159 ),
];



export default function VolumetricTable() {
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Type of customer</TableCell>
            <TableCell align="left">Volume(Minimum vol(v1))</TableCell>
            <TableCell align="left">Volume(Minimum vol(v2))</TableCell>
            <TableCell align="left"><>Charge (Ksh/m<sup>3</sup>) (Minimum charge)</></TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic' >
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important' }}>
                {row.name}
              </TableCell>
              <TableCell align="left" >{row.calories}</TableCell>
              <TableCell align="left" >{row.fat}</TableCell>
              <TableCell align="left" >{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    </TableContainer>
  );
}