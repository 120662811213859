import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { Input, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react"; 
import FileButton from "../../../components/button/FileButton";
import RatingsStar from "./RatingsStar";
import { formatPath, previewPaper } from "../../../utils";
import { BorderColor } from "@mui/icons-material";

 

const SingleAttachmentComplete = ({
  title, name, value, comment, score, tableFile, onChange, scoreComment, titleLabel, isUpdating, handleRemoveUpdating }) => {
  const [editing, setEditing] = useState(false)

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {
        !tableFile ? (
          <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0, display: 'flex', alignItems: 'center' }}  >
            {titleLabel}
            <FileButton title={title} titlePreview={title} viable="true" />
            {
              comment && (
                <Tooltip title="Edit comment" arrow placement="top" >
                  <IconButton onClick={() => setEditing(!editing)} >
                    <BorderColor sx={{ fontWeight: 'inherit', fontSize: 'inherit', color: '#000000DE' }} />
                  </IconButton>
                </Tooltip>
              )
            }
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0 }}  >
            <FileButton title={formatPath(title)} titlePreview={title} />
          </Typography>
        )
      }

      {/* {
        comment && (
          <Typography sx={{ mt: 2 }}>{comment}</Typography>
        )
      }
      {
        scoreComment &&
        (
          <Grid container>
            <Grid item xs={6}>
              <Textarea minRows={1}
                sx={{ marginBottom: '0px !important', mt: 1.5 }}
                label=""
                type="text"
                value={value}
                name={name}
                onChange={onChange}
                placeholder="Add a comment..."
                size="lg"
                className="fmw-input-joy-comment"
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        )
      } */}
 <Typography>{comment}</Typography>
      {/* {

        comment && !editing && !isUpdating ? (<>
          <Typography>{comment}</Typography>
        </>) :
          editing || isUpdating || scoreComment ? (<>
            <Grid container>
              <Grid item xs={6}>
                <Textarea minRows={1}
                  sx={{ marginBottom: '0px !important', mt: 1.5 }}
                  label=""
                  type="text"
                  value={comment}
                  name={name}
                  onBlur={handleRemoveUpdating}
                  onChange={onChange}
                  placeholder="Add a comment..."
                  size="lg"
                  className="fmw-input-joy-comment"
                />
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </>)
            : (<>
              <Grid container>
                <Grid item xs={6}>
                  <Textarea minRows={1}
                   sx={{ marginBottom: '0px !important', mt: 1.5 }}
                    label=""
                    type="text"
                    value={comment}
                    name={name}
                    onBlur={handleRemoveUpdating}
                    onChange={onChange}
                    placeholder="Add a comment..."
                    size="lg"
                    className="fmw-input-joy-comment"
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </>)
      }


      {
        (!tableFile && score) && (
          <Box
            sx={{
              display: "flex",
              mt: 3,
              mb: 2,
              gap: 6,
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px' }}>System score: {score}</Typography>
            <Box
              sx={{
                width: "300px",
                display: "flex",
                alignItems: "center",
                bgColor: "red",
                gap: 2,
              }}
            >
              <Typography sx={{
                display: "flex",
                alignItems: "center", gap: 2
              }}>
                Your Score:
                <Input
                    // name="usrFirstName"
                    // value={usrFirstName}
                    // onChange={handleChange}
                    type="number"
                    size="lg"
                    className="fmw-input-joy-score"
                  />
                </Typography>
            </Box>
          </Box>
        )
      } */}

    </Box>
  );
};

export default SingleAttachmentComplete;
