export const MainBudgetItems
    = [
    {
        value: 'Pipes',
        label: 'Pipes',
    },
    {
        value: 'Kiosks',
        label: 'Kiosks',
    },
    {
        value: 'Storage',
        label: 'Storage',
    },
    {
        value: 'Pumps',
        label: 'Pumps',
    },
    {
        value: 'Meters',
        label: 'Meters',
    },
    {
        value: 'Equipment',
        label: 'Equipment',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]
