import { Box, Divider, List, Toolbar, Typography } from '@mui/material';
import React from 'react'
// import { mainListItems, secondaryListItems } from './listItems';
import MainListItems from './listItems';
// import fmwLogo from '../../assets/fmw-logo.png'
import fmwLogo from '../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg';
import PartnerListItems from './PartnerListItems';
import { useSelector } from 'react-redux';
import ExpressionListItem from './ExpressionListItems';

const Menu = () => {
  const { user } = useSelector(state => state.auth);
 
  return (
    <>
      <Toolbar
        className="menu-toolbar"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          px: [2.25],
        }}
      >
        <Box sx={{ borderRadius: '8px', mt: 1.75 }}>
          <img src={fmwLogo} alt="logo" style={{ width: "100%" }} />
        </Box>

      </Toolbar>
      {/* <Divider /> */}
      <List component="nav" sx={{ px: 1.15 }} className="menu fmw-drawer">
        {user.usrJbrnId == 1 ? (
          <>
            <MainListItems />
          </>
        ) :
          user.usrJbrnId == 2 ? (
            <>
              <ExpressionListItem/>
            </>
          )
            : (
              <>
                <PartnerListItems />
              </>
            )}

       

      </List>
    </>
  )
}

export default Menu