import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  globalLoading: false,
  singleProject: {},
};

export const UploadAction = createAsyncThunk(
  "globalSlice/UploadAction",
  (data) => {
    return axios
      .post(`${url}/usr/postImages.action`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  }
);

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    reset: (state) => {
      state.globalLoading = false;
    },
    setSingleProject: (state, action) => {
      state.singleProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(UploadAction.pending, (state) => {
        state.globalLoading = true;
      })
      .addCase(UploadAction.fulfilled, (state, action) => {
        state.globalLoading = false;
      })
      .addCase(UploadAction.rejected, (state) => {
        state.globalLoading = false;
      });
  },
});

export default globalSlice.reducer;
export const { reset, setSingleProject } = globalSlice.actions;
