import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useState } from "react";

function RetryDialog({ open, setOpen, onRetry }) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleRetry = () => {
    onRetry();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="retry-dialog-title"
      aria-describedby="retry-dialog-description"
      sx={{ display: "flex" }}
    >
      <DialogTitle id="retry-dialog-title">Error Occured</DialogTitle>
      <DialogContent>
        <p id="retry-dialog-description">
          There was an error. Would you like to retry?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRetry} color="primary">
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RetryDialog;
