import { cleanupMtrReading, onbaordMtrReadingPdf, reset } from '../../features/onboard_partner/onboardPartnerSlice';
import {   Typography } from '@mui/joy'
import { Divider,  Grid, IconButton, } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Article, CloudUploadOutlined, Image, PictureAsPdf} from '@mui/icons-material';
import { formatPath, previewPaper, subString } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';

function MasterMeter({formData}) {
    const dispatch = useDispatch();
    const { step3, mtrReadingPdf,isSuccessSaveWtrSrc,isSuccessUpdateWtrSrc} = useSelector((state) => state.onboardPartner)
    const { wspApplications, isLoadingWsp } = useSelector((state) => state.wsp)
    const [isUpdating, setIsUpdating] = useState(false);
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;

    // MtrReadingPdf
    const [file, setFile] = useState("")
    const [fileMtrReading, setFileMtrReading] = useState(mtrReadingPdf)
    const { nameMtrReading, dateMtrReading, file_base64MtrReading, typeMtrReading } = fileMtrReading;
    const [mtrPdf, setMtrPdf] = useState('')
    const [errorMtrPdf, setErrorMtrPdf] = useState('')


      //  MtrReadingPdf
      const handleMtrPdf = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;
        const allowedExtensions = /(\.pdf)$/i;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorMtrPdf(
							'Please upload a PDF file Only of size not more than 10mb',
						);
            setMtrPdf('');
            return;
        }
        setFile(_file);
        setErrorMtrPdf('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameMtrReading: _file.name,
                dateMtrReading: _date,
                typeMtrReading: _file.type,
                file_base64MtrReading: base64String.toString()
            };
            setFileMtrReading(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordMtrReadingPdf(formData));
    }

    const removeMtrPdf = () => {
        setFileMtrReading({
            nameMtrReading: '',
            dateMtrReading: '',
            typeMtrReading: '',
            file_base64MtrReading: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupMtrReading());
    }
    const [fetchedMeterReadingsFile, setFetchedMeterReadingsFile] = useState(formData?.wsMtrFile)
    const removeFetchedMeterReadingsFile = () => {
        setFetchedMeterReadingsFile('')
    }
    useEffect(()=>{
        if(isSuccessSaveWtrSrc || isSuccessUpdateWtrSrc){
            setFileMtrReading({
                nameMtrReading: '',
            })
        }
        return()=>{
            dispatch(reset())
        }
       },[isSuccessSaveWtrSrc,isSuccessUpdateWtrSrc])

  return (
    <>
        <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            PDF of 12 months meter readings
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            fetchedMeterReadingsFile ? (<>
                                                <a className='file-stepper-primary' onClick={() => previewPaper(fetchedMeterReadingsFile)}>
                                                    {formatPath(fetchedMeterReadingsFile)}
                                                </a>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={removeFetchedMeterReadingsFile}
                                                    sx={{
                                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton></>) : (<>
                                                    {
                                                        !nameMtrReading ? (
                                                            <>
                                                                <div style={{
                                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                                    border: 'none',
                                                                    // border: '1px solid var(--color-primary-light)',
                                                                    width: '337px',
                                                                    height: '46px'
                                                                }}>
                                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                                            </div>
                                                                            {
                                                                                errorMtrPdf ? (
                                                                                    <Typography component="h6" className="upload-sub-label-error">
                                                                                        {errorMtrPdf}
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                                        Click here to upload
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <input
                                                                            type="file"
                                                                            name='nameMtrReading'
                                                                            value={nameMtrReading}
                                                                            accept=".pdf"
                                                                            webkitdirectory
                                                                            onChange={handleMtrPdf}
                                                                            style={{ width: '0px', height: '0px' }}
                                                                        />

                                                                    </label>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div style={{
                                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                                    border: '1px solid var(--color-primary-light)',
                                                                    width: '337px',
                                                                    height: '46px'
                                                                }}>
                                                                    <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                                        <span style={{ margin: "0px 6px" }}>
                                                                            {
                                                                                typeMtrReading === "application/pdf" ? (
                                                                                    <PictureAsPdf sx={{
                                                                                        fontSize: '40px',
                                                                                        color: '#f44336'
                                                                                    }} />
                                                                                ) :
                                                                                    typeMtrReading === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                                        <Image sx={{
                                                                                            fontSize: '40px',
                                                                                            color: '#263238',
                                                                                        }} />
                                                                                    ) :
                                                                                        (
                                                                                            <Article sx={{
                                                                                                fontSize: '40px',
                                                                                                color: '#1976d2'
                                                                                            }} />
                                                                                        )
                                                                            }

                                                                        </span>
                                                                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                        <Typography component="h6" className="fmw-upload-typography">
                                                                            {subString(nameMtrReading, 25)}
                                                                        </Typography>


                                                                        <Typography variant="caption" className="upload-sub-label">
                                                                            uploaded on  {_date}
                                                                        </Typography>
                                                                    </div>

                                                                    <div>
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={removeMtrPdf}
                                                                            sx={{
                                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }</>)


                                        }
                                    </Grid>
                                </Grid>
    </>
  )
}

export default MasterMeter
