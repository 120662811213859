import { AttachFile, Close, CloudUploadOutlined } from "@mui/icons-material";
import { Avatar, Stack } from "@mui/joy";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { onbaordAudited1Step5 } from "../../features/onboard_partner/onboardPartnerSlice";
import { useDispatch } from "react-redux";
import { attachCommittedFiles } from "../../features/committed_project/committedSlice";

function ImageInput({ title, getFileUrl }) { 
  // const [images, setImages] = useState([]);

  // const handleImageSelect = (event) => {
  //     const files = event.target.files;
  //     const newImages = [];

  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       const reader = new FileReader();

  //       reader.onload = (e) => {
  //         newImages.push({
  //           name: file.name,
  //           size:file.size,
  //           url: e.target.result,
  //         });

  //         if (newImages.length === files.length) {
  //           setImages([...images, ...newImages]);
  //         }
  //       };

  //       reader.readAsDataURL(file);
  //     }
  //   };

  const dispatch = useDispatch();
  const handleFile = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(attachCommittedFiles(formData));
  };

  return (
    <>
      <label
        className=""
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <AttachFile
          sx={{ fontSize: "1.5rem", mr: 0.7, color: "var(--color-technical)" }}
        />
        <span
          style={{
            textDecoration: "underline",
            color: "var(--color-technical)",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {title ? title : "attach minute"}
        </span>

        <input
          // multiple
          type="file"
          accept="pdf/*"
          onChange={handleFile}
          style={{ width: "0px", height: "0px" }}
        />
      </label>

    
    </>
  );
}
export default ImageInput;
