import { Divider, Typography } from "@mui/joy";
import { Grid } from "@mui/material";
import React from "react";
import equity from "../../assets/equity.png";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Account() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography
            component="h3"
            className="fmw-page-title"
            style={{ paddingLeft: "none" }}
          >
            My account
          </Typography>
          <Divider sx={{ mt: "17px" }} />
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <div
            style={{
              width: "",
              height: "193px",
              background:
                "linear-gradient(90deg, rgba(38,222,255,1) 0%, rgba(0,186,186,1) 35%)",
              borderRadius: "2px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "148px",
                height: "148px",
                borderRadius: "50%",
                background: "#F7F7F7",
                position: "absolute",
                top: "52px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={equity}
                alt="equity-logo"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#202020",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        Equity Bank
      </div>
      <Divider sx={{ mt: "17px" }} />

      <Grid
        container
        spacing={2}
        style={{ margin: "3rem", color: "#202020", fontSize: "15px" }}
      >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Email:
            </Grid>
            <Grid item xs={8}>
              partnerships@equity.com
            </Grid>
            <Grid item xs={4}>
              Phone:
            </Grid>
            <Grid item xs={8}>
              +254 23929949
            </Grid>
            <Grid item xs={4}>
              Website:
            </Grid>
            <Grid item xs={8}>
              31488384
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Business name:
            </Grid>
            <Grid item xs={8}>
              Equity bank
            </Grid>
            <Grid item xs={4}>
              Business registration No:
            </Grid>
            <Grid item xs={8}>
              2312312
            </Grid>
            <Grid item xs={4}>
              Business location:
            </Grid>
            <Grid item xs={8}>
              Nairobi, Kenya
            </Grid>
            <Grid item xs={4}>
              Business zip code:
            </Grid>
            <Grid item xs={8}>
              384
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Account;
