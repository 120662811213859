import { Box, Button, Divider, Input, Typography } from '@mui/joy'
import { CircularProgress, Grid, Paper } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cleanupForm, reset, saveTermsheet, setTermsheetDetails } from '../../features/deal_sourcing/dealSourcingSlice';
import { cashConverter, toSentenceCase } from '../../utils';

function SubmitTermsheet() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { termsheet, isProcessing, isSuccess, message, isError } = useSelector(state => state.dealSourcing)
    const { user } = useSelector(state => state.auth)
    const [formData, setFormData] = useState(termsheet)
    const { ltcLoanAmnt, ltcPurpose, ltcMaxLnAmnt, ltcMaxEqtyAmnt, ltcMaxLnTenure, ltcLoanInterest, ltcGraceRate,
        ltcInterestBasis, ltcInstallment, ltcPaymentMode, ltcDisbursement, ltcAppraisalMode, ltcOthersTerms,
        ltcContactualSavings, ltcSecurity, } = formData

    const [isUpdating, setIsUpdating] = useState(false)
    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
    const { id } = useParams();
    const { expressionData } = useSelector(
        (state) => state.expressionDemandData
    );
 

    const singleProjectData = expressionData.find(
        (x) => x.projId === Number(id)
    );
   


    const handleSubmitTs = (e) => {
        if (!ltcPurpose || !ltcMaxLnAmnt || !ltcMaxEqtyAmnt || !ltcMaxLnTenure || !ltcLoanInterest || !ltcGraceRate ||
            !ltcInterestBasis || !ltcInstallment || !ltcPaymentMode || !ltcDisbursement || !ltcAppraisalMode || !ltcOthersTerms ||
            !ltcContactualSavings || !ltcSecurity) {
            toast.warning("Fill all fields")
        } else {
            dispatch(saveTermsheet({
                ltcProjId: singleProjectData.projId,
                ltcAccId: user.usrAccId,
                ltcLoanAmnt: singleProjectData.projCost,
                ltcPurpose: ltcPurpose,
                ltcMaxLnAmnt: ltcMaxLnAmnt,
                ltcMaxEqtyAmnt: ltcMaxEqtyAmnt,
                ltcMaxLnTenure: ltcMaxLnTenure,
                ltcLoanInterest: ltcLoanInterest,
                ltcGraceRate: ltcGraceRate,
                ltcInterestBasis: ltcInterestBasis,
                ltcInstallment: ltcInstallment,
                ltcPaymentMode: ltcPaymentMode,
                ltcDisbursement: ltcDisbursement,
                ltcAppraisalMode: ltcAppraisalMode,
                ltcOthersTerms: ltcOthersTerms,
                ltcContactualSavings: ltcContactualSavings,
                ltcSecurity: ltcSecurity
            }))
        }
    }

    useEffect(() => {
        if (!isUpdating) {
        }
        // if (isUpdating) {
        //     dispatch(setTermsheetDetails(formData))
        // }
        if (isSuccess && message) {
            dispatch(cleanupForm())
            setFormData({ltcPurpose: '', ltcMaxLnAmnt: '', ltcMaxEqtyAmnt: '', ltcMaxLnTenure: '', ltcLoanInterest: '', ltcGraceRate: '',
                ltcInterestBasis: '', ltcInstallment: '', ltcPaymentMode: '', ltcDisbursement: '', ltcAppraisalMode: '', ltcOthersTerms: '',
                ltcContactualSavings: '', ltcSecurity: '',})
            toast.success(toSentenceCase(message))
            // dispatch(setTermsheetDetails(formData));
            navigate(`/bids`)
        }
        if (isError && message) {
            toast.warning(toSentenceCase(message))
        }
        else { }
        dispatch(reset())
        // return () => {
        //     dispatch(reset());
        //   }
    }, [dispatch, formData, isUpdating, isSuccess, isError])
    return (
			<>
				<Grid container spacing={4}>
					<Grid item xs={12} sx={{ mb: 2 }}>
						<Typography component='h3' style={{ fontSize: '20px' }}>
							Refill stations: Submit termsheet
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: 0 }}>
						<Divider />
					</Grid>

					<Grid item xs={12}>
						<Paper
							sx={{ p: 0, display: 'flex', flexDirection: 'column' }}
							variant='outlined'
						>
							<Box sx={{ backgroundColor: '#475459' }}>
								<Grid container spacing={4}>
									<Grid item xs={12}>
										<Typography
											component='h6'
											sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}
										>
											Items & Conditions
										</Typography>
									</Grid>
								</Grid>
							</Box>

							<Grid
								container
								spacing={2}
								sx={{ px: 3, py: 2, display: 'flex', alignItems: 'center' }}
							>
								<Grid item xs={2}>
									<Grid item xs={2}></Grid>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Borrower
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<Input
										label=''
										type='text'
										value={singleProjectData?.accTradeName}
										name='accTradeName'
										// onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>
								<Grid item xs={2}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Loan amount
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<Input
										label=''
										type='text'
										value={cashConverter(singleProjectData?.projCost)}
										name='ltcLoanAmnt'
										//    onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>
								<Grid item xs={2}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Purpose
									</Typography>
								</Grid>
								<Grid item xs={10}>
									<Input
										label=''
										type='text'
										value={ltcPurpose}
										name='ltcPurpose'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Maximum loan amount as a percentage of cost
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcMaxLnAmnt}
										name='ltcMaxLnAmnt'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Minimum equity amount as a percentage of cost
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcMaxEqtyAmnt}
										name='ltcMaxEqtyAmnt'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Maximum loan tenure
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcMaxLnTenure}
										name='ltcMaxLnTenure'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Interest rate
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcLoanInterest}
										name='ltcLoanInterest'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Interest during grace period
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcGraceRate}
										name='ltcGraceRate'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Basis of charging interest
									</Typography>
									<Input
									 label="" type="text" value={ltcInterestBasis}
									name="ltcInterestBasis" onChange={handleChange}
									size="lg" className="fmw-input-joy" />
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Loan repayment installations
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcInstallment}
										name='ltcInstallment'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Mode of payment (loan repayment)
									</Typography>
									<Input
										label=''
										type='text'
										value={ltcPaymentMode}
										name='ltcPaymentMode'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Loan disbursement
									</Typography>
									<Input
										label=''
										type='text'
										value={ltcDisbursement}
										name='ltcDisbursement'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Loan appraisal fee
									</Typography>
									<Input
										label=''
										type='text'
										// value={wspApplications[0].accTradeName}
										// name="waterProject"
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Mode of payment (appraisal fee)
									</Typography>
									<Input
										label=''
										type='text'
										value={ltcAppraisalMode}
										name='ltcAppraisalMode'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Other terms
									</Typography>
									<Input
										label=''
										type='text'
										value={ltcOthersTerms}
										name='ltcOthersTerms'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Contractual savings
									</Typography>
									<Input
										label=''
										type='number'
										value={ltcContactualSavings}
										name='ltcContactualSavings'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12}>
									<Typography
										component='p'
										className='fmw-onboard-partner-sub-label'
										sx={{ mb: '.5rem' }}
									>
										Security of financial institutions
									</Typography>
									<Input
										label=''
										type='text'
										value={ltcSecurity}
										name='ltcSecurity'
										onChange={handleChange}
										size='lg'
										className='fmw-input-joy'
									/>
								</Grid>

								<Grid item xs={12} style={{ paddingTop: '1rem' }}>
									{isProcessing ? (
										<Button
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Adding...
										</Button>
									) : (
										<Button
											className='submit-btn'
											sx={{ my: 2, font: 500 }}
											onClick={handleSubmitTs}
										>
											Submit termsheet
										</Button>
									)}
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</>
		);
}

export default SubmitTermsheet