import { InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAllChatHeads,
	sendMessage,
	setSelectedUser,
	uploadMessageFile,
} from '../../features/message/chatUserSlice';

function ChartInput({ hasFile, myFiles, handleFileSend }) {
	const { currentChat, uploadSuccess } = useSelector((state) => state.messages);
	const currentUser = useSelector((state) => state.auth.user);
	const [text, settext] = useState('');
	const dispatch = useDispatch();

	async function sendChat(e) {
		e.preventDefault();

		let filePath;

		if (hasFile) {
			const formData = new FormData();

			formData.append('file', myFiles);

			const fileRes = await dispatch(uploadMessageFile(formData));
			filePath = fileRes.payload.jsonData;
		}

		await dispatch(
			sendMessage({
				msgStatus: 'ACTIVE',
				msgAccId: currentUser.usrAccId,
				msgInstId: currentChat.chInstId,
				msgMessage: text,
				msgProjId: 1,
				msgUsrId: currentUser.usrAccId,
				msgSenderId: currentUser.usrAccId,
				msgChId: currentChat.chId || null,
				msgFile: filePath || null,
			}),
		);
		const users = await dispatch(fetchAllChatHeads(currentUser.usrAccId));
		users.payload.data.result.map((user) => {
			if (user.chInstId === currentChat.chInstId) {
				return dispatch(setSelectedUser(user));
			} else {
				return users;
			}
		});
		settext('');
		handleFileSend();
	}

	return (
		<>
			<form method="POST" enctype="multipart/form-data"
				onSubmit={sendChat}
				fullwidth='true'
				sx={{ m: 1 }}
				variant='standard'
			>
				<Box>
					<TextField
						disabled={currentChat?.chInstId ? false : true}
						name='chat'
						id='chat'
						required={hasFile ? false : true}
						value={text}
						onChange={(e) => settext(e.target.value)}
						placeholder={hasFile ? 'Send File' : 'Reply here'}
						sx={{
							width: '51vw',
							outline: 0,
							border: 0,
							background: '#f1f4fb',
							p: 1,
							borderRadius: '5px',
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position='start'>
									<button
										style={{
											border: 0,
											outline: 0,
											backgroundColor: 'transparent',
											cursor: 'pointer',
										}}
										disabled={currentChat?.chInstId ? false : true}
										type='submit'
									>
										<SendIcon
											sx={{ color: '#00baba', bgcolor: 'transparent' }}
										/>
									</button>
								</InputAdornment>
							),
							type: 'text',
							disableUnderline: true,
							autoComplete: 'off',
						}}
						variant='standard'
					/>
				</Box>
			</form>
		</>
	);
}

export default ChartInput;
