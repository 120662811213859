import React, { useRef, useState } from "react";
import logo from "../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../assets/chevron-left.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Checkbox, Form, Input } from "antd";
import AuthSideImage from "../../components/auth/AuthSideImage";
import { login } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
import Signin from "../signin/Signin";
import { CircularProgress } from "@mui/material";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [hasForgot, sethasForgot] = useState(false);
  const [openSignin, setOpenSignin] = React.useState(false);

  const { isLoginProcessing } = useSelector((state) => state.auth);

  function handleForgotChange() {
    console.log("clicked");
    sethasForgot(!hasForgot);
    setOpenSignin(true);
  }

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleSignup = () => {
    navigate("/signup-wsp/getting-started");
  };

  const onFinish = async (data) => {
    const res = await dispatch(
      login({
        usrUsername: data?.usrUsername,
        usrEncryptedPassword: data?.usrEncryptedPassword,
      })
    );
    console.log("res?.payload?.usrJbrnId ",res?.payload?.usrJbrnId )
    if (res?.payload?.usrId) {
      toast.success("Login successful");
      if (res?.payload?.usrJbrnId === 1) {
        navigate("/wsp-home");
      } else if (res?.payload?.usrJbrnId === 2) {
        navigate("/expression-demand/complete");
      } else if (res?.payload?.usrJbrnId === 0) {
        const url = `https://partner.fundmywater.com/#/${res?.payload?.usrId}/${res?.payload?.usrAccId}`;
        window.open(url, "_blank");
      } else {
        const url = `https://impactfunder.fundmywater.com/#/impact-funder-account/${res?.payload?.usrId}/${res?.payload?.usrAccId}`;

        window.open(url, "_self");
      }
    } else {
      toast.error("Login failed, check your credentials");
    }
  };
  

  return (
    <>
      <div className="lg:grid lg:grid-cols-6">
        <div className="bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block">
          <AuthSideImage hideStepper={true} hideText={true} />
        </div>
        <div className="lg:col-span-4 ">
          <div
            className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[88px] items-center justify-center flex cursor-pointer"
            onClick={() => navigate("/signup-wsp/password-setup")}
          >
            <img src={leftArrow} alt="backarrow" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={logo} alt="logo" style={{ marginTop: "10px" }} />
            <div
              style={{
                color: "#171A1F",
                fontSize: "30px",
                fontFamily: "DM Sans",
                fontWeight: "700",
                lineHeight: "46px",
                wordWrap: "break-word",
                marginTop: "50px",
              }}
            >
              Welcome back
            </div>
            <div
              style={{
                texAalign: "center",
                color: "#565E6C",
                fontSize: "18px",
                fontFamily: "DM Sans",
                marginTop: "15px",
                fontWeight: "500",
                lineHeight: "20px",
                wordWrap: "break-word",
              }}
            >
              Log in with your details below
            </div>

            <div className="flex text-start justify-start mt-[39px]">
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                className="lg:w-[436px] w-auto max-w-[100%]"
                form={form}
              >
                <div className="grid grid-cols-1 mb-[-23px]">
                  <Form.Item
                    name="usrUsername"
                    rules={[
                      {
                        required: true,
                        message: "Please add official email address",
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Email address"
                      className="rounded-[8px] h-[52px] w-full border border-black"
                    />
                  </Form.Item>

                  <Form.Item
                    name="usrEncryptedPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please add password",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="password"
                      className="rounded-[8px] h-[52px] w-full border border-black"
                    />
                  </Form.Item>
                </div>

                <div className="flex justify-between mt-[21px] items-center">
                  <Checkbox
                    onChange={onChange}
                    className="!text-[18px] font-dmSans flex items-center"
                  >
                    Remember me
                  </Checkbox>

                  <div
                    class="text-darkBlue text-[18px] font-normal font-dmSans leading-tight cursor-pointer"
                    onClick={handleForgotChange}
                  >
                    Forgot password?
                  </div>
                </div>

                <div className="text-center mt-[68px]">
                  <button
                    className="w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal"
                    type="submit"
                  >
                    {isLoginProcessing ? (
                      <CircularProgress size={17} color="inherit" />
                    ) : (
                      " Log in"
                    )}
                  </button>
                </div>

                <div className="mt-[44px] flex justify-center">
                  <span className="text-lightBlue text-[18px] font-normal font-dmSans leading-tight">
                    Don't have an account yet?{" "} 
                  </span>
                  <span
                    className="text-darkBlue ml-1 text-[18px] font-normal font-dmSans underline leading-tight cursor-pointer"
                    onClick={handleSignup}
                  >
                    Sign up now
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Signin
        hasForgot={hasForgot}
        sethasForgot={sethasForgot}
        handleForgotChange={handleForgotChange}
        open={openSignin}
        setOpenSignin={setOpenSignin}
      />
    </>
  );
}

export default Login;
