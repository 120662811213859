import { FilePresentOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';
import { Grid, Paper, Avatar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import equitylarge from '../../assets/equity-large.png';
import equity from '../../assets/equity.png';
import { formatImgPath } from '../../utils';
import placeholderPartner from "../../assets/placeholder.jpg";
// import ViewTermsheet from './ViewTermsheet';
// import './deal-room.css'

export default function OfferCard({
	title,
	project,
	sheet,
	projId,
	ltcId,
	amount,
	parnerIcon
}) {
	const navigate = useNavigate();

	const handleClickRS = (e) => {
		navigate(`/deal-refill-stations/${projId}`);
	};

	const ViewTermsheet = (e) => {
		navigate(`/view-termsheet/${ltcId}`);
	};

	return (
		<>
			<Grid item xs={12} style={{ paddingTop: '1.15rem' }}>
				<Paper
					sx={{ p: 0, display: 'flex', flexDirection: 'column' }}
					variant='outlined'
				>
					<Box sx={{ p: 2, pb: 1 }}>
						<Grid container style={{ display: 'flex', alignItems: 'center' }}>
							<Grid item xs={3} sx={{ mb: 1 }}>
								<Box>
									<img
										alt='Logo'
										src={ 
											typeof parnerIcon ==="object"?placeholderPartner:formatImgPath(parnerIcon)}
										style={{ width: 50, height: 50, objectFit: 'cover', marginLeft: '15px' }}
									/>
								</Box>
							</Grid>

							<Grid item xs={3} sx={{ mb: 1 }}>
								<Box>
									<Typography
										sx={{ textTransform: 'capitalize', color: "#484848", fontWeight: 'bold' }}
										component='h6'
									>
										{title}
									</Typography>
								</Box>
							</Grid>

							<Grid item xs={3} sx={{ mb: 1 }}>
								<Box>
									<Typography component='body3' sx={{ fontSize: '.925rem', color: "#484848", fontWeight: 'bold' }}>
										Project: {project}
									</Typography>
								</Box>
							</Grid>

							<Grid item xs={1} sx={{ mb: 1 }}></Grid>

							<Grid item xs={2} sx={{ mb: 1 }}>
								<Box>
									<Typography component='body3' sx={{ fontSize: '.925rem', color: "#484848", fontWeight: 'bold' }}>
										Loan Amount:
									</Typography>
									<Typography component='body3' sx={{ fontSize: '.925rem', color: "#0069DF", fontWeight: 'bold' }}>
										KES {amount ? amount : 0}
									</Typography>
								</Box>
							</Grid>


						</Grid>
					</Box>

					{sheet ? (
						<Box
							sx={{ px: 1, py: 1 }}
							className='fmw-paper-footer'
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Grid
								style={{
									display: 'flex',
									alignItems: 'center',
									cursor: 'pointer',
								}}
							>
								<FilePresentOutlined
									sx={{ mr: 1, fontSize: '2rem', color: '#00BABA' }}
									onClick={ViewTermsheet}
								/>
								<Typography
									component='p'
									className='term'
									sx={{
										fontSize: '1rem',
										color: '#00BABA',
										fontWeight: 'bold',
									}}
									onClick={ViewTermsheet}
								>
									View Termsheet{' '}
								</Typography>
							</Grid>
						</Box>
					) : null}
				</Paper>
			</Grid>
		</>
	);
}
