import { Box, Typography } from '@mui/material'
import React from 'react'
import { formatPath } from '../../../utils';
import FileButton from '../../button/FileButton';

const BusinessDetails = ({ data }) => {
    const { accTradeName, accCounty, accSubCounty, accRole, accServices, projBankStatementFile } = data;
    return (
        <Box
            sx={{
                backgroundColor: "#FEFEFF",
                display: "flex",
                flexDirection: "column",
                p: 4,
                borderRadius: 4,
                mt: 5
            }}
        >
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Business Details
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#",
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{}}>
                        Water Project
                    </Typography>
                    <Typography sx={{}}>Role on the project</Typography>
                    <Typography sx={{}}>Financial Statements </Typography>
                    <Typography sx={{}}>County</Typography>
                    <Typography sx={{}}>
                        Sub-County
                    </Typography>
                    <Typography sx={{}}>
                        Services offered
                    </Typography>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#",
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ color: "#667085" }}>{accTradeName}</Typography>
                    <Typography sx={{ color: "#667085" }}>{accRole}</Typography>
                    <FileButton title={formatPath(projBankStatementFile)} titlePreview={projBankStatementFile} viable="true" />
                    <Typography sx={{ color: "#667085" }}>{accCounty}</Typography>
                    <Typography sx={{ color: "#667085" }}>{accSubCounty}</Typography>
                    <Typography sx={{ color: "#667085" }}>{accServices}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default BusinessDetails