import { Divider, Grid, Typography } from "@mui/joy";
import React from "react";
import DealSourcingCard from "./DealSourcingCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpressionDemandData,
  setFilterParams,
} from "../../features/expression_demand/expressionDemandSlice";
import { useEffect } from "react";

function DealSourcing() {
  const { expressionData, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  const dispatch = useDispatch();

  const handleDealSourceData = () => {
    dispatch(setFilterParams("approved"));
    const res = dispatch(getExpressionDemandData({ projStatus: "APPROVED" }));

    console.log(res);
  };

  useEffect(() => {
    handleDealSourceData();
  }, []);

  const DealSourceElement = expressionData.map((item) => (
    <DealSourcingCard
      projectDesc={item.projDesc}
      accTradeName={item.accTradeName}
      projId={item.projId}
      page="Partner"
    />
  ));

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography component="h3" className="fmw-page-title" sx={{ mb: 1 }}>
            Deal Sourcing
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "1rem" }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label fmw-cl-lg text-blue"
            style={{ mb: ".5rem" }}
          >
            Screened Viable Projects
          </Typography>
          {DealSourceElement}
        </Grid>
      </Grid>
    </>
  );
}

export default DealSourcing;
