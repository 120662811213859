import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
	Box,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import {
	Add,
	AttachFile,
	Close,
	FilterList,
	KeyboardArrowDown,
	Search,
} from '@mui/icons-material';
import { Button, Input } from '@mui/joy';

import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import '../search_field/search.css';
import { PositionData, filterDataConnections } from '../../PositionData';
import ViableTable from '../classification/ViableTable';
import FilterSection from '../../pages/expression_demand/components/FilterSection';
import ExportModal from '../../pages/expression_demand/components/ExportModal';
import CommittedTable from '../../pages/committedProjects/CommittedTable';

const RedditTextField = styled((props) => (
	<TextField
		InputProps={{
			startAdornment: (
				<InputAdornment position='start'>
					<Search sx={{ color: 'var(--color-technical)' }} />
				</InputAdornment>
			),
			disableUnderline: true,
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiFilledInput-root': {
		// border: "1px solid var(--color-primary-light)",
		overflow: 'hidden',
		borderRadius: 0,
		color: '#646464',
		fontSize: '15px !important',
		backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			// borderColor: theme.palette.primary.main,
			//   borderLeft: "3px solid var(--color-info)",
		},
	},
}));

function DealRoomSearch({ getSearchValue, category }) {
	const [searchField, setSearchField] = useState('');
	const [show, setShow] = useState(false);
	const [openExportDialog, setExportDialog] = useState(false);

	const handleChange = (e) => {
		setSearchField(e.target.value);
		getSearchValue(e.target.value);
	};

	useEffect(() => {}, [searchField]);

	return (
		<>
			<Grid container spacing={0}>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						alignItems: 'center !important',
						textAlign: 'center',
						mb: 3
					}}
					className='gap-10'
				>
					<Grid
						item
						xs={6}
						sx={{ display: 'flex', alignItems: 'center !important' }}
					>
						<RedditTextField
							onChange={(e) => handleChange(e)}
							label='Search'
							placeholder='Search project'
							id='reddit-input-search'
							variant='filled'
							autoFocus
							fullWidth
							className='reddit-input-search'
						/>
					</Grid>
					<Grid
						item
						xs={4}
						sx={{ display: 'flex', alignItems: 'center !important' }}
					>
						{!show ? (
							<>
								<Button
									onClick={() => setShow(true)}
									sx={{
										height: '56px',
										color: 'inherit',
										borderRadius: '2px',
										backgroundColor: 'inherit !important',
										border: '1px solid var(--color-light-blue)',
									}}
									startDecorator={
										<FilterList sx={{ color: 'var(--color-technical)' }} />
									}
								>
									Filter
								</Button>
							</>
						) : (
							<>
								<Button
									onClick={() => setShow(false)}
									sx={{
										height: '56px',
										color: 'inherit',
										borderRadius: '2px',
										backgroundColor: 'inherit !important',
										border: '1px solid var(--color-light-blue)',
									}}
									startDecorator={<Close />}
								>
									Close Filter
								</Button>
							</>
						)}
					</Grid>
				</Grid>
				{category == 'committed' || category == 'viable' ? null : (
					// <Grid
					// 	item
					// 	xs={6}
					// 	sx={{ display: 'flex', justifyContent: 'flex-end' }}
					// >
					// 	<Button
					// 		startDecorator={<AttachFile />}
					// 		sx={{
					// 			fontSize: '15px',
					// 			fontWeight: 500,
					// 			backgroundColor: 'var(--color-technical)',
					// 			height: '26px',
					// 			mb: 4,
					// 			borderRadius: '2px',
					// 		}}
					// 		onClick={() => setExportDialog(true)}
					// 	>
					// 		Export Applications
					// 	</Button>
					// </Grid>
					<div className=''>
						
					</div>
				)}
			</Grid>

			{show && (
				<>
					<FilterSection />
				</>
			)}

			<ExportModal open={openExportDialog} setDialog={setExportDialog} />
		</>
	);
}

export default DealRoomSearch;
