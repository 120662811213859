import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from 'react-toastify';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FormHelperText, Grid, Input, Typography } from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PositionData } from '../../PositionData';
import { cleanupNationalId, fetchDirectorsById, onbaordCopyIdStep3, reset, updateBankInfos, updateDirector } from '../../features/onboard_partner/onboardPartnerSlice';
import NationalId from './NationalId';
import { useEffect } from 'react';
import { formDataFromObject } from '../../utils';
import { CircularProgress } from '@mui/material';
import BankPdf from '../onboard_wsp_files/BankPdf';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BankEdit = ({ open, handleClickOpen, handleClose, bankEdited }) => {
    const dispatch = useDispatch();
    const { isLoading, isLoadingCopyId,step5BankStmntFile, isLoadingUpdateBank, message, isSuccessUpdateBank, isErrorBank } = useSelector((state) => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState('')


    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };


    const updateBankDetails = () => {
        dispatch(updateBankInfos({
            bId: formData.bId,
            bName: formData.bName,
            bBranch: formData.bBranch,
            bAccNo: formData.bAccNo,
            bAccName: formData.bAccName,
            bFile: step5BankStmntFile.length > 0 ? (step5BankStmntFile) : (null)
        }));
    }

    useEffect(() => {
        setFormData(bankEdited)
        if (isSuccessUpdateBank) {
            toast.success('Bank Info updated successfully ');
            handleClose();
        }
        if (isErrorBank) {
            toast.warning(message);
        }
        return () => {
            dispatch(reset())
        }

    }, [dispatch, bankEdited, isSuccessUpdateBank,isErrorBank, message])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                {
                    isLoading ? (<><p>loading...</p></>)
                        : (<>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="h6" className="fmw-checkbox-label" style={{ display: 'flex', alignItems: 'center' }}>Editing:
                                    <Typography sx={{ ml: 1 }} component="p" className="fmw-onboard-partner-sub-label-modal">{formData?.bName}</Typography>
                                </Typography>

                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Bank
                                        </Typography>
                                        <Input
                                            label=""
                                            type="text"
                                            value={formData?.bName}
                                            name="bName"
                                            onChange={handleChange}
                                            size="lg"
                                            className="fmw-input-joy"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Branch
                                        </Typography>
                                        <Input
                                            label=""
                                            type="text"
                                            value={formData?.bBranch}
                                            name="bBranch"
                                            onChange={handleChange}
                                            size="lg"
                                            className="fmw-input-joy"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Account number
                                        </Typography>
                                        <Input
                                            label=""
                                            type="text"
                                            value={formData?.bAccNo}
                                            name="bAccNo"
                                            onChange={handleChange}
                                            size="lg"
                                            className="fmw-input-joy"
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Account name
                                        </Typography>
                                        <Input
                                            label=""
                                            type="text"
                                            value={formData?.bAccName}
                                            name="bAccNo"
                                            onChange={handleChange}
                                            size="lg"
                                            className="fmw-input-joy"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <BankPdf formData={bankEdited}/>
                                </Grid>
                                </Grid>


                            </DialogContent>
                            <DialogActions>
                                {!isLoadingUpdateBank ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                                        onClick={updateBankDetails}
                                    >
                                        Update Bank information
                                    </Button>
                                ) :

                                    (
                                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                            <CircularProgress size={17} color="inherit" />&nbsp;Updating...
                                        </Button>
                                    )}
                            </DialogActions></>)


                }
            </BootstrapDialog>
        </div>
    );
}
export default BankEdit