import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/page_title/PageTitle'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import { Typography } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatPhoneNumber } from '../../../utils'
import FileButton from '../../../components/button/FileButton'
import WaterDemandTable from '../../../components/committed_project/WaterDemandTable';
import CustomAccordion from '../../../components/accordion/custom_accordion';
import CustomTable from '../../../components/accordion/custom_table';
import { fetchCommittedDetails } from '../../../features/committed_project/committedSlice'

const ViableStep1 = () => {
    const { id } = useParams();
    const [expressLoading, setExpressLoading] = useState(false);

    const { wrSpecialMinFile, wrWaterAdequacy, wrWaterReliablity,
        wrNemaAuthorizationPermitFile, wrHydroAssessmentReport, wrHydroAssessmentReportFile, wrWaterQualityAnalysis, wrWaterQualityAnalysisFile,
        wrWaterResourceRisk, wrWaterQuality, wrWraCompliance,
        wrWraAuthorizationPermitFile, wrWraPermitFile, wrNemaComliance
    } = useSelector((state) => state.committedProj.committedd)

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const dispatch = useDispatch()
    const projectData = useSelector(
        (state) => state.expressionDemandData.expressionData
    );

    const singleProjectData = projectData?.find(
        (item) => item?.projId === Number(id)
    );



    function createData(
        name,
        desc,
        file,

    ) {
        return {
            name,
            desc,
            file,

        };
    }

    useEffect(() => {
        dispatch(
            fetchCommittedDetails({
                wrProjId: id,
            }))
    }, [])


    const waterResource = [
        createData("Water Resource", wrWaterAdequacy, wrSpecialMinFile),
        createData("Seasonal reliability", wrWaterAdequacy, "file"),
        createData("Water Quality", wrWaterQuality, "file"),
        createData("Statutory compliance - WRA", wrWraCompliance, wrWraPermitFile),
        createData("Statutory compliance - NEMA", wrNemaComliance, wrNemaAuthorizationPermitFile),
        createData("Water Resource Risks", wrWaterResourceRisk, "file"),
        createData("Hydrological Assessment Report", wrHydroAssessmentReport, wrHydroAssessmentReportFile),
        createData("Water Quality Analysis", wrWaterQualityAnalysis, wrWaterQualityAnalysisFile),
    ];

    console.log(waterResource, "Water Resources here");

    return (
        <>
            {
                expressLoading ? (
                    <>
                        <Skeleton width={1450} height={50} />
                        <Skeleton width={1450} height={50} />
                    </>
                ) : (
                    <>
                        <PageTitle currentPage="Project Preparation" subTitle="Committed Project" subHeader={singleProjectData?.accTradeName} />
                        <Grid container spacing={2} sx={{ mt: '0px' }}>
                            <Grid item xs={6} >

                            </Grid>
                            <Grid item xs={6}  >
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', }} className='gap-5 '>
                            <Typography className="fmw-technical-label-committed">
                                {singleProjectData?.accTradeName}
                            </Typography>
                            <Divider orientation='vertical' className='divider-sub' />
                            <Typography className="fmw-technical-label-committed">
                                SSWP No: 004
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mb: '39px' }}>
                            <Typography className="fmw-technical-label-committed" sx={{ fontSize: '18px !important', mt: 1 }}>
                                Contact Person:  {singleProjectData?.usrFirstName ?? "N/A"} {singleProjectData?.usrLastName ?? ""}   -   {formatPhoneNumber(singleProjectData?.usrMobileNumber)}
                            </Typography>
                        </Grid>
                        <Box sx={{ mt: "66px" }}>
                            <Divider sx={{ border: "1px solid #E8F8FE", }} />
                            <Box sx={{ p: "16px", display: "flex", gap: 5 }}>
                                <Typography sx={{ fontWeight: 500 }}>Minute for Loan Application</Typography>
                                <FileButton title={wrSpecialMinFile} titlePreview={wrSpecialMinFile} />
                            </Box>
                            <Divider sx={{ border: "1px solid #E8F8FE", }} />
                            <CustomAccordion
                                expanded={expanded === 'panel1'}
                                handleChange={handleChange('panel1')}
                                title="Water resources"
                            >
                                <CustomTable data={waterResource} />
                            </CustomAccordion>
                            <CustomAccordion
                                expanded={expanded === 'panel2'}
                                handleChange={handleChange('panel2')}
                                title="Water demand assessment"
                            >
                                <WaterDemandTable />
                            </CustomAccordion>

                        </Box>
                    </>
                )
            }

        </>
    )
}

export default ViableStep1;