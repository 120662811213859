import OfferCard from './OfferCard';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllWspOffers } from '../../features/wsp_offers/offerSlice';
import { useEffect } from 'react';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import {useNavigate} from "react-router-dom"
import DealRoomSkeleton from '../deal_room_list/DealRoomSkeleton';

export default function OfferCardList() {
	const { offers, loading } = useSelector((state) => state.offer);
	const { wspApplications } = useSelector((state) => state.wsp);
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	console.log("wspApplicationswspApplications",wspApplications[0]);
	const projAccIdUser = wspApplications?.length ? wspApplications[0] : null;

	useEffect(() => {
		dispatch(fetchAllWspOffers(projAccIdUser?.projAccId));
	}, []);

	
	useEffect(() => {

		  dispatch((fetchWspApplications()))
	
	  }, [])

	if (loading) {
		return (
			<div style={{marginTop: '20px'}}>
				<DealRoomSkeleton />
			</div>
		)
	}

	return (
		<>
			{offers?.length ? (
				offers?.map((offer) => {
					return (
						<>
							<OfferCard
								title={offer.accTradeName}
								project={offer.wsp}
								sheet={true}
								projId={offer.projId}
								ltcId={offer.ltcId}
								amount={offer.ltcLoanAmnt}
								parnerIcon={offer.accIcon}
							/>
						</>
					);
				})
			) : (
				<h2>No Offers Available</h2>
			)}
		</>
	);
}
