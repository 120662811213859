import { Box, Button, CircularProgress, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getPdfInteractions,
  getToolTipData,
  postAttachmentAlt,
  postToolTipData,
  resetLoader,
  setOpenPDfDialog,
  setPdfData,
} from "../../../features/expression_demand/expressionDemandSlice";
import { useEffect } from "react";
import RetryDialog from "./RetryDialog";

const ToolTipTableCell = ({
  PROJATYPE,
  dataId,
  setDialog,
  setProjData,
  hasFileName,
  fileName,
  projaProjId,
  resetFileName
}) => {
  const [open, setOPen] = useState(false);
  const [cellName, setCellName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // const proposalData = toolTipData.find((item) => item.projaType === PROJATYPE);

  /**
   * Function that uploads a file
   * @param {*} e
   */

  const checkFileName = async () => {
    console.log(hasFileName);
    toast.info("Kindly add file name.");
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (file.size > 1024 * 1024 * 10) {
      // limit file size to 10MB
      return toast.error("File Exceed 10 MBs");
    }
    if (file.type !== "application/pdf") {
      return toast.error("Please select a PDF file");
    }

    const formData = new FormData();

    const data = {
      projaProjId: dataId,
      projaDesc: fileName,
    };

    formData.append("projaProjId", dataId);
    formData.append("projaDesc", fileName);
    formData.append("file", file);

    await dispatch(postAttachmentAlt(formData));
    await dispatch(getToolTipData(dataId));
    await toast.success('File upload successful');
    await resetFileName()
    file = null;
  };

  useEffect(() => {}, [hasFileName]);

  return (
    <>
      {
        loading ? (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : !PROJATYPE ? (
          // <Button onClick={handleFileView}>
          //   <Typography
          //     sx={{
          //       display: "flex",
          //       gap: 1,
          //       alignItems: "center",
          //       fontSize: "14px",
          //       color: "#00BABA",
          //       textTransform: "capitalize",
          //     }}
          //   >
          //     <AttachFileIcon fontSize="small" />
          //     Added
          //   </Typography>
          // </Button>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            {hasFileName ? (
              <input
                onChange={handleFileUpload}
                type="file"
                id={"PROJATYPE"}
                name={"PROJATYPE"}
                style={{ opacity: 0, position: "absolute", left: "-9999px" }}
              />
            ) : (
              <input
                onClick={checkFileName}
                type=""
                id={"PROJATYPE"}
                style={{ opacity: 0, position: "absolute", left: "-9999px" }}
              />
            )}
            <label
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              htmlFor={"PROJATYPE"}
            >
              <AttachFileIcon fontSize="small" />
              Add
            </label>
          </Typography>
        ) : null

        // <Typography
        // 	sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}
        // >
        // 	<input
        // 		onChange={handleFileUpload}
        // 		type={hasFileName ? 'file' : ''}
        // 		id={PROJATYPE}
        // 		name={PROJATYPE}
        // 		style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
        // 	/>
        // 	<label
        // 		style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        // 		htmlFor={hasFileName ? PROJATYPE : ''}
        // 	>
        // 		<AttachFileIcon
        // 			sx={{
        // 				transform: 'rotate(45deg)',
        // 			}}
        // 			fontSize='small'
        // 		/>
        // 		Add
        // 	</label>
        // </Typography>
      }
      <RetryDialog onRetry={handleFileUpload} setOpen={setOPen} open={open} />
    </>
  );
};

export default ToolTipTableCell;
