import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { Button, Input } from '@mui/joy';
import { reset, updateLoanTermConditionsPartnerBids } from '../../features/deal_sourcing/dealSourcingSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const RejectDialog = ({ open, handleClickOpen, handleClose, id }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState('')
    const { ltcRejectReason } = formData
    const [isUpdating, setIsUpdating] = useState(false)
    const { isSuccessUpdateBids, message } = useSelector(state => state.dealSourcing)

    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
    const submitRejectionDetails = (e) => {
        if(!ltcRejectReason){
            toast.warning('Please provide your rejection reason ');
        }else {
            dispatch(updateLoanTermConditionsPartnerBids({
                ltcId: id,
                ltcStatus: "REJECTED",
                ltcRejectReason: ltcRejectReason
            }))
            handleClose();
            setFormData({ ltcRejectReason: '' })
        }
    }
    // useEffect(() => {
    //     if (isSuccessUpdateBids && open) {
    //         toast.success("Bidsssasas status updated successfully")
    //         handleClose();
    //     }

    // }, [isSuccessUpdateBids,message])
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Reason to Reject
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                label=""
                                type="text"
                                value={ltcRejectReason}
                                name="ltcRejectReason"
                                onChange={handleChange}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button
                        sx={{ mr: 3 }}
                        className="fmw-btn-secondary-auto"
                        onClick={submitRejectionDetails}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
export default RejectDialog;