import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import SingleItem from '../../components/single_item';
import { useLocation } from 'react-router-dom';
import SingleAttachment from '../../components/single_attachment';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import { dateConverter } from '../../../../utils';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}





export default function MeetingTable({ data }) {
  const rows = [
    createData('Date of AGM', data?.projAgmMinutesDate ? data?.projAgmMinutesDate : "N/A"),
    createData(<>Minutes of last AGM held on {dateConverter(data?.projAgmMinutesDate)}</>, data?.projMinAgmFile),
    createData('PDF of constitution/bylaws or articles of association', data?.projAssocArtFile),
    createData('PDF copy of minute stating project willing to seek a loan', data?.projSgmFile),
    createData(<>Minutes of First committee meeting held on {dateConverter(data?.projMin1Date)}</>, data?.projMin1File),
    createData(<>Minutes of second committee meeting held on {dateConverter(data?.projMin2Date)}</>, data?.projMin2File),
    createData(<>Minutes of third committee meeting held on {dateConverter(data?.projMin3Date)}</>, data?.projMin3File),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='basic'>
          {rows?.map((row) => (
            <TableRow

            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                {row?.name}
              </TableCell>
              {
                row?.name !== 'Date of AGM' ? (
                  <TableCell align="left">
                    <SingleAttachmentComplete
                      title={row?.calories}
                      tableFile="true"
                    />
                  </TableCell>
                ) : (

                  <TableCell component="th" scope="row" sx={{ width: '50%' }}>
                    {row?.calories}
                  </TableCell>
                )
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}