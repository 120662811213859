import { Box, Button, Divider, Typography } from '@mui/joy'
import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import SupportProject from '../../components/support_project/SupportProject'
import { cleanupFormWithPartner } from '../../features/wsp/wspSlice'
import { useDispatch } from 'react-redux'
import { cleanupForm } from '../../features/onboard_partner/onboardPartnerSlice'
 

function SupportMyProject() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [partnerForm,setPartnerForm] =  useState(false)
    const onboardPartner =(e)=>{
        dispatch(cleanupFormWithPartner());
        dispatch(cleanupForm());
        setPartnerForm(true)
        navigate(`/onboard-wsp`)
    }
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography component="h3" className="fmw-page-title">
                        Support my project
                    </Typography>
                    <Grid item>
                        <Button color="neutral" size="sm" className="fmw-btn-secondary-auto" sx={{ px: 2 }}
                        onClick={onboardPartner}
                        >
                            Add New Project
                        </Button>
                    </Grid>

                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Divider />
                </Grid>
                <SupportProject />
            </Grid>
        </>
    )
}

export default SupportMyProject