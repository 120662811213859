import { Button, Card, Form } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthSideImage from "../../components/auth/AuthSideImage";
import leftArrow from "../../assets/chevron-left.svg";
import logo from "../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import { toast } from "react-toastify";

function VerifyOtp() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const [otp, setOtp] = useState("");
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const { authLoading, institution } = useSelector((state) => state.auth);

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = value;
    setVerificationCode(updatedVerificationCode);
  };

  const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
  const [activeResend, setActiveResend] = useState(false);
  const resendOTP = async () => {
    setCounter({ minutes: 0, seconds: 59 });
    setVerificationCode(["", "", "", ""])
    // const res =  await dispatch(resendSignupOtp({
    //   email:institution?.jsonData?.instEmail,
    //   phoneNumber:institution?.jsonData?.instWorkTel
    // }));
    // if (res?.type === "authSlice/resendOtp/fulfilled") {
    //   setActiveResend(true);
    //   setCounter({ minutes: 0, seconds: 59 });
    // } else {
    // }
  };
  const handleNext = () => {
    navigate("/password-field");
  };

  const cancatenatedString = verificationCode?.join("");

  const handleOtp = () => {
    // navigate("/signup-wsp/business-details")


    if (cancatenatedString !== institution?.data?.admin?.usrSalt) {
      toast.error("OTP not matched, please try again");
    } else {
      toast.success("OTP matched successfully");
      setVerificationCode(["", "", "", ""]);
      navigate("/password-field");
    }
  };

  const handleVerifyOtp = async () => {
    // navigate("/signup-wsp/business-details")


    // const res = await dispatch(
    //   verifySignupOtp({
    //     usrId: institution?.data?.admin?.usrId,
    //     usrSalt: cancatenatedString,
    //   })
    // );
    // if (res?.payload) {
    //   toast.success("OTP matched successfully");
    //   setVerificationCode(["", "", "", ""]);
    //   navigate("/password-field");
    // } else {
    //   toast.error("OTP not matched, please try again");
    // }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter.minutes === 0 && counter.seconds === 0) {
        clearInterval(interval);
      } else {
        setCounter((prevCounter) => {
          if (prevCounter.seconds === 0) {
            return { minutes: prevCounter.minutes - 1, seconds: 59 };
          } else {
            return { ...prevCounter, seconds: prevCounter.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);
  return (
    <>
      <div className="lg:grid lg:grid-cols-6">
        <div className="bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block">
          <AuthSideImage hideStepper={false} hideText={false} current={0} />
        </div>
        <div className="lg:col-span-4 ">
          <div
            className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[88px] items-center justify-center flex cursor-pointer"
            onClick={() => navigate("/signup-wsp/basic-details")}
          >
            <img src={leftArrow} alt="backarrow" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={logo} alt="logo" style={{ marginTop: "10px" }} />
            <div class="text-center text-blackest text-[28px] font-bold font-dmSans leading-10">
              Enter the four digit code <br />
              sent to your email
            </div>

            <div class="w-[497px] text-center text-gray text-sm font-normal font-dmSans leading-tight mt-[29px]">
              This will help us secure your account by verifying it’s really
              you.
            </div>

            <div className="mt-[36px] flex justify-between">
              <Form
                onFinish={activeResend ? handleVerifyOtp : handleOtp}
                layout="vertical"
                ref={formRef}
                name="control-ref"
                form={form}
              >
                {/* start point otp */}
                <div className="mt-[40px] mb-[60px]">
                  <div className="flex flex-col space-y-16">
                    <div className="flex flex-row items-center justify-between mx-auto gap-x-10 w-full max-w-xs">
                      {inputRefs?.map((ref, index) => (
                        <div key={index} className="w-16 h-16">
                          <input
                            required
                            ref={ref}
                            className="w-[78.14px] h-[96px] text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                            type="text"
                            maxLength="1"
                            value={verificationCode[index]}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="text-center text-darkBlue text-sm font-normal font-['DM Sans'] leading-[18.20px]">
                  Resend code?
                  {counter.minutes === 0 && counter.seconds === 0 ? (
                    <Button
                      className="text-white bg-darkBlue ml-3"
                      onClick={resendOTP}
                      disabled={counter.minutes > 0 && counter.seconds > 0}
                      type="primary"
                    >
                      Resend
                    </Button>
                  ) : (
                    <span className="text-center text-darkBlue text-sm font-normal font-['DM Sans'] leading-[18.20px]">
                      {" "}
                      Resend in {counter.minutes.toString().padStart(2, "0")}:
                      {counter.seconds.toString().padStart(2, "0")}
                    </span>
                  )}
                </div>
                {/* end point otp */}
                <div className="text-center mt-[72px]">
                  <button
                    className="w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal"
                    type="submit"
                    // onClick={() => navigate("/signup-wsp/business-details")}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>

            <div className="absolute bottom-1 mb-[30px] mt-10 text-center text-darkBlue text-[14px] font-normal font-['Inter'] leading-snug">
              Privacy Policy • Terms and Conditions
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOtp;
