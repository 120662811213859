import { Box, Typography } from '@mui/material'
import React from 'react'

import { formatPath } from '../../../utils';
import FileButton from '../../button/FileButton';

const ManagementCard = ({ data }) => {
    const { dirName, dirPhone, dirPosition, dirCopyOfId, dirDuration } = data;
    return (<Box
        sx={{
            backgroundColor: "#FEFEFF",
            display: "flex",
            flexDirection: "column",
            p: 2,
            borderRadius: 4,
            mt: 2,
            mr: 5,
        }}
    >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                {`${dirName} (${dirPosition})`}
            </Typography>
            <Typography sx={{ mb: 1, }}>
                Duration: {dirDuration} years
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <span>ID:</span>  <FileButton title={formatPath(dirCopyOfId)} titlePreview={dirCopyOfId} viable="true" />
        </Box>
        <Typography sx={{ mt: 2, color: '#667085' }}>
            Phone: +{dirPhone}
        </Typography>
    </Box>
    )
}

export default ManagementCard