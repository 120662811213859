import { Radio, Typography } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setHydrologicalReport } from '../../features/committed_project/committedSlice';
import ImageInput from './ImageInput';

function Hydrological() {
    const { hydrologicalReport, committedd, waterResources,
    } = useSelector((state) => state.committedProj)
    const dispatch = useDispatch()
    const [isUpdating, setIsUpdating] = useState()

    const [selectedValueStatutory, setSelectedValueStatutory] = useState(waterResources?.wrHydroAssessmentReport || hydrologicalReport);
    const handleRadioChangeStatutory = (value) => {
        setSelectedValueStatutory(value);
        setIsUpdating(true);
    };
    const handleRadioClickStatutory = (value) => {
        if (selectedValueStatutory === value) {
            setSelectedValueStatutory(null);
        } else {
            setSelectedValueStatutory(value);
        }
        setIsUpdating(true);
    };

   
    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(setHydrologicalReport(selectedValueStatutory))
        }
    }, [dispatch, isUpdating, selectedValueStatutory]);

    useEffect(() => {
        setSelectedValueStatutory(waterResources)
    }, [committedd])


    useEffect(() => {
        setSelectedValueStatutory(waterResources?.wrHydroAssessmentReport)
    }, [committedd, waterResources])

    return (
        <>
            <Grid item xs={12} sx={{ mt: '1rem' }}>
                <Typography component="h6" className="fmw-checkbox-label-committed" sx={{ mb: '.8rem' }}>
                    Hydrological Assessment Report Provided
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <Radio
                    size="lg"
                    label="Yes"
                    className='fmw-radio-label'
                    value="Yes"
                    checked={selectedValueStatutory === 'Yes'}
                    onChange={() => handleRadioChangeStatutory('Yes')}
                    onClick={() => handleRadioClickStatutory('Yes')}
                    slotProps={{ input: { 'aria-label': 'Yes' } }}
                />
            </Grid>
            <Grid item xs={3}>
                <Radio
                    size="lg"
                    label="Required but not provided"
                    className='fmw-radio-label'
                    value="Required but not provided"
                    checked={selectedValueStatutory === 'Required but not provided'}
                    onChange={() => handleRadioChangeStatutory('Required but not provided')}
                    onClick={() => handleRadioClickStatutory('Required but not provided')}
                    slotProps={{ input: { 'aria-label': 'Required but not provided' } }}
                />
            </Grid>

            <Grid item xs={3}>
                <Radio
                    size="lg"
                    label="Not required"
                    className='fmw-radio-label'
                    value="Not required"
                    checked={selectedValueStatutory === 'Not required'}
                    onChange={() => handleRadioChangeStatutory('Not required')}
                    onClick={() => handleRadioClickStatutory('Not required')}
                    slotProps={{ input: { 'aria-label': 'Not required' } }}
                />
            </Grid>

            <Grid item xs={4}>
                <Box sx={{ mt: '10px' }}>
                    <ImageInput title="Attach hydrological assesment report" />
                </Box>
            </Grid>

        </>
    )
}

export default Hydrological
