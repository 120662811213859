import { FilePresentOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
	AccountCircle,
	FirstPage,
	Flag,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
	MoreHoriz,
	Visibility,
} from '@mui/icons-material';
import { cashConverter } from '../../utils';
import PropTypes from 'prop-types';
import {
	Box,
	IconButton,
	TableFooter,
	TablePagination,
	Tooltip,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import '../../pages/components/committed-menu.css';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { setLoanPerfection } from '../../features/project/projectSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#F4FCFF',
		color: '#222',
		fontSize: '16px',
		fontWeight: 500,
		fontFamily: 'DM Sans',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		// backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label='first page'
			>
				{theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label='previous page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='next page'
			>
				{theme.direction === 'rtl' ? (
					<KeyboardArrowLeft />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label='last page'
			>
				{theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function DealList({ data, search, category, subUrl }) {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - expressionData?.length)
			: 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleUrlChange = async (url, obj) => {
		console.log("URL-------", url);
		console.log(subUrl);
		if (subUrl === "loan-perfection") {
			await dispatch(setLoanPerfection(obj));
		}
		await navigate(`/${subUrl}/` + url)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const filteredData = data?.filter((item) => {
		return (
			item?.accTradeName?.toString().toLowerCase().includes(search) ||
			item?.wsp?.toString().includes(search.toString())
		);
	});

	useEffect(() => {}, [search]);

	console.log("DATA________________-------------", data);

	return (
		<>
			<TableContainer component={Paper} elevation={0}>
				<Table
					sx={{ minWidth: 500 }}
					aria-label='custom pagination table'
					className='intro-tables'
				>
					<TableHead>
						<TableRow>
							<StyledTableCell align='left'>Project</StyledTableCell>
							<StyledTableCell align='left'>Region</StyledTableCell>
							<StyledTableCell align='left'>Application cost</StyledTableCell>
							<StyledTableCell align='left'>Action</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredData?.length == 0 ? (
							<TableRow>
								<StyledTableCell colSpan={4}>No data found</StyledTableCell>
							</TableRow>
						) : (
							<>
								{(rowsPerPage > 0
									? filteredData?.slice(
											page * rowsPerPage,
											page * rowsPerPage + rowsPerPage,
									  )
									: filteredData
								).map((row) => (
									<StyledTableRow key={row.projId}>
										<StyledTableCell component='th' scope='row'>
											<Box
												sx={{ display: 'flex', alignItems: 'center' }}
												className='gap-5'
											>
												<div>
													{
														<AccountCircle
															sx={{
																fontSize: '3rem',
																color: 'var(--color-technical)',
															}}
														/>
													}
												</div>
												<div className='capitalize'>{row?.wsp}</div>
											</Box>
										</StyledTableCell>

										<StyledTableCell align='left' className='capitalize'>
											{row?.accLocation ?? +
												' ' +
												',' +
												row?.accSubCounty +
												' ' +
												',' +
												row?.accCounty}
										</StyledTableCell>

										<StyledTableCell align='left'>
											{cashConverter(row?.projCost)}
										</StyledTableCell>

										<StyledTableCell
											// onClick={() => handleClick()}
											align='left'
											sx={{ cursor: 'pointer', textDecoration: 'underline' }}
										>
											<div>
												<MoreHorizIcon
													id='basic-button'
													aria-controls={open ? 'basic-menu' : undefined}
													aria-haspopup='true'
													aria-expanded={open ? 'true' : undefined}
													onClick={handleClick}
												/>

												<Menu
													elevation={0}
													className='committed-menu'
													id='fade-deal'
													MenuListProps={{
														'aria-labelledby': 'basic-button',
													}}
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
													TransitionComponent={Fade}
												>
													<MenuItem onClick={() => handleUrlChange(row?.wsp, row)}>
														View project
													</MenuItem>
													<MenuItem onClick={handleClose}>
														View term sheet
													</MenuItem>
													{/* <MenuItem onClick={handleClose}>
														Update term sheet
													</MenuItem> */}
												</Menu>
											</div>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooter sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<TableRow>
					<TablePagination
						className='fmw-pagination'
						rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
						colSpan={3}
						count={filteredData?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							native: true,
						}}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</TableRow>
			</TableFooter>
			{/* 
			<div style={{ width: '187px', backgroundColor: 'red' }}>
				<Menu
					elevation={0}
					className='committed-menu'
					id='fade-menu'
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem onClick={handleClose}>View project</MenuItem>
					<MenuItem onClick={handleClose}>View term sheet</MenuItem>
					<MenuItem onClick={handleClose}>Update term sheet</MenuItem>
				</Menu>
			</div> */}
		</>
	);
}

export default DealList;
