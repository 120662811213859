import { Box, Divider, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Input, Textarea } from "@mui/joy";
import React, { useState } from "react";
import RatingsStar from "./RatingsStar";
import { BorderColor, Edit } from "@mui/icons-material";
import SingleComment from "../../expression_demand/components/SingleComment";
import MatrixInput from "../../expression_demand/components/MatrixInput";
import UserMatrix from "../../expression_demand/components/UserMatrix";
import { useLocation } from "react-router-dom";


const SingleItem = ({
  overallComment, title, value, valueInput, noCommentScore,
  name, stepName, id, setOnblur, onBlur, maxScores, status, comment, setComment,
  userComment, setUserComment, setMatrixInfo, matrixInfo, row,
  userMatrixInfo, setUserMatrixInfo
}) => {
  const [editing, setEditing] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  return (
    <>
      {
        category == 'Partner' ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>


            <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 2, display: 'flex', alignItems: 'center' }}>
              {row?.name} | {row?.description}

            </Typography>



            <>
              {
                userComment[name] ? (<>
                  <Typography>{userComment[name]}</Typography>
                </>) : (
                  <Typography>No Comment</Typography>
                )
              }


              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  mb: 2,
                  gap: 6,
                  alignItems: "center",
                }}
              >

                <Typography
                  className="gap-5"
                  sx={{
                    color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                  }}>
                  System score:

                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row?.variableName}
                    stepName={stepName}
                    id={id}
                    maxScores={row?.maxScores}
                  />
                </Typography>
                <Box
                  sx={{
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                    bgColor: "red",
                    gap: 2,
                  }}
                >
                  <Typography sx={{
                    display: "flex",
                    alignItems: "center", gap: 1,
                    color: '#0873B9',

                  }}>
                    Technical advisor score:
                    <UserMatrix
                      setMatrixInfo={setUserMatrixInfo}
                      matrixInfo={userMatrixInfo}
                      name={row?.variableName}
                      stepName={stepName}
                      id={id}
                      maxScores={row?.maxScores}
                    />

                  </Typography>

                </Box>
              </Box>
            </>


          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>

            {
              !overallComment && (
                <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 1, display: 'flex', alignItems: 'center' }}>
                  {row?.name} | {row?.description}
                  {
                    userComment[name] && (
                      <Tooltip title="Edit comment" arrow placement="top" >
                        <IconButton onClick={() => setEditing(!editing)} >
                          <BorderColor sx={{ fontWeight: 'inherit', fontSize: 'inherit', color: 'var(--color-technical)' }} />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </Typography>
              )
            }

            {
              !noCommentScore && (
                <>
                  {
                    (userComment[name] && (!editing)) ? (<>
                      <Typography>{userComment[name]}</Typography>
                    </>) : (
                      <SingleComment
                        userComment={userComment}
                        setUserComment={setUserComment}
                        name={name}
                        stepName={stepName}
                        id={id}
                        setOnblur={setOnblur}
                        onBlur={onBlur}
                        maxScores={maxScores}
                        status={status}
                        comment={comment}
                        setComment={setComment}
                        setEditing={setEditing}
                      />
                    )
                  }


                  <Box
                    sx={{
                      display: "flex",
                      mt: 0,
                      mb: 2,
                      gap: 6,
                      alignItems: "center",
                    }}
                  >

                    <Typography sx={{ color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px' }}>System score: {matrixInfo[name]}</Typography>
                    <Box
                      sx={{
                        width: "300px",
                        display: "flex",
                        alignItems: "center",
                        bgColor: "red",
                        gap: 2,
                      }}
                    >
                      <Typography sx={{
                        display: "flex",
                        alignItems: "center", gap: 1
                      }}>
                        Your Score:
                        <UserMatrix
                          setMatrixInfo={setUserMatrixInfo}
                          matrixInfo={userMatrixInfo}
                          name={row?.variableName}
                          stepName={stepName}
                          id={id}
                          maxScores={row?.maxScores}
                        />
                        {/* <Input
                      // name="usrFirstName"
                      // value={usrFirstName}
                      // onChange={handleChange}
                      type="number"
                      size="lg"
                      className="fmw-input-joy-score"
                    /> */}

                      </Typography>

                    </Box>
                  </Box>
                </>
              )
            }

          </Box>
        )
      }

    </>

  );
};

export default SingleItem;
