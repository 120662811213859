export const Benefit
    = [
    {
        value: 'New customers',
        label: 'New customers',
    },
    {
        value: 'All customers',
        label: 'All customers',
    },
    {
        value: 'Water project finances',
        label: 'Water project finances',
    },
    {
        value: 'Specific Customers',
        label: 'Specific Customers',
    },
]
