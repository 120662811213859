import { Button, Checkbox, TextField, Chip, Typography, FormHelperText } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, Image, Create, Delete, KeyboardArrowDown, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { cleanupNationalId, onbaordCopyIdStep3 } from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';




const NationalId = ({formData}) => {
    const { step3CopyId, isLoadingCopyId, } = useSelector((state) => state.onboardPartner)
    const [fileId, setFileId] = useState("")
    const [isUpdating, setIsUpdating] = useState(false);
    const dispatch = useDispatch();
    const [fetchedNationalId, setFetchedNationalId] = useState(formData?.dirCopyOfId)
  
    const removeFetchedNationalId = () => {
        setFetchedNationalId('')
        setIsUpdating(true);
        dispatch(cleanupNationalId());
    }

    const { nameId, dateId, file_base64Id, typeId } = fileId;
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // id pdf
    const [errorId, setErrorId] = useState('')
    const [idPdf, setIdPdf] = useState(undefined)
    const [file, setFile] = useState(undefined)

    const handleId = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];

        if (!_file) return;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorId('Please upload a PDF file not more than 10mb ');
            setIdPdf('');
            return;
        }
        setFile(_file);
        setErrorId('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameId: _file.name,
                dateId: _date,
                typeId: _file.type,
                file_base64Id: base64String.toString()
            };
            setFileId(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordCopyIdStep3(formData));
    }

    const removeId = () => {
        setFileId({
            nameId: '',
            dateId: '',
            typeId: '',
            file_base64Id: undefined,
        });
    }
    return (
        <>
            <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '.5rem' }}>
                Attach copy of ID<span className="star">*</span>
            </Typography>
            {
                fetchedNationalId ? (
                    <>
                        <a className='file-stepper-primary' onClick={() => previewPaper(fetchedNationalId)}>
                            {formatPath(fetchedNationalId)}</a>
                        <IconButton
                            aria-label="close"
                            onClick={removeFetchedNationalId}
                            sx={{
                                color: (theme) => theme.palette.grey[500], mr: '6px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {
                            !nameId ? (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        border: 'none',
                                        // border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                    <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                </div>
                                                {
                                                    errorId ? (
                                                        <Typography component="h6" className="upload-sub-label-error">
                                                            {errorId}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="caption" className="upload-sub-label">
                                                            Click here to upload
                                                        </Typography>
                                                    )
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                name='file'
                                                value={nameId}
                                                accept=".pdf"
                                                webkitdirectory
                                                onChange={handleId}
                                                style={{ width: '0px', height: '0px' }}
                                            />

                                        </label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                        border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                            <span style={{ margin: "0px 6px" }}>
                                                {
                                                    typeId === "application/pdf" ? (
                                                        <PictureAsPdf sx={{
                                                            fontSize: '40px',
                                                            color: '#f44336'
                                                        }} />
                                                    ) :
                                                        typeId === "image/png" || "image/jpeg" || "image/gif" ? (
                                                            <Image sx={{
                                                                fontSize: '40px',
                                                                color: '#263238',
                                                            }} />
                                                        ) :
                                                            (
                                                                <Article sx={{
                                                                    fontSize: '40px',
                                                                    color: '#1976d2'
                                                                }} />
                                                            )
                                                }

                                            </span>
                                            <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography component="h6" className="fmw-upload-typography">
                                                {subString(nameId, 25)}
                                            </Typography>


                                            <Typography variant="caption" className="upload-sub-label">
                                                uploaded on  {_date}
                                            </Typography>
                                        </div>

                                        <div>
                                            <IconButton
                                                aria-label="close"
                                                onClick={removeId}
                                                sx={{
                                                    color: (theme) => theme.palette.grey[500], mr: '6px'
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default NationalId
