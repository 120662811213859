import React from "react";

import { Card, Typography, Box } from "@mui/material";

const HistoryCard = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: 1,
        p: 2,
        borderColor: "#E4E4E4",
        borderRadius: 2,
        bgcolor: "#FCFCFF",
        mb: 2,
        pb: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Status <span style={{ color: "#00BABA" }}>On-hold</span>
        </Typography>
        <Typography sx={{ color: "#00BABA" }}> TID:23424</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <span style={{ color: "#00BABA" }}> Mater water</span>
        <br />
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure, eaque
        totam.
      </Box>
    </Card>
  );
};

export default HistoryCard;
