import { FilePresentOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/joy'
import { Grid, Paper } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function PostImplementationList() {
    const navigate = useNavigate();
    const handlePerformance = (e) => {
        navigate(`/performance`)
    }
    const handleView = (e) => {
        navigate(`/refill-stations`)
    }
    return (
        <>
            <Grid item xs={12} style={{ paddingTop: '1.15rem' }}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                    <Box sx={{ p: 2, pb: 1 }}>
                        <Grid container spacing={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={2} sx={{ mb: 1 }} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Box>
                                    <Typography component="body2" className="list-avatar" />
                                </Box>
                            </Grid>
                            <Grid item xs={8} sx={{ mb: 1 }} >
                                <Box sx={{ mx: 2.5 }}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem' }}>
                                        Project: Refill stations
                                    </Typography>
                                    <Typography component="p" className="fmw-p" sx={{ mb: 1, width: '70%' }}>
                                        On the acceptance of the deal by the applicants, the financial institution will establish an output target to be archieved by the Wsp.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ my: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="neutral" size="sm" className="fmw-btn-primary" onClick={handleView}>
                                    View project
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ px: 2, py: 2 }} className="fmw-paper-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Grid item>
                            <Button color="neutral" size="sm" className="fmw-btn-secondary-auto" sx={{ px: 2 }}
                                onClick={handlePerformance}
                            >
                                Performance
                            </Button>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>

        </>
    )
}

export default PostImplementationList