import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/joy";
import { CircularProgress } from "@mui/material";
import { onboardPartner } from "../../../features/onboard_partner/onboardPartnerSlice";

export default function ConfirmationModal({
  open,
  projId,
  setOpenModal,
  message,
  type,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user,
    newUsers,
    isLoadingNewUser,
    isSuccessDelete,
    isLoadingDelete,
    isSuccessNewUser,
  } = useSelector((state) => state.auth);
  const { orgDetails, techDetails } = useSelector((state) => state.techAdv);



  const sendHandler = () => {
    if (type === "COMMITTED") {
      const rowd = dispatch(
        onboardPartner({
          projId: projId,
          projStatus: "COMMITTED",
          projCommittedId: user.usrId,
          projCommittedName: orgDetails.accTradeName
        })
      );
      navigate(`/deal-sourcing`);
      toast.success("Project sent to Technical Advisor for further Analysis");
    } else if (type === "VIABLE") {
      const rowd = dispatch(
        onboardPartner({
          projId: projId,
          projStatus: "APPROVED",
        })
      );
      navigate(`/expression-demand/Viable`);

    } else if (type === "WASH") {
      const rowd = dispatch(
        onboardPartner({
          projId: projId,
          projStatus: "WASH",
        })
      );
      navigate(`/expression-demand/wash`);
      toast.success("Project sent to Washed projects");

    }
    else if (type === "DRAFT") {
      const rowd = dispatch(
        onboardPartner({
          projId: projId,
          projStatus: "DRAFT",
        })
      );
      navigate(`/expression-demand/complete`);
      toast.success("Project sent back to user for Editing");
    }
    else if (type === "DEAL_SOURCING") {
      const rowd = dispatch(
        onboardPartner({
          projId: projId,
          projStatus: "DEAL_SOURCING",
        })
      );

      navigate(`/intro-committed-projects/committed`);
      toast.success("Project sent to deal room successfully");
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      // fullWidth={true}
      >
        <DialogTitle className="fmw-page-title">
          Confirm your request
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontSize: "1.05rem" }}
            >
              {message}?
            </Typography>
            <br />
            <Typography component="p" sx={{ fontSize: ".95rem" }}>
              NB: Once you send, the action is irreversible!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "var(--bg-primary) !important",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>

          {!isLoadingDelete ? (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "var(--bg-primary) !important",
              }}
              onClick={sendHandler}
            >
              Send
            </Button>
          ) : (
            <Button disabled variant="contained" sx={{ textTransform: "none" }}>
              <CircularProgress size={17} color="inherit" />
              &nbsp;Deleting User...
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
