import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}





export default function WaterSourceInfrastractureTable({data}) {
  const rows = [
    createData('Is the project existing and operational ?', data?.projExistingOperational),
    createData('Other existing sources within the water project area of operation', data?.projOtherExistingWaterSources ),
    createData('Is there a WSP or another SSWP operating within the same area of the applicant', data?.projOtherWspSswp ),
    createData(<>Average volume abstracted per day from all sources m<sup>3</sup>/day dry season</>,data?.projAverageVolumeDry
    ),
    createData(<>Average volume abstracted per day from source m<sup>3</sup>/day wet season</>, data?.projAverageVolWet),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic' >
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important',width:'50%' }}>
                {row.name}
              </TableCell>
              <TableCell align="left" >{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    </TableContainer>
  );
}