import React, { useEffect, useState } from 'react'
import PageTitle from '../../../components/page_title/PageTitle'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import { Typography } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { formatPhoneNumber } from '../../../utils'
import FileButton from '../../../components/button/FileButton'
import WaterDemandTable from '../../../components/committed_project/WaterDemandTable';
import CustomAccordion from '../../../components/accordion/custom_accordion';
import CustomTable from '../../../components/accordion/custom_table';
import ObjectorTable from './objector_table'
import { fetchCommittedDetails } from '../../../features/committed_project/committedSlice'
import EngineeringTable from './EngineeringTable'
import EnvironmentalTable from './EnvironmentalTable'
import QuotationTable from './QuotationTable'

const ViableStep2 = () => {
    const { id } = useParams();
    const [expressLoading, setExpressLoading] = useState(false);
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const dispatch = useDispatch()
    const projectData = useSelector(
        (state) => state.expressionDemandData.expressionData
    );

    const singleProjectData = projectData?.find(
        (item) => item?.projId === Number(id)
    );

    function createData(
        name,
        desc,
        file,
        contact

    ) {
        return {
            name,
            desc,
            file,
            contact

        };
    }

    const { wrNemaLicenseAvailable, wrNemaLicenseFile, wrEngBillOfQuantity,
        wrEngBillOfQuantityFile, wrEngTenderMaterialFile, wrEngConstructionPlanFile, wrEngTenderMaterial, wrEngConstructionPlan,
        wrEngSpecification, wrEngDrawingFile, wrEngDrawing, wrEiaReportProvided, wrEngDesignReport, wrEiaReportFile,
        wrEngSpecificationFile, wrStakeholdersAware, wrEngMap, wrEngMapFile } = useSelector((state) => state.committedProj.committedd)

    const environmentalData = [
        createData("Type of EIA Report Required", "wrEiaReportType", "file"),
        createData("NEMA License available", wrNemaLicenseAvailable, wrNemaLicenseFile),
        createData("EIA Report Provided", wrEiaReportProvided, wrEiaReportFile),
        createData("Are ALL stakeholders aware of project?", wrStakeholdersAware, "file"),
    ];


    const engineeringData = [
        createData("Map of supply area", wrEngMap, wrEngMapFile),
        createData("Engineering design report", wrEngDesignReport, wrEngDesignReport),
        createData("Drawings", wrEngDrawing, wrEngDrawingFile),
        createData("Specifications", wrEngSpecification, wrEngSpecificationFile),
        createData("Costed Bills of Quantities", wrEngBillOfQuantity, wrEngBillOfQuantityFile),
        createData("Construction Plan", wrEngConstructionPlan, wrEngConstructionPlanFile),
        createData("Tender materials", wrEngTenderMaterial, wrEngTenderMaterialFile),
    ];

    console.log(wrEngMap)





    useEffect(() => {
        dispatch(
            fetchCommittedDetails({
                wrProjId: id,
            }))
    }, [])

    return (
        <>
            {
                expressLoading ? (
                    <>
                        <Skeleton width={1450} height={50} />
                        <Skeleton width={1450} height={50} />
                    </>
                ) : (
                    <>
                        <PageTitle currentPage="Project Preparation" subTitle="Committed Project" subHeader={singleProjectData?.accTradeName} />
                        <Grid container spacing={2} sx={{ mt: '0px' }}>
                            <Grid item xs={6} >

                            </Grid>
                            <Grid item xs={6}  >
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: "46px" }}>
                            <CustomAccordion
                                expanded={expanded === 'panel1'}
                                handleChange={handleChange('panel1')}
                                title="Environmental Impact Statement"
                            >
                                <EnvironmentalTable viewOnly={true} />

                            </CustomAccordion>
                            <CustomAccordion
                                expanded={expanded === 'panel2'}
                                handleChange={handleChange('panel2')}
                                title="Engineering Design"
                            >
                                <EngineeringTable viewOnly={true} />
                            </CustomAccordion>
                            <CustomAccordion
                                expanded={expanded === 'panel3'}
                                handleChange={handleChange('panel3')}
                                title="Quotations"
                            >
                                <QuotationTable viewOnly={true} />
                            </CustomAccordion>

                        </Box>
                    </>
                )
            }

        </>
    )
}

export default ViableStep2;