import { cleanupWtrAnalysis, onbaordWtrAnalysisPdf, reset } from '../../features/onboard_partner/onboardPartnerSlice';
import {   Typography } from '@mui/joy'
import { Divider,  Grid, IconButton, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Article, AttachFile, CloudUploadOutlined, Image, PictureAsPdf} from '@mui/icons-material';
import { formatPath, previewPaper, subString } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';

function WaterQuality({formData}) {
    const dispatch = useDispatch();
    const {  wtrAnalysisPdf, step3WtrAnalysisPdf,isSuccessSaveWtrSrc,isSuccessUpdateWtrSrc} = useSelector((state) => state.onboardPartner)
    const { wspApplications, isLoadingWsp } = useSelector((state) => state.wsp)
    const [isUpdating, setIsUpdating] = useState(false);
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;
    // waterAnalysisPdf
    const [file, setFile] = useState("")
    const [fileWtrAnalysis, setFileWtrAnalysis] = useState(wtrAnalysisPdf)
    const { nameWtrAnalysis, dateWtrAnalysis, file_base64WtrAnalysis, typeWtrAnalysis } = fileWtrAnalysis;
    const [wtrPdf, setWtrPdf] = useState('')
    const [errorWtrPdf, setErrorWtrPdf] = useState('')

    const [fetchedWaterQualityFile, setFetchedWaterQualityFile] = useState(formData?.wsQualityFile)
    const removeFetchedWaterQualityFile = () => {
        setFetchedWaterQualityFile('')
    }
    // waterAnalysisPdf
    const handleWtrPdf = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;
        const allowedExtensions = /(\.pdf)$/i;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorWtrPdf(
							'Please upload a PDF file Only of size not more than 10mb',
						);
            setWtrPdf('');
            return;
        }
        setFile(_file);
        setErrorWtrPdf('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameWtrAnalysis: _file.name,
                dateWtrAnalysis: _date,
                typeWtrAnalysis: _file.type,
                file_base64WtrAnalysis: base64String.toString()
            };
            setFileWtrAnalysis(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordWtrAnalysisPdf(formData));
    }

    const removeWtrPdf = () => {
        setFileWtrAnalysis({
            nameWtrAnalysis: '',
            dateWtrAnalysis: '',
            typeWtrAnalysis: '',
            file_base64WtrAnalysis: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupWtrAnalysis());
    }
   useEffect(()=>{
    if(isSuccessSaveWtrSrc || isSuccessUpdateWtrSrc){
        setFileWtrAnalysis({
            nameWtrAnalysis: '',
        })
    }
    return()=>{
        dispatch(reset())
    }
   },[isSuccessSaveWtrSrc ,isSuccessUpdateWtrSrc])

    return (
        <>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                        PDF of water quality analysis of source water
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        fetchedWaterQualityFile ? (
                            <>
                                <a className='file-stepper-primary' onClick={() => previewPaper(fetchedWaterQualityFile)}>
                                    {formatPath(fetchedWaterQualityFile)}
                                </a>
                                <IconButton
                                    aria-label="close"
                                    onClick={removeFetchedWaterQualityFile}
                                    sx={{
                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>) : (<>
                                {
                                    !nameWtrAnalysis ? (
                                        <>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                border: 'none',
                                                // border: '1px solid var(--color-primary-light)',
                                                width: '337px',
                                                height: '46px'
                                            }}>
                                                <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                    <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                        <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                            <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                        </div>
                                                        {
                                                            errorWtrPdf ? (
                                                                <Typography component="h6" className="upload-sub-label-error">
                                                                    {errorWtrPdf}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="caption" className="upload-sub-label">
                                                                    Click here to upload
                                                                </Typography>
                                                            )
                                                        }
                                                    </div>
                                                    <input
                                                        type="file"
                                                        name='nameWtrAnalysis'
                                                        value={nameWtrAnalysis}
                                                        accept=".pdf"
                                                        webkitdirectory
                                                        onChange={handleWtrPdf}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                border: '1px solid var(--color-primary-light)',
                                                width: '337px',
                                                height: '46px'
                                            }}>
                                                <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                    <span style={{ margin: "0px 6px" }}>
                                                        {
                                                            typeWtrAnalysis === "application/pdf" ? (
                                                                <PictureAsPdf sx={{
                                                                    fontSize: '40px',
                                                                    color: '#f44336'
                                                                }} />
                                                            ) :
                                                                typeWtrAnalysis === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                    <Image sx={{
                                                                        fontSize: '40px',
                                                                        color: '#263238',
                                                                    }} />
                                                                ) :
                                                                    (
                                                                        <Article sx={{
                                                                            fontSize: '40px',
                                                                            color: '#1976d2'
                                                                        }} />
                                                                    )
                                                        }

                                                    </span>
                                                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                    <Typography component="h6" className="fmw-upload-typography">
                                                        {subString(nameWtrAnalysis, 25)}
                                                    </Typography>


                                                    <Typography variant="caption" className="upload-sub-label">
                                                        uploaded on  {_date}
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={removeWtrPdf}
                                                        sx={{
                                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default WaterQuality
