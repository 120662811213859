import React from "react";
import { Chip, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Events = () => {
  return (
    <>
      <Typography sx={{ mt: 1 }}>
        Status <span style={{ color: "#0069DF" }}>On-hold</span>
      </Typography>
     
            <Typography sx={{ color: "#00C3E6" }}>Trigger</Typography>
            <Typography sx={{ fontSize: 14, color: "#A0A0A0" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
            <Box sx={{width:'2px',height:'15px', mt:1.5, bgcolor:'#E4E4E4'}}>{}</Box>
            <Box sx={{width:'10px',height:'10px', borderRadius: "50%", transform: "translate(-50%, -50%)",mt:1.5 ,bgcolor:'#E4E4E4'}}>{}</Box>
            <Box sx={{width:'2px',height:'15px', mb:1.5,bgcolor:'#E4E4E4'}}>{}</Box>
            <Typography sx={{ color: "#00C3E6" }}>Actuator</Typography>
            <Typography sx={{ fontSize: 14, color: "#A0A0A0" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography>
          
    </>
  );
};

export default Events;
