import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import leftArrow from "../../assets/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import AuthSideImage from "../../components/auth/AuthSideImage";
import { formatPath, numberWithCommas } from "../../utils";
import addIcon from "../../assets/plus-01.svg";
import team from "../../assets/About Our Team 1.svg";
import { countryPhoneCodes } from "../../phoneCountryCodes";
import { setManagementDetails } from "../../features/wsp_registration/wspRegSlice";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;

const selectBefore = (
  <Select defaultValue="+254">
    {countryPhoneCodes.map((item) => {
      return (
        <>
          <Option key={item?.code} value={item?.dial_code}>
            {item?.code} ({item?.dial_code})
          </Option>
        </>
      );
    })}
  </Select>
);

function ManagementDetails() {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { managementDetails } = useSelector((state) => state.wspReg);

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const [data, setdata] = useState({});

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");

  const [adding, setAdding] = useState(false);
  const [management, setManagement] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [manageArray, setmanageArray] = useState(managementDetails);
  const handleAdd = () => {
    setAdding(true);
    setManagement(false);
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const handleNumberChange = (e) => {
    setdata((prevData) => ({
      ...prevData,
      conMobileNumber: "254" + String(e),
    }));
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  const options = [
    {
      label: "One",
      value: 1,
    },
    {
      label: "Two",
      value: 2,
    },
    {
      label: "three",
      value: 3,
    },
  ];

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const props = {
    name: "file",
    multiple: false,
    // accept: 'image/png, image/jpeg, .pdf',
    accept: ".pdf",
    action: `${url}/usr/postImages.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = async () => {
    console.log("onFinish")
    await dispatch(setManagementDetails(manageArray));
    navigate("/signup-wsp/project-description");
  };

  function handleSaveManagement(values) {
    console.log("handleSaveManagement");
    values.dirCopyOfId = fileUrl;
    setmanageArray([...manageArray, values]);
    setManagement(true);
    setAdding(false);
    form.resetFields();
  }

  useEffect(() => {console.log("manageArray",manageArray)}, [manageArray]);

  useEffect(() => {
    setmanageArray(managementDetails);
  }, [managementDetails]);

  return (
    <>
      <div className="lg:grid lg:grid-cols-6">
        <div className="bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block">
          <AuthSideImage hideStepper={false} hideText={false} current={2} />
        </div>
        <div className="lg:col-span-4 ">
          <div
            className="border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[88px] items-center justify-center flex cursor-pointer"
            onClick={() => navigate("/signup-wsp/business-details")}
          >
            <img src={leftArrow} alt="backarrow" />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={logo} alt="logo" style={{ marginTop: "10px" }} />
            <div className="text-center text-blackest text-[28px] font-bold font-dmSans leading-[44px] mt-[25px]">
              Let’s get started
            </div>
            <div className="w-[497px] text-center text-gray text-[14px] font-normal font-dmSans leading-tight">
              All we need is a few company details. <br />
              Then you'll be set up and able to apply for financing.
            </div>

            <div className="mt-[39px] flex justify-center ">
              {adding ? (
                <>
                  <Form
                    layout="vertical"
                    ref={formRef}
                    name="control-ref"
                    onFinish={handleSaveManagement}
                    className="lg:w-[740px] w-auto bg-[#F0F5FF] px-[83px] py-[42px] mb-[80px]"
                    form={form}
                  >
                    <div className="grid grid-cols-1 mb-[-23px]">
                      <div className="text-[#1D2939] text-[20px] font-medium font-dmSans leading-7 text-center justify-center mb-[32px]">
                        Add member
                      </div>
                      <Form.Item
                        name="dirName"
                        extra="Full name as it appears on identification document"
                        rules={[
                          {
                            required: true,
                            message:
                              "Full name as it appears on identification document",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Name of member"
                          className="reg-input"
                        />
                      </Form.Item>

                      <Form.Item
                        extra={"Choose option"}
                        name="dirPosition"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          style={{
                            height: "52px",
                          }}
                          placeholder={"Position of member"}
                          size="large"
                          onChange={(values) => {
                            console.log(values);
                          }}
                          className="reg-input"
                          dropdownRender={(menu) => (
                            <>
                              <Space
                                style={{
                                  padding: "8px 8px 4px",
                                }}
                              >
                                <span className="select-info mt-1">
                                  You may choose one or more of the following
                                </span>
                              </Space>
                              <Divider
                                style={{
                                  margin: "8px 0",
                                }}
                              />
                              {menu}
                            </>
                          )}
                          menuItemSelectedIcon={({ isSelected }) => (
                            <>
                              {isSelected ? (
                                <Checkbox checked={true}></Checkbox>
                              ) : (
                                <Checkbox checked={false}></Checkbox>
                              )}
                            </>
                          )}
                        >
                          <Select.Option
                            onClick={() => console.log("Chairman")}
                            value={"Chairman"}
                          >
                            Chairman
                          </Select.Option>
                          <Select.Option value={"Treasurer"}>
                            Treasurer
                          </Select.Option>
                          <Select.Option value={"Secretary"}>
                            Secretary
                          </Select.Option>
                          <Select.Option value={"V-Secretary"}>
                            V-Secretary
                          </Select.Option>
                          <Select.Option value={"Committee member"}>
                            Committee member
                          </Select.Option>
                          <Select.Option value={"Other"}>Other</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="dirDuration"
                        extra="How long the member has been in the committee"
                        rules={[
                          {
                            required: true,
                            message:
                              "How long the member has been in the committee",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Duration of participation in years"
                          className="rounded-[8px] h-[52px] w-full border border-black"
                        />
                      </Form.Item>

                      <Form.Item
                        name="attFile"
                        extra="Upload .png, .jpeg or .pdf"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                      >
                        <Dragger {...props}>
                          <div className="flex items-center justify-between">
                            <p className="ant-upload-text">Attach copy of ID</p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_311_2606)">
                                <path
                                  d="M16 16.2514L12 12.2514M12 12.2514L7.99996 16.2514M12 12.2514V21.2514M20.39 18.6414C21.3653 18.1097 22.1358 17.2683 22.5798 16.2501C23.0239 15.2318 23.1162 14.0947 22.8422 13.0181C22.5682 11.9416 21.9434 10.987 21.0666 10.3049C20.1898 9.62283 19.1108 9.25218 18 9.25145H16.74C16.4373 8.08069 15.8731 6.99378 15.0899 6.07244C14.3067 5.1511 13.3248 4.4193 12.2181 3.93206C11.1113 3.44481 9.90851 3.21481 8.70008 3.25933C7.49164 3.30385 6.30903 3.62175 5.24114 4.18911C4.17325 4.75648 3.24787 5.55855 2.53458 6.53503C1.82129 7.51151 1.33865 8.63699 1.12294 9.82684C0.90723 11.0167 0.964065 12.24 1.28917 13.4047C1.61428 14.5694 2.1992 15.6453 2.99996 16.5514"
                                  stroke="#565E6C"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_311_2606">
                                  <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0 0.25)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </Dragger>
                      </Form.Item>

                      <Form.Item
                        extra={
                          "For example '+254 723 456 789' or ‘+254 110 123 456’ "
                        }
                        name="dirPhone"
                        rules={[
                          {
                            required: true,
                            message: "Required field",
                          },
                        ]}
                        className="w-full"
                      >
                        <InputNumber
                          bordered={false}
                          addonBefore={selectBefore}
                          placeholder="Phone number"
                          maxLength={10}
                          minLength={9}
                          onChange={handleNumberChange}
                          className="reg-phone"
                        />
                      </Form.Item>
                    </div>

                    <div className="flex justify-between">
                      <div></div>
                      <div className="text-end mt-[46px] items-center gap-10 flex">
                        <button
                          className="text-darkBlue text-[18px] font-medium font-dmSans leading-normal"
                          onClick={() => setAdding(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="w-[77px] h-9 px-4 py-1.5 bg-darkBlue rounded-[6px] justify-center items-center gap-1.5 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-tight"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </Form>
                </>
              ) : !adding && !manageArray?.length > 0 ? (
                <div className="card bg-[#F0F5FF] mt-[40px] w-[900px] mx-10 h-[436px] flex flex-col ">
                  <div className="text-[#1D2939] text-lg font-medium font-dmSans leading-7 mt-[37px] flex justify-center">
                    Management Details
                  </div>
                  <div className="flex justify-center mt-[28px]">
                    <img src={team} alt="our-team" />
                  </div>
                  <div className="text-center text-[#667085] text-sm font-medium font-dmSans leading-snug mt-[41px]">
                    Directors or Committee Members you add will be displayed
                    here
                  </div>

                  <div className="flex justify-center">
                    <Button
                      className="!text-white text-[16px] font-medium font-dmSans leading-normal mt-[36px] bg-darkBlue
              h-[52px] px-[20px] py-[10px] rounded-[25px]   justify-center items-center gap-2 inline-flex"
                      onClick={handleAdd}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 4L10 16M16 10L4 10"
                            stroke="#F0F5FF"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      }
                    >
                      Add member
                    </Button>
                  </div>
                </div>
              ) : manageArray?.length > 0 ? (
                <>
                  <Form
                    layout="vertical"
                    ref={formRef}
                    name="control-ref"
                    onFinish={onFinish}
                    className="lg:w-[740px] w-auto bg-[#F0F5FF] px-[83px] py-[42px]"
                    form={form}
                  >
                    <div className="grid grid-cols-1 mb-[-23px]">
                      <div className="flex justify-between flex-row items-center">
                        <div className="text-[#1D2939] text-[20px] font-medium font-dmSans leading-7 text-start">
                          Management Details
                        </div>
                        <div
                          className="flex gap-x-5 items-center cursor-pointer"
                          onClick={handleAdd}
                        >
                          <div className="flex items-center justify-center h-[36px] w-[36px] rounded-full bg-darkBlue">
                            <img src={addIcon} alt="addIcon" />
                          </div>
                          <span className="text-center text-darkBlue text-[18px] font-medium font-dmSans leading-tight">
                            Add Member
                          </span>
                        </div>
                      </div>

                      {manageArray?.length > 0 &&
                        manageArray?.map((member) => (
                          <div className="card bg-white p-3 rounded-[8px] mt-[27px]">
                            <div className="flex items-center justify-between">
                              <div className=" text-black1 text-[18px] font-bold font-dmSans leading-normal justify-start">
                                {member?.dirName} ({member?.dirPosition})
                              </div>
                              <div className="w-[117px] text-black1 text-sm font-normal font-dmSans leading-tight">
                                Duration: {member?.dirDuration} years
                              </div>
                            </div>

                            <div className="w-[283px]">
                              <span className="text-lightGray text-sm font-normal font-dmSans leading-tight">
                                ID:{" "}
                              </span>
                              <span className="text-lightGray text-sm font-normal font-dmSans underline leading-tight">
                                {formatPath(member?.dirCopyOfId)}
                                <br />
                              </span>
                              <span className="text-lightGray text-sm font-normal font-dmSans leading-tight">
                                Phone: {member?.dirPhone}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Form>
                </>
              ) : null}

              <div className="absolute bottom-1 mb-[30px] mt-10 text-center text-darkBlue text-[14px] font-normal font-['Inter'] leading-snug">
                Privacy Policy • Terms and Conditions
              </div>
            </div>

            {manageArray?.length > 0 && (
              <div className="text-center my-[81px]">
                <button
                  className="w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal"
                  type="submit"
                  onClick={onFinish}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementDetails;
