export const ExistenceData = [
  {
    value: "Existing and Operational",
    label: "Existing and Operational",
  },
  {
    value: "Existing but not operational",
    label: "Existing but not operational",
  },
  {
    value: "Not existing",
    label: "Not existing",
  },
];

export const WaterSourceData = [
  {
    value: "River Intake",
    label: "River Intake",
  },
  {
    value: "Borehole",
    label: "Borehole",
  },
  {
    value: "Well",
    label: "Well",
  },
  {
    value: "Spring",
    label: "Spring",
  },
  {
    value: "Dam",
    label: "Dam",
  },
  {
    value: "Bulk Supply",
    label: "Bulk Supply",
  },
];

export const ReliabilityData = [
  {
    value: "Very reliable (never runs out)",
    label: "Very reliable (never runs out)",
  },
  {
    value: "Unreliable in the dry season",
    label: "Unreliable in the dry season",
  },
];
export const OtherSourcesData = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

export const OtherSourcesRWHData = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
  {
    value: "Only RWH",
    label: "Only RWH",
  },
];

export const WaterQualityData = [
  {
    value: "Raw Water",
    label: "Raw Water",
  },
  {
    value: "Safe source (borehole)",
    label: "Safe source (borehole)",
  },
  {
    value: "Treated",
    label: "Treated",
  },
];

export const MasterMeterData = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "Yes but not operational",
    label: "Yes but not operational",
  },
  {
    value: "No",
    label: "No",
  },
];
export const WRA = [
  {
    value: "Yes, valid",
    label: "Yes, valid",
  },
  {
    value: "Yes, expired",
    label: "Yes, expired",
  },
  {
    value: "Authorisation",
    label: "Authorisation",
  },
  {
    value: "None",
    label: "None",
  },
];

export const SysytemTypeData = [
  {
    value: "Pumped",
    label: "Pumped",
  },
  {
    value: "Gravity",
    label: "Gravity",
  },
  {
    value: "Hybrid = Pumped + Gravity",
    label: "Hybrid = Pumped + Gravity",
  },
];

export const PowerData = [
  {
    value: "Solar",
    label: "Solar",
  },
  {
    value: "Wind",
    label: "Wind",
  },
  {
    value: "Electricity",
    label: "Electricity",
  },
  {
    value: "Fuel",
    label: "Fuel",
  },
];

export const MaterialData = [
  {
    value: "Masonry",
    label: "Masonry",
  },
  {
    value: "PVC",
    label: "PVC",
  },
  {
    value: "Steel",
    label: "Steel",
  },
];

export const PipelinesMaterialData = [
  {
    value: "PVC",
    label: "PVC",
  },
  {
    value: "HDPE",
    label: "HDPE",
  },
  {
    value: "GI",
    label: "GI",
  },
];

export const TariffSystemData = [
  {
    value: "Flat rate (same rate every month)",
    label: "Flat rate (same rate every month)",
  },
  {
    value: "Volumetric (Based on volume consumed)",
    label: "Volumetric (Based on volume consumed)",
  },
  {
    value: "Other Charges",
    label: "Other Charges",
  },
];
export const TypeStaffData = [
  {
    value: "Payroll",
    label: "Payroll",
  },
  {
    value: "Casual",
    label: "Casual",
  },
];

export const OperationalStaffData = [
  {
    value: "Project manager",
    label: "Project manager",
  },
  {
    value: "Plumber",
    label: "Plumber",
  },
  {
    value: "Meter reader",
    label: "Meter reader",
  },
  {
    value: "Pump operator",
    label: "Pump operator",
  },
  {
    value: "Office clerk",
    label: "Office clerk",
  },
  {
    value: "Guard",
    label: "Other",
  },
];

export const ProjectPurposeData = [
  {
    value: "Increase supply",
    label: "Increase supply",
  },
  {
    value: "Increase supply area",
    label: "Increase supply area",
  },
  {
    value: "Improve quality",
    label: "Improve quality",
  },
  {
    value: "Improve reliability",
    label: "Improve reliability",
  },
  {
    value: "Metering",
    label: "Metering",
  },
  {
    value: "Reduce operating costs",
    label: "Reduce operating costs",
  },
  {
    value: "Increase customers",
    label: "Increase customers",
  },
  {
    value: "Reduce leaks",
    label: "Reduce leaks",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const BillingData = [
  {
    value: "Manual/Book",
    label: "Manual/Book",
  },
  {
    value: "Worksheet",
    label: "Worksheet",
  },
  {
    value: "Billing Software",
    label: "Billing Software",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const ManagementToolData = [
  {
    value: "Operation and Maintenance Plan",
    label: "Operation and Maintenance Plan",
  },
  {
    value: "Strategic Plan",
    label: "Strategic Plan",
  },
  {
    value: "Marketing Plan",
    label: "Marketing Plan",
  },
  {
    value: "Customer Contract",
    label: "Customer Contract",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const InstitutionTypeData = [
  {
    value: "School",
    label: "School",
  },
  {
    value: "Outpatient Health Care",
    label: "Outpatient Health Care",
  },
  {
    value: "Inpatient Health Care",
    label: "Inpatient Health Care",
  },
  {
    value: "Others",
    label: "Others",
  },
];
