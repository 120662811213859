import { Button, Checkbox, TextField, Chip, Typography, FormHelperText } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, Image, Create, Delete, KeyboardArrowDown, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { cleanupNationalId, cleanupStatutory, onbaordCopyIdStep3, onbaordStatutoryFile, setStatutoryFile } from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';
import TypographySub from './TypographySub';


const Statutory = ({ formData, Title }) => {
    const { statutoryFile, step3CopyId, isLoadingCopyId, } = useSelector((state) => state.onboardPartner)
    const [fileStatutory, setFileStatutory] = useState(statutoryFile)
    const [isUpdating, setIsUpdating] = useState(false);
    const dispatch = useDispatch();
    const [fetchedStatutory, setFetchedStatutory] = useState(formData?.projStatu1File)
    const removeFetchedStatutory = () => {
        setFetchedStatutory('')
        setIsUpdating(true);
        dispatch(cleanupStatutory());
    }

    const { nameStatutory, dateStatutory, file_base64Statutory, typeStatutory } = fileStatutory;
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // statutory pdf
    const [errorStatutory, setErrorStatutory] = useState('')
    const [statutoryPdf, setStatutoryPdf] = useState(undefined)
    const [file, setFile] = useState(undefined)

    const handleStatutory = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];

        if (!_file) return;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorStatutory('Please upload a PDF file not more than 10mb ');
            setStatutoryPdf('');
            return;
        }
        setFile(_file);
        setErrorStatutory('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameStatutory: _file.name,
                dateStatutory: _date,
                typeStatutory: _file.type,
                file_base64Statutory: base64String.toString()
            };
            setFileStatutory(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordStatutoryFile(formData));
    }

    const removeStatutory = () => {
        setFileStatutory({
            nameStatutory: '',
            dateStatutory: '',
            typeStatutory: '',
            file_base64Statutory: undefined,
        });
    }
    const currentYear = new Date().getFullYear();
    const previousYear1 = currentYear - 1;
    const previousYear2 = currentYear - 2;

    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(setStatutoryFile(fileStatutory))
        }
    }, [fileStatutory])
    return (
        <>
            <TypographySub title="Statutory returns to registrar of year" sub={currentYear} superS="true" />
            {
                fetchedStatutory ? (
                    <>
                        <a className='file-stepper-primary' onClick={() => previewPaper(fetchedStatutory)}>
                            {formatPath(fetchedStatutory)}</a>
                        <IconButton
                            aria-label="close"
                            onClick={removeFetchedStatutory}
                            sx={{
                                color: (theme) => theme.palette.grey[500], mr: '6px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {
                            !nameStatutory ? (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        border: 'none',
                                        // border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                    <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                </div>
                                                {
                                                    errorStatutory ? (
                                                        <Typography component="h6" className="upload-sub-label-error">
                                                            {errorStatutory}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="caption" className="upload-sub-label">
                                                            Click here to upload
                                                        </Typography>
                                                    )
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                name='file'
                                                value={nameStatutory}
                                                accept=".pdf"
                                                webkitdirectory
                                                onChange={handleStatutory}
                                                style={{ width: '0px', height: '0px' }}
                                            />

                                        </label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                        border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                            <span style={{ margin: "0px 6px" }}>
                                                {
                                                    typeStatutory === "application/pdf" ? (
                                                        <PictureAsPdf sx={{
                                                            fontSize: '40px',
                                                            color: '#f44336'
                                                        }} />
                                                    ) :
                                                        typeStatutory === "image/png" || "image/jpeg" || "image/gif" ? (
                                                            <Image sx={{
                                                                fontSize: '40px',
                                                                color: '#263238',
                                                            }} />
                                                        ) :
                                                            (
                                                                <Article sx={{
                                                                    fontSize: '40px',
                                                                    color: '#1976d2'
                                                                }} />
                                                            )
                                                }

                                            </span>
                                            <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography component="h6" className="fmw-upload-typography">
                                                {subString(nameStatutory, 25)}
                                            </Typography>


                                            <Typography variant="caption" className="upload-sub-label">
                                                uploaded on  {_date}
                                            </Typography>
                                        </div>

                                        <div>
                                            <IconButton
                                                aria-label="close"
                                                onClick={removeStatutory}
                                                sx={{
                                                    color: (theme) => theme.palette.grey[500], mr: '6px'
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default Statutory
