import { Grid, Divider } from '@mui/material';
import { Button } from '@mui/joy';
import DealList from '../../components/deal_room_list/DealList';
import { Typography } from '@mui/joy';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchLoanTermConditionsPartnerBids } from '../../features/deal_sourcing/dealSourcingSlice';
import DealRoomSkeleton from '../../components/deal_room_list/DealRoomSkeleton';
import PageTitle from '../../components/page_title/PageTitle';
import DealRoomSearch from '../../components/deal_room_list/DealRoomSearch';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ py: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function Deals() {
	const { partnerBids, isProcessingBidsPartner } = useSelector(
		(state) => state.dealSourcing,
	);
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [active, setactive] = useState(true);
	const [active1, setactive1] = useState(false);
	const [value, setValue] = useState(0);
	const [search, setsearch] = useState('');

	function getSearchValue(val) {
		setsearch(val);
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	let dealView;

	// function toggleActive() {
	// 	if (active) {
	// 		setactive(false);
	// 		setactive1(true);
	// 	} else {
	// 		setactive(true);
	// 		setactive1(false);
	// 	}
	// }

	if (value == 0) {
		const acceptedBids = partnerBids.filter(
			(bid) => bid.ltcStatus === 'ACCEPTED',
		);
		dealView = (
			<>
				<DealList search={search} data={acceptedBids} />
			</>
		);
	} else if (value == 1) {
		const rejectedBids = partnerBids.filter(
			(bid) => bid.ltcStatus === 'REJECTED',
		);
		dealView = (
			<>
				<DealList search={search} data={rejectedBids} />
			</>
		);
	}

	useEffect(() => {
		dispatch(
			fetchLoanTermConditionsPartnerBids({
				ltcAccId: user.usrAccId,
			}),
		);
	}, []);

	useEffect(() => {}, [search, partnerBids]);

	return (
		<>
			<PageTitle
				currentPage='Deal Room'
				subSubTitle={'Deals'}
				subTitle={'Deals'}
				urlSubTitle={'/deals'}
			/>
			<DealRoomSearch getSearchValue={getSearchValue} />
			<Tabs
				sx={{
					width: '100%',
				}}
				value={value}
				onChange={handleChange}
				aria-label='basic tabs example'
			>
				<Tab
					sx={{
						width: '100%',
					}}
					label='Accepted projects'
					{...a11yProps(0)}
				/>
				<Tab
					sx={{
						width: '100%',
					}}
					label='Rejected projects'
					{...a11yProps(1)}
				/>
			</Tabs>

			<CustomTabPanel value={value} index={0}>
				{dealView}
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1}>
				{dealView}
			</CustomTabPanel>
		</>
	);
}
