import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectScore,
  postScoreStep,
} from "../../../features/expression_demand/expressionDemandSlice";
import { cashConverter } from "../../../utils";
import ScrollToBottom from "../../messages/scrollToBottom";

const ProjectSummary = ({ data, user, status, setTotalScores }) => {
  const { scores } = useSelector((state) => state.expressionDemandData);
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");
  const stepName = "PROJECT_SUMMARY";
  const dispatch = useDispatch();
  const [matrixInfo, setMatrixInfo] = useState({
    projTown: null,
    accCounty: 0,
    accSubCounty: 0,
    accLocation: 0,
    projLnNumbers: 0,
    accZipCode: 0,
    projTelMobile: 0,
    projAlternatePhone: 0,
    projEmail: 0,
    accTradeName: 0,
    projDesc: 0,
    projImpact: 0,
    projectCost: 0,
    projLoanYears: 0,
    projIndividualActiveConn: 0,
    projIndividualClosedConn: 0,
    projInstitutionCommercialConn: 0,
    projState: 0,
    usrFullNames: 0,
    projNumberWaterKiosks: 0,
    projNumberHouseholdsServed: 0,
    projOrgPeriod: 0,
  });

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const submitStep = () => {
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
    };
    const res = dispatch(postScoreStep(formData));
  };

  const updateMatrix = () => {
    scores?.forEach((score) => {
      const { scScore, scMethod } = score;
      setComment("");
      setComment(score.sctComment);
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
    });
  };

  useEffect(() => {
    updateMatrix();
  }, [scores, comment]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  function createData(name, description, variableName) {
    return { name, description, variableName };
  }

  const address = [
    // createData("Town", data.projTown, "projTown"),
    createData(
      "Telephone contact (Mobile)",
      data.usrMobileNumber,
      "projTelMobile"
    ),
    createData("Email contact", data.usrEmail, "projEmail"),
    createData(
      "Alternate telephone contact",
      data.usrAltPhoneNo,
      "projAlternatePhone"
    ),
    createData("County", data.accCounty, "accCounty"),
    createData("Sub-county", data.accSubCounty, "accSubCounty"),
    createData("Location", data.accLocation, "accLocation"),
    // createData("Land registration number", data.projLnNumbers, "projLnNumbers"),
    createData("Post code", data.accZipCode, "accZipCode"),
  ];

  const summary = [
    createData("Name of water project", data.accTradeName, "accTradeName"),
    createData("Region", data.accLocation, "accLocation"),
    createData(
      "Project summary",
      data.projDesc ? data.projDesc : data.projDescSubProj,
      "projDesc"
    ),
    // createData("Impact", data.projImpact, "projImpact"),
    createData(
      "Valuation of the project",
      cashConverter(data.projEstCost ?? data.projCost),
      "projectCost"
    ),
    // createData(
    //   "Scale of repayment (Lowest 1, Highest 5)",
    //   data.projLoanYears,
    //   "projLoanYears"
    // ),
    // createData(
    //   "Recommendation",
    //   "Well Located area with dense population",
    //   "noField"
    // ),
    createData(
      "Individual active connections",
      data.projIndividualActiveConn,
      "projIndividualActiveConn"
    ),
    createData(
      "Individual closed connections",
      data.projIndividualClosedConn,
      "projIndividualClosedConn"
    ),
    createData(
      "Institution or commercial connections",
      data.projInstitutionCommercialConn,
      "projInstitutionCommercialConn"
    ),
    // createData(
    //   "State whether NEW or EXISTING water project",
    //   data.projState,
    //   "projState"
    // ),
    createData(
      "Name of contact person & designation",
      data.usrFullNames,
      "usrFullNames"
    ),
    createData(
      "Number of water kiosks",
      data.projNumberWaterKiosks,
      "projNumberWaterKiosks"
    ),
    createData(
      "Number of households served",
      data.projNumberHouseholdsServed,
      "projNumberHouseholdsServed"
    ),
    createData(
      "Period the organization has been providing water services",
      data.projOrgPeriod,
      "projOrgPeriod"
    ),
  ];

  useEffect(() => {}, [matrixInfo]);
  const attachments = [
    createData(
      "Certificate of registration",
      data.projRegCertFile,
      "projRegCertFile"
    ),
    createData("Memorandum of association", data.projMemFile, "projMemFile"),
    createData(
      "Articles of association",
      data.projAssocArtFile,
      "projAssocArtFile"
    ),
    createData("Minutes of last AGM", data.projMinAgmFile, "projMinAgmFile"),
    createData(
      "Audited accounts for last 3 years",
      data.projAudiAccFile,
      "projAudiAccFile"
    ),
  ];
  return (
    <>
      {/* <ScrollToBottom /> */}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Project Summary
      </Typography>
      <Table aria-label="customized table">
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {/* {status === "Viable" && (
              <TableCell
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                System Matrix
              </TableCell>
            )} */}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {summary.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>

              {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                />
              )} */}
            </TableRow>
          ))}
          <TableRow
            key={data.projPayCapable}
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
            style={{ borderBottom: "1px solid #E4E4E4" }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
                color: "white",
                bgcolor: `${data.projPayCapable === "Yes" ? "green" : "red"}`,
              }}
            >
              Confirmation of loan repayment capabilities
            </TableCell>
            <TableCell
              sx={{
                borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row

                bgcolor: `${data.projPayCapable === "Yes" ? "green" : "red"}`,
                color: "white",
              }}
            >
              {data.projPayCapable}
            </TableCell>
            {/* {status === "Viable" && (
              <MatrixInput
                setMatrixInfo={setMatrixInfo}
                matrixInfo={matrixInfo}
                name={"projPayCapable"}
                stepName={stepName}
                id={data.projId}
                setOnblur={setOnBlur}
                onBlur={onBlur}
              />
            )} */}
          </TableRow>
        </TableBody>
      </Table>
      <Typography sx={{ mt: 5, mb: 2, color: "#0069DF", fontWeight: "bold" }}>
        Address Information
      </Typography>
      <Table aria-label="customized table">
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {/* {status === "Viable" && (
              <TableCell
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                System Matrix
              </TableCell>
            )} */}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {address.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>

              {/* {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                />
              )} */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" || status === "DEAL_SOURCING" || status === "Viable") && (
        <>
          <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
            Comments on above information
          </Typography>

          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            minRows={3}
            name="projSummary"
            placeholder="Write here..."
            size="lg"
            className="fmw-textarea"
            sx={{ width: "100%" }}
          />
          <Typography sx={{ textAlign: "end" }}>
            <Button
              onClick={submitStep}
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              submit
            </Button>
          </Typography>
        </>
      )} */}
    </>
  );
};

export default ProjectSummary;
