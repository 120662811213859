import { Box, Container, Grid } from '@mui/material';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Typography } from '@mui/joy';
import './news-events.css';
import NewsCard from '../../components/news_events/NewsCard';
import MiniCard from '../../components/news_events/MiniCard';
import EventCard from '../../components/news_events/EventCard';

export default function NewsEvents() {
	return (
		<>
			<Header />
			<Container sx={{ mb: 13, minWidth: '100%' }}>
				<Grid sx={{ mt: 3, ml:2 }} rowSpacing={1} container spacing={8}>
					<Grid className='news-grid' item xs={8} md={8}>
						<Typography
							sx={{ mb: 2 }}
							component='h3'
							className='fmw-page-title'
						>
							News
						</Typography>
						<Box>
							<NewsCard />

							<Grid sx={{ mt: 3 }} container spacing={2}>
								<Grid className='news-grid' item xs={6} md={6}>
								<MiniCard />
								</Grid>

								<Grid className='news-grid' item xs={6} md={6}>
								<MiniCard />
								</Grid>
							</Grid>

							<Grid sx={{ mt: 3 }} container spacing={2}>
								<Grid className='news-grid' item xs={6} md={6}>
								<MiniCard />
								</Grid>

								<Grid className='news-grid' item xs={6} md={6}>
								<MiniCard />
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid sx={{ mt: 0, ml: 0 }} className='events-grid' item xs={3} md={3}>
						<Typography
							sx={{ mb: 2 }}
							component='h3'
							className='fmw-page-title'
						>
							Events
						</Typography>
						<Box>
							<EventCard />
							<EventCard />
							<EventCard />
						</Box>
					</Grid>
				</Grid>
			</Container>

			<Footer />
		</>
	);
}
