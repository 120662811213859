import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import record from "../../assets/record.gif";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-input-2/lib/style.css";
import { Box, Grid } from "@mui/material";
import SearchTile from "./SearchTile";
import { Input } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupportAccounts } from "../../features/support/supportSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 14,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogContent>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const SupportModal = ({ open, setDialog, accountType, setFormData }) => {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.support.accounts);
  const handleClose = () => {
    setDialog(false);
  };

  // console.log(
  //   accounts,
  //   "=======================Here are My Accounts!!!!==============================="
  // );

  const onChange = async (e) => {
    console.log(e.target.value);
    console.log(
      accountType,
      "Here is the Type of Account-==================--=-=-=-=-=-=-=-=-"
    );
    if (accountType) {
      const data = await dispatch(fetchSupportAccounts(e.target.value));
    } else {
      const data = await dispatch(fetchSupportAccounts(e.target.value));
    }
  };

  const searchTileElement = accounts.map((acc) => {
    if (accountType) {
      return (
        <SearchTile
          key={acc.accId}
          icon={acc.accIcon}
          name={acc.accTradeName}
          setFormData={setFormData}
          id={acc.accId}
          accountType={accountType}
          setDialog={setDialog}
        />
      );
    } else {
      return (
        <SearchTile
          key={acc.projId}
          icon={acc.accIcon}
          name={acc.accTradeName}
          setFormData={setFormData}
          id={acc.accId}
          accountType={accountType}
          setDialog={setDialog}
        />
      );
    }
  });
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        padding="20"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid item xs={6} sx={{ mt: 4 }} margin="auto">
            <Input
              onChange={onChange}
              name="accTradeName"
              placeholder="Search for the Organization..."
              size="lg"
              className="fmw-input-joy"
              sx={{ width: "100%" }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            margin="auto"
            sx={{ overflow: "hidden", overflowY: "auto" }}
          >
            <Box sx={{ width: "100%" }}>
              {searchTileElement.length > 0 ? (
                searchTileElement
              ) : (
                <img width="100%" src={record} alt="no Data" />
              )}
            </Box>
          </Grid>
        </BootstrapDialogTitle>
      </BootstrapDialog>
    </div>
  );
};

export default SupportModal;
