import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, TextField } from '@mui/material';
import { Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWspApplications, reset } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';
import { onboardPartner } from '../../features/onboard_partner/onboardPartnerSlice';

const OtherCharges = ({wspApplications}) => {
  const {   isLoading, isSuccess } = useSelector((state) => state.wsp)
  const dispatch = useDispatch()
const columns = [
  { field: 'id', headerName: 'ID', width: 90, hide: true },
  {
    field: 'item',
    headerName: 'Item',
    width: 250,
    sortable: false
  },
  {
    field: 'tariff',
    type: 'number',
    headerName: 'Tariff (Kenyan shillings)',
    width: 200,
    sortable: false,
    editable: true,
    renderCell: (params) => {
      return (
        <TextField
          id="standard-basic"
          type="number"
          variant="standard"
          value={params.row.tariff}
        />
      )
    }
  },


];

const rows = [
  { id: 1, tariff: wspApplications[0].projMeterRentTariff == -1 ? "" : wspApplications[0].projMeterRentTariff , item: 'Meter Rent' },
  { id: 2, tariff: wspApplications[0].projMembershipTariff == -1 ? "":  wspApplications[0].projMembershipTariff, item: 'Membership' },
  { id: 3, tariff: wspApplications[0].projHarambeeTariff == -1 ? "" : wspApplications[0].projHarambeeTariff, item: 'Harambee' },
];
const processRowUpdate = (newRow) => {
  const updatedRow = { ...newRow };
  const id = updatedRow.id
  const tariff = updatedRow.tariff ? updatedRow.tariff : "-1";

  const formdata = new FormData();
  formdata.append("id", updatedRow.id);
  formdata.append("item", updatedRow.item);
  formdata.append("tariff", tariff);

  if (id == 1) {
    dispatch(onboardPartner({
      projId: wspApplications[0].projId,
      projMeterRentTariff: tariff
    }))
    setTimeout(() => {
      dispatch((fetchWspApplications()))
    }, 800)
  }
  if (id == 2) {
    dispatch(onboardPartner({
      projId: wspApplications[0].projId,
      projMembershipTariff: tariff
    }))
    setTimeout(() => {
      dispatch((fetchWspApplications()))
    }, 800)
  }
  if (id == 3) {
    dispatch(onboardPartner({
      projId: wspApplications[0].projId,
      projHarambeeTariff: tariff
    }))
    setTimeout(() => {
      dispatch((fetchWspApplications()))
    }, 800)
  }

  return updatedRow;
};
// useEffect(() => {
//   dispatch(fetchWspApplications())
//   return () => {
//     dispatch(reset())
//   }

// }, [dispatch])
 
  return (
    <>
      <Typography sx={{ my: 3, mt: 0 }} component="body2" className="fmw-divider" />
      <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
        Fill the other charges table below <span className="star">*</span>
      </Typography>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <DataGrid
          autoHeight
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          processRowUpdate={processRowUpdate}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isLoading}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
        />
      </Paper>
    </>
  );
}

export default OtherCharges 
