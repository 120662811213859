import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAllChatSuggestions,
	handleChats,
	setSelectedUser,
} from '../../features/message/chatUserSlice';
import { useState } from 'react';

const url = process.env.REACT_APP_API_BASE_URL;

function SearchMessage({handleSearchValue}) {
	const dispatch = useDispatch();
	const {users, chatArray } = useSelector((state) => state.messages);
	const [value, setValue] = useState('');
	

	useEffect(() => {
		if (value) {
			dispatch(fetchAllChatSuggestions(value));
		}
		handleSearchValue(value)
	}, [value]);

	function handleChange(e){
		setValue(value => e.target.value)
	}

	function fetchChatData(cht) {
		if (!cht.chId) return [];
		fetch(
			`${url}/usr/fetchMsgMessageQueues.action?msgChId=${cht.chId}`,
		)
			.then((res) => res.json())
			.then((data) => dispatch(handleChats(data.data.result)));
	}

	async function handleSelectingChat(user) {
		const newObj = {
			chCreatedBy: null,
			chCreatedDate: null,
			chCreatedTime: null,
			chDesc: null,
			chId: null,
			chInstId: user.accId,
			chProjId: null,
			chStatus: "",
			chUpdatedBy: null,
			chUpdatedDate: null,
			chUpdatedTime: null,
			withParty: user.accTradeName
		}
		if (chatArray?.length) {
			await chatArray.map(chatUser => {
				if(chatUser.chInstId === user.accId && chatArray.length > 0){
					console.log(chatUser.chInstId === user.accId && chatArray.length > 0);
					dispatch(setSelectedUser(chatUser))
					setValue('');
					return dispatch(fetchChatData(chatUser));
				}else{
					console.log(newObj);
					setValue('');
					return dispatch(setSelectedUser(newObj))
				}
			})
		}else{
			setValue('');
			return dispatch(setSelectedUser(newObj))
		}

		// await chatArray.map(chatUser => {
		// 	if(chatUser.chInstId === user.accId && chatArray.length > 0){
		// 		console.log(chatUser.chInstId === user.accId && chatArray.length > 0);
		// 		dispatch(setSelectedUser(chatUser))
		// 		setValue('');
		// 		return dispatch(fetchChatData(chatUser));
		// 	}else{
		// 		console.log(newObj);
		// 		setValue('');
		// 		return dispatch(setSelectedUser(newObj))
		// 	}
		// })
		setValue('');
	}

	function capitalizeWords(str) {
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	return (
		<>
			<Box
				sx={{
					p: 2,
					fontWeight: 'bold',
					backgroundColor: '#e6fafd',
					color: '#131414',
				}}
			>
				All Messages
			</Box>
			<TextField
				onChange={(e) => handleChange(e)}
				value={value}
				fullwidth="true"
				sx={{ border: '1px solid #caf7fe', outline: 0, p: 1, width: '100%' }}
				placeholder='Search'
				size='small'
				InputProps={{
					type: 'search',
					disableUnderline: true,
					autoComplete: 'off',
				}}
				variant='standard'
			/>

			{value && users?.length ? (
				<Box
					sx={{
						width: 'auto',
						p: 1,
						maxHeight: '65vh',
						height: 'fit-content',
						overflowY: 'auto',
					}}
				>
					{users.map((user) => {
						return (
							<Box
								key={user.accId}
								onClick={() => handleSelectingChat(user)}
								sx={{
									border: '1px solid black',
									borderRadius: 15,
									p: 1,
									mb: 0.5,
									width: 'fit-content',
									cursor: 'pointer',
									textTransform: 'capitalized',
									fontSize: 13.5,
								}}
							>
								{capitalizeWords(user.accTradeName)}
								<br />
							</Box>
						);
					})}
				</Box>
			) : null}
		</>
	);
}


export default SearchMessage;
