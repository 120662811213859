import { Button, Checkbox, Chip, Textarea, Input, Typography } from '@mui/joy'
import {
    CircularProgress, Divider, FormControl, FormLabel, Grid,
    IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Article, AttachFile, CloudUploadOutlined, Image, Create, Delete, KeyboardArrowDown,
    PictureAsPdf, Visibility, VisibilityOff
} from '@mui/icons-material';
import { formatPath, previewPaper, subString } from '../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import {
    cleanupMtrReading, cleanupPermit, cleanupPump, cleanupWtrAnalysis,
    deletePipeExtent, deleteTotalCapacity, fetchPipeExtents, fetchTotalCapacity,
    onbaordMtrReadingPdf, onbaordPermitPdf, onbaordPumpPdf,
    onbaordWtrAnalysisPdf, reset, savePipeExtent, saveTotalCapacity,
    setMtrReadingFile, setPermitFile, setPumpFile, setStep3, setStep3ProjWaterSources,
    setStep3Source,
    setWtrAnalysisFile
} from '../../../features/onboard_partner/onboardPartnerSlice';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import {
    ExistenceData, MasterMeterData, MaterialData, OtherSourcesData,
    PipelinesMaterialData, PowerData, ReliabilityData, SysytemTypeData, WaterQualityData, WRA
}
    from '../../../Step3Data';
import MultipleSelectWaterSrc from '../../../components/multipleselect_water_source/MultipleSelectWaterSrc'
import { fetchWspApplications } from '../../../features/wsp/wspSlice';
import PipeEdit from '../../../components/onboard_partner_modals/PipeEdit';
import TankEdit from '../../../components/onboard_partner_modals/TankEdit';
import { OtherSourcesRWHData } from '../../../Step3Data';
import OperationStaff from '../../../components/Tables/OperationStaff';
import WaterSources from '../../../components/Tables/WaterSources';




function Step3() {
    const dispatch = useDispatch();


    const { isLoadingTank, isLoadingPipe, step3, wtrAnalysisPdf, mtrReadingPdf, permitPdf, pumpPdf, step3WtrAnalysisPdf,
        tankCapacity, pipeExtent, isSuccessDelete, isSuccessUpdateTank, isSuccessUpdatePipe } = useSelector((state) => state.onboardPartner)

 

    const [isUpdating, setIsUpdating] = useState(false);
    const { wspApplications, sendProjId, isLoadingWsp } = useSelector((state) => state.wsp)
    const { user } = useSelector((state) => state.auth)
    const [formData, setFormData] = useState(wspApplications[0] || step3)
    const { projExistingOperational, projWaterSources, projReliabilityWaterSource, projOtherExistingWaterSources,
        projOtherExistingWaterSourcesDesc, projReliabilityOtherWaterSources, projOtherWspSswp, projOtherWspSswpDesc,
        projWspAround, projWspAroundDesc,
        projAverageVolumeDry, projAverageVolWet, projWaterQuality, projMasterMeterInstalledFunctional, projWraAbstrationPermit,
        projTypeOfSysytem, projPumpedPow } = formData


    const [fetchedWaterQualityFile, setFetchedWaterQualityFile] = useState(wspApplications[0].projWaterQualityFile)
    const [fetchedMeterReadingsFile, setFetchedMeterReadingsFile] = useState(wspApplications[0].projMeterReadingsFile)
    const [fetchedPermitFile, setFetchedPermitFile] = useState(wspApplications[0].projPermitFile)
    const [fetchedPumpFile, setFetchedPumpFile] = useState(wspApplications[0].projPictureOfPumpFile)
    const removeFetchedWaterQualityFile = () => {
        setFetchedWaterQualityFile('')
    }
    const removeFetchedMeterReadingsFile = () => {
        setFetchedMeterReadingsFile('')
    }
    const removeFetchedPermitFile = () => {
        setFetchedPermitFile('')
    }
    const removeFetchedPumpFile = () => {
        setFetchedPumpFile('')
    }

    const [tankk, setTankk] = useState()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [tankData, setTanKData] = useState({
        stName: "",
        stMaterial: null,
        stSize: "",
    });
    const { stName, stMaterial, stSize } = tankData;

    const saveTank = (e) => {
        if (!stName || !stMaterial || !stSize) {
            toast.warning("Please fill all details about Total capacity")
        } else {
            dispatch(saveTotalCapacity({
                stProjId: wspApplications[0].projId,
                stName: stName,
                stMaterial: stMaterial,
                stSize: stSize,
            }));
            setTanKData({ stName: "", stMaterial: null, stSize: "" })
        }

    }
    const handleDeleteTank = (id) => {
        dispatch(deleteTotalCapacity({
            stId: id
        }));
        setIsUpdating(false);
    }
    const handleDeletePipeline = (id) => {
        dispatch(deletePipeExtent({
            peId: id
        }));
        setIsUpdating(false);
    }
    const [pipelinn, setPipelinn] = useState()
    const [openPipe, setOpenPipe] = React.useState(false);

    const handleClickOpenPipe = () => {
        setOpenPipe(true);
    };
    const handleClosePipe = () => {
        setOpenPipe(false);
    };
    const [pipelineData, setPipelineData] = useState({
        peSize: '',
        peMaterial: null,
        peTotalLength: '',
    });
    const { peSize, peMaterial, peTotalLength } = pipelineData;

    const savePipeline = (e) => {
        if (!peSize || !peMaterial || !peTotalLength) {
            toast.warning("Please fill all details about Total capacity")
        } else {
            dispatch(savePipeExtent({
                peProjId: wspApplications[0].projId,
                peSize: peSize,
                peMaterial: peMaterial,
                peTotalLength: peTotalLength,
            }));
            setPipelineData({ peSize: '', peMaterial: '', peTotalLength: '' })
        }
    }

    const handleChangeTank = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setTanKData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };

    const handleChangePipeline = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setPipelineData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };


    const [file, setFile] = useState("")

    // waterAnalysisPdf
    const [fileWtrAnalysis, setFileWtrAnalysis] = useState(wtrAnalysisPdf)
    const { nameWtrAnalysis, dateWtrAnalysis, file_base64WtrAnalysis, typeWtrAnalysis } = fileWtrAnalysis;
    const [wtrPdf, setWtrPdf] = useState('')
    const [errorWtrPdf, setErrorWtrPdf] = useState('')

    // MtrReadingPdf
    const [fileMtrReading, setFileMtrReading] = useState(mtrReadingPdf)
    const { nameMtrReading, dateMtrReading, file_base64MtrReading, typeMtrReading } = fileMtrReading;
    const [mtrPdf, setMtrPdf] = useState('')
    const [errorMtrPdf, setErrorMtrPdf] = useState('')

    //PermitPdf 
    const [filePermit, setFilePermit] = useState(permitPdf)
    const { namePermit, datePermit, file_base64Permit, typePermit } = filePermit;
    const [validPermitPdf, setValidPermitPdf] = useState('')
    const [errorPermitPdf, setErrorPermitPdf] = useState('')

    //PumpPdf 
    const [filePump, setFilePump] = useState(pumpPdf)
    const { namePump, datePump, file_base64Pump, typePump } = filePump;
    const [pumpPicPdf, setPumpPicPdf] = useState('')
    const [errorPumpPdf, setErrorPumpPdf] = useState('')



    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;
    const [waterSrc, setwaterSrc] = React.useState(wspApplications[0].projWaterSources ? wspApplications[0].projWaterSources.split(",") : []);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    // waterAnalysisPdf
    const handleWtrPdf = (e) => {
			const _imgArr = [];
			const _file = e.target.files[0];
			if (!_file) return;
			const allowedExtensions = /(\.pdf)$/i;
			const MAX_FILE_SIZE = 1e7; //10mb
			if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
				setErrorWtrPdf(
					'Please upload a PDF file Only of size not more than 10mb',
				);
				setWtrPdf('');
				return;
			}
			setFile(_file);
			setErrorWtrPdf('');
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result
					.replace('data:', '')
					.replace(/^.+,/, '');
				let imageObj = {
					nameWtrAnalysis: _file.name,
					dateWtrAnalysis: _date,
					typeWtrAnalysis: _file.type,
					file_base64WtrAnalysis: base64String.toString(),
				};
				setFileWtrAnalysis(imageObj);
				setIsUpdating(true);
			};
			reader.readAsDataURL(_file);
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			dispatch(onbaordWtrAnalysisPdf(formData));
		};

		const removeWtrPdf = () => {
			setFileWtrAnalysis({
				nameWtrAnalysis: '',
				dateWtrAnalysis: '',
				typeWtrAnalysis: '',
				file_base64WtrAnalysis: undefined,
			});
			setIsUpdating(true);
			dispatch(cleanupWtrAnalysis());
		};

		//  MtrReadingPdf
		const handleMtrPdf = (e) => {
			const _imgArr = [];
			const _file = e.target.files[0];
			if (!_file) return;
			const allowedExtensions = /(\.pdf)$/i;
			const MAX_FILE_SIZE = 1e7; //10mb
			if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
				setErrorMtrPdf(
					'Please upload a PDF file Only of size not more than 10mb',
				);
				setMtrPdf('');
				return;
			}
			setFile(_file);
			setErrorMtrPdf('');
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result
					.replace('data:', '')
					.replace(/^.+,/, '');
				let imageObj = {
					nameMtrReading: _file.name,
					dateMtrReading: _date,
					typeMtrReading: _file.type,
					file_base64MtrReading: base64String.toString(),
				};
				setFileMtrReading(imageObj);
				setIsUpdating(true);
			};
			reader.readAsDataURL(_file);
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			dispatch(onbaordMtrReadingPdf(formData));
		};

		const removeMtrPdf = () => {
			setFileMtrReading({
				nameMtrReading: '',
				dateMtrReading: '',
				typeMtrReading: '',
				file_base64MtrReading: undefined,
			});
			setIsUpdating(true);
			dispatch(cleanupMtrReading());
		};

		//PermitPdf
		const handlePermitPdf = (e) => {
			const _imgArr = [];
			const _file = e.target.files[0];
			if (!_file) return;
			const allowedExtensions = /(\.pdf)$/i;
			const MAX_FILE_SIZE = 1e7; //10mb
			if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
				setErrorPermitPdf(
					'Please upload a PDF file Only of size not more than 10mb',
				);
				setValidPermitPdf('');
				return;
			}
			setFile(_file);
			setErrorPermitPdf('');
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result
					.replace('data:', '')
					.replace(/^.+,/, '');
				let imageObj = {
					namePermit: _file.name,
					datePermit: _date,
					typePermit: _file.type,
					file_base64Permit: base64String.toString(),
				};
				setFilePermit(imageObj);
				setIsUpdating(true);
			};
			reader.readAsDataURL(_file);
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			dispatch(onbaordPermitPdf(formData));
		};

		const removePermitPdf = () => {
			setFilePermit({
				namePermit: '',
				datePermit: '',
				typePermit: '',
				file_base64Permit: undefined,
			});
			setIsUpdating(true);
			dispatch(cleanupPermit());
		};

		//PumpPdf
		const handlePumpPdf = (e) => {
			const _imgArr = [];
			const _file = e.target.files[0];
			if (!_file) return;
			const allowedExtensions = /(\.pdf)$/i;
			const MAX_FILE_SIZE = 1e7; //10mb
			if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
				setErrorPumpPdf(
					'Please upload a PDF file Only of size not more than 10mb',
				);
				setPumpPicPdf('');
				return;
			}
			setFile(_file);
			setErrorPumpPdf('');
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result
					.replace('data:', '')
					.replace(/^.+,/, '');
				let imageObj = {
					namePump: _file.name,
					datePump: _date,
					typePump: _file.type,
					file_base64Pump: base64String.toString(),
				};
				setFilePump(imageObj);
				setIsUpdating(true);
			};
			reader.readAsDataURL(_file);
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			dispatch(onbaordPumpPdf(formData));
		};

    const removePumpPdf = () => {
        setFilePump({
            namePump: '',
            datePump: '',
            typePump: '',
            file_base64Pump: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupPump());
    }

    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };


    const handleWtrSrcChange = (event) => {
        const {
            target: { value },
        } = event;
        setwaterSrc(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        setIsUpdating(true);
    };



    useEffect(() => {
        if ((!isUpdating || isSuccessDelete || isSuccessUpdateTank || isSuccessUpdatePipe) && (user.usrJbrnId === 1)) {
            setTimeout(() => {
                dispatch(fetchTotalCapacity({
                    projId: wspApplications[0].projId
                }));
            }, 1000)

            setTimeout(() => {
                dispatch(fetchPipeExtents({
                    projId: wspApplications[0].projId
                }));
            }, 1000)

            setTimeout(() => {
                dispatch((fetchWspApplications()))
            }, 800)

        } else if ((!isUpdating || isSuccessDelete || isSuccessUpdateTank || isSuccessUpdatePipe) && (user.usrJbrnId === 0)) {
            
            setTimeout(() => {
                dispatch((fetchWspApplications({
                    projId: sendProjId
                })))
            }, 800)

            setTimeout(() => {
                dispatch(fetchTotalCapacity({
                    projId: wspApplications[0].projId
                }));
            }, 1000)

            setTimeout(() => {
                dispatch(fetchPipeExtents({
                    projId: wspApplications[0].projId
                }));
            }, 1000)

       

        }
        else {
            dispatch(setStep3(formData));
            dispatch(setStep3ProjWaterSources(waterSrc));
            dispatch(setWtrAnalysisFile(fileWtrAnalysis));
            dispatch(setMtrReadingFile(fileMtrReading));
            dispatch(setPermitFile(filePermit));
            dispatch(setPumpFile(filePump));
        }

        return () => {
            dispatch(reset())
        }
    }, [dispatch, isSuccessDelete, isUpdating, formData, waterSrc, fileWtrAnalysis, fileMtrReading, filePermit, filePump,
        isSuccessUpdateTank, isSuccessUpdatePipe, sendProjId
    ]);
 


    return (
        <>
            {
                isLoadingWsp ? (<>
                    <Skeleton width={1480} />
                    <Skeleton width={1480} />
                    <Skeleton width={1480} />
                </>) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography component="h6" className="fmw-onboard-partner-label" style={{ marginBottom: '0.5rem' }}>Water source & infrastructure</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Is the project existing and operational ?
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projExistingOperational"
                                        }
                                    })
                                }}
                                placeholder="Select existence *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projExistingOperational"
                                value={formData.projExistingOperational}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {ExistenceData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid>

                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                What is the current Water Source(s) for the Water Project
                            </Typography>
                            <MultipleSelectWaterSrc
                                waterSource={waterSrc}
                                handleChange={handleWtrSrcChange}
                            />
                        </Grid> */}



                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Reliability of water source
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projReliabilityWaterSource"
                                        }
                                    })
                                }}
                                placeholder="Select reliability*"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projReliabilityWaterSource"
                                value={formData.projReliabilityWaterSource}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {ReliabilityData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid> */}


                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Other existing sources within the water project area of operation
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projOtherExistingWaterSources"
                                        }
                                    })
                                }}
                                placeholder="Select choice *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projOtherExistingWaterSources"
                                value={formData.projOtherExistingWaterSources}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {OtherSourcesRWHData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid>

                        {
                            projOtherExistingWaterSources == "Yes" && (
                                <>
                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Describe your other existing sources within the water project area of operation <span className="star">*</span>
                                        </Typography>
                                        <Textarea
                                            onChange={handleChange}
                                            value={formData.projOtherExistingWaterSourcesDesc}
                                            name="projOtherExistingWaterSourcesDesc"
                                            minRows={3}
                                            size="lg"
                                            variant="outlined"
                                            placeholder="Write here"
                                            className="fmw-textarea"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Reliability of other water sources
                                        </Typography>
                                        <Select
                                            className="fmw-select"
                                            onChange={(e, newval) => {
                                                handleChange({
                                                    target: {
                                                        value: newval,
                                                        name: "projReliabilityOtherWaterSources"
                                                    }
                                                })
                                            }}
                                            placeholder="Select reliability *"
                                            indicator={<KeyboardArrowDown />}
                                            size='lg'
                                            name="projReliabilityOtherWaterSources"
                                            value={formData.projReliabilityOtherWaterSources}

                                            sx={{
                                                width: '100%',
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}
                                        >
                                            {ReliabilityData.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>

                                    </Grid>
                                </>


                            )
                        }

                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Is SSP within the area of an existing WSP ? 
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projWspAround"
                                        }
                                    })
                                }}
                                placeholder="Select choice *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projWspAround"
                                value={formData.projWspAround}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {OtherSourcesData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>


                        </Grid>
                        {
                            projWspAround == "Yes" && (
                                <>
                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Describe your other SSP within the area of an existing WSP<span className="star">*</span>
                                        </Typography>
                                        <Textarea
                                            onChange={handleChange}
                                            value={formData.projWspAroundDesc}
                                            name="projWspAroundDesc"
                                            minRows={3}
                                            size="lg"
                                            variant="outlined"
                                            placeholder="Write here"
                                            className="fmw-textarea"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Reliability of other water sources
                                        </Typography>
                                        <Select
                                            className="fmw-select"
                                            onChange={(e, newval) => {
                                                handleChange({
                                                    target: {
                                                        value: newval,
                                                        name: "projReliabilityOtherWaterSources"
                                                    }
                                                })
                                            }}
                                            placeholder="Select reliability *"
                                            indicator={<KeyboardArrowDown />}
                                            size='lg'
                                            name="projReliabilityOtherWaterSources"
                                            value={formData.projReliabilityOtherWaterSources}

                                            sx={{
                                                width: '100%',
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}
                                        >
                                            {ReliabilityData.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>

                                    </Grid>
                                </>


                            )
                        }

                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Is there a WSP or another SSP operating within the same area of the water project ?
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projOtherWspSswp"
                                        }
                                    })
                                }}
                                placeholder="Select choice *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projOtherWspSswp"
                                value={formData.projOtherWspSswp}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {OtherSourcesData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Grid>

                        {
                            projOtherWspSswp == "Yes" && (
                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                        Describe your WSP or another SSP operating within the same area of the water project <span className="star">*</span>
                                    </Typography>
                                    <Textarea
                                        onChange={handleChange}
                                        value={formData.projOtherWspSswpDesc}
                                        name="projOtherWspSswpDesc"
                                        minRows={3}
                                        size="lg"
                                        variant="outlined"
                                        placeholder="Write here"
                                        className="fmw-textarea"
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Average volume abstracted per day from all sources m<sup>3</sup>/day dry season
                            </Typography>
                            <Input
                                label=""
                                type="number"
                                value={formData.projAverageVolumeDry}
                                name="projAverageVolumeDry"
                                onChange={handleChange}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Average volume abstracted per day from source m<sup>3</sup>/day wet season
                            </Typography>
                            <Input
                                label=""
                                type="number"
                                value={formData.projAverageVolWet}
                                name="projAverageVolWet"
                                onChange={handleChange}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <WaterSources />


                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Water quality at source
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projWaterQuality"
                                        }
                                    })
                                }}
                                placeholder="Select water quality *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projWaterQuality"
                                value={formData.projWaterQuality}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {WaterQualityData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid> */}


                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Is a master meter installed and functional ?
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projMasterMeterInstalledFunctional"
                                        }
                                    })
                                }}
                                placeholder="Select functionality *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projMasterMeterInstalledFunctional"
                                value={formData.projMasterMeterInstalledFunctional}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {MasterMeterData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid> */}


                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Is there a water regulatory authority (WRA) abstraction permit ?
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projWraAbstrationPermit"
                                        }
                                    })
                                }}
                                placeholder="Select existence *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projWraAbstrationPermit"
                                value={formData.projWraAbstrationPermit}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {WRA.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid> */}

                        {/* <Grid item xs={6}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Type of system a pumped or gravity system or hybrid?
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "projTypeOfSysytem"
                                        }
                                    })
                                }}
                                placeholder="Select type of pump *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="projTypeOfSysytem"
                                value={formData.projTypeOfSysytem}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {SysytemTypeData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid> */}

                        {/* {
                            projTypeOfSysytem == "Pumped" || projTypeOfSysytem == "Hybrid = Pumped + Gravity" ? (
                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                        If pumped, type of power used
                                    </Typography>
                                    <Select
                                        className="fmw-select"
                                        onChange={(e, newval) => {
                                            handleChange({
                                                target: {
                                                    value: newval,
                                                    name: "projPumpedPow"
                                                }
                                            })
                                        }}
                                        placeholder="Select Type of power*"
                                        indicator={<KeyboardArrowDown />}
                                        size='lg'
                                        name="projPumpedPow"
                                        value={formData.projPumpedPow}

                                        sx={{
                                            width: '100%',
                                            [`& .${selectClasses.indicator}`]: {
                                                transition: '0.2s',
                                                [`&.${selectClasses.expanded}`]: {
                                                    transform: 'rotate(-180deg)',
                                                },
                                            },
                                        }}
                                    >
                                        {PowerData.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Grid>
                            ) : (<></>)
                        } */}


                        <Grid item xs={12}>
                            <Typography component="h3" sx={{ my: 2, mb: 0 }}>
                                Total capacity of storage tanks within the project  <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                                    (m<sup>3</sup>) </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Tank no.
                            </Typography>
                            <Input
                                label=""
                                type="text"
                                value={stName}
                                name="stName"
                                onChange={handleChangeTank}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Material
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChangeTank({
                                        target: {
                                            value: newval,
                                            name: "stMaterial"
                                        }
                                    })
                                }}
                                placeholder="Select material*"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="stMaterial"
                                value={stMaterial}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {MaterialData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid>

                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Size (m<sup>3</sup>)
                            </Typography>
                            <Input
                                label=""
                                type="number"
                                value={stSize}
                                name="stSize"
                                onChange={handleChangeTank}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                            <FormControl fullWidth variant={"standard"}>
                                <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many storage tanks as possible*</FormLabel>
                                {!isProcessing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                                        onClick={saveTank}
                                    >
                                        {
                                            tankCapacity.length ? 'Add Tank' : 'Save Tank'
                                        }
                                        
                                    </Button>
                                ) : (
                                    <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                        <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                    </Button>
                                )}
                            </FormControl>
                        </Grid>

                        {isLoadingTank ? (
                            <>
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                            </>
                        ) : (
                            <>
                                {tankCapacity.length ? (<>
                                    <Divider sx={{ my: 2 }} />
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Tank no.</TableCell>
                                                    <TableCell align="left">Material</TableCell>
                                                    <TableCell align="left">Size (m<sup>3</sup>)</TableCell>
                                                    <TableCell align="left">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {tankCapacity.length && tankCapacity.map((tank) => (
                                                    <TableRow
                                                        key={tank.stId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {tank.stName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {tank.stMaterial}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {tank.stSize} (m<sup>3</sup>)
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <IconButton aria-label="delete" size="small" sx={{ mr: .5 }} onClick={() => setTankk(tank)}>
                                                                <Create fontSize="small" onClick={() => handleClickOpen()} />
                                                            </IconButton>

                                                            <IconButton aria-label="delete" size="small">
                                                                <Delete fontSize="small" onClick={() => handleDeleteTank(tank.stId)} />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                                ) : (<></>)}

                            </>
                        )}


                        <Grid item xs={12}>
                            <Typography component="h3" sx={{ my: 2, mb: 0 }}>
                                Extent of pipelines  <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                                    (diameter) </Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Size (diameter size in inches)
                            </Typography>
                            <Input
                                label=""
                                type="text"
                                value={peSize}
                                name="peSize"
                                onChange={handleChangePipeline}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Material
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChangePipeline({
                                        target: {
                                            value: newval,
                                            name: "peMaterial"
                                        }
                                    })
                                }}
                                placeholder="Select material*"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="peMaterial"
                                value={peMaterial}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {PipelinesMaterialData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Grid>

                        <Grid item xs={4}>
                            <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                Total length <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}> (provide estimates in metres)</Typography>
                            </Typography>

                            <Input
                                label=""
                                type="number"
                                value={peTotalLength}
                                name="peTotalLength"
                                onChange={handleChangePipeline}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>

                        <Grid item md={6} xs={12} >
                        </Grid>
                        <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                            <FormControl fullWidth variant={"standard"}>
                                <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many pipelines as possible*</FormLabel>
                                {!isLoadingPipe ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                                        onClick={savePipeline}
                                    >
                                        {
                                            pipeExtent?.length ? 'Add Pipeline' : 'Save Pipeline'
                                        }
                                        
                                    </Button>
                                ) : (
                                    <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                        <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                    </Button>
                                )}
                            </FormControl>
                        </Grid>

                        {isLoadingPipe ? (
                            <>
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                                <Skeleton width={1450} height={30} />
                            </>
                        ) : (
                            <>
                                {pipeExtent?.length ? (<>
                                    <Divider sx={{ my: 2 }} />
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow  >
                                                    <TableCell>Size (diameter)</TableCell>
                                                    <TableCell align="left" >Material</TableCell>
                                                    <TableCell align="left" >Total length (m)</TableCell>
                                                    <TableCell align="left">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pipeExtent.length && pipeExtent.map((pipe) => (
                                                    <TableRow
                                                        key={pipe.peId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {pipe.peSize}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {pipe.peMaterial}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {pipe.peTotalLength} (m)
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <IconButton aria-label="delete" size="small" sx={{ mr: .5 }} onClick={() => setPipelinn(pipe)}>
                                                                <Create fontSize="small" onClick={() => handleClickOpenPipe()} />
                                                            </IconButton>

                                                            <IconButton aria-label="delete" size="small">
                                                                <Delete fontSize="small" onClick={() => handleDeletePipeline(pipe.peId)} />
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                                ) : (<></>)}

                            </>
                        )}

                        <OperationStaff />

                        {/* <Grid item xs={6}>
                            <Grid item xs={12}>
                                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    PDF of water quality analysis of source water
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    fetchedWaterQualityFile ? (
                                        <>
                                            <a className='file-stepper-primary' onClick={() => previewPaper(fetchedWaterQualityFile)}>
                                                {formatPath(fetchedWaterQualityFile)}
                                            </a>
                                            <IconButton
                                                aria-label="close"
                                                onClick={removeFetchedWaterQualityFile}
                                                sx={{
                                                    color: (theme) => theme.palette.grey[500], mr: '6px'
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </>) : (<>
                                            {
                                                !nameWtrAnalysis ? (
                                                    <>
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                            border: 'none',
                                                            // border: '1px solid var(--color-primary-light)',
                                                            width: '337px',
                                                            height: '46px'
                                                        }}>
                                                            <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                                <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                    <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                        <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                                    </div>
                                                                    {
                                                                        errorWtrPdf ? (
                                                                            <Typography component="h6" className="upload-sub-label-error">
                                                                                {errorWtrPdf}
                                                                            </Typography>
                                                                        ) : (
                                                                            <Typography variant="caption" className="upload-sub-label">
                                                                                Click here to upload
                                                                            </Typography>
                                                                        )
                                                                    }
                                                                </div>
                                                                <input
                                                                    type="file"
                                                                    name='nameWtrAnalysis'
                                                                    value={nameWtrAnalysis}
                                                                    accept=".pdf"
                                                                    webkitdirectory
                                                                    onChange={handleWtrPdf}
                                                                    style={{ width: '0px', height: '0px' }}
                                                                />

                                                            </label>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div style={{
                                                            display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                            border: '1px solid var(--color-primary-light)',
                                                            width: '337px',
                                                            height: '46px'
                                                        }}>
                                                            <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                                <span style={{ margin: "0px 6px" }}>
                                                                    {
                                                                        typeWtrAnalysis === "application/pdf" ? (
                                                                            <PictureAsPdf sx={{
                                                                                fontSize: '40px',
                                                                                color: '#f44336'
                                                                            }} />
                                                                        ) :
                                                                            typeWtrAnalysis === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                                <Image sx={{
                                                                                    fontSize: '40px',
                                                                                    color: '#263238',
                                                                                }} />
                                                                            ) :
                                                                                (
                                                                                    <Article sx={{
                                                                                        fontSize: '40px',
                                                                                        color: '#1976d2'
                                                                                    }} />
                                                                                )
                                                                    }

                                                                </span>
                                                                <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                <Typography component="h6" className="fmw-upload-typography">
                                                                    {subString(nameWtrAnalysis, 25)}
                                                                </Typography>


                                                                <Typography variant="caption" className="upload-sub-label">
                                                                    uploaded on  {_date}
                                                                </Typography>
                                                            </div>

                                                            <div>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={removeWtrPdf}
                                                                    sx={{
                                                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                                                    }}
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Grid>
                        </Grid> */}

                        {/* {
                            projMasterMeterInstalledFunctional == "Yes" && (
                                <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            PDF of 12 months meter readings
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            fetchedMeterReadingsFile ? (<>
                                                <a className='file-stepper-primary' onClick={() => previewPaper(fetchedMeterReadingsFile)}>
                                                    {formatPath(fetchedMeterReadingsFile)}
                                                </a>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={removeFetchedMeterReadingsFile}
                                                    sx={{
                                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton></>) : (<>
                                                    {
                                                        !nameMtrReading ? (
                                                            <>
                                                                <div style={{
                                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                                    border: 'none',
                                                                    // border: '1px solid var(--color-primary-light)',
                                                                    width: '337px',
                                                                    height: '46px'
                                                                }}>
                                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                                            </div>
                                                                            {
                                                                                errorMtrPdf ? (
                                                                                    <Typography component="h6" className="upload-sub-label-error">
                                                                                        {errorMtrPdf}
                                                                                    </Typography>
                                                                                ) : (
                                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                                        Click here to upload
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <input
                                                                            type="file"
                                                                            name='nameMtrReading'
                                                                            value={nameMtrReading}
                                                                            accept=".pdf"
                                                                            webkitdirectory
                                                                            onChange={handleMtrPdf}
                                                                            style={{ width: '0px', height: '0px' }}
                                                                        />

                                                                    </label>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div style={{
                                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                                    border: '1px solid var(--color-primary-light)',
                                                                    width: '337px',
                                                                    height: '46px'
                                                                }}>
                                                                    <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                                        <span style={{ margin: "0px 6px" }}>
                                                                            {
                                                                                typeMtrReading === "application/pdf" ? (
                                                                                    <PictureAsPdf sx={{
                                                                                        fontSize: '40px',
                                                                                        color: '#f44336'
                                                                                    }} />
                                                                                ) :
                                                                                    typeMtrReading === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                                        <Image sx={{
                                                                                            fontSize: '40px',
                                                                                            color: '#263238',
                                                                                        }} />
                                                                                    ) :
                                                                                        (
                                                                                            <Article sx={{
                                                                                                fontSize: '40px',
                                                                                                color: '#1976d2'
                                                                                            }} />
                                                                                        )
                                                                            }

                                                                        </span>
                                                                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                        <Typography component="h6" className="fmw-upload-typography">
                                                                            {subString(nameMtrReading, 25)}
                                                                        </Typography>


                                                                        <Typography variant="caption" className="upload-sub-label">
                                                                            uploaded on  {_date}
                                                                        </Typography>
                                                                    </div>

                                                                    <div>
                                                                        <IconButton
                                                                            aria-label="close"
                                                                            onClick={removeMtrPdf}
                                                                            sx={{
                                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }</>)


                                        }
                                    </Grid>
                                </Grid>
                            )
                        } */}

                        {/* {
                            projWraAbstrationPermit == "Yes, valid" && (
                                <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            PDF of valid permit
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {

                                            fetchedPermitFile ? (<>
                                                <a className='file-stepper-primary' onClick={() => previewPaper(fetchedPermitFile)}>
                                                    {formatPath(fetchedPermitFile)}
                                                </a>
                                                <IconButton
                                                    aria-label="close"
                                                    onClick={removeFetchedPermitFile}
                                                    sx={{
                                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </IconButton></>) : (<>{
                                                    !namePermit ? (
                                                        <>
                                                            <div style={{
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                                border: 'none',
                                                                // border: '1px solid var(--color-primary-light)',
                                                                width: '337px',
                                                                height: '46px'
                                                            }}>
                                                                <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                                    <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                        <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                            <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                                        </div>
                                                                        {
                                                                            errorPermitPdf ? (
                                                                                <Typography component="h6" className="upload-sub-label-error">
                                                                                    {errorPermitPdf}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography variant="caption" className="upload-sub-label">
                                                                                    Click here to upload
                                                                                </Typography>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        name='namePermit'
                                                                        value={namePermit}
                                                                        accept=".pdf"
                                                                        webkitdirectory
                                                                        onChange={handlePermitPdf}
                                                                        style={{ width: '0px', height: '0px' }}
                                                                    />

                                                                </label>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                                border: '1px solid var(--color-primary-light)',
                                                                width: '337px',
                                                                height: '46px'
                                                            }}>
                                                                <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                                    <span style={{ margin: "0px 6px" }}>
                                                                        {
                                                                            typePermit === "application/pdf" ? (
                                                                                <PictureAsPdf sx={{
                                                                                    fontSize: '40px',
                                                                                    color: '#f44336'
                                                                                }} />
                                                                            ) :
                                                                                typePermit === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                                    <Image sx={{
                                                                                        fontSize: '40px',
                                                                                        color: '#263238',
                                                                                    }} />
                                                                                ) :
                                                                                    (
                                                                                        <Article sx={{
                                                                                            fontSize: '40px',
                                                                                            color: '#1976d2'
                                                                                        }} />
                                                                                    )
                                                                        }

                                                                    </span>
                                                                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                    <Typography component="h6" className="fmw-upload-typography">
                                                                        {subString(namePermit, 25)}
                                                                    </Typography>


                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                        uploaded on  {_date}
                                                                    </Typography>
                                                                </div>

                                                                <div>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={removePermitPdf}
                                                                        sx={{
                                                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }</>)


                                        }
                                    </Grid>
                                </Grid>
                            )
                        } */}

                        {/* {
                            projTypeOfSysytem == "Pumped" || projTypeOfSysytem == "Hybrid = Pumped + Gravity" ? (
                                <Grid item xs={6}>
                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            PDF of picture of pump- if pumped or hybrid
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            fetchedPumpFile ? (
                                                <>
                                                    <a className='file-stepper-primary' onClick={() => previewPaper(fetchedPumpFile)}>
                                                        {formatPath(fetchedPumpFile)}
                                                    </a>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={removeFetchedPumpFile}
                                                        sx={{
                                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </>
                                            ) : (<>
                                                {
                                                    !namePump ? (
                                                        <>
                                                            <div style={{
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                                border: 'none',
                                                                // border: '1px solid var(--color-primary-light)',
                                                                width: '337px',
                                                                height: '46px'
                                                            }}>
                                                                <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                                    <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                                        <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                            <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                                        </div>
                                                                        {
                                                                            errorPumpPdf ? (
                                                                                <Typography component="h6" className="upload-sub-label-error">
                                                                                    {errorPumpPdf}
                                                                                </Typography>
                                                                            ) : (
                                                                                <Typography variant="caption" className="upload-sub-label">
                                                                                    Click here to upload
                                                                                </Typography>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        name='namePump'
                                                                        value={namePump}
                                                                        accept=".pdf"
                                                                        webkitdirectory
                                                                        onChange={handlePumpPdf}
                                                                        style={{ width: '0px', height: '0px' }}
                                                                    />

                                                                </label>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div style={{
                                                                display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                                border: '1px solid var(--color-primary-light)',
                                                                width: '337px',
                                                                height: '46px'
                                                            }}>
                                                                <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                                    <span style={{ margin: "0px 6px" }}>
                                                                        {
                                                                            typePump === "application/pdf" ? (
                                                                                <PictureAsPdf sx={{
                                                                                    fontSize: '40px',
                                                                                    color: '#f44336'
                                                                                }} />
                                                                            ) :
                                                                                typePump === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                                    <Image sx={{
                                                                                        fontSize: '40px',
                                                                                        color: '#263238',
                                                                                    }} />
                                                                                ) :
                                                                                    (
                                                                                        <Article sx={{
                                                                                            fontSize: '40px',
                                                                                            color: '#1976d2'
                                                                                        }} />
                                                                                    )
                                                                        }

                                                                    </span>
                                                                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                                    <Typography component="h6" className="fmw-upload-typography">
                                                                        {subString(namePump, 25)}
                                                                    </Typography>


                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                        uploaded on  {_date}
                                                                    </Typography>
                                                                </div>

                                                                <div>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={removePumpPdf}
                                                                        sx={{
                                                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }</>)


                                        }
                                    </Grid>
                                </Grid>

                            ) : (<></>)
                        } */}

                    </Grid>
                )
            }
            <TankEdit open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} tankEdited={tankk} />
            <PipeEdit open={openPipe} handleClickOpen={handleClickOpenPipe} handleClose={handleClosePipe} pipeEdited={pipelinn} />

        </>
    )
}

export default Step3