import { Avatar, Card, CardHeader } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedUser } from '../../features/message/chatUserSlice';

function ChartUsers({ userData }) {
	const myRef = useRef(null);
	const scrollToFirstChat = () =>
		myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

	const dispatch = useDispatch();
	async function logName(data) {
		dispatch(setSelectedUser(data));
	}

	function getRandomColor() {
		let color = Math.floor(0x1000000 * Math.random()).toString(16);
		return '#' + ('000000' + color).slice(-6);
	}

	function capitalizeWords(str) {
		if (!str) return str;
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}

	useEffect(() => {
		scrollToFirstChat();
	}, [userData]);

	let date;
	let currentTime = moment(userData.chCreatedTime).startOf('hour').fromNow();

	function checkHours(str) {
		let hour = Number(str.split(' ')[0]);
		if(!hour) return str
		return hour
	}

	if (checkHours(currentTime) > 2 && checkHours(currentTime) > 24) {
		date = moment(userData.chCreatedTime).calendar()
	} 
	// else if (checkHours(currentTime) >= 2) {
	// 	date = moment(userData.chCreatedTime).startOf('day').fromNow()
	// } 
	else if (currentTime === 'an hour ago') {
		date = moment(userData.chCreatedTime).calendar()
	} else if (checkHours(currentTime) > 24 && checkHours(currentTime) > 48) {
		date = moment(userData.chCreatedTime).format('LL');
	} else if(currentTime === 'a day ago'){
		date = moment(userData.chCreatedTime).add(0, 'days').calendar(); 
	}
	else {
		date = moment(userData.chCreatedTime).calendar()
	}

	return (
		<>
			<Card
				ref={myRef}
				onClick={() => logName(userData)}
				sx={{
					borderRight: userData.active ? 4 : 0,
					borderTop: userData.active ? '1px solid #caf7fe' : null,
					borderColor: userData.active ? '#74e4f9' : null,
					backgroundColor: userData.active ? '#e6fafd' : null,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					borderBottom: 'none',
					boxShadow: 'none',
					cursor: 'pointer',
				}}
			>
				<CardHeader
					sx={{ fontWeight: 'bold' }}
					avatar={
						<Avatar sx={{ bgcolor: getRandomColor() }} aria-label='recipe'>
							{userData.withParty
								? userData.withParty.substring(0, 1).toUpperCase()
								: null}
						</Avatar>
					}
					title={capitalizeWords(userData.withParty)}
					subheader={date}
				/>
				<Box sx={{ fontSize: 11, pr: 2, color: ' #2ec7c7', mt: 1 }}>
					{/* {userData.active ? '1 New message' : null} */}
				</Box>
			</Card>
		</>
	);
}

export default ChartUsers;
