import React from 'react'
import { Divider, Typography } from '@mui/joy'
import { Grid } from '@mui/material'
import PostImplementationList from '../../components/loan_perfection/PostImplementationList'


function PostImplementation() {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography component="h3" className="fmw-page-title">
            Post implementation
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Divider />
        </Grid>
        <PostImplementationList />
        <PostImplementationList />
      </Grid>
    </>
  )
}

export default PostImplementation