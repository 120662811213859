import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from 'react-toastify';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FormHelperText, Grid, Input, TextField, Typography } from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PositionData } from '../../PositionData';
import { cleanupNationalId, fetchDirectorsById, onbaordCopyIdStep3, reset, updateBankInfos, updateBudgetInfo, updateDirector } from '../../features/onboard_partner/onboardPartnerSlice';
import NationalId from './NationalId';
import { useEffect } from 'react';
import { formDataFromObject } from '../../utils';
import { CircularProgress } from '@mui/material';
import { MainBudgetItems } from '../../pages/onboard_wsp/data/MainBudgetItems';
import NumericFormat from 'react-number-format';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BudgetEdit = ({ open, handleClickOpen, handleClose, budgetEdited }) => {

    const dispatch = useDispatch();
    const { isLoading, message, isSuccessUpdateBudget, isErrorBudget, isLoadingUpdateBudget } = useSelector((state) => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState('')
    const [biBudget, setBiBudget] = useState('')
    const handleChangeBudgetKes = (ev) => {
        ev.floatValue < 0 ? (setBiBudget(ev.floatValue = 0)) :
            setBiBudget(ev.floatValue);
        setIsUpdating(true);
    };


    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };

    const materialUiTextFieldProps = {
        required: true,
        fullWidth: true,
    };

    const updateBudgetDetails = () => {
        dispatch(updateBudgetInfo({
            biId: formData.biId,
            biItem: formData.biItem,
            biBudget: biBudget,
            biComment: formData.biComment,
        }));
    }

    useEffect(() => {
        setFormData(budgetEdited)
        setBiBudget(budgetEdited?.biBudget)

        if (isSuccessUpdateBudget) {
            toast.success('Budget Info updated successfully ');
            handleClose();
        }
        if (isErrorBudget) {
            toast.warning(message);
        }
        return () => {
            dispatch(reset())
        }

    }, [dispatch, budgetEdited, isSuccessUpdateBudget, isErrorBudget, message])

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                {
                    isLoading ? (<><p>loading...</p></>)
                        : (<>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="h6" className="fmw-checkbox-label" style={{ display: 'flex', alignItems: 'center' }}>Editing:
                                    <Typography sx={{ ml: 1 }} component="p" className="fmw-onboard-partner-sub-label-modal">{formData?.biItem}</Typography>
                                </Typography>

                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Item
                                        </Typography>
                                        <Select
                                            className="fmw-select"
                                            onChange={(e, newval) => {
                                                handleChange({
                                                    target: {
                                                        value: newval,
                                                        name: "biItem"
                                                    }
                                                })
                                            }}
                                            placeholder="Select position *"
                                            indicator={<KeyboardArrowDown />}
                                            size='lg'
                                            name="biItem"
                                            value={formData?.biItem ? formData?.biItem : ''}
                                            sx={{
                                                width: '100%',
                                                [`& .${selectClasses.indicator}`]: {
                                                    transition: '0.2s',
                                                    [`&.${selectClasses.expanded}`]: {
                                                        transform: 'rotate(-180deg)',
                                                    },
                                                },
                                            }}
                                        >
                                            {MainBudgetItems?.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>

                                    </Grid>


                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Budget (Kes)
                                        </Typography>
                                        <NumericFormat
                                            value={biBudget}
                                            customInput={TextField}
                                            placeholder='KES'
                                            className="fmw-input fmw-step4"
                                            onValueChange={handleChangeBudgetKes}
                                            thousandSeparator=","
                                            decimalSeparator="."
                                            {...materialUiTextFieldProps}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                            Comment
                                        </Typography>
                                        <Input
                                            label=""
                                            type="text"
                                            value={formData?.biComment}
                                            name="bAccNo"
                                            onChange={handleChange}
                                            size="lg"
                                            className="fmw-input-joy"
                                        />
                                    </Grid>
                                </Grid>


                            </DialogContent>
                            <DialogActions>
                                {!isLoadingUpdateBudget ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                                        onClick={updateBudgetDetails}
                                    >
                                        Update Budget Information
                                    </Button>
                                ) :

                                    (
                                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                            <CircularProgress size={17} color="inherit" />&nbsp;Updating...
                                        </Button>
                                    )}
                            </DialogActions></>)


                }
            </BootstrapDialog>
        </div>
    );
}
export default BudgetEdit