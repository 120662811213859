export const SourceParty = [
    {
        value: 'County Government',
        label: 'County Government',
    },
    {
        value: 'Harambee',
        label: 'Harambee',
    },
    {
        value: 'Donor',
        label: 'Donor',
    },
]
