import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const onboardFiles =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/postImages.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const saveCommittedDetails =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveWaterResource.action`, formData)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const fetchCommittedDetails =async({wrProjId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchWaterResource.action?wrProjId=${wrProjId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const fetchBusinessPlan = async({bspProjId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchBusinessPlan.action?bspProjId=${bspProjId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const saveBusinessPlan = async({formData})=>{
    const response = await axios.post(`${API_URL}/usr/saveBusinessPlan.action`, formData)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const fetchObjector = async({obProjId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchObjector.action?obProjId=${obProjId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const saveObjector = async({formData})=>{
    const response = await axios.post(`${API_URL}/usr/saveObjector.action`, formData)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}



const committedService={
    onboardFiles,
    saveCommittedDetails,
    fetchCommittedDetails,
    fetchBusinessPlan,
    saveBusinessPlan,
    fetchObjector,
    saveObjector,
}
export default committedService
