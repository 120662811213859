import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const getPdfInteractions = async (fileId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchEvents.action?eveProjaId=${fileId}&start=0&limit=25`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const postPdfInteractions = async (formData) => {
  const response = await axios.post(
    `${API_URL}/usr/saveEvent.action`,
    formData
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

/**
 * Call expression Data from DB
 * returns all expression Data
 */

const getExpressionData = async (filterData) => {
 

  const response = await axios.get(
    `${API_URL}/usr/fetch_projects.action${filterData}`
  );

  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

/**
 * Get Director Information from DB
 * @param {projId} projId This is project identifier
 * @returns directors Information
 */
const getDirectorInformation = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchDirectors.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};
//ss
/**
 * Calls Storage information from DB
 * @param {*} projId
 * @returns
 */
const getStorageInformation = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchTotalCapacityOfStorages.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const getPipeInformation = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchPipeExtents.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const getBankInformation = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchBankInfos.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const getBudgetItemsInformation = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchBudgetItems.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const postToolTipData = async (toolData) => {
  const response = await axios.post(
    `${API_URL}/usr/saveAttachment.action`,
    toolData
  );

  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const getToolTipData = async (projId) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchAttachments.action?projId=${projId}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const fetchProjectScore = async (data) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchProjectScore.action?projId=${data.projId}&page=${data.page}`
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }

  return response.data;
};

const postProjectScore = async (formData) => {
  const response = await axios.post(
    `${API_URL}/usr/saveProjectScore.action?page=${formData.stepName}`,
    formData.data
  );

  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const postScoreStep = async (formData) => {
  const response = await axios.post(
    `${API_URL}/usr/saveScoreStep.action`,
    formData
  );

  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const fetchScoreStep = async (formData) => {
  const response = await axios.get(
    `${API_URL}/usr/fetchScoreStep.action?projId=${formData.projId}`
  );

  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

const postAttachmentAlt = async (formData) => {
  const response = await axios.post(
    `${API_URL}/usr/postAttachmentAlt.action`,
    formData
  );
  if (!response.data.success) {
    return Promise.reject(response.data.message);
  }
  return response.data;
};

// saveProjectPriority
const saveProjectPriority =async(formData)=>{
  const response=await axios.post(`${API_URL}/usr/saveProjectPriority.action`, formData)
  if (!response.data.success) {
      return Promise.reject(response.data.message)
  }
  return response.data.jsonData
}

// fetchProjectPriority
const fetchProjectPriority =async(projId)=>{
  const response = await axios.get(`${API_URL}/usr/fetchProjectPriority.action?priProjId=${projId}`)
  if (!response.data.success) {
      return Promise.reject(response.data.message)
  }

  return response.data.data.result;
}

const expressionDemandService = {
  getExpressionData,
  postToolTipData,
  getDirectorInformation,
  getToolTipData,
  getStorageInformation,
  getPipeInformation,
  getBankInformation,
  getBudgetItemsInformation,
  postPdfInteractions,
  getPdfInteractions,
  fetchProjectScore,
  postProjectScore,
  postScoreStep,
  postAttachmentAlt,
  fetchScoreStep,
  saveProjectPriority,
  fetchProjectPriority,
};
export default expressionDemandService;
