import React, { useState } from 'react'
import { MasterMeterData, OperationalStaffData, PowerData, ReliabilityData, SysytemTypeData, TypeStaffData, WRA, WaterQualityData, WaterSourceData } from '../../Step3Data'
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { AttachFile, Create, Delete, FavoriteBorder, KeyboardArrowDown } from '@mui/icons-material';
import {
    CircularProgress, Divider, FormControl, FormLabel, Grid,
    IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton
} from '@mui/material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Chip, Input, Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { deleteWaterSrc,  fetchWaterSrc,  reset,  saveWaterSrc } from '../../features/onboard_partner/onboardPartnerSlice';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';
import WaterQuality from '../onboard_wsp_files/WaterQuality';
import MasterMeter from '../onboard_wsp_files/MasterMeter';
import AbstractionPermit from '../onboard_wsp_files/AbstractionPermit';
import PumpPicture from '../onboard_wsp_files/PumpPicture';
import { formatPath, previewPaper } from '../../utils';
import WtrSrcEdit from '../onboard_partner_modals/WtrSrcEdit';

function WaterSources() {
    const dispatch = useDispatch();
    const { isLoadingWtrSrc,  isSuccessDelete, isSuccessUpdateWtrSrc,step3Source,step3, 
        step3WtrAnalysisPdf, step3MtrReadingPdf, step3PermitPdf, step3PumpPdf,wtrSrc
    } = useSelector((state) => state.onboardPartner)
    const { wspApplications, isLoadingWsp } = useSelector((state) => state.wsp)
    const [formData1, setFormData1] = useState(wspApplications[0])
    const [isUpdating, setIsUpdating] = useState(false);

//    console.log("step3",step3)
  
       
    //  const [wsSource, setWsSource] = useState('')
       const [formData, setFormData] = useState({
        wsSource: null,
        wsReliability: null,
        wsQuality: null,
        wsMasterMtr: null,
        wsWra: null,
        wsSystem: null,
        wsPower: null,
    })
 
    const { wsSource,  wsReliability, wsQuality, wsMasterMtr, wsWra, wsSystem, wsPower } = formData;

 
    const saveWaterSources = (e) => {
        if (!wsSource || !wsReliability || !wsQuality || !wsMasterMtr || !wsWra || !wsSystem) {
            toast.warning("Please fill all water source details")
        }
        else if ((wsSystem == "Pumped" || wsSystem == "Hybrid = Pumped + Gravity" ) && (!wsPower)){
            toast.warning("Please select type of power")
        }
         else {
            dispatch(saveWaterSrc({
                wsProjId: wspApplications[0].projId,
                wsSource: wsSource,
                wsReliability: wsReliability,
                wsQuality: wsQuality,
                wsMasterMtr: wsMasterMtr,
                wsWra: wsWra,
                wsSystem: wsSystem,
                wsPower: wsPower,
                wsQualityFile: step3WtrAnalysisPdf.length > 0 ? (step3WtrAnalysisPdf) : (null),
                wsMtrFile: step3MtrReadingPdf.length > 0 ? (step3MtrReadingPdf) : (null),
                wsWraFile: step3PermitPdf.length > 0 ? (step3PermitPdf) : (null),
                wsPowerFile: step3PumpPdf.length > 0 ? (step3PumpPdf) : (null),

            }));
            setFormData({ wsSource: null, wsReliability: null, wsQuality: null, wsMasterMtr: null, wsWra: null, wsSystem: null, wsPower: null });
        }
    }
    const handleChangeSource = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
    const handleDeleteWaterSources = (id) => {
        dispatch(deleteWaterSrc({
            wsId: id
        }));
        setIsUpdating(false);
    }
    const [wtrSrcc, setWtrSrcc] = useState()
    const [openWtr, setOpenWtr] = React.useState(false);

    const handleClickOpenWtr = () => {
        setOpenWtr(true);
    };
    const handleCloseWtr = () => {
        setOpenWtr(false);
    };
    useEffect(() => {
        if (!isUpdating && isSuccessDelete) {
            dispatch(fetchWaterSrc({
                projId: wspApplications[0].projId
            }));
        } else if (!isUpdating || isSuccessUpdateWtrSrc) {
            setTimeout(() => {
                dispatch(fetchWaterSrc({
                    projId: wspApplications[0].projId
                }));
            }, 800)
            // dispatch(fetchWspApplications())
        } else if (!isUpdating) {
            setTimeout(() => {
                dispatch(fetchWaterSrc({
                    projId: wspApplications[0].projId
                }));
            }, 800)

            dispatch(fetchWspApplications())
        }
        else {
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, isUpdating, isSuccessDelete, isSuccessUpdateWtrSrc]);

    return (
        <>
            <Grid item xs={12}>
                <Typography component="h3" sx={{ my: 2, mb: 0 }}>
                    Water sources
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Average volume abstracted per day from source m3/day wet season
                </Typography>
                <Select
                    className="fmw-select joy-select"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsSource"
                            }
                        })
                    }}
                    placeholder="Select water source*"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsSource"
                    value={wsSource}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {WaterSourceData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
              
            </Grid>
            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Reliability of water source
                </Typography>
                <Select
                    className="fmw-select sm"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsReliability"
                            }
                        })
                    }}
                    placeholder="Select reliability*"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsReliability"
                    value={wsReliability}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {ReliabilityData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>

            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Water quality at source
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsQuality"
                            }
                        })
                    }}
                    placeholder="Select water quality *"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsQuality"
                    value={wsQuality}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {WaterQualityData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>

            <WaterQuality formData={formData1} />

            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Is a master meter installed and functional ?
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsMasterMtr"
                            }
                        })
                    }}
                    placeholder="Select functionality *"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsMasterMtr"
                    value={wsMasterMtr}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {MasterMeterData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>
            {
                wsMasterMtr == "Yes" && (
                    <MasterMeter formData={formData1} />
                )
            }


            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Is there a water regulatory authority (WRA) abstraction permit ?
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsWra"
                            }
                        })
                    }}
                    placeholder="Select permit existence *"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsWra"
                    value={wsWra}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {WRA.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </Grid>

            {
                wsWra == "Yes, valid" && (
                    <AbstractionPermit formData={formData1} />
                )
            }


            <Grid item xs={6}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Type of system a pumped or gravity system or hybrid?
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeSource({
                            target: {
                                value: newval,
                                name: "wsSystem"
                            }
                        })
                    }}
                    placeholder="Select type of pump *"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="wsSystem"
                    value={wsSystem}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {SysytemTypeData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>

            {
                wsSystem == "Pumped" || wsSystem == "Hybrid = Pumped + Gravity" ? (
                    <Grid item xs={6}>
                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                            If pumped, type of power used
                        </Typography>
                        <Select
                            className="fmw-select"
                            onChange={(e, newval) => {
                                handleChangeSource({
                                    target: {
                                        value: newval,
                                        name: "wsPower"
                                    }
                                })
                            }}
                            placeholder="Select Type of power*"
                            indicator={<KeyboardArrowDown />}
                            size='lg'
                            name="wsPower"
                            value={wsPower}

                            sx={{
                                width: '100%',
                                [`& .${selectClasses.indicator}`]: {
                                    transition: '0.2s',
                                    [`&.${selectClasses.expanded}`]: {
                                        transform: 'rotate(-180deg)',
                                    },
                                },
                            }}
                        >
                            {PowerData.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Grid>
                ) : (<></>)
            }
            {
                wsSystem == "Pumped" || wsSystem == "Hybrid = Pumped + Gravity" ? (
                    <PumpPicture formData={formData1} />
                ) : (<></>)
            }



            <Grid item md={7} xs={12} >
            </Grid>
            <Grid item md={5} xs={12} sx={{ mt: 1 }} >
                <FormControl fullWidth variant={"standard"}>
                    <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many water sources as possible*</FormLabel>
                    {!isLoadingWtrSrc ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                            onClick={saveWaterSources}
                        >
                            {
                                wtrSrc?.length ? 'Add Water Source' :'Save Water Sources'
                            }
                            
                        </Button>
                    ) : (
                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                            <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                        </Button>
                    )}
                </FormControl>
            </Grid>

            {isLoadingWtrSrc ? (
                <>
                    <Skeleton width={1450} height={30} />
                    <Skeleton width={1450} height={30} />
                </>
            ) : (
                <>
                    {wtrSrc?.length ? (<>
                        <Divider sx={{ my: 2 }} />
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow  >
                                        <TableCell>Source</TableCell>
                                        <TableCell align="left" >Reliability</TableCell>
                                        <TableCell align="left" >Quality</TableCell>
                                        <TableCell align="left">Quality File</TableCell>
                                        <TableCell align="left" >Meter</TableCell>
                                        <TableCell align="left">Meter File</TableCell>
                                        <TableCell align="left" >Permit</TableCell>
                                        <TableCell align="left"> Permit File</TableCell>
                                        <TableCell align="left" >System </TableCell>
                                        <TableCell align="left" >Power</TableCell>
                                        <TableCell align="left">Pump</TableCell>

                                        <TableCell align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {wtrSrc.length && wtrSrc.map((wtr) => (
                                        <TableRow
                                            key={wtr.osId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {wtr.wsSource}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {wtr.wsReliability}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {wtr.wsQuality}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Chip
                                                    variant="soft"
                                                    className="file-chip-primary"
                                                    color="primary"
                                                    size="sm"
                                                    startDecorator={<AttachFile size="sm" />}
                                                    onClick={() => previewPaper(wtr.wsQualityFile)}
                                                >
                                                    {formatPath(wtr.wsQualityFile)}
                                                </Chip>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {wtr.wsMasterMtr}
                                            </TableCell>

                                            <TableCell align="left">
                                                <Chip
                                                    variant="soft"
                                                    className="file-chip-primary"
                                                    color="primary"
                                                    size="sm"
                                                    startDecorator={<AttachFile size="sm" />}
                                                    onClick={() => previewPaper(wtr.wsMtrFile)}
                                                >
                                                    {formatPath(wtr.wsMtrFile)}
                                                </Chip>
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {wtr.wsWra}
                                            </TableCell>

                                            <TableCell align="left">
                                                <Chip
                                                    variant="soft"
                                                    className="file-chip-primary"
                                                    color="primary"
                                                    size="sm"
                                                    startDecorator={<AttachFile size="sm" />}
                                                    onClick={() => previewPaper(wtr.wsWraFile)}
                                                >
                                                    {formatPath(wtr.wsWraFile)}
                                                </Chip>
                                            </TableCell>

                                            <TableCell component="th" scope="row">
                                                {wtr.wsSystem}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {wtr.wsPower}
                                            </TableCell>

                                            <TableCell align="left">
                                                <Chip
                                                    variant="soft"
                                                    className="file-chip-primary"
                                                    color="primary"
                                                    size="sm"
                                                    startDecorator={<AttachFile size="sm" />}
                                                    onClick={() => previewPaper(wtr.wsPowerFile)}
                                                >
                                                    {formatPath(wtr.wsPowerFile)}
                                                </Chip>
                                            </TableCell>
                                            
                                            <TableCell align="left">
                                                <IconButton aria-label="delete" size="small" sx={{ mr: .5 }} onClick={() => setWtrSrcc(wtr)}>
                                                    <Create fontSize="small" onClick={() => handleClickOpenWtr()} />
                                                </IconButton>

                                                <IconButton aria-label="delete" size="small">
                                                    <Delete fontSize="small" onClick={() => handleDeleteWaterSources(wtr.wsId)} />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    ) : (<></>)}

                </>
            )}
            <WtrSrcEdit open={openWtr} handleClickOpen={handleClickOpenWtr} handleClose={handleCloseWtr} wtrEdited={wtrSrcc} />
        </>
    )
}

export default WaterSources
