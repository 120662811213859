import { Checkbox, Grid, Typography } from '@mui/joy'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cleanDrinkingWater from '../../../assets/clean_drinking_water.png';
import waterResourceManagement from '../../../assets/water_resource_management.png';
import waterQuality from '../../../assets/water_quality.png';
import waterUseEfficiency from '../../../assets/water_use_efficiency.png';
import accessSantitationHygiene from '../../../assets/access_santitation_hygiene.png';
import protectionRestoration from '../../../assets/protection_restoration.png';
import { registerPartner, setCoreIndicators } from '../../../features/partner/partnerSlice';

function CoreIndicators() {
    const dispatch = useDispatch();
    const { indicators } = useSelector(state => state.partner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState(indicators)
    const { accFocus1,accFocus2,accFocus3,accFocus4,accFocus5,accFocus6 } = formData
 
    
    const handleChange = (e) => {
        if(e.target.checked){
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
            // console.log("e.target",e.target.checked)
            setIsUpdating(true);
        }else{
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: ''
            }))
            setIsUpdating(true);
        }
    };

    useEffect(()=>{
        if(!isUpdating){

        }else{
            dispatch(setCoreIndicators(formData))
        }
    },[dispatch, isUpdating, formData])
  
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '2rem' }}>
                        Please select your focus area <span className="star">*</span>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus1"
                                checked={accFocus1}
                                value="Access to clean drinking water"
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={cleanDrinkingWater} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Access to clean drinking water
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    Universal and equitable access to safe and affordable drinking water for all.
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus2"
                                checked={accFocus2}
                                value="Water resource management "
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={waterResourceManagement} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Water resource management
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    Access to adequate and equitable sanitation and hygiene for all and end open defecation, paying special attention to the needs of women and girls and those in vulnerable situations
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus3"
                                checked={accFocus3}
                                value="Water quality"
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={waterQuality} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Water quality
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    Improve water quality by reducing pollution, eliminating dumping and minimizing release of hazardous chemicals and materials, halving the proportion of untreated wastewater, and substantially increasing recycling and safe reuse globally
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus4"
                                checked={accFocus4}
                                value="Water-use efficiency"
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={waterUseEfficiency} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Water-use efficiency
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    Increase water-use efficiency across all sectors and ensure sustainable withdrawals and supply of freshwater to address water scarcity and substantially reduce the number of people suffering from water scarcity.
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus5"
                                checked={accFocus5}
                                value="Access to sanitation & hygiene"
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={accessSantitationHygiene} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Access to sanitation & hygiene
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    implement integrated water resources management at all levels, including through transboundary cooperation as appropriate
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Checkbox
                                color="primary"
                                name="accFocus6"
                                checked={accFocus6}
                                value="Protection & restoration of water related ecosystems"
                                onChange={handleChange}
                                label={
                                    <React.Fragment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <img src={protectionRestoration} alt="clean-drinking-water" style={{ width: '47px', height: '46px', marginRight: '12px' }} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography component="h6" className="fmw-coreindicators-sub-label">
                                                    Protection & restoration of water related ecosystems
                                                </Typography>

                                                <Typography component="h9" className="fmw-coreindicators-text">
                                                    Protect and restore water related ecosystems, including mountains, forests, wetlands, rivers, aquifers and lakes
                                                </Typography>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CoreIndicators