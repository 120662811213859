import { Typography } from '@mui/joy'
import { Divider, Grid } from '@mui/material'
import React from 'react'
import './timelines.css'

function Timelines() {
   return (

      <div style={{ margin: '1rem' }}>
         <Grid container spacing={2}>
            <Grid item xs={12} className = "heading-timelines">
            <Typography component="h3" className='fmw-page-title' >Timelines</Typography>
            <Divider sx={{mt:'17px' }}/>
            </Grid>
         </Grid>
         
         <Grid container spacing={2} style={{ marginBottom: '2rem' }}>
            <Grid item xs={2} className="heading-activity">
               Activity
            </Grid>
            <Grid item xs={8} className="heading-keyFeature-timeline">
               Key feature
            </Grid>
            <Grid item xs={2} className="heading-keyFeature-timeline">
               Timeline
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#72CECE' }} className="time-activity">
               Application
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Submitted Successfully
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               -
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#72CECE' }} className="time-activity">
               Screening
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Technical team is reviewing your application.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               6 weeks
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#72CECE' }} className="time-activity">
               Preparation
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Technical team is preparing the project for submission to financial institution
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               6 months
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#D8849D' }} className="time-activity">
               Loan offer
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               After analysis, financial institutions will make an offer.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               4 weeks
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#547A9A' }} className="time-activity">
               Loan perfection
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               On the acceptance of the deal by the applicants, the financial institution will establish an output target to be archieved by the SSP.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               6 Weeks
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#7A78BC' }} className="time-activity">
               Project development
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Funds are disbursed and project construction commences.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               1 Year
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#AF8FDE' }} className="time-activity">
               Post Implementation
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Project construction is complete and the project is handed over to the SSP.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               1 Month from completion
            </Grid>
         </Grid>

         <Grid container spacing={2} className="timelines">
            <Grid item xs={2} style={{ background: '#547A9A' }} className="time-activity">
               Outcome Verficication
            </Grid>
            <Grid item xs={8} style={{ borderRight: '1px solid #E4E4E4' }} className="time-key-feature">
               Subsidy is paid to the SSP on an achievement of the pre determined output target.
            </Grid>
            <Grid item xs={2} className="time-key-feature">
               2-6 Months from completion
            </Grid>
         </Grid>
      </div>

   )
}

export default Timelines