import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Flag, KeyboardArrowDown, PersonAdd } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';

export default function CustomChoice({
	choiceName,
	getChoiceData,
	choiceValue,
    options
}) {

    const [choice, setchoice] = useState(choiceValue)
	const handleChangeSelect = async (e) => {
        await setchoice(e.target.value)
		await getChoiceData(e);
	};

	const getSelectBorder = () => {
		let borderColor = '';
		if (!choice) {
			borderColor = '1px dashed var(--color-technical) !important';
		} else if (choice === 'Yes') {
			borderColor = '1px solid #44BA41 !important';
		} else if (choice === 'No') {
			borderColor = '1px solid #C04544 !important';
		}
		return `${borderColor}`;
	};

	const getSelectBackground = () => {
		let backgroundColor = '#E8FAFF';
		if (choice) {
			backgroundColor = '#fff !important';
		}
		return `${backgroundColor}`;
	};

    useEffect(() => {
    }, [choice, choiceValue])

    useEffect(() => {
        if (choiceValue?.length) {
            setchoice(choiceValue)
        }
    }, [choiceValue])

	return (
		<>
			<Select
				onChange={(e, newval) => {
					handleChangeSelect({
						target: {
							value: newval,
							name: choiceName,
						},
					});
				}}
				value={choice}
				name={choiceName}
				placeholder={choice ? '' : 'Choose option'}
				slotProps={{
					listbox: {
						sx: {
							'--ListItemDecorator-size': '44px',
						},
					},
				}}
				sx={{
					'--ListItemDecorator-size': '44px',
					// minWidth: 24,
					width: choice ? '93px' : '175px',
					borderRadius: '25px',
					border: getSelectBorder(),
					backgroundColor: getSelectBackground(),
					[`& .${selectClasses.indicator}`]: {
						transition: '0.2s',
						[`&.${selectClasses.expanded}`]: {
							transform: 'rotate(-180deg)',
						},
					},
				}}
				indicator={<KeyboardArrowDown />}
				// renderValue={renderValue}
			>
				{options.map((option, index) => (
					<React.Fragment key={option.value}>
						<Option value={option.value} label={option.label}>
							{option.label}
						</Option>
					</React.Fragment>
				))}
			</Select>
		</>
	);
}
