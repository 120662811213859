import {
  Checkbox,
  Chip,
  Select,
  Option,
  Input,
  Typography,
  FormHelperText,
} from "@mui/joy";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  registerPartner,
  setInstitutionDetails,
} from "../../../features/partner/partnerSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Delete,
  KeyboardArrowDown,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { setPhoneErrorStep1 } from "../../../features/onboard_partner/onboardPartnerSlice";
import { selectClasses } from "@mui/joy/Select";
import { TypeOfRegistration } from "../data/TypeOfRegistration";
import { PeriodsOrg } from "../data/PeriodsOrg";
import { fetchWspApplications } from "../../../features/wsp/wspSlice";
import { StateWp } from "../../../stateWp";
import { WaterProject } from "../../../WaterProject";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Step1Partner from "./Step1Partner";
import { Navigate } from "react-router-dom";
import { formatPhoneNumber } from "../../../utils";

const Step1 = () => {
  const dispatch = useDispatch();
  const { step1, errorPhoneStep1 } = useSelector(
    (state) => state.onboardPartner
  );
  const { user } = useSelector((state) => state.auth);
   
  const { wsp, sendProjId, wspApplications } = useSelector(
    (state) => state.wsp
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState({
    projNumberWaterKiosks: "",
    registeredMembers: "",
    projIndividualActiveConn: "",
    projIndividualClosedConn: "",
    projInstitutionCommercialConn: "",
    projState: "",
    projNumberHouseholdsServed: "",
    projOrgPeriod: "",
    projAlternatePhone: "",
    projCounty: "",
    projSubCounty: "",
    usrFullNames: "",
    projLnNumbers: "",
    proWard: "",
    projSubLoc: "",
    projLocation: "",
    projTown: "",
    projPostCode: "",
    projTelLandline: "",
    projTelMobile: "",
    projEmail: "",
  });
  // const { projNumberWaterKiosks, registeredMembers, projIndividualActiveConn, projIndividualClosedConn, projInstitutionCommercialConn, projState, projNumberHouseholdsServed, projOrgPeriod, projAlternatePhone, projCounty, projSubCounty, usrFullNames, projLnNumbers, proWard, projSubLoc, projLocation, projTown, projPostCode, projTelLandline, projTelMobile, projEmail } = formData

  // const [phoneNo,setPhoneNo] =  useState(projAlternatePhone);
  // const [isValidPhone, setIsValidPhone] = useState(errorPhoneStep1);
  const usrAltFullNames = `${user.usrAltFirstName} ${user.usrAltLastName}`;
  // const handlePhone=(e)=>{
  //     const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(e);
  //     setPhoneNo(e);
  //     setIsValidPhone(isKenyanPhoneNumber);
  //     setIsUpdating(true);
  // }

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };

  // const handleChangePhone = (e) => {
  //     e.target.value < 0 ? (e.target.value = 0) :
  //         setFormData((prevState) => ({
  //             ...prevState,
  //             [e.target.name]: e.target.value
  //         }))
  //     // setIsValid(validatePhoneNumber(e.target.value));
  //     setIsUpdating(true);
  // };

  let newWspApplications = wspApplications[0]
   
  useEffect(() => {
    if (!isUpdating && user.usrJbrnId === 1) {
      setTimeout(() => {
        dispatch(fetchWspApplications());
      }, 800);
    } else if (!isUpdating && sendProjId) {
      dispatch(
        fetchWspApplications({
          projId: sendProjId,
        })
      );
    } else {
    }
  }, [dispatch, isUpdating, sendProjId]);

 

  return (
    <>
      {user.usrJbrnId === 0 && !sendProjId ? (
        <Step1Partner />
      ) : user.usrJbrnId === 0 && sendProjId ? (
        <>Details can't be edited again ! proceed to next step</>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="h6"
                className="fmw-onboard-partner-label"
                style={{ marginBottom: "0.5rem" }}
              >
                Particulars of the applicant
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Name of water project
              </Typography>
              <Input
                label=""
                type="text"
                value={newWspApplications?.accTradeName}
                name="waterProject"
                onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Name of contact person & designation
              </Typography>
              <Input
                label=""
                type="text"
                value={user.usrFullNames}
                name="usrFullNames"
                onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Email contact
              </Typography>
              <Input
                label=""
                type="text"
                value={user.usrEmail}
                name="projEmail"
                onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Telephone contact (mobile)
              </Typography>
              <Input
                label=""
                type="text"
                value={formatPhoneNumber(user.usrMobileNumber)}
                name="projTelMobile"
                onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Position in water project
              </Typography>
              <Input
                label=""
                type="text"
                value={newWspApplications?.accRole}
                name="accRole"
                // onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={6}>
              <Typography
                component="h6"
                className="fmw-onboard-partner-label"
                style={{ marginBottom: "1.5rem" }}
              >
                Postal address & alternate contact details
              </Typography>
              <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Post code
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={newWspApplications?.accZipCode}
                  name="projPostCode"
                  onChange={handleChange}
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Name of alternate contact person
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={usrAltFullNames}
                  name="usrAltFullNames"
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Mobile of alternate contact person
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={formatPhoneNumber(user.usrAltPhoneNo)}
                  name="usrAltPhoneNo"
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography
                component="h6"
                className="fmw-onboard-partner-label"
                style={{ marginBottom: "1.5rem" }}
              >
                Physical address
              </Typography>

              <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  County
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={newWspApplications?.accCounty}
                  name="projCounty"
                  onChange={handleChange}
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Sub-county
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={newWspApplications?.accSubCounty}
                  name="projSubCounty"
                  onChange={handleChange}
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "1.4rem" }}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Location
                </Typography>
                <Input
                  label=""
                  type="text"
                  value={newWspApplications?.accLocation}
                  name="projLocation"
                  onChange={handleChange}
                  size="lg"
                  className="fmw-input-joy"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Step1;
