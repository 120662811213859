import React, { useEffect, useState } from "react";
import { Textarea, Typography, colors } from "@mui/joy";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary"; 
import {
  DragIndicator,
  Circle,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import SingleItem from "../../components/single_item";
import SingleAttachment from "../../components/single_attachment";
import WaterSourceInfrastractureTable from "./WaterSourceInfrastractureTable";
import { useLocation, useParams } from "react-router-dom";
import OperationStaff from "./OperationStaff";
import DescWaterSources from "./DescWaterSources";
import TankCapacity from "./TankCapacity";
import Pipeline from "./Pipeline";
import Attachments from "./Attachments";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectScore, getPipeInformations, getStorageInformations, postScoreStep, setProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import { fetchStaff, fetchWaterSrc } from "../../../../features/onboard_partner/onboardPartnerSlice";
import TextareaComment from "../../components/TextareaComment";
import { toast } from "react-toastify";

const WaterSource = () => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var commentType =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi tristique senectus et netus et malesuada fames. Scelerisque eleifend donec pretium vulputate. Sagittis nisl rhoncus mattis rhoncus urna. Aliquam ut porttitor leo a diam sollicitudin tempor. Eget felis eget nunc lobortis mattis aliquam faucibus.";
  const [formData, setFormData] = useState({
    trust: '',
    comment1: ''
  })
  const { trust, comment1 } = formData
  const handleChangeInput = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };
  const category = location.pathname.split('/')[3]

  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );

  const { id } = useParams();

  const data = projectData?.find(
    (item) => item?.projId === Number(id)
  );

  const { scores } = useSelector((state) => state.expressionDemandData);
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const stepName = "PROJECT_DESCRIPTION";
  const dispatch = useDispatch();
  const {
    isLoadingWtrSrc,
    isSuccessDelete,
    isSuccessUpdateWtrSrc,
    step3WtrAnalysisPdf,
    step3MtrReadingPdf,
    step3PermitPdf,
    step3PumpPdf,
    wtrSrc,
  } = useSelector((state) => state.onboardPartner);

  const { isLoadingStaff, operationalStaff } = useSelector(
    (state) => state.onboardPartner
  );

  const matrixFields = [
    "projOtherExistingWaterSources",
    "projOtherWspSswp",
    "projExistingOperational",
    "projWraAbstrationPermit",
  ];

  const {
    projExistingOperational,
    projWaterSources,
    projReliabilityWaterSource,
    projOtherExistingWaterSources,
    projOtherExistingWaterSourcesDesc,
    projAverageVolumeDry,
    projAverageVolWet,
    projWaterQuality,
    projMasterMeterInstalledFunctional,
    projWraAbstrationPermit,
    projPermitFile,
    projOtherWspSswp,
    projOtherWspSswpDesc,
    projTypeOfSystem,
    projPictureOfPumpFile,
    projMeterReadingsFile,
    projWaterQualityFile,
    projId,
  } = data;

  const { storageInformation, pipeInformations, loading } = useSelector(
    (state) => state.expressionDemandData
  );

  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const fetchReduxData = async () => {
    const res = await dispatch(getPipeInformations(projId));
    const storage = await dispatch(getStorageInformations(projId));
    const staffInfo = await dispatch(fetchStaff({ projId }));
    const waterInfo = await dispatch(fetchWaterSrc({ projId }));
    console.log(res, storage, staffInfo, waterInfo);
  };

  useEffect(() => {
    console.log("COnsoled For the first TIme");
    fetchReduxData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchReduxData();
    }, 2000);

  }, []);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };
  let counter = 0;
  useEffect(() => { }, [
    operationalStaff,
    storageInformation,
    pipeInformations,
    isLoadingStaff,
    counter,
    isLoadingWtrSrc,
  ]);



  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const tableOneData = [
    createData(
      "Is the project existing and operational ?",
      projExistingOperational,
      "projExistingOperational",
      5
    ),

    createData(
      "Other existing sources within the water project area of operation",
      projOtherExistingWaterSources,
      "projOtherExistingWaterSources",
      5
    ),
    // projOtherExistingWaterSources === "Yes" &&
    //   createData(
    //     "Description of other existing sources within the water project area of operation",
    //     projOtherExistingWaterSourcesDesc,
    //     "projOtherExistingWaterSourcesDesc"
    //   ),
    createData(
      "Is there a WSP or another SSWP operating within the same area of the applicant ",
      projOtherWspSswp,
      "projOtherWspSswp",
      5
    ),
    // projOtherWspSswp === "Yes" &&
    //   createData(
    //     "Description of other WSP or another SSP operated by the this Organization",
    //     projOtherWspSswpDesc,
    //     "projOtherWspSswpDesc"
    //   ),
    // createData(
    //   <>
    //     Average volume abstracted per day from all sources m<sup>3</sup>/day dry
    //     season
    //   </>,
    //   projAverageVolumeDry,
    //   "projAverageVolumeDry"
    // ),
    // createData(
    //   <>
    //     Average volume abstracted per day from source m<sup>3</sup>/day wet
    //     season
    //   </>,
    //   projAverageVolWet,
    //   "projAverageVolWet"
    // ),
    // createData("Water quality at source", projWaterQuality, "projWaterQuality"),
    // createData(
    //   "Is a master meter installed and functional ?",
    //   projMasterMeterInstalledFunctional,
    //   "projMasterMeterInstalledFunctional"
    // ),
    // createData(
    //   "Is there a water regulatory authority (WRA) abstraction permit ?",
    //   projWraAbstrationPermit,
    //   "projWraAbstrationPermit",
    //   5
    // ),
    // createData("Type of system", projTypeOfSystem, "projTypeOfSystem"),
  ];

  const attachments = [
    createData(
      "PDF of water quality analysis of source water",
      projWaterQualityFile,
      "projWaterQualityFile"
    ),
    createData(
      "PDF of 12 months meter readings",
      projMeterReadingsFile,
      "projMeterReadingsFile"
    ),
    createData(
      "PDF of picture of pump- if pumped or hybrid",
      projPictureOfPumpFile,
      "projPictureOfPumpFile"
    ),
  ];

  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Water demand assessment
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <WaterSourceInfrastractureTable data={data} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Water demand assessment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                {
                  tableOneData?.map((row) => (
                    <SingleItem
                      row={row}
                      userComment={userComment}
                      setUserComment={setUserComment}
                      setMatrixInfo={setMatrixInfo}
                      matrixInfo={matrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={data?.projId}
                      setOnblur={setOnBlur}
                      onBlur={onBlur}
                      maxScores={row.maxScores}
                      status={category}
                      comment={comment}
                      setComment={setComment}

                      setUserMatrixInfo={setUserMatrixInfo}
                      userMatrixInfo={userMatrixInfo}

                    />
                  ))
                }

              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }




      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel3bh-content'
          id='panel3bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Description of water sources
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <DescWaterSources data={data} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel4bh-content'
          id='panel4bh-header'
        >

          <Typography
            className='accordion-title'
            sx={{ width: '75%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' style={{ marginRight: '1.25rem' }} />
            Total capacity of storage tanks within the project <span style={{ marginLeft: '10px' }}>(m<sup>3</sup>)</span>

          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <TankCapacity />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel5' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel5bh-content'
          id='panel5bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '75%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Extent of pipelines (diameter)
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <Pipeline />
        </AccordionDetails>
      </Accordion>


      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel2bh-content'
          id='panel2bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Operations staff information
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>

          <OperationStaff
            userComment={userComment}
            setUserComment={setUserComment}
            setMatrixInfo={setMatrixInfo}
            matrixInfo={matrixInfo}
            name="operationsScores"
            stepName={stepName}
            id={data?.projId}
            status={category}
            comment={comment}
            setComment={setComment}
            setUserMatrixInfo={setUserMatrixInfo}
            userMatrixInfo={userMatrixInfo}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel6' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel6bh-content'
          id='panel6bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '75%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Attachments
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <Attachments attachments={attachments} />
        </AccordionDetails>
      </Accordion>


      {(category === "COMMITTED" ||
        category === "DEAL_SOURCING" ||
        category == 'Assistance' ||
        category == 'poor' ||
        category === "Viable" ||
        category === "Partner") &&
        <TextareaComment
          onChange={(e) => setComment(e.target.value)}
          comment={comment}
          submitStep={submitStep}
        />
      }

    </Box>
  );
};

export default WaterSource;
