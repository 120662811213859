import { Grid, Paper, Box, Divider, Stack } from "@mui/material";
import { Button, Input, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import "./finacing.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchWspApplications, reset } from "../../features/wsp/wspSlice";
import {
    fetchCalculate,
    setLoanSumValues,
} from "../../features/onboard_partner/onboardPartnerSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { cashConverter, numberWithCommas } from "../../utils";

// const rows = [
//     {
//         id: 1, item: 'Loan', sum: (wspApplications[0].proj3rdpartySum && wspApplications[0].projSswpSum) ? ((wspApplications[0].projLoanAmount) - (wspApplications[0].projSswpSum + wspApplications[0].proj3rdpartySum)) :
//             wspApplications[0].projSswpSum ? wspApplications[0].projLoanAmount - wspApplications[0].projSswpSum :
//                 0,
//         percentage: wspApplications[0].projLoanSum == 0 ? '' : wspApplications[0].projLoanCalculated
//     },

//     {
//         id: 2, item: '3rd Party', sum: wspApplications[0].proj3rdpartySum == 0 ? '' : wspApplications[0].proj3rdpartySum,
//         percentage: wspApplications[0].proj3rdpartySum == 0 ? '' : wspApplications[0].proj3rdpartyCalculated
//     },

//     {
//         id: 3, item: 'Your contribution', sum: wspApplications[0].projSswpSum == 0 ? '' : wspApplications[0].projSswpSum,
//         percentage: wspApplications[0].projSswpSum == 0 ? '' : wspApplications[0].projSswpCalculated
//     },

//     { id: 4, item: 'Total', sum: wspApplications[0].projLoanAmount, percentage: wspApplications[0].projTotalCalculated },

// ];
function FinancingProject({ id }) {
    const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector(
        (state) => state.wsp
    );
    const { user } = useSelector((state) => state.auth);
    const { loanSumValues, step6 } = useSelector((state) => state.onboardPartner);
    const dispatch = useDispatch();
    const [idSet, setIdSet] = useState(0)

    const [formData, setFormData] = useState(
        wspApplications[0] || {
            loan: "",
            proj3rdpartySum: "",
            projSswpSum: "",
            projLoanAmount: "",
            projLoanCalculated: "",
            proj3rdpartyCalculated: "",
            projSswpCalculated: "",
            projTotalCalculated: "",
        }
    );

    const loanAmounts = step6?.projLoanAmount ? step6?.projLoanAmount : wspApplications[0].projLoanAmount
    const loanee =
        wspApplications[0].proj3rdpartySum && wspApplications[0].projSswpSum ? loanAmounts -
            (wspApplications[0].projSswpSum + wspApplications[0].proj3rdpartySum)
            : wspApplications[0].projSswpSum ? loanAmounts - wspApplications[0].projSswpSum
                : 0;



    const [isUpdating, setIsUpdating] = useState(false)

    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setIsUpdating(true)
    };



    useEffect(() => {
        if (user.usrJbrnId === 1) {
            dispatch((fetchWspApplications()))
        } else if (user.usrJbrnId === 0) {
            dispatch((fetchWspApplications({
                projId: sendProjId
            })))
        }

        return () => {
            dispatch(reset())
        }

    }, [dispatch, sendProjId])


    useEffect(() => {
        if (idSet == 2) {
            handleSubmitId2()
            return;
        }


    }, [loanee, loanAmounts])

    useEffect(() => {
        if (idSet == 3) {
            handleSubmitId3()
            return;
        }
    }, [loanee, loanAmounts])

    const [blur2, setBlur2] = useState(false)
    const [blur3, setBlur3] = useState(false)
    const handleSubmitId2 = async () => {
        setBlur2(true)
        setBlur3(false)
        setIdSet(2)

        const res = await dispatch(fetchCalculate({
            projId: wspApplications[0].projId,
            id: 2,
            sum: formData?.proj3rdpartySum,
            loan: loanee
        }))

        if (user.usrJbrnId === 1) {
            const res = await dispatch((fetchWspApplications()))
            if (res?.payload?.length) {
                setFormData(res?.payload[0])
            }

        } else {
            const res = await dispatch((fetchWspApplications({
                projId: sendProjId
            })))
            if (res?.payload?.length) {
                setFormData(res?.payload[0])
            }
        }

    }

    const handleSubmitId3 = async () => {
        setIdSet(3)
        setBlur3(true)
        setBlur2(false)
        const toastPercentage =   0.2 * loanAmounts 
        console.log("toastPercentage", toastPercentage)
        if (formData?.projSswpSum >= 0.2 * loanAmounts) {
            const res = await dispatch(
                fetchCalculate({
                    projId: wspApplications[0].projId,
                    id: 3,
                    sum: formData?.projSswpSum,
                    loan: loanee,
                })
            );
        } else if (blur3) {
            toast.error(`Value must be greater than ${toastPercentage} which is 20% of total loan applied`);
        }else{}

        if (user.usrJbrnId === 1) {
            const res = await dispatch(fetchWspApplications());
            if (res?.payload?.length) {
                setFormData(res?.payload[0])
            }
        } else {
            const res = await dispatch(
                fetchWspApplications({
                    projId: sendProjId,
                })
            );
            if (res?.payload?.length) {
                setFormData(res?.payload[0])
            }
        }
    };
    return (
        <Paper
            sx={{ p: 0, display: "flex", flexDirection: "column" }}
            variant="outlined"
        >
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            className="typo-paragraph"
                            sx={{ fontWeight: 500, p: 1.5, height: "100%" }}
                        >
                            Item
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            className="typo-paragraph"
                            sx={{ fontWeight: 500, p: 1.5, height: "100%" }}
                        >
                            Sum(KES)
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            className="typo-paragraph"
                            sx={{ fontWeight: 500, p: 1.5, height: "100%" }}
                        >
                            %(Calculated)
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={4} md={2}>
                        <Typography
                            component="p"
                            className="typo-paragraph"
                            sx={{ p: 1.5, height: "100%" }}
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value="Loan"
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={numberWithCommas(loanee)}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={formData?.projLoanCalculated ? `${formData.projLoanCalculated}%` : ''}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={4} md={2}>
                        <Typography
                            component="p"
                            className="typo-paragraph"
                            sx={{ p: 1.5, height: "100%" }}
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value="3rd Party"
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                label=""
                                type="text"
                                value={formData?.proj3rdpartySum}
                                name="proj3rdpartySum"
                                onChange={handleChange}
                                onBlur={handleSubmitId2}
                                size="lg"
                                className="fmw-input-joy-financing"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={formData?.proj3rdpartyCalculated ? `${formData.proj3rdpartyCalculated}%` : ''}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={4} md={2}>
                        <Typography
                            component="p"
                            className="typo-paragraph"
                            sx={{ p: 1.5, height: "100%" }}
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value="Your contribution"
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                label=""
                                type="number"
                                value={formData.projSswpSum}
                                name="projSswpSum"
                                onChange={handleChange}
                                onBlur={handleSubmitId3}
                                size="lg"
                                className="fmw-input-joy-financing"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={formData?.projSswpCalculated ? `${formData.projSswpCalculated}%` : ''}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={4} md={2}>
                        <Typography
                            component="p"
                            className="typo-paragraph"
                            sx={{ p: 1.5, height: "100%" }}
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value="Total"
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={numberWithCommas(step6?.projLoanAmount || wspApplications[0].projLoanAmount)}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={2} style={{}}>
                        <Typography
                            component="p"
                            sx={{ p: 1.5 }}
                            className="typo-paragraph"
                        >
                            <Input
                                sx={{ border: "none !important" }}
                                label=""
                                type="text"
                                value={formData?.projTotalCalculated ? `${formData.projTotalCalculated}%` : ''}
                                size="lg"
                                className="fmw-no-border"
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Box>
        </Paper>
    );
}

export default FinancingProject;

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import { DataGrid } from '@mui/x-data-grid';
// import { Paper, TextField } from '@mui/material';
// import { Typography } from '@mui/joy';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchCalculate, onboardPartner, setLoanSumValues } from '../../features/onboard_partner/onboardPartnerSlice';
// import { fetchWspApplications, reset } from '../../features/wsp/wspSlice';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { cashConverter } from '../../utils';

// const FinancingProject = ({ id }) => {
//     const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector((state) => state.wsp)
//     const {loanSumValues} =  useSelector((state) => state.onboardPartner)
//     const { user } = useSelector((state) => state.auth)
//     const dispatch = useDispatch()

//     const columns = [
//         { field: 'id', headerName: 'ID', width: 90, hide: true },
//         {
//             field: 'item',
//             headerName: 'Item',
//             width: 250,
//             sortable: false,
//         },
//         {
//             field: 'sum',
//             type: 'number',
//             headerName: 'Sum (KES)',
//             width: 200,
//             editable: true,
//             sortable: false,
//             renderCell: (params) => {
//                 return (
//                     <TextField
//                         id="standard-basic"
//                         type="number"
//                         variant="standard"
//                         value={params.row.sum}
//                     />
//                 )
//             },
//             headerAlign: 'left',
//         },
//         {
//             type: 'number',
//             field: 'percentage',
//             headerName: '% (Calculated)',
//             width: 150,
//             sortable: false,
//             renderCell: (params) => {
//                 return (
//                     <TextField
//                         id="standard-basic"
//                         type="number"
//                         variant="standard"
//                         value={params.row.percentage}
//                     />
//                 )
//             },
//             headerAlign: 'left',
//         },
//     ];

//     const rows = [
//         {
//             id: 1, item: 'Loan', sum: (wspApplications[0].proj3rdpartySum && wspApplications[0].projSswpSum) ? ((wspApplications[0].projLoanAmount) - (wspApplications[0].projSswpSum + wspApplications[0].proj3rdpartySum)) :
//                 wspApplications[0].projSswpSum ? wspApplications[0].projLoanAmount - wspApplications[0].projSswpSum :
//                     0,
//             percentage: wspApplications[0].projLoanSum == 0 ? '' : wspApplications[0].projLoanCalculated
//         },

//         {
//             id: 2, item: '3rd Party', sum: wspApplications[0].proj3rdpartySum == 0 ? '' : wspApplications[0].proj3rdpartySum,
//             percentage: wspApplications[0].proj3rdpartySum == 0 ? '' : wspApplications[0].proj3rdpartyCalculated
//         },

//         {
//             id: 3, item: 'Your contribution', sum: wspApplications[0].projSswpSum == 0 ? '' : wspApplications[0].projSswpSum,
//             percentage: wspApplications[0].projSswpSum == 0 ? '' : wspApplications[0].projSswpCalculated
//         },

//         { id: 4, item: 'Total', sum: wspApplications[0].projLoanAmount, percentage: wspApplications[0].projTotalCalculated },

//     ];

//     const loanee = rows?.find((row) => row.item === 'Loan')?.sum;
//     const [loanSum, setLoanSum] = useState()

//     useEffect(() => {
//         dispatch(setLoanSumValues(loanee))
//     }, [loanee])

//     const isCellEditable = (params) => {
//         if (params.field === 'sum' && params.row.item !== 'Loan' && params.row.item !== 'Total') {
//             return true;
//         }
//         return false;
//     };

//     console.log("loanSumValues============",loanSumValues)

//     const processRowUpdate = (newRow) => {
//         const updatedRow = { ...newRow };

//         const id = updatedRow.id
//         const sum = updatedRow.sum ? updatedRow.sum : ["0"];

//         const formdata = new FormData();
//         formdata.append("projId", wspApplications[0].projId);
//         formdata.append("projLoanSum", sum);

//             if (id == 1) {
//                 dispatch((fetchCalculate({
//                     projId: wspApplications[0].projId,
//                     id: updatedRow.id,
//                     sum: sum,
//                 })))

//                 if (user.usrJbrnId === 1) {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications()))
//                     }, 800)
//                 } else {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications({
//                             projId: sendProjId
//                         })))
//                     }, 800)
//                 }
//             }

//             if (id == 3) {
//                 if (sum >= 0.2 * (wspApplications[0].projLoanAmount)) {
//                        dispatch((fetchCalculate({
//                         projId: wspApplications[0].projId,
//                         id: updatedRow.id,
//                         sum: sum,
//                         loan: loanSumValues
//                     })))

//                 }
//                 else {
//                     console.log("wrong")
//                     toast.error("Value must be greater than 20% of total loan applied")
//                 }

//                 if (user.usrJbrnId === 1) {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications()))
//                     }, 800)
//                 } else {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications({
//                             projId: sendProjId
//                         })))
//                     }, 800)
//                 }
//             }

//             if (id == 2) {
//                 dispatch((fetchCalculate({
//                     projId: wspApplications[0].projId,
//                     id: updatedRow.id,
//                     sum: sum,
//                     loan: loanSum
//                 })))
//                 if (user.usrJbrnId === 1) {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications()))
//                     }, 800)
//                 } else {
//                     setTimeout(() => {
//                         dispatch((fetchWspApplications({
//                             projId: sendProjId
//                         })))
//                     }, 800)
//                 }
//             }

//         return updatedRow;
//     };
//     const [snackbar, setSnackbar] = React.useState(null);
//     const handleProcessRowUpdateError = React.useCallback((error) => {
//         toast.error('Value must be greater than 20% of loan applied 90', {
//             theme: "colored",
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//         });
//         setSnackbar({ children: error.message, severity: 'error' });
//     }, []);

//     useEffect(() => {
//         if (user.usrJbrnId === 1) {
//             dispatch((fetchWspApplications()))
//         } else if (user.usrJbrnId === 0) {
//             dispatch((fetchWspApplications({
//                 projId: sendProjId
//             })))
//         }

//         return () => {
//             dispatch(reset())
//         }

//     }, [dispatch, sendProjId])

//     return (
//         <>
//             <Typography sx={{ my: 3, mt: 0 }} component="body2" className="fmw-divider" />
//             <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
//                 Fill the Table Below <span className="star">*</span>
//             </Typography>

//             <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//                 <DataGrid
//                     autoHeight
//                     rows={rows}
//                     isCellEditable={isCellEditable}
//                     getRowId={(row) => row.id}
//                     columns={columns}
//                     processRowUpdate={processRowUpdate}
//                     onProcessRowUpdateError={handleProcessRowUpdateError}
//                     pageSize={5}
//                     rowsPerPageOptions={[5]}
//                     disableSelectionOnClick
//                     experimentalFeatures={{ newEditingApi: true }}
//                     loading={isLoading}
//                     disableColumnMenu={true}
//                     disableColumnFilter={true}
//                     disableColumnSelector={true}
//                 />
//             </Paper>
//         </>
//     );
// }

// export default FinancingProject
