import { Grid, Divider } from '@mui/material';
import { Typography } from '@mui/joy';
import DealRoomCard from '../../components/deal_room_list/DealRoomCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoanTermConditionsPartnerBids } from '../../features/deal_sourcing/dealSourcingSlice';
import DealRoomSkeleton from '../../components/deal_room_list/DealRoomSkeleton';
import {
	getExpressionDemandData,
	setFilterParams,
} from '../../features/expression_demand/expressionDemandSlice';
import PageTitle from '../../components/page_title/PageTitle';
import DealRoomSearch from '../../components/deal_room_list/DealRoomSearch';
import DealList from '../../components/deal_room_list/DealList';
import { useState } from 'react';

export default function Bids() {
	const dispatch = useDispatch();
	const { partnerBids, isProcessingBidsPartner } =
		useSelector((state) => state.dealSourcing) ?? [];

	const { user } = useSelector((state) => state.auth);

	const [search, setsearch] = useState('');

	function getSearchValue(val) {
		setsearch(val);
	}

	useEffect(() => {
		setTimeout(() => {
			if (user.usrJbrnId == 0) {
				dispatch(
					fetchLoanTermConditionsPartnerBids({
						ltcAccId: user.usrAccId,
					}),
				);
			} else {
				dispatch(
					fetchLoanTermConditionsPartnerBids({
						ltcAccId: null,
					}),
				);
			}
			dispatch(setFilterParams('deal_sourcing'));
			const formData = { projStatus: 'DEAL_SOURCING' };

			dispatch(getExpressionDemandData(formData));
		}, 1);
	}, []);

	const DealRoomBids = <DealList search={search} data={partnerBids} />;

	useEffect(() => {}, [partnerBids]);

	return (
		<>
			<PageTitle
				currentPage='Deal Room'
				subSubTitle={'Bids'}
				subTitle={'Bids'}
				urlSubTitle={'/bids'}
			/>
			<DealRoomSearch getSearchValue={getSearchValue} />

			{/* {isProcessingBidsPartner ? (
        <>
          <DealRoomSkeleton />
          <DealRoomSkeleton />
        </>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              {user.usrJbrnId == 0 ? (
                <Typography component="h3" className="fmw-page-title">
                  Bids
                </Typography>
              ) : (
                <Typography component="h3" className="fmw-page-title">
                  Offers
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Divider />
            </Grid>
           
          </Grid>
        </>
      )} */}

			{DealRoomBids}
		</>
	);
}
