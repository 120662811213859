import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import pipelinesService from './pipelinesService';
 
 
const initialState={
    isError:false,
    isProcessing: true,
    isLoading:false,
    isSuccess:false,
    message:'',
}

export const saveDealSourcing = createAsyncThunk('pipelines/save-deal-sourcing',async(formData,thunkAPI)=>{
    try{
        return await pipelinesService.saveDealSourcing(formData)
    }catch(error){
        const message = handleFailure(error)  
        return thunkAPI.rejectWithValue(message)
        }
});


 
export const pipelinesSlice = createSlice({
     name:'pipelines',
     initialState,
     reducers:{
         reset:(state)=>{
            state.isLoading=false
            state.isProcessing=false
            state.isError=false
            state.isSuccess=false
            state.message = ''
         },
     },
     extraReducers:(builder)=>{
         builder

        .addCase(saveDealSourcing.pending, (state)=>{
            state.isProcessing=true
        })
        .addCase(saveDealSourcing.fulfilled, (state,action) =>{
            state.isProcessing=false
            state.isSuccess=true
            state.message="Deal sourcing info submitted successfully."
        })
        .addCase(saveDealSourcing.rejected,(state,action)=>{
            state.isProcessing=false
            state.isError=true
            state.message=action.payload
        })
     }
 })

export const {reset } = pipelinesSlice.actions
export default pipelinesSlice.reducer