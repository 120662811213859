import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import SingleItem from '../../components/single_item';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cashConverter, formatPath } from '../../../../utils';
import NoTableDataComponent from '../../../expression_demand/tables/NoTableDataComponent';
import FileButton from '../../../../components/button/FileButton';

export default function Institutes() {
    const { budgetInfo, isLoadingBudget } = useSelector(
        (state) => state.onboardPartner
    );
    const { isLoadingInstitution, institutionDetailsTable, isSuccessDelete, isSuccessUpdateInstitution, step4InstitutionPdf } = useSelector((state) => state.onboardPartner)
    const location = useLocation();
    const category = location.pathname.split('/')[3]
    console.log(institutionDetailsTable)

    return (
        <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
            {institutionDetailsTable?.length > 0 ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow className='table'>

                        <TableCell align="left">Type of Institution</TableCell>
                        <TableCell align="left">Number of Institutions</TableCell>
                        <TableCell>Sample Contract</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='basic'>
                    {institutionDetailsTable?.map((row) => (
                        <TableRow
                            key={row?.instName}
                        >
                            <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                                {row?.instName}
                            </TableCell>
                            <TableCell align="left">{row?.instNo}</TableCell>
                            <TableCell align="left">
                                <FileButton title={formatPath(row?.instAttachment)} titlePreview={row.instAttachment} viable="true" />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table> : <Box sx={{ display: 'flex', width: "60vw", justifyContent: "center" }}><NoTableDataComponent /></Box>}

        </TableContainer>
    );
}