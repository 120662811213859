import React, { useEffect } from 'react'
import {Grid, Paper, Divider, Skeleton} from '@mui/material';
import './wspHome.css'
import { Typography, Button,  } from '@mui/joy';
import { ExpandMore } from '@mui/icons-material';
import WspApplication from '../../components/wsp_application/WspApplication';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import { useNavigate } from 'react-router-dom';
import WspAppSkeleton from '../../components/wsp_application/WspAppSkeleton';
import WspNewHome from '../wsp_home/WspHome';


const WspHome = () => {

  const navigate = useNavigate()
  const {user} = useSelector(state => state.auth)
  console.log("usr",user?.usrJbrnId)
  const {wspApplications, isLoading, isLoadingWsp,isSuccess, isError, message} = useSelector(state => state.wsp)
  const dispatch = useDispatch()
 
  const handleOnboarding = () => {
    navigate(`/onboard-wsp`);
  }

  useEffect(() => {
    if (!user) navigate("/");
    setTimeout(() => {
      dispatch((fetchWspApplications()))
  }, 800)

  }, [dispatch, user, navigate])

  return (
    <>
    <WspNewHome />
      {/* <Grid container spacing={4}> 
        
        <Grid item xs={9} sx={{mb: 2}}>
          <Typography component="h3" className="fmw-page-title">
             Expression of Demand
          </Typography>
        </Grid>
        <Grid item xs={3}>
         

        </Grid>

        { !isLoadingWsp ?  (
          <>
            {wspApplications?.length && (
              <>
                {wspApplications.map(app => (
                  <WspApplication app={app}/>
                )) }
              </>
            )
            }
          </>
          ) : (
          <>
           <WspAppSkeleton/>
           <WspAppSkeleton/>
          </>
        )}
        
        

        </Grid> */}
    </>
  )
}

export default WspHome;