import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import DownloadIcon from "@mui/icons-material/Download";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import "react-phone-input-2/lib/style.css";
import PdfAnnotations from "./PdfAnnotations";
import { useDispatch, useSelector } from "react-redux";
import { setOpenPDfDialog } from "../../../../features/expression_demand/expressionDemandSlice";
import { Box, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { formatImgPath } from "../../../../utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PdfModal = () => {
  const { openPDfDialog } = useSelector((state) => state.expressionDemandData);
  const { pdfData } = useSelector((state) => state.expressionDemandData);
  const [scale, setScale] = useState(1.4);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setOpenPDfDialog(false));
  };

  function handleZoomIn() {
    setScale(scale + 0.2);
  }

  function handleZoomOut() {
    setScale(scale - 0.2);
  }

  React.useEffect(() => {
    console.log(
      pdfData,
      "========================PDF DATA===================="
    );
  }, [pdfData]);

  function downloadFile(file) {
    console.log(formatImgPath(file));
    fetch(
      formatImgPath(file?.projaFile, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS", // Add additional methods if needed
          "Access-Control-Allow-Headers": "Content-Type, Authorization", // Add additional headers if needed
        },
      })
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("File download failed.");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = file?.projaDesc;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  function printFile(pdfData) {
    const { projaFile } = pdfData;
    const printWindow = window.open(formatImgPath(projaFile ?? null), "_blank");
    printWindow.addEventListener("load", () => {
      printWindow.print();
    });
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPDfDialog}
        fullWidth={true}
        maxWidth="xl"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            bgcolor: "#3A3D41",
            display: "flex",
            gap: 2,
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box>
            <IconButton onClick={handleZoomIn}>
              <Typography paddingRight="5px" color="white">
                Zoom In
              </Typography>
              <ZoomInIcon fontSize="large" color="info" />
            </IconButton>
            <IconButton onClick={handleZoomOut}>
              <Typography paddingRight="5px" color="white">
                Zoom Out
              </Typography>
              <ZoomOutIcon fontSize="large" color="info" />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={() => downloadFile(pdfData)}>
              <Typography paddingRight="5px" color="white">
                Download
              </Typography>
              <DownloadIcon fontSize="large" color="info" />
            </IconButton>
            <IconButton onClick={() => printFile(pdfData)}>
              <Typography paddingRight="5px" color="white">
                Print
              </Typography>
              <LocalPrintshopIcon fontSize="large" color="info" />
            </IconButton>
          </Box>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <PdfAnnotations scale={scale} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default PdfModal;
