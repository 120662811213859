import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Checkbox, Link, Typography, Button } from "@mui/joy";
import "./signin.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { login, reset } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import VerifyLogin from "../verify_login/VerifyLogin";
import VerifyButton from "../../components/button/VerifyButton";
import axios from "axios";
import { toSentenceCase } from "../../utils";
import './signin.css'

const url = process.env.REACT_APP_API_BASE_URL;

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    // border: "1px solid var(--color-primary-light)",
    overflow: "hidden",
    borderRadius: 0,
    color: "#646464",
    fontSize: "15px !important",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      // borderColor: theme.palette.primary.main,
      borderLeft: "3px solid var(--color-info)",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    maxWidth: "md",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", left: 22, top: 15, color: "#2C2C2C" }}
      >
        <ArrowBackIcon />
      </IconButton>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 10,
            color: (theme) => theme.palette.grey[500],
            display: "none",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Signin = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(false);
  const [hasForgot, sethasForgot] = useState(false);
  const [forgotValue, setforgotValue] = useState("");
  const [codeSentMessage, setcodeSentMessage] = useState("");
  const [showOtpForm, setshowOtpForm] = useState(false);
  const [OtpClose, setOtpClose] = useState(false);
  const [usrOtp, setusrOtp] = useState("");
  const [counter, setCounter] = useState(60);
  const [loadingResend, setLoadingResend] = useState(false);
  const [otpOpen, setotpOpen] = useState(false);
  const [showChangeForm, setshowChangeForm] = useState(false);
  const [userId, setuserId] = useState(null);
  const [usrEncryptedPassword2, setusrEncryptedPassword2] = useState("");
  const [passError, setpassError] = useState("");

  const [accountError, setaccountError] = useState({
		emailError: false,
		passError: false,
		emailMsg: '',
		passMsg: '',
		genMsg: '',
	});

  var format = /[@]/;

  const { user, isLoginProcessing, isLoginError, isLoginSuccess, message } =
    useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    usrUsername: "",
    usrEncryptedPassword: "",
  });

  const { usrUsername, usrEncryptedPassword } = formData;

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function handleForgotChange() {
    sethasForgot(!hasForgot);
  }

  const [openVerifySignin, setOpenVerifySignin] = React.useState(false);

  const handleClickOpenVerifySignin = () => {
    setOpenVerifySignin(true);
    setTimeout(() => {
      setTimer(true);
    }, 60000);
  };
  const handleCloseVerifySignin = () => {
    setOpenVerifySignin(false);
  };

  const signIn = (e) => {
    e.preventDefault();
    if (!usrUsername || !usrEncryptedPassword) {
      toast.warning("Please fill in email & password fields");
    } else {
      dispatch(
        login({
          usrUsername: usrUsername,
          usrEncryptedPassword: usrEncryptedPassword,
        })
      );
    }
  };

  function hideEmail(target) {
    var email = target;
    var hiddenEmail = "";
    for (let i = 0; i < email.length; i++) {
      if (i > 2 && i < email.indexOf("@")) {
        hiddenEmail += "*";
      } else {
        hiddenEmail += email[i];
      }
    }
    return hiddenEmail;
  }

  const sendForgotOtp = (e) => {
    e.preventDefault();
    if (!forgotValue) {
      toast.warning("Please fill in email or phone number");
    } else {
      // dispatch();
      // setshowOtpForm(true);
      // setotpOpen(true);

      fetch(`${url}/usr/reset_password.action?phone=${forgotValue}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            // toast.success(
            // 	`A four digits code has been sent to ${
            // 		format.test(forgotValue)
            // 			? hideEmail(forgotValue)
            // 			: forgotValue.replace(/.(?=.{4})/g, 'x')
            // 	}. Verify your account to continue.`,
            // );
            toast.success("A four digit code has been sent.");
            setshowOtpForm(true);
            setotpOpen(true);
            setCounter(60);
          } else {
            setcodeSentMessage(
              data.messages.message +
                ". Please check your email address and try again."
            );
            setshowOtpForm(false);
            setotpOpen(false);
          }
        });
    }
  };

  function verifyChangePwdOtp(e) {
		e.preventDefault();
		setLoadingResend(true);
		fetch(`${url}/usr/authenticate_otp.action`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				usrUsername: forgotValue,
				usrEncryptedPassword: usrOtp,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				setLoadingResend(false);
				if (data.success) {
					setshowChangeForm(true);
					setusrOtp('');
					setuserId(data?.jsonData?.usrId);
					toast.success('Verification successfull');
				} else {
					setshowChangeForm(false);
					toast.error('The code you entered is not valid');
				}
			});
		// e.preventDefault();
		// console.log(usrOtp);
		// setshowChangeForm(true);
	}

	function handleOtpClose() {
		setotpOpen(false);
	}

	const resend = () => {
		setLoadingResend(true);
		fetch(`${url}/usr/reset_password.action?phone=${forgotValue}`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
			},
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.success) {
					setLoadingResend(false);
					toast.success(`We have resent the code. Check your device again.`);
					setCounter(60);
				} else {
					setLoadingResend(false);
					toast.error('Error! Could not resend code. Try agin later.');
				}
			});
	};

	function handleChangeNewPassword(e) {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		// let numbers = /[0-9]/g;
		let numbers = /\d/g;

		e.preventDefault();
		if (!usrEncryptedPassword2) {
			setpassError('Please confirm your new password');
			return false;
		} else if (usrEncryptedPassword?.length < 6) {
			return false;
		} else if (usrEncryptedPassword !== usrEncryptedPassword2) {
			return false;
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			return false;
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			return false;
		} else if (!usrEncryptedPassword?.test(numbers)) {
			return false;
		} else {
			setpassError('');
			setLoadingResend(true);
			fetch(`${url}/usr/updateUser.action`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					usrId: userId,
					usrEncryptedPassword: usrEncryptedPassword,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					toast.success(`New password has been set. Log in to continue.`);
					usrEncryptedPassword = '';
					setusrEncryptedPassword2('');
					setLoadingResend(false);
					setshowChangeForm(false);
					setshowOtpForm(false);
					sethasForgot(false);
					setotpOpen(false);
				});
		}
	}

	useEffect(() => {
		if (isLoginError) {
			toast.error(toSentenceCase(message));
		}
		setTimeout(() => {
			setcodeSentMessage('');
		}, 5000);

		if (isLoginSuccess) {
			handleClickOpenVerifySignin();
			toast.success({ toastId: 'verify' });
		}

		if (codeSentMessage) {
			toast.error(toSentenceCase(codeSentMessage));
		}

		dispatch(reset());
	}, [user, isLoginSuccess, isLoginError, message, dispatch, codeSentMessage]);

	async function handlePassResetValidation() {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		let numbers = /[0-9]/g;

		if (!usrEncryptedPassword) {
			setpassError('');
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			await setpassError(
				'Password must contain atleast a lowercase character!',
			);
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			await setpassError(
				'Password must contain atleast an uppercasecharacter!',
			);
		} else if (!usrEncryptedPassword?.match(numbers)) {
			await setpassError('Password must contain atleast a number!');
		} else if (usrEncryptedPassword?.length < 6) {
			await setpassError('Passwords must be atleast 6 characters long!');
		} else if (
			usrEncryptedPassword2 &&
			usrEncryptedPassword !== usrEncryptedPassword2
		) {
			await setpassError('Password does not match');
		} else {
			setpassError('');
		}
	}

  useEffect(() => {}, [hasForgot]);

  // useEffect(() => {
  // 	if (showOtpForm) {
  // 		handleClose();
  // 	}
  // }, [showOtpForm]);

  useEffect(() => {
    if (counter > 0) {
      const interval = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [counter]);

  useEffect(() => {
    handlePassResetValidation();
  }, [usrEncryptedPassword, usrEncryptedPassword2, passError]);

  useEffect(() => {}, [userId]);

  return (
    <>
      {
        // <form onSubmit={(e) => handleChangeNewPassword(e)}>
        //   {passError ? <span className="text-red-600">{passError}</span> : null}
        //   <RedditTextField
        //     name="usrEncryptedPassword"
        //     value={usrEncryptedPassword}
        //     onChange={handleChange}
        //     label="New password"
        //     placeholder=""
        //     type="password"
        //     id="reddit-input-new"
        //     variant="filled"
        //     style={{ marginTop: 11 }}
        //     fullWidth
        //     className="reddit-input"
        //     autoFocus
        //   />

        //   <RedditTextField
        //     name="usrEncryptedPassword2"
        //     value={usrEncryptedPassword2}
        //     onChange={(e) => setusrEncryptedPassword2(e.target.value)}
        //     label="Confirm password"
        //     placeholder=""
        //     type="password"
        //     id="reddit-input-conf"
        //     variant="filled"
        //     style={{ marginTop: 11 }}
        //     fullWidth
        //     className="reddit-input"
        //   />

        //   <Grid container spacing={3} sx={{ mt: 0.75 }}>
        //     <Grid item xs={12}>
        //       {!loadingResend ? (
        //         <Button className="sign-btn" type="submit">
        //           Change Password
        //         </Button>
        //       ) : (
        //         <Button
        //           className="sign-btn"
        //           disabled
        //           variant="contained"
        //           sx={{ textTransform: "none" }}
        //         >
        //           <CircularProgress size={17} color="inherit" />
        //           &nbsp;Loading...
        //         </Button>
        //       )}
        //     </Grid>
        //   </Grid>
        // </form>

        <form onSubmit={signIn}>
							{/* {accountError?.genMsg ||
							accountError?.emailMsg ||
							accountError?.passMsg ? (
								<p style={{ color: 'red' }}>{accountError?.genMsg}</p>
							) : null} */}
							<RedditTextField
								name='usrUsername'
								value={usrUsername}
								onChange={handleChange}
								label='Email address'
								placeholder='John@doe.com'
								type='email'
								id='reddit-input-log1'
								variant='filled'
								style={{ marginTop: 11}}
								fullWidth
                // className="reddit-input-error"
								className={
									!accountError?.emailError
										? 'reddit-input-error'
										: 'reddit-input'
								}
								autoFocus
							/>

							<RedditTextField
								name='usrEncryptedPassword'
								value={usrEncryptedPassword}
								onChange={handleChange}
								label='Password'
								placeholder=''
								type='password'
								id='reddit-input-log2'
								variant='filled'
								style={{ marginTop: 11 }}
								fullWidth
								// className={
								// 	accountError?.passError
								// 		? 'reddit-input-error'
								// 		: 'reddit-input'
								// }
 
							/>

							<Grid container spacing={3} sx={{ mt: 1 }}>
								<Grid item xs={7}>
									<Checkbox
										color='primary'
										label='Remember me'
										size='md'
										variant='soft'
										className='fmw-checkbox-label'
									/>
								</Grid>

								<Grid item xs={5}>
									<Link
										onClick={handleForgotChange}
										color='primary'
										underline='none'
										className='fmw-link'
										sx={{ fontSize: '15px', textAlign: 'right' }}
									>
										Forgot password?
									</Link>
								</Grid>
							</Grid>

							<Grid container spacing={3} sx={{ mt: 0.75 }}>
								<Grid item xs={12}>
									{!isLoginProcessing ? (
										<Button className='sign-btn' type='submit'>
											Log in
										</Button>
									) : (
										<Button
											className='sign-btn'
											disabled
											variant='contained'
											sx={{ textTransform: 'none' }}
										>
											<CircularProgress size={17} color='inherit' />
											&nbsp;Loading...
										</Button>
									)}
								</Grid>
							</Grid>
						</form>
      }
    </>
  );
};

export default Signin;
