import React, { useEffect, useState } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import CampaignIcon from "@mui/icons-material/Campaign";
import {
  ExpandLess,
  ExpandMore,
  Circle,
  Home,
  Message,
  AccountCircle,
  Balance,
  AccountTree,
  Campaign,
  Work,
} from "@mui/icons-material";
import { Box, Collapse, Divider, List, Typography } from "@mui/material";

import { Link, NavLink } from "react-router-dom";
import BuildIcon from "@mui/icons-material/Build";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssistanceActive,
  setCompleteActive,
  setDraftActive,
  setInCompleteActive,
  setPoorActive,
  setViableActive,
  setWashActive,
} from "../../features/expression_demand/expressionDemandSlice";

const ExpressionListItem = () => {
  const [nlOpen, setnlOpen] = useState(true);
  const toggleNestedList = () => {
    setnlOpen(!nlOpen);
    setnlOpen1(false);
    setnlOpen2(false);
    setnlOpen3(false);
    setnlOpen4(false);
  };
  const [nlOpen1, setnlOpen1] = useState(true);
  const toggleNestedList1 = () => {
    setnlOpen1(!nlOpen1);
    setnlOpen(false);
    setnlOpen2(false);
    setnlOpen3(false);
    setnlOpen4(false);
  };
  const [nlOpen2, setnlOpen2] = useState(false);
  const toggleNestedList2 = () => {
    setnlOpen2(!nlOpen2);
    setnlOpen(false);
    setnlOpen1(false);
    setnlOpen3(false);
    setnlOpen4(false);
  };
  const [nlOpen3, setnlOpen3] = useState(false);
  const toggleNestedList3 = () => {
    setnlOpen3(!nlOpen3);
    setnlOpen(false);
    setnlOpen1(false);
    setnlOpen2(false);
    setnlOpen4(false);
  };
  const [nlOpen4, setnlOpen4] = useState(false);
  const toggleNestedList4 = () => {
    setnlOpen4(!nlOpen4);
    setnlOpen3(false);
    setnlOpen(false);
    setnlOpen1(false);
    setnlOpen2(false);
  };
  const toggleList4 = () => {
    setnlOpen3(false);
    setnlOpen(false);
    setnlOpen1(false);
    setnlOpen2(false);
    setnlOpen4(false);
  };

  const {
    completeActive,
    inCompleteActive,
    viableActive,
    assistanceActive,
    isDraftActive,
    poorActive,
    washActive,
  } = useSelector((state) => state.expressionDemandData);

  const dispatch = useDispatch();

  const nlOpen1Urls = ["/complete", "/incomplete"];
  const nlOpen2Urls = ["/Viable", "/Assistance", "/poor"];
  const nlOpen3Urls = ["/commited-projects"];
  const nlOpen4Urls = ["/viable-projects", "/bids"];

  useEffect(() => {
    if (nlOpen1Urls.includes(window.location.hash)) {
      setnlOpen1(!nlOpen1);
      setnlOpen(false);
      setnlOpen2(false);
      setnlOpen3(false);
    } else if (nlOpen2Urls.includes(window.location.hash)) {
      setnlOpen2(!nlOpen2);
      setnlOpen(false);
      setnlOpen1(false);
      setnlOpen3(false);
    } else if (nlOpen3Urls.includes(window.location.hash)) {
      setnlOpen3(!nlOpen3);
      setnlOpen(false);
      setnlOpen1(false);
      setnlOpen2(false);
    } else if (nlOpen4Urls.includes(window.location.hash)) {
      setnlOpen4(!nlOpen4);
      setnlOpen3(false);
      setnlOpen(false);
      setnlOpen1(false);
      setnlOpen2(false);
    }
  }, [window.location.hash]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <ListItemButton
        className={
          nlOpen1
            ? `is-expandable lib-active  ${nlOpen1 && "expanded"}`
            : `is-expandable ${nlOpen1 && "expanded"}`
        }
        onClick={toggleNestedList1}
      >
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary='Expression of demand' />
        {nlOpen1 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' />
      </ListItemButton>

      <Collapse
        in={nlOpen1}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen1 && "open"}`}
      >
        <List
          sx={{ display: "flex", flexDirection: "column" }}
          component='div'
          disablePadding
        >
          <NavLink
            to='/expression-demand/complete'
            className={({ isActive }) =>
              isActive || completeActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setCompleteActive(true)),
                  dispatch(setWashActive(false)),
                  dispatch(setInCompleteActive(false)),
                  dispatch(setDraftActive(false)),
                  dispatch(setViableActive(false)),
                  dispatch(setAssistanceActive(false)),
                  dispatch(setPoorActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Complete' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/expression-demand/incomplete'
            className={({ isActive }) =>
              isActive || inCompleteActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setCompleteActive(false)),
                  dispatch(setWashActive(false)),
                  dispatch(setInCompleteActive(true)),
                  dispatch(setDraftActive(false)),
                  dispatch(setViableActive(false)),
                  dispatch(setAssistanceActive(false)),
                  dispatch(setPoorActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Incomplete' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/expression-demand/draft'
            className={({ isActive }) =>
              isActive || isDraftActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setCompleteActive(false)),
                  dispatch(setWashActive(false)),
                  dispatch(setInCompleteActive(false)),
                  dispatch(setDraftActive(true)),
                  dispatch(setViableActive(false)),
                  dispatch(setAssistanceActive(false)),
                  dispatch(setPoorActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Drafts' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
      <ListItemButton
        className={
          nlOpen2
            ? `is-expandable lib-active  ${nlOpen2 && "expanded"}`
            : `is-expandable ${nlOpen2 && "expanded"}`
        }
        onClick={toggleNestedList2}
      >
        <ListItemIcon>
          <AccountTree />
        </ListItemIcon>
        <ListItemText primary='Classification' />
        {nlOpen2 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen2}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen2 && "open"}`}
      >
        <List
          sx={{ display: "flex", flexDirection: "column" }}
          component='div'
          disablePadding
        >
          <NavLink
            to='/expression-demand/Viable'
            className={({ isActive }) =>
              isActive || viableActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setViableActive(true)),
                  dispatch(setWashActive(false)),
                  dispatch(setCompleteActive(false)),
                  dispatch(setInCompleteActive(false)),
                  dispatch(setDraftActive(false)),
                  dispatch(setAssistanceActive(false)),
                  dispatch(setPoorActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Viable' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/expression-demand/Assistance'
            className={({ isActive }) =>
              isActive || assistanceActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setAssistanceActive(true)),
                  dispatch(setWashActive(false)),
                  dispatch(setCompleteActive(false)),
                  dispatch(setInCompleteActive(false)),
                  dispatch(setDraftActive(false)),
                  dispatch(setViableActive(false)),
                  dispatch(setPoorActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Assistance needed' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/expression-demand/poor'
            className={({ isActive }) =>
              isActive || poorActive ? "lib-active" : ""
            }
          >
            <ListItemButton
              onClick={() => {
                dispatch(setPoorActive(true)),
                  dispatch(setWashActive(false)),
                  dispatch(setAssistanceActive(false)),
                  dispatch(setCompleteActive(false)),
                  dispatch(setDraftActive(false)),
                  dispatch(setInCompleteActive(false)),
                  dispatch(setViableActive(false));
              }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Poor prospects' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
      <ListItemButton
        className={
          nlOpen3
            ? `is-expandable lib-active  ${nlOpen3 && "expanded"}`
            : `is-expandable ${nlOpen3 && "expanded"}`
        }
        onClick={toggleNestedList3}
      >
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText primary='Project preparation' />
        {nlOpen3 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen3}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen3 && "open"}`}
      >
        <List
          sx={{ display: "flex", flexDirection: "column" }}
          component='div'
          disablePadding
        >
          <NavLink
            to='/new-project'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton className='' sx={{ pl: 2 }}>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='New Projects' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/intro-committed-projects/committed'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Committed projects' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/intro-committed-projects/screened'
            className={({ isActive }) =>
              isActive || isDraftActive ? "lib-active" : ""
            }
          >
            <ListItemButton
            // onClick={() => {
            //   dispatch(setCompleteActive(false)),
            //     dispatch(setWashActive(false)),
            //     dispatch(setInCompleteActive(false)),
            //     dispatch(setDraftActive(true)),
            //     dispatch(setViableActive(false)),
            //     dispatch(setAssistanceActive(false)),
            //     dispatch(setPoorActive(false));
            // }}
            >
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Screened Viable Projects' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <ListItemButton
        className={
          nlOpen4
            ? `is-expandable lib-active  ${nlOpen4 && "expanded"}`
            : `is-expandable ${nlOpen4 && "expanded"}`
        }
        onClick={toggleNestedList4}
      >
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary='Deal room' />
        {nlOpen4 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen4}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen4 && "open"}`}
      >
        <List
          sx={{ display: "flex", flexDirection: "column" }}
          component='div'
          disablePadding
        >
          <NavLink
            to='/viable-projects'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Viable projects' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/bids'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Offers' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <NavLink
        to='/messages'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList4}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Message />
          </ListItemIcon>
          <ListItemText primary='Messages' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/advisor-account'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList4}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary='My account' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/expression-demand/wash'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList4}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Balance />
          </ListItemIcon>
          <ListItemText primary='Wash Prospects' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/support'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList4}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Campaign />
          </ListItemIcon>
          <ListItemText primary='Support' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
    </Box>
  );
};

export default ExpressionListItem;
