import React, { useEffect, useState } from "react";
import { Textarea, Typography, colors } from "@mui/joy";
import { Box, Grid, TextareaAutosize } from "@mui/material";
import { toast } from "react-toastify";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary"; 
import {
  DragIndicator,
  Circle,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import SingleItem from "../../components/single_item";
import SingleAttachment from "../../components/single_attachment";
import TextareaComment from "../../components/TextareaComment";
import { useLocation, useParams } from "react-router-dom";
import GovernanceInfoTable from "./GovernanceInfoTable";
import MeetingTable from "./MeetingTable";
import FileButton from "../../../../components/button/FileButton";
import DirectorsTable from "./DirectorsTable";

import ManagementTools from "./ManagementTools";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectScore, postScoreStep, setProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import { fetchDirectors, fetchManagementTool } from "../../../../features/onboard_partner/onboardPartnerSlice";
import { dateConverter, dateForHumans, formatPath } from "../../../../utils";
import NoTableDataComponent from "../../../expression_demand/tables/NoTableDataComponent";
import SingleTableItem from "../../components/single_table_item";

const Governance = () => {
  const [expanded, setExpanded] = React.useState('panel1');
  const location = useLocation();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const [isUpdating, setIsUpdating] = useState(false);
  const category = location.pathname.split('/')[3]

  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );

  const { id } = useParams();

  const data = projectData?.find(
    (item) => item?.projId === Number(id)
  );

  const { isLoading, director } = useSelector((state) => state.onboardPartner);
  const { scores, loading, directorsInformation } = useSelector(
    (state) => state.expressionDemandData
  );
  const { sLoadingManagementTool, managementTool } = useSelector(
    (state) => state.onboardPartner
  );
  const [dirLoading, setDirLoading] = useState(false);
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const stepName = "GOVERNANCE_INFO";
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const agmDate = data.projAgmMinutesDate
    ? dateConverter(data.projAgmMinutesDate)
    : "N/A";

  function createData(
    name,
    description,
    variableName,
    maxScores,
    userScore,
    userComment
  ) {
    return {
      name,
      description,
      variableName,
      maxScores,
      userScore,
      userComment,
    };
  }

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const fetchProjectScores = async () => {
    setDirLoading(true);
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));

    const res = await dispatch(
      fetchManagementTool({
        projId: data.projId,
      })
    );

    dispatch(
      fetchDirectors({
        projId: data.projId,
      })
    );

    setDirLoading(false);
  };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    fetchProjectScores();
  }, []);



  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);
  const governanceStatement = [
    createData("Type of registration", data.projRegType, "projRegType", 5),
    createData(
      "Period the organization has been providing water services",
      data.projOrgPeriod,
      "projOrgPeriod",
      5
    ),
  ];

  const attachments = [
    createData(
      "1st statutory returns",
      data.projStatu1File,
      "projStatu1File",
      1
    ),
    createData(
      "2nd statutory returns",
      data.projStatu2File,
      "projStatu2File",
      2
    ),
    createData(
      "3rd statutory returns",
      data.projStatu3File,
      "projStatu3File",
      2
    ),
  ];

  const agmAttachments = [
    createData(
      `Minutes of last AGM held on ${agmDate}`,
      data.projMinAgmFile,
      "projMinAgmFile",
      10
    ),
    createData(
      "PDF of constitution/bylaws or articles of association",
      data.projAssocArtFile,
      "projAssocArtFile",
      5
    ),
    // createData(
    //   "PDF copy of minute stating project willing to seek a loan",
    //   data.projSgmFile,
    //   "projSgmFile"
    // ),
    createData(
      `Minutes of First committee meeting held on ${data.projMin1Date ? dateConverter(data.projMin1Date) : ""
      }`,
      data.projMin1File,
      "projMin1File",
      1
    ),
    createData(
      `Minutes of second committee meeting held on ${data.projMin2Date ? dateConverter(data.projMin2Date) : ""
      }`,
      data.projMin2File,
      "projMin2File",
      1
    ),
    createData(
      `Minutes of third committee meeting held on ${data.projMin3Date ? dateConverter(data.projMin3Date) : ""
      }`,
      data.projMin3File,
      "projMin3File",
      1
    ),
  ];

  const managementTools = managementTool?.map((item) => {
    let newItem = {};
    if (item.mtType === "Operation and Maintenance Plan") {
      newItem = {
        ...item,
        variableName: "operationAndMaintenancePlan",
        maxScores: 1,
      };
    } else if (item.mtType === "Strategic Plan") {
      newItem = { ...item, variableName: "strategicPlan", maxScores: 1 };
    } else if (item.mtType === "Marketing Plan") {
      newItem = { ...item, variableName: "marketingPlan", maxScores: 1 };
    } else if (item.mtType === "Customer Contract") {
      newItem = { ...item, variableName: "customerContract", maxScores: 1 };
    } else if (item.mtType === "Other") {
      newItem = {
        ...item,
        variableName: "other",
        maxScores: 1,
        mtType: "Other Management tools",
      };
    }
    return newItem;
  });

  console.log(managementTools, "===================================================", managementTool)

  const filePath = "myimages/OTP_IMAGES/FUND_MY_WATER/1688452850623_sample.pdf";
  const filename = filePath.substring(filePath.lastIndexOf("_") + 1);

  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Governance information
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <GovernanceInfoTable data={data} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Governance information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', }}>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Box
                    sx={{
                      width: "85%",
                      display: "flex",
                      background: "var(--color-background-technical)",
                      justifyContent: "space-between",
                      p: 3,
                      fontWeight: 900,
                      fontSize: "18px",
                      borderRadius: "40px",
                    }}
                  >
                    <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                      Registration No. | {data?.projRegNo}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                      <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>Registration Cert |</Typography> <FileButton title={formatPath(data.projRegCertFile)} titlePreview={data.projRegCertFile} viable="true" />
                    </Box>
                    <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                      Registration Date |  {dateConverter(data?.projRegDate)}
                    </Typography>
                    <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                      KRA PIN | {data?.projKraPin}
                    </Typography>
                  </Box>

                </Box>
              </Box>
              {
                governanceStatement?.map((row) => (
                  <SingleItem
                    row={row}
                    userComment={userComment}
                    setUserComment={setUserComment}
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data?.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    status={category}
                    comment={comment}
                    setComment={setComment}

                    setUserMatrixInfo={setUserMatrixInfo}
                    userMatrixInfo={userMatrixInfo}

                  />
                ))
              }
              {
                attachments?.map((row) => (
                  <SingleAttachment
                    row={row}
                    userComment={userComment}
                    setUserComment={setUserComment}
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data?.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    status={category}
                    comment={comment}
                    setComment={setComment}

                    setUserMatrixInfo={setUserMatrixInfo}
                    userMatrixInfo={userMatrixInfo}

                  />
                ))}

            </AccordionDetails>
          </Accordion>
        )
      }


      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Annual general meeting information
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <MeetingTable data={data} />
            </AccordionDetails>
          </Accordion>

        ) : (
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Annual general meeting information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    background: "var(--color-background-technical)",
                    justifyContent: "space-between",
                    p: 3,
                    fontWeight: 900,
                    fontSize: "18px",
                    borderRadius: "40px",
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                    Annual General Meeting Date | {dateConverter(data?.projAgmMinutesDate)}
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: "16px", display: 'flex', alignItems: 'center' }}>
                    PDF copy of minute stating project willing to seek a loan |   <FileButton title={formatPath(data?.projSgmFile)} titlePreview={data?.projSgmFile} viable="true" />
                  </Typography>
                  <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>

                  </Typography>
                </Box>

                {
                  agmAttachments?.map((row) => (
                    <SingleAttachment
                      row={row}
                      userComment={userComment}
                      setUserComment={setUserComment}
                      setMatrixInfo={setMatrixInfo}
                      matrixInfo={matrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={data?.projId}
                      setOnblur={setOnBlur}
                      onBlur={onBlur}
                      maxScores={row.maxScores}
                      status={category}
                      comment={comment}
                      setComment={setComment}
                      setUserMatrixInfo={setUserMatrixInfo}
                      userMatrixInfo={userMatrixInfo}

                    />
                  ))}

              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }



      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel3bh-content'
          id='panel3bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '53%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Details of management committee members/ directors
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <DirectorsTable
            userComment={userComment}
            setUserComment={setUserComment}
            setMatrixInfo={setMatrixInfo}
            matrixInfo={matrixInfo}
            name="director"
            stepName={stepName}
            id={data?.projId}
            status={category}
            comment={comment}
            setComment={setComment}
            setUserMatrixInfo={setUserMatrixInfo}
            userMatrixInfo={userMatrixInfo}
          />
        </AccordionDetails>
      </Accordion>

      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel4bh-content'
              id='panel4bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Management tools
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <ManagementTools data={managementTools} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel4bh-content'
              id='panel4bh-header'
            >
              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Management tools
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ManagementTools
                userComment={userComment}
                setUserComment={setUserComment}
                setMatrixInfo={setMatrixInfo}
                matrixInfo={matrixInfo}
                name="director"
                stepName={stepName}
                id={data?.projId}
                status={category}
                comment={comment}
                setComment={setComment}
                setUserMatrixInfo={setUserMatrixInfo}
                userMatrixInfo={userMatrixInfo}
              />
            </AccordionDetails>
          </Accordion>
        )
      }

      <Grid item xs={12}>
        <Grid item xs={12}>
          {(category === "COMMITTED" ||
            category === "DEAL_SOURCING" ||
            category == 'Assistance' ||
            category == 'poor' ||
            category === "Viable" ||
            category === "Partner") &&
            <TextareaComment
              onChange={(e) => setComment(e.target.value)}
              comment={comment}
              submitStep={submitStep}
            />
          }
        </Grid>
      </Grid>
      {/* {(category === "COMMITTED" ||
        category === "DEAL_SOURCING" ||
        category === "Viable" ||
        category === "Partner") && (
          <>
            <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
              Comments on above information
            </Typography>
            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>

          </>
        )} */}

    </Box >
  );
};

export default Governance;
