import { Button, Checkbox, Chip, FormHelperText, Input, Textarea, Typography } from '@mui/joy'
import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { registerPartner, setEmailErrorDetails, setInstitutionDetails, setPhoneErrorDetails } from '../../../features/partner/partnerSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Delete, KeyboardArrowDown, Visibility, VisibilityOff } from '@mui/icons-material';
import { TitleData } from '../../../TitleData';
import { useTheme } from '@mui/material/styles';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormPhoneError from '../../../components/FormPhoneError';
import { searchPhoneNumber } from '../../../features/auth/authSlice';

function Institution() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { institution, errorEmail, errorPhone } = useSelector(state => state.partner)
    const { messagePhone } = useSelector(state => state.auth)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState(institution)
    const { accountName, websiteLink, address, institutionType, accInstDesc, usrTitle, usrFullNames, usrEmail, usrMobileNumber } = formData
    const [error, setError] = useState(errorEmail)
    const [phoneNo, setPhoneNo] = useState(usrMobileNumber);
    const [isValidPhone, setIsValidPhone] = useState(errorPhone);
    const [focus, setFocus] = useState(false);

    const validatePhoneNumber = () => {
        const kenyanPhoneNumberRegex = /^254[17]\d{8}$|^2547\d{8}$/;
        setIsValidPhone(kenyanPhoneNumberRegex.test(phoneNo));
        setIsUpdating(true);
        setFocus(false);
        dispatch(searchPhoneNumber({
            userPhone:phoneNo
          }))
    };
    const handleChangePhone = (e) => {
        setPhoneNo(e);
    }
    const handleFocus = (e) => {
        setFocus(true);
    }

    // const validatePhoneNumber = (phoneNumber) => {
    //     const kenyanPhoneNumberRegex = /^254[17]\d{7}$/; // Regular expression to match Kenyan phone number format
    //     return kenyanPhoneNumberRegex.test(phoneNumber);
    //   };
    //   const phoneNumber = "254712345678"; // example phone number
    //   const isValidPhoneNumber = validatePhoneNumber(phoneNumber);
    //   console.log(isValidPhoneNumber); // true


    const [focusEmail, setFocusEmail] = useState('')
    const isValidEmail = (usrEmail) => {
        return /\S+@\S+\.\S+/.test(usrEmail);
    }
    const handleValidateEmail = (e) => {
        if (!isValidEmail(usrEmail)) {
            setError(true)
        } else {
            setError(false)
        }
        setIsUpdating(true);
        setFocusEmail(false);
    }
    const handleFocusEmail = (e) => {
        setFocusEmail(true);
    }



    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setIsUpdating(true);
    };

    const handleChangeCheckbox = (e) => {
        if (e.target.checked) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
            // console.log("e.target",e.target.checked)
            setIsUpdating(true);
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: ''
            }))

        }
    };


    useEffect(() => {
        if (!isUpdating) {
            // TODO::
        }
        else {
            const data = {
                ...formData,
                usrMobileNumber: phoneNo
            }

            dispatch(setInstitutionDetails(data))
            dispatch(setEmailErrorDetails(error))
            dispatch(setPhoneErrorDetails(isValidPhone))
        }
    }, [dispatch, isUpdating, formData, error, phoneNo, isValidPhone]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <Typography
                        component="h6"
                        className="fmw-checkbox-label"
                        sx={{ mb: ".5rem" }}
                    >
                        Name <span className="star">*</span>
                    </Typography>
                    <Input
                        label=""
                        type="text"
                        value={accountName}
                        name="accountName"
                        onChange={handleChange}
                        placeholder="Name *"
                        size="lg"
                        className="fmw-input-joy"
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography
                        component="h6"
                        className="fmw-checkbox-label"
                        sx={{ mb: ".5rem" }}
                    >
                        Website link <span className="star">*</span>
                    </Typography>
                    <Input
                        label=""
                        type="text"
                        value={websiteLink}
                        name="websiteLink"
                        onChange={handleChange}
                        placeholder="Website link *"
                        size="lg"
                        className="fmw-input-joy"
                    />
                </Grid>
                <Grid item xs={12}>

                    <Typography
                        component="h6"
                        className="fmw-checkbox-label"
                        sx={{ mb: ".5rem" }}
                    >
                        Address <span className="star">*</span>
                    </Typography>
                    <Input
                        label=""
                        type="text"
                        value={address}
                        name="address"
                        onChange={handleChange}
                        placeholder="Enter address *"
                        size="lg"
                        className="fmw-input-joy"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '2rem' }}>
                        Type of institution <span className="star">*</span>
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Bank"
                                checked={institutionType == "Bank"}
                                name="institutionType"
                                value="Bank"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="NGO"
                                checked={institutionType == "NGO’s"}
                                name="institutionType"
                                value="NGO’s"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Trust"
                                checked={institutionType == "Trust"}
                                name="institutionType"
                                value="Trust"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="MFI"
                                checked={institutionType == "MFI"}
                                name="institutionType"
                                value="MFI"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Private Company"
                                checked={institutionType == "Private Company"}
                                name="institutionType"
                                value="Private Company"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="DFI"
                                checked={institutionType == "DFI"}
                                name="institutionType"
                                value="DFI"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Private Investor"
                                checked={institutionType == "Private Investors"}
                                name="institutionType"
                                value="Private Investors"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Foundation"
                                checked={institutionType == "Foundations"}
                                name="institutionType"
                                value="Foundations"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Other"
                                checked={institutionType == "Others"}
                                name="institutionType"
                                value="Others"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Checkbox
                                color="primary"
                                label="Sacco"
                                checked={institutionType == "Sacco"}
                                name="institutionType"
                                value="Sacco"
                                onChange={handleChangeCheckbox}
                                size="lg"
                                variant="soft"
                                className="fmw-checkbox-label"
                            />
                        </Grid>
                        {
                            institutionType == "Others" && (
                                <Grid item xs={12}>
                                    <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '.5rem' }}>
                                        Describe your other type of institution here <span className="star">*</span>
                                    </Typography>
                                    <Textarea
                                        onChange={handleChange}
                                        value={institutionType == "Others" ? accInstDesc : ''}
                                        // value={accInstDesc}
                                        name="accInstDesc"
                                        minRows={3}
                                        size="lg"
                                        variant="outlined"
                                        placeholder="Write here"
                                        className="fmw-textarea"
                                    />
                                </Grid>
                            )
                        }

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '2rem', mt: '1rem' }}>
                        Contact person
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography
                                component="h6"
                                className="fmw-checkbox-label"
                                sx={{ mb: ".5rem" }}
                            >
                                Title <span className="star">*</span>
                            </Typography>
                            <Select
                                className="fmw-select"
                                onChange={(e, newval) => {
                                    handleChange({
                                        target: {
                                            value: newval,
                                            name: "usrTitle"
                                        }
                                    })
                                }}
                                placeholder="Select title *"
                                indicator={<KeyboardArrowDown />}
                                size='lg'
                                name="usrTitle"
                                value={formData.usrTitle}

                                sx={{
                                    width: '100%',
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                            >
                                {TitleData.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography
                                component="h6"
                                className="fmw-checkbox-label"
                                sx={{ mb: ".5rem" }}
                            >
                                Name <span className="star">*</span>
                            </Typography>
                            <Input
                                label=""
                                value={usrFullNames}
                                name="usrFullNames"
                                placeholder="Name *"
                                type="text"
                                onChange={handleChange}
                                size="lg"
                                className="fmw-input-joy"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                component="h6"
                                className="fmw-checkbox-label"
                                sx={{ mb: ".5rem" }}
                            >
                                Email <span className="star">*</span>
                            </Typography>
                            <Input
                                label=""
                                value={usrEmail}
                                name="usrEmail"
                                placeholder="Email address *"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleValidateEmail}
                                onFocus={handleFocusEmail}
                                size="lg"
                                className="fmw-input-joy"
                            />

                            {usrEmail && error && !focusEmail && <FormHelperText className="upload-sub-label-error">Invalid email address</FormHelperText>}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                component="h6"
                                className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-checkbox-label-error" : "fmw-checkbox-label"}
                                sx={{ mb: ".5rem" }}
                            >
                                Phone number <span className="star">*</span>
                            </Typography>
                            {phoneNo && messagePhone == "OK" && !focus &&
                                <FormPhoneError
                                    title="Account already exists" />
                            }
                            <ReactPhoneInput
                                defaultCountry="ke"
                                country="ke"
                                className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "flags-error" : "flags"}
                                inputClass={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-phone-error" : "fmw-phone flags"}
                                required
                                searchClass="search-class"
                                searchStyle={{
                                    margin: "0",
                                    width: "100% !important",
                                    height: "80px !important",
                                }}
                                enableSearchField
                                disableSearchIcon
                                value={phoneNo}
                                onChange={handleChangePhone}
                                onBlur={validatePhoneNumber}
                                onFocus={handleFocus}
                            />
                            {phoneNo && !isValidPhone && !focus &&
                                <FormPhoneError
                                    title="Enter a valid phone number" />
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Institution