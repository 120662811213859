import { Button, Checkbox, TextField, Chip, Typography, FormHelperText } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, PictureAsPdf } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {  cleanupStep4KioskContract,  onbaordKioskContract,  reset,  setStep4KioskContract} from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';
import TypographySub from './TypographySub';


const KioskContract = ({formData}) => {
    const dispatch = useDispatch();
    const {  step4KioskContract,  } = useSelector((state) => state.onboardPartner)
    const [fileKioskContract, setFileKioskContract] = useState(step4KioskContract)
    const { nameKioskContract, dateKioskContract, file_base64KioskContract, typeKioskContract } = fileKioskContract;
    const [isUpdating, setIsUpdating] = useState(false);

    const [fetchedKioskContract, setFetchedKioskContract] = useState(formData?.projKioskConnFile)
   
    const removeFetchedKioskContract = () => {
        setFetchedKioskContract('')
        setFileKioskContract({
            nameKioskContract: '',
            dateKioskContract: '',
            typeKioskContract: '',
            file_base64KioskContract: undefined,
        });
        dispatch(cleanupStep4KioskContract());
    }
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // KioskContract pdf
    const [errorKioskContract, setErrorKioskContract] = useState('')
    const [file, setFile] = useState(undefined)

    const handleKioskContract = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;

        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorKioskContract(
							'Please upload a PDF file not more than 10mb ',
						);
            setFileKioskContract('');
            return;
        }
        setFile(_file);
        setErrorKioskContract('')
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            // Create an object containing image information.
            let imageObj = {
                nameKioskContract: _file.name,
                dateKioskContract: _date,
                typeKioskContract: _file.type,
                file_base64KioskContract: base64String.toString()
            };

            setFileKioskContract(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordKioskContract(formData));
    }

    const removeKioskContract = () => {
        setFileKioskContract({
            nameKioskContract: '',
            dateKioskContract: '',
            typeKioskContract: '',
            file_base64KioskContract: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupStep4KioskContract());
    }

 
    useEffect(()=>{
        if(!isUpdating){
        }else{
            dispatch(setStep4KioskContract(fileKioskContract));
        }
        dispatch(reset());
    },[fileKioskContract,isUpdating])
    return (
        <>
                              <TypographySub title="PDF of kiosk sample customer contract" sub="pdf" superS="true"/>
                    {
                        fetchedKioskContract ? (
                            <>
                                <Chip
                                    variant="soft"
                                    className="file-chip-primary"
                                    color="primary"
                                    size="sm"
                                    startDecorator={<AttachFile size="sm" />}
                                    onClick={() => previewPaper(fetchedKioskContract)}
                                >
                                    {formatPath(fetchedKioskContract)}
                                </Chip>
                                <IconButton
                                    aria-label="close"
                                    onClick={removeFetchedKioskContract}
                                    sx={{
                                        color: (theme) => theme.palette.grey[500], mr: '6px'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </>) : (
                            <>
                                {
                                    !nameKioskContract ? (
                                        <>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                border: 'none',
                                                // border: '1px solid var(--color-primary-light)',
                                                width: '100%',
                                                height: '50px'
                                            }}>
                                                <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                    <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                        <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                            <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                        </div>
                                                        {
                                                            errorKioskContract ? (
                                                                <Typography component="h6" className="upload-sub-label-error">
                                                                    {errorKioskContract}
                                                                </Typography>
                                                            ) : (
                                                                <Typography variant="caption" className="upload-sub-label">
                                                                    Click here to upload
                                                                </Typography>
                                                            )
                                                        }
                                                    </div>
                                                    <input
                                                        type="file"
                                                        name='file'
                                                        accept=".pdf"
                                                        webkitdirectory
                                                        onChange={handleKioskContract}
                                                        style={{ width: '0px', height: '0px' }}
                                                    />

                                                </label>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{
                                                display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                border: '1px solid var(--color-primary-light)',
                                                width: '337px',
                                                height: '55px'
                                            }}>
                                                <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                    <span style={{ margin: "0px 6px" }}>
                                                        {
                                                            typeKioskContract === "application/pdf" ? (
                                                                <PictureAsPdf sx={{
                                                                    fontSize: '40px',
                                                                    color: 'red'
                                                                }} />
                                                            ) : (
                                                                <Article sx={{
                                                                    fontSize: '40px',
                                                                    color: '#1976d2'
                                                                }} />
                                                            )
                                                        }

                                                    </span>
                                                    <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                    <Typography component="h6" className="fmw-upload-typography">
                                                        {subString(nameKioskContract, 25)}
                                                    </Typography>


                                                    <Typography variant="caption" className="upload-sub-label">
                                                        uploaded on  {_date}
                                                    </Typography>
                                                </div>

                                                <div>
                                                    <IconButton
                                                        aria-label="close"
                                                        onClick={removeKioskContract}
                                                        sx={{
                                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                                        }}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }

        </>
    )
}

export default KioskContract
