import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { message, Steps, theme, Typography } from "antd";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/joy";

import ProjectDescription from "../new_projects/steps/ProjectDescription";
import { useState } from "react";
import PersonalDetails from "../new_projects/steps/PersonalDetails";
import BusinessDetails from "../new_projects/steps/BusinessDetails";
import ManagementDetails from "../new_projects/steps/ManagementDetails";
import Approval from "../new_projects/steps/Approval";
import PageTitle from "../page_title/PageTitle";

export default function ViewProject() {
  const { name } = useParams();
  const { singleProject } = useSelector((state) => state.global);
  const { user } = useSelector((state) => state.auth);
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const { token } = theme.useToken();
  const location = useLocation();
  const subTitle = location.pathname.split("/")[3];
  const { id } = useParams();
  const navigate = useNavigate();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  console.log(name);

  const steps = [
    {
      title: "Basic Details",
      content: <PersonalDetails data={singleProject} />,
    },
    {
      title: "Business Details",
      content: <BusinessDetails data={singleProject} />,
    },
    {
      title: "Management Details",
      content: <ManagementDetails data={id} />,
    },
    {
      title: "Project Description",
      content: <ProjectDescription data={singleProject} />,
    },
    {
      title: "Approval",
      content: <Approval data={singleProject} />,
    },
  ];

  const handleStepClick = (index) => {
    setCurrent(index);
  };

  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    // fetchDirectors();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Box sx={{ ml: 5 }}>
        {/* <CustomSeparator /> */}
        <PageTitle
          currentPage='New Projects'
          subTitle={subTitle}
          urlSubTitle={`/expression-demand/${subTitle}`}
          subSubTitle={singleProject?.accTradeName}
          subHeader='Screening'
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#F0F5FF",
          height: "80%",
          width: "95%",
          alignSelf: "center",
          mt: 5,
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#226CBF",
            height: "100%",
            width: "40%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "60%",
            }}
          >
            <Box sx={{ mt: 5 }}>
              <Box
                sx={{ fontWeight: "bold", color: "white", fontSize: 22, mb: 1 }}
              >
                {singleProject?.accTradeName}
              </Box>
            </Box>

            <Box sx={{ color: "#C6E1FF", mb: "4.98rem", fontSize: " 0.75rem" }}>
              Reviewing {singleProject?.accTradeName} application
            </Box>
            <Steps direction='vertical' current={current}>
              {items.map((item, index) => (
                <Step
                  key={index}
                  title={item.title}
                  onClick={() => handleStepClick(index)}
                />
              ))}
            </Steps>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "60%",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "70%",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            {steps[current].content}
          </Box>
          {!(current === steps.length - 1) && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {current < steps.length - 1 && (
                <Button
                  onClick={() => next()}
                  sx={{
                    backgroundColor: "#226CBF",
                    width: "10.75rem",
                    height: "3.25rem",
                    borderRadius: "30px",
                    mt: 2,
                  }}
                >
                  Next
                </Button>
              )}
              {current > 0 && (
                <Button
                  onClick={() => prev()}
                  variant='outlined'
                  sx={{
                    width: "10.75rem",
                    height: "3.25rem",
                    borderRadius: "30px",
                    borderColor: "#226CBF", // Set border color
                    color: "#226CBF", // Set text color
                    "&:hover": {
                      borderColor: "#226CBF", // Set hover border color
                      color: "#226CBF", // Set hover text color
                    },
                  }}
                >
                  Previous
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
