import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { onboardPartner, reset, setStep2AgmDate } from '../../../features/onboard_partner/onboardPartnerSlice';
import { dateForHumans } from '../../../utils';


const AgmMinDatePicker = ({formData}) => {
    const { step2AgmDate } = useSelector(state => state.onboardPartner)
     
    const [isUpdatingDate, setIsUpdatingDate] = useState(false);
    const [valueAgm, setValueAgm] = React.useState(formData?.projAgmMinutesDate || step2AgmDate.projAgmMinutesDate);
    const dispatch = useDispatch();
    
    

    useEffect(() => {
        if (!isUpdatingDate) {
        } else {
            // dispatch(onboardPartner({
            //     projId: formData?.projId,
            //     projAgmMinutesDate: dateForHumans(valueAgm.$d)
            // }))
            dispatch(setStep2AgmDate({
                projAgmMinutesDate: dateForHumans(valueAgm.$d)
            }))
        }
        dispatch(reset())
    }, [dispatch, isUpdatingDate,valueAgm]);
  

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    disableFuture= "true"
                    value={valueAgm}
                    onChange={(newValue) => {
                        setValueAgm(newValue);
                        setIsUpdatingDate(true);
                    }}
                    PopperProps={{
                        placement: "bottom-end",
                    }}
                    renderInput={(params) => <TextField fullWidth  {...params}
                        size="small"
                        className="fmw-date-picker"
                    />
                    }
                />
            </LocalizationProvider>

        </>
    );
}
export default AgmMinDatePicker;