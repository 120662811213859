import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { setStep2, setStep2Date } from '../../../features/onboard_partner/onboardPartnerSlice';
import { dateForHumans } from '../../../utils';


const BasicDatePicker = ({formData}) => {
  const { step2Date } = useSelector(state => state.onboardPartner)
    
  const [isUpdating, setIsUpdating] = useState(false);
  const [valueReg, setValueReg] = React.useState(formData?.projRegDate || step2Date.projRegDate);
  const dispatch = useDispatch();

 
  useEffect(() => {
        if (!isUpdating) {
            // TODO::
        } else {
          // dispatch(setStep2Date(valueReg.$d))
            dispatch(setStep2Date({
              projRegDate: dateForHumans(valueReg.$d)
          }))
        }
    }, [dispatch, isUpdating,valueReg]);
   
  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD-MM-YYYY"
          disableFuture="true"
          value={valueReg}
          onChange={(newValue) => {
            setValueReg(newValue);
            setIsUpdating(true);
          }}
          PopperProps={{
            placement: "bottom-end",
          }}
          renderInput={(params) => <TextField fullWidth  {...params}
            size="small"
            className="fmw-date-picker"

          />
        }
        />
      </LocalizationProvider> */}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD-MM-YYYY"
          disableFuture="true"
          value={valueReg}
          onChange={(newValue) => {
            setValueReg(newValue);
            setIsUpdating(true);
          }}
          PopperProps={{
            placement: "bottom-end",
          }}
          renderInput={(params) => <TextField fullWidth  {...params}
            size="small"
            className="fmw-date-picker"

          />
          }
        />
      </LocalizationProvider>

    </>
  );
}
export default BasicDatePicker;