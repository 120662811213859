import React, { useEffect, useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import Button from "@mui/joy/Button";
import Grid from "@mui/material/Grid";
import {
  Box,
  Container,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  CircularProgress,
  Paper,
} from "@mui/material";
import { TextField, Typography } from "@mui/joy";
import RegisterPartner from "../../assets/partner.png";
import Institution from "./components/Institution";
import CoreIndicators from "./components/CoreIndicators";
import OutcomeAreas from "./components/OutcomeAreas";
import Mandate from "./components/Mandate";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanupForm,
  onboardMandateFileCor,
  onboardMandateFileCorpStrategy,
  onboardMandateFileRegCert,
  registerPartner,
  reset,
  saveAccountMandate,
  savePartnerAreas,
  savePartnerIndicators,
} from "../../features/partner/partnerSlice";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/header/Header";
import { Navigate, useNavigate } from "react-router-dom";
import Otp from "./components/Otp";
import Signin from "../signin/Signin";
import { useMediaQuery } from "react-responsive";
import { toSentenceCase } from "../../utils";

const notifySuccess = () =>
	toast.success(' Successfully Uploaded', {
		theme: 'colored',
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

const notifyError = () =>
	toast.error(' passwords dont match', {
		theme: 'colored',
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});

const steps = [
	{
		label: 'Step 1',
		description: 'Institution',
	},
	{
		label: 'Step 2',
		description: 'Core Indicators',
	},
	{
		label: 'Step 3',
		description: 'Outcome Areas',
	},
	{
		label: 'Step 4',
		description: 'Mandate',
	},
];

const ActiveStepContent = ({ step }) => {
	switch (step) {
		case 0:
			return <Institution />;
		case 1:
			return <CoreIndicators />;
		case 2:
			return <OutcomeAreas />;
		case 3:
			return <Mandate />;
		default:
			throw new Error('Unknown step');
	}
};

const SignupImpactFunder = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [stateChecker, setStateChecker] = useState(false);
	const {
		institution,
		errorEmail,
		errorPassword,
		errorPhone,
		partner,
		indicators,
		outcomeAreas,
		mandate,
		isErrorRegister,
		mandateFile,
		isSuccess,
		isError,
		message,
		isCleanupForm,
		isSuccessAm,
		isErrorAm,
		messageAm,
		isSuccessFileCor,
		isSuccessRegister,
		messageRegister,
		mandateCor,
		mandateRegCert,
		mandateFinStatement,
		mandateCorpStrategy,
		mandateFileCor,
		mandateFileRegCert,
		mandateFileFinStatement,
		mandateFileCorpStrategy,
		isLoading,
		isSuccessIndicators,
		isErrorIndicators,
		isSuccessOutcome,
		isErrorOutcome,
		isLoadingFileCor,
		isLoadingFileRegCert,
		isLoadingFileFinStatement,
		isLoadingFileCorpStrategy,
	} = useSelector((state) => state.partner);
	const { messagePhone } = useSelector((state) => state.auth);
	const { nameCor, dateCor, file_base64Cor, typeCor } = mandateCor;

	const { nameRegCert, dateRegCert, file_base64RegCert, typeRegCert } =
		mandateRegCert;

	const [activeStep, setActiveStep] = React.useState(0);
	const [completed, setCompleted] = React.useState({});
	const [openOtp, setopenOtp] = React.useState(false);

	const [openLogIn, setOpenLogIn] = React.useState(false);
	const handleClickOpenLogIn = () => {
		setOpenLogIn(true);
	};
	const handleCloseLogIn = () => {
		setOpenLogIn(false);
	};

	const handleSubmitInstitution = (e) => {
		const _formData = {
			accTradeName: institution.accountName,
			accWebsite: institution.websiteLink,
			accLocation: institution.address,
			accType: institution.institutionType,

			accInstDesc:
				institution.institutionType == 'Others' ? institution.accInstDesc : '',
			usrTitle: institution.usrTitle,
			usrFullNames: institution.usrFullNames,
			usrEmail: institution.usrEmail,
			usrMobileNumber: institution.usrMobileNumber,
			usrEncryptedPassword: mandate.usrEncryptedPassword,
			accTypeId: 3,
		};
		dispatch(registerPartner(_formData));
		setStateChecker(true);
		// handleOpenOTP();
	};

	const handleOpenOTP = () => {
		setopenOtp(true);
	};

	const handleCloseOTP = () => {
		setopenOtp(false);
	};

	const handleSubmitCoreIndicators = (e) => {
		const _formData = {
			accId: partner.usrAccId,
			accFocus: Object.values(indicators).join(','),
		};
		dispatch(savePartnerIndicators(_formData));
	};

	const handleSubmitOutcomeAreas = (e) => {
		const _formData = {
			accId: partner.usrAccId,
			accAreas: Object.values(outcomeAreas).join(','),
		};
		dispatch(savePartnerAreas(_formData));
	};

	const handleSubmitMandate = (e) => {
		if (institution.institutionType == 'Others') {
			handleSubmitMandateB();
		} else {
			handleSubmitMandateA();
		}
	};
	const handleSubmitMandateA = () => {
		if (
			!mandate.accMandate ||
			!mandate.accWebsite ||
			!mandate.accSdgs ||
			!mandateFileCor ||
			!mandateCor.nameCor ||
			!mandate.motherCompany
		) {
			toast.warning('Please fill all required mandate details');
		} else {
			dispatch(
				saveAccountMandate({
					accId: partner.usrAccId,
					accMotherCmpy: mandate.motherCompany,
					accMandate: mandate.accMandate,
					accWebsite: mandate.accWebsite,
					accSdgs: mandate.accSdgs,
					accRegCert: mandateFileCor,
				}),
			);
		}
	};
	const handleSubmitMandateB = () => {
		if (
			!mandate.accMandate ||
			!mandateRegCert.nameRegCert ||
			!mandateFinStatement.nameFinStatement ||
			!mandateCorpStrategy.nameCorpStrategy
		) {
			toast.warning('Please fill all the required mandate details ');
		} else {
			dispatch(
				saveAccountMandate({
					accId: partner.usrAccId,
					accMandate: mandate.accMandate,
					accRegCert: mandateFileRegCert,
					accFinStatement: mandateFileFinStatement,
					accSdgsFile: mandateFileCorpStrategy,
				}),
			);
		}
	};

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);

		// little customization
		if (step === 0) {
			handleReset();
		} else if (step === 1) {
			setCompleted({ 0: true, 1: true });
		} else if (step === 2) {
			setCompleted({ 0: true, 1: true });
		} else if (step === 3) {
			setCompleted({ 0: true, 1: true, 2: true });
		} else {
			handleComplete();
		}
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();

		console.log('newCompleted', newCompleted);
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const handleInstitution = () => {
		if (
			!institution.usrMobileNumber ||
			!institution.accountName ||
			!institution.websiteLink ||
			!institution.address ||
			!institution.institutionType ||
			!institution.usrTitle ||
			!institution.usrFullNames ||
			!institution.usrEmail
		) {
			toast.warning('fill all details in step1');
		} else if (
			institution.institutionType == 'Others' &&
			!institution.accInstDesc
		) {
			toast.warning('Describe your other type of institution');
		} else if (errorEmail) {
			toast.warning('Invalid email address');
		} else if (!errorPhone) {
			toast.warning('Enter a valid phone number');
		} else if (isErrorRegister && messageRegister) {
			toast.error(toSentenceCase(messageRegister));
		} else {
			// handleSubmitInstitution()
			// handleOpenOTP();
			handleComplete();
		}
	};

	const handleCoreIndicators = () => {
		let indicators_step_arr = [];
		Object.keys(indicators).forEach(
			(key) => indicators[key] && indicators_step_arr.push(indicators[key]),
		);
		if (!indicators_step_arr.length) {
			toast.warning('Select atleast one focus area');
		} else {
			if (
				!institution.usrMobileNumber ||
				!institution.accountName ||
				!institution.websiteLink ||
				!institution.address ||
				!institution.institutionType ||
				!institution.usrTitle ||
				!institution.usrFullNames ||
				!institution.usrEmail
			) {
				toast.warning("'Step 1' details required");
				return;
			}
			// handleSubmitCoreIndicators();
			// isLoading
			handleComplete();
		}
	};

	const handleOutcomeAreas = () => {
		let outcome_step_arr = [];
		Object.keys(outcomeAreas).forEach(
			(key) => outcomeAreas[key] && outcome_step_arr.push(outcomeAreas[key]),
		);

		let indicators_step_arr = [];
		Object.keys(indicators).forEach(
			(key) => indicators[key] && indicators_step_arr.push(indicators[key]),
		);
		if (!outcome_step_arr.length) {
			toast.warning('Select atleast one outcome area');
		} else {
			if (
				!institution.usrMobileNumber ||
				!institution.accountName ||
				!institution.websiteLink ||
				!institution.address ||
				!institution.institutionType ||
				!institution.usrTitle ||
				!institution.usrFullNames ||
				!institution.usrEmail
			) {
				toast.warning("'Step 1' details required");
				return;
			}
			if (!indicators_step_arr.length) {
				toast.warning('Please submit step 2 details first');
				return;
			}
			// handleSubmitOutcomeAreas();
			handleComplete();
		}
	};

	const handleApply = () => {
		// if (Object.keys(partner).length === 0) {
		//   toast.warning(''Step 1' details required');
		//   return;
		// }
		let indicators_arr = [];
		Object.keys(indicators).forEach(
			(key) => indicators[key] && indicators_arr.push(indicators[key]),
		);

		let outcome_areas_arr = [];
		Object.keys(outcomeAreas).forEach(
			(key) => outcomeAreas[key] && outcome_areas_arr.push(outcomeAreas[key]),
		);

		if (
			!institution.usrMobileNumber ||
			!institution.accountName ||
			!institution.websiteLink ||
			!institution.address ||
			!institution.institutionType ||
			!institution.usrTitle ||
			!institution.usrFullNames ||
			!institution.usrEmail
		) {
			toast.warning('Please fill all required institution details !');
		} else if (!indicators_arr.length) {
			toast.warning('Please fill all required core indicators details !');
		} else if (!outcome_areas_arr.length) {
			toast.warning('Please fill all required outcome areas details !');
		} else if (mandate.usrEncryptedPassword !== mandate.confirmPassword) {
			toast.warning('confirm password is not matched');
		} else if (errorPassword) {
			toast.warning(errorPassword);
		} else if (
			(institution.institutionType == 'Foundations' ||
				institution.institutionType == 'Trust') &&
			(!mandate.accMandate ||
				!mandate.accWebsite ||
				!mandate.accSdgs ||
				!mandateFileCor ||
				!mandateCor.nameCor ||
				!mandate.motherCompany)
		) {
			toast.warning('Please fill all required mandate details !');
		}
		// institution.institutionType == 'Foundations' || institution.institutionType == 'Trust'
		else if (
			(institution.institutionType == 'Others' ||
				institution.institutionType == 'Bank' ||
				institution.institutionType == 'NGO’s' ||
				institution.institutionType == 'MFI' ||
				institution.institutionType == 'Private Company' ||
				institution.institutionType == 'DFI' ||
				institution.institutionType == 'Private Investors' ||
				institution.institutionType == 'Sacco') &&
			(!mandate.accMandate ||
				!mandateRegCert.nameRegCert ||
				!mandateFinStatement.nameFinStatement ||
				!mandateCorpStrategy.nameCorpStrategy)
		) {
			toast.warning('Please submit step mandate details first');
		} else {
			handleSubmitInstitution();
			// handleSubmitMandate();
		}
	};

	const isMobile = useMediaQuery({ query: '(max-width:768px)' });
	const isMobileResp = useMediaQuery({ query: '(max-width:480px)' });
	// console.log("window",window.innerWidth)

	useEffect(() => {
		if (isSuccessRegister && institution) {
			// toast.success(messageRegister);
		}
		if (isSuccessRegister && messageRegister) {
			handleSubmitCoreIndicators();
		}
		if (isSuccessIndicators && message) {
			// toast.success(message);
			handleSubmitOutcomeAreas();
		}
		if (
			isSuccessOutcome &&
			message &&
			mandate.accMandate &&
			mandate.accWebsite &&
			mandate.accSdgs &&
			mandateFileCor &&
			mandateCor.nameCor &&
			mandate.motherCompany
		) {
			//part 4A
			// toast.success(message);
			dispatch(
				saveAccountMandate({
					accId: partner.usrAccId,
					accMotherCmpy: mandate.motherCompany,
					accMandate: mandate.accMandate,
					accWebsite: mandate.accWebsite,
					accSdgs: mandate.accSdgs,
					accRegCert: mandateFileCor,
				}),
			);
		}

		if (
			isSuccessOutcome &&
			message &&
			mandate.accMandate &&
			mandateRegCert.nameRegCert &&
			mandateFinStatement.nameFinStatement &&
			mandateCorpStrategy.nameCorpStrategy
		) {
			//part 4B
			// toast.success(message);
			dispatch(
				saveAccountMandate({
					accId: partner.usrAccId,
					accMandate: mandate.accMandate,
					accRegCert: mandateFileRegCert,
					accFinStatement: mandateFileFinStatement,
					accSdgsFile: mandateFileCorpStrategy,
				}),
			);
		}

		if (isSuccessAm && messageAm) {
			setStateChecker(false);
			toast.success(toSentenceCase(messageAm));
			handleOpenOTP();
		}

		if (isErrorRegister && messageRegister) {
			toast.error(toSentenceCase(messageRegister));
			setStateChecker(false);
			handleClickOpenLogIn();
		}
		if (isErrorIndicators && message) {
			setStateChecker(false);
			toast.error(toSentenceCase(message));
		}
		if (isErrorOutcome && message) {
			setStateChecker(false);
			toast.error(toSentenceCase(message));
		}
		if (isErrorAm && messageAm) {
			//account mandate step
			setStateChecker(false);
			toast.error(toSentenceCase(messageAm));
		}

		// if (isCleanupForm && isSuccessAm) {
		//   toast.success(messageAm)
		//   navigate("/")
		//   dispatch(cleanupForm());
		// }

		dispatch(reset());
	}, [
		dispatch,
		isSuccess,
		isError,
		mandateFileCor,
		mandateCor.nameCor,
		mandateFileRegCert,
		mandateFileFinStatement,
		mandateFileCorpStrategy,
		isCleanupForm,
		mandate,
		indicators,
		outcomeAreas,
		isSuccessAm,
		isErrorAm,
		messageAm,
		isSuccessFileCor,
		isErrorRegister,
		isSuccessRegister,
		messageRegister,
		isSuccessIndicators,
		isErrorIndicators,
		isSuccessOutcome,
		isErrorOutcome,
	]);

	return (
		<>
			<Header />

			<Box
				component='main'
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<Container className='main' maxWidth='lg' sx={{ my: 4 }}>
					<Grid container spacing={2}>
						{isMobile ? (
							<>
								<Grid item md={5} xs={12} sx={{ pt: 0, mt: '16px' }}>
									<Typography component='body2' className='highlighter' />
									<Typography component='h5' className='meta-title'>
										Become a partner
									</Typography>
									<Typography component='h4' className='main-title'>
										Register here
									</Typography>

									<Box sx={{ mt: 4 }}>
										<img
											src={RegisterPartner}
											alt='register-wsp'
											loading='lazy'
											style={{
												height: isMobileResp ? '200px' : '400px',
												width: '100%',
												borderRadius: '2px',
												objectFit: 'cover',
											}}
										/>
									</Box>
								</Grid>
								<Grid
									item
									md={7}
									xs={12}
									style={{
										backgroundColor: '#ffff',
										paddingTop: 0,
										marginTop: '16px',
										padding: '0',
									}}
								>
									<Paper
										sx={{
											width: { xs: 1, sm: 1, md: 1 / 2 },
											paddingTop: '24px',
											overflow: 'hidden',
										}}
										className='custom-stepper'
									>
										<Stepper
											nonLinear
											activeStep={activeStep}
											alternativeLabel
											className='custom-stpper-tab'
										>
											{steps.map((step, index) => (
												<Step key={step.label} completed={completed[index]}>
													<StepButton
														color='primary'
														onClick={handleStep(index)}
														className={`stepper-btn  ${
															activeStep === index || completed[index]
																? 'sb-active'
																: ''
														}`}
													>
														<Typography
															component='body2'
															className='step-label'
														>
															{step.label}
														</Typography>
														<Typography
															component='h5'
															className='step-description'
														>
															{step.description}
														</Typography>
													</StepButton>
												</Step>
											))}
										</Stepper>
										<div style={{ margin: '4rem 1.5rem' }}>
											<React.Fragment>
												<Box sx={{ mt: 4, mb: 2, py: 1 }}>
													<ActiveStepContent step={activeStep} />
												</Box>

												<Box
													sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
												>
													{activeStep == 0 && (
														<Button
															onClick={handleInstitution}
															className='next-btn'
														>
															Next
														</Button>
													)}
													{activeStep == 1 && (
														<Button
															onClick={handleCoreIndicators}
															className='next-btn'
														>
															Next
														</Button>
													)}
													{activeStep == 2 && (
														<Button
															onClick={handleOutcomeAreas}
															className='next-btn'
														>
															Next
														</Button>
													)}
													{activeStep == 3 ? (
														<>
															{stateChecker ? (
																<Button
																	disabled
																	variant='contained'
																	sx={{ textTransform: 'none' }}
																>
																	<CircularProgress size={17} color='inherit' />
																	&nbsp;Registering Partner...
																</Button>
															) : (
																<Button
																	onClick={handleApply}
																	className='next-btn'
																>
																	Submit Application
																</Button>
															)}
														</>
													) : (
														<></>
													)}
												</Box>
											</React.Fragment>
										</div>
									</Paper>

									<CssVarsProvider />
								</Grid>
							</>
						) : (
							<>
								<Grid
									item
									md={7}
									xs={12}
									style={{
										backgroundColor: '#ffff',
										paddingTop: 0,
										marginTop: '16px',
									}}
								>
									<Box
										sx={{ width: '100%', paddingTop: '24px' }}
										className='custom-stepper'
									>
										<Stepper nonLinear activeStep={activeStep} alternativeLabel>
											{steps.map((step, index) => (
												<Step key={step.label} completed={completed[index]}>
													<StepButton
														color='primary'
														onClick={handleStep(index)}
														className={`stepper-btn  ${
															activeStep === index || completed[index]
																? 'sb-active'
																: ''
														}`}
													>
														<Typography
															component='body2'
															className='step-label'
														>
															{step.label}
														</Typography>
														<Typography
															component='h5'
															className='step-description'
														>
															{step.description}
														</Typography>
													</StepButton>
												</Step>
											))}
										</Stepper>
										<div style={{ margin: '4rem 1.5rem' }}>
											<React.Fragment>
												<Box sx={{ mt: 4, mb: 2, py: 1 }}>
													<ActiveStepContent step={activeStep} />
												</Box>

												<Box
													sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
												>
													{activeStep == 0 && (
														<Button
															onClick={handleInstitution}
															className='next-btn'
															disabled={messagePhone == 'OK'}
														>
															Next
														</Button>
													)}
													{activeStep == 1 && (
														<Button
															onClick={handleCoreIndicators}
															className='next-btn'
															disabled={messagePhone == 'OK'}
														>
															Next
														</Button>
													)}
													{activeStep == 2 && (
														<Button
															onClick={handleOutcomeAreas}
															className='next-btn'
															disabled={messagePhone == 'OK'}
														>
															Next
														</Button>
													)}
													{activeStep == 3 ? (
														<>
															{stateChecker ? (
																<Button
																	disabled
																	variant='contained'
																	sx={{ textTransform: 'none' }}
																>
																	<CircularProgress size={17} color='inherit' />
																	&nbsp;Registering Partner...
																</Button>
															) : (
																<Button
																	onClick={handleApply}
																	className='next-btn'
																	disabled={messagePhone == 'OK'}
																>
																	Submit Application
																</Button>
															)}
														</>
													) : (
														<></>
													)}
												</Box>
											</React.Fragment>
										</div>
									</Box>

									<CssVarsProvider />
								</Grid>
								<Grid item md={5} xs={12} sx={{ pt: 0, mt: '16px' }}>
									<Typography component='body2' className='highlighter' />
									<Typography component='h5' className='meta-title'>
										Become a partner
									</Typography>
									<Typography component='h4' className='main-title'>
										Register here
									</Typography>

									<Box sx={{ mt: 4 }}>
										<img
											src={RegisterPartner}
											alt='register-wsp'
											style={{ height: '582px', borderRadius: '2px' }}
										/>
									</Box>
								</Grid>
							</>
						)}
					</Grid>
					<Otp
						open={openOtp}
						handleClickOpen={handleOpenOTP}
						handleClose={handleCloseOTP}
						num={partner.usrSalt}
						iD={partner.usrId}
						handleComplete={handleComplete}
					/>
				</Container>
			</Box>
			<Signin
				open={openLogIn}
				handleClickOpen={handleClickOpenLogIn}
				handleClose={handleCloseLogIn}
			/>
		</>
	);
};

export default SignupImpactFunder;
