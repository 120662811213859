import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import techAdvService from './techAdvService';


const initialState = {
    projectDetails: {},
    technicalDetails: {},

    isError: false,
    isProcessing: false,
    isLoading: false,
    isSuccess: false,
    message: '',
    messageAcc: '',
    messageBaic:'',

    isErrorBasic: false,
    isProcessingBasic: false,
    isLoadingBasic: false,
    isSuccessBasic: false,

    isErrorAcc: false,
    isProcessingAcc: false,
    isLoadingAcc: false,
    isSuccessAcc: false,

    isErrorRegFile: false,
    isProcessingRegFile: false,
    isLoadingRegFile: false,
    isSuccessRegFile: false,


    techDetails: {
        usrId: null,
        usrRoleId: 3,
        usrEmail: '',
        usrFirstName: '',
        usrLastName: '',
        usrFullNames: '',
        usrMobileNumber: '',
        usrTitle:''
    },
    orgDetails: {
        accTradeName: '',
        accWebsite: '',
        accRegNo: '',
        accPinCert: '',
        accRegCert: '',
        usrEncryptedPassword: '',
        usrConfirmPassword: '',
        accIcon: '',
        accEmail: '',
        accPhoneNumber: '',
        accZipCode: '',
        accLocation: '',
    },

    regFile: {
        nameReg: '',
        dateReg: '',
        file_base64Reg: undefined,
        typeReg: '',
    },

    taxFile: {
        nameTax: '',
        dateTax: '',
        file_base64Tax: undefined,
        typeTax: '',
    },

    regCertFile: {},
    taxCertFile: {},
    errorPhone: true,
    errorEmail: true,
    errorPassword: null,
    isCleanupForm: false,

   

}

export const registerTech = createAsyncThunk('techAdv/register', async (formData, thunkAPI) => {
    try {
        return await techAdvService.registerTech(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const registerTechDetails = createAsyncThunk('techAdv/project_details', async (formData, thunkAPI) => {
    try {
        return await techAdvService.registerTechDetails(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const onboardRegFile = createAsyncThunk('techAdv/onboardRegFile', async (formData, thunkAPI) => {
    try {
        return await techAdvService.techFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const onboardTaxFile = createAsyncThunk('techAdv/onboardTaxFile', async (formData, thunkAPI) => {
    try {
        return await techAdvService.techFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const fetchAccountInfo = createAsyncThunk('techAdv/fetchAccountInfo', async (accId, thunkAPI) => {
    try {
        return await techAdvService.fetchAccountInfo(accId)
    } catch (err) {
        const message = handleFailure(err)
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateProfileImage = createAsyncThunk('techAdv/updateProfileImage', async (formData, thunkAPI) => {
    try {
        return await techAdvService.updateProfileImage(formData)
    } catch (err) {
        const message = handleFailure(err)
        return thunkAPI.rejectWithValue(message)
    }
})

export const editProfileImage = createAsyncThunk('techAdv/editProfileImage', async (formData, thunkAPI) => {
    try {
        return await techAdvService.editProfileImage(formData)
    } catch (err) {
        const message = handleFailure(err)
        return thunkAPI.rejectWithValue(message)
    }
})


export const techAdvSlice = createSlice({
    name: 'techAdv',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isProcessing = false
            state.isError = false
            state.isSuccess = false
            state.isErrorBasic = false
            state.isProcessingBasic = false
            state.isLoadingBasic = false
            state.isSuccessBasic = false
            state.isErrorAcc = false
            state.isProcessingAcc = false
            state.isLoadingAcc = false
            state.isSuccessAcc = false
            state.isErrorRegFile = false
            state.isProcessingRegFile = false
            state.isLoadingRegFile = false
            state.isSuccessRegFile = false

            state.message = ''
            state.messageAcc = ''
            state.messageBaic=''
            state.isCleanupForm = false
        },
        setTechDetails: (state, action) => {
            state.techDetails = action.payload
        },
        setOrgDetails: (state, action) => {
            state.orgDetails = action.payload
        },
        setRegFile: (state, action) => {
            state.regFile = action.payload
        },
        setTaxFile: (state, action) => {
            state.taxFile = action.payload
        },
        setPhoneErrorDetails: (state, action) => {
            state.errorPhone = action.payload
        },
        setEmailErrorDetails: (state, action) => {
            state.errorEmail = action.payload
        },
        setPasswordErrorDetails: (state, action) => {
            state.errorPassword = action.payload
        },
        
        
        cleanupRegFilePdf: (state) => {
            state.regCertFile = initialState.regCertFile
        },
        cleanupForm: (state) => {
            state.techDetails = initialState.techDetails
            state.orgDetails = initialState.orgDetails
            state.regFile = initialState.regFile
            state.technicalDetails = initialState.technicalDetails
            state.projectDetails = initialState.projectDetails
            state.regCertFile = initialState.regCertFile
            state.taxCertFile = initialState.taxCertFile
        },
      
        
    },
    extraReducers: (builder) => {
        builder

            // tech_user details

            .addCase(registerTech.pending, (state) => {
                state.isProcessingBasic = true
            })
            .addCase(registerTech.fulfilled, (state, action) => {
                state.isProcessingBasic = false
                state.isSuccessBasic = true
                state.technicalDetails = action.payload
                state.messageBaic = "Details Registered Successfully."
            })
            .addCase(registerTech.rejected, (state, action) => {
                state.isProcessingBasic = false
                state.isErrorBasic = true
                state.technicalDetails = {}
                state.messageBaic = action.payload
            })

            // tech_user proj details
            .addCase(registerTechDetails.pending, (state) => {
                state.isProcessingAcc = true
            })
            .addCase(registerTechDetails.fulfilled, (state, action) => {
                state.isProcessingAcc = false
                state.isSuccessAcc = true
                state.projectDetails = action.payload
                state.messageAcc = "Account Registered Successfully."
            })
            .addCase(registerTechDetails.rejected, (state, action) => {
                state.isProcessingAcc = false
                state.isErrorAcc = true
                state.projectDetails = {}
                state.messageAcc = action.payload
            })

            // Reg File
            .addCase(onboardRegFile.pending, (state) => {
                state.isProcessingRegFile = true
            })
            .addCase(onboardRegFile.fulfilled, (state, action) => {
                state.isProcessingRegFile = false
                state.isSuccessRegFile = true
                state.regCertFile = action.payload
                state.message = "File Successfully Uploaded."
                state.isCleanupFile = true
            })
            .addCase(onboardRegFile.rejected, (state, action) => {
                state.isProcessingRegFile = false
                state.isErrorRegFile = true
                state.regCertFile = {}
                state.message = action.payload
                state.isCleanupFile = false
            })

            //Tax file
            .addCase(onboardTaxFile.pending, (state) => {
                state.isProcessing = true
            })
            .addCase(onboardTaxFile.fulfilled, (state, action) => {
                state.isProcessing = false
                state.isSuccess = true
                state.taxFile = action.payload
                state.message = "File Successfully Uploaded."
            })
            .addCase(onboardTaxFile.rejected, (state, action) => {
                state.isProcessing = false
                state.isError = true
                state.taxFile = {}
                state.message = action.payload
            })

            // fetch Account Information
            .addCase(fetchAccountInfo.pending, (state) => {
                state.isProcessingBasic = true
            })
            .addCase(fetchAccountInfo.fulfilled, (state, action) => {
 
                state.isProcessingBasic = false
                state.orgDetails.accTradeName = action.payload?.accTradeName
                state.orgDetails.accWebsite = action.payload?.accWebsite
                state.orgDetails.accRegNo = action.payload?.accRegNo
                state.orgDetails.accPinCert = action.payload?.accPinCert
                state.orgDetails.accRegCert = action.payload?.accRegCert
                state.orgDetails.accIcon = action.payload?.accIcon
                state.orgDetails.accEmail = action.payload?.accEmail
                state.orgDetails.accPhoneNumber = action.payload?.accPhoneNumber
                state.orgDetails.accZipCode = action.payload?.accZipCode
                state.orgDetails.accLocation = action.payload?.accLocation
                state.techDetails.usrFullNames = action.payload?.usrFullNames
                state.techDetails.usrFirstName = action.payload?.usrFirstName
                state.techDetails.usrLastName = action.payload?.usrLastName
                state.techDetails.usrTitle = action.payload?.usrTitle
                state.techDetails.usrMobileNumber = action.payload?.usrMobileNumber
                state.techDetails.usrEmail = action.payload?.usrEmail
                state.isSuccessBasic = true

                state.message = "Details Registered Successfully."
            })
            .addCase(fetchAccountInfo.rejected, (state, action) => {
                state.isProcessingBasic = false
                state.isErrorBasic = true
                state.message = action.payload
            })

            // Add image URL to USer Data 
            .addCase(updateProfileImage.pending, (state) => {
                state.isProcessingBasic = true
            })
            .addCase(updateProfileImage.fulfilled, (state, action) => {

                state.isProcessingBasic = false
                state.isSuccessBasic = true
                state.orgDetails.accIcon = action.payload
                state.message = "Details Registered Successfully."
            })
            .addCase(updateProfileImage.rejected, (state, action) => {
                state.isProcessingBasic = false
                state.isErrorBasic = true
                state.message = action.payload
            })


            // upload profile image
            .addCase(editProfileImage.pending, (state) => {
                state.isProcessingBasic = true
            })
            .addCase(editProfileImage.fulfilled, (state, action) => {

                state.isProcessingBasic = false
                state.isSuccessBasic = true
                state.orgDetails.accIcon = action.payload
                state.message = "Details Registered Successfully."
            })
            .addCase(editProfileImage.rejected, (state, action) => {
                state.isProcessingBasic = false
                state.isErrorBasic = true
                state.message = action.payload
            })

    }
})

export const {
    reset,
    setOrgDetails,
    setRegFile, setTaxFile,
    setTechDetails,
    cleanupForm,
    setPhoneErrorDetails,
    setEmailErrorDetails,
    setPasswordErrorDetails,
    cleanupRegFilePdf,
    
 } = techAdvSlice.actions
export default techAdvSlice.reducer