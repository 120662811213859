import { Box, Button, Typography } from '@mui/joy'
import { CircularProgress, Divider, Grid, Paper,Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchLoanTermConditionsPartnerBidsSingle, reset, updateLoanTermConditionsPartnerBids } from '../../features/deal_sourcing/dealSourcingSlice'
import RejectDialog from '../Deal_room_modals/RejectDialog'

function ViewTermsheet() {
    const dispatch = useDispatch()

    const { id } = useParams();
    const { isSuccessUpdateBids, partnerBidsSingle, isErrorUpdateBids, message, isProcessingUpdateBids } = useSelector(state => state.dealSourcing)
    const { user } = useSelector(state => state.auth)


 
    const submitAcceptance = (e) => {
        dispatch(updateLoanTermConditionsPartnerBids({
            ltcId: id,
            ltcStatus: "ACCEPTED"
        }))
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        if (isSuccessUpdateBids){
            dispatch(fetchLoanTermConditionsPartnerBidsSingle({
                ltcId: id
            }))
        }
        dispatch(fetchLoanTermConditionsPartnerBidsSingle({
            ltcId: id
        }))
     
    }, [dispatch, id,isSuccessUpdateBids])

    useEffect(() => {
        if (isSuccessUpdateBids) {
            toast.success("Bids status updated successfully")
        }
        if (isErrorUpdateBids && message) {
            toast.warning(message)
        }
        return () => {
            dispatch(reset())
        }

    }, [dispatch, isSuccessUpdateBids, isErrorUpdateBids, message])
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography component="h3" className="fmw-page-title">
                        Offers: {partnerBidsSingle.accTradeName}
                    </Typography>
                    {
                       isProcessingUpdateBids ? (<>
                        <Skeleton width={140} />
                       </>):(<>
                        {
                        partnerBidsSingle.ltcStatus =="ACTIVE"?(<>   {
                            user.usrJbrnId == 1 ?
                            <Box>
                            {!isProcessingUpdateBids ? (
                                <Button
                                    sx={{ mr: 3 }}
                                    className="fmw-btn-secondary-auto"
                                    onClick={submitAcceptance}>
                                    Accept deal
                                </Button>
                            ) :
    
                                (
                                    <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                        <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                    </Button>
                                )}
    
                            <Button className="fmw-btn-secondary-neutral" onClick={handleClickOpen}>Reject deal</Button>
                        </Box>
                            :
                            null
                            
                        }</>):(null)
                    }
                       </>) 
                    }
                   
                 
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Divider />
                </Grid>

                <Grid item xs={7}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                        <Box sx={{ backgroundColor: '#475459' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Conditions
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{
                                        p: 1.5,
                                        height: '100%', borderRight: '1px solid #0000001f'
                                    }}>
                                        Borrower
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.wsp}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Loan Amount
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcLoanAmnt}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Purpose
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcPurpose}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Maximum loan amount as a percentage of cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcMaxLnAmnt}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Minimum equity amount as a percentage of cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcMaxEqtyAmnt}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Maximum loan tenure
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcMaxLnTenure}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Interest rate
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcLoanInterest}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Interest during grace period
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcGraceRate}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Basis of charging interest
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcInterestBasis}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Loan repayment installations
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcInstallment}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Mode of payment (loan repayment)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcPaymentMode}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Loan disbursement
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcDisbursement}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Loan appraisal fee
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Loan appraisal fee
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Mode of payment (appraisal fee)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcAppraisalMode}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Other terms
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcOthersTerms}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Contractual savings
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcContactualSavings}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Security of financial institutions
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        {partnerBidsSingle.ltcSecurity}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>


                    </Paper>
                </Grid>
            </Grid>
            <RejectDialog open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} id={id} />
        </>
    )
}

export default ViewTermsheet