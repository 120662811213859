import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import ChoiceSelector from '../ChoiceSelector';
import { Input, Textarea, Typography } from '@mui/joy';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	fetchObjector,
	saveCommittedDetails,
	saveObjector,
} from '../../../features/committed_project/committedSlice';
import CustomChoice from '../../../components/custom_components/CustomChoice';

const url = `${process.env.REACT_APP_API_BASE_URL}`;

export default function EnvironmentalTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd, objectors } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);
	const [formDatas, setFormDatas] = useState({});

	const handleObjectorChange = async (e) => {
		await setFormDatas((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleChange = async (e) => {
		await setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function getFile1(data) {
		setFormData((prevState) => ({
			...prevState,
			wrNemaLicenseFile: data,
		}));
	}

	function getFile2(data) {
		setFormData((prevState) => ({
			...prevState,
			wrEiaReportFile: data,
		}));
	}

	async function fetchNewData() {
		await dispatch(
			fetchObjector({
				obProjId: id,
			}),
		);

		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleFileChnages() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	async function handleInputChanges() {
		formDatas.obProjId = id;
		if (
			formDatas?.obName &&
			formDatas?.obCompany &&
			formDatas?.obPosition &&
			formDatas?.obContact
		) {
			// await dispatch(saveObjector(formDatas));
			await fetch(`${url}/usr/saveObjector.action`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify(formDatas),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					if (data?.success) {
						dispatch(
							fetchObjector({
								obProjId: id,
							}),
						);
					}
				});

			await setFormDatas({});

			await dispatch(
				fetchObjector({
					obProjId: id,
				}),
			);
		}
	}


	let file1 = formData?.wrNemaLicenseFile
		?.substring(formData?.wrNemaLicenseFile?.lastIndexOf('/'))
		.split('_');

	let file2 = formData?.wrEiaReportFile
		?.substring(formData?.wrEiaReportFile?.lastIndexOf('/'))
		.split('_');

	useEffect(() => {
		handleFileChnages();
	}, [formData]);

	useEffect(() => { }, [formData, id, committedd, formDatas, objectors]);

	useEffect(() => {
		fetchNewData();
	}, []);

	// console.log(document.querySelector('.act'));

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						className='basic'
						sx={{
							borderBottom: '2px solid var(--color-light-blue) !important',
						}}
					>
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Type of EIA report required
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<CustomChoice
									options={[
										{ value: 'None', label: 'None' },
										{ value: 'SPR', label: 'SPR' },
										{ value: 'CPR', label: 'CPR' },
									]}
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEiaReportType'}
									choiceValue={formData?.wrEiaReportType}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								NEMA license available
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrNemaLicenseAvailable'}
									choiceValue={formData?.wrNemaLicenseAvailable}
								// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile1}
									title={file1?.length ? file1[1] : 'Attach license'}
									file={file1[1]}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								EIA report provided
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrEiaReportProvided'}
									choiceValue={formData?.wrEiaReportProvided}
								// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile2}
									title={file2?.length ? file2[1] : 'Attach report'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Are all stakeholders aware of project?
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrStakeholdersAware'}
									choiceValue={formData?.wrStakeholdersAware}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Grid container spacing={2} sx={{ padding: '0px 16px' }}>
				<Grid item xs={12}>
					<Typography
						sx={{ mt: '0px', mb: 1, mt: 4, fontSize: '16px', fontWeight: 400 }}
					>
						Who may object to project?
					</Typography>

					{/* <button onClick={handleSubmitFormDatas} type='button'>
						SUBMIT
					</button> */}
				</Grid>
			</Grid>

			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Name</TableCell>
							<TableCell align='left'>Company</TableCell>
							<TableCell align='left'>Position</TableCell>
							<TableCell align='left'>Contact</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						className='basic'
						sx={{
							borderBottom: '2px solid var(--color-light-blue) !important',
						}}
					>
						{objectors?.map((item) => {
							return (
								<>
									<TableRow key={item?.ObId}>
										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											<Input
												readOnly={true}
												value={item?.obName}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>

										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											<Input
												readOnly={true}
												value={item?.obCompany}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>

										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											<Input
												readOnly={true}
												value={item?.obPosition}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>

										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											<Input
												readOnly={true}
												value={item?.obContact}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>
									</TableRow>
								</>
							);
						})}
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<Input
									disabled={viewOnly}
									onBlur={handleInputChanges}
									onChange={handleObjectorChange}
									name='obName'
									value={formDatas?.obName}
									className='fmw-input-joy-mitigation act'
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<Input
									disabled={viewOnly}
									onBlur={handleInputChanges}
									onChange={handleObjectorChange}
									name='obCompany'
									value={formDatas?.obCompany}
									className='fmw-input-joy-mitigation act'
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<Input
									disabled={viewOnly}
									onBlur={handleInputChanges}
									onChange={handleObjectorChange}
									name='obPosition'
									value={formDatas?.obPosition}
									className='fmw-input-joy-mitigation act'
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<Input
									disabled={viewOnly}
									onBlur={handleInputChanges}
									onChange={handleObjectorChange}
									name='obContact'
									value={formDatas?.obContact}
									className='fmw-input-joy-mitigation act'
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
