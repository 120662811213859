export const PositionData = [
    {
        value: 'Chairman',
        label: 'Chairman',
    },
    {
        value: 'V-Chairman',
        label: 'V-Chairman',
    },
    {
        value: 'Treasurer',
        label: 'Treasurer',
    },
    {
        value: 'Secretary',
        label: 'Secretary',
    },
    {
        value: 'V-Secretary',
        label: 'V-Secretary',
    },
    {
        value: 'Committee Member',
        label: 'Committee Member',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]

export const filterDataConnections = [
    {
        value: 'Total Score',
        label: 'Total Score',
    },
    {
        value: 'Active Connections',
        label: 'Active Connections',
    },
    {
        value: 'Loan Amount',
        label: 'Loan Amount',
    },
]

export const filterDataComparisons = [
    {
        value: 'greater than',
        label: 'greater than',
    },
    {
        value: 'less than',
        label: 'less than',
    },
 
]


export const AdequacyData = [
    {
        value: 'Total resource without CF project sufficient to meet demand',
        label: 'Total resource without CF project sufficient to meet demand',
    },
    {
        value: 'Total resource only sufficient to meet demand with CF project',
        label: 'Total resource only sufficient to meet demand with CF project',
    },
    {
        value: 'Total resource not sufficient to meet demand even with CF project',
        label: 'Total resource not sufficient to meet demand even with CF project',
    },
]

export const reliabilityData = [
    {
        value: 'No seasonal change',
        label: 'No seasonal change',
    },
    {
        value: 'Seasonal change but not likely to affect BP',
        label: 'Seasonal change but not likely to affect BP',
    },
    {
        value: 'Seasonal change likely to affect supply',
        label: 'Seasonal change likely to affect supply',
    },
    {
        value: 'Seasonal change factored into BP',
        label: 'Seasonal change factored into BP',
    },
 
]