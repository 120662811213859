import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import ImageInput from '../../../components/committed_project/ImageInput';
import ChoiceSelector from '../ChoiceSelector';
import { Textarea, Typography } from '@mui/joy';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';
import { useEffect } from 'react';

export default function MitigationTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);
	const [filename, setfilename] = useState(
		committedd?.wrRiskMitigationPlanFile,
	);

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleRiskMitigation() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	function getRiskUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			wrRiskMitigationPlanFile: data,
		}));
	}

	useEffect(() => {
		handleRiskMitigation();
	}, [formData]);

	useEffect(() => {
		if (committedd?.wrRiskMitigationPlanFile) {
			setfilename(committedd?.wrRiskMitigationPlanFile);
		}
	}, [committedd?.wrRiskMitigationPlanFile]);

	useEffect(() => {}, [formData, id, filename, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
							<TableCell align='left'>File</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						className='basic'
						sx={{
							borderBottom: '2px solid var(--color-light-blue) !important',
						}}
					>
						<TableRow>
							<TableCell
								component='th'
								scope='row'
								sx={{ borderLeft: 'none !important', width: '33.33%' }}
							>
								Risk Mitigation Plan provided
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									choiceName={'wrRiskMitigationPlan'}
									choiceValue={formData?.wrRiskMitigationPlan}
									getChoiceData={handleChange}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '50%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getRiskUrl}
									title={
										threeValues?.length
											? threeValues[1]
											: 'Attach mitigation plan'
									}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
