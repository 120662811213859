import React, { useEffect, useState } from "react";
import { Typography } from "@mui/joy";
import { Box, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material";
import SingleItem from "../../components/single_item";
import { useLocation, useParams } from "react-router-dom";
import TextareaComment from "../../components/TextareaComment";
import CapitalTable from "./CapitalTable";
import BudgetTable from "./BudgetTable";
import FinanceTable from "./FinanceTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectScore, postScoreStep, setProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import { fetchBudgetInfo } from "../../../../features/onboard_partner/onboardPartnerSlice";
import { cashConverter } from "../../../../utils";
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import { toast } from "react-toastify";

const FinancialType = () => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var commentType =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi tristique senectus et netus et malesuada fames. Scelerisque eleifend donec pretium vulputate. Sagittis nisl rhoncus mattis rhoncus urna. Aliquam ut porttitor leo a diam sollicitudin tempor. Eget felis eget nunc lobortis mattis aliquam faucibus.";
  const [formData, setFormData] = useState({
    trust: '',
    comment1: ''
  })
  const { trust, comment1 } = formData
  const handleChangeInput = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };
  const category = location.pathname.split('/')[3]

  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );

  const { id } = useParams();

  const data = projectData?.find(
    (item) => item?.projId === Number(id)
  );

  const scores = useSelector((state) => state.expressionDemandData.scores);
  const { budgetInfo, isLoadingBudget } = useSelector(
    (state) => state.onboardPartner
  );

  const stepName = "LOAN_INFO";
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});

  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  useEffect(() => { }, [matrixInfo, scores, budgetInfo]);

  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const matrixFields = ["projOtherPartyFinance", "projBillingSystem"];

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;

      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const {
    projProjCategory,
    projDescSubProj,
    projProbSolved,
    projWhoBenefit,
    projEstCost,
    projLoanAmount,
    projLoanYears,
    projMoneySource,
    projOtherPartyFinance,
    projInformGovYesFile,
    projInformGov,
    projWspInformed,
    projLoanSum,
    projLoanCalculated,
    projSswpSum,
    projSswpCalculated,
    proj3rdpartySum,
    proj3rdpartyCalculated,
    projTotalSum,
    projTotalCalculated,
    projWspInformFile,
    projId,
    projPurpose,
    projBillingSystem,
  } = data;
  function createTable(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  function budgetTable(item, budget, comment, variableName) {
    return { item, budget, comment, variableName };
  }


  const finacialDetails = [
    createTable(
      "Description of sub-project for which the loan is applied",
      projDescSubProj,
      "projDescSubProj"
    ),
    createTable("Problem being solved", projProbSolved, "projProbSolved"),
    createTable("Beneficiaries", projWhoBenefit, "projWhoBenefit"),
    createTable(
      "Total estimated project cost",
      projEstCost ? cashConverter(projEstCost) : "N/A",
      "projEstCost"
    ),
    createTable(
      "Amount of loan required",
      projLoanAmount ? cashConverter(projLoanAmount) : "N/A",
      "projLoanAmount"
    ),
    createTable("Purpose of the Project", projPurpose, "projPurpose"),

    createTable(
      "How funds will be generated to repay the loan",
      projMoneySource,
      "projMoneySource"
    ),
    createTable(
      "Has county government been informed",
      projInformGov,
      "projInformGov"
    ),

  ];

  const finacialDetails2 = [
    createTable(
      "Source of 3rd party financing",
      projOtherPartyFinance,
      "projOtherPartyFinance",
      10
    ),
  ];

  const financialAttachment = [
    createTable(
      "County government Information File",
      projInformGovYesFile,
      "projInformGovYesFile",
      10
    ),
  ];

  const fetchReduxData = () => {
    dispatch(
      fetchBudgetInfo({
        projId: projId,
      })
    );
  };



  useEffect(() => {
    fetchReduxData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchReduxData();
    }, 1000);
  }, []);

  useEffect(() => {

  }, [budgetInfo, scores]);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const typeOfFinancing = [
    budgetTable("Loans", projLoanSum, projLoanCalculated, "projLoanSum"),
    budgetTable("SSP", projSswpSum, projSswpCalculated, "projSswpSum"),
    budgetTable(
      "3rd Party",
      proj3rdpartySum,
      proj3rdpartyCalculated,
      "proj3rdpartySum"
    ),
    budgetTable("Total", projTotalSum, projTotalCalculated, "projTotalSum"),
  ];

  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Loan information
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <FinanceTable data={data} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Loan information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>

                <Table>
                  <TableBody>
                    {
                      finacialDetails?.map((row) => (
                        <TableRow
                        >
                          <TableCell component='th' scope='row'
                            className="gap-5"
                            sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <span>{row?.name}</span>
                            <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                            <span>{row?.description}</span>
                          </TableCell>

                        </TableRow>
                      ))
                    }
                  </TableBody>

                  {
                    data?.projInformGov === "Yes" &&
                    <TableBody>
                      {
                        financialAttachment?.map((row) => (
                          <TableRow
                          >
                            <TableCell component='th' scope='row'
                              className="gap-5"
                              sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                              <span>{row?.name}</span>
                              <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                              <span>
                                <SingleAttachmentComplete
                                  title={row?.description}
                                  tableFile="true"
                                />
                              </span>
                            </TableCell>

                          </TableRow>
                        ))
                      }
                    </TableBody>
                  }
                </Table>

                {
                  finacialDetails2?.map((row) => (
                    <SingleItem
                      row={row}
                      userComment={userComment}
                      setUserComment={setUserComment}
                      setMatrixInfo={setMatrixInfo}
                      matrixInfo={matrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={data?.projId}
                      setOnblur={setOnBlur}
                      onBlur={onBlur}
                      maxScores={row.maxScores}
                      status={category}
                      comment={comment}
                      setComment={setComment}
                      setUserMatrixInfo={setUserMatrixInfo}
                      userMatrixInfo={userMatrixInfo}
                    />
                  ))
                }

              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel2bh-content'
          id='panel2bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '50%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Capital sources
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <CapitalTable data={data} />
        </AccordionDetails>
      </Accordion>



      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel3bh-content'
          id='panel3bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Main budget items
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <BudgetTable />
        </AccordionDetails>
      </Accordion>



      {(category === "COMMITTED" ||
        category === "DEAL_SOURCING" ||
        category == 'Assistance' ||
        category == 'poor' ||
        category === "Viable" ||
        category === "Partner") &&
        <TextareaComment
          onChange={(e) => setComment(e.target.value)}
          comment={comment}
          submitStep={submitStep}
        />
      }

    </Box>
  );
};

export default FinancialType;
