import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { dateConverter, formatImgPath, handleOpenDoc } from "../../../utils";
import CustomerInfo from "./CustomerInfo";
import ScrollToBottom from "../../messages/scrollToBottom";
import { Textarea } from "@mui/joy";
import MatrixInput from "../components/MatrixInput";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectScore,
  getDirectorInformation,
  postScoreStep,
  setProjectScore,
} from "../../../features/expression_demand/expressionDemandSlice";
import { toast } from "react-toastify";
import FileButton from "../../../components/button/FileButton";
import {
  fetchDirectors,
  fetchManagementTool,
} from "../../../features/onboard_partner/onboardPartnerSlice";
import NoTableDataComponent from "./NoTableDataComponent";
import SingleComment from "../components/SingleComment";
import UserMatrix from "../components/UserMatrix";

const GovernanceInfo = ({ data, title, status }) => {
  const { isLoading, director } = useSelector((state) => state.onboardPartner);
  const { scores, loading, directorsInformation } = useSelector(
    (state) => state.expressionDemandData
  );
  const { sLoadingManagementTool, managementTool } = useSelector(
    (state) => state.onboardPartner
  );
  const [dirLoading, setDirLoading] = useState(false);
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();
  const stepName = "GOVERNANCE_INFO";
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const agmDate = data.projAgmMinutesDate
    ? dateConverter(data.projAgmMinutesDate)
    : "N/A";

  function createData(
    name,
    description,
    variableName,
    maxScores,
    userScore,
    userComment
  ) {
    return {
      name,
      description,
      variableName,
      maxScores,
      userScore,
      userComment,
    };
  }

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const fetchProjectScores = async () => {
    setDirLoading(true);
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));

    const res = await dispatch(
      fetchManagementTool({
        projId: data.projId,
      })
    );

    dispatch(
      fetchDirectors({
        projId: data.projId,
      })
    );

    setDirLoading(false);
  };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      // console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  // useEffect(() => {
  //   fetchReduxData();
  // }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     fetchReduxData();
  //   }, 100);
  // }, []);

  const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);
  const page2 = [
    createData("Type of registration", data.projRegType, "projRegType", 5),
    createData("Registration number", data.projRegNo, "projRegNo"),
    createData("Registration number", data.projRegNo, "projRegNo"),
    createData(
      "Date of registration",
      data.projRegDate ? dateConverter(data.projRegDate) : "N/A",
      "projRegDate"
    ),
    createData("KRA pin", data.projKraPin, "projKraPin"),
    createData(
      "Period the organization has been providing water services",
      data.projOrgPeriod,
      "projOrgPeriod",
      5
    ),
  ];

  const attachments = [
    createData(
      "1st statutory returns",
      data.projStatu1File,
      "projStatu1File",
      1
    ),
    createData(
      "2nd statutory returns",
      data.projStatu2File,
      "projStatu2File",
      2
    ),
    createData(
      "3rd statutory returns",
      data.projStatu3File,
      "projStatu3File",
      2
    ),
  ];

  const agmAttachments = [
    createData(
      `Minutes of last AGM held on ${agmDate}`,
      data.projMinAgmFile,
      "projMinAgmFile",
      10
    ),
    createData(
      "PDF of constitution/bylaws or articles of association",
      data.projAssocArtFile,
      "projAssocArtFile",
      5
    ),
    createData(
      "PDF copy of minute stating project willing to seek a loan",
      data.projSgmFile,
      "projSgmFile"
    ),
    createData(
      `Minutes of First committee meeting held on ${data.projMin1Date ? dateConverter(data.projMin1Date) : ""
      }`,
      data.projMin1File,
      "projMin1File",
      1
    ),
    createData(
      `Minutes of second committee meeting held on ${data.projMin2Date ? dateConverter(data.projMin2Date) : ""
      }`,
      data.projMin2File,
      "projMin2File",
      1
    ),
    createData(
      `Minutes of third committee meeting held on ${data.projMin3Date ? dateConverter(data.projMin3Date) : ""
      }`,
      data.projMin3File,
      "projMin3File",
      1
    ),
  ];

  const managementTools = managementTool?.map((item) => {
    let newItem = {};
    if (item.mtType === "Operation and Maintenance Plan") {
      newItem = {
        ...item,
        variableName: "operationAndMaintenancePlan",
        maxScores: 1,
      };
    } else if (item.mtType === "Strategic Plan") {
      newItem = { ...item, variableName: "strategicPlan", maxScores: 1 };
    } else if (item.mtType === "Marketing Plan") {
      newItem = { ...item, variableName: "marketingPlan", maxScores: 1 };
    } else if (item.mtType === "Customer Contract") {
      newItem = { ...item, variableName: "customerContract", maxScores: 1 };
    } else if (item.mtType === "Other") {
      newItem = {
        ...item,
        variableName: "other",
        maxScores: 1,
        mtType: "Other Management tools",
      };
    }
    return newItem;
  });

  return (
    <>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        {title}
      </Typography>
      <Table aria-label="customized table">
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {page2.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                (row.variableName === "projRegType" ||
                  row.variableName === "projOrgPeriod") && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    system={true}
                  />
                )}
              {status === "Viable" &&
                (row.variableName === "projRegType" ||
                  row.variableName === "projOrgPeriod") && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}

              {(status === "Viable" || status === "Partner") &&
                (row.variableName === "projRegType" ||
                  row.variableName === "projOrgPeriod") && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    status={status}
                    comment={comment}
                    setComment={setComment}
                  />
                )}
            </TableRow>
          ))}
          {attachments.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                <FileButton file={row.description} />
              </TableCell>
              {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={row.maxScores}
                />
              )}
              {status === "Viable" && (
                <UserMatrix
                  setMatrixInfo={setUserMatrixInfo}
                  matrixInfo={userMatrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={row.maxScores}
                />
              )}
              {(status === "Viable" || status === "Partner") && (
                <SingleComment
                  setMatrixInfo={setUserComment}
                  matrixInfo={userComment}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={row.maxScores}
                  status={status}
                />
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Annual general meeting information
      </Typography>
      <Table aria-label="customized table">
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          <TableRow
            key={"AgmDate"}
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
            style={{ borderBottom: "1px solid #E4E4E4" }}
          >
            <TableCell
              component="th"
              scope="row"
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
                color: "#646464",
              }}
            >
              Date of AGM
            </TableCell>
            <TableCell
              sx={{
                borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                color: "#646464",
              }}
            >
              {agmDate}
            </TableCell>
          </TableRow>

          {agmAttachments.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                }}
              >
                {/* <Button
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => handleOpenDoc(formatImgPath(row.description))}
                >
                  <Typography sx={{ display: "flex", gap: 1 }}>
                    <AttachFileIcon
                      sx={{
                        transform: "rotate(45deg)",
                      }}
                    />
                    View File
                  </Typography>
                </Button> */}
                <FileButton file={row.description} />
              </TableCell>
              {status === "Viable" && row.variableName !== "projSgmFile" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={row.maxScores}
                />
              )}
              {status === "Viable" && row.variableName !== "projSgmFile" && (
                <UserMatrix
                  setMatrixInfo={setUserMatrixInfo}
                  matrixInfo={userMatrixInfo}
                  name={row.variableName}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={row.maxScores}
                />
              )}
              {(status === "Viable" || status === "Partner") &&
                row.variableName !== "projSgmFile" && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    status={status}
                    comment={comment}
                    setComment={setComment}
                  />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Details of management committee members/ directors
      </Typography>
      {dirLoading && director?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Name
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Position
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Telephone
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Duration(Years)
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Attachments
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {director?.map((row) => (
              <TableRow
                key={row.dirId}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.dirName}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.dirPosition}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.dirPhone}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.dirDuration}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {/* <Button
                    variant="outlined"
                    style={{ textTransform: "capitalize" }}
                    onClick={() =>
                      handleOpenDoc(formatImgPath(row.dirCopyOfId))
                    }
                  >
                    <Typography sx={{ display: "flex", gap: 1 }}>
                      <AttachFileIcon
                        sx={{
                          transform: "rotate(45deg)",
                        }}
                      />
                      View File
                    </Typography>
                  </Button> */}

                  <FileButton file={row.dirCopyOfId} />
                </TableCell>
              </TableRow>
            ))}
            {status === "Viable" && (
              <TableRow
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  Scores
                </TableCell>
                {status === "Viable" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name="director"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={5}
                  />
                )}
                {status === "Viable" && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name="director"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={5}
                  />
                )}
                {(status === "Viable" || status === "Partner") && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name="director"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    status={status}
                    comment={comment}
                    setComment={setComment}
                  />
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {!isLoading && director?.length === 0 && <NoTableDataComponent />}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Management tools
      </Typography>
      {sLoadingManagementTool && managementTools?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table aria-label="customized table">
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Item
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Details
              </TableCell>
              {status === "Viable" && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  System Matrix
                </TableCell>
              )}
              {status === "Viable" && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Technical Advisor Matrix
                </TableCell>
              )}
              {(status === "Viable" || status === "Partner") && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  comment
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {managementTools?.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.mtType}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  <FileButton file={row.mtFile} />
                </TableCell>
                {status === "Viable" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
                {status === "Viable" && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
                {(status === "Viable" || status === "Partner") && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                    status={status}
                    comment={comment}
                    setComment={setComment}
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!sLoadingManagementTool && managementTools?.length === 0 && (
        <NoTableDataComponent />
      )}
      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" ||
        status === "DEAL_SOURCING" ||
        status === "Viable" ||
        status === "Partner") && (
          <>
            <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
              Comments on above information
            </Typography>

            <Textarea
              disabled={
                status === "Partner" ||
                status === "COMMITTED" ||
                status === "DEAL_SOURCING"
              }
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              minRows={3}
              name="projSummary"
              placeholder={`${status === "Partner" ? "N/A " : "write here..."}`}
              size="lg"
              className="fmw-textarea"
              color={`${status === "Partner"
                } ? "red !important" : "#646464 !important"`}
              sx={{
                width: "100%",
              }}
            />

            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>

          </>
        )}
      Where is the Button
    </>
  );
};

export default GovernanceInfo;
