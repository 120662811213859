import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button, Typography } from '@mui/joy';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = ({ open, handleClickOpen, handleClose, handleApply }) => {
    const { isLoading } = useSelector((state) => state.onboardPartner)
 
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
               
            >
                <DialogTitle className="fmw-page-title">{"Submit Expression of Demand?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant='h5' component="h5" sx={{ fontSize: '1.05rem' }}>
                            Are you sure you want to submit?
                        </Typography>
                        <br />
                        <Typography component="p" sx={{ fontSize: '.95rem' }}>
                            NB: Once you agree to submit expression of demand you will not be able to edit your application again!.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='soft' onClick={handleClose}>Disagree</Button>
                    <Button
                        disabled={isLoading}
                        variant='soft'
                        onClick={handleApply}>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default ConfirmationDialog