import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import { FormHelperText, Grid, Input, Typography } from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  reset,
  updateWaterSrc,
} from "../../features/onboard_partner/onboardPartnerSlice";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import {
  MasterMeterData,
  PowerData,
  ReliabilityData,
  SysytemTypeData,
  WRA,
  WaterQualityData,
  WaterSourceData,
} from "../../Step3Data";
import WaterQuality from "../onboard_wsp_files/WaterQuality";
import MasterMeter from "../onboard_wsp_files/MasterMeter";
import AbstractionPermit from "../onboard_wsp_files/AbstractionPermit";
import PumpPicture from "../onboard_wsp_files/PumpPicture";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const WtrSrcEdit = ({ open, handleClickOpen, handleClose, wtrEdited }) => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isSuccessUpdateWtrSrc,
    isLoadingUpdateWtrSrc,
    isErrorWtrSrc,
    message,
    step3WtrAnalysisPdf,
    step3MtrReadingPdf,
    step3PermitPdf,
    step3PumpPdf,
  } = useSelector((state) => state.onboardPartner);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState("");

  const handleChange = (e) => {
    e.target.value < 0
      ? (e.target.value = 0)
      : setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
    setIsUpdating(true);
  };

  const updateWaterDetails = () => {
    dispatch(
      updateWaterSrc({
        wsId: formData.wsId,
        wsSource: formData.wsSource,
        wsReliability: formData.wsReliability,
        wsQuality: formData.wsQuality,
        wsMasterMtr: formData.wsMasterMtr,
        wsWra: formData.wsWra,
        wsSystem: formData.wsSystem,
        wsPower: formData.wsPower,

        wsQualityFile:
          step3WtrAnalysisPdf.length > 0 ? step3WtrAnalysisPdf : null,
        wsMtrFile: step3MtrReadingPdf.length > 0 ? step3MtrReadingPdf : null,
        wsWraFile: step3PermitPdf.length > 0 ? step3PermitPdf : null,
        wsPowerFile: step3PumpPdf.length > 0 ? step3PumpPdf : null,
      })
    );
  };

  useEffect(() => {
    setFormData(wtrEdited);
    if (isSuccessUpdateWtrSrc) {
      toast.success('Water Source Details updated successfully ');
      handleClose();
    }
    if (isErrorWtrSrc && message) {
      toast.warning(message);
    }
    return () => {
      dispatch(reset());
    };
  }, [dispatch, wtrEdited, isSuccessUpdateWtrSrc, isErrorWtrSrc, message]);
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        {isLoading ? (
          <>
            <p>loading...</p>
          </>
        ) : (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                component="h6"
                className="fmw-checkbox-label"
                style={{ display: "flex", alignItems: "center" }}
              >
                Editing:
                <Typography
                  sx={{ ml: 1 }}
                  component="p"
                  className="fmw-onboard-partner-sub-label-modal"
                >
                  {formData?.wsSource}
                </Typography>
              </Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Average volume abstracted per day from source m<sup>3</sup>
                  /day wet season
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsSource",
                      },
                    });
                  }}
                  placeholder="Select position *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsSource"
                  value={formData?.wsSource ? formData?.wsSource : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {WaterSourceData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Reliability of water source
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsReliability",
                      },
                    });
                  }}
                  placeholder="Select reliability*"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsReliability"
                  value={formData?.wsReliability ? formData?.wsReliability : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {ReliabilityData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Water quality at source
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsQuality",
                      },
                    });
                  }}
                  placeholder="Select position *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsQuality"
                  value={formData?.wsQuality ? formData?.wsQuality : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {WaterQualityData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <WaterQuality formData={wtrEdited} />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Is a master meter installed and functional ?
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsMasterMtr",
                      },
                    });
                  }}
                  placeholder="Select position *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsMasterMtr"
                  value={formData?.wsMasterMtr ? formData?.wsMasterMtr : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {MasterMeterData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <MasterMeter formData={wtrEdited} />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Is there a water regulatory authority (WRA) abstraction permit
                  ?
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsWra",
                      },
                    });
                  }}
                  placeholder="Select permit existence *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsWra"
                  value={formData?.wsWra ? formData?.wsWra : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {WRA?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <AbstractionPermit formData={wtrEdited} />
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  Type of system a pumped or gravity system or hybrid?
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsSystem",
                      },
                    });
                  }}
                  placeholder="Select position *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsSystem"
                  value={formData?.wsSystem ? formData?.wsSystem : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {SysytemTypeData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  component="p"
                  className="fmw-onboard-partner-sub-label"
                  sx={{ mb: ".5rem" }}
                >
                  If pumped, type of power used
                </Typography>
                <Select
                  className="fmw-select"
                  onChange={(e, newval) => {
                    handleChange({
                      target: {
                        value: newval,
                        name: "wsPower",
                      },
                    });
                  }}
                  placeholder="Select position *"
                  indicator={<KeyboardArrowDown />}
                  size="lg"
                  name="wsPower"
                  value={formData?.wsPower ? formData?.wsPower : ""}
                  sx={{
                    width: "100%",
                    [`& .${selectClasses.indicator}`]: {
                      transition: "0.2s",
                      [`&.${selectClasses.expanded}`]: {
                        transform: "rotate(-180deg)",
                      },
                    },
                  }}
                >
                  {PowerData?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <PumpPicture formData={wtrEdited} />
              </Grid>
            </DialogContent>
            <DialogActions>
              {!isLoadingUpdateWtrSrc ? (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--bg-primary) !important",
                  }}
                  onClick={updateWaterDetails}
                >
                  Update Wateer Source Details
                </Button>
              ) : (
                <Button
                  disabled
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  <CircularProgress size={17} color="inherit" />
                  &nbsp;Adding...
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </BootstrapDialog>
    </div>
  );
};
export default WtrSrcEdit;
