import { Box, Button, Typography } from "@mui/joy";
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProjectDetails from "./ProjectDetails";
import {
  fetchWspApplications,
  setProjIdSend,
} from "../../features/wsp/wspSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DealRoomSkeleton from "../deal_room_list/DealRoomSkeleton";
import Step1 from "../../pages/onboard_wsp/components/Step1";

function SupportProject({ button }) {
  const {
    wspApplications,
    isLoading,
    isLoadingWsp,
    isSuccess,
    isError,
    message,
  } = useSelector((state) => state.wsp);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openPD, setOpenPD] = useState(false);
  const handleClickOpenPD = () => {
    setOpenPD(true);
  };
  const handleCloseFS = () => {
    setOpenPD(false);
  };

  const viewWspOnboarding = (projId) => {
    dispatch(setProjIdSend(projId));
    navigate("/onboard-wsp");
  };
  const newWspApplications = wspApplications?.filter((supp) => supp.projStatus == "DRAFT" || supp.projStatus == "ACTIVE")
 

  useEffect(() => {
    if (!user) navigate("/");
    setTimeout(() => {
      dispatch(fetchWspApplications());
    }, 800);
  }, [dispatch, user, navigate]);

  return (
    <>
      {isLoadingWsp ? (
        <>
          <DealRoomSkeleton />
          <DealRoomSkeleton />
        </>
      ) : (
        <>
          {!newWspApplications?.length ? (
            <>
              <h1
                style={{
                  marginLeft: "30px",
                }}
              >
                No Project Available
              </h1>{" "}
            </>
          ) : (
            <>
              {newWspApplications.length &&
                newWspApplications?.map((support) => (
                  <>
                    <Grid item xs={12} style={{ paddingTop: "1.15rem" }}>
                      <Paper
                        sx={{ p: 0, display: "flex", flexDirection: "column" }}
                        variant="outlined"
                      >
                        <Box sx={{ p: 2, pb: 1 }}>
                          <Grid
                            container
                            spacing={4}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sx={{ mb: 1 }}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Box>
                                <Typography
                                  component="body2"
                                  className="list-avatar"
                                />
                              </Box>
                              <Box sx={{ mx: 2.5 }}>
                                <Typography
                                  component="h6"
                                  sx={{ fontSize: ".925rem" }}
                                  className="acc-title-fmw"
                                >
                                  Project: {support.accTradeName}
                                </Typography>
                                <Typography
                                  component="p"
                                  className="fmw-p"
                                  sx={{ mb: 1 }}
                                >
                                  {support.projDescSubProj
                                    ? support.projDescSubProj
                                    : "More information about the project will be displayed after you complete the form"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ my: 3 }}>
                              {support.projStatus == "PENDING_APPROVAL" ? (
                                <Button
                                  sx={{
                                    backgroundColor: "#26DEFF67 !important",
                                    color: "#fff",
                                  }}
                                  disabled
                                  color="neutral"
                                  size="sm"
                                  className="fmw-btn-primary btn-fmw"
                                  onClick={() =>
                                    viewWspOnboarding(support.projId)
                                  }
                                >
                                  Under Review
                                </Button>
                              ) : support.projStatus == "APPROVED" ? (
                                <Button
                                  sx={{ backgroundColor: "#0069DF !important" }}
                                  disabled
                                  color="neutral"
                                  size="sm"
                                  className="fmw-btn-primary btn-fmw"
                                  onClick={() =>
                                    viewWspOnboarding(support.projId)
                                  }
                                >
                                  Screened
                                </Button>
                              ) : support.projStatus == "COMMITTED" ? (
                                <Button

                                  disabled
                                  color="neutral"
                                  size="sm"
                                  className="fmw-btn-primary btn-fmw"
                                  onClick={() =>
                                    viewWspOnboarding(support.projId)
                                  }
                                >
                                  Project Preparation
                                </Button>
                              ) : support.projStatus == "ACTIVE" ? (
                                <Button

                                  color="neutral"
                                  size="sm"
                                  className="fmw-btn-primary btn-fmw"
                                  onClick={() =>
                                    viewWspOnboarding(support.projId)
                                  }
                                >
                                  ACTIVE
                                </Button>
                              ) :(
                                <Button
                                sx={{backgroundColor:'#5A5A72 !important'}}
                                  color="neutral"
                                  size="sm"
                                  className="fmw-btn-primary btn-fmw"
                                  onClick={() =>
                                    viewWspOnboarding(support.projId)
                                  }
                                >
                                DRAFT
                                </Button>
                                   )
                              }
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </Grid>
                  </>
                ))}
            </>
          )}
        </>
      )}

      {/* <ProjectDetails open={openPD} handleClickOpen={handleClickOpenPD} handleClose={handleCloseFS} button={button} /> */}
    </>
  );
}

export default SupportProject;
