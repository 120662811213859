import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Textarea } from "@mui/joy";
import MatrixInput from "../components/MatrixInput";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectScore,
  postScoreStep,
  setProjectScore,
} from "../../../features/expression_demand/expressionDemandSlice";
import FileButton from "../../../components/button/FileButton";
import { toast } from "react-toastify";
import SingleComment from "../components/SingleComment";
import UserMatrix from "../components/UserMatrix";

const CustomerDetails = ({ data, status }) => {
  const dispatch = useDispatch();
  const stepName = "CUSTOMER_DETAIL";
  const [comment, setComment] = useState("");
  const [onBlur, setOnBlur] = useState(true);
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const { scores } = useSelector((state) => state.expressionDemandData);
  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  const matrixFields = [
    "projIndividualActiveConn",
    "projInstitutionCommercialConn",
    "projNumberWaterKiosks",
  ];

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  useEffect(() => {
    fetchProjectScores();
  }, []);

  // const updateMatrix = () => {
  //   setMatrixInfo({});
  //   scores?.forEach((score) => {
  //     const { scScore, scMethod } = score;

  //     setMatrixInfo((prevMatrixInfo) => ({
  //       ...prevMatrixInfo,
  //       [scMethod]: scScore,
  //     }));
  //   });
  //   scores[0]?.sctComment === null
  //     ? setComment("")
  //     : setComment(scores[0]?.sctComment);
  // };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const {
    projIndividualActiveConn,
    projIndividualClosedConn,
    projArea,
    projLat,
    projLong,
    projTotalPopulation,
    projAproxPopulationServed,
    projInstitutionCommercialConn,
    projNumberHouseholdsServed,
    projNumberWaterKiosks,
    projMapAreaFile,
    projKioskConnFile,
    projInstConnFile,
    projIndActiveConnMbrFile,
    projIndActiveConnCustFile,
  } = data;

  const customerDetails = [
    createData(
      "Individual active connections",
      projIndividualActiveConn,
      "projIndividualActiveConn",
      10
    ),
    createData(
      "Individual closed connections",
      projIndividualClosedConn,
      "projIndividualClosedConn"
    ),
    createData(
      "Institution or commercial connections",
      projInstitutionCommercialConn,
      "projInstitutionCommercialConn",
      10   
    ),
    createData(
      "Number of water kiosks",
      projNumberWaterKiosks,
      "projNumberWaterKiosks",
      10
    ),
    createData(
      "Number of households served",
      projNumberHouseholdsServed,
      "projNumberHouseholdsServed"
    ),
    createData(
      "Approximate population served",
      projAproxPopulationServed,
      "projAproxPopulationServed"
    ),
    createData(
      "Approximate total population in service area",
      projTotalPopulation,
      "projTotalPopulation"
    ),
    createData(
      <>
        Service provision area (km<sup>2</sup>)
      </>,
      projArea,
      "projArea"
    ),
    createData(
      "Grid reference of office / infrastructure",
      `Latitude: ${projLat ?? "N/A"}, longitude: ${projLong ?? "N/A"}`,
      "projLat"
    ),
  ];

  const customerAttachments = [
    createData(
      "PDF of map of service area(pdf)",
      projMapAreaFile,
      "projMapAreaFile"
    ),
    createData(
      "PDF of sample siosks contract(pdf)",
      projKioskConnFile,
      "projKioskConnFile"
    ),
    createData(
      "PDF of sample Institution contract(pdf)",
      projInstConnFile,
      "projInstConnFile"
    ),
    createData(
      "PDF of list of members or accounts(pdf)",
      projIndActiveConnMbrFile,
      "projIndActiveConnMbrFile"
    ),
    createData(
      "PDF of sample customer contract(pdf)",
      projIndActiveConnCustFile,
      "projIndActiveConnCustFile"

      //Note: projInstConnFile to bw changed
    ),
  ];

  return (
    <Box>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Particulars of the applicant
      </Typography>

      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {customerDetails?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {(status === "Viable" || status === "Partner") &&
                matrixFields.includes(row.variableName) && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
          {customerAttachments?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FileButton file={row.description} />
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                  />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" ||
        status === "DEAL_SOURCING" ||
        status === "Viable" ||
        status === "Partner") && (
          <>
            <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
              Comments on above information
            </Typography>

            <Textarea
              value={comment}
              disabled={status !== "Viable"}
              onChange={(e) => setComment(e.target.value)}
              minRows={3}
              name="projSummary"
              placeholder={status !== "Viable" ? "N/A" : "write here..."}
              size="lg"
              className="fmw-textarea"
              color={`${status === "Partner"
                } ? "red !important" : "#646464 !important"`}
              sx={{ width: "100%", color: "#646464" }}
            />

            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>

          </>
        )}
    </Box>
  );
};

export default CustomerDetails;
