import { Textarea, Typography } from '@mui/joy'
import React from 'react'
import { useLocation } from 'react-router-dom'

function TextareaComment({ submitStep, comment, onChange, }) {
    const location = useLocation()
    const category = location.pathname.split('/')[3]
    return (
        <>
            {
                (
                    <>
                        <Typography sx={{ mt: "0px", mb: 2, fontSize: "18px", fontWeight: 500 }}>
                            Comments on above information
                        </Typography>
                        <Textarea
                            minRows={4}
                            onChange={onChange}
                            onBlur={submitStep}
                            type="text"
                            name="projSummary"
                            value={comment}
                            placeholder="Add a comment..."
                            size="lg"
                            className="fmw-input-joy-comment"
                        />
                    </>
                )
            }

        </>
    )
}

export default TextareaComment
