import { Box, Button, Typography } from "@mui/joy";
import { Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DealRoomSkeleton from "../../components/deal_room_list/DealRoomSkeleton";

function DealSourcingCard({ projectDesc, accTradeName, projId, page }) {
  const navigate = useNavigate();
  const viewWspOnboarding = (projId) => {
    // navigate(`/expression-details/${projId}/${page}`);
    navigate(`/intro-page/${projId}/${page}`);
   
  };

  return (
    <>
      <>
        <Grid item xs={12} style={{ paddingTop: "1.15rem" }}>
          <Paper
            sx={{ p: 0, display: "flex", flexDirection: "column" }}
            variant="outlined"
          >
            <Box sx={{ p: 2, pb: 1 }}>
              <Grid
                container
                spacing={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={10}
                  sx={{ mb: 1 }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography component="body2" className="list-avatar" />
                  </Box>
                  <Box sx={{ mx: 2.5 }}>
                    <Typography component="h6" sx={{ fontSize: ".925rem" }} className="acc-title-fmw">
                      Project: {accTradeName}
                    </Typography>
                    <Typography component="p" className="fmw-p" sx={{ mb: 1 }}>
                      {projectDesc}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2} sx={{ my: 3 }}>
                  <Button
                    color="neutral"
                    size="sm"
                    className="fmw-btn-primary btn-fmw"
                    onClick={() => viewWspOnboarding(projId)}
                  >
                    View project
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </>
    </>
  );
}

export default DealSourcingCard;
