import React from 'react';
import waterImg from '../../assets/bgImg.png';
import { Steps } from 'antd';

export default function WspApplicationStep({ current ,wspApplications}) {
	return (
		<>
			<div className='h-full w-full bgImg'>
				<div className='flex justify-center flex-col text-left px-[48px] w-full'>
					<h3 className='signup-info mt-20 '>{wspApplications?.accTradeName}</h3>
					<p className='signup-info-text mt-5'>
						Your application is under review
					</p>
				</div>

				<Steps
					className='mt-[56px] px-[48px] relative'
					direction='vertical'
					current={current}
					items={[
						{
							title: 'Application review',
						},
						{
							title: 'Expression',
						},
					]}
				/>
			</div>
		</>
	);
}
