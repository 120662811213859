import React from 'react'
import {Grid, Paper, Divider, Skeleton} from '@mui/material';

const ClassificationSkeleton = () => {
  return (
    <>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
              <Grid container spacing={4}>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                   
                  <Grid item xs={12} style={{paddingTop: '10px'}}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>

                  <Grid item xs={12} style={{paddingTop: '10px'}}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={12} style={{paddingTop: '10px'}}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
                  <Grid item xs={3}>
                    <Skeleton width={240} />
                  </Grid>
              </Grid>
            </Paper>
        </Grid>
    </>
  )
}

export default ClassificationSkeleton