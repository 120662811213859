import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteNewUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Typography } from "@mui/joy";
import { CircularProgress } from "@mui/material";

export default function DeleteAccountDialog({
  open,
  handleClickOpen,
  handleClose,
  personId,
}) {
  const dispatch = useDispatch();
  const { isSuccessDelete, isLoadingDelete } = useSelector(
    (state) => state.auth
  );
  const handleDelete = () => {
    dispatch(
      deleteNewUser({
        usrId: personId.usrId,
      })
    );
  };
  React.useEffect(() => {
    if (isSuccessDelete) {
      toast.success("User deleted successfully");
      handleClose();
    }
  }, [dispatch, isSuccessDelete]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth={true}
      >
        <DialogTitle className="fmw-page-title">
          Delete {personId?.usrFirstName} {personId?.usrLastName}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              variant="h5"
              component="h5"
              sx={{ fontSize: "1.05rem" }}
            >
              Are you sure you want to delete {personId?.usrFirstName}{" "}
              {personId?.usrLastName}?
            </Typography>
            <br />
            <Typography component="p" sx={{ fontSize: ".95rem" }}>
              NB: Once you send, the action is irreversible!
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "var(--bg-primary) !important",
            }}
            onClick={handleClose}
          >
            Disagree
          </Button>

          {!isLoadingDelete ? (
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "var(--bg-primary) !important",
              }}
              onClick={handleDelete}
            >
              Agree
            </Button>
          ) : (
            <Button disabled variant="contained" sx={{ textTransform: "none" }}>
              <CircularProgress size={17} color="inherit" />
              &nbsp;Deleting User...
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
