import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, } from '@mui/material';
import { Typography } from '@mui/joy';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import EnvironmentalTable from './EnvironmentalTable';
import EngineeringTable from './EngineeringTable';
import QuotationTable from './QuotationTable';

function Step2() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
        sx={{ backgroundColor: expanded === 'panel1' ? '#fff' : 'inherit' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Environmental Impact Statement
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <EnvironmentalTable viewOnly={false} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
        sx={{ backgroundColor: expanded === 'panel2' ? '#fff' : 'inherit' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel2bh-content'
          id='panel2bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Engineering Design
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <EngineeringTable viewOnly={false} />
        </AccordionDetails>
      </Accordion>


      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className='fmw-accrdion-table fmw-accrd'
        elevation={0}
        sx={{ backgroundColor: expanded === 'panel3' ? '#fff' : 'inherit' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls='panel3bh-content'
          id='panel3bh-header'
        >

          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Quotations
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px !important' }}>
          <QuotationTable viewOnly={false} />
        </AccordionDetails>
      </Accordion>


    </Box>
  )
}

export default Step2
