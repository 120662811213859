import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import './committed-menu.css'
import { useNavigate } from 'react-router-dom';

export default function CommittedMenu({ anchorEl, open, handleClick, handleClose, category, projId }) {
  const navigate = useNavigate();
  const handleViewTermsheet = () => {
    console.log("*************************", projId, "*************************************************");
    navigate(`/committed-projects/${projId}/${category}`);
    handleClose();
  }

  const handleProjDetails = () => {
    console.log("*************************", projId, "*************************************************");
    navigate(`/intro-page/${projId}/complete`)
  }
  return (
    <div style={{ width: '187px', backgroundColor: 'red' }}>

      <Menu
        elevation={0}
        className='committed-menu'
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleProjDetails} >View project</MenuItem>
        <MenuItem onClick={handleViewTermsheet} >Prepare project</MenuItem>
        <MenuItem onClick={handleClose}>View term sheet</MenuItem>
        <MenuItem onClick={handleClose}>Update term sheet</MenuItem>
      </Menu>
    </div>
  );
}