import React, { useEffect } from 'react'
import SearchField from '../../components/search_field/SearchField'
import { useDispatch, useSelector } from 'react-redux';
import { getExpressionDemandData, setFilterParams } from '../../features/expression_demand/expressionDemandSlice';
import PageTitle from '../../components/page_title/PageTitle';
import { useParams } from 'react-router-dom';

function IntroductionCommitted() {
  const { category } = useParams();
  const { expressionData, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  const dispatch = useDispatch();
  const handleDealSourceData = () => {
    dispatch(setFilterParams(category));
    if (category === "committed") {
      const res = dispatch(getExpressionDemandData({ projStatus: "COMMITTED" }));
    } else {
      const res = dispatch(getExpressionDemandData({ projStatus: "APPROVED" }));
    }
  };

  useEffect(() => {
    handleDealSourceData();
  }, [category]);

  return (
    <>
      <PageTitle currentPage="Project Preparation" />
      <SearchField expressionData={expressionData} category={category} />
    </>
  )
}

export default IntroductionCommitted
