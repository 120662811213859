import { Button, Checkbox, TextField, Chip, Typography, FormHelperText, Grid } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, Image, PictureAsPdf } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { cleanupBank, onbaordBankFile, } from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';


const BankPdf = ({formData}) => {
    const { step5, assetFile, bankFile, step5File, bankInfos,
        isSuccessDelete, isLoadingBank, errorEmail, errorPhoneAuditor, isSuccessUpdateBank } = useSelector((state) => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const dispatch = useDispatch();
    const [fetchedBankStatementFile, setFetchedBankStatementFile] = useState(formData?.bFile)
    const removeFetchedBankStatementFile = () => {
        setFetchedBankStatementFile('')
    }
  
    
  
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


     // bankStmnt
     const [file, setFile] = useState("")
     const [fileBank, setFileBank] = useState(bankFile)
     const { nameBank, typeBank } = fileBank;
     const [bank, setBank] = useState('')
     const [errorBankFile, setErrorBankFile] = useState('')
 
    // bankStmnt
    const handleBankStmnt = (e) => {
        const _file = e.target.files[0];
        if (!_file) return;
        const allowedExtensions = /(\.pdf)$/i;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorBankFile(
							'Please upload a PDF file Only not more than 10mb',
						);
            setBank('');
            return;
        }
        setFile(_file);
        setErrorBankFile('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameBank: _file.name,
                dateBank: _date,
                typeBank: _file.type,
                file_base64Bank: base64String.toString()
            };
            setFileBank(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordBankFile(formData));
    }

    const removeBankStmnt = () => {
        setFileBank({
            nameBank: '',
            dateBank: '',
            typeBank: '',
            file_base64Bank: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupBank());
    }
    return (
        <>
                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                            PDF of bank statements for last 12 months
                        </Typography>
                
                    <Grid item xs={12}>
                        {
                            fetchedBankStatementFile ? (
                                <>
                                    <a className='file-stepper-primary' onClick={() => previewPaper(fetchedBankStatementFile)}>
                                        {formatPath(fetchedBankStatementFile)}</a>
                                    <IconButton
                                        aria-label="close"
                                        onClick={removeFetchedBankStatementFile}
                                        sx={{
                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    {
                                        !nameBank ? (
                                            <>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    border: 'none',
                                                    // border: '1px solid var(--color-primary-light)',
                                                    width: '337px',
                                                    height: '46px'
                                                }}>
                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                            </div>
                                                            {
                                                                errorBankFile ? (
                                                                    <Typography component="h6" className="upload-sub-label-error">
                                                                        {errorBankFile}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                        Click here to upload
                                                                    </Typography>
                                                                )
                                                            }
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name='nameBank'
                                                            value={nameBank}
                                                            accept=".pdf"
                                                            webkitdirectory
                                                            onChange={handleBankStmnt}
                                                            style={{ width: '0px', height: '0px' }}
                                                        />

                                                    </label>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                    border: '1px solid var(--color-primary-light)',
                                                    width: '337px',
                                                    height: '46px'
                                                }}>
                                                    <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                        <span style={{ margin: "0px 6px" }}>
                                                            {
                                                                typeBank === "application/pdf" ? (
                                                                    <PictureAsPdf sx={{
                                                                        fontSize: '40px',
                                                                        color: '#f44336'
                                                                    }} />
                                                                ) :
                                                                    typeBank === "image/png" || "image/jpeg" || "image/gif" ? (
                                                                        <Image sx={{
                                                                            fontSize: '40px',
                                                                            color: '#263238',
                                                                        }} />
                                                                    ) :
                                                                        (
                                                                            <Article sx={{
                                                                                fontSize: '40px',
                                                                                color: '#1976d2'
                                                                            }} />
                                                                        )
                                                            }

                                                        </span>
                                                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <Typography component="h6" className="fmw-upload-typography">
                                                            {subString(nameBank, 25)}
                                                        </Typography>


                                                        <Typography variant="caption" className="upload-sub-label">
                                                            uploaded on  {_date}
                                                        </Typography>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={removeBankStmnt}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )


                        }
                    </Grid>

        </>
    )
}

export default BankPdf
