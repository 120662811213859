import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid } from "@mui/material";
import { Typography, Button } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import { MuiOtpInput } from "mui-one-time-password-input";
import { sendOTP, RefetchOTP, reset } from "../../../features/wsp/wspSlice";
import SigninRegister from "../../signin/SigninRegister";
import { cleanupForm } from "../../../features/tech_adv/techAdvSlice";
import { toSentenceCase } from "../../../utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    maxWidth: "md",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", left: 22, top: 15, color: "#2C2C2C" }}
      >
        <ArrowBackIcon />
      </IconButton>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 10,
            color: (theme) => theme.palette.grey[500],
            display: "none",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const VerifyOTP = ({
  open,
  handleClickOpen,
  handleClose,
  num,
  iD,
  handleComplete,
}) => {
  const {
    sendOtp,
    OTP,
    isProcessing,
    isLoadingSend,
    isLoadingResend,
    isSuccess,
    isSuccessSend,
    isSuccessResend,
    isError,
    isErrorSend,
    isErrorResend,
    message,
  } = useSelector((state) => state.wsp);

  const { isCleanupForm } = useSelector((state) => state.techAdv);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(true);
  const [counter, setCounter] = useState(60);

  let [otp, setOtp] = React.useState("");
  let [resend, setResend] = React.useState(iD);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // const handleChange = e => {
  //   setOtp(e.target.newValue);
  //   console.log("OTP", otp);
  // }

  const [openLogIn, setOpenLogIn] = React.useState(false);
  const handleClickOpenLogIn = () => {
    setOpenLogIn(true);
  };
  const handleCloseLogIn = () => {
    setOpenLogIn(false);
  };
  const verifyOtp = () => {
    if (num !== otp) {
      toast.error("Verification code does not match");
    } else if (open) {
      dispatch(
        sendOTP({
          usrId: iD,
          usrSalt: num,
        })
      );
      // handleComplete();
      // handleClose();
    }
  };

  // console.log('id', iD);

  const resendOTP = () => {
    dispatch(RefetchOTP(iD));
    setCounter(60);
  };

  useEffect(() => {
    if (open && counter > 0) {
      const interval = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [counter, open]);

  useEffect(() => {
    setCounter(60);
  }, [open]);

  useEffect(() => {
    if (isSuccessSend && message) {
      toast.success(toSentenceCase(message));
      handleClickOpenLogIn();
      dispatch(cleanupForm());
      handleClose();
    }

    if (isSuccessResend && message) {
      toast.success(toSentenceCase(message));
    }
    if (isCleanupForm && isSuccessSend) {
      dispatch(cleanupForm());
    }

    if (isErrorSend && message && open) {
      toast.error(toSentenceCase(message));
    }

    if (isErrorResend && message && open) {
      toast.error(toSentenceCase(message));
    }

    setTimeout(() => {
      setTimer(false);
    }, 50000);

    dispatch(reset());
  }, [
    otp,
    isSuccessSend,
    isSuccessResend,
    isErrorResend,
    isErrorSend,
    isCleanupForm,
  ]);

  return (
		<>
			<BootstrapDialog
				// onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				fullWidth={true}
				maxWidth='xs'
			>
				<BootstrapDialogTitle
					id='customized-dialog-title'
					onClose={handleClose}
				/>

				<DialogContent sx={{ mx: 5, my: 3 }} className='signin-dialog'>
          <form onSubmit={verifyOtp}>
					<Typography component='h4' className='meta-title signin-title'>
          Verification Code
					</Typography>

					<Typography component='p' className='fmw-otp-d'>
						Enter the verification code sent to your phone / email
					</Typography>
					<Typography component='p'>
						Resend PIN in: <span style={{ fontWeight: 'bold' }}>{counter}</span>
					</Typography>

					<Box sx={{}}>
						<Grid container spacing={3} sx={{ mt: 1 }}>
							<Grid item xs={12} md={10}>
								<MuiOtpInput value={otp} onChange={handleChange} length={4} />
							</Grid>
						</Grid>

						<Grid container spacing={3} sx={{ mt: 1 }}>
							<Grid item xs={6}>
								<Button
									className='sign-btn'
									onClick={resendOTP}
									disabled={counter > 0}
								>
									Resend
								</Button>
							</Grid>

							<Grid item xs={6}>
								<Button className='sign-btn ' type="submit">
									Verify
								</Button>
							</Grid>
						</Grid>
					</Box>
          </form>
				</DialogContent>
				<DialogActions>
					{/*<Grid container spacing={3} sx={{mt: .25, mx: 'auto'}}>*/}
					{/*  <Grid item xs={6} sx={{ ml: 0}}>*/}
					{/*    <Button className="signup-btn" onClick={resendOTP}>*/}
					{/*      Resend*/}
					{/*    </Button>*/}
					{/*  </Grid>*/}

					{/*  <Grid item xs={6}>*/}
					{/*    <Button className="sign-btn " onClick={verifyOTP} >*/}
					{/*      Verify*/}
					{/*    </Button>*/}
					{/*  </Grid>*/}
					{/*</Grid>*/}
				</DialogActions>
			</BootstrapDialog>
			<SigninRegister
				open={openLogIn}
				handleClickOpen={handleClickOpenLogIn}
				handleClose={handleCloseLogIn}
			/>
			<CssVarsProvider />
		</>
	);
};

export default VerifyOTP;
