import { Button, Checkbox, Input, Chip, Typography, FormHelperText } from '@mui/joy'
import {
	CircularProgress, Divider, FormControl, FormLabel, Grid, IconButton, InputAdornment, Table,
	TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, Image, Create, Delete, KeyboardArrowDown, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import { formatPath, previewPaper, subString } from '../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import FinancialDatePicker from './FinancialDatePicker';
import { cleanupNationalId, deleteDirector, fetchDirectors, fetchDirectorsById, onbaordCopyIdStep3, onbaordFiles, onboardPartner, reset, saveDirector, setStep3, setStep3File } from '../../../features/onboard_partner/onboardPartnerSlice';
import { PositionData } from '../../../PositionData';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { fetchWspApplications } from '../../../features/wsp/wspSlice';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DirectorEdit from '../../../components/onboard_partner_modals/DirectorEdit';


function Director() {
	const dispatch = useDispatch();
	const { step3, step3File, step3CopyId, isLoadingCopyId, isLoading, director, isSuccessDelete, directorEdit,
		isSuccessUpdateDirector, isLoadingDirectorUpdate } = useSelector((state) => state.onboardPartner)
	const [isUpdating, setIsUpdating] = useState(false);
	const [formData, setFormData] = useState({
		projMemberName: '',
		projCommitteePosition: null,
		projCommiteeMemberPhone: '',
		projCommitteeDuration: ''
	})
	const { projMemberName, projCommitteePosition, projCommiteeMemberPhone, projCommitteeDuration } = formData
	const { wspApplications, sendProjId } = useSelector((state) => state.wsp)
	const { user } = useSelector((state) => state.auth)
	const [file, setFile] = useState(undefined)

	const [fileId, setFileId] = useState("")
	const { nameId, dateId, file_base64Id, typeId } = fileId;
	const [isProcessing, setIsProcessing] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const current = new Date();
	const year = current.getFullYear();
	const month = current.toLocaleString('default', { month: 'short' });
	const day = ("0" + current.getDate()).slice(-2);
	const _date = `${month} ${day},${year}`;


	// id pdf
	const [errorId, setErrorId] = useState('')
	const [idPdf, setIdPdf] = useState(undefined)
	const [personn, setPersonn] = useState()

	const handleId = (e) => {
		const _imgArr = [];
		const _file = e.target.files[0];

		if (!_file) return;
		const MAX_FILE_SIZE = 1e7; //10mb
		const allowedExtensions = /(\.pdf)$/i;
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setErrorId('Please upload a PDF file not more than 10mb ');
			setIdPdf('');
			return;
		}
		setFile(_file);
		setErrorId('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameId: _file.name,
				dateId: _date,
				typeId: _file.type,
				file_base64Id: base64String.toString(),
			};
			setFileId(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);

		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onbaordCopyIdStep3(formData));
	};

	const removeId = () => {
		setFileId({
			nameId: '',
			dateId: '',
			typeId: '',
			file_base64Id: undefined,
		});
		setIsUpdating(true);
		dispatch(cleanupNationalId());
	};

	const handleChange = (e) => {
		e.target.value < 0
			? (e.target.value = 0)
			: setFormData((prevState) => ({
				...prevState,
				[e.target.name]: e.target.value,
			}));
		setIsUpdating(true);
	};

	const [phoneNo, setPhoneNo] = useState('');
	const [isValidPhone, setIsValidPhone] = useState(false);

	const handlePhone = (e) => {
		const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(e);
		setPhoneNo(e);
		setIsValidPhone(isKenyanPhoneNumber);
		setIsUpdating(true);
	};
	// const [isValid, setIsValid] = useState(false);
	// const validatePhoneNumber = (phoneNumber) => {
	//     const kenyanPhoneNumberRegex = /^(?:\+254|0)?[17]?\d{8}$/; // Regular expression to match Kenyan phone number format

	//     if (projCommiteeMemberPhone && projCommiteeMemberPhone.match(kenyanPhoneNumberRegex)) {
	//       if (projCommiteeMemberPhone.startsWith("7") && projCommiteeMemberPhone.length === 9) {
	//         return true;
	//       } else {
	//         return false;
	//       }
	//     } else {
	//       return false;
	//     }
	//   };
	// const phoneNumber = projCommiteeMemberPhone;
	// const isValidPhoneNumber = validatePhoneNumber(phoneNumber);

	//   ^ and $ match the start and end of the string respectively.
	// (?:\+254|0) matches either "+254" or "0".
	// [17] matches either "1" or "7".
	// \d{8} matches any 8 digits.

	const handleChangePhone = (e) => {
		e.target.value < 0
			? (e.target.value = 0)
			: setFormData((prevState) => ({
				...prevState,
				[e.target.name]: e.target.value,
			}));
		// setIsValid(validatePhoneNumber(e.target.value));
		setIsUpdating(true);
	};

	const handleDelete = (id) => {
		dispatch(
			deleteDirector({
				dirId: id,
			}),
		);
		setIsUpdating(false);
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const addMember = (e) => {
		e.preventDefault();
		if (
			!projMemberName ||
			!projCommitteePosition ||
			!phoneNo ||
			!projCommitteeDuration ||
			!nameId
		) {
			toast.warning('Please fill all details about committee members');
		} else if (!isValidPhone) {
			toast.warning('Enter a valid phone number');
		} else {
			dispatch(
				saveDirector({
					dirProjId: wspApplications[0].projId,
					dirName: projMemberName,
					dirPosition: projCommitteePosition,
					dirPhone: phoneNo,
					dirDuration: projCommitteeDuration,
					dirCopyOfId: step3CopyId.length > 0 ? step3CopyId : null,
				}),
			);
			setFormData({
				projMemberName: '',
				projCommitteePosition: null,
				projCommitteeDuration: '',
			});
			setPhoneNo('');
			setFileId({ nameId: '' });
		}
	};

	useEffect(() => {
		if (!isUpdating && user.usrJbrnId === 1) {
			dispatch(fetchWspApplications());
			setTimeout(() => {
				dispatch(
					fetchDirectors({
						projId: wspApplications[0].projId,
					}),
				);
			}, 800);
		} else if (!isUpdating && user.usrJbrnId === 0) {
			dispatch(
				fetchWspApplications({
					projId: sendProjId,
				}),
			);
			setTimeout(() => {
				dispatch(
					fetchDirectors({
						projId: sendProjId,
					}),
				);
			}, 800);
		}
		// else if (!isUpdating && isSuccessDelete) {
		//     dispatch(fetchDirectors());
		// }
		else if (
			(!isUpdating || isSuccessUpdateDirector) &&
			user.usrJbrnId === 1
		) {
			setTimeout(() => {
				dispatch(
					fetchDirectors({
						projId: wspApplications[0].projId,
					}),
				);
			}, 800);
			dispatch(fetchWspApplications());
		} else if (
			(!isUpdating || isSuccessUpdateDirector) &&
			user.usrJbrnId === 0
		) {
			setTimeout(() => {
				dispatch(
					fetchDirectors({
						projId: sendProjId,
					}),
				);
			}, 800);
			dispatch(
				fetchWspApplications({
					projId: sendProjId,
				}),
			);
		} else {
			// dispatch(setStep3(formData));
			// dispatch(setStep3File(fileId));
		}
		return () => {
			dispatch(reset());
		};
	}, [
		dispatch,
		isUpdating,
		isSuccessDelete,
		isSuccessUpdateDirector,
		sendProjId,
	]);

	return (
		<>
			<Grid container spacing={2} sx={{ mt: 2 }}>
				<Grid item xs={12}>
					<Typography
						component='h6'
						className='fmw-onboard-partner-label'
						style={{ marginBottom: '0.5rem' }}
					>
						Details of management committee members/directors
					</Typography>
				</Grid>

				<Grid item xs={6}>
					<Typography
						component='p'
						className='fmw-onboard-partner-sub-label'
						sx={{ mb: '.5rem' }}
					>
						Name of member
					</Typography>
					<Input
						label=''
						type='text'
						value={projMemberName}
						name='projMemberName'
						onChange={handleChange}
						size='lg'
						className='fmw-input-joy'
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography
						component='p'
						className='fmw-onboard-partner-sub-label'
						sx={{ mb: '.5rem' }}
					>
						Position of committee member
					</Typography>
					<Select
						className='fmw-select'
						onChange={(e, newval) => {
							handleChange({
								target: {
									value: newval,
									name: 'projCommitteePosition',
								},
							});
						}}
						placeholder='Select position *'
						indicator={<KeyboardArrowDown />}
						size='lg'
						name='projCommitteePosition'
						value={projCommitteePosition ? projCommitteePosition : null}
						sx={{
							width: '100%',
							[`& .${selectClasses.indicator}`]: {
								transition: '0.2s',
								[`&.${selectClasses.expanded}`]: {
									transform: 'rotate(-180deg)',
								},
							},
						}}
					>
						{PositionData.map((option) => (
							<Option key={option.value} value={option.value}>
								{option.label}
							</Option>
						))}
					</Select>
				</Grid>

				<Grid item xs={6}>
					<Typography
						component='p'
						className='fmw-onboard-partner-sub-label'
						sx={{ mb: '.5rem' }}
					>
						Telephone of commitee member
					</Typography>
					<ReactPhoneInput
						defaultCountry='ke'
						country='ke'
						inputClass='fmw-phone'
						required
						searchClass='search-class'
						searchStyle={{
							margin: '0',
							width: '100% !important',
							height: '80px !important',
						}}
						enableSearchField
						disableSearchIcon
						value={phoneNo}
						onChange={handlePhone}
					/>
					{phoneNo && !isValidPhone && (
						<FormHelperText className='upload-sub-label-error'>
							Enter a valid phone number
						</FormHelperText>
					)}
				</Grid>
				<Grid item xs={6}>
					<Typography
						component='p'
						className='fmw-onboard-partner-sub-label'
						sx={{ mb: '.5rem' }}
					>
						Duration in committee in years
					</Typography>
					<Input
						label=''
						type='number'
						value={projCommitteeDuration}
						name='projCommitteeDuration'
						onChange={handleChange}
						size='lg'
						className='fmw-input-joy'
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography
						component='h6'
						className='fmw-checkbox-label'
						sx={{ mb: '.5rem' }}
					>
						Attach copy of ID<span className='star'>*</span>
					</Typography>
					{!nameId ? (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									border: 'none',
									// border: '1px solid var(--color-primary-light)',
									width: '337px',
									height: '46px',
								}}
							>
								<label
									className=''
									style={{ cursor: 'pointer', width: '100%', height: '100%' }}
								>
									<div
										className='mb-2'
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											height: '100%',
											backgroundColor: '#F7F7F7',
										}}
									>
										<div
											style={{
												backgroundColor: '#A0A0A0',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												marginRight: '20px',
												width: '52px',
											}}
										>
											<CloudUploadOutlined
												sx={{
													display: 'flex',
													fontSize: '27px',
													justifyContent: 'center',
													alignItems: 'center',
													mx: '12px',
													color: '#ffff',
													backgroundColor: '#A0A0A0 !important',
												}}
											/>
										</div>
										{errorId ? (
											<Typography
												component='h6'
												className='upload-sub-label-error'
											>
												{errorId}
											</Typography>
										) : (
											<Typography
												variant='caption'
												className='upload-sub-label'
											>
												Click here to upload
											</Typography>
										)}
									</div>
									<input
										type='file'
										name='file'
										value={nameId}
										accept='.pdf'
										webkitdirectory
										onChange={handleId}
										style={{ width: '0px', height: '0px' }}
									/>
								</label>
							</div>
						</>
					) : (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									justifyContent: 'space-between',
									border: '1px solid var(--color-primary-light)',
									width: '337px',
									height: '46px',
								}}
							>
								<div
									style={{
										backgroundColor: 'inherit',
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										marginRight: '5px',
										width: '52px',
									}}
								>
									<span style={{ margin: '0px 6px' }}>
										{typeId === 'application/pdf' ? (
											<PictureAsPdf
												sx={{
													fontSize: '40px',
													color: '#f44336',
												}}
											/>
										) : typeId === 'image/png' ||
											'image/jpeg' ||
											'image/gif' ? (
											<Image
												sx={{
													fontSize: '40px',
													color: '#263238',
												}}
											/>
										) : (
											<Article
												sx={{
													fontSize: '40px',
													color: '#1976d2',
												}}
											/>
										)}
									</span>
									<Divider
										orientation='vertical'
										flexItem
										sx={{
											backgroundColor:
												'var(--color-primary-light) !important',
										}}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography
										component='h6'
										className='fmw-upload-typography'
									>
										{subString(nameId, 25)}
									</Typography>

									<Typography variant='caption' className='upload-sub-label'>
										uploaded on {_date}
									</Typography>
								</div>

								<div>
									<IconButton
										aria-label='close'
										onClick={removeId}
										sx={{
											color: (theme) => theme.palette.grey[500],
											mr: '6px',
										}}
									>
										<CloseIcon />
									</IconButton>
								</div>
							</div>
						</>
					)}
				</Grid>

				<Grid item md={6} xs={12}></Grid>
				<Grid item md={6} xs={12} sx={{ mb: 3, mt: 0 }}>
					<FormControl fullWidth variant={'standard'}>
						<FormLabel htmlFor='my-input' variant={'subtitle'}>
							NOTE: You can add as many management committee members/directors
							as possible*
						</FormLabel>
						{!isLoading ? (
							<Button
								disabled={isLoadingCopyId}
								variant='outlined'
								color='primary'
								sx={{
									textTransform: 'capitalize',
									backgroundColor: 'var(--bg-primary) !important',
								}}
								onClick={addMember}
								type='submit'
							>
								 
								{
									director?.length? 'Add Member' : 'Save Member'
								}
							</Button>
						) : (
							<Button
								disabled
								variant='contained'
								sx={{ textTransform: 'none' }}
							>
								<CircularProgress size={17} color='inherit' />
								&nbsp;Adding...
							</Button>
						)}
					</FormControl>
				</Grid>

				{isLoading || isLoadingDirectorUpdate ? (
					<>
						<Skeleton width={1450} height={30} />
						<Skeleton width={1450} height={30} />
						<Skeleton width={1450} height={30} />
						<Skeleton width={1450} height={30} />
						<Skeleton width={1450} height={30} />
					</>
				) : (
					<>
						{director?.length ? (
							<>
								<Divider sx={{ my: 2 }} />
								<TableContainer>
									<Table sx={{ minWidth: 650 }} aria-label='simple table'>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell align='left'>Position</TableCell>
												<TableCell align='left'>Telephone</TableCell>
												<TableCell align='left'>Duration </TableCell>
												<TableCell align='left'>File</TableCell>
												<TableCell align='left'>Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{director?.length &&
												director?.map((person) => (
													<>
														<TableRow
															key={person.dirId}
															sx={{
																'&:last-child td, &:last-child th': {
																	border: 0,
																},
															}}
														>
															<TableCell component='th' scope='row'>
																{person.dirName}
															</TableCell>
															<TableCell component='th' scope='row'>
																{person.dirPosition}
															</TableCell>
															<TableCell component='th' scope='row'>
																{person.dirPhone}
															</TableCell>
															<TableCell component='th' scope='row'>
																{person.dirDuration}
															</TableCell>

															<TableCell align='left'>
																<Chip
																	variant='soft'
																	className='file-chip-primary'
																	color='primary'
																	size='sm'
																	startDecorator={<AttachFile size='sm' />}
																	onClick={() =>
																		previewPaper(person.dirCopyOfId)
																	}
																>
																	{formatPath(person.dirCopyOfId)}
																</Chip>
															</TableCell>

															<TableCell align='left'>
																<IconButton
																	aria-label='delete'
																	size='small'
																	sx={{ mr: 0.5 }}
																	onClick={() => setPersonn(person)}
																>
																	<Create
																		fontSize='small'
																		onClick={() => handleClickOpen()}
																	/>
																</IconButton>

																<IconButton aria-label='delete' size='small'>
																	<Delete
																		fontSize='small'
																		onClick={() => handleDelete(person.dirId)}
																	/>
																</IconButton>
															</TableCell>
														</TableRow>
													</>
												))}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						) : (
							<></>
						)}
					</>
				)}
			</Grid>
			<DirectorEdit
				open={open}
				handleClickOpen={handleClickOpen}
				handleClose={handleClose}
				directorEdited={personn}
			/>
		</>
	);
}

export default Director

