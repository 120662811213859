import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import partnerService from './partnerService';
import wspService from "../wsp/wspService";


const initialState = {
    partner: {},
    account: {},
    mandateFileCor: {},
    mandateFileRegCert: {},
    mandateFileFinStatement: {},
    mandateFileCorpStrategy: {},

    accountMandate: {},
    isError: false,
    isErrorRegister:false,
    isLoading: false,
    isSuccess: false,
    isSuccessAm: false,
    isSuccessFileCor: false,
    isLoadingFileCor:false,

    isLoadingIndicators: false,
    isSuccessIndicators: false,
    isErrorIndicators: false,

    isLoadingOutcome: false,
    isSuccessOutcome: false,
    isErrorOutcome: false,

    isErrorFileRegCert: false,
    isLoadingFileRegCert: false,
    isSuccessFileRegCert: false,

    isErrorFileFinStatement: false,
    isLoadingFileFinStatement: false,
    isSuccessFileFinStatement: false,

    isErrorFileCorpStrategy: false,
    isLoadingFileCorpStrategy: false,
    isSuccessFileCorpStrategy: false,

    isSuccessRegister: false,
    isErrorAm: false,
    messageAm: '',
    messageRegister: '',
    message: '',
    errorEmail: true,
    errorPassword: null,
    errorPhone:true,
    sendOtpCode:{
        usrId: '',
        usrSalt: ''
    },
    institution: {
        accountName: '',
        websiteLink: '',
        address: '',
        institutionType: '',
        accInstDesc: '',
        usrTitle: null,
        usrFullNames: '',
        usrEmail: '',
        usrMobileNumber: '',
    },
    indicators: {
        accFocus1: '',
        accFocus2: '',
        accFocus3: '',
        accFocus4: '',
        accFocus5: '',
        accFocus6: '',
    },
    outcomeAreas: {
        accAreas1: '',
        accAreas2: '',
        accAreas3: '',
        accAreas4: '',
        accAreas5: '',
        accAreas6: '',
    },
    mandate: {
        motherCompany: '',
        accMandate: '',
        accWebsite: '',
        accSdgs: '',
        usrEncryptedPassword: '', 
        confirmPassword:'' 
    },
    // type A
    mandateCor: {
        nameCor: '',
        dateCor: '',
        file_base64Cor: undefined,
        typeCor: '',
    },

    // type B
    mandateRegCert: {
        nameRegCert: '',
        dateRegCert: '',
        file_base64RegCert: undefined,
        typeRegCert: '',
    },

    mandateFinStatement: {
        nameFinStatement: '',
        dateFinStatement: '',
        file_base64FinStatement: undefined,
        typeFinStatement: '',
    },
    mandateCorpStrategy: {
        nameCorpStrategy: '',
        dateCorpStrategy: '',
        file_base64CorpStrategy: undefined,
        typeCorpStrategy: '',
    },

    isCleanupForm: false,


}

//createAccount
export const registerPartner = createAsyncThunk('partner/registerPartner', async (_formData, thunkAPI) => {
    try {

        return await partnerService.registerPartner(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


//savePartnerIndicators
export const savePartnerIndicators = createAsyncThunk('partner/savePartnerIndicators', async (_formData, thunkAPI) => {
    try {
        return await partnerService.savePartner(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//savePartnerAreas
export const savePartnerAreas = createAsyncThunk('partner/savePartnerAreas', async (_formData, thunkAPI) => {
    try {
        return await partnerService.savePartner(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//onboardMandateFileCor
export const onboardMandateFileCor = createAsyncThunk('partner/onboardMandateFileCor', async (formData, thunkAPI) => {
    try {
        return await partnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//onboardMandateFileRegCert
export const onboardMandateFileRegCert = createAsyncThunk('partner/onboardMandateFileRegCert', async (formData, thunkAPI) => {
    try {
        return await partnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//onboardMandateFileFinStatement
export const onboardMandateFileFinStatement = createAsyncThunk('partner/onboardMandateFileFinStatement', async (formData, thunkAPI) => {
    try {
        return await partnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//onboardMandateFileCorpStrategy
export const onboardMandateFileCorpStrategy = createAsyncThunk('partner/onboardMandateFileCorpStrategy', async (formData, thunkAPI) => {
    try {
        return await partnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const sendOTP = createAsyncThunk('partner/sendOtp',async(formData,thunkAPI)=>{
    try{
        return await partnerService.sendOTP(formData)
    }catch(error){
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});




//saveAccountMandate
export const saveAccountMandate = createAsyncThunk('partner/saveAccountMandate', async (_formData, thunkAPI) => {
    try {
        return await partnerService.savePartner(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.isSuccessAm = false
            state.isSuccessFileCor = false
            state.isLoadingFileCor = false

            state.isLoadingIndicators = false
            state.isSuccessIndicators = false
            state.isErrorIndicators = false
        
            state.isLoadingOutcome = false
            state.isSuccessOutcome = false
            state.isErrorOutcome = false
        

            state.isErrorFileRegCert = false
            state.isLoadingFileRegCert = false
            state.isSuccessFileRegCert = false

            state.isErrorFileFinStatement = false
            state.isLoadingFileFinStatement = false
            state.isSuccessFileFinStatement = false

            state.isErrorFileCorpStrategy = false
            state.isLoadingFileCorpStrategy = false
            state.isSuccessFileCorpStrategy = false

            state.isErrorRegister=false

            state.isErrorAm = false
            state.isSuccessRegister = false
            state.messageRegister = ''
            state.messageAm = ''
            state.message = ''
            state.isCleanupForm = false
        },
        setInstitutionDetails: (state, action) => {
            state.institution = action.payload
        },
        setEmailErrorDetails: (state, action) => {
            state.errorEmail = action.payload
        },
        setPasswordErrorDetails: (state, action) => {
            state.errorPassword = action.payload
        },
        setPhoneErrorDetails: (state, action) => {
            state.errorPhone = action.payload
        },

        


        setCoreIndicators: (state, action) => {
            state.indicators = action.payload
        },
        setOutcomeAreas: (state, action) => {
            state.outcomeAreas = action.payload
        },
        setMandate: (state, action) => {
            state.mandate = action.payload
        },
        setMandateFileCor: (state, action) => {
            state.mandateCor = action.payload
        },
        setMandateFileRegCert: (state, action) => {
            state.mandateRegCert = action.payload
        },
        setMandateFileFinancial: (state, action) => {
            state.mandateFinStatement = action.payload
        },
        setMandateFileCorporate: (state, action) => {
            state.mandateCorpStrategy = action.payload
        },


        cleanupForm: (state) => {
            state.partner = initialState.partner
            state.account = initialState.account
            state.institution = initialState.institution
            state.indicators = initialState.indicators
            state.outcomeAreas = initialState.outcomeAreas
            state.mandate = initialState.mandate
            state.mandateFileCor = initialState.mandateFileCor
            state.mandateCor = initialState.mandateCor
            state.mandateFileRegCert = initialState.mandateFileRegCert
            state.mandateRegCert = initialState.mandateRegCert
            state.mandateFileFinStatement = initialState.mandateFileFinStatement
            state.mandateFinStatement = initialState.mandateFinStatement
            state.mandateFileCorpStrategy = initialState.mandateFileCorpStrategy
            state.mandateCorpStrategy = initialState.mandateCorpStrategy
            state.accountMandate = initialState.accountMandate
        }
    },
    extraReducers: (builder) => {
        builder

            //  createAccount
            .addCase(registerPartner.pending, (state) => {
                state.isLoading = true
            })
            .addCase(registerPartner.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessRegister = true
                state.messageRegister = "Institution details added successfully."
                state.partner = action.payload
            })
            .addCase(registerPartner.rejected, (state, action) => {
                state.isLoading = false
                state.isErrorRegister = true
                // state.partner = {}
                state.messageRegister = action.payload
            })


            //savePartnerIndicators
            .addCase(savePartnerIndicators.pending, (state) => {
                state.isLoadingIndicators = true
            })
            .addCase(savePartnerIndicators.fulfilled, (state, action) => {
                state.isLoadingIndicators = false
                state.isSuccessIndicators = true
                state.message = "Core indicators details added successfully"
                state.account = action.payload
            })
            .addCase(savePartnerIndicators.rejected, (state, action) => {
                state.isLoadingIndicators = false
                state.isErrorIndicators = true
                state.message = action.payload
                state.account = {}
            })

              //savePartnerOutcomeAreas
              .addCase(savePartnerAreas.pending, (state) => {
                state.isLoadingOutcome = true
            })
            .addCase(savePartnerAreas.fulfilled, (state, action) => {
                state.isLoadingOutcome = false
                state.isSuccessOutcome = true
                state.message = "Outcome areas details added successfully"
                state.account = action.payload
            })
            .addCase(savePartnerAreas.rejected, (state, action) => {
                state.isLoadingOutcome = false
                state.isErrorOutcome = true
                state.message = action.payload
                state.account = {}
            })

            // onboardMandateFileCor
            .addCase(onboardMandateFileCor.pending, (state) => {
                state.isLoadingFileCor = true
            })
            .addCase(onboardMandateFileCor.fulfilled, (state, action) => {
                state.isLoadingFileCor = false
                state.isSuccessFileCor = true
                state.mandateFileCor = action.payload
                state.isCleanupForm = true
            })
            .addCase(onboardMandateFileCor.rejected, (state, action) => {
                state.isLoadingFileCor = false
                state.isError = true
                state.mandateFileCor = {}
                state.message = action.payload
                state.isCleanupForm = false
            })

            // onboardMandateFileRegCert
            .addCase(onboardMandateFileRegCert.pending, (state) => {
                state.isLoadingFileRegCert = true
            })
            .addCase(onboardMandateFileRegCert.fulfilled, (state, action) => {
                state.isLoadingFileRegCert = false
                state.isSuccessFileRegCert = true
                state.mandateFileRegCert = action.payload
            })
            .addCase(onboardMandateFileRegCert.rejected, (state, action) => {
                state.isLoadingFileRegCert = false
                state.isErrorFileRegCert = true
                state.mandateFileRegCert = {}
                state.message = action.payload
            })

            // onboardMandateFileFinStatement
            .addCase(onboardMandateFileFinStatement.pending, (state) => {
                state.isLoadingFileFinStatement = true
            })
            .addCase(onboardMandateFileFinStatement.fulfilled, (state, action) => {
                state.isLoadingFileFinStatement = false
                state.isSuccessFileFinStatement = true
                state.mandateFileFinStatement = action.payload
            })
            .addCase(onboardMandateFileFinStatement.rejected, (state, action) => {
                state.isLoadingFileFinStatement = false
                state.isErrorFileFinStatement = true
                state.mandateFileFinStatement = {}
                state.message = action.payload
            })

            // onboardMandateFileCorpStrategy
            .addCase(onboardMandateFileCorpStrategy.pending, (state) => {
                state.isLoadingFileCorpStrategy = true
            })
            .addCase(onboardMandateFileCorpStrategy.fulfilled, (state, action) => {
                state.isLoadingFileCorpStrategy = false
                state.isSuccessFileCorpStrategy = true
                state.mandateFileCorpStrategy = action.payload
            })
            .addCase(onboardMandateFileCorpStrategy.rejected, (state, action) => {
                state.isLoadingFileCorpStrategy = false
                state.isErrorFileCorpStrategy = true
                state.mandateFileCorpStrategy = {}
                state.message = action.payload
            })





            // saveAccountMandate
            .addCase(saveAccountMandate.pending, (state) => {
                state.isLoading = true
            })
            .addCase(saveAccountMandate.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessAm = true
                state.messageAm = "We have sent a verification code to your phone."
                state.accountMandate = action.payload
                state.isCleanupForm = true
            })
            .addCase(saveAccountMandate.rejected, (state, action) => {
                state.isLoading = false
                state.isErrorAm = true
                state.accountMandate = {}
                state.messageAm = action.payload
                state.isCleanupForm = false
            })

            .addCase(sendOTP.pending, (state)=>{
                state.isProcessing=true
            })
            .addCase(sendOTP.fulfilled, (state,action) =>{
                state.isProcessing=false
                state.isSuccess=true
                state.sendOtpCode=action.payload
                state.message="OTP verified."
            })
            .addCase(sendOTP.rejected,(state,action)=>{
                state.isProcessing=false
                state.isError=true
                // state.message=action.payload
                state.message="OTP NOT VERIFIED PARTNER"
            })

    }
})

export const {
    reset,
    setInstitutionDetails,
    setEmailErrorDetails,
    setPasswordErrorDetails,
    setPhoneErrorDetails,
    setCoreIndicators,
    setOutcomeAreas,
    setMandate,
    setMandateFileCor,
    setMandateFileRegCert,
    setMandateFileFinancial,
    setMandateFileCorporate,
    cleanupForm } = partnerSlice.actions
export default partnerSlice.reducer