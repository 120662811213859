import React, { useEffect, useState } from 'react'
import CommittedProject from '../../../components/committed_project/CommittedProject'
import PageTitle from '../../../components/page_title/PageTitle'
import { Box, Divider, Grid, Skeleton } from '@mui/material'
import { Typography } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { getExpressionDemandData } from '../../../features/expression_demand/expressionDemandSlice'
import { useDispatch, useSelector } from 'react-redux'
import { formatPhoneNumber } from '../../../utils'
import ClassificationSkeleton from '../../../components/classification/ClassificationSkeleton'


function Step1CommittedProjects() {
  const { id } = useParams();
  const [expressLoading, setExpressLoading] = useState(false);
  const dispatch = useDispatch()
  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );


  const singleProjectData = projectData?.find(
    (item) => item?.projId === Number(id)
  );


  return (
    <>
      {
        expressLoading ? (
          <>
          <Skeleton width={1450} height={50} />
          <Skeleton width={1450} height={50} />
          </>
        ) : (
          <>
            <PageTitle currentPage="Project Preparation" subTitle="Committed Project" subHeader={singleProjectData?.accTradeName} />
            <Grid container spacing={2} sx={{ mt: '0px' }}>
              <Grid item xs={6} >
                <Divider sx={{ borderColor: 'var(--blue-grey, #7E93AE) !important' }} />
              </Grid>
              <Grid item xs={6}  >
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', margin: '35px 0rem 1rem 0rem' }} className='gap-5 '>
              <Typography className="fmw-technical-label-committed">
                {singleProjectData?.accTradeName}
              </Typography>
              <Divider orientation='vertical' className='divider-sub' />
              <Typography className="fmw-technical-label-committed">
                SSWP No: 004
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: '39px' }}>
              <Typography className="fmw-technical-label-committed" sx={{ fontSize: '20px !important' }}>
                Contact Person:  {singleProjectData?.usrFirstName} {singleProjectData?.usrLastName}   -   {formatPhoneNumber(singleProjectData?.usrMobileNumber)}
              </Typography>
            </Grid>
            <CommittedProject />
          </>
        )
      }

    </>
  )
}

export default Step1CommittedProjects
