import * as React from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { setStep2CommitteeDate3 } from '../../../../features/onboard_partner/onboardPartnerSlice';
import { dateForHumans } from '../../../../utils';
 


const MeetingsComDatePicker3 = ({formData}) => {
  const { step2CommitteeDate3 } = useSelector(state => state.onboardPartner)
  const { wspApplications } = useSelector((state) => state.wsp)
  const [isUpdating, setIsUpdating] = useState(false);
  const [valueReg, setValueReg] = React.useState(formData?.projMin3Date || step2CommitteeDate3.projCommitteeDate3);
  const dispatch = useDispatch();



  useEffect(() => {
    if (!isUpdating) {
      // TODO::
    } else {
      dispatch(setStep2CommitteeDate3({
        projCommitteeDate3: dateForHumans(valueReg.$d)
      }))
    }
  }, [dispatch, isUpdating, valueReg]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD-MM-YYYY"
          disableFuture="true"
          value={valueReg}
          onChange={(newValue) => {
            setValueReg(newValue);
            setIsUpdating(true);
          }}
          PopperProps={{
            placement: "bottom-end",
          }}
          renderInput={(params) => <TextField fullWidth  {...params}
            size="small"
            className="fmw-date-picker"

          />
          }
        />
      </LocalizationProvider>

    </>
  );
}
export default MeetingsComDatePicker3;