import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import { arrCleanUp } from '../../utils';
import { handleFailure } from '../utils';
import dealSourcingService from './dealSourcingService';
 
 
const initialState={
    isError:false,
    isProcessing: false,
    isLoading:false,
    isSuccess:false,

    isProcessingBidsPartner: false,
    isErrorBidsPartner:false,
    isSuccessBidsPartner:false,

    isProcessingBidsPartnerSingle: false,
    isErrorBidsPartnerSingle:false,
    isSuccessBidsPartnerSingle:false,

    isProcessingUpdateBids: false,
    isErrorUpdateBids:false,
    isSuccessUpdateBids:false,
    message:'',
    partnerBids:[],
    partnerBidsSingle:[],
    partnerBidsProjAcc: {},

    termsheet:{
        ltcLoanAmnt: '',
        ltcPurpose: '',
        ltcMaxLnAmnt: '',
        ltcMaxEqtyAmnt: '',
        ltcMaxLnTenure: '',
        ltcLoanInterest: '',
        ltcGraceRate: '',
        ltcInterestBasis: '',
        ltcInstallment: '',
        ltcPaymentMode: '',
        ltcDisbursement: '',
        ltcAppraisalMode: '',
        ltcOthersTerms: '',
        ltcContactualSavings: '',
        ltcSecurity: '',
    },
    isCleanupForm: false,
   
}
//saveTermsheet
export const saveTermsheet = createAsyncThunk('termsheet/save-termsheet',async(formData,thunkAPI)=>{
    try{
        return await dealSourcingService.saveTermsheet(formData)
    }catch(error){
        const message = handleFailure(error)  
        return thunkAPI.rejectWithValue(message)
        }
});
// fetchLoanTermConditionsPartnerBids
export const fetchLoanTermConditionsPartnerBids = createAsyncThunk('partner/fetchLoanTermConditionsPartnerBids', async (formData, thunkAPI) => {
    try {
        return await dealSourcingService.fetchLoanTermConditionsPartnerBids(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// fetchLoanTermUserId and ProjID
export const fetchBidsByUserProj = createAsyncThunk('partner/fetchBidsByUserProj', async (formData, thunkAPI) => {
    try {
        return await dealSourcingService.fetchBidsByUserProj(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// fetchLoanTermConditionsPartnerBidsSingle
export const fetchLoanTermConditionsPartnerBidsSingle = createAsyncThunk('partner/fetchLoanTermConditionsPartnerBidsSingle', async (formData, thunkAPI) => {
    try {
        return await dealSourcingService.fetchLoanTermConditionsPartnerBidsSingle(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//updateLoanTermConditionsPartnerBids
export const updateLoanTermConditionsPartnerBids = createAsyncThunk('partner/updateLoanTermConditionsPartnerBids', async (_formData, thunkAPI) => {
    try {
        return await dealSourcingService.updateLoanTermConditionsPartnerBids(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});



 
export const dealSourcingSlice = createSlice({
     name:'dealSourcing',
     initialState,
     reducers:{
         reset:(state)=>{
            state.isLoading=false
            state.isProcessing=false
            state.isError=false
            state.isSuccess=false

            state.isProcessingBidsPartner =  false
            state.isErrorBidsPartner = false
            state.isSuccessBidsPartner = false

            state.isProcessingBidsPartnerSingle =  false
            state.isErrorBidsPartnerSingle = false
            state.isSuccessBidsPartnerSingle = false

            state.isProcessingUpdateBids =  false
            state.isErrorUpdateBids = false
            state.isSuccessUpdateBids = false

            state.message = ''
            state.isCleanupForm = false
         },
         setTermsheetDetails: (state, action) => {
            state.termsheet = action.payload
        },
        cleanupForm:(state)=>{
            state.termsheet =  initialState.termsheet
        }
     },
     extraReducers:(builder)=>{
         builder

        .addCase(saveTermsheet.pending, (state)=>{
            state.isProcessing=true
        })
        .addCase(saveTermsheet.fulfilled, (state,action) =>{
            state.isProcessing=false
            state.isSuccess=true
            state.message="Termsheet info submitted successfully."
            state.partnerBids.unshift(action.payload)
        })
        .addCase(saveTermsheet.rejected,(state,action)=>{
            state.isProcessing=false
            state.isError=true
            state.message=action.payload
        })

        //fetchLoanTermConditionsPartnerBids
        .addCase(fetchLoanTermConditionsPartnerBids.pending, (state)=>{
            state.isProcessingBidsPartner=true
        })
        .addCase(fetchLoanTermConditionsPartnerBids.fulfilled, (state,action) =>{
            state.isProcessingBidsPartner=false
            state.isSuccessBidsPartner=true
            state.partnerBids = arrCleanUp(action.payload, "ltcId")
        })
        .addCase(fetchLoanTermConditionsPartnerBids.rejected,(state,action)=>{
            state.isProcessingBidsPartner=false
            state.isErrorBidsPartner=true
            state.message=action.payload
        })


        .addCase(fetchBidsByUserProj.pending, (state)=>{
            state.isProcessingBidsPartner=true
        })
        .addCase(fetchBidsByUserProj.fulfilled, (state,action) =>{
            state.isProcessingBidsPartner=false
            state.isSuccessBidsPartner=true
            state.partnerBidsProjAcc = action.payload[0]
        })
        .addCase(fetchBidsByUserProj.rejected,(state,action)=>{
            state.isProcessingBidsPartner=false
            state.isErrorBidsPartner=true
            state.message=action.payload
        })

        
          //fetchLoanTermConditionsPartnerBidsSingle
          .addCase(fetchLoanTermConditionsPartnerBidsSingle.pending, (state)=>{
            state.isProcessingBidsPartnerSingle=true
        })
        .addCase(fetchLoanTermConditionsPartnerBidsSingle.fulfilled, (state,action) =>{
            state.isProcessingBidsPartnerSingle=false
            state.isSuccessBidsPartnerSingle=true
            state.partnerBidsSingle = action.payload[0]
        })
        .addCase(fetchLoanTermConditionsPartnerBidsSingle.rejected,(state,action)=>{
            state.isProcessingBidsPartnerSingle=false
            state.isErrorBidsPartnerSingle=true
            state.message=action.payload
        })


        // updateLoanTermConditionsPartnerBids
        .addCase(updateLoanTermConditionsPartnerBids.pending, (state)=>{
            state.isProcessingUpdateBids=true
        })
        .addCase(updateLoanTermConditionsPartnerBids.fulfilled, (state,action) =>{
            state.isProcessingUpdateBids=false
            state.isSuccessUpdateBids=true
            state.message="Bids Status Updated successfully."
            state.partnerBids = action.payload
        })
        .addCase(updateLoanTermConditionsPartnerBids.rejected,(state,action)=>{
            state.isProcessingUpdateBids=false
            state.isErrorUpdateBids=true
            state.message=action.payload
        })
     }
 })

export const {
    reset ,
    setTermsheetDetails,
    cleanupForm
} = dealSourcingSlice.actions
export default dealSourcingSlice.reducer