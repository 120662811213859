export const TitleData = [
    {
        value: 'Mr',
        label: 'Mr',
    },
    {
        value: 'Miss',
        label: 'Miss',
    },
    {
        value: 'Mrs',
        label: 'Mrs',
    },
]
 