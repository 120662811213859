import React, { useEffect, useState } from 'react';
import Sewage from '../../assets/img/desin1-2.png';
import Logo from '../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg';
import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/joy';

import {
	CircularProgress,
	Grid,
	MenuItem,
	Paper,
	TextField,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Checkbox, Link, Typography, Button } from '@mui/joy';
import Header from '../../components/header/Header';
import './signin.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { toSentenceCase } from '../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

const RedditTextField = styled((props) => (
	<TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& .MuiFilledInput-root': {
		border: '1px solid var(--color-primary-light)',
		overflow: 'hidden',
		borderRadius: 0,
		color: '#646464',
		fontSize: '15px !important',
		backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			// borderColor: theme.palette.primary.main,
			borderLeft: '3px solid var(--color-info)',
		},
	},
}));

const SetPassword = ({}) => {
	const { userName, userId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [timer, setTimer] = useState(false);
	const [hasForgot, sethasForgot] = useState(false);
	const [forgotValue, setforgotValue] = useState('');
	const [codeSentMessage, setcodeSentMessage] = useState('');
	const [showOtpForm, setshowOtpForm] = useState(false);
	const [usrOtp, setusrOtp] = useState('');
	const [counter, setCounter] = useState(60);
	const [loadingResend, setLoadingResend] = useState(false);
	const [otpOpen, setotpOpen] = useState(false);
	const [showChangeForm, setshowChangeForm] = useState(false);
	const [usrEncryptedPassword2, setusrEncryptedPassword2] = useState('');
	const [passError, setpassError] = useState('');

	var format = /[@]/;

	const { user, isLoginProcessing, isLoginError, isLoginSuccess, message } =
		useSelector((state) => state.auth);
	const [formData, setFormData] = useState({
		usrUsername: '',
		usrEncryptedPassword: '',
	});

	const { usrUsername, usrEncryptedPassword } = formData;

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function hideEmail(target) {
		var email = target;
		var hiddenEmail = '';
		for (let i = 0; i < email.length; i++) {
			if (i > 2 && i < email.indexOf('@')) {
				hiddenEmail += '*';
			} else {
				hiddenEmail += email[i];
			}
		}
		return hiddenEmail;
	}

	function handleChangeNewPassword(e) {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		// let numbers = /[0-9]/g;
		let numbers = /\d/g;

		e.preventDefault();
		if (!usrEncryptedPassword2) {
			setpassError('Please confirm your new password');
			return false;
		} else if (usrEncryptedPassword?.length < 6) {
			return false;
		} else if (usrEncryptedPassword !== usrEncryptedPassword2) {
			return false;
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			return false;
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			return false;
		}
		// else if (!usrEncryptedPassword?.test(numbers)) {
		//   return false;
		// }setFormDat
		else {
			setpassError('');
			setLoadingResend(true);
			fetch(`${url}/usr/updateUser.action`, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify({
					usrId: userId,
					usrEncryptedPassword: usrEncryptedPassword,
				}),
			})
				.then((res) => res.json())
				.then((data) => {
					console.log(data);
					toast.success(`New password has been set. Log in to continue.`, {
						position: 'bottom-right',
						autoClose: false,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'colored',
						rtl: false,
					});
					setFormData({ usrEncryptedPassword: '' });
					setusrEncryptedPassword2('');
					setLoadingResend(false);
					setshowChangeForm(false);
					setshowOtpForm(false);
					sethasForgot(false);
					setotpOpen(false);
					navigate('/');
				});
		}
	}
	useEffect(() => {
		if (isLoginError) {
			toast.error(toSentenceCase(message));
		}
		setTimeout(() => {
			setcodeSentMessage('');
		}, 5000);

		if (isLoginSuccess) {
			handleClickOpenVerifySignin();
			toast.success({ toastId: 'verify' });
		}

		if (codeSentMessage) {
			toast.error(toSentenceCase(codeSentMessage));
		}
	}, [user, isLoginSuccess, isLoginError, message, dispatch, codeSentMessage]);

	async function handlePassResetValidation() {
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		let numbers = /[0-9]/g;

		if (!usrEncryptedPassword) {
			setpassError('');
		} else if (!usrEncryptedPassword?.match(lowerCaseLetters)) {
			await setpassError(
				'Password must contain atleast a lowercase character!',
			);
		} else if (!usrEncryptedPassword?.match(upperCaseLetters)) {
			await setpassError(
				'Password must contain atleast an uppercasecharacter!',
			);
		} else if (!usrEncryptedPassword?.match(numbers)) {
			await setpassError('Password must contain atleast a number!');
		} else if (usrEncryptedPassword?.length < 6) {
			await setpassError('Passwords must be atleast 6 characters long!');
		} else if (
			usrEncryptedPassword2 &&
			usrEncryptedPassword2?.length >= usrEncryptedPassword?.length &&
			usrEncryptedPassword !== usrEncryptedPassword2
		) {
			await setpassError('Password does not match');
		} else {
			setpassError('');
		}
	}

	useEffect(() => {}, [hasForgot]);

	// useEffect(() => {
	// 	if (showOtpForm) {
	// 		handleClose();
	// 	}
	// }, [showOtpForm]);

	useEffect(() => {
		if (counter > 0) {
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [counter]);

	useEffect(() => {
		handlePassResetValidation();
	}, [usrEncryptedPassword, usrEncryptedPassword2, passError]);

	useEffect(() => {}, [userId]);

	return (
		<>
			<Header />
			<Box
				sx={{
					backgroundBlendMode: 'multiply',
					backgroundColor: 'grey',
					backgroundImage: `url(${Sewage})`,
					height: '90vh',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					justifyItems: 'center',
					overflow: 'hidden',
				}}
			>
				<Box
					sx={{
						bgcolor: '#ffffff',
						width: { xs: '100%', md: '40%' },
						mt: '40px',
						height: '80%',
						borderRadius: '10px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						justifyItems: 'center',
						alignItems: 'center',
						overflow: 'hidden',
						// mb: "200px",
					}}
				>
					<img
						src={Logo}
						style={{
							height: 'auto',
							objectFit: 'cover',
							width: '50%',
							// marginLeft: "10%",
						}}
					/>

					<form
						onSubmit={(e) => handleChangeNewPassword(e)}
						style={{
							display: 'flex',
							justifyContent: 'center',
							justifyItems: 'center',
							flexDirection: 'column',
							width: '80%',
							// marginLeft: "10%",
							overflow: 'hidden',
						}}
					>
						<Typography sx={{ color: '#646464', mt: 2, mb: 0.5 }}>
							Hey {userName ?? 'User'}, please reset your account password to
							login to your account.
						</Typography>
						<RedditTextField
							name='usrEncryptedPassword'
							value={usrEncryptedPassword}
							onChange={handleChange}
							label='New password'
							placeholder=''
							type='password'
							id='reddit-input-new'
							variant='filled'
							style={{ marginTop: 11 }}
							fullWidth
							className='reddit-input'
							autoFocus
						/>

						<RedditTextField
							name='usrEncryptedPassword2'
							value={usrEncryptedPassword2}
							onChange={(e) => setusrEncryptedPassword2(e.target.value)}
							label='Confirm password'
							placeholder=''
							type='password'
							id='reddit-input-conf'
							variant='filled'
							style={{ marginTop: 11 }}
							fullWidth
							className='reddit-input'
						/>
						{passError ? (
							<FormHelperText className='upload-sub-label-error'>
								{passError}
							</FormHelperText>
						) : null}

						<Grid container spacing={3} sx={{ mt: 0.75 }}>
							<Grid item xs={12}>
								{!loadingResend ? (
									<Button
										// onClick={(e) => handleChangeNewPassword(e)}
										className='sign-btn'
										type='submit'
									>
										Change Password
									</Button>
								) : (
									<Button
										className='sign-btn'
										disabled
										variant='contained'
										sx={{ textTransform: 'none' }}
									>
										<CircularProgress size={17} color='inherit' />
										&nbsp;Loading...
									</Button>
								)}
							</Grid>
						</Grid>
					</form>
				</Box>
			</Box>
		</>
	);
};

export default SetPassword;
