import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/page_title/PageTitle';
import { Box, Divider, Grid, Skeleton } from '@mui/material';
import { Typography, Input } from '@mui/joy';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from '../../../utils';
import FileButton from '../../../components/button/FileButton';
import WaterDemandTable from '../../../components/committed_project/WaterDemandTable';
import CustomAccordion from '../../../components/accordion/custom_accordion';
import CustomTable from '../../../components/accordion/custom_table';
import RiskBpTable from './RiskBpTable';
import BusinessPlanTable from './BusinessPlanTable';
import FileHandler from '../../../components/custom_components/FileHandler';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomModal from '../../../components/custom_components/CustomModal';
import {
	fetchBidsByUserProj,
	fetchLoanTermConditionsPartnerBids,
    saveTermsheet,
} from '../../../features/deal_sourcing/dealSourcingSlice';
import { toast } from 'react-toastify';

const ViableStep3 = () => {
	const { id } = useParams();
	const [expressLoading, setExpressLoading] = useState(false);
	const [expanded, setExpanded] = React.useState('panel1');
	const { user } = useSelector((state) => state.auth);
	const { partnerBidsProjAcc } = useSelector((state) => state.dealSourcing);
    const navigate = useNavigate();

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const dispatch = useDispatch();
	const projectData = useSelector(
		(state) => state.expressionDemandData.expressionData,
	);

	const {
		wrRiskMitigationPlan,
		wrRiskMitigationPlanFile,
		wrDsCountyGovLetter,
		wrDsCountyGovLetterFile,
		wrDsWsspPresent,
		wrDsWspLetter,
		wrDsDelegatedSpa,
		wrDsDelegatedSpaFile,
	} = useSelector((state) => state?.committedProj?.committedd);

	const [formData, setFormData] = useState(partnerBidsProjAcc);
	const [filename, setfilename] = useState(partnerBidsProjAcc?.ltcTermsheetFile);
	const [openModal, setOpenModal] = useState(false);

	const handleInputChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function getFileUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			ltcTermsheetFile: data,
		}));
	}

	async function submitFucntion() {
        const newObj = {...formData}
		newObj.ltcProjId = id;
        newObj.ltcAccId = user?.usrAccId;
        await dispatch(saveTermsheet(newObj))
        await toast.success("Offer sent")
        await navigate('/viable-projects')
	}

	async function fetchLtcTerms() {
		const obj = {
			ltcProjId: id,
			ltcAccId: user?.usrAccId,
		};
		const resp = await dispatch(fetchBidsByUserProj(obj));
        await setFormData(resp?.payload[0])
	}

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	const singleProjectData = projectData?.find(
		(item) => item?.projId === Number(id),
	);

	function createData(name, desc, file) {
		return {
			name,
			desc,
			file,
		};
	}

	const environmentalData = [
		createData('Type of EIA Report Required', 'Yes', 'file'),
		createData('NEMA License available', 'Yes', 'file'),
		createData('EIA Report Provided', 'No', 'file'),
		createData('Are ALL stakeholders aware of project?', 'Yes', 'file'),
	];

	const riskManagement = [
		createData(
			'Risk Mitigation Plan ',
			wrRiskMitigationPlan,
			wrRiskMitigationPlanFile,
		),
	];

	const engineeringData = [
		createData('Map of supply area', 'Yes', 'file'),
		createData('Engineering design report', 'Yes', 'file'),
		createData('Drawings', 'No', 'file'),
		createData('Specifications', 'Yes', 'file'),
		createData('Costed Bills of Quantities', 'No', 'file'),
		createData('Construction Plan', 'Yes', 'file'),
		createData('Tender materials', 'No', 'file'),
	];

	const delegationData = [
		createData(
			'Letter of No Objection from County Government',
			wrDsCountyGovLetter,
			wrDsCountyGovLetterFile,
		),
		createData('Is SSWP in WSP area?', wrDsWsspPresent),
		createData('Letter of No Objection from WSP?', wrDsWspLetter),
		createData('Delegated SPA', wrDsDelegatedSpa, wrDsDelegatedSpaFile),
	];

	useEffect(() => {
		fetchLtcTerms();
	}, []);

    useEffect(() => {
        if (filename) {
            setfilename(partnerBidsProjAcc?.ltcTermsheetFile)
        }
    }, [filename])

	useEffect(() => {}, [id, partnerBidsProjAcc, formData]);

	return (
		<>
			{expressLoading ? (
				<>
					<Skeleton width={1450} height={50} />
					<Skeleton width={1450} height={50} />
				</>
			) : (
				<>
					<PageTitle
						currentPage='Project Preparation'
						subTitle='Committed Project'
						subHeader={singleProjectData?.accTradeName}
					/>
					<Grid container spacing={2} sx={{ mt: '0px' }}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6}></Grid>
					</Grid>

					<Box sx={{ mt: '46px' }}>
						<CustomAccordion
							expanded={expanded === 'panel1'}
							handleChange={handleChange('panel1')}
							title='Business plan '
						>
							<BusinessPlanTable viewOnly={true} />
						</CustomAccordion>
						<CustomAccordion
							expanded={expanded === 'panel2'}
							handleChange={handleChange('panel2')}
							title='Risk Mitigation Plan'
						>
							<CustomTable data={riskManagement} />
							<Typography
								sx={{
									mt: '49px',
									mb: '20px',
									fontSize: '16px',
									fontWeight: 400,
								}}
							>
								Risks to Business Plan
							</Typography>
							<RiskBpTable viewOnly={true} />
						</CustomAccordion>
						<CustomAccordion
							expanded={expanded === 'panel3'}
							handleChange={handleChange('panel3')}
							title=' Delegated Service Provision Agreement'
						>
							<CustomTable data={delegationData} />
						</CustomAccordion>

						<TableContainer
							component={Paper}
							elevation={0}
							sx={{ background: 'inherit', mt: 4 }}
						>
							<Typography sx={{ fontSize: '18px', fontWeight: 700, mb: 3 }}>
								Term sheet
							</Typography>

							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableHead>
									{/* <TableRow className='table'>
									<TableCell align='left'>Item</TableCell>
									<TableCell align='left'>Details</TableCell>
								</TableRow> */}
								</TableHead>
								<TableBody
									className='basic'
									sx={{
										borderBottom:
											'2px solid var(--color-light-blue) !important',
									}}
								>
									<TableRow>
										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											Loan (KES)
										</TableCell>

										<TableCell component='th' scope='row' sx={{ width: '50%' }}>
											<Input
												type='number'
												onChange={handleInputChange}
												name='ltcLoanAmnt'
												value={formData?.ltcLoanAmnt}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											Interest Rate (%)
										</TableCell>

										<TableCell component='th' scope='row' sx={{ width: '50%' }}>
											<Input
												type='number'
												onChange={handleInputChange}
												name='ltcLoanInterest'
												value={formData?.ltcLoanInterest}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											Loan Term (months)
										</TableCell>

										<TableCell component='th' scope='row' sx={{ width: '50%' }}>
											<Input
												type='number'
												onChange={handleInputChange}
												name='ltcMaxLnTenure'
												value={formData?.ltcMaxLnTenure}
												className='fmw-input-joy-mitigation'
											/>
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell
											component='th'
											scope='row'
											sx={{ width: '33.33%' }}
										>
											Term sheet attachment
										</TableCell>

										<TableCell component='th' scope='row' sx={{ width: '50%' }}>
											<FileHandler
												getFileUrl={getFileUrl}
												title={
													threeValues?.length
														? threeValues[1]
														: 'Attach term sheet'
												}
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<div className='flex justify-center mt-10 mb-20'>
							<button
								onClick={() => setOpenModal(true)}
								className='btn w-[240px] bg-[#0873B9] text-white py-5 rounded-lg '
							>
								Offer term sheet
							</button>
						</div>
					</Box>
				</>
			)}

			<CustomModal
				open={openModal}
				message={'Are you sure you want to bid on this project?'}
				message2={'NB: Once you submit, the action is irreversible'}
				setOpenModal={setOpenModal}
				okText={'Agree and bid'}
				submitFucntion={submitFucntion}
			/>
		</>
	);
};

export default ViableStep3;
