import { Box, Typography } from '@mui/joy'
import { Divider, Grid, Paper } from '@mui/material'
import React from 'react'

function RefillStations() {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <Typography component="h3" className="fmw-page-title">
                        Refill stations
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Divider />
                </Grid>

                <Grid item xs={7}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                        <Box sx={{ backgroundColor: '#475459' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Item
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography component="h6" sx={{ fontSize: '.925rem', p: 2, color: '#FFFFFF' }}>
                                        Details
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        John Doe
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Entire description
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Supply water in XYZ area with a customer base of 100 connections.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Project summary
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        The water utility is managed by Maji Safi Supplies, in addition to being responsible for all its maintenance, construction and operation are also for sewerage collection, treatment and disposal, road service and construction, town planning, and all the main utility services to the population. Maji Safi intends to convert the existing intermittent water supply into (24x7) continuous water supply by improving and upscaling the existing system, reducing present NRW, and improving the service level benchmark.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Impact
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Access to clean water, Entrepreneurship, Gender
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Valuation of the project
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        kes1,000,000/=
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Scale of repayment (Lowest 1, Highest 5)
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        3
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>

                        <Box>
                            <Grid container spacing={4}>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p" sx={{ p: 1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                        Recommendation
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} style={{}}>
                                    <Typography component="p" sx={{ p: 1.5 }} className="fmw-p">
                                        Well located area with potential clients.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>


                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default RefillStations