import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, } from '@mui/material';
import { Button, Typography } from '@mui/joy';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import DelegatedTable from './DelegatedTable';
import BillingTable from './BillingTable';
import MitigationTable from './MitigationTable';
import RiskBpTable from './RiskBpTable';
import BusinessPlanTable from './BusinessPlanTable';
import ConfirmationModal from '../../expression_demand/components/ConfrimationModal';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

function Step3() {
    const [expanded, setExpanded] = React.useState('panel1');
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const { id } = useParams();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const submitToDealRoom = async () => {
        setMessage("Are you sure you want to send the project to deal room");
        setOpenModal(true);
        setType("DEAL_SOURCING");
    };

    return (
        <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>

            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                className='fmw-accrdion-table fmw-accrd'
                elevation={0}
                sx={{ backgroundColor: expanded === 'panel1' ? '#fff' : 'inherit' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                        className='fmw-accrdion-svg'
                    />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                >

                    <Typography
                        className='gap-5 accordion-title'
                        sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                        <DragIndicator className='fmw-accrdion-drag-svg' />
                        Business plan
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px !important' }}>
                    <BusinessPlanTable viewOnly={false} />
                </AccordionDetails>
            </Accordion>

            <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                className='fmw-accrdion-table fmw-accrd'
                elevation={0}
                sx={{ backgroundColor: expanded === 'panel2' ? '#fff' : 'inherit' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
                        className='fmw-accrdion-svg'
                    />}
                    aria-controls='panel2bh-content'
                    id='panel2bh-header'
                >

                    <Typography
                        className='gap-5 accordion-title'
                        sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                        <DragIndicator className='fmw-accrdion-drag-svg' />
                        Risk mitigation plan
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px !important' }}>
                    <MitigationTable viewOnly={false} />


                    <Typography sx={{ mt: '60px', mb: '15px', padding: '0px 16px', fontSize: '16px', fontWeight: 500 }}>
                        What are 5 main risks to BP?
                    </Typography>

                    <Box>
                        <RiskBpTable viewOnly={false} />
                    </Box>

                </AccordionDetails>
            </Accordion>


            <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                className='fmw-accrdion-table fmw-accrd'
                elevation={0}
                sx={{ backgroundColor: expanded === 'panel3' ? '#fff' : 'inherit' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
                        className='fmw-accrdion-svg'
                    />}
                    aria-controls='panel3bh-content'
                    id='panel3bh-header'
                >

                    <Typography
                        className='gap-5 accordion-title'
                        sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                        <DragIndicator className='fmw-accrdion-drag-svg' />
                        Delegated service provision agreement
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px !important' }}>
                    <DelegatedTable viewOnly={false} />
                </AccordionDetails>
            </Accordion>


            <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                className='fmw-accrdion-table fmw-accrd'
                elevation={0}
                sx={{ backgroundColor: expanded === 'panel4' ? '#fff' : 'inherit' }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore sx={{ color: expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)' }}
                        className='fmw-accrdion-svg'
                    />}
                    aria-controls='panel3bh-content'
                    id='panel4bh-header'
                >

                    <Typography
                        className='gap-5 accordion-title'
                        sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                        <DragIndicator className='fmw-accrdion-drag-svg' />
                        Billing transition plan
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0px !important' }}>
                    <BillingTable viewOnly={false} />
                </AccordionDetails>
            </Accordion>

            <Grid container spacing={2} sx={{ mt: '79px', mb: '79px' }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        onClick={submitToDealRoom}
                        sx={{
                            fontSize: '16px', fontWeight: 500,
                            backgroundColor: 'var(--color-technical)',
                            width: '240px',
                            height: '55px',
                            borderRadius: '6px'
                        }}
                    >
                        Send to Deal Room
                    </Button>
                </Grid>
            </Grid>
            <ConfirmationModal
                open={openModal}
                message={message}
                setOpenModal={setOpenModal}
                projId={id}
                type={type}
            />

        </Box>
    )
}

export default Step3
