import { Checkbox, Typography } from '@mui/joy'
import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOutcomeAreas } from '../../../features/partner/partnerSlice';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';


function OutcomeAreas() {
    const dispatch = useDispatch();
    const { outcomeAreas } = useSelector(state => state.partner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState(outcomeAreas)
    const { accAreas1, accAreas2, accAreas3, accAreas4, accAreas5, accAreas6 } = formData

    const error = [accAreas1, accAreas2, accAreas3, accAreas4, accAreas5, accAreas6].filter((v) => v).length >= 2;
    const handleChange = (e) => {
        if (e.target.checked) {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
            setIsUpdating(true);

        } else {
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: ''
            }))
            setIsUpdating(true);
        }
    };

    useEffect(() => {
        if (!isUpdating) {

        } else {
            dispatch(setOutcomeAreas(formData))
        }
    }, [dispatch, isUpdating, formData])

    return (
        <>
            <FormControl
                required
                // error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
            >
                <Grid container spacing={2} style={{ marginBottom: '9rem' }}>
                    <Grid item xs={12}>
                        <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '0rem' }}>
                            Cross cutting output or outcome areas <span className="star">*</span>
                        </Typography>

                        <Typography variant="caption" className="fmw-checkbox-sub-label" sx={{ mb: '1rem' }}>
                            <FormLabel component="legend">
                                {error ?
                                    (<>Maximum Number Selected</>) :
                                    (<>Select a maximum of 2</>)
                                }
                            </FormLabel> </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="accAreas1"
                                            checked={accAreas1}
                                            value="Access to clean drinking water"
                                            onChange={handleChange}
                                            label="Access to clean drinking water"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas1}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="accAreas2"
                                            checked={accAreas2}
                                            value="Gender"
                                            onChange={handleChange}
                                            label="Gender"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas2}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="accAreas3"
                                            checked={accAreas3}
                                            value="Energy transition"
                                            onChange={handleChange}
                                            label="Energy transition"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas3}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox

                                            color="primary"
                                            name="accAreas4"
                                            checked={accAreas4}
                                            value="Entrepreneurship"
                                            onChange={handleChange}
                                            label="Entrepreneurship"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas4}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="accAreas5"
                                            checked={accAreas5}
                                            value="Employment"
                                            onChange={handleChange}
                                            label="Employment"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas5}
                                        />
                                    } />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="accAreas6"
                                            checked={accAreas6}
                                            value="Climate"
                                            onChange={handleChange}
                                            label="Climate"
                                            size="lg"
                                            variant="soft"
                                            className="fmw-checkbox-label"
                                            disabled={error && !accAreas6}
                                        />
                                    } />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </FormControl>

        </>
    )
}

export default OutcomeAreas 