import React, { useState } from 'react'
import { OperationalStaffData, TypeStaffData } from '../../Step3Data'
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Create, Delete, KeyboardArrowDown } from '@mui/icons-material';
import {
    CircularProgress, Divider, FormControl, FormLabel, Grid,
    IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton
} from '@mui/material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Input, Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStaff, fetchStaff, reset, saveStaff } from '../../features/onboard_partner/onboardPartnerSlice';
import OperationalStaffEdit from '../onboard_partner_modals/OperationalStaffEdit';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';

function OperationStaff() {
    const dispatch = useDispatch();
    const { isLoadingStaff, operationalStaff, isSuccessDelete, isSuccessUpdateStaff } = useSelector((state) => state.onboardPartner)
    const { wspApplications, isLoadingWsp } = useSelector((state) => state.wsp)

    const [isUpdating, setIsUpdating] = useState(false);
    const [staffData, setStaffData] = useState({
        osStaff: null,
        osNumber: '',
        osContract: null,
    });
    const { osStaff, osNumber, osContract } = staffData;

    const saveOperatingStaff = (e) => {
        if (!osStaff || !osNumber || !osContract) {
            toast.warning("Please fill all Staff Details")
        } else {
            dispatch(saveStaff({
                osProjId: wspApplications[0].projId,
                osStaff: osStaff,
                osNumber: osNumber,
                osContract: osContract,
            }));
            setStaffData({ osStaff: null, osNumber: '', osContract: null })
        }
    }
    const handleChangeStaff = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setStaffData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
    const handleDeleteStaff = (id) => {
        dispatch(deleteStaff({
            osId: id
        }));
        setIsUpdating(false);
    }
    const [stafff, setStafff] = useState()
    const [openStaff, setOpenStaff] = React.useState(false);

    const handleClickOpenStaff = () => {
        setOpenStaff(true);
    };
    const handleCloseStaff = () => {
        setOpenStaff(false);
    };
    useEffect(() => {
        if (!isUpdating && isSuccessDelete) {
            dispatch(fetchStaff({
                projId: wspApplications[0].projId
            }));
        } else if (!isUpdating || isSuccessUpdateStaff) {
            setTimeout(() => {
                dispatch(fetchStaff({
                    projId: wspApplications[0].projId
                }));
            }, 800)
            // dispatch(fetchWspApplications())
        } else if (!isUpdating) {
            setTimeout(() => {
                dispatch(fetchStaff({
                    projId: wspApplications[0].projId
                }));
            }, 800)

            dispatch(fetchWspApplications())
        }
        else {
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, isUpdating, isSuccessDelete, isSuccessUpdateStaff]);

    return (
        <>
            <Grid item xs={12}>
                <Typography component="h3" sx={{ my: 2, mb: 0 }}>
                    Operations staff
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Type of staff
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeStaff({
                            target: {
                                value: newval,
                                name: "osStaff"
                            }
                        })
                    }}
                    placeholder="Select position*"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="osStaff"
                    value={osStaff}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {OperationalStaffData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>
            <Grid item xs={4}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Number of operations staff
                </Typography>
                <Input
                    label=""
                    type="number"
                    value={osNumber}
                    name="osNumber"
                    onChange={handleChangeStaff}
                    size="lg"
                    className="fmw-input-joy"
                />
            </Grid>

            <Grid item xs={4}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Type of  contract
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeStaff({
                            target: {
                                value: newval,
                                name: "osContract"
                            }
                        })
                    }}
                    placeholder="Select contract*"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="osContract"
                    value={osContract}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {TypeStaffData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>

            <Grid item md={6} xs={12} >
            </Grid>
            <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                <FormControl fullWidth variant={"standard"}>
                    <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many operation staff members as possible*</FormLabel>
                    {!isLoadingStaff ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                            onClick={saveOperatingStaff}
                        >
                            {
                                operationalStaff?.length ? ' Add Operational Staff' : 'Save Operational Staff'
                            }
                           
                        </Button>
                    ) : (
                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                            <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                        </Button>
                    )}
                </FormControl>
            </Grid>

            {isLoadingStaff ? (
                <>
                    <Skeleton width={1450} height={30} />
                    <Skeleton width={1450} height={30} />
                </>
            ) : (
                <>
                    {operationalStaff?.length ? (<>
                        <Divider sx={{ my: 2 }} />
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow  >
                                        <TableCell>Type of staff</TableCell>
                                        <TableCell align="left" >Number</TableCell>
                                        <TableCell align="left" >Type of contract</TableCell>
                                        <TableCell align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {operationalStaff.length && operationalStaff.map((staff) => (
                                        <TableRow
                                            key={staff.osId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {staff.osStaff}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {staff.osNumber}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {staff.osContract}
                                            </TableCell>

                                            <TableCell align="left">
                                                <IconButton aria-label="delete" size="small" sx={{ mr: .5 }} onClick={() => setStafff(staff)}>
                                                    <Create fontSize="small" onClick={() => handleClickOpenStaff()} />
                                                </IconButton>

                                                <IconButton aria-label="delete" size="small">
                                                    <Delete fontSize="small" onClick={() => handleDeleteStaff(staff.osId)} />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    ) : (<></>)}

                </>
            )}
            <OperationalStaffEdit open={openStaff} handleClickOpen={handleClickOpenStaff} handleClose={handleCloseStaff} staffEdited={stafff} />
        </>
    )
}

export default OperationStaff
