import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormHelperText,
  Option,
  Select,
  selectClasses,
  TextField,
  Input,
  Typography,
} from "@mui/joy";
import {
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Article,
  CloudUploadOutlined,
  Create,
  Image,
  Delete,
  Download,
  KeyboardArrowDown,
  PictureAsPdf,
  Visibility,
  VisibilityOff,
  AttachFile,
} from "@mui/icons-material";
import { formatPath, previewPaper, subString } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import FinancialDatePicker from "./FinancialDatePicker";
import {
  onbaordFiles,
  setStep5,
  setSte54File,
  setStep5File,
  cleanupBank,
  onbaordBankFile,
  onbaordAssetFile,
  cleanupAsset,
  setBankFile,
  setAssetFile,
  saveBankInfo,
  fetchBankInfos,
  deleteBankInfo,
  reset,
  setPhoneErrorAuditor,
} from "../../../features/onboard_partner/onboardPartnerSlice";
import NumericFormat from "react-number-format";
import IncomeExpenditure from "./IncomeExpenditure";
import TariffSysytem from "./TariffSysytem";
import TariffOtherCharges from "./TariffOtherCharges";
import { useNavigate } from "react-router-dom";
import { fetchWspApplications, setEmailErrorDetails } from "../../../features/wsp/wspSlice";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BankEdit from "../../../components/onboard_partner_modals/BankEdit";
import BankPdf from "../../../components/onboard_wsp_files/BankPdf";
import AuditedReport from "../../../components/onboard_wsp_files/AuditedReport";
import AuditedReport2 from "../../../components/onboard_wsp_files/AuditedReport2";
import AuditedReport3 from "../../../components/onboard_wsp_files/AuditedReport3";
import { BillingData } from "../../../Step3Data";

function Step5() {
  const dispatch = useDispatch();
  const {
    step5,
    assetFile,
    bankFile,
    step5File,
    bankInfos,
    isSuccess,
    step5BankStmntFile,
    isSuccessDelete,
    isLoadingBank,
    errorPhoneAuditor,
    isSuccessUpdateBank,
  } = useSelector((state) => state.onboardPartner);
  const [isUpdating, setIsUpdating] = useState(false);
  const { wspApplications, sendProjId, isLoadingWsp,errorEmail } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState(wspApplications[0] || step5);
  const {
    projAuditorName,
    projAuditorFirm,
    projAuditorTelephone,
    projAuditorEmail,
    projBillingSystem,
  } = formData;
  const [file, setFile] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [fetchedAssetFile, setFetchedAssetFile] = useState(
    wspApplications[0].projAssets
  );

  const removeFetchedAssetFile = () => {
    setFetchedAssetFile("");
  };

  // bankStmnt
  const [fileBank, setFileBank] = useState("");
  const { nameBank, typeBank } = fileBank;
  const [bank, setBank] = useState("");
  const [errorBankFile, setErrorBankFile] = useState("");
  // bankStmnt
  const handleBankStmnt = (e) => {
		const _file = e.target.files[0];
		if (!_file) return;
		const allowedExtensions = /(\.pdf)$/i;
		const MAX_FILE_SIZE = 1e7; //10mb
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setErrorBankFile('Please upload a PDF file Only not more than 10mb');
			setBank('');
			return;
		}
		setFile(_file);
		setErrorBankFile('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameBank: _file.name,
				dateBank: _date,
				typeBank: _file.type,
				file_base64Bank: base64String.toString(),
			};
			setFileBank(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onbaordBankFile(formData));
	};

	const removeBankStmnt = () => {
		setFileBank({
			nameBank: '',
			dateBank: '',
			typeBank: '',
			file_base64Bank: undefined,
		});
		setIsUpdating(true);
		dispatch(cleanupBank());
	};

	const [bankkEdit, setBankkEdit] = useState();
	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [bankData, setBanKData] = useState({
		bName: '',
		bBranch: '',
		bAccNo: '',
		bAccName: '',
	});

	const { bName, bBranch, bAccNo, bAccName } = bankData;

	const handleChangeBank = (e) => {
		e.target.value < 0
			? (e.target.value = 0)
			: setBanKData((prevState) => ({
					...prevState,
					[e.target.name]: e.target.value,
			  }));
		setIsUpdating(true);
	};

	const saveBank = () => {
		if (!bName || !bBranch || !bAccNo || !bAccName || !nameBank) {
			toast.warning('Please fill all bank details');
		} else {
			dispatch(
				saveBankInfo({
					bProjId: wspApplications[0].projId,
					bName: bName,
					bBranch: bBranch,
					bAccNo: bAccNo,
					bAccName: bAccName,
					bFile: step5BankStmntFile.length > 0 ? step5BankStmntFile : null,
				}),
			);
			setBanKData({ bName: '', bBranch: '', bAccNo: '', bAccName: '' });
			setFileBank({ nameBank: '' });
		}
	};
	const handleDelete = (id) => {
		dispatch(
			deleteBankInfo({
				bId: id,
			}),
		);
		setIsUpdating(false);
	};
	// assetFile
	const [fileAsset, setFileAsset] = useState(assetFile);
	const { nameAsset, typeAsset } = fileAsset;
	const [asset, setAsset] = useState('');
	const [errorAssetFile, setErrorAssetFile] = useState('');

	const [fileArr, setFileArr] = useState(step5File);
	const { nameStep5, dateStep5, file_base64Step5, typeStep5 } = fileArr;

	const current = new Date();
	const year = current.getFullYear();
	const month = current.toLocaleString('default', { month: 'short' });
	const day = ('0' + current.getDate()).slice(-2);
	const _date = `${month} ${day},${year}`;

	// AssetFile
	const handleAssetFile = (e) => {
		const _file = e.target.files[0];
		if (!_file) return;
		const allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
		const MAX_FILE_SIZE = 1e7; //10mb
		if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
			setErrorAssetFile(
				'Please upload a PDF of Doc file of size not more than 10mb',
			);
			setAsset('');
			return;
		}
		setFile(_file);
		setErrorAssetFile('');
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result
				.replace('data:', '')
				.replace(/^.+,/, '');
			let imageObj = {
				nameAsset: _file.name,
				dateAsset: _date,
				typeAsset: _file.type,
				file_base64Asset: base64String.toString(),
			};
			setFileAsset(imageObj);
			setIsUpdating(true);
		};
		reader.readAsDataURL(_file);
		const formData = new FormData();
		formData.append('file', e.target.files[0]);
		dispatch(onbaordAssetFile(formData));
	};

	const removeAssetFile = () => {
		setFileAsset({
			nameAsset: '',
			dateAsset: '',
			typeAsset: '',
			file_base64Asset: undefined,
		});
		setIsUpdating(true);
		dispatch(cleanupAsset());
	};

	const [phoneNo, setPhoneNo] = useState(projAuditorTelephone);
	const [isValidPhone, setIsValidPhone] = useState(errorPhoneAuditor);
	const [focus, setFocus] = useState(false);

	const validatePhoneNumber = () => {
		const kenyanPhoneNumberRegex = /^254[17]\d{8}$|^2547\d{8}$/;
		setIsValidPhone(kenyanPhoneNumberRegex.test(phoneNo));
		setIsUpdating(true);
		setFocus(false);
	};
	const handleChangePhone = (e) => {
		setPhoneNo(e);
	};
	const handleFocus = (e) => {
		setFocus(true);
	};

	const [error, setError] = useState(errorEmail);
	console.log('errorstep5', error);
	const [focusEmail, setFocusEmail] = useState('');
	const isValidEmail = (projAuditorEmail) => {
		return /\S+@\S+\.\S+/.test(projAuditorEmail);
	};

	const handleValidateEmail = (e) => {
		if (!isValidEmail(projAuditorEmail)) {
			setError(true);
		} else {
			setError(false);
		}
		setIsUpdating(true);
		setFocusEmail(false);
	};
	const handleFocusEmail = (e) => {
		setFocusEmail(true);
	};

	const handleChange = (e) => {
		e.target.value < 0
			? (e.target.value = 0)
			: setFormData((prevState) => ({
					...prevState,
					[e.target.name]: e.target.value,
			  }));
		setIsUpdating(true);
	};

	const [projHandCash, setProjHandCash] = React.useState(
		step5.projHandCash || wspApplications[0].projHandCash,
	);
	const handleHandCash = (ev) => {
		ev.floatValue < 0
			? setProjHandCash((ev.floatValue = 0))
			: setProjHandCash(ev.floatValue);
		setIsUpdating(true);
	};

	const [projBankCash, setProjBankCash] = React.useState(
		step5.projBankCash || wspApplications[0].projBankCash,
	);
	const handleBankCash = (ev) => {
		ev.floatValue < 0
			? setProjBankCash((ev.floatValue = 0))
			: setProjBankCash(ev.floatValue);
		setIsUpdating(true);
	};

	const [projTotalDebtors, setProjTotalDebtors] = React.useState(
		step5.projTotalDebtors || wspApplications[0].projTotalDebtors,
	);
	const handleTotalDebtors = (ev) => {
		ev.floatValue < 0
			? setProjTotalDebtors((ev.floatValue = 0))
			: setProjTotalDebtors(ev.floatValue);
		setIsUpdating(true);
	};
	const [projLiquidAssets, setProjLiquidAssets] = React.useState(
		step5.projLiquidAssets || wspApplications[0].projLiquidAssets,
	);
	const handleLiquidAssets = () => {
		const sum =
			parseFloat(projHandCash) +
			parseFloat(projBankCash) +
			parseFloat(projTotalDebtors);
		sum < 0 ? setProjLiquidAssets((sum = 0)) : setProjLiquidAssets(sum);
		setIsUpdating(true);
	};

	const [projLiabilities, setProjLiabilities] = React.useState(
		step5.projLiabilities || wspApplications[0].projLiabilities,
	);
	const handleLiabilities = (ev) => {
		ev.floatValue < 0
			? setProjLiabilities((ev.floatValue = 0))
			: setProjLiabilities(ev.floatValue);
		setIsUpdating(true);
	};
	const [projNetFinances, setProjNetFinances] = React.useState(
		step5.projNetFinances || wspApplications[0].projNetFinances,
	);
	const handleNetFinances = () => {
		const sum = parseFloat(projLiquidAssets) - parseFloat(projLiabilities);
		sum < 0 ? setProjNetFinances((sum = 0)) : setProjNetFinances(sum);
		setIsUpdating(true);
	};

	const materialUiTextFieldProps = {
		required: true,
		// error: totalAmount > 100000,
		fullWidth: true,
		// label: "Total Amount",
		// InputProps: {
		//     startAdornment: <InputAdornment position="start">$</InputAdornment>
		// }
	};

	useEffect(() => {
		if (
			(!isUpdating || isSuccessDelete || isSuccessUpdateBank) &&
			user.usrJbrnId === 1
		) {
			setTimeout(() => {
				dispatch(
					fetchBankInfos({
						projId: wspApplications[0].projId,
					}),
				);
				dispatch(fetchWspApplications());
			}, 800);
			// setTimeout(() => {
			//     dispatch((fetchWspApplications()))
			// }, 800);
			const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(phoneNo);
			setIsValidPhone(isKenyanPhoneNumber);
		}
		if (
			(!isUpdating || isSuccessDelete || isSuccessUpdateBank) &&
			user.usrJbrnId === 0
		) {
			setTimeout(() => {
				dispatch(
					fetchBankInfos({
						projId: wspApplications[0].projId,
					}),
				);
				dispatch(
					fetchWspApplications({
						projId: sendProjId,
					}),
				);
			}, 800);
			const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(phoneNo);
			setIsValidPhone(isKenyanPhoneNumber);
		} else {
			dispatch(
				setStep5({
					// projFinDate: step5.projFinDate,
					projHandCash: projHandCash,
					projBankCash: projBankCash,
					projTotalDebtors: projTotalDebtors,
					projLiquidAssets: projLiquidAssets,
					projLiabilities: projLiabilities,
					projNetFinances: projNetFinances,
					projBillingSystem: projBillingSystem,
					projAuditorName: projAuditorName,
					projAuditorFirm: projAuditorFirm,
					projAuditorTelephone: phoneNo,
					projAuditorEmail: projAuditorEmail,
				}),
			);
			dispatch(setPhoneErrorAuditor(isValidPhone));
			dispatch(setAssetFile(fileAsset));
			dispatch(setStep5File(fileArr));
			dispatch(setEmailErrorDetails(error));
		}

		dispatch(reset());
	}, [
		isSuccessDelete,
		isUpdating,
		formData,
		fileArr,
		projHandCash,
		projBankCash,
		projTotalDebtors,
		projLiquidAssets,
		projLiabilities,
		projNetFinances,
		projBillingSystem,
		projAuditorName,
		projAuditorFirm,
		projAuditorTelephone,
		projAuditorEmail,
		fileAsset,
		error,
		isValidPhone,
		phoneNo,
		isSuccessUpdateBank,
		sendProjId,
	]);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Summary of financial positions
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={4}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              As on
            </Typography>
            <FinancialDatePicker wspApplications={wspApplications} />
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Cash in hand (petty cash)
          </Typography>
          <NumericFormat
            value={projHandCash}
            customInput={Input}
            placeholder="KES"
            className="fmw-input-joy"
            // className="fmw-input fmw-step4"
            onValueChange={handleHandCash}
            onKeyUp={handleLiquidAssets}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Cash in bank account(s)
          </Typography>
          <NumericFormat
            value={projBankCash}
            customInput={Input}
            placeholder="KES"
            className="fmw-input fmw-step4"
            onValueChange={handleBankCash}
            onKeyUp={handleLiquidAssets}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Total debtors (Money owed to project)
          </Typography>
          <NumericFormat
            value={projTotalDebtors}
            customInput={Input}
            placeholder="KES"
            className="fmw-input fmw-step4"
            onValueChange={handleTotalDebtors}
            onKeyUp={handleLiquidAssets}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Total liquid assets [(a) + (b) + (c)]
          </Typography>
          <NumericFormat
            value={projLiquidAssets}
            customInput={Input}
            placeholder="KES"
            className="fmw-input fmw-step4"
            disabled
            // onValueChange={handleLiquidAssets}
            onValueChange={handleNetFinances}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Liabilities(Money owed by project to others)
          </Typography>
          <NumericFormat
            value={projLiabilities}
            customInput={Input}
            placeholder="KES"
            className="fmw-input fmw-step4"
            onValueChange={handleLiabilities}
            onKeyUp={handleNetFinances}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Net financial position [(d) - (e)]
          </Typography>
          <NumericFormat
            value={projNetFinances}
            customInput={Input}
            placeholder="KES"
            className="fmw-input fmw-step4"
            disabled
            // onValueChange={handleNetFinances}
            thousandSeparator=","
            decimalSeparator="."
            {...materialUiTextFieldProps}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            What type of billing system do you use ?
          </Typography>
          <Select
            className="fmw-select"
            onChange={(e, newval) => {
              handleChange({
                target: {
                  value: newval,
                  name: "projBillingSystem",
                },
              });
            }}
            placeholder="Select billing type..."
            indicator={<KeyboardArrowDown />}
            size="lg"
            name="projBillingSystem"
            value={formData.projBillingSystem}
            sx={{
              width: "100%",
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            {BillingData.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <TariffSysytem wspApplications={wspApplications} />
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: "1rem" }}
          >
            Are there other charges ? <span className="star">*</span>
          </Typography>
          <TariffOtherCharges wspApplications={wspApplications} />
        </Grid>

        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0rem" }}
          >
            Most recent months financial data
          </Typography>
        </Grid>

        <Grid item md={6} xs={6} sx={{ mb: 1 }}>
          <FormControl variant={"standard"}>
            <FormLabel htmlFor="my-input" variant={"subtitle"}>
              NOTE: Download and fill sheet offline and load it *
            </FormLabel>
            <a
              href="https://fundmywater.com/myimages/Most_Recent_Month_Financial_Data.xlsx"
              style={{ textDecoration: "none" }}
              target="self"
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--bg-primary) !important",
                  color: "var(--color-primary)",
                }}
                variant="outlined"
                color="inherit"
                startDecorator={<Download />}
              >
                Download Most recent months financial data
              </Button>
            </a>
          </FormControl>
        </Grid>
        <Grid item xs={6} sx={{ mb: 1 }}>
          <IncomeExpenditure wspApplications={wspApplications} />
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0rem" }}
          >
            Contact for current auditor
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Name
          </Typography>
          <Input
            label=""
            type="text"
            value={formData.projAuditorName}
            name="projAuditorName"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
            
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Firm
          </Typography>
          <Input
            label=""
            type="text"
            value={formData.projAuditorFirm}
            name="projAuditorFirm"
            onChange={handleChange}
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Telephone
          </Typography>
          <ReactPhoneInput
            defaultCountry="ke"
            country="ke"
            className="flags"
            inputClass="fmw-phone"
            required
            searchClass="search-class"
            searchStyle={{
              margin: "0",
              width: "100% !important",
              height: "80px !important",
            }}
            enableSearchField
            disableSearchIcon
            value={phoneNo}
            onChange={handleChangePhone}
            onBlur={validatePhoneNumber}
            onFocus={handleFocus}
          />
          {phoneNo && !isValidPhone && !focus && (
            <FormHelperText className="upload-sub-label-error">
              Enter valid number !
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Email
          </Typography>
          <Input
            label=""
            type="text"
            value={projAuditorEmail}
            name="projAuditorEmail"
            onChange={handleChange}
            onBlur={handleValidateEmail}
            onFocus={handleFocusEmail}
            size="lg"
            className="fmw-input"
          />
          {projAuditorEmail && error && !focusEmail && (
            <FormHelperText className="upload-sub-label-error">
              Invalid email address
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0rem" }}
          >
            Bank information all bank accounts
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Bank
          </Typography>
          <Input
            label=""
            type="text"
            value={bName}
            name="bName"
            onChange={handleChangeBank}
            size="lg"
            className="fmw-input"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Branch
          </Typography>
          <Input
            label=""
            type="text"
            value={bBranch}
            name="bBranch"
            onChange={handleChangeBank}
            size="lg"
            className="fmw-input"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Account number
          </Typography>
          <Input
            label=""
            type="text"
            value={bAccNo}
            name="bAccNo"
            onChange={handleChangeBank}
            size="lg"
            className="fmw-input"
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            component="p"
            className="fmw-onboard-partner-sub-label"
            sx={{ mb: ".5rem" }}
          >
            Account name
          </Typography>
          <Input
            label=""
            type="text"
            value={bAccName}
            name="bAccName"
            onChange={handleChangeBank}
            size="lg"
            className="fmw-input"
          />
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              PDF of bank statements for last 12 months
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {!nameBank ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "none",
                    // border: '1px solid var(--color-primary-light)',
                    width: "337px",
                    height: "46px",
                  }}
                >
                  <label
                    className=""
                    style={{ cursor: "pointer", width: "100%", height: "100%" }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#F7F7F7",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#A0A0A0",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "20px",
                          width: "52px",
                        }}
                      >
                        <CloudUploadOutlined
                          sx={{
                            display: "flex",
                            fontSize: "27px",
                            justifyContent: "center",
                            alignItems: "center",
                            mx: "12px",
                            color: "#ffff",
                            backgroundColor: "#A0A0A0 !important",
                          }}
                        />
                      </div>
                      {errorBankFile ? (
                        <Typography
                          component="h6"
                          className="upload-sub-label-error"
                        >
                          {errorBankFile}
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          className="upload-sub-label"
                        >
                          Click here to upload
                        </Typography>
                      )}
                    </div>
                    <input
                      type="file"
                      name="nameBank"
                      value={nameBank}
                      accept=".pdf"
                      webkitdirectory
                      onChange={handleBankStmnt}
                      style={{ width: "0px", height: "0px" }}
                    />
                  </label>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid var(--color-primary-light)",
                    width: "337px",
                    height: "46px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "inherit",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                      width: "52px",
                    }}
                  >
                    <span style={{ margin: "0px 6px" }}>
                      {typeBank === "application/pdf" ? (
                        <PictureAsPdf
                          sx={{
                            fontSize: "40px",
                            color: "#f44336",
                          }}
                        />
                      ) : typeBank === "image/png" ||
                        "image/jpeg" ||
                        "image/gif" ? (
                        <Image
                          sx={{
                            fontSize: "40px",
                            color: "#263238",
                          }}
                        />
                      ) : (
                        <Article
                          sx={{
                            fontSize: "40px",
                            color: "#1976d2",
                          }}
                        />
                      )}
                    </span>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        backgroundColor:
                          "var(--color-primary-light) !important",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      component="h6"
                      className="fmw-upload-typography"
                    >
                      {subString(nameBank, 25)}
                    </Typography>

                    <Typography variant="caption" className="upload-sub-label">
                      uploaded on {_date}
                    </Typography>
                  </div>

                  <div>
                    <IconButton
                      aria-label="close"
                      onClick={removeBankStmnt}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                        mr: "6px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}></Grid>

        <Grid item md={6} xs={12}></Grid>
        <Grid item md={6} xs={12} sx={{ mt: 1 }}>
          <FormControl fullWidth variant={"standard"}>
            <FormLabel htmlFor="my-input" variant={"subtitle"}>
              NOTE: Add Bank information of all bank accounts *
            </FormLabel>
            {!isProcessing ? (
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "var(--bg-primary) !important",
                }}
                onClick={saveBank}
              >
                {
                  bankInfos?.length ? 'Add Bank' : 'Save Bank'
                }
                
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                <CircularProgress size={17} color="inherit" />
                &nbsp;Adding...
              </Button>
            )}
          </FormControl>
        </Grid>

        {isLoadingBank ? (
          <>
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
            <Skeleton width={1450} height={30} />
          </>
        ) : (
          <>
            {bankInfos?.length ? (
              <>
                <Divider sx={{ my: 2 }} />
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Bank</TableCell>
                        <TableCell align="left">Branch</TableCell>
                        <TableCell align="left">Account number</TableCell>
                        <TableCell align="left">Account name</TableCell>
                        <TableCell align="left">File</TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bankInfos.length &&
                        bankInfos.map((ban) => (
                          <TableRow
                            key={ban.bId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {ban.bName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ban.bBranch}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ban.bAccNo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ban.bAccName}
                            </TableCell>
                            <TableCell align="left">
                              <Chip
                                variant="soft"
                                className="file-chip-primary"
                                color="primary"
                                size="sm"
                                startDecorator={<AttachFile size="sm" />}
                                onClick={() => previewPaper(ban.bFile)}
                              >
                                {formatPath(ban.bFile)}
                              </Chip>
                            </TableCell>

                            <TableCell align="left">
                              <IconButton
                                aria-label="delete"
                                size="small"
                                sx={{ mr: 0.5 }}
                                onClick={() => setBankkEdit(ban)}
                              >
                                <Create
                                  fontSize="small"
                                  onClick={() => handleClickOpen()}
                                />
                              </IconButton>

                              <IconButton aria-label="delete" size="small">
                                <Delete
                                  fontSize="small"
                                  onClick={() => handleDelete(ban.bId)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Upload a list of all assets owned
              <Typography
                variant={"span"}
                sx={{ my: 2, fontSize: "13px", color: "#777" }}
              >
                (pdf/word){" "}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {fetchedAssetFile && fetchedAssetFile ? (
              <>
                <a
                  className="file-stepper-primary"
                  onClick={() => previewPaper(fetchedAssetFile)}
                >
                  {formatPath(fetchedAssetFile)}
                </a>
                <IconButton
                  aria-label="close"
                  onClick={removeFetchedAssetFile}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    mr: "6px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </>
            ) : (
              <>
                {!nameAsset ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                        // border: '1px solid var(--color-primary-light)',
                        width: "337px",
                        height: "46px",
                      }}
                    >
                      <label
                        className=""
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#A0A0A0",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              marginRight: "20px",
                              width: "52px",
                            }}
                          >
                            <CloudUploadOutlined
                              sx={{
                                display: "flex",
                                fontSize: "27px",
                                justifyContent: "center",
                                alignItems: "center",
                                mx: "12px",
                                color: "#ffff",
                                backgroundColor: "#A0A0A0 !important",
                              }}
                            />
                          </div>
                          {errorAssetFile ? (
                            <Typography
                              component="h6"
                              className="upload-sub-label-error"
                            >
                              {errorAssetFile}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              className="upload-sub-label"
                            >
                              Click here to upload
                            </Typography>
                          )}
                        </div>
                        <input
                          type="file"
                          name="nameAsset"
                          value={nameAsset}
                          accept=".pdf,.docx,.doc"
                          webkitdirectory
                          onChange={handleAssetFile}
                          style={{ width: "0px", height: "0px" }}
                        />
                      </label>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px solid var(--color-primary-light)",
                        width: "337px",
                        height: "46px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "inherit",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "5px",
                          width: "52px",
                        }}
                      >
                        <span style={{ margin: "0px 6px" }}>
                          {typeAsset === "application/pdf" ? (
                            <PictureAsPdf
                              sx={{
                                fontSize: "40px",
                                color: "#f44336",
                              }}
                            />
                          ) : typeAsset === "image/png" ||
                            "image/jpeg" ||
                            "image/gif" ? (
                            <Image
                              sx={{
                                fontSize: "40px",
                                color: "#263238",
                              }}
                            />
                          ) : (
                            <Article
                              sx={{
                                fontSize: "40px",
                                color: "#1976d2",
                              }}
                            />
                          )}
                        </span>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            backgroundColor:
                              "var(--color-primary-light) !important",
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          component="h6"
                          className="fmw-upload-typography"
                        >
                          {subString(nameAsset, 25)}
                        </Typography>

                        <Typography
                          variant="caption"
                          className="upload-sub-label"
                        >
                          uploaded on {_date}
                        </Typography>
                      </div>

                      <div>
                        <IconButton
                          aria-label="close"
                          onClick={removeAssetFile}
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                            mr: "6px",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <AuditedReport formData={formData} />
        </Grid>
        <Grid item xs={6}>
          <AuditedReport2 formData={formData} />
        </Grid>

        <Grid item xs={6}>
          <AuditedReport3 formData={formData} />
        </Grid>
      </Grid>

      <BankEdit
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        bankEdited={bankkEdit}
      />
    </>
  );
}

export default Step5;
