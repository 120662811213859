import { Box, Container, Paper } from '@mui/material';
// import Logo from '../../assets/img/fmw-logo.png';
import Logo from '../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg';
import React from 'react';
import { Typography, Stack } from '@mui/joy';
import email from '../../assets/img/email_received.svg';
import VerifyButton from '../../components/button/VerifyButton';

const VerifyEmail = () => {
	return (
		<Container>
			<Box>
				<img
					src={Logo}
					alt='logo'
					width='15%'
					height='15%'
					style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}
				/>
			</Box>

			<Box
				component={Paper}
				elevation={3}
				sx={{
					my: 6,
					mx: 'auto',
					p: 3,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					maxWidth: '600px',
				}}
			>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					spacing={4}
				>
					<Box sx={{}}>
						<img src={email} alt='Pic' width='150px' height='150px' />
					</Box>
					<Typography
						level='h2'
						sx={{ mt: 2, fontSize: '1.45rem', fontWeight: 'bold' }}
					>
						Verify your account
					</Typography>

					<Typography level='body2' sx={{ fontSize: '0.95rem', mx: 3 }}>
						Thank you for joining FundMyWater. We just sent a verification link
						to your email. Click on it to verify and get started!
					</Typography>

					<VerifyButton />
				</Stack>
			</Box>
		</Container>
	);
};

export default VerifyEmail;
