import { FilePresentOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/joy';
import { Grid, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../page_title/PageTitle';
import DealRoomSearch from '../deal_room_list/DealRoomSearch';
import { useDispatch, useSelector } from 'react-redux';
import DealList from '../deal_room_list/DealList';
import { useEffect } from 'react';
import { fetchLoanPerfections } from '../../features/project/projectSlice';

function LoanPerfectionList() {
	const { loanPerfections } = useSelector((state) => state.project);
    const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [search, setsearch] = useState('');

	function getSearchValue(val) {
		setsearch(val);
	}

    const loanPerfection = <DealList search={search} data={loanPerfections} subUrl={"loan-perfection"} />;

    async function hnadleGetLoanPerfections(){
        await dispatch(fetchLoanPerfections(user?.usrAccId))
    }

    useEffect(() => {
        hnadleGetLoanPerfections()
    }, [])

    useEffect(() => {
    
    }, [loanPerfections])

	return (
		<>
			<PageTitle
				currentPage='Loan perfection'
				subSubTitle={'Loan perfection'}
				subTitle={'Projects'}
				urlSubTitle={'/loan-perfection'}
			/>
			<DealRoomSearch getSearchValue={getSearchValue} />
            {
                loanPerfection
            }
		</>
	);
}

export default LoanPerfectionList;
