import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import SingleItem from '../../components/single_item';
import { useLocation } from 'react-router-dom';
import SingleAttachment from '../../components/single_attachment';
import { useSelector } from 'react-redux';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import SingleTableItem from '../../components/single_table_item';
import NoTableDataComponent from '../../../expression_demand/tables/NoTableDataComponent';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('', 159),
  createData('', 237),
  createData('', 159),
  createData('', 237),
  createData('', 237),
];



export default function BankTable({ handleChangeInput, trust, data,
  userComment,
  setUserComment,
  setMatrixInfo,
  matrixInfo,
  name,
  stepName,
  id,
  status,
  comment,
  setComment,

  setUserMatrixInfo,
  userMatrixInfo
}) {
  const { bankInformation, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Bank</TableCell>
            <TableCell align="left">Branch</TableCell>
            <TableCell>Account number</TableCell>
            <TableCell align="left">Account name</TableCell>
            <TableCell align="left">Attachment</TableCell>
          </TableRow>
        </TableHead>
        {bankInformation.length > 0 ? (<TableBody className='basic'>
          {bankInformation?.map((row) => (
            <TableRow
              key={row?.dirId}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                {row?.bName}
              </TableCell>
              <TableCell align="left">{row?.bBranch}</TableCell>
              <TableCell align="left">{row?.bAccNo}</TableCell>
              <TableCell align="left">{row?.bAccName}</TableCell>
              <TableCell align="left">
                <SingleAttachmentComplete
                  title={row?.bFile}
                  tableFile="true"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>) : <Box sx={{ display: 'flex', width: "40vw", justifyContent: "center" }}><NoTableDataComponent /></Box>}
      </Table>
      {/* {
        category =="Viable" && (
          <Box sx={{padding:'20px',mt:2}}>
          <SingleTableItem
              overallComment="true"
              userComment={userComment}
              setUserComment={setUserComment}
              setMatrixInfo={setMatrixInfo}
              matrixInfo={matrixInfo}
              name="director"
              stepName={stepName}
              id={data?.projId}

              maxScores="5"
              status={category}
              comment={comment}
              setComment={setComment}

              setUserMatrixInfo={setUserMatrixInfo}
              userMatrixInfo={userMatrixInfo}

            />
          </Box>
        )
      } */}

    </TableContainer>
  );
}