import { Checkbox, Chip, Option, Select, Input, Typography } from "@mui/joy";
import {
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Delete,
  KeyboardArrowDown,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import BasicDatePicker from "./BasicDatePicker";
import { setStep2 } from "../../../features/onboard_partner/onboardPartnerSlice";
import { selectClasses } from "@mui/joy/Select";
import {
  TypeOfRegistration,
  periodWaterServices,
} from "../data/TypeOfRegistration";
import { fetchWspApplications } from "../../../features/wsp/wspSlice";
import Statutory from "../../../components/onboard_wsp_files/Statutory";
import Director from "./Director";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import AgmMinDatePicker from "./AgmMinDatePicker";
import TypographySub from "../../../components/onboard_wsp_files/TypographySub";
import MinutesAgm from "../../../components/onboard_wsp_files/MinutesAgm";
import ArticlesOfAssociation from "../../../components/onboard_wsp_files/ArticlesOfAssociation";
import { PeriodsOrg } from "../data/PeriodsOrg";
import SeekLoan from "../../../components/onboard_wsp_files/SeekLoan";
import MinutesCommittee from "../../../components/onboard_wsp_files/MinutesCommittee";
import MeetingsComDatePicker from "./Dates/MeetingsComDatePicker";
import Statutory2 from "../../../components/onboard_wsp_files/Statutory2";
import Statutory3 from "../../../components/onboard_wsp_files/Statutory3";
import MinutesCommittee2 from "../../../components/onboard_wsp_files/MinutesCommittee2";
import MinutesCommittee3 from "../../../components/onboard_wsp_files/MinutesCommittee3";
import MeetingsComDatePicker2 from "./Dates/MeetingsComDatePicker2";
import MeetingsComDatePicker3 from "./Dates/MeetingsComDatePicker3";
import ManagementTool from "../../../components/Tables/ManagementTool";
import CertificateOfRegistration from "../../../components/onboard_wsp_files/CertificateOfRegistration";

function Step2() {
  const dispatch = useDispatch();
  const { step2 } = useSelector((state) => state.onboardPartner);
  const [isUpdating, setIsUpdating] = useState(false);
  const { wspApplications, sendProjId, isLoadingWsp } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);
  const [formDataa, setFormDataa] = useState(wspApplications[0] || step2);
  const {
    projRegTypeOther,
    projKraPin,
    projRegNo,
    projRegType,
    projOrgPeriod,
  } = formDataa;
 
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F7F7F7",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const handleChange = (e) => {
    setFormDataa((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };

  useEffect(() => {
    if (!isUpdating && user.usrJbrnId === 1) {
      dispatch(fetchWspApplications());
    } else if (!isUpdating && user.usrJbrnId === 0) {
      dispatch(
        fetchWspApplications({
          projId: sendProjId,
        })
      );
    } else {
      dispatch(
        setStep2({
          projRegNo: projRegNo,
          projRegType: projRegType,
          projRegTypeOther: projRegTypeOther,
          projKraPin: projKraPin,
          projOrgPeriod: projOrgPeriod,
        })
      );
    }
  }, [dispatch, isUpdating, formDataa, sendProjId]);

  // useEffect(() => {
  //     if (!isUpdating) {
  //         dispatch(fetchWspApplications())
  //     }

  //     else {
  //         dispatch(setStep2({
  //             projRegNo: projRegNo,
  //             projRegType: projRegType,
  //             projRegTypeOther: projRegTypeOther,
  //             projKraPin: projKraPin,
  //             projOrgPeriod: projOrgPeriod,
  //         }))
  //     }
  // }, [dispatch, isUpdating, formDataa, sendProjId]);

  return (
    <>
      {isLoadingWsp ? (
        <>
          <Skeleton width={1480} />
          <Skeleton width={1480} />
          <Skeleton width={1480} />
        </>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="h6"
              className="fmw-onboard-partner-label"
              style={{ marginBottom: "0.5rem" }}
            >
              Registration details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Type of registration
            </Typography>
            <Select
              className="fmw-select"
              onChange={(e, newval) => {
                handleChange({
                  target: {
                    value: newval,
                    name: "projRegType",
                  },
                });
              }}
              placeholder="Select type..."
              indicator={<KeyboardArrowDown />}
              size="lg"
              name="projRegType"
              value={formDataa.projRegType}
              sx={{
                width: "100%",
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              {TypeOfRegistration.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Grid>

          <Grid item xs={6}>
            <CertificateOfRegistration formData={formDataa} />
          </Grid>

          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Registration number
            </Typography>
            <Input
              label=""
              type="text"
              value={formDataa.projRegNo}
              name="projRegNo"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
          {formDataa.projRegType != "Other" ? (
            <></>
          ) : (
            <Grid item xs={6}>
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                if Other, Please specify
              </Typography>
              <Input
                label=""
                type="text"
                value={formDataa.projRegTypeOther}
                name="projRegTypeOther"
                onChange={handleChange}
                size="lg"
                className="fmw-input-joy"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              Date of registration
            </Typography>
            <BasicDatePicker formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              component="p"
              className="fmw-onboard-partner-sub-label"
              sx={{ mb: ".5rem" }}
            >
              KRA pin
            </Typography>
            <Input
              label=""
              type="text"
              value={formDataa.projKraPin}
              name="projKraPin"
              onChange={handleChange}
              size="lg"
              className="fmw-input-joy"
            />
          </Grid>
          <Grid item xs={6}>
            <Statutory formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <Statutory2 formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <Statutory3 formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <LightTooltip
              title="We are looking for organizations with 3 years of experience. You can continue applying even if you do not meet this requirement"
              arrow
            >
              <Typography
                component="p"
                className="fmw-onboard-partner-sub-label"
                sx={{ mb: ".5rem" }}
              >
                Period the organization has been providing water services
              </Typography>
            </LightTooltip>
            <Select
              className="fmw-select"
              onChange={(e, newval) => {
                handleChange({
                  target: {
                    value: newval,
                    name: "projOrgPeriod",
                  },
                });
              }}
              placeholder="Select period..."
              indicator={<KeyboardArrowDown />}
              size="lg"
              name="projOrgPeriod"
              value={formDataa.projOrgPeriod}
              sx={{
                width: "100%",
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
              }}
            >
              {PeriodsOrg.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TypographySub title="Date of last AGM" />
            <AgmMinDatePicker  formData={formDataa}/>
          </Grid>

          <Grid item xs={6}>
            <MinutesAgm formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <ArticlesOfAssociation formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <SeekLoan formData={formDataa} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="h6"
              className="fmw-onboard-partner-label"
              style={{ marginBottom: "0.5rem" }}
            >
              Minutes of last 3 committee meetings
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TypographySub title="Date of most recent committee meeting" />
            <MeetingsComDatePicker formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <MinutesCommittee formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <TypographySub title="Date of past committee meeting" />
            <MeetingsComDatePicker2 formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <MinutesCommittee2 formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <TypographySub title="Date of oldest committee meeting" />
            <MeetingsComDatePicker3 formData={formDataa} />
          </Grid>
          <Grid item xs={6}>
            <MinutesCommittee3 formData={formDataa} />
          </Grid>
        </Grid>
      )}
      <Director />
      <ManagementTool />
    </>
  );
}

export default Step2;
