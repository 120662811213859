import React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export default function NewsCard() {
	return (
		<>
			<Card sx={{ minWidth: '617px', flexGrow: 1 }}>
				<CardCover>
					<img
						className='newscard-img'
						src='https://www.norfund.no/app/uploads/2023/03/gallery-ds-X_tEarX6svc-unsplash-1-1024x683.jpg'
						srcSet='https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x'
						loading='lazy'
						alt=''
					/>
				</CardCover>
				<CardContent>
					<Typography
						level='h3'
						fontWeight='lg'
						textColor='#fff'
						mt={{ xs: 12, sm: 18, md: 40, lg: 40 }}
						sx={{ px: 4, pb: 2 }}
					>
						Borehole as a solution
					</Typography>

					<Typography sx={{ px: 4, fontSize: '14.5px' }} level='p' fontWeight='xs' textColor='#fff'>
						Improve water quality by reducing pollution, eliminating dumbing and
						minimizing release of harzadous chemicals and materials, halving the
						proportion on untreated wastewater.
					</Typography>
				</CardContent>
			</Card>
		</>
	);
}
