import React from 'react';
import LoanPerfectionList from '../../components/loan_perfection/LoanPerfectionList';

const LoanPerfection = () => {
	return (
		<>
			<LoanPerfectionList />
		</>
	);
};

export default LoanPerfection;
