import React from 'react';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';
import { Button } from '@mui/joy';

export default function MiniCard() {
	return (
		<>
			<Box sx={{ minWidth: '330px', flexGrow: 1 }}>
				<img
					className='minicard-img'
					src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwNoKe-ALlRGWfwHQFfauddjTotXhv0cwX-ImkCHT-L_JNV2uvRoupThUnYK7mujiwwmg&usqp=CAU'
					srcSet='https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x'
					loading='lazy'
					alt=''
				/>
				<Typography
					sx={{ py: 1 }}
					level='h6'
					fontWeight='lg'
					textColor='#2c2c2c'
				>
					Borehole as a solution
				</Typography>

				<Typography
					sx={{ fontSize: '13.5px' }}
					level='p'
					fontWeight='xs'
					textColor='#2c2c2c'
				>
					Improve water quality by reducing pollution, eliminating dumbing and
					minimizing release of harzadous chemicals and materials, halving the
					proportion on untreated wastewater.
				</Typography>

				<Button className='' sx={{ my: 3, font: 500, backgroundColor: '#26deff', width: '158px', borderRadius: '2px' }}>
					Read More
				</Button>
			</Box>
		</>
	);
}
