import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import ImageInput from '../../../components/committed_project/ImageInput';
import ChoiceSelector from '../ChoiceSelector';
import { Textarea, Typography } from '@mui/joy';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';
import CustomChoice from '../../../components/custom_components/CustomChoice';

export default function QuotationTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);

	const handleChange = async (e) => {
		await setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	function getFile1(data) {
		setFormData((prevState) => ({
			...prevState,
			qtQuotationsFile: data,
		}));
	}

	function getFile2(data) {
		setFormData((prevState) => ({
			...prevState,
			qtCheckoutFile: data,
		}));
	}

	function getFile3(data) {
		setFormData((prevState) => ({
			...prevState,
			qtEvaluationReportFile: data,
		}));
	}

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleFileChnages() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	let file1 = formData?.qtQuotationsFile
		?.substring(formData?.qtQuotationsFile?.lastIndexOf('/'))
		.split('_');

	let file2 = formData?.qtCheckoutFile
		?.substring(formData?.qtCheckoutFile?.lastIndexOf('/'))
		.split('_');

	let file3 = formData?.qtEvaluationReportFile
		?.substring(formData?.qtEvaluationReportFile?.lastIndexOf('/'))
		.split('_');

	useEffect(() => {
		handleFileChnages();
	}, [formData]);

	useEffect(() => {}, [formData, id, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
							<TableCell align='left'>File</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className='basic'>
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								At least 3 good quotations
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'qtQuotations'}
									choiceValue={formData?.qtQuotations}
									// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile1}
									title={file1?.length ? file1[1] : 'Attach quotations'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Suppliers/Contractors checked out
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'qtCheckout'}
									choiceValue={formData?.qtCheckout}
									// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile2}
									title={file2?.length ? file2[1] : 'Attach file'}
								/>
							</TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Quotation Evaluation Report
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'qtEvaluationReport'}
									choiceValue={formData?.qtEvaluationReport}
									// handleOnBlur={handleDelegatedServices}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getFile3}
									title={file3?.length ? file3[1] : 'Attach report'}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
