import React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

export default function PublicationCard() {
	return (
		<>
			<Box sx={{ width: '370px', height: '360px', mb: 3 }}>
				<img
					className='publication-img'
					src='https://wallpaperaccess.com/full/1550579.jpg'
					loading='lazy'
					alt=''
				/>
				{/* <CardContent>
					<Typography
						level='h3'
						fontWeight='lg'
						textColor='#000'
						mt={{ xs: 12, sm: 18, md: 40, lg: 40 }}
						sx={{ px: 4, pb: 2 }}
					>
						Borehole as a solution
					</Typography>
				</CardContent> */}
			</Box>
			<Typography level='h5' fontWeight='lg' textColor='#000'>
				Borehole as a solution
			</Typography>

            <hr style={{ marginTop: '20px'}}/>
		</>
	);
}
