export const GenFunds = [
    {
        value: 'Increase customers',
        label: 'Increase customers',
    },
    {
        value: 'Raise tariff',
        label: 'Raise tariff',
    },
    {
        value: 'Introduce flat fee',
        label: 'Introduce flat fee',
    },
    {
        value: 'Harambee',
        label: 'Harambee',
    },
    {
        value: 'Reduce operating costs',
        label: 'Reduce operating costs',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]
