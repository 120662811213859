import { Box } from '@mui/system';
import ChartHeader from './chartHeader';
import ChartInput from './chartInput';
import ChartUsers from './chartUsers';
import DefaultChatMsg from './defaultChatMsg';
import SearchMessage from './searchMessage';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';

function MessageList() {
	const [fileData, setfileData] = useState(null)
	const chatArr = useSelector((state) => state.messages.chatArray);
	const loading = useSelector((state) => state.messages.loadingChatHeads);
	const [serchValue, setserchValue] = useState("")
	const chatArrCopy = [...chatArr];
	const sortedChatArr = chatArrCopy.sort(
		(a, b) => b.chCreatedTime - a.chCreatedTime,
	);

	const loadArray = [1, 2, 3, 4, 5, 6, 7];

	function handleFetchFile(file){
		setfileData(file)
	}

	function handleSearchValue(val){
		setserchValue(val)
	}

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
				<Box
					sx={{
						border: 3,
						width: 380,
						height: '87vh',
						borderRadius: '5px',
						borderColor: '#f4f4f4',
					}}
				>
					<SearchMessage handleSearchValue={handleSearchValue} />

					<Box
						sx={{ overflowY: 'auto', maxHeight: '70vh', height: 'fit-content' }}
					>
						{loading && (
							<>
								{loadArray.map((ske) => {
									return (
										<div
											style={{
												padding: '10px',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
											}}
										>
											<Skeleton variant='circular' width={50} height={50} />
											<div style={{ marginLeft: '10px' }}>
												<Skeleton
													animation='wave'
													variant='text'
													width={170}
													sx={{ fontSize: '1rem' }}
												/>

												<Skeleton
													animation='wave'
													variant='text'
													width={90}
													sx={{ fontSize: '1rem' }}
												/>
											</div>
										</div>
									);
								})}
							</>
						)}
						{
							!loading && sortedChatArr?.length && serchValue ?
							null : null
						}

						{!serchValue && !loading && sortedChatArr.length ? (
							sortedChatArr.map((userData) => {
								return <ChartUsers key={userData.chId} userData={userData} />;
							})
						) : (
							null
						)}

						{
							!serchValue && !loading && !sortedChatArr?.length &&
							<Box
								sx={{
									p: 2,
									textAlign: 'center',
									fontWeight: 500,
								}}
							>
								No conversations available
							</Box>
						}
					</Box>
				</Box>

				<Box
					sx={{
						border: 3,
						width: '100%',
						height: '87vh',
						borderRadius: '5px',
						borderColor: '#f4f4f4',
					}}
				>
					<ChartHeader handleFetchFile={handleFetchFile} />

					<DefaultChatMsg fileData={fileData} />
				</Box>
			</Box>
		</>
	);
}

export default MessageList;
