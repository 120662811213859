import React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Paper, Box, Divider } from '@mui/material';
import { Button, Typography } from '@mui/joy';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      maxWidth: 'md'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
        
        <IconButton 
          aria-label="close"
          onClick={onClose}
          sx={{position: 'absolute', left: 22, top: 15 ,color: '#2C2C2C' }}>
          <CloseIcon />
        </IconButton>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 15, top: 10, color: (theme) => theme.palette.grey[500], display: 'none'}}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };


const ProjectDetails = ({open, handleClickOpen, handleClose}) => {
  return (
    <>
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />

            <DialogContent sx={{mx: 5, my:3}} className="finance-sourcing-dialog">

            <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column' }} variant="outlined">
                <Box sx={{ backgroundColor: '#475459' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component="h6" sx={{fontSize: '.925rem', p: 2, color: '#FFFFFF'}}>
                                Item
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" sx={{fontSize: '.925rem', p: 2, color: '#FFFFFF'}}>
                                Details
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{}}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p" sx={{ p:1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                Project summary
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ }}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p">
                                Access to clean water
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>

                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Typography component="p" className="fmw-p" sx={{ p:1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                Location
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ }}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p">
                                Nyandarua County
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>

                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{}}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p" sx={{ p:1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                Size of the project
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ }}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p">
                                KES 1M - 5M
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>

                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{}}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p" sx={{ p:1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                Period
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ }}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p">
                                1 year +
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>
                
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={6} style={{}}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p" sx={{ p:1.5, height: '100%', borderRight: '1px solid #0000001f' }}>
                                Project preparation amount
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ }}>
                            <Typography component="p" sx={{ p:1.5 }} className="fmw-p">
                                KES 1,000,000
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Box>


            </Paper>

            <Box sx={{ display: 'flex', justifyContent:'center', my: 2, mt: 4}}>
                <Button color="neutral" size="md" className="fmw-btn-primary" >
                    Finance project preparation
                </Button>
            </Box>

            </DialogContent>

        </BootstrapDialog>
    </>
  )
}

export default ProjectDetails