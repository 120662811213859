import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const registerWsp = async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/create_account.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const registerBizDetails =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/save_account.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const registerProjectDetails =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/save_project.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const fetchWspApplications =async(accId)=>{
    const response = await axios.get(`${API_URL}/usr/fetch_projects.action?accId=${accId}`)
 
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const fetchWspApplicationsPartner =async(partnerAccId)=>{
    const response = await axios.get(`${API_URL}/usr/fetch_projects.action?reqAccId=${partnerAccId}`)
 
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}

const fetchWspApplicationsPartnerOne =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetch_projects.action?projId=${projId}`)
 
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}


const sendOTP =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/verify_User.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData;
}

const verifyOTP =async(Id)=>{
    const response = await axios.post(`${API_URL}/usr/resend_otp.action?usrId=${Id}`, {
        headers: {
            'Accept': 'application/json',
        }
    })

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    let can = response.data.jsonData

    console.log("Can ===> ", can);

    return response.data.jsonData;
}

const wspService={
    registerWsp,
    registerBizDetails,
    registerProjectDetails,
    fetchWspApplications,
    fetchWspApplicationsPartner,
    fetchWspApplicationsPartnerOne,
    sendOTP,
    verifyOTP
}
export default wspService
