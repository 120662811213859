import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Input, Textarea } from "@mui/joy";
import React, { useState } from "react";
import SingleComment from "../../expression_demand/components/SingleComment";
import MatrixInput from "../../expression_demand/components/MatrixInput";
import UserMatrix from "../../expression_demand/components/UserMatrix";
import { useLocation } from "react-router-dom";
import { BorderColor } from "@mui/icons-material";

 
const SingleTableItem = ({
    overallComment, title, value, valueInput, noCommentScore,
    name, stepName, id, setOnblur, onBlur, maxScores, status, comment, setComment,
    userComment, setUserComment, setMatrixInfo, matrixInfo, row,
    userMatrixInfo, setUserMatrixInfo
}) => {
    const [editing, setEditing] = useState(false)
    console.log("editing,,,",editing)
    const [isUpdating, setIsUpdating] = useState(false)
    const location = useLocation();
    const category = location.pathname.split('/')[3]
    return (
        <>
            {
                category == 'Partner' ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {
                            !noCommentScore && (
                                <>
                                    {
                                        userComment[name] ? (<>
                                            <Typography>{userComment[name]}</Typography>
                                        </>) : (
                                            <Typography>No comment</Typography>
                                        )
                                    }


                                    <Box
                                        sx={{
                                            display: "flex",
                                            mt: 2,
                                            mb: 2,
                                            gap: 6,
                                            alignItems: "center",
                                        }}
                                    >

                                        <Typography
                                            className="gap-5"
                                            sx={{
                                                color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                            System score:

                                            <MatrixInput
                                                setMatrixInfo={setMatrixInfo}
                                                matrixInfo={matrixInfo}
                                                name={name}
                                                stepName={stepName}
                                                id={id}
                                                maxScores={maxScores}
                                            />
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: "300px",
                                                display: "flex",
                                                alignItems: "center",
                                                bgColor: "red",
                                                gap: 2,
                                            }}
                                        >
                                            <Typography sx={{
                                                display: "flex",
                                                alignItems: "center", gap: 1,
                                                color: 'var(--color-technical)'
                                            }}>
                                                Technical advisor score:
                                                <UserMatrix
                                                    setMatrixInfo={setUserMatrixInfo}
                                                    matrixInfo={userMatrixInfo}
                                                    name={name}
                                                    stepName={stepName}
                                                    id={id}
                                                    maxScores={maxScores}
                                                />
                                            </Typography>

                                        </Box>
                                    </Box>
                                </>
                            )
                        }

                    </Box>
                ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {
                            !noCommentScore && (
                                <>
                                    {
                                        (userComment[name] && (!editing)) ? (<>
                                            <Typography sx={{display:'flex',alignItems:'center',gap:3}}>{userComment[name]}  
                                            <Tooltip title="Edit comment" arrow placement="top" >
                                                <IconButton onClick={() => setEditing(!editing)} >
                                                    <BorderColor sx={{ fontWeight: 'inherit', fontSize: 'inherit', color: 'var(--color-technical)' }} />
                                                </IconButton>
                                            </Tooltip>
                                            </Typography>
                                        </>) : (
                                            <SingleComment
                                                userComment={userComment}
                                                setUserComment={setUserComment}
                                                name={name}
                                                stepName={stepName}
                                                id={id}
                                                setOnblur={setOnblur}
                                                onBlur={onBlur}
                                                maxScores={maxScores}
                                                status={status}
                                                comment={comment}
                                                setComment={setComment}
                                                setEditing={setEditing}
                                            />
                                        )
                                    }


                                    <Box
                                        sx={{
                                            display: "flex",
                                            mt: 0,
                                            mb: 2,
                                            gap: 6,
                                            alignItems: "center",
                                        }}
                                    >

                                        <Typography
                                            className="gap-5"
                                            sx={{
                                                color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>
                                            System score:

                                            <MatrixInput
                                                setMatrixInfo={setMatrixInfo}
                                                matrixInfo={matrixInfo}
                                                name={name}
                                                stepName={stepName}
                                                id={id}
                                                maxScores={maxScores}
                                            />
                                        </Typography>
                                        <Box
                                            sx={{
                                                width: "300px",
                                                display: "flex",
                                                alignItems: "center",
                                                bgColor: "red",
                                                gap: 2,
                                            }}
                                        >
                                            <Typography sx={{
                                                display: "flex",
                                                alignItems: "center", gap: 1
                                            }}>
                                                Your Score:
                                                <UserMatrix
                                                    setMatrixInfo={setUserMatrixInfo}
                                                    matrixInfo={userMatrixInfo}
                                                    name={name}
                                                    stepName={stepName}
                                                    id={id}
                                                    maxScores={maxScores}
                                                />
                                            </Typography>

                                        </Box>
                                    </Box>
                                </>
                            )
                        }

                    </Box>
                )
            }
        </>
    );
};

export default SingleTableItem;
