import React from "react";
import { Box, Button, Typography } from "@mui/joy";
import { Divider, Grid, Paper } from "@mui/material";
import SourcedProject from "../../components/sourced_project/SourcedProject";

const ProjectPreparation = () => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography component="h3" className="fmw-page-title" sx={{ mb: 1 }}>
            Project preparation
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "1rem" }}>
          <Typography
            component="h6"
            className="fmw-checkbox-label fmw-cl-lg text-blue"
            style={{ mb: ".5rem" }}
          >
            Sourced projects
          </Typography>
        </Grid>

        <>
          {/* <SourcedProject /> */}

        </>
      </Grid>
    </>
  );
};

export default ProjectPreparation;
