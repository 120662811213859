import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import committedService from './committedService';

const initialState = {
	committedd: [],
	committedFile: {},
	isError: false,
	isProcessing: false,
	isSuccess: false,
	message: '',
	isErrorCommitted: false,
	isProcessingCommitted: false,
	isSuccessCommitted: false,
	messageCommitted: '',

	specialMinute: null,
	wrSelectors: {
		wrWaterAdequacy: '',
		wrWaterReliablity: '',
	},
	waterQuality: {
		sufficientDemand: '',
		treatmentWorks: '',
		affectDemand: '',
	},
	waterCompliance: null,
	nemaCompliance: {
		complianceWra: '',
		complianceWraProcess: '',
		complianceWraOutstanding: '',
		nemaFull: '',
		nemaProcess: '',
		nemaOutstanding: '',
	},

	waterRisks: {
		none: '',
		pollution: '',
		upstream: '',
		drought: '',
	},
	hydrologicalReport: null,
	waterAnalysis: null,
	waterDemandAssesment: {
		existingIcs: '',
		existingKiosks: '',
		existingInstitution: '',
		existingProduction: '',
		existingNrw: '',
		existingConsumed: '',
		existingIc: '',
		existingZonal: '',
		existingMaster: '',
		futureIcs: '',
		futureKiosks: '',
		futureInstitution: '',
		futureProduction: '',
		futureNrw: '',
		futureConsumed: '',
		futureIc: '',
		futureZonal: '',
		futureMaster: '',
	},
	institution: {
		accountName: '',
		websiteLink: '',
		address: '',
		institutionType: '',
		accInstDesc: '',
		usrTitle: null,
		usrFullNames: '',
		usrEmail: '',
		usrMobileNumber: '',
	},

	isCleanupForm: false,
	waterResources:{},

	businessPlan: {},
	objectors: []
};

// attachCommittedFiles
export const attachCommittedFiles = createAsyncThunk(
	'partner/attachCommittedFiles',
	async (formData, thunkAPI) => {
		try {
			return await committedService.onboardFiles(formData);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

//saveCommittedDetails
export const saveCommittedDetails = createAsyncThunk(
	'committed/saveCommittedDetails',
	async (_formData, thunkAPI) => {
		try {
			return await committedService.saveCommittedDetails(_formData);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// fetchCommittedDetails
export const fetchCommittedDetails = createAsyncThunk(
	'committed/fetchCommittedDetails',
	async (wrProjId, thunkAPI) => {
		try {
			return await committedService.fetchCommittedDetails(wrProjId);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// fetchBusinessPlans
export const fetchBusinessPlan = createAsyncThunk(
	'committed/fetchBusinessPlan',
	async (bspProjId, thunkAPI) => {
		try {
			return await committedService.fetchBusinessPlan(bspProjId);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// saveBusinessPlan
export const saveBusinessPlan = createAsyncThunk(
	'committed/saveBusinessPlan',
	async (formData, thunkAPI) => {
		try {
			return await committedService.saveBusinessPlan(formData);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// fetchObjectors
export const fetchObjector = createAsyncThunk(
	'committed/fetchObjector',
	async (obProjId, thunkAPI) => {
		try {
			return await committedService.fetchObjector(obProjId);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

// saveObjector
export const saveObjector = createAsyncThunk(
	'committed/saveObjector',
	async (formData, thunkAPI) => {
		try {
			return await committedService.saveObjector(formData);
		} catch (error) {
			const message = handleFailure(error);
			return thunkAPI.rejectWithValue(message);
		}
	},
);

export const committedSlice = createSlice({
	name: 'committed',
	initialState,
	reducers: {
		reset: (state) => {
			state.isProcessing = false;
			state.isError = false;
			state.isSuccess = false;
			state.message = '';

			state.isErrorCommitted = false;
			state.isProcessingCommitted = false;
			state.isSuccessCommitted = false;
			state.messageCommitted = '';

			state.isCleanupForm = false;
		},
		setSpecialMinute: (state, action) => {
			state.specialMinute = action.payload;
		},
		setWrSelectors: (state, action) => {
			state.wrSelectors = action.payload;
		},
		setWaterQuality: (state, action) => {
			state.waterQuality = action.payload;
		},
		setWaterCompliance: (state, action) => {
			state.waterCompliance = action.payload;
		},
		setNemaCompliance: (state, action) => {
			state.nemaCompliance = action.payload;
		},
		setWaterRisks: (state, action) => {
			state.waterRisks = action.payload;
		},
		setHydrologicalReport: (state, action) => {
			state.hydrologicalReport = action.payload;
		},
		setWaterAnalysis: (state, action) => {
			state.waterAnalysis = action.payload;
		},

		setWaterDemand: (state, action) => {
			state.waterDemandAssesment = action.payload;
		},
		setInstitutionDetails: (state, action) => {
			state.institution = action.payload;
		},
		setWaterResources: (state, action) => {
			state.waterResources = action.payload;
		},

		cleanupFormCommitted: (state) => {
			state.specialMinute = initialState.specialMinute;
			state.wrSelectors = initialState.wrSelectors;
			state.waterQuality = initialState.waterQuality;
			state.waterCompliance = initialState.waterCompliance;
			state.nemaCompliance = initialState.nemaCompliance;
			state.waterRisks = initialState.waterRisks;
			state.hydrologicalReport = initialState.hydrologicalReport;
			state.waterAnalysis = initialState.waterAnalysis;
			state.waterDemandAssesment = initialState.waterDemandAssesment;
			state.institution = initialState.institution;
			state.waterResources = initialState.waterResources;
		},
	},
	extraReducers: (builder) => {
		builder
			//  attachCommittedFiles
			.addCase(attachCommittedFiles.pending, (state) => {
				state.isProcessing = true;
			})
			.addCase(attachCommittedFiles.fulfilled, (state, action) => {
				state.isProcessing = false;
				state.isSuccess = true;
				state.message = 'File uploaded successfully';
				state.committedFile = action.payload;
			})
			.addCase(attachCommittedFiles.rejected, (state, action) => {
				state.isProcessing = false;
				state.isError = true;
				state.message = action.payload;
				state.committedFile = {};
			})

			//  saveCommittedDetails
			.addCase(saveCommittedDetails.pending, (state) => {
				state.isProcessingCommitted = true;
			})
			.addCase(saveCommittedDetails.fulfilled, (state, action) => {
				state.isProcessingCommitted = false;
				state.isSuccessCommitted = true;
				state.messageCommitted = 'Details saved successfully.';
				state.committedd = action.payload;
			})
			.addCase(saveCommittedDetails.rejected, (state, action) => {
				state.isProcessingCommitted = false;
				state.isErrorCommitted = true;
				state.committedd = {};
				state.messageCommitted = action.payload;
			})

			//fetchCommittedDetails
			.addCase(fetchCommittedDetails.pending, (state) => {
				state.isProcessingCommitted = true;
			})
			.addCase(fetchCommittedDetails.fulfilled, (state, action) => {
				state.isProcessingCommitted = false;
				state.isSuccessCommitted = true;
				state.committedd = action.payload[0];
			})
			.addCase(fetchCommittedDetails.rejected, (state, action) => {
				state.isProcessingCommitted = false;
				state.isErrorCommitted = true;
				state.committedd = {};
				state.messageCommitted = action.payload;
			})

			//fetchBusinessPlan
			.addCase(fetchBusinessPlan.pending, (state) => {
				state.isProcessingCommitted = true;
			})
			.addCase(fetchBusinessPlan.fulfilled, (state, action) => {
				state.isProcessingCommitted = false;
				state.isSuccessCommitted = true;
				state.businessPlan = action.payload[0];
			})
			.addCase(fetchBusinessPlan.rejected, (state, action) => {
				state.isProcessingCommitted = false;
				state.isErrorCommitted = true;
				state.businessPlan = {};
				state.messageCommitted = action.payload;
			})


			//fetchObjectors
			.addCase(fetchObjector.pending, (state) => {
				state.isProcessingCommitted = true;
			})
			.addCase(fetchObjector.fulfilled, (state, action) => {
				console.log(action.payload);
				state.isProcessingCommitted = false;
				state.objectors = action.payload;
			})
			.addCase(fetchObjector.rejected, (state, action) => {
				state.isProcessingCommitted = false;
				state.isErrorCommitted = true;
			});
	},
});

export const {
	reset,
	setSpecialMinute,
	setWrSelectors,
	setWaterQuality,
	setWaterCompliance,
	setNemaCompliance,
	setWaterRisks,
	setHydrologicalReport,
	setWaterAnalysis,
	setWaterDemand,
	setInstitutionDetails,
	setWaterResources,
	cleanupFormCommitted,
} = committedSlice.actions;

export default committedSlice.reducer;
