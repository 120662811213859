import { Button, Checkbox, TextField, Chip, Typography, FormHelperText } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, Image, Create, Delete, KeyboardArrowDown, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {  cleanupStatutory3, onbaordStatutoryFile3, setStatutoryFile3 } from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';
import TypographySub from './TypographySub';


const Statutory3 = ({formData,Title}) => {
    const {  statutoryFile3, step3CopyId, isLoadingCopyId, } = useSelector((state) => state.onboardPartner)
    const [fileStatutory3, setFileStatutory3] = useState(statutoryFile3)
    const [isUpdating, setIsUpdating] = useState(false);
    const dispatch = useDispatch();
    const [fetchedStatutory3, setFetchedStatutory3] = useState(formData?.projStatu3File)

    const removeFetchedStatutory3 = () => {
        setFetchedStatutory3('')
        setIsUpdating(true);
        dispatch(cleanupStatutory3());
    }

    const { nameStatutory3, dateStatutory3, file_base64Statutory3, typeStatutory3 } = fileStatutory3;
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // statutory3 pdf
    const [errorStatutory3, setErrorStatutory3] = useState('')
    const [statutory3Pdf, setStatutory3Pdf] = useState(undefined)
    const [file, setFile] = useState(undefined)

    const handleStatutory3 = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];

        if (!_file) return;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorStatutory3('Please upload a PDF file not more than 10mb ');
            setStatutory3Pdf('');
            return;
        }
        setFile(_file);
        setErrorStatutory3('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameStatutory3: _file.name,
                dateStatutory3: _date,
                typeStatutory3: _file.type,
                file_base64Statutory3: base64String.toString()
            };
            setFileStatutory3(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordStatutoryFile3(formData));
    }

    const removeStatutory3 = () => {
        setFileStatutory3({
            nameStatutory3: '',
            dateStatutory3: '',
            typeStatutory3: '',
            file_base64Statutory3: undefined,
        });
    }
    const currentYear = new Date().getFullYear(); 
    const previousYear1 =  currentYear-1;
    const previousYear2 =  currentYear-2;
 
    useEffect(()=>{
        if(!isUpdating){
        }else{
            dispatch(setStatutoryFile3(fileStatutory3))
        }
    },[fileStatutory3])
    return (
        <>
                              <TypographySub title="Statutory returns to registrar of year" sub={previousYear2} superS="true"/>
            {
                fetchedStatutory3 ? (
                    <>
                        <a className='file-stepper-primary' onClick={() => previewPaper(fetchedStatutory3)}>
                            {formatPath(fetchedStatutory3)}</a>
                        <IconButton
                            aria-label="close"
                            onClick={removeFetchedStatutory3}
                            sx={{
                                color: (theme) => theme.palette.grey[500], mr: '6px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        {
                            !nameStatutory3 ? (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        border: 'none',
                                        // border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                    <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                </div>
                                                {
                                                    errorStatutory3 ? (
                                                        <Typography component="h6" className="upload-sub-label-error">
                                                            {errorStatutory3}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="caption" className="upload-sub-label">
                                                            Click here to upload
                                                        </Typography>
                                                    )
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                name='file'
                                                value={nameStatutory3}
                                                accept=".pdf"
                                                webkitdirectory
                                                onChange={handleStatutory3}
                                                style={{ width: '0px', height: '0px' }}
                                            />

                                        </label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                        border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '46px'
                                    }}>
                                        <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                            <span style={{ margin: "0px 6px" }}>
                                                {
                                                    typeStatutory3=== "application/pdf" ? (
                                                        <PictureAsPdf sx={{
                                                            fontSize: '40px',
                                                            color: '#f44336'
                                                        }} />
                                                    ) :
                                                        typeStatutory3=== "image/png" || "image/jpeg" || "image/gif" ? (
                                                            <Image sx={{
                                                                fontSize: '40px',
                                                                color: '#263238',
                                                            }} />
                                                        ) :
                                                            (
                                                                <Article sx={{
                                                                    fontSize: '40px',
                                                                    color: '#1976d2'
                                                                }} />
                                                            )
                                                }

                                            </span>
                                            <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography component="h6" className="fmw-upload-typography">
                                                {subString(nameStatutory3, 25)}
                                            </Typography>


                                            <Typography variant="caption" className="upload-sub-label">
                                                uploaded on  {_date}
                                            </Typography>
                                        </div>

                                        <div>
                                            <IconButton
                                                aria-label="close"
                                                onClick={removeStatutory3}
                                                sx={{
                                                    color: (theme) => theme.palette.grey[500], mr: '6px'
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default Statutory3
