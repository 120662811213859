import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import {
  fetchProjectScore,
  getPipeInformations,
  getStorageInformations,
  postScoreStep,
  setProjectScore,
} from "../../../features/expression_demand/expressionDemandSlice";
import { formatImgPath, handleOpenDoc } from "../../../utils";
import { useCallback } from "react";
import {
  fetchStaff,
  fetchWaterSrc,
} from "../../../features/onboard_partner/onboardPartnerSlice";
import ScrollToBottom from "../../messages/scrollToBottom";
import { Textarea } from "@mui/joy";
import MatrixInput from "../components/MatrixInput";
import FileButton from "../../../components/button/FileButton";
import { toast } from "react-toastify";
import NoTableDataComponent from "./NoTableDataComponent";
import UserMatrix from "../components/UserMatrix";
import SingleComment from "../components/SingleComment";

const ProjectDescription = ({ data, status }) => {
  const { scores } = useSelector((state) => state.expressionDemandData);
  const [onBlur, setOnBlur] = useState(true);
  const [comment, setComment] = useState("");
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const stepName = "PROJECT_DESCRIPTION";
  const dispatch = useDispatch();
  const {
    isLoadingWtrSrc,
    isSuccessDelete,
    isSuccessUpdateWtrSrc,
    step3WtrAnalysisPdf,
    step3MtrReadingPdf,
    step3PermitPdf,
    step3PumpPdf,
    wtrSrc,
  } = useSelector((state) => state.onboardPartner);

  const { isLoadingStaff, operationalStaff } = useSelector(
    (state) => state.onboardPartner
  );

  const matrixFields = [
    "projOtherExistingWaterSources",
    "projOtherWspSswp",
    "projExistingOperational",
    "projWraAbstrationPermit",
  ];

  const {
    projExistingOperational,
    projWaterSources,
    projReliabilityWaterSource,
    projOtherExistingWaterSources,
    projOtherExistingWaterSourcesDesc,
    projAverageVolumeDry,
    projAverageVolWet,
    projWaterQuality,
    projMasterMeterInstalledFunctional,
    projWraAbstrationPermit,
    projPermitFile,
    projOtherWspSswp,
    projOtherWspSswpDesc,
    projTypeOfSystem,
    projPictureOfPumpFile,
    projMeterReadingsFile,
    projWaterQualityFile,
    projId,
  } = data;

  const { storageInformation, pipeInformations, loading } = useSelector(
    (state) => state.expressionDemandData
  );

  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  const submitStep = () => {
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  // const updateMatrix = () => {
  //   setMatrixInfo({});
  //   setComment("Hi");
  //   scores?.forEach((score) => {
  //     const { scScore, scMethod } = score;
  //     setMatrixInfo((prevMatrixInfo) => ({
  //       ...prevMatrixInfo,
  //       [scMethod]: scScore,
  //     }));
  //   });
  //   scores[0]?.sctComment === null
  //     ? setComment("")
  //     : setComment(scores[0]?.sctComment);
  // };

  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      console.log(scUsrComment, "===============User Comment=================");
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
      // scores[0]?.sctComment === null
      //   ? setComment("")
      //   : setComment(scores[0].sctComment);
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  useEffect(() => {
  window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    fetchProjectScores();
  }, []);

  const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const fetchReduxData = async () => {
    const res = await dispatch(getPipeInformations(projId));
    const storage = await dispatch(getStorageInformations(projId));
    const staffInfo = await dispatch(fetchStaff({ projId }));
    const waterInfo = await dispatch(fetchWaterSrc({ projId }));
    console.log(res, storage, staffInfo, waterInfo);
  };

  useEffect(() => {
    console.log("COnsoled For the first TIme");
    fetchReduxData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchReduxData();
    }, 2000);
    console.log("COnsoled For the first second Time");
  }, []);

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };
  let counter = 0;
  useEffect(() => {}, [
    operationalStaff,
    storageInformation,
    pipeInformations,
    isLoadingStaff,
    counter,
    isLoadingWtrSrc,
  ]);

  console.log(counter, "================================");

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const tableOneData = [
    createData(
      "Is the project existing and operational ?",
      projExistingOperational,
      "projExistingOperational",
      5
    ),
    // createData(
    //   "What is the current Water Source(s) for the Water Project",
    //   projWaterSources,
    //   "projWaterSources"
    // ),
    // createData(
    //   "Reliability of water source",
    //   projReliabilityWaterSource,
    //   "projReliabilityWaterSource"
    // ),
    createData(
      "Other existing sources within the water project area of operation",
      projOtherExistingWaterSources,
      "projOtherExistingWaterSources",
      5
    ),
    // projOtherExistingWaterSources === "Yes" &&
    //   createData(
    //     "Description of other existing sources within the water project area of operation",
    //     projOtherExistingWaterSourcesDesc,
    //     "projOtherExistingWaterSourcesDesc"
    //   ),
    createData(
      "Is there a WSP or another SSWP operating within the same area of the applicant ",
      projOtherWspSswp,
      "projOtherWspSswp",
      5
    ),
    projOtherWspSswp === "Yes" &&
      createData(
        "Description of other WSP or another SSP operated by the this Organization",
        projOtherWspSswpDesc,
        "projOtherWspSswpDesc"
      ),
    createData(
      <>
        Average volume abstracted per day from all sources m<sup>3</sup>/day dry
        season
      </>,
      projAverageVolumeDry,
      "projAverageVolumeDry"
    ),
    createData(
      <>
        Average volume abstracted per day from source m<sup>3</sup>/day wet
        season
      </>,
      projAverageVolWet,
      "projAverageVolWet"
    ),
    // createData("Water quality at source", projWaterQuality, "projWaterQuality"),
    // createData(
    //   "Is a master meter installed and functional ?",
    //   projMasterMeterInstalledFunctional,
    //   "projMasterMeterInstalledFunctional"
    // ),
    // createData(
    //   "Is there a water regulatory authority (WRA) abstraction permit ?",
    //   projWraAbstrationPermit,
    //   "projWraAbstrationPermit",
    //   5
    // ),
    // createData("Type of system", projTypeOfSystem, "projTypeOfSystem"),
  ];

  const attachments = [
    createData(
      "PDF of water quality analysis of source water",
      projWaterQualityFile,
      "projWaterQualityFile"
    ),
    createData(
      "PDF of 12 months meter readings",
      projMeterReadingsFile,
      "projMeterReadingsFile"
    ),
    createData(
      "PDF of picture of pump- if pumped or hybrid",
      projPictureOfPumpFile,
      "projPictureOfPumpFile"
    ),
  ];

  return (
    <Box sx={{ bgColor: "red", width: "100%" }}>
      {/* <ScrollToBottom /> */}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Water source & infrastructure
      </Typography>

      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {tableOneData.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.description}
              </TableCell>
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {status === "Viable" &&
                matrixFields.includes(row.variableName) && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
              {(status === "Viable" || status === "Partner") &&
                matrixFields.includes(row.variableName) && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={row.maxScores}
                  />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Operations staffs information
      </Typography>
      {isLoadingStaff && operationalStaff?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Type of staff
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Number
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Type of contract
              </TableCell>
              {(status === "Viable" || status === "Partner") && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  comment
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {operationalStaff?.map((row) => (
              <TableRow
                key={row.osId}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.osStaff}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.osNumber}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.osContract}
                </TableCell>
              </TableRow>
            ))}
            {status === "Viable" && (
              <TableRow
                key={"operations_scores"}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  Operations scores
                </TableCell>
                {status === "Viable" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name="operationsScores"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={5}
                  />
                )}
                {status === "Viable" && (
                  <UserMatrix
                    setMatrixInfo={setUserMatrixInfo}
                    matrixInfo={userMatrixInfo}
                    name="operationsScores"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={5}
                  />
                )}
                {(status === "Viable" || status === "Partner") && (
                  <SingleComment
                    setMatrixInfo={setUserComment}
                    matrixInfo={userComment}
                    name="operationsScores"
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                    maxScores={5}
                  />
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
      {!isLoadingStaff && operationalStaff?.length === 0 && (
        <NoTableDataComponent />
      )}

      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Description of water sources
      </Typography>
      {isLoadingWtrSrc && wtrSrc?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Box sx={{ overflowX: "scroll" }}>
          <Table>
            <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
              <TableRow
                sx={{
                  borderLeft: "1px solid #475459",
                  borderRight: "1px solid #475459",
                }}
              >
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Source
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Reliability
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Quality
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Quality File
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Meter
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Meter File
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Permit
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Permit File
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  System
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Power
                </TableCell>
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  Pump
                </TableCell>
                {/* {status === "Viable" && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  System Matrix
                </TableCell>
              )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {wtrSrc?.map((row) => (
                <TableRow
                  key={row.wsId}
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                  }}
                  style={{ borderBottom: "1px solid #E4E4E4" }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsSource}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsReliability}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsQuality}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ textTransform: "capitalize" }}
                      onClick={() =>
                        handleOpenDoc(formatImgPath(row.wsQualityFile))
                      }
                    >
                      <Typography sx={{ display: "flex", gap: 1 }}>
                        View
                      </Typography>
                    </Button>
                    {/* {row.wsQualityFile} */}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsMasterMtr}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ textTransform: "capitalize" }}
                      onClick={() =>
                        handleOpenDoc(formatImgPath(row.wsMtrFile))
                      }
                    >
                      <Typography sx={{ display: "flex", gap: 1 }}>
                        View
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsWra}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ textTransform: "capitalize" }}
                      onClick={() =>
                        handleOpenDoc(formatImgPath(row.wsWraFile))
                      }
                    >
                      <Typography sx={{ display: "flex", gap: 1 }}>
                        View
                      </Typography>
                    </Button>
                    {/* {row.wsWraFile} */}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsSystem}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                      color: "#646464",
                    }}
                  >
                    {row.wsPower}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                      color: "#646464",
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{ textTransform: "capitalize" }}
                      onClick={() =>
                        handleOpenDoc(formatImgPath(row.wsPowerFile))
                      }
                    >
                      <Typography sx={{ display: "flex", gap: 1 }}>
                        View
                      </Typography>
                    </Button>

                    {/* {row.wsPowerFile} */}
                  </TableCell>
                  {/* {status === "Viable" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                  />
                )} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      {!isLoadingWtrSrc && wtrSrc?.length === 0 && <NoTableDataComponent />}

      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Total capacity of storage tanks within the project{" "}
        <span style={{ fontSize: "18px" }}>(&#x33a5;)</span>
      </Typography>
      {loading && storageInformation.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Tank no
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Material
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Size (&#x33a5;)
              </TableCell>
              {/* {status === "Viable" && (
                <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                  System Matrix
                </TableCell>
              )} */}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {storageInformation?.map((row) => (
              <TableRow
                key={row.stId}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.stId}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.stMaterial}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.stSize}
                </TableCell>
                {/* {status === "Viable" && (
                  <MatrixInput
                    setMatrixInfo={setMatrixInfo}
                    matrixInfo={matrixInfo}
                    name={row.variableName}
                    stepName={stepName}
                    id={data.projId}
                    setOnblur={setOnBlur}
                    onBlur={onBlur}
                  />
                )} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && storageInformation.length === 0 && <NoTableDataComponent />}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Extent of pipelines (diameter)
      </Typography>
      {loading && pipeInformations.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Size (diameter)
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Material
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Total length (m)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {pipeInformations?.map((row) => (
              <TableRow
                key={row.peSize}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.peSize}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.peMaterial}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                  }}
                >
                  {row.peTotalLength} (m)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && pipeInformations.length === 0 && <NoTableDataComponent />}
      <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
        Attachments
      </Typography>
      <Table>
        <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
          <TableRow
            sx={{
              borderLeft: "1px solid #475459",
              borderRight: "1px solid #475459",
            }}
          >
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Item
            </TableCell>
            <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
              Details
            </TableCell>
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                System Matrix
              </TableCell>
            )}
            {status === "Viable" && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Technical Advisor Matrix
              </TableCell>
            )}
            {(status === "Viable" || status === "Partner") && (
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                comment
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            borderLeft: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
          }}
        >
          {attachments?.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                {row.name}
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                  textTransform: "capitalize",
                }}
              >
                <FileButton file={row.description} />
              </TableCell>
            </TableRow>
          ))}
          {projPermitFile !== null && (
            <TableRow
              key={"permitIfAvailable"}
              sx={{
                borderLeft: "1px solid #E4E4E4",
                borderRight: "1px solid #E4E4E4",
              }}
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                  color: "#646464",
                }}
              >
                Water regulatory Authority (WRA) Abstraction Permit
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                  color: "#646464",
                  textTransform: "capitalize",
                }}
              >
                <FileButton file={projPermitFile} />
              </TableCell>
              {status === "Viable" && (
                <MatrixInput
                  setMatrixInfo={setMatrixInfo}
                  matrixInfo={matrixInfo}
                  name={"projPermitFile"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
              {status === "Viable" && (
                <UserMatrix
                  setMatrixInfo={setUserMatrixInfo}
                  matrixInfo={userMatrixInfo}
                  name={"projPermitFile"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
              {(status === "Viable" || status === "Partner") && (
                <SingleComment
                  setMatrixInfo={setUserComment}
                  matrixInfo={userComment}
                  name={"projPermitFile"}
                  stepName={stepName}
                  id={data.projId}
                  setOnblur={setOnBlur}
                  onBlur={onBlur}
                  maxScores={5}
                />
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {status === "Viable" && `Total score: ${total} Points`}
      </Typography>
      {(status === "COMMITTED" ||
        status === "DEAL_SOURCING" ||
        status === "Viable" ||
        status === "Partner") && (
        <>
          <Typography sx={{ my: 2, color: "#0069DF", fontWeight: "bold" }}>
            Comments on above information
          </Typography>

          <Textarea
            value={comment}
            disabled={status !== "Viable"}
            onChange={(e) => setComment(e.target.value)}
            minRows={3}
            name="projSummary"
            placeholder={status !== "Viable" ? "N/A" : "write here..."}
            size="lg"
            className="fmw-textarea"
            color={`${
              status === "Partner"
            } ? "red !important" : "#646464 !important"`}
            sx={{ width: "100%", color: "#646464" }}
          />
          {status === "Viable" && (
            <Typography sx={{ textAlign: "end" }}>
              <Button
                onClick={submitStep}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                submit
              </Button>
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default ProjectDescription;
