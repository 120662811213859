import { Typography } from '@mui/joy';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './publications.css';
import { Box, Container, Grid } from '@mui/material';
import PublicationCard from '../../components/publication/PublicationCard';

export default function Publication() {
	return (
		<>
			<Header />
			<Container sx={{ mb: 13, minWidth: '100%', py: 3, px: 15 }}>
				<Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
					<Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>

                    <Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>

                    <Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>

                    <Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>

                    <Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>

                    <Box sx={{mx: 3, my: 5}} className='publication-grid'>
						<PublicationCard />
					</Box>
                    
				</Box>
			</Container>
			<Footer />
		</>
	);
}
