import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Checkbox, Link, Typography, Button } from "@mui/joy";
import "./signin.css";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { login, reset } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { CssVarsProvider } from "@mui/joy/styles";
import VerifyLogin from "../verify_login/VerifyLogin";
import VerifyButton from "../../components/button/VerifyButton";
import ShowPassword from "./ShowPassword";

const RedditTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    border: "1px solid var(--color-primary-light)",
    overflow: "hidden",
    borderRadius: 0,
    color: "#646464",
    fontSize: "15px !important",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      // borderColor: theme.palette.primary.main,
      borderLeft: "3px solid var(--color-info)",
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    maxWidth: "md",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", left: 22, top: 15, color: "#2C2C2C" }}
      >
        <ArrowBackIcon />
      </IconButton>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 10,
            color: (theme) => theme.palette.grey[500],
            display: "none",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SigninRegister = ({ open, handleClickOpen, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false)
  const handleShowPassowrd = () => {
    setShow(!show)
  }

  const [timer, setTimer] = useState(false);
  const { user, isLoginProcessing, isLoginError, isLoginSuccess, message } =
    useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    usrUsername: "",
    usrEncryptedPassword: "",
  });

  const { usrUsername, usrEncryptedPassword } = formData;

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [openVerifySignin, setOpenVerifySignin] = React.useState(false);

  const handleClickOpenVerifySignin = () => {
    setOpenVerifySignin(true);
    setTimeout(() => {
      setTimer(true);
    }, 60000);
  };
  const handleCloseVerifySignin = () => {
    setOpenVerifySignin(false);
  };

  const signIn = (e) => {
    e.preventDefault();
    if (!usrUsername || !usrEncryptedPassword) {
      toast.warning("Please fill in email & password fields");
    } else {
      dispatch(
        login({
          usrUsername: usrUsername,
          usrEncryptedPassword: usrEncryptedPassword,
        })
      );
    }
  };

  useEffect(() => {
    if (isLoginError) {
      toast.error(message);
    }

    if (isLoginSuccess) {
      handleClickOpenVerifySignin();
      toast.success({ toastId: "verify" });
    }

    return () => {
      dispatch(reset());
    };
  }, [user, isLoginSuccess, isLoginError, message, open, dispatch]);

  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        />

        <DialogContent sx={{ mx: 5, my: 3 }} className="signin-dialog">
          <form onSubmit={signIn}>
            <Typography component="h4" className="meta-title signin-title">
              Log in
            </Typography>

            <Typography component="body2" className="highlighter signin-h" />

            <Typography component="p" className="signin-tagline">
              Please log in to your account to complete the funding application
            </Typography>

            <RedditTextField
              name="usrUsername"
              value={usrUsername}
              onChange={handleChange}
              label="Email address"
              placeholder="john@doe.com"
              type="email"
              id="reddit-input"
              variant="filled"
              style={{ position: 'relative', marginTop: 11 }}
              fullWidth
              className="reddit-input"
              autoFocus
            />

            <ShowPassword show={show} handleShowPassowrd={handleShowPassowrd} />
            <RedditTextField
              name="usrEncryptedPassword"
              value={usrEncryptedPassword}
              onChange={handleChange}
              label="Password"
              placeholder=""
              type={show ? 'text' : 'password'}
              id="reddit-input"
              variant="filled"
              style={{ marginTop: 11 }}
              fullWidth
              className="reddit-input"
            />

            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={7}>
                <Checkbox
                  color="primary"
                  label="Remember me"
                  size="md"
                  variant="soft"
                  className="fmw-checkbox-label"
                />
              </Grid>

              <Grid item xs={5}>
                <Link
                  color="primary"
                  underline="none"
                  className="fmw-link"
                  sx={{ fontSize: "15px", textAlign: "right" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 0.75 }}>
              <Grid item xs={12}>
                {!isLoginProcessing ? (
                  <Button
                    className="sign-btn"
                    type="submit"
                  //  onClick={handleClickOpenVerifySignin}
                  >
                    Log in
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    sx={{ textTransform: "none" }}
                  >
                    <CircularProgress size={17} color="inherit" />
                    &nbsp;Loading...
                  </Button>
                )}
              </Grid>

              {/* <Grid item xs={5}>
              <Button color="neutral" className="signup-btn">
                Sign up
              </Button>
            </Grid> */}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>

      <CssVarsProvider />

      <VerifyLogin
        open={openVerifySignin}
        loginSucess={isLoginSuccess}
        handleClickOpen={handleClickOpenVerifySignin}
        user={user}
        isError={isLoginError}
        isVerifySuccess={isLoginSuccess}
        message={message}
        handleClose={handleCloseVerifySignin}
        timer={timer}
      />
    </>
  );
};

export default SigninRegister;
