import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SingleAttachment from '../../components/single_attachment';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
 
 
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function Attachments({data}) {
  const rows = [
    createData('Most recent months financial data', data?.projIncomeExpenditureFile),
    createData('List of all assets owned', data?.projAssets ),
    // createData('PDF of bank statements for last 12 months', data?.projBankStatementFile ),
    createData('First Audit Report', data?.projAudiAccFile),
    createData('Second audit report', data?.projAudiAccFile2 ),
    createData('Third audit report', data?.projAudiAccFile3 ),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic'>
          {rows.map((row) => (
            <TableRow
              key={row?.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important',width:'50%' }}>
                {row?.name}
              </TableCell>
              <TableCell align="left" sx={{width:'50%'}}> 
              <SingleAttachmentComplete
                  title={row?.calories}
                  tableFile="true"
                />
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    
    </TableContainer>
  );
}