import {
  Article,
  AttachFile,
  CloudUploadOutlined,
  PictureAsPdf,
} from "@mui/icons-material";
import { Chip, Grid, Input, FormHelperText, Typography } from "@mui/joy";
import { Divider, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { formatPath, previewPaper, subString } from "../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  onboardRegFile,
  setRegFile,
  setTaxFile,
  setOrgDetails,
  setPasswordErrorDetails,
} from "../../../features/tech_adv/techAdvSlice";
import { toast } from "react-toastify";
import CertificateOfReg from "./CertificateOfReg";

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { orgDetails, errorPassword } = useSelector((state) => state.techAdv);
  const [formData, setFormData] = useState(orgDetails);
  const [isUpdating, setIsUpdating] = useState(false);
  const [passwordError, setPasswordErr] = useState(errorPassword);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const {
    accTradeName,
    accRegNo,
    accWebsite,
    accPinCert,
    usrEncryptedPassword,
    usrConfirmPassword,
  } = formData;
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;

    //for password
    if (passwordInputFieldName === 'usrEncryptedPassword') {
			const uppercaseRegExp = /(?=.*?[A-Z])/;
			const lowercaseRegExp = /(?=.*?[a-z])/;
			const digitsRegExp = /(?=.*?[0-9])/;
			const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
			const minLengthRegExp = /.{8,}/;

			const passwordLength = passwordInputValue.length;
			const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
			const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
			const digitsPassword = digitsRegExp.test(passwordInputValue);
			const specialCharPassword = specialCharRegExp.test(passwordInputValue);
			const minLengthPassword = minLengthRegExp.test(passwordInputValue);

			let errMsg = '';
			if (passwordLength === 0) {
				errMsg = 'Password is empty';
			} else if (!uppercasePassword) {
				errMsg = 'At least one uppercase';
			} else if (!lowercasePassword) {
				errMsg = 'At least one lowercase';
			} else if (!digitsPassword) {
				errMsg = 'At least one digit';
			} else if (!specialCharPassword) {
				errMsg = 'At least one special character';
			} else if (!minLengthPassword) {
				errMsg = 'Minumum of 8 characters';
			} else {
				errMsg = '';
			}
			setPasswordErr(errMsg);
		}

		// for confirm password
		if (
			passwordInputFieldName === 'usrConfirmPassword' ||
			(passwordInputFieldName === 'usrEncryptedPassword' &&
				usrConfirmPassword.length > 0)
		) {
			if (usrConfirmPassword.length >= usrEncryptedPassword.length && usrConfirmPassword !== usrEncryptedPassword) {
				setConfirmPasswordError('Password does not match');
			} else {
				setConfirmPasswordError('');
			}
		}
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  useEffect(() => {
    if (!isUpdating) {
      // TODO::
    } else {
      dispatch(setOrgDetails(formData));
      dispatch(setPasswordErrorDetails(passwordError));
    }
  }, [
    dispatch,
    isUpdating,
    formData,
    usrEncryptedPassword,
    usrConfirmPassword,
    passwordError,
  ]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Name of organization <span className="star">*</span>
          </Typography>
          <Input
            name="accTradeName"
            value={accTradeName}
            onChange={handleChange}
            label=""
            placeholder="Name of organization *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Website <span className="star">*</span>
          </Typography>
          <Input
            name="accWebsite"
            value={accWebsite}
            onChange={handleChange}
            // label=""
            placeholder="Website *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Business registration No <span className="star">*</span>
          </Typography>
          <Input
            name="accRegNo"
            value={accRegNo}
            onChange={handleChange}
            // label=""
            placeholder="Business registration No. *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Tax pin <span className="star">*</span>
          </Typography>
          <Input
            name="accPinCert"
            value={accPinCert}
            onChange={handleChange}
            // label=""
            placeholder="Tax pin *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="p"
            className="fmw-checkbox-label"
            sx={{ mb: ".7rem" }}
          >
            Certificate of registration
          </Typography>
          <CertificateOfReg />
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="p"
            className="fmw-checkbox-label"
            sx={{ mb: ".7rem" }}
          >
            Password
          </Typography>
          <Input
            label=""
            value={usrEncryptedPassword ? usrEncryptedPassword : ""}
            name="usrEncryptedPassword"
            placeholder="Create password *"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            onKeyUp={handleValidation}
            size="lg"
            className="fmw-input-joy"
            endDecorator={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {usrEncryptedPassword && passwordError && (
            <FormHelperText className="upload-sub-label-error">
              {passwordError}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="p"
            className="fmw-checkbox-label"
            sx={{ mb: ".7rem" }}
          >
            Confirm Password
          </Typography>
          <Input
            label=""
            value={usrConfirmPassword}
            name="usrConfirmPassword"
            placeholder="Confirm password *"
            type={showConfirmPassword ? "text" : "password"}
            onChange={handleChange}
            onKeyUp={handleValidation}
            size="lg"
            className="fmw-input-joy"
            endDecorator={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {usrConfirmPassword && confirmPasswordError && (
            <FormHelperText className="upload-sub-label-error">
              {confirmPasswordError}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AccountDetails;
