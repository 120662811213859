import { Checkbox, Chip, Divider, TextField, Typography } from '@mui/joy'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { registerPartner, setInstitutionDetails } from '../../../features/partner/partnerSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, CloudUploadOutlined, Delete, PictureAsPdf, Visibility, VisibilityOff } from '@mui/icons-material';
import { formatPath, previewPaper, subString } from '../../../utils';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios"
import { cleanupIncomeExp, onbaordFiles, onbaordIncomeExpFile, setStep5IncomeExp } from '../../../features/onboard_partner/onboardPartnerSlice';
import { fetchWspApplications } from '../../../features/wsp/wspSlice';

const IncomeExpenditure =({wspApplications})=> {
    const dispatch = useDispatch();
    const { institution } = useSelector(state => state.partner)
    const { step5IncomeExp } = useSelector(state => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState(institution)
    const { RegNumber, DateReg } = formData
    const [file, setFile] = useState("")
    
    const [fetchedExpenditureFile, setFetchedExpenditureFile] = useState(wspApplications[0].projIncomeExpenditureFile)
    const removeFetchedExpenditureFile = () => {
        setFetchedExpenditureFile('')
    }

    //IncomeExp
    const [fileIncomeExp, setFileIncomeExp] = useState(step5IncomeExp)
    const { nameIncomeExp, dateIncomeExp, file_base64IncomeExp, typeIncomeExp } = fileIncomeExp;
    const [income, setIncome] = useState('')
    const [errorIncomeExp, setErrorIncomeExp] = useState('')

    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    // const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;

    // const handleIncomeExp = (e) => {
    //     const _imgArr = [];
    //     const _file = e.target.files[0];

    //     setFile(_file);


    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //         const base64String = reader.result
    //             .replace('data:', '')
    //             .replace(/^.+,/, '');

    //         // Create an object containing image information.
    //         let imageObj = {
    //             name: _file.name,
    //             date: _date,
    //             type: _file.type,
    //             file_base64: base64String.toString()
    //         };

    //         //   setFileArr(base64String.toString())
    //         setFileArr(imageObj)
    //         setIsUpdating(true)


    //         //   dispatch(setStep5IncomeExp(imageObj))
    //     };
    //     reader.readAsDataURL(_file);
    // }
    // const getBase64 = async (data) => {
    //     const base64 = await fetch(`data:${type};base64,${data}`);
    //     const blob = await base64.blob();

    //     const formData = new FormData()
    //     formData.append('file', blob);
    //     // uploadImage(formData, base64)
    //     dispatch(onbaordFiles(formData,base64));
    //     console.log("_blob", blob);
    //     return base64;
    // }

    // const uploadImage = async (file) => {
    //     console.log("file--------", file);
    //     const response = await axios.post(`http://majikonnect.co.ke/FUND/usr/postImages.action`, file)
    //     console.log("response", response);
    // }


    const handleIncomeExp = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;
        const allowedExtensions = /(\.xlsx|\.csv|\.xls)$/i;
        const MAX_FILE_SIZE = 1e+7  //10mb 
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorIncomeExp("Please upload a CSV or Excel file of Size not more than 10mb");
            setIncome('');
            return;
        }
        setFile(_file);
        setErrorIncomeExp('');
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
            let imageObj = {
                nameIncomeExp: _file.name,
                dateIncomeExp: _date,
                typeIncomeExp: _file.type,
                file_base64IncomeExp: base64String.toString()
            };
            setFileIncomeExp(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordIncomeExpFile(formData));
    }


    const removeIncomeExp = () => {
        setFileIncomeExp({
            nameIncomeExp: '',
            dateIncomeExp: '',
            typeIncomeExp: '',
            file_base64IncomeExp: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupIncomeExp());
    }

    useEffect(() => {
        if (!isUpdating) {
            // setTimeout(() => {
            //     dispatch((fetchWspApplications()))
            // }, 800)
        } else {
            dispatch(setStep5IncomeExp(fileIncomeExp))
        }
    }, [dispatch, isUpdating, fileIncomeExp]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                            Income and expenditure for the last 12 months<Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                                (xlsx) </Typography>
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {
                            fetchedExpenditureFile ? (
                                <>
                                    <a className='file-stepper-primary' onClick={() => previewPaper(fetchedExpenditureFile)}>
                                        {formatPath(fetchedExpenditureFile)}
                                    </a>
                                    <IconButton
                                        aria-label="close"
                                        onClick={removeFetchedExpenditureFile}
                                        sx={{
                                            color: (theme) => theme.palette.grey[500], mr: '6px'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </>
                            ) : (
                                <>
                                    {
                                        !nameIncomeExp ? (
                                            <>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                                    border: 'none',
                                                    // border: '1px solid var(--color-primary-light)',
                                                    width: '337px',
                                                    height: '46px'
                                                }}>
                                                    <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                                        <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                            <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                                <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                            </div>

                                                            {
                                                                errorIncomeExp ? (
                                                                    <Typography component="h6" className="upload-sub-label-error">
                                                                        {errorIncomeExp}
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography variant="caption" className="upload-sub-label">
                                                                        Click here to upload
                                                                    </Typography>
                                                                )
                                                            }
                                                        </div>
                                                        <input
                                                            type="file"
                                                            name='nameIncomeExp'
                                                            accept=".xlsx,.csv,.xls"
                                                            webkitdirectory
                                                            onChange={handleIncomeExp}
                                                            style={{ width: '0px', height: '0px' }}
                                                        />

                                                    </label>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div style={{
                                                    display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                                    border: '1px solid var(--color-primary-light)',
                                                    width: '337px',
                                                    height: '46px'
                                                }}>
                                                    <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                                        <span style={{ margin: "0px 6px" }}>
                                                            {
                                                                typeIncomeExp === "text/csv" ? (
                                                                    <Article sx={{
                                                                        fontSize: '40px',
                                                                        color: '#4caf50'
                                                                    }} />
                                                                ) : (
                                                                    <Article sx={{
                                                                        fontSize: '40px',
                                                                        color: '#4caf50'
                                                                    }} />
                                                                )
                                                            }

                                                        </span>
                                                        <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                                        <Typography component="h6" className="fmw-upload-typography">
                                                            {subString(nameIncomeExp, 25)}
                                                        </Typography>


                                                        <Typography variant="caption" className="upload-sub-label">
                                                            uploaded on  {_date}
                                                        </Typography>
                                                    </div>

                                                    <div>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={removeIncomeExp}
                                                            sx={{
                                                                color: (theme) => theme.palette.grey[500], mr: '6px'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )


                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default IncomeExpenditure