import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Textarea } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectScore,
  fetchScoreStep,
  postProjectScore,
  postScoreStep,
} from "../../../features/expression_demand/expressionDemandSlice";
import { onboardPartner } from "../../../features/onboard_partner/onboardPartnerSlice";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfrimationModal";
import NoTableDataComponent from "./NoTableDataComponent";

const StepSevenTable = ({ data, status }) => {
  const stepName = "FINAL_VERDICT";
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const { projectScore, stepScore, loading } = useSelector(
    (state) => state.expressionDemandData
  );

  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const totalScore = stepScore?.reduce((accumulator, currentValue) => {
    if (currentValue.sctStep === "FINAL_VERDICT") {
      return accumulator;
    }
    return accumulator + currentValue.sctTotalScore;
  }, 0);

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    const res = await dispatch(fetchProjectScore(info));
    const step = await dispatch(fetchScoreStep(info));
  };

  const updateComment = (scoresList) => {
    scoresList?.forEach((step) => {
      if (step.sctStep === "FINAL_VERDICT") {
        setComment(step.sctComment ?? "");
      }
    });
  };

  const finalData = [];

  stepScore &&
    stepScore?.forEach((item) => {
      let newData = {};
      if (item.sctStep === "GOVERNANCE_INFO") {
        newData = {
          name: "Governance information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "PROJECT_DESCRIPTION") {
        newData = {
          name: "Existing Project Description & Infrastructure",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "CUSTOMER_DETAIL") {
        newData = {
          name: "Customer Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "LOAN_INFO") {
        newData = {
          name: "Loan Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "FINANCIAL_SUMMARY") {
        newData = {
          name: "Financial Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      }
      if (item.sctStep !== "FINAL_VERDICT") {
        finalData.push(newData);
      }

      // return newData;
    });

  const submitToCommit = async () => {
    setMessage("Are you sure you want to send a letter of intent");
    setOpenModal(true);
    setType("COMMITTED");
  };

  const submitToDealRoom = async () => {
    setMessage("Are you sure you want to send the project to deal room");
    setOpenModal(true);
    setType("DEAL_SOURCING");
  };

  const submitStep = () => {
    if (comment === "") {
      toast.error("Kindly provide your final verdict");
      return;
    }
    setMessage("Are you sure you want to send the project to pipelines");
    setOpenModal(true);
    setType("VIABLE");
    const rawData = {
      scProjId: data.projId,
      scScore: totalScore,
      scMethod: stepName,
    };
    const saveData = { data, stepName };
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: totalScore,
    };
    const res = dispatch(postScoreStep(formData));
    dispatch(postProjectScore(saveData));
  };

  useEffect(() => {
    updateComment(stepScore);
  }, [stepScore]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  return (
    <>
      <Typography
        sx={{
          my: 2,
          color: "#0069DF",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Final verdict
      </Typography>
      {loading && finalData?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Table>
          <TableHead sx={{ bgcolor: "#475459", height: "20px" }}>
            <TableRow
              sx={{
                borderLeft: "1px solid #475459",
                borderRight: "1px solid #475459",
              }}
            >
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Step
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Comments
              </TableCell>
              <TableCell sx={{ color: "#FFFFFF", fontWeight: "bold" }}>
                Total score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              borderLeft: "1px solid #E4E4E4",
              borderRight: "1px solid #E4E4E4",
            }}
          >
            {finalData?.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
                style={{ borderBottom: "1px solid #E4E4E4" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    borderLeft: "1px solid #E4E4E4",
                    borderRight: "1px solid #E4E4E4",
                    color: "#646464",
                  }}
                >
                  {row.comment}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                    color: "#646464",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {row.marks}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!loading && finalData?.length === 0 && <NoTableDataComponent />}

      <Typography
        sx={{
          my: 2,
          fontWeight: "bold",
          mx: "auto",
          bgColor: "blue",
          width: "100%",
          textAlign: "end",
          mr: "10px",
        }}
      >
        {!loading &&
          finalData?.length > 0 &&
          `Total score: ${totalScore} Points`}
      </Typography>
      {(status === "Viable" ||
        status === "Partner" ||
        status === "COMMITTED" ||
        status === "DEAL_SOURCING") && (
        <>
          <Typography
            sx={{
              my: 2,
              color: "#0069DF",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {status === "Viable" ? "Give your final verdict" : "Expert review"}
          </Typography>

          <Textarea
            value={comment}
            disabled={status !== "Viable"}
            onChange={(e) => setComment(e.target.value)}
            minRows={5}
            name="projSummary"
            placeholder={status !== "Viable" ? "N/A" : "write here..."}
            size="lg"
            className="fmw-textarea"
            color={`${
              status === "Partner"
            } ? "red !important" : "#646464 !important"`}
            sx={{ width: "100%", color: "#646464" }}
          />
        </>
      )}
      <Typography
        sx={{
          my: 2,
          color: "#0069DF",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {status === "Viable" ? (
          <Button
            onClick={submitStep}
            variant="contained"
            sx={{ textTransform: "capitalize", textAlign: "center", my: 2 }}
          >
            send to pipeline
          </Button>
        ) : status === "Partner" ? (
          <Button
            onClick={submitToCommit}
            variant="contained"
            sx={{ textTransform: "capitalize", textAlign: "center", my: 2 }}
          >
            Offer Letter of Intent
          </Button>
        ) : status === "COMMITTED" ? (
          <Button
            onClick={submitToDealRoom}
            variant="contained"
            sx={{ textTransform: "capitalize", textAlign: "center", my: 2 }}
          >
            send to Deal Room
          </Button>
        ) : (
          <p></p>
        )}
      </Typography>
      <ConfirmationModal
        open={openModal}
        message={message}
        setOpenModal={setOpenModal}
        projId={data.projId}
        type={type}
      />
    </>
  );
};

export default StepSevenTable;
