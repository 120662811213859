import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import NoTableDataComponent from '../../../expression_demand/tables/NoTableDataComponent';
import { Box } from '@mui/material';





function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Meter reader', 159),
  createData('Project manager', 237),
];



export default function Pipeline() {
  const { storageInformation, pipeInformations, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Size (diameter)</TableCell>
            <TableCell align="left">Material</TableCell>
            <TableCell align="left">Total length (m)</TableCell>
          </TableRow>
        </TableHead>
        {pipeInformations.length > 0 ? (<TableBody className='basic'>
          {pipeInformations?.map((row) => (
            <TableRow
              key={row?.peSize}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important', width: '33.33%' }}>
                {row?.peSize}
              </TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }}>{row?.peMaterial}</TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }} >{row?.peTotalLength}</TableCell>
            </TableRow>
          ))}
        </TableBody>) : <Box sx={{ display: 'flex', width: "60vw", justifyContent: "center" }}><NoTableDataComponent /></Box>}
      </Table>

    </TableContainer>
  );
}