import React, { useEffect } from "react";
import SearchField from "../../components/search_field/SearchField";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpressionDemandData,
  setFilterParams,
} from "../../features/expression_demand/expressionDemandSlice";
import PageTitle from "../../components/page_title/PageTitle";

function ViableProject() {
  const { expressionData, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  const dispatch = useDispatch();
  const handleDealSourceData = () => {
    dispatch(setFilterParams("committed"));
    const res = dispatch(
      getExpressionDemandData({ projStatus: "DEAL_SOURCING" })
    );
  };

  useEffect(() => {
    handleDealSourceData();
  }, []);

  return (
    <>
      <PageTitle currentPage='Viable Projects' />
      <SearchField expressionData={expressionData} category='viable' />
    </>
  );
}

export default ViableProject;
