import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/joy';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';

export default function CustomModal({
	open,
	setOpenModal,
	message,
	message2,
	okText,
	submitFucntion,
}) {
	const [loading, setloading] = useState(false);

	const sendHandler = () => {
		submitFucntion();
	};

	const handleClose = () => {
		setOpenModal(false);
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				// fullWidth={true}
			>
				<DialogTitle className='fmw-page-title'>
					Confirm your request
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						<Typography
							variant='h5'
							component='h5'
							sx={{ fontSize: '1.05rem' }}
						>
							{message}
						</Typography>
						<br />
						<Typography component='p' sx={{ fontSize: '.95rem' }}>
							{message2}
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant='outlined'
						color='primary'
						sx={{
							textTransform: 'capitalize',
							backgroundColor: 'var(--bg-primary) !important',
						}}
						onClick={handleClose}
					>
						Cancel
					</Button>

					{!loading ? (
						<Button
							variant='outlined'
							color='primary'
							sx={{
								textTransform: 'capitalize',
								backgroundColor: 'var(--bg-primary) !important',
							}}
							onClick={sendHandler}
						>
							{okText}
						</Button>
					) : (
						<Button disabled variant='contained' sx={{ textTransform: 'none' }}>
							<CircularProgress size={17} color='inherit' />
							&nbsp;Deleting User...
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
