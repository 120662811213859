import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { cashConverter } from '../../../utils';
import { Radio, ConfigProvider } from 'antd'
import { useState } from 'react'
import { Textarea } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { onboardPartner } from '../../../features/onboard_partner/onboardPartnerSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Approval = ({ data }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [value, setValue] = useState(1);
    const navigate = useNavigate();
    const [message, setMessage] = useState(data.projMemo);

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };


    const onApproval = (e) => {
        if (message.length === 0) {
            toast.error("Add description");
        } else {
            const rowd = dispatch(
                onboardPartner({
                    projId: data.projId,
                    projStatus: "DRAFT",
                    projMemo: message,
                    projApprovedBy: user.usrId,
                    projApprovedByAcc: user.usrAccId
                })
            );
            navigate("/new-project");
        }

    };

    const onReject = (e) => {

        if (message.length === 0) {
            toast.error("Add description");
        } else {
            const rowd = dispatch(
                onboardPartner({
                    projId: data,
                    projStatus: "REJECT",
                    projMemo: message,
                    projApprovedBy: user.usrId,
                    projApprovedByAcc: user.usrAccId
                })
            );
            navigate("/new-project");
        }
    };




    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', height: '100%', alignItems: 'center' }}>
            {user.usrJbrnId === 0 ? <Box
                sx={{
                    backgroundColor: "#FEFEFF",
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: 4,
                    mt: 2,
                    width: "70%"
                }}
            >
                <Typography sx={{ px: 2, pt: 2, py: 1, fontWeight: 500 }}>
                    How would you like to proceed with this application?
                </Typography>
                <Divider />
                <Radio.Group onChange={onChange} value={value}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, m: 2 }}>
                        <Radio value={1}>Approve</Radio>
                        <ConfigProvider
                            theme={{
                                token: {
                                    // Seed Token
                                    colorPrimary: '#CE1821',
                                    borderRadius: 2,

                                    // Alias Token
                                    colorBgContainer: '#f6ffed',
                                },
                            }}
                        >
                            <Radio value={2} >Reject</Radio>
                        </ConfigProvider>

                    </Box>

                </Radio.Group>
            </Box > : <Box
                sx={{
                    backgroundColor: "#FEFEFF",
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: 4,
                    mt: 2,
                    p: 2,
                    width: "70%"
                }}
            >
                <Typography sx={{ px: 2, pt: 2, py: 1, fontWeight: 500, textAlign: 'center' }}>
                    Project Status
                </Typography>

                <Divider />
                <Typography sx={{ px: 2, pt: 4, py: 1, fontWeight: 500, textAlign: 'center' }}>
                    {(data.projStatus === "ACTIVE") ? <Typography style={{ color: 'grey', fontSize: "24px" }}>Pending Approval</Typography> : data.projStatus === "REJECT" ? <Typography style={{ color: 'red', fontSize: "24px" }}>Rejected</Typography> : <Typography style={{ color: 'green', fontSize: "24px" }}>Approved</Typography>}
                </Typography>
            </Box>}
            {user.usrJbrnId === 0 ? (<Textarea value={message} placeholder='Reason' onChange={(e) => setMessage(e.target.value)} minRows={6} sx={{ width: "70%" }} />) :

                (<Box
                    sx={{
                        backgroundColor: "#FEFEFF",
                        display: "flex",
                        flexDirection: "column",
                        p: 4,
                        borderRadius: 4,
                        mt: 2,
                        width: "70%"
                    }}
                >
                    <Typography sx={{ mb: 1, px: 2, pt: 2, py: 1, fontWeight: 500, textAlign: 'center' }}>
                        Description
                    </Typography>
                    <Divider />
                    <Typography sx={{ color: '#667085', lineHeight: 2, overflowY: 'auto', maxHeight: '14em', pt: 1, textAlign: 'center' }}>
                        {data.projMemo ?? 'N/A'}
                    </Typography>

                </Box>)
            }

            {(user.usrJbrnId !== 0 && data.projStatus !== "ACTIVE") && <Box
                sx={{
                    backgroundColor: "#FEFEFF",
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: 4,
                    mt: 2,
                    p: 2,
                    width: "70%"
                }}
            >
                The Project was {data.projStatus === "REJECT" ? "rejected" : "approved"} by {data.approverName ?? "N/A"} From {data.bankName ?? "N/A"}
            </Box>}



            {user.usrJbrnId === 0 && (< Button
                onClick={value === 1 ? onApproval : onReject}
                sx={{
                    backgroundColor: value === 1 ? "#226CBF" : "#CE1821",
                    width: "10.75rem",
                    height: "3.25rem",
                    borderRadius: "30px",
                    mt: 8,
                    color: "white",
                    textTransform: 'capitalize',
                    "&:hover": {
                        borderColor: "#226CBF",
                        backgroundColor: value === 1 ? "blue" : "red",
                    },
                }}
            >
                {value === 1 ? "Approve" : "Reject"}
            </Button>)}

        </Box >
    )


}

export default Approval