import {
  Button,
  TableContainer,
  Stack,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDirectorInformation } from "../../../features/expression_demand/expressionDemandSlice";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { cashConverter } from "../../../utils";
import CustomerInfo from "./CustomerInfo";
import CustomerDetails from "./CustomerDetails";
import ProjectSummary from "./ProjectSummary";
import GovernanceInfo from "./GovernaceInfo";
import FinancialSummary from "./FinancialSummary";
import LoanInfo from "./LoanInfo";
import StepSevenTable from "./StepSevenTable";
import ProjectDescription from "./ProjectDescription";
import ScrollToBottom from "../../messages/scrollToBottom";
import { useEffect } from "react";

const ExpresssionDetailTable = ({ projectData, status }) => {
  const {
    // projAgmMinutesDate,
    projCost,
    projRegNo,
    projKraPin,
    projRegType,
    projRegDate,
    projId,
    projStatu1File,
    projStatu2File,
    projStatu3File,
  } = projectData;
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  function createData(name, description, variableName) {
    return { name, description, variableName };
  }
  const projectCost = cashConverter(projCost);

  const date = new Date(projRegDate);
  const projRegistrationDate = `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()}`;

  const [pageCount, setPageCount] = useState(1);

  const page2 = [
    createData("Type of registration", projRegType),
    createData("Registration number", projRegNo),
    createData("Date of registration", projRegistrationDate),
    createData("KRA pin", projKraPin),
    createData("1st statutory returns", projStatu1File),
    createData("2nd statutory returns", projStatu2File),
    createData("3rd statutory returns", projStatu3File),
  ];

  const pageSelector = 7;

  const handleChange = (event, value) => {
    setPageCount(value);
  };

  function tableSelector(counter) {
    if (counter === 1) {
      return <ProjectSummary data={projectData} user={user} status={status} />;
    } else if (counter === 2) {
      // dispatch(getDirectorInformation(projId));
      return (
        <GovernanceInfo
          data={projectData}
          title={"Governance information"}
          status={status}
        />
      );
    } else if (counter === 3) {
      return <ProjectDescription data={projectData} status={status} />;
      // <GovernanceInfo data={projectData} title={"Governance information"} />;
    } else if (counter === 4) {
      return <CustomerDetails data={projectData} status={status} />;
    } else if (counter === 5) {
      return (
        <FinancialSummary
          data={projectData}
          // projAgmMinutesDate={projAgmMinutesDate}
          status={status}
        />
      );
    } else if (counter === 6) {
      return <LoanInfo data={projectData} status={status} />;
    } else {
      return <StepSevenTable data={projectData} status={status} />;
    }
  }

  useEffect(() => {
    document.getElementById("divFirst").scrollIntoView();
  }, [pageCount])


  return (
    <>
    <div id="divFirst"></div>
      {tableSelector(pageCount)}
      <Box sx={{ my: 5, display: "flex", justifyContent: "center" }}>
        <Stack spacing={2} paddingBottom={5}>
          {/* <Typography>Page: {pageCount}</Typography> */}
          <Pagination count={7} page={pageCount} onChange={handleChange} />
        </Stack>
     
      </Box>
    </>
  );
};

export default ExpresssionDetailTable;
