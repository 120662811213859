import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import SingleItem from '../../components/single_item';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { cashConverter } from '../../../../utils';
import NoTableDataComponent from '../../../expression_demand/tables/NoTableDataComponent';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Meters', 159),
  createData('Equipment', 237),
];



export default function BudgetTable() {
  const { budgetInfo, isLoadingBudget } = useSelector(
    (state) => state.onboardPartner
  );
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Budget</TableCell>
            <TableCell align="left">Comment</TableCell>
          </TableRow>
        </TableHead>
        {budgetInfo.length > 0 ? (<TableBody className='basic'>
          {budgetInfo?.map((row) => (
            <TableRow
              key={row?.biItem}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                {row?.biItem}
              </TableCell>
              <TableCell align="left">{cashConverter(row?.biBudget)}</TableCell>
              <TableCell align="left">{row?.biComment}</TableCell>
            </TableRow>
          ))}
        </TableBody>) : <Box sx={{ display: 'flex', width: "60vw", justifyContent: "center" }}><NoTableDataComponent /></Box>}
      </Table>

    </TableContainer>
  );
}