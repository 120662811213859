import { Error } from '@mui/icons-material'
import { Button } from '@mui/joy'
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { cleanupFormWithPartner } from '../../features/wsp/wspSlice'
import { cleanupForm } from '../../features/onboard_partner/onboardPartnerSlice'
import { useNavigate } from 'react-router-dom'
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux'
import { cleanupFormCommitted } from '../../features/committed_project/committedSlice'

function ErrorPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const logout = () => {
    storage.removeItem('persist:root');
    dispatch(cleanupForm());
    dispatch(cleanupFormWithPartner());
    dispatch(cleanupFormCommitted());
    navigate('/')
  }
  return (
    <>
      <Grid container spacing={2} sx={{display:'flex',alignItems:'center',justifyItems:'center', justifyContent:'center',height:'100vh'}}>
        <Grid item xs={12}>
          <Box sx={{display:'flex',alignItems:'center',  flexDirection:'column'}}>
          <Typography variant="h1" sx={{color: 'var(--color-primary)',fontWeight:'700',m:3}}>
            404
          </Typography>

          <Typography variant="h5" sx={{fontWeight:'700'}}>
            <Error sx={{mr:1,fontSize:'3rem'}}/>Oops! Page not found!
          </Typography>

          <Typography variant="h6"sx={{m:2}}>
          System has logged you out!
          </Typography>

          <Button color="neutral" size="sm" className="fmw-btn-secondary-auto" 
          sx={{ mt:2,fontSize:'1.2rem !important',height:'50px !important'}}
          onClick={logout}
          >
              Back to Login 
          </Button>
      
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ErrorPage
