import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, TextField } from '@mui/material';
import { Typography } from '@mui/joy';
import { fetchWspApplications, reset } from '../../features/wsp/wspSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { onboardPartner } from '../../features/onboard_partner/onboardPartnerSlice';
import { toast, ToastContainer } from 'react-toastify';

const Volumetric = ({wspApplications}) => {
  const {  isLoading,sendProjId, isSuccess } = useSelector((state) => state.wsp)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'customer',
      headerName: 'Type of customer',
      width: 250,
      sortable: false
    },
    {
      field: 'vol',
      headerName: 'Vol (Minimum vol (V1) )',
      width: 260,
      type: 'number',
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <TextField
            id="standard-basic"
            type="number"
            variant="standard"
            placeholder={params.row.placeholderVol}
            value={params.row.vol}
          />
        )
      }
    },
    {
      field: 'vol2',
      headerName: 'Vol (Minimum vol (V2) )',
      width: 260,
      type: 'number',
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <TextField
            id="standard-basic"
            type="number"
            variant="standard"
            placeholder={params.row.placeholderVol2}
            value={params.row.vol2}
          />
        )
      }
    },
    {
      field: 'charge',
      headerName: (
        <>
         Charge (Ksh/m<sup>3</sup>(Minimum charge))
        </>
      ),
  
      // headerName: 'Charge (Ksh/m3 (Minimum charge) )',
      width: 250,
      type: 'number',
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <TextField
            id="standard-basic"
            type="number"
            variant="standard"
            placeholder={params.row.placeholderCharge}
            value={params.row.charge}
          />
        )
      }
    },



  ];

  // const rows = [
  //   { id: 1, placeholderVol: 'V1', placeholderVol2: 'V2', placeholderCharge: 'T1', vol: wspApplications[0].projIndV1  == 0 ? "" :wspApplications[0].projIndV1 , vol2: wspApplications[0].projIndV2 == -1 ? "":wspApplications[0].projIndV2, charge: wspApplications[0].projIndividualCharge == -1 ? "" : wspApplications[0].projIndividualCharge, customer: 'Individual' },
  //   { id: 2, placeholderVol: 'V2', placeholderVol2: 'V3', placeholderCharge: 'T2', vol: wspApplications[0].projKioskV1 == 0 ? "" : wspApplications[0].projKioskV1, vol2: wspApplications[0].projKioskV2 == -1 ? "" : wspApplications[0].projKioskV2, charge: wspApplications[0].projKioskCharge == -1 ?  "" : wspApplications[0].projKioskCharge, customer: 'Kiosk' },
  //   { id: 3, placeholderVol: 'V3', placeholderVol2: 'V4', placeholderCharge: 'T3', vol: wspApplications[0].projInstV1 == 0 ? "" : wspApplications[0].projInstV1, vol2: wspApplications[0].projInstV2 == -1 ? "": wspApplications[0].projInstV2 , charge: wspApplications[0].projIntitutionCharge == -1 ? "":wspApplications[0].projIntitutionCharge, customer: 'Institution (School, HCF)' },
  //   { id: 4, placeholderVol: 'V4', placeholderVol2: 'V5', placeholderCharge: 'T4', vol: wspApplications[0].projComV1 == 0 ? "" : wspApplications[0].projComV1, vol2: wspApplications[0].projComV2 == -1 ? "" : wspApplications[0].projComV2, charge: wspApplications[0].projCommercialCharge == -1 ? "":wspApplications[0].projCommercialCharge, customer: 'Commercial' },
  //   { id: 5, placeholderVol: '>V5', placeholderVol2: '>V5', placeholderCharge: 'T5', vol: wspApplications[0].projOtherV1 == 0 ? "":wspApplications[0].projOtherV1, vol2: wspApplications[0].projOtherV2 == -1 ? "":wspApplications[0].projOtherV2, charge: wspApplications[0].projOtherCharge == -1 ?"": wspApplications[0].projOtherCharge, customer: 'Other' },
  // ];
  // const processRowUpdate = (newRow) => {
  //   const updatedRow = { ...newRow };

  //   const id = updatedRow.id
  //   const vol = updatedRow.vol ? updatedRow.vol : '0';
  //   // const vol2 = updatedRow.vol2 && updatedRow.vol2 > updatedRow.vol ? updatedRow.vol2 : -1;
  //   const vol2 = updatedRow.vol2 ? updatedRow.vol2 : '0';
  //   const charge = updatedRow.charge ? updatedRow.charge : '0';
  //   const placeholderVol = updatedRow.placeholderVol ? updatedRow.placeholderVol : '';
  //   const placeholderCharge = updatedRow.placeholderCharge ? updatedRow.placeholderCharge : "No placeholder";


  const rows = [
    { id: 1, placeholderVol: 'V1', placeholderVol2: 'V2', placeholderCharge: 'T1', vol: wspApplications[0].projIndV1, vol2: wspApplications[0].projIndV2, charge: wspApplications[0].projIndividualCharge, customer: 'Individual' },
    { id: 2, placeholderVol: 'V2', placeholderVol2: 'V3', placeholderCharge: 'T2', vol: wspApplications[0].projKioskV1, vol2: wspApplications[0].projKioskV2, charge: wspApplications[0].projKioskCharge, customer: 'Kiosk' },
    { id: 3, placeholderVol: 'V3', placeholderVol2: 'V4', placeholderCharge: 'T3', vol: wspApplications[0].projInstV1, vol2: wspApplications[0].projInstV2, charge: wspApplications[0].projIntitutionCharge, customer: 'Institution (School, HCF)' },
    { id: 4, placeholderVol: 'V4', placeholderVol2: 'V5', placeholderCharge: 'T4', vol: wspApplications[0].projComV1, vol2: wspApplications[0].projComV2, charge: wspApplications[0].projCommercialCharge, customer: 'Commercial' },
    { id: 5, placeholderVol: '>V5', placeholderVol2: '>V5', placeholderCharge: 'T5', vol: wspApplications[0].projOtherV1, vol2: wspApplications[0].projOtherV2, charge: wspApplications[0].projOtherCharge, customer: 'Other' },
  ];
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };

    const id = updatedRow.id
    const vol = updatedRow.vol ? updatedRow.vol : '';
    // const vol2 = updatedRow.vol2 && updatedRow.vol2 > updatedRow.vol ? updatedRow.vol2 : -1;
    const vol2 = updatedRow.vol2 ? updatedRow.vol2 : '';
    const charge = updatedRow.charge ? updatedRow.charge : '';
    const placeholderVol = updatedRow.placeholderVol ? updatedRow.placeholderVol : '';
    const placeholderCharge = updatedRow.placeholderCharge ? updatedRow.placeholderCharge : "No placeholder";

  

   
    if (id == 1 && vol) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projIndV1: vol,
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }
    if (id == 1 && vol2) {
      if (vol2 < vol) {
        toast.warning("Enter a volume greater than voluu 1")
      } else {
        dispatch(onboardPartner({
          projId: wspApplications[0].projId,
          projIndV2: vol2
        }))
        if (user.usrJbrnId === 1) {
          setTimeout(() => {
            dispatch((fetchWspApplications()))
          }, 800)
        } else {
          setTimeout(() => {
            dispatch((fetchWspApplications({
              projId: sendProjId
            })))
          }, 800)
        }
      }

    }
    if (id == 1 && charge) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projIndividualCharge: charge
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }


    if (id == 2 && vol) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projKioskV1: vol
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }
    if (id == 2 && vol2) {
      if (vol2 < vol) {
        toast.warning("Enter a volume greater than vol 1")
      } else {
        dispatch(onboardPartner({
          projId: wspApplications[0].projId,
          projKioskV2: vol2,
        }))
        if (user.usrJbrnId === 1) {
          setTimeout(() => {
            dispatch((fetchWspApplications()))
          }, 800)
        } else {
          setTimeout(() => {
            dispatch((fetchWspApplications({
              projId: sendProjId
            })))
          }, 800)
        }
      }
    }
    if (id == 2 && charge) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projKioskCharge: charge,
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }


    if (id == 3 && vol) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projInstV1: vol
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }
    if (id == 3 && vol2) {
      if (vol2 < vol) {
        toast.warning("Enter a volume greater than vol 1")
      } else {
        dispatch(onboardPartner({
          projId: wspApplications[0].projId,
          projInstV2: vol2,
        }))
        if (user.usrJbrnId === 1) {
          setTimeout(() => {
            dispatch((fetchWspApplications()))
          }, 800)
        } else {
          setTimeout(() => {
            dispatch((fetchWspApplications({
              projId: sendProjId
            })))
          }, 800)
        }
      }

    }
    if (id == 3 && charge) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projIntitutionCharge: charge
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }

    if (id == 4 && vol) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projComV1: vol
      }))
        if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }
    if (id == 4 && vol2) {
      if (vol2 < vol) {
        toast.warning("Enter a volume greater than vol 1")
      } else {
        dispatch(onboardPartner({
          projId: wspApplications[0].projId,
          projComV2: vol2,
        }))
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      }

    }
    if (id == 4 && charge) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projCommercialCharge: charge
      }))
        if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }

    if (id == 5 && vol) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projOtherV1: vol
      }))
        if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }
    if (id == 5 && vol2) {
      if (vol2 < vol) {
        toast.warning("Enter a volume greater than vol 1")
      }
      else {
        dispatch(onboardPartner({
          projId: wspApplications[0].projId,
          projOtherV2: vol2
        }))
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      }

    }
    if (id == 5 && charge) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projOtherCharge: charge
      }))
        if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }


    return updatedRow;
  };


  // useEffect(() => {
  //   dispatch(fetchWspApplications())
  //   return () => {
  //     dispatch(reset())
  //   }

  // }, [dispatch])

  return (
    <>
      <Typography sx={{ my: 3, mt: 0 }} component="body2" className="fmw-divider" />
      <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
        Fill the volumetric table below <span className="star">*</span>
      </Typography>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <DataGrid
          autoHeight
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          processRowUpdate={processRowUpdate}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isLoading}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
        />
      </Paper>
    </>
  );
}
export default Volumetric


 