import { Button } from "@mui/joy";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginDetails } from "../../features/auth/authSlice";

const VerifyButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const verifyEmail = async () => {
    const userData = await dispatch(loginDetails());
    const { payload } = userData;

    if (payload.usrChannel !== "EMAIL_VERIFIED") {
      toast.error("Please verify your email");
    } else {
      toast.success("Email verified successfully");
      if (user.usrJbrnId === 0) {
        navigate("/support-project");
        toast.success("Login successful");
      }else if(user.usrJbrnId === 1){
        navigate("/wsp-home");
        toast.success("Login successful");
      }else{
        navigate("/expression-demand/complete");
        toast.success('Login successful');
      }
        
    }
  };

  return (
    <>
      <Button
        variant="soft"
        color="primary"
        sx={{
          backgroundColor: "var(--bg-primary) !important",
          borderRadius: "2rem",
        }}
        onClick={verifyEmail}
      >
        I have verified
      </Button>
    </>
  );
};

export default VerifyButton;
