import * as React from 'react';
import { Logout, Notifications, OpenInNewOutlined, PersonAdd, Settings } from '@mui/icons-material'
import { AppBar, Avatar, Badge, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import { deepOrange, green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupForm } from '../features/onboard_partner/onboardPartnerSlice';
import { cleanupFormWithPartner } from '../features/wsp/wspSlice';
import storage from 'redux-persist/lib/storage';
import { cleanupFormCommitted } from '../features/committed_project/committedSlice';

const Header = () => {
  const {user} = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch =  useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    storage.removeItem('persist:root');
    dispatch(cleanupForm());
    dispatch(cleanupFormWithPartner());
    dispatch(cleanupFormCommitted());
    // window.location.href = "https://fundmywater.com/"
    navigate('/')
  }

  const getFirstLetter = (word) => {
    if (!word) return '';
    return word.charAt(0).toUpperCase();
  }

  return (
    <AppBar position="absolute" elevation={0}>
        <Toolbar
        className="header-toolbar"
        sx={{
            pr: '20px', // keep right padding when drawer closed
            color: '#000000DE',
            backgroundColor: '#fff',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        }}
        >
        
        <IconButton sx={{ color: '#484848' }}>
            <Badge badgeContent={4} color="error">
                <Notifications />
            </Badge>
        </IconButton>

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 30, height: 30, bgcolor: '#00BABA' }}>
              {getFirstLetter(user.usrFullNames)}
            </Avatar>
          </IconButton>
        </Tooltip>

        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 30,
                height: 30,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
        
          <Typography
              sx={{color: '#5E6C84', fontSize: '10px', fontWeight: '700', my:1, mx: 2 }}
          >
              ACCOUNT
          </Typography>

          <MenuItem>

          <Box sx={{ mb: 1, display: "flex", justifyContent: 'centre', alignItems: 'center'}}>
              <Stack direction="row" spacing={0}>
                  <Avatar sx={{ width: 32, height: 32, bgcolor: '#00BABA', mt: .5 }}>
                    {getFirstLetter(user.usrFullNames)}
                  </Avatar>

                  <Stack spacing={0}>
                      <Typography
                          sx={{fontSize: '13px',fontWeight: 'bold'}}
                      >
                          {`${user.usrFullNames}`}
                      </Typography>
                      <Typography
                          sx={{fontSize: "11px"}}
                      >
                          {user.usrEmail}
                      </Typography>
                  </Stack>
              </Stack>
          </Box>
        </MenuItem>
        <MenuItem>
          <Stack
              direction={{ sm: 'row' }}
              spacing={{ xs: 5, sm: 6, md: 7 }}
              sx={{display: 'flex', alignItems: 'center'}}
          >
              <Typography
                  fontSize="inherit"
              >
                  Manage account
              </Typography>
              <OpenInNewOutlined sx={{ fontSize: "medium", justifyContent: 'end'}} />
          </Stack>
        </MenuItem>
        <Divider />
        <Typography
              sx={{color: '#5E6C84', fontSize: '10px', fontWeight: '700', my:1, mx: 2}}
          >
              FUNDMYWATER
          </Typography>
        <MenuItem>
          <ListItemIcon>
            <PersonAdd sx={{ fontSize: '1.125rem' }} />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings sx={{ fontSize: '1.125rem' }} />
          </ListItemIcon>
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout sx={{ fontSize: '1.125rem' }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

        </Toolbar>
    </AppBar>
  )
}

export default Header