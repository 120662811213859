import React from 'react';
import { Box, Button, Link, Stack } from "@mui/material";
import { Typography } from "@mui/joy";
import { useNavigate } from 'react-router-dom';
import { previewPaper } from '../../utils';



const Footer = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={{ minWidth: '100%', backgroundColor: '#F7F7F7' }}>
                <Box sx={{
                    p: 4,
                    m: 0,
                }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 0, sm: 1, md: 20 }}
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Box>
                            <Typography sx={{
                                mb: 1,
                                color: '#2C2C2C',
                                letterSpacing: '0px',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: '600',
                                fontSize: '16px',
                            }}>
                                Quick Links
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 1

                            }}>
                                <Link
                                    href="#"
                                    underline="none"
                                    sx={{
                                        color: '#9A9EA6',
                                        font: 'normal normal Roboto',
                                        fontFamily: 'Roboto',
                                        fontWeight: 'normal',
                                        mt: 1
                                    }}>Home</Link>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Our work</Link>
                                {/* <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>News/events</Link> */}
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>About us</Link>
                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{
                                mb: 1,
                                color: '#2C2C2C',
                                letterSpacing: '0px',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: '600',
                                fontSize: '16px',
                            }}>
                                Projects
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 1
                            }}>
                                {/* <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Publications</Link> */}
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Support my project</Link>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Water project</Link>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>My projects</Link>
                            </Box>
                        </Box>
                        <Box >
                            <Typography sx={{
                                mb: 1,
                                color: '#2C2C2C',
                                letterSpacing: '0px',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: '700',
                                fontSize: '16px',
                            }}>
                                Register
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>

                                <Link onClick={() => { navigate('/signup-advisors') }} underline="hover" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    mt: 1
                                }}>Technical advisors</Link>

                                <Link onClick={() => { navigate('/signup-impact-funder') }} underline="hover" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    mt: 1
                                }}>Impact funder</Link>
                                <Link onClick={() => { navigate('/signup-partner') }} underline="hover" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    mt: 1
                                }}>Partners</Link>

                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{
                                mb: 1,
                                color: '#2C2C2C',
                                letterSpacing: '0px',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: '700',
                                fontSize: '16px',
                            }}>
                                More
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Log in</Link>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Sign up</Link>
                                <Link href="#" underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    mt: 1
                                }}>Terms & conditions</Link>
                            </Box>
                        </Box>
                        <Box>
                            <Typography sx={{
                                mb: 1,
                                color: '#2C2C2C',
                                letterSpacing: '0px',
                                fontFamily: 'Roboto, sans-serif',
                                fontWeight: '700',
                                fontSize: '16px',
                            }}>
                                Downloadable
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start'
                            }}>
                                <Button onClick={() => previewPaper("./myimages/fund-challenge.pdf")} underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    textTransform: 'capitalize',
                                    mt: 0
                                }}>About Challenge Fund
                                </Button>
                                <Button onClick={() => previewPaper("./myimages/challenge.pdf")} underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    textTransform: 'capitalize',
                                    mt: 0
                                }}>Call for Proposals</Button>
                                <Button onClick={() => previewPaper("./myimages/faq.docx")} variant='none' underline="none" sx={{
                                    color: '#9A9EA6',
                                    font: 'normal normal Roboto',
                                    fontFamily: 'Roboto',
                                    fontWeight: 'normal',
                                    textTransform: 'capitalize',
                                    mt: 0
                                }}>FAQ's</Button>
                            </Box>
                        </Box>
                    </Stack>

                </Box>
            </div>
            <div style={{ minWidth: '100%', backgroundColor: '#2C2C2C' }}>
                <Box sx={{
                    p: 4,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        // font: 'normal normal bold 18px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        fontFamily: 'Roboto, sans-serif',
                        letterSpacing: '0px',
                        color: '#F7F7F7',
                    }}>
                        FundMyWater
                    </Typography>
                    <Typography sx={{
                        // font: 'normal normal medium 12px Aventa Variable',
                        fontSize: '12px',
                        fontWeight: 'medium',
                        fontFamily: 'Roboto, sans-serif',
                        letterSpacing: '0px',
                        color: '#F7F7F7',
                        ml: 2
                    }}>
                        © FundMyWater. 2022. All Rights Reserved.
                    </Typography>
                </Box>
            </div>
        </>
    );
};

export default Footer;
