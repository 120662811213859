import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';



export default function PageTitle(props) {
  const navigate = useNavigate()
  const handleClick = (page) => {
    navigate(`${page}`)
  }

  const breadcrumbsCurrentPage = [
    <Link underline="hover" key="1" color="inherit"
      sx={{ cursor: 'pointer' }}
      >
      {props.currentPage}
    </Link>,
  ];
  
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit"
      sx={{ cursor: 'pointer' }}
      >
      {props.currentPage}
    </Link>,

    <Typography key="2" sx={{ color: 'var(--color-technical) !important' }}>
      {props.subHeader}
    </Typography>,
  ];

  const breadcrumbsSub = [
    <Link underline="hover" key="1" color="inherit"
      sx={{ cursor: 'pointer' }}
      // onClick={handleClick}
      >
      {props.currentPage}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      // href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      {props.subTitle}
    </Link>,
    <Typography key="2" sx={{ color: 'var(--color-technical) !important' }}>
      {props.subHeader}
    </Typography>,
  ];


  const breadcrumbsSubSub = [
    <Link underline="hover" key="1" color="inherit"
      sx={{ cursor: 'pointer' }}
      // onClick={handleClick}
      >
      {props.currentPage}
    </Link>,
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      key="2"
      color="inherit"
      // href={props.urlSubTitle}
      onClick={() => handleClick(props.urlSubTitle)}
    >
      {props.subTitle}
    </Link>,

    <Typography key="1" sx={{ color: 'var(--color-technical) !important' }}>
      {props.subSubTitle}
    </Typography>,
    <Typography key="2" sx={{ color: 'var(--color-technical) !important' }}>
      {props.subHeader}
    </Typography>,
  ];

  return (
    <Stack spacing={1} sx={{mb: props.currentPage && ! props.subSubTitle && !props.subTitle ? '35px' : '18px'}}>
      <Typography sx={{ fontWeight: 700, fontSize: '18px' }}> {props.currentPage}</Typography>

      {
        props.subTitle && !props.subSubTitle ? (<>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbsSub}
          </Breadcrumbs>
        </>) :

          props.subSubTitle ? (<>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbsSubSub}
            </Breadcrumbs>
          </>) : 
            props.currentPage && ! props.subSubTitle && !props.subTitle ? (<>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbsCurrentPage}
              </Breadcrumbs>
            </>) :
          (<>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </>)
      }

    </Stack>
  );
}
