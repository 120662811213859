import React from 'react';
import { Box, Button, IconButton, LinearProgress } from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ScrollToBottom from './scrollToBottom';
import { useSelector } from 'react-redux';
import ChartInput from './chartInput';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import InfoIcon from '@mui/icons-material/Info';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useEffect } from 'react';
import { useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TaskIcon from '@mui/icons-material/Task';
import { previewPaper } from '../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

function Chat({ messages, loading, fileData }) {
	const currentUserId = useSelector((state) => state.auth.user.usrAccId);
	const activeChat = useSelector((state) => state.messages.currentChat);
	const { uploading } = useSelector((state) => state.messages);
	const [myFiles, setmyFiles] = useState();
	const [hasFile, sethasFile] = useState(false);

	function handleFileChange() {
		setmyFiles(fileData);

		if (fileData?.name) {
			sethasFile(true);
		} else {
			sethasFile(false);
		}
	}

	function handleFileSend() {
		setmyFiles();
		sethasFile(false);
	}

	useEffect(() => {
		handleFileChange();
	}, [fileData]);


	return (
		<>
			<Box
				sx={{
					display: 'grid !important',
					maxHeight: '85%',
					overflowY: 'auto',
					padding: '50px',
				}}
			>
				{loading && (
					<>
						<div
							style={{
								justifySelf: 'end',
								position: 'absolute',
								bottom: '20%',
							}}
						>
							<Skeleton
								animation='wave'
								variant='rounded'
								width={210}
								height={60}
							/>
							<Skeleton
								animation='wave'
								variant='text'
								width={60}
								sx={{ fontSize: '1rem' }}
							/>
						</div>

						<div
							style={{
								justifySelf: 'end',
								position: 'absolute',
								bottom: '35%',
							}}
						>
							<Skeleton
								animation='wave'
								variant='rounded'
								width={210}
								height={60}
							/>
							<Skeleton
								animation='wave'
								variant='text'
								width={60}
								sx={{ fontSize: '1rem' }}
							/>
						</div>

						<div
							style={{
								justifySelf: 'start',
								position: 'absolute',
								bottom: '50%',
							}}
						>
							<Skeleton
								animation='wave'
								variant='rounded'
								width={210}
								height={60}
							/>
							<Skeleton
								animation='wave'
								variant='text'
								width={60}
								sx={{ fontSize: '1rem' }}
							/>
						</div>

						<div
							style={{
								justifySelf: 'start',
								position: 'absolute',
								bottom: '65%',
							}}
						>
							<Skeleton
								animation='wave'
								variant='rounded'
								width={210}
								height={60}
							/>
							<Skeleton
								animation='wave'
								variant='text'
								width={60}
								sx={{ fontSize: '1rem' }}
							/>
						</div>
					</>
				)}

				{!loading && !messages?.length ? (
					<Box
						sx={{
							// p: 10,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							position: 'absolute',
							bottom: '14%',
							right: '21%',
							cursor: 'pointer',
						}}
					>
						<MarkUnreadChatAltIcon
							sx={{ fontSize: 30, mr: 1, color: '#00c3b0' }}
						/>
						<p>
							{activeChat.chId ? (
								<span>
									Chat with{' '}
									<i>
										<b>{activeChat.withParty}</b>
									</i>
								</span>
							) : (
								<span>
									Start a new conversation with{' '}
									<i>
										<b>
											{activeChat.withParty ? activeChat.withParty : 'Anyone'}
										</b>
									</i>
								</span>
							)}
						</p>
					</Box>
				) : null}

				{!loading &&
					messages.map((message) => {
						if (message.msgSenderId === currentUserId) {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyItems: 'end',
										justifySelf: 'end',
										width: '250px',
										margin: '15px',
									}}
								>
									<span
										style={{
											marginRight: '5%',
											padding: '15px',
											borderTopLeftRadius: '10px',
											borderBottomRightRadius: '10px',
											borderBottomLeftRadius: '10px',
											border: '2px solid #e6fafd',
										}}
									>
										{message.msgMessage}
										{message.msgFile ? (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<Button onClick={() => previewPaper(message.msgFile)}
													sx={{
														textTransform: 'capitalize',
													}}
													variant='contained'
													startIcon={<TaskIcon />}
												>
													{message.msgAccId === currentUserId
														? 'You sent a file'
														: null}
												</Button>
											</div>
										) : null}
									</span>
									<span
										style={{
											color: '#808080',
											fontSize: '12px',
											margin: '5px',
										}}
									>
										{moment(message.msgCreatedTime).calendar()}
									</span>
								</div>
							);
						} else {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyItems: 'start',
										justifySelf: 'start',
										maxWidth: '250px',
										width: '250px',
										margin: '15px',
									}}
								>
									<span
										style={{
											borderTopRightRadius: '10px',
											borderBottomRightRadius: '10px',
											borderBottomLeftRadius: '10px',
											backgroundColor: '#e6fafd',
											marginLeft: '5%',
											padding: '15px',
										}}
									>
										{message.msgMessage}
										{message.msgFile ? (
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<a href='#view_file'
													onClick={() => previewPaper(message.msgFile)}
													sx={{
														textTransform: 'capitalize',
													}}
												>
													{message.msgAccId !== currentUserId ? (
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<SaveAltIcon />
															<span
																style={{
																	marginLeft: '3%',
																	width: '200px',
																}}
															>
																You received a file
															</span>
														</div>
													) : null}
												</a>
											</div>
										) : null}
									</span>
									<span
										style={{
											color: '#808080',
											fontSize: '12px',
											marginLeft: '20px',
											marginTop: '5px',
										}}
									>
										{moment(message.msgCreatedTime).calendar()}
									</span>
								</div>
							);
						}
					})}
				<ScrollToBottom />
			</Box>

			<div style={{ display: 'flex', justifyContent: 'center' }}>
				{uploading && (
					<Box
						sx={{
							width: '20%',
							position: 'absolute',
							bottom: '14%',
						}}
					>
						<LinearProgress />
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<span>Uploading...</span>
						</div>
					</Box>
				)}
				{myFiles && !uploading ? (
					<div
						style={{
							position: 'absolute',
							bottom: '13%',
							right: '19%',
						}}
					>
						<Box
							sx={{ display: 'flex', backgroundColor: 'black', color: 'white', padding: '5px', alignItems: 'center', justifyContent: 'space-between', width: '400px', height: '50px'}}
						>
							<span style={{
								width: "350px",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis"
							}}>{myFiles?.name}</span>
							<button
								onClick={handleFileSend}
								style={{
									background: 'transparent',
									border: 'none',
									cursor: 'pointer',
								}}
								type='button'
							>
								<HighlightOffIcon sx={{ color: 'white', fontSize: '30px' }}>
									<InfoIcon />
								</HighlightOffIcon>
							</button>
						</Box>
					</div>
				) : null}
				<Box sx={{ position: 'absolute', bottom: '6%' }}>
					<ChartInput
						myFiles={myFiles}
						handleFileSend={handleFileSend}
						hasFile={hasFile}
					/>
				</Box>
			</div>
		</>
	);
}

export default Chat;
