export const TypeOfRegistration = [
    {
        value: 'Association',
        label: 'Association',
    },
    {
        value: 'Self Help Group',
        label: 'Self Help Group',
    },
    {
        value: 'Company Limited Liability',
        label: 'Company Limited Liability',
    },
    {
        value: 'Company Limited by Guarantee',
        label: 'Company Limited by Guarantee',
    },
    {
        value: 'Trust',
        label: 'Trust',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]
export const periodWaterServices = [
    {
        value: 'Less than 12 months',
        label: 'Less than 12 months',
    },
    {
        value: '1-2 Year',
        label: '1-2 Year',
    },
    {
        value: '3-4 Years',
        label: '3-4 Years',
    },
    {
        value: 'Above 5 years',
        label: 'Above 5 years',
    },
]
