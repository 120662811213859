import React from 'react'
import {Grid, Paper, Divider, Skeleton} from '@mui/material';

const DealRoomSkeleton = () => {
  return (
    <>
        <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} variant="outlined">
              <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <Skeleton width={240} />
                    <Skeleton width={140} />
                  </Grid>
                  <Grid item xs={2} >
                    <Skeleton variant='rectangular' width={120} height={28} />
                  </Grid>
                  <Grid item xs={12} style={{paddingTop: '10px'}}>
                    <Divider/>
                  </Grid>
                  <Grid item xs={7} style={{paddingTop: '10px'}}>
                    <Skeleton width={275} />
                  </Grid>
              </Grid>
            </Paper>
        </Grid>
    </>
  )
}

export default DealRoomSkeleton