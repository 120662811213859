import React, { useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import Button from "@mui/joy/Button";
import Grid from "@mui/material/Grid";
import {
  Box,
  Container,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  CircularProgress,
} from "@mui/material";
import { Divider, TextField, Typography } from "@mui/joy";
import RegisterPartner from "../../assets/partner.png";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import Step6 from "./components/Step6";
// import Step7 from './components/Step7';
import {
  cleanupAsset,
  cleanupBank,
  cleanupFile,
  cleanupForm,
  cleanupGovernment,
  cleanupIncomeExp,
  cleanupMtrReading,
  cleanupNationalId,
  cleanupPermit,
  cleanupPump,
  cleanupStep2Aoa,
  cleanupStep5Audited1,
  cleanupStep5Audited2,
  cleanupStep5Audited3,
  cleanupStep7Cor,
  cleanupStep7Moa,
  cleanupMol,
  cleanupWtrAnalysis,
  onbaordAoaStep7,
  onbaordAuditedStep7,
  onbaordCorStep7,
  onbaordFiles,
  onbaordFilesAssets,
  onbaordMoaStep7,
  // onbaordMolStep7,
  onboardPartner,
  setStep5File,
} from "../../features/onboard_partner/onboardPartnerSlice";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import IncomeExpenditure from "./components/IncomeExpenditure";
import {
  cleanupFormWithPartner,
  fetchWspApplications,
  registerBizDetails,
  registerProjectDetails,
  registerWsp,
  reset,
  setProjIdSend,
} from "../../features/wsp/wspSlice";
import ConfirmationDialog from "../../components/wsp_application/ConfirmationDialog";
import { toSentenceCase } from "../../utils";

const steps = [
  {
    description: "SSP Details",
  },
  {
    description: "Governance information",
  },
  {
    description: "Existing Project Description & Infrastructure",
  },
  {
    description: "Customer Information",
  },
  {
    description: "Financial Information",
  },
  {
    description: "Proposed Project Details",
  },
  // {
  //   description: 'Step 7'
  // },
];

const ActiveStepContent = ({ step }) => {
  switch (step) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    case 3:
      return <Step4 />;
    case 4:
      return <Step5 />;
    case 5:
      return <Step6 />;
    // case 6:
    //   return <Step7 />
    default:
      throw new Error("Unknown step");
  }
};

const OnboardWsp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [stateChecker, setStateChecker] = useState(false);

  const {
    institution,
    partner,
    indicators,
    outcomeAreas,
    mandate,
  } = useSelector((state) => state.partner);

  const {
    step2,
    molStep2,
    step2AoaPdf,
	step2CorPdf,
    statutoryFilePdf,
    statutoryFile2Pdf,
    statutoryFile3Pdf,
    step2MinutesComPdf,
    step2MinutesCom2Pdf,
    step2MinutesCom3Pdf,
    step2SeekLoanPdf,
    step2MinutesComPd,
    step2Date,
    step2AgmDate,
    step2CommitteeDate,
    step2CommitteeDate2,
    step2CommitteeDate3,
    step5IncomeExp,
    step3,
    step4,
    step5,
    step5Date,
    step3ProjWaterSources,
    step6ProjProbSolved,
    step6ProjPurpose,
    step3WtrAnalysisPdf,
    step3MtrReadingPdf,
    step3PermitPdf,
    step3PumpPdf,
    step4MembersListPdf,
    step4ActiveContractPdf,
    step4InstitutionContractPdf,
    step4KioskContractPdf,
    step4MapPdf,
    step5IncomeExpFile,
    audited1Step5Pdf,
    audited2Step5Pdf,
    audited3Step5Pdf,
    step5AssetsFile,
    step5BankStmntFile,
    step5TariffType,
    step5Tariff,
    step5OtherCharges,
    step5File,
    step6,
    stepGovFile,
    step6ObjLetterPdf,
    step7,
    step7Cor,
    step7Moa,
    step7Aoa,
    step7Mol,
    step7Audited,
    partnerOnboard,
    isSuccess,
    isError,
    message,
    isCleanupForm,
    partnerFiles,
    projAssetsFiles,
    corStep7,
    moaStep7,
    aoaStep7,
    molStep7,
    auditedStep7,
    isSuccessS3,
    isSuccessS4,
    isSuccessS7Cor,
    isSuccessS7Moa,
    isSuccessS7Ooa,
    isSuccessS7Mol,
    isSuccessS7Audited,
    errorPhoneStep1,
    errorPhoneAuditor,
    step5TariffMultiple,
  } = useSelector((state) => state.onboardPartner);

  const {
    wsp,
    messageBas,
    sendProjId,
    wspAccount,
    wspProject,
    basicDetails,
    businessDetails,
    services,
    projectDesc,
    societyImpacts,
    isProcessing,
    isSuccessFinal,
    isErrorFinal,
    isSuccessAc,
    isSuccessBas,
    isErrorAc,
    isErrorBas,
    errorPhone,
    errorAltPhone,
    errorPassword,
    messageAc,
    messageFinal,
    errorEmail,
    isProcessingBas,
    isProcessingAc,
    isLoadingFinal,
    isLoadingBas,
  } = useSelector((state) => state.wsp);

  const { user } = useSelector((state) => state.auth);

  const { wspApplications } = useSelector((state) => state.wsp);

  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});
  // console.log("projAssetsFiles", projAssetsFiles)
  // console.log("step5IncomeExp", step5IncomeExp)

  const {
    nameIncomeExp,
    dateIncomeExp,
    file_base64IncomeExp,
    typeIncomeExp,
  } = step5IncomeExp;
  const { nameStep5, dateSt5, file_base64Step5, typeStep5 } = step5File;
  let thirdpartySum =  wspApplications[0].proj3rdpartySum
  let contributionSum =  wspApplications[0].projSswpSum
  let amount =  wspApplications[0].projLoanAmount || step6.projLoanAmount
  const handleClickOpen = () => {
	handleSubmitStep6();
		setOpen(true);
	// if(!amount){
	// 	toast.warning("Fill in amount of loan required")
	// }
	// else if(!contributionSum){
	// 	toast.warning("Fill in your contribution")
	// }else if(!thirdpartySum){
	// 	toast.warning("Fill in third party contribution")
	// }
	// else{
	// 	handleSubmitStep6();
	// 	setOpen(true);
	// }
   
  };

  
  const handleClose = () => {
    setOpen(false);
  };
  const handleBasicDetails = () => {
		if (
			!basicDetails.usrFirstName ||
			!basicDetails.usrLastName ||
			!basicDetails.usrMobileNumber ||
			!basicDetails.usrNationalId ||
			!basicDetails.usrEmail ||
			!basicDetails.usrAltFirstName ||
			!basicDetails.usrAltLastName
		) {
			toast.warning('Please fill all the required details in step 1');
		} else if (errorEmail) {
			toast.warning('Invalid email address');
		} else if (!errorPhone) {
			toast.warning('Enter a valid phone number');
		} else if (!errorAltPhone) {
			toast.warning('Enter valid alternate number !');
		} else {
		}
	};

	const handleBusinessDetails = () => {
		let services_arr = [];
		Object.keys(services).forEach(
			(key) => services[key] && services_arr.push(services[key]),
		);
		// console.log("impacts", services_arr.join(','));
		// if (!businessDetails.accBizType) {
		//   toast.warning('Please indicate type of business');
		// } else
		if (!services_arr.length) {
			toast.warning('Please indicate services offered');
		} else if (!businessDetails.accTradeName) {
			toast.warning('Please Enter water project name !');
		} else if (!businessDetails.accRole) {
			toast.warning('Please indicate your role !');
		} else if (!businessDetails.accCounty) {
			toast.warning('Please select Your County !');
		} else if (!businessDetails.accSubCounty) {
			toast.warning('Please select Your Sub-county !');
		} else if (!businessDetails.accLocation) {
			toast.warning('Please provide Your Location !');
		} else if (!businessDetails.accZipCode) {
			toast.warning('Please provide Your Postal Address !');
		} else {
		}
	};

	const handleRegsiterWsp = () => {
		if (errorEmail) {
			toast.warning('Invalid email address');
		} else if (!errorPhone) {
			toast.warning('Enter a valid phone number');
		} else if (!errorAltPhone) {
			toast.warning('Enter valid alternate number !');
		} else if (
			basicDetails.usrFirstName &&
			basicDetails.usrLastName &&
			basicDetails.usrMobileNumber &&
			basicDetails.usrEmail &&
			basicDetails.usrAltFirstName &&
			basicDetails.usrAltLastName && //basicdetails
			businessDetails.accTradeName && //businessdetails
			businessDetails.accRole &&
			businessDetails.accCounty &&
			businessDetails.accSubCounty &&
			businessDetails.accLocation &&
			businessDetails.accZipCode
		) {
			dispatch(
				registerWsp({
					...basicDetails,
					usrFullNames: `${basicDetails.usrFirstName} ${basicDetails.usrLastName}`,
					usrSalt: wsp.usrSalt,
					usrEncryptedPassword: '123456',
					usrConfirmPassword: '123456',
				}),
			);
			setStateChecker(true);
		} else if (
			!businessDetails.accTradeName ||
			!businessDetails.accRole ||
			!businessDetails.accCounty ||
			!businessDetails.accSubCounty ||
			!businessDetails.accLocation ||
			!businessDetails.accZipCode
		) {
			toast.warning('Fill all required details !');
		} else {
			// toast.warning("Fill all required basic details ");
		}
	};

	const handleSubmitStep1Partner = (e) => {
		handleRegsiterWsp();
	};

	const handleSubmitStep1 = (e) => {
		// const _formData = {
		//   projId: wspApplications[0].projId,
		//   projState: step1.projState ? step1.projState : wspApplications[0].projState,
		//   projNumberWaterKiosks: step1.projNumberWaterKiosks ? step1.projNumberWaterKiosks : wspApplications[0].projNumberWaterKiosks,
		//   projIndividualActiveConn: step1.projIndividualActiveConn ? step1.projIndividualActiveConn : wspApplications[0].projIndividualActiveConn,
		//   projIndividualClosedConn: step1.projIndividualClosedConn ? step1.projIndividualClosedConn : wspApplications[0].projIndividualClosedConn,
		//   projInstitutionCommercialConn: step1.projInstitutionCommercialConn ? step1.projInstitutionCommercialConn : wspApplications[0].projInstitutionCommercialConn,
		//   projNumberHouseholdsServed: step1.projNumberHouseholdsServed ? step1.projNumberHouseholdsServed : wspApplications[0].projNumberHouseholdsServed,
		//   projOrgPeriod: step1.projOrgPeriod ? step1.projOrgPeriod : wspApplications[0].projOrgPeriod,
		//   projAlternatePhone: step1.projAlternatePhone ? step1.projAlternatePhone : wspApplications[0].projAlternatePhone,
		//   projLnNumbers: step1.projLnNumbers ? step1.projLnNumbers : wspApplications[0].projLnNumbers,
		//   projTown: step1.projTown ? step1.projTown : wspApplications[0].projTown,
		//   projPostCode: step1.projPostCode ? step1.projPostCode : wspApplications[0].projPostCode,
		//   projTelLandline: step1.projTelLandline ? step1.projTelLandline : wspApplications[0].projTelLandline,
		//   projTelMobile: step1.projTelMobile ? step1.projTelMobile : user.usrMobileNumber,
		//   projEmail: step1.projEmail ? step1.projEmail : user.usrEmail,
		//   projStatus: step1.projIndividualActiveConn ? "DRAFT" : wspApplications[0].projIndividualActiveConn ? "DRAFT" : "ACTIVE"
		// }
		// dispatch(onboardPartner(_formData));
	};

	const handleSubmitStep2 = (e) => {
		const _formData = {
			projId: wspApplications[0].projId,
			projRegNo: step2.projRegNo
				? step2.projRegNo
				: wspApplications[0].projRegNo,
			projKraPin: step2.projKraPin
				? step2.projKraPin
				: wspApplications[0].projKraPin,
			projRegType: step2.projRegType
				? step2.projRegType
				: wspApplications[0].projRegType,
			projRegTypeOther:
				step2.projRegType == 'Other'
					? step2.projRegTypeOther
					: wspApplications[0].projRegTypeOther
					? wspApplications[0].projRegTypeOther
					: '',
			projRegDate: step2Date.projRegDate
				? step2Date.projRegDate
				: wspApplications[0].projRegDate,
			projAgmMinutesDate: step2AgmDate.projAgmMinutesDate
				? step2AgmDate.projAgmMinutesDate
				: wspApplications[0].projAgmMinutesDate,
			projOrgPeriod: step2.projOrgPeriod
				? step2.projOrgPeriod
				: wspApplications[0].projOrgPeriod,
			projMinAgmFile:
				molStep2.length > 0
					? molStep2
					: wspApplications[0].projMinAgmFile
					? wspApplications[0].projMinAgmFile
					: null,
			projAssocArtFile:
				step2AoaPdf.length > 0
					? step2AoaPdf
					: wspApplications[0].projAssocArtFile
					? wspApplications[0].projAssocArtFile
					: null,

					projRegCertFile:
					step2CorPdf.length > 0
						? step2CorPdf
						: wspApplications[0].projRegCertFile
						? wspApplications[0].projRegCertFile
						: null,

					
			projStatu1File:
				statutoryFilePdf.length > 0
					? statutoryFilePdf
					: wspApplications[0].projStatu1File
					? wspApplications[0].projStatu1File
					: null,
			projStatu2File:
				statutoryFile2Pdf.length > 0
					? statutoryFile2Pdf
					: wspApplications[0].projStatu2File
					? wspApplications[0].projStatu2File
					: null,
			projStatu3File:
				statutoryFile3Pdf.length > 0
					? statutoryFile3Pdf
					: wspApplications[0].projStatu3File
					? wspApplications[0].projStatu3File
					: null,
			projSgmFile:
				step2SeekLoanPdf.length > 0
					? step2SeekLoanPdf
					: wspApplications[0].projSgmFile
					? wspApplications[0].projSgmFile
					: null,
			projMin1Date: step2CommitteeDate.projCommitteeDate
				? step2CommitteeDate.projCommitteeDate
				: wspApplications[0].projMin1Date,
			projMin1File:
				step2MinutesComPdf.length > 0
					? step2MinutesComPdf
					: wspApplications[0].projMin1File
					? wspApplications[0].projMin1File
					: null,
			projMin2Date: step2CommitteeDate2.projCommitteeDate2
				? step2CommitteeDate2.projCommitteeDate2
				: wspApplications[0].projMin2Date,
			projMin2File:
				step2MinutesCom2Pdf.length > 0
					? step2MinutesCom2Pdf
					: wspApplications[0].projMin2File
					? wspApplications[0].projMin2File
					: null,
			projMin3Date: step2CommitteeDate3.projCommitteeDate3
				? step2CommitteeDate3.projCommitteeDate3
				: wspApplications[0].projMin3Date,
			projMin3File:
				step2MinutesCom3Pdf.length > 0
					? step2MinutesCom3Pdf
					: wspApplications[0].projMin3File
					? wspApplications[0].projMin3File
					: null,

			projStatus: step2.projRegNo? 'DRAFT': wspApplications[0].projRegNo? 'DRAFT': 'DRAFT',
		};
		dispatch(onboardPartner(_formData));
	};

	const handleSubmitStep3 = (e) => {
		const _formData = {
			projId: wspApplications[0].projId,
			projExistingOperational: step3.projExistingOperational
				? step3.projExistingOperational
				: wspApplications[0].projExistingOperational,
			projWaterSources: step3ProjWaterSources
				? Object.values(step3ProjWaterSources).join(',')
				: wspApplications[0].projWaterSources,
			projReliabilityWaterSource: step3.projReliabilityWaterSource
				? step3.projReliabilityWaterSource
				: wspApplications[0].projReliabilityWaterSource,
			projOtherExistingWaterSources: step3.projOtherExistingWaterSources
				? step3.projOtherExistingWaterSources
				: wspApplications[0].projOtherExistingWaterSources,
			projWspAround: step3.projWspAround
				? step3.projWspAround
				: wspApplications[0].projWspAround,
			projWspAroundDesc: step3.projWspAroundDesc
				? step3.projWspAroundDesc
				: wspApplications[0].projWspAroundDesc,
			projOtherExistingWaterSourcesDesc:
				step3.projOtherExistingWaterSources == 'Yes'
					? step3.projOtherExistingWaterSourcesDesc
					: wspApplications[0].projOtherExistingWaterSources == 'Yes'
					? wspApplications[0].projOtherExistingWaterSourcesDesc
					: '',
			projReliabilityOtherWaterSources: step3.projReliabilityOtherWaterSources
				? step3.projReliabilityOtherWaterSources
				: wspApplications[0].projReliabilityOtherWaterSources,
			projOtherWspSswp: step3.projOtherWspSswp
				? step3.projOtherWspSswp
				: wspApplications[0].projOtherWspSswp,
			projOtherWspSswpDesc:
				step3.projOtherWspSswp == 'Yes'
					? step3.projOtherWspSswpDesc
					: wspApplications[0].projOtherWspSswp == 'Yes'
					? wspApplications[0].projOtherWspSswpDesc
					: '',
			projAverageVolumeDry: step3.projAverageVolumeDry
				? step3.projAverageVolumeDry
				: wspApplications[0].projAverageVolumeDry,
			projAverageVolWet: step3.projAverageVolWet
				? step3.projAverageVolWet
				: wspApplications[0].projAverageVolWet,
			projWaterQuality: step3.projWaterQuality
				? step3.projWaterQuality
				: wspApplications[0].projWaterQuality,
			projMasterMeterInstalledFunctional:
				step3.projMasterMeterInstalledFunctional
					? step3.projMasterMeterInstalledFunctional
					: wspApplications[0].projMasterMeterInstalledFunctional,
			projWraAbstrationPermit: step3.projWraAbstrationPermit
				? step3.projWraAbstrationPermit
				: wspApplications[0].projWraAbstrationPermit,
			projTypeOfSysytem: step3.projTypeOfSysytem
				? step3.projTypeOfSysytem
				: wspApplications[0].projTypeOfSysytem,
			projPumpedPow: step3.projPumpedPow
				? step3.projPumpedPow
				: wspApplications[0].projPumpedPow,
			projWaterQualityFile:
				step3WtrAnalysisPdf.length > 0
					? step3WtrAnalysisPdf
					: wspApplications[0].projWaterQualityFile
					? wspApplications[0].projWaterQualityFile
					: null,
			projMeterReadingsFile:
				step3MtrReadingPdf.length > 0
					? step3MtrReadingPdf
					: wspApplications[0].projMeterReadingsFile
					? wspApplications[0].projMeterReadingsFile
					: null,
			projPermitFile:
				step3PermitPdf.length > 0
					? step3PermitPdf
					: wspApplications[0].projPermitFile
					? wspApplications[0].projPermitFile
					: null,
			projPictureOfPumpFile:
				step3PumpPdf.length > 0
					? step3PumpPdf
					: wspApplications[0].projPictureOfPumpFile
					? wspApplications[0].projPictureOfPumpFile
					: null,
		};
		dispatch(onboardPartner(_formData));
	};

	const handleSubmitStep4 = async (e) => {
		const _formData = {
			projId: wspApplications[0].projId,
			projNumberWaterKiosks: step4.projNumberWaterKiosks
				? step4.projNumberWaterKiosks
				: wspApplications[0].projNumberWaterKiosks,
			projIndividualActiveConn: step4.projIndividualActiveConn
				? step4.projIndividualActiveConn
				: wspApplications[0].projIndividualActiveConn,
			projIndividualClosedConn: step4.projIndividualClosedConn
				? step4.projIndividualClosedConn
				: wspApplications[0].projIndividualClosedConn,
			projInstitutionCommercialConn: step4.projInstitutionCommercialConn
				? step4.projInstitutionCommercialConn
				: wspApplications[0].projInstitutionCommercialConn,
			projNumberHouseholdsServed: step4.projNumberHouseholdsServed
				? step4.projNumberHouseholdsServed
				: wspApplications[0].projNumberHouseholdsServed,
			projAproxPopulationServed: step4.projAproxPopulationServed
				? step4.projAproxPopulationServed
				: wspApplications[0].projAproxPopulationServed,
			projTotalPopulation: step4.projTotalPopulation
				? step4.projTotalPopulation
				: wspApplications[0].projTotalPopulation,
			projArea: step4.projArea ? step4.projArea : wspApplications[0].projArea,
			projLat: step4.projLat ? step4.projLat : wspApplications[0].projLat,
			projLong: step4.projLong ? step4.projLong : wspApplications[0].projLong,

			projIndActiveConnMbrFile:
				step4MembersListPdf.length > 0
					? step4MembersListPdf
					: wspApplications[0].projIndActiveConnMbrFile
					? wspApplications[0].projIndActiveConnMbrFile
					: null,
			projIndActiveConnCustFile:
				step4ActiveContractPdf.length > 0
					? step4ActiveContractPdf
					: wspApplications[0].projIndActiveConnCustFile
					? wspApplications[0].projIndActiveConnCustFile
					: null,
			projInstConnFile:
				step4InstitutionContractPdf.length > 0
					? step4InstitutionContractPdf
					: wspApplications[0].projInstConnFile
					? wspApplications[0].projInstConnFile
					: null,
			projKioskConnFile:
				step4KioskContractPdf.length > 0
					? step4KioskContractPdf
					: wspApplications[0].projKioskConnFile
					? wspApplications[0].projKioskConnFile
					: null,
			projMapAreaFile:
				step4MapPdf.length > 0
					? step4MapPdf
					: wspApplications[0].projMapAreaFile
					? wspApplications[0].projMapAreaFile
					: null,
		};
		dispatch(onboardPartner(_formData));
	};

	const handleSubmitStep5 = (e) => {
		const _formData = {
			projId: wspApplications[0].projId,
			projFinDate: step5Date.projFinDate
				? step5Date.projFinDate
				: wspApplications[0].projFinDate,
			projHandCash: step5.projHandCash
				? step5.projHandCash
				: wspApplications[0].projHandCash,
			projBankCash: step5.projBankCash
				? step5.projBankCash
				: wspApplications[0].projBankCash,
			projTotalDebtors: step5.projTotalDebtors
				? step5.projTotalDebtors
				: wspApplications[0].projTotalDebtors,
			projLiquidAssets: step5.projLiquidAssets
				? step5.projLiquidAssets
				: wspApplications[0].projLiquidAssets,
			projLiabilities: step5.projLiabilities
				? step5.projLiabilities
				: wspApplications[0].projLiabilities,
			projNetFinances: step5.projNetFinances
				? step5.projNetFinances
				: wspApplications[0].projNetFinances,
			projBillingSystem: step5.projBillingSystem
				? step5.projBillingSystem
				: wspApplications[0].projBillingSystem,
			projAuditorEmail: step5.projAuditorEmail
				? step5.projAuditorEmail
				: wspApplications[0].projAuditorEmail,
			projAuditorFirm: step5.projAuditorFirm
				? step5.projAuditorFirm
				: wspApplications[0].projAuditorFirm,
			projAuditorName: step5.projAuditorName
				? step5.projAuditorName
				: wspApplications[0].projAuditorName,
			projAuditorTelephone: step5.projAuditorTelephone
				? step5.projAuditorTelephone
				: wspApplications[0].projAuditorTelephone,
			projTariffType: step5TariffType.projTariffType
				? step5TariffType.projTariffType
				: wspApplications[0].projTariffType,
			// projTariffSystem: step5Tariff.projTariffSystem ? step5Tariff.projTariffSystem : wspApplications[0].projTariffSystem,
			projTariffSystem:
				step5TariffType.projTariffType == 'Yes'
					? step5Tariff.projTariffSystem
					: step5TariffType.projTariffType == 'No'
					? Object.values(step5TariffMultiple).join(', ')
					: wspApplications[0].projTariffSystem,

			projOtherCharges: step5OtherCharges.projOtherCharges
				? step5OtherCharges.projOtherCharges
				: wspApplications[0].projOtherCharges,
			projAssets:
				step5AssetsFile.length > 0
					? step5AssetsFile
					: wspApplications[0].projAssets
					? wspApplications[0].projAssets
					: null,
			projAudiAccFile:
				audited1Step5Pdf.length > 0
					? audited1Step5Pdf
					: wspApplications[0].projAudiAccFile
					? wspApplications[0].projAudiAccFile
					: null,
			projAudiAccFile2:
				audited2Step5Pdf.length > 0
					? audited2Step5Pdf
					: wspApplications[0].projAudiAccFile2
					? wspApplications[0].projAudiAccFile2
					: null,
			projAudiAccFile3:
				audited3Step5Pdf.length > 0
					? audited3Step5Pdf
					: wspApplications[0].projAudiAccFile3
					? wspApplications[0].projAudiAccFile3
					: null,
			projIncomeExpenditureFile:
				step5IncomeExpFile.length > 0
					? step5IncomeExpFile
					: wspApplications[0].projIncomeExpenditureFile
					? wspApplications[0].projIncomeExpenditureFile
					: null,
			// projBankStatementFile: step5BankStmntFile.length > 0 ? (step5BankStmntFile) : wspApplications[0].projBankStatementFile ? (wspApplications[0].projBankStatementFile) :
			//   (null),
		};
		dispatch(onboardPartner(_formData));
	};

	const getBase64FormDataAssets = async () => {
		const base64 = await fetch(`data:${typeStep5};base64,${file_base64Step5}`);
		const blob = await base64.blob();
		const myFile = new File([blob], nameStep5);
		const formData = new FormData();
		formData.append('file', myFile);
		dispatch(onbaordFilesAssets(formData));
	};

	 
	const handleSubmitStep6 = async (e) => {
		const _formData = {
			projId: wspApplications[0].projId,
			// projProjCategory: step6.projProjCategory ? step6.projProjCategory : wspApplications[0].projProjCategory,
			projDescSubProj: step6.projDescSubProj
				? step6.projDescSubProj
				: wspApplications[0].projDescSubProj,
			projProbSolved: step6ProjProbSolved
				? Object.values(step6ProjProbSolved).join(',')
				: wspApplications[0].projProbSolved,
			projPurpose: step6ProjPurpose
				? Object.values(step6ProjPurpose).join(',')
				: wspApplications[0].projPurpose,
			projOtherProblemSolved: step6.projOtherProblemSolved
				? step6.projOtherProblemSolved
				: wspApplications[0].projOtherProblemSolved,
			projWhoBenefit: step6.projWhoBenefit
				? step6.projWhoBenefit
				: wspApplications[0].projWhoBenefit,
			projOtherBenefit: step6.projOtherBenefit
				? step6.projOtherBenefit
				: wspApplications[0].projOtherBenefit,
			projOtherPartyFinance: step6.projOtherPartyFinance
				? step6.projOtherPartyFinance
				: wspApplications[0].projOtherPartyFinance,
			projDonorPartyFinance: step6.projDonorPartyFinance
				? step6.projDonorPartyFinance
				: wspApplications[0].projDonorPartyFinance,
			projEstCost: step6.projEstCost
				? step6.projEstCost
				: wspApplications[0].projEstCost,
			projLoanAmount: step6.projLoanAmount
				? step6.projLoanAmount
				: wspApplications[0].projLoanAmount,
			projLoanYears: step6.projLoanYears
				? step6.projLoanYears
				: wspApplications[0].projLoanYears,
			projInfrastSubsidy: step6.projInfrastSubsidy
				? step6.projInfrastSubsidy
				: wspApplications[0].projInfrastSubsidy,
			projMoneySource: step6.projMoneySource
				? step6.projMoneySource
				: wspApplications[0].projMoneySource,
			projOtherFunds: step6.projOtherFunds
				? step6.projOtherFunds
				: wspApplications[0].projOtherFunds,
			projInformGov: step6.projInformGov
				? step6.projInformGov
				: wspApplications[0].projInformGov,
			projWspInformed: step6.projWspInformed
				? step6.projWspInformed
				: wspApplications[0].projWspInformed,
			projLoanSum: step6.projLoanSum
				? step6.projLoanSum
				: wspApplications[0].projLoanSum,
			projSswpSum: step6.projSswpSum
				? step6.projSswpSum
				: wspApplications[0].projSswpSum,
			proj3rdpartySum: step6.proj3rdpartySum
				? step6.proj3rdpartySum
				: wspApplications[0].proj3rdpartySum,
			proj3rdpartyCalculated: step6.proj3rdpartyCalculated
				? step6.proj3rdpartyCalculated
				: wspApplications[0].proj3rdpartyCalculated,
			projSswpCalculated: step6.projSswpCalculated
				? step6.projSswpCalculated
				: wspApplications[0].projSswpCalculated,
			projLoanCalculated: step6.projLoanCalculated
				? step6.projLoanCalculated
				: wspApplications[0].projLoanCalculated,
			projTotalSum: step6.projTotalSum
				? step6.projTotalSum
				: wspApplications[0].projTotalSum,
			projTotalCalculated: step6.projTotalCalculated
				? step6.projTotalCalculated
				: wspApplications[0].projTotalCalculated,
			projPayCapable: step6.projPayCapable
				? step6.projPayCapable
				: wspApplications[0].projPayCapable,
			projInformGovYesFile:
				stepGovFile.length > 0
					? stepGovFile
					: wspApplications[0].projInformGovYesFile
					? wspApplications[0].projInformGovYesFile
					: null,
			projWspInformFile:
				step6ObjLetterPdf.length > 0
					? step6ObjLetterPdf
					: wspApplications[0].projWspInformFile
					? wspApplications[0].projWspInformFile
					: null,

			projMonthlyPay: step6.projMonthlyPay,
		};
		const res = await dispatch(onboardPartner(_formData));
	};
	

	const handleSubmitStep7 = (e) => {
		const _formDataStep7 = {
			projId: wspApplications[0].projId,
			projRegCertFile:
				step7Cor.length > 0
					? step7Cor
					: wspApplications[0].projRegCertFile
					? wspApplications[0].projRegCertFile
					: null,
			projMemFile:
				step7Moa.length > 0
					? step7Moa
					: wspApplications[0].projMemFile
					? wspApplications[0].projMemFile
					: null,
			projAssocArtFile:
				step7Aoa.length > 0
					? step7Aoa
					: wspApplications[0].projAssocArtFile
					? wspApplications[0].projAssocArtFile
					: null,
			projMinAgmFile:
				step7Mol.length > 0
					? step7Mol
					: wspApplications[0].projMinAgmFile
					? wspApplications[0].projMinAgmFile
					: null,
			projAudiAccFile:
				step7Audited.length > 0
					? step7Audited
					: wspApplications[0].projAudiAccFile
					? wspApplications[0].projAudiAccFile
					: null,
			// projAgmMinutesDate: step7.projAgmMinutesDate ? step7.projAgmMinutesDate : wspApplications[0].projAgmMinutesDate,
		};

		dispatch(onboardPartner(_formDataStep7));
	};

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
		// little customization
		if (step === 0) {
			handleReset();
		} else if (step === 1) {
			setCompleted({ 0: true, 1: true });
		} else if (step === 2) {
			setCompleted({ 0: true, 1: true });
		} else if (step === 3) {
			setCompleted({ 0: true, 1: true, 2: true });
		} else if (step === 4) {
			setCompleted({ 0: true, 1: true, 2: true, 3: true });
		} else if (step === 5) {
			setCompleted({ 0: true, 1: true, 2: true, 3: true, 4: true });
		} else if (step === 6) {
			setCompleted({ 0: true, 1: true, 2: true, 3: true, 4: true, 5: true });
		}
		// else if (step === 7) {
		//   setCompleted({ "0": true, "1": true, "2": true, "3": true, "4": true, "5": true, "6": true, })
		// }
		else {
			handleComplete();
		}
	};

	const handleComplete = () => {
		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();

		console.log('newCompleted', newCompleted);
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	// const handleStep1 = () => {
	//   if ( !step1.projLnNumbers) {
	//     toast.warning("Please fill all details in step 1")
	//   } else {
	//     handleSubmitStep1()
	//     console.log(step1.projState );
	//     console.log("Sent");
	//     handleComplete();
	//   }
	// }

	const handleStep1 = () => {
		if (user.usrJbrnId === 0 && !sendProjId) {
			handleSubmitStep1Partner();
		} else {
			handleSubmitStep1();
			handleComplete();
		}
	};

	const handleStep2 = () => {
		handleSubmitStep2();
		handleComplete();
	};
	const handleStep3 = () => {
		handleSubmitStep3();
		handleComplete();
	};
	const handleStep4 = () => {
		handleSubmitStep4();
		handleComplete();
	};
	// const handleStep5 = () => {
	//   if (!step5.projFinDate || !step5.projHandCash || !step5.projBankCash || !step5.projTotalDebtors || !step5.projLiquidAssets || !step5.projLiabilities || !step5.projNetFinances) {
	//     toast.warning("Please fill all details in step 5")
	//   } else {
	//     handleSubmitStep5()
	//     handleComplete();
	//   }
	// }
	const handleStep5 = () => {
		console.log('errorEmailonboardwsp', errorEmail);
		if (errorEmail && step5.projAuditorEmail) {
			toast.warning('Invalid auditor email address');
		} else if (!errorPhoneAuditor && step5.projAuditorTelephone) {
			toast.warning('Enter valid auditor phone number ');
		} else {
			handleSubmitStep5();
			handleComplete();
		}
	};
 
	
	const handleStep6 = async() => {
		// if(!amount){
		// 	toast.warning("Fill in amount of loan required")
		// }
		// else if(!contributionSum){
		// 	toast.warning("Fill in your contribution")
		// }else if(!thirdpartySum){
		// 	toast.warning("Fill in third party contribution")
		// }else{
		// 	await handleSubmitStep6();
		// 	toast.success('Draft saved successfully');
		// }
		await handleSubmitStep6();
			toast.success('Draft saved successfully');
	  
	//   handleComplete();
	}

	const handleRegisterWspP = (e) => {
		toast.warning('Please fill all SSP details first to proceed ');
	};

  const handleApply = () => {
    // handleSubmitStep7()
    if (user.usrJbrnId === 1) {
      dispatch(fetchWspApplications());
    }
    if (user.usrJbrnId === 0) {
      dispatch(
        fetchWspApplications({
          projId: sendProjId,
        })
      );
    }

    if (
    //   wspApplications[0].projRegNo &&
    //   wspApplications[0].projExistingOperational &&
    //   wspApplications[0].projNumberWaterKiosks &&
    //   wspApplications[0].projFinDate &&
      wspApplications[0].projDescSubProj
    ) {
      toast.success("Application Successful.");
      dispatch(
        onboardPartner({
          projId: wspApplications[0].projId,
          projStatus: "PENDING_APPROVAL",
        })
      );
      dispatch(cleanupForm());
      dispatch(cleanupFormWithPartner());
      dispatch(cleanupFile());
      dispatch(cleanupWtrAnalysis());
      dispatch(cleanupMtrReading());
      dispatch(cleanupPermit());
      dispatch(cleanupPump());
      dispatch(cleanupAsset());
      dispatch(cleanupBank());
      dispatch(cleanupIncomeExp());
      dispatch(cleanupNationalId());
      dispatch(cleanupGovernment());
      dispatch(cleanupStep7Cor());
      dispatch(cleanupStep7Moa());
      dispatch(cleanupStep2Aoa());
      dispatch(cleanupMol());
      dispatch(cleanupStep5Audited1());
      dispatch(cleanupStep5Audited2());
      dispatch(cleanupStep5Audited3());

      if (user.usrJbrnId === 1) {
        navigate("/wsp-home");
      } else {
        navigate("/support-project");
      }
    } else {
      toast.error("Please provide description of sub-project for which the loan is applied");
    }
  };

  useEffect(() => {
    if (open && user.usrJbrnId === 0) {
      dispatch(
        fetchWspApplications({
          projId: sendProjId,
        })
      );
    }
    if (open && user.usrJbrnId === 1) {
      dispatch(fetchWspApplications());
    }

    if (isSuccessBas && messageBas) {
      // toast.success(messageBas);
    }

    if (isSuccessBas && messageBas) {
      dispatch(
        registerBizDetails({
          ...businessDetails,
          accId: wsp.usrAccId,
        })
      );
    }

    if (isSuccessAc && messageAc) {
      dispatch(
        registerProjectDetails({
          ...projectDesc,
          proReqAccId: user.usrAccId,
          projAccId: wsp.usrAccId,
        })
      );
    }

    if (isSuccessAc && messageAc) {
      // toast.success(messageAc);
    }

    if (isSuccessFinal && messageFinal) {
      dispatch(setProjIdSend(wspProject.projId));
      toast.success(toSentenceCase(messageFinal));
      setStateChecker(false);
      dispatch(
        fetchWspApplications({
          projId: wspProject.projId,
        })
      );
      handleComplete();
    }

    if (isErrorBas && messageBas) {
      setStateChecker(false);
      toast.error(toSentenceCase(messageBas));
    }

    if (isErrorAc && messageAc) {
      setStateChecker(false);
      toast.error(toSentenceCase(messageAc));
    }

    if (isErrorFinal && messageFinal) {
      setStateChecker(false);
      toast.error(toSentenceCase(messageFinal));
    } else {
    }
    dispatch(reset());
  }, [
    dispatch,
    navigate,
    isSuccessBas,
    isSuccessAc,
    isSuccessFinal,
    isErrorBas,
    isErrorAc,
    isErrorFinal,
    message,
    messageBas,
    messageAc,
    messageFinal,
    open,
  ]);


  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h3" className="fmw-page-title">
            FundMyWater Application
          </Typography>
          <Divider sx={{ mt: "17px" }} />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" className="fmw-checkbox-label">
            Answer the onboarding questions to proceed
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: 0, marginTop: "16px" }}>
          <Box
            sx={{ width: "100%", paddingTop: "24px", paddingLeft: "16px" }}
            className="custom-stepper"
          >
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => (
                <Step key={step.label} completed={completed[index]}>
                  {user.usrJbrnId === 0 && sendProjId ? (
                    <>
                      <StepButton
                        color="primary"
                        onClick={handleStep(index)}
                        className={`stepper-btn  ${activeStep === index || completed[index]
                            ? "sb-active"
                            : ""
                          }`}
                      >
                        <Typography component="body2" className="step-label">
                          {step.label}
                        </Typography>
                        <Typography component="h5" className="step-description">
                          {step.description}
                        </Typography>
                      </StepButton>
                    </>
                  ) : user.usrJbrnId === 0 && !wsp.usrAccId ? (
                    <>
                      <StepButton
                        color="primary"
                         onClick={handleRegisterWspP}
                        className={`stepper-btn  ${activeStep === index || completed[index]
                            ? "sb-active"
                            : ""
                          }`}
                      >
                        <Typography component="body2" className="step-label">
                          {step.label}
                        </Typography>
                        <Typography component="h5" className="step-description">
                          {step.description}
                        </Typography>
                      </StepButton>
                    </>
                  ) : (
                    <>
                      <StepButton
                        color="primary"
                        onClick={handleStep(index)}
                        className={`stepper-btn  ${activeStep === index || completed[index]
                            ? "sb-active"
                            : ""
                          }`}
                      >
                        <Typography component="body2" className="step-label">
                          {step.label}
                        </Typography>
                        <Typography component="h5" className="step-description">
                          {step.description}
                        </Typography>
                      </StepButton>
                    </>
                  )}
                  {/* <StepButton color="primary"
                 
                   onClick={handleStep(index)}
                    className={`stepper-btn  ${activeStep === index || completed[index] ? 'sb-active' : ''}`}>
                    <Typography component="body2" className="step-label">
                      {step.label}
                    </Typography>
                    <Typography component="h5" className="step-description">
                      {step.description}
                    </Typography>
                  </StepButton> */}
                </Step>
              ))}
            </Stepper>
            <div style={{ margin: "2.5rem 1.5rem" }}>
              <React.Fragment>
                <Box sx={{ mt: 4, mb: 2, py: 1 }}>
                  <ActiveStepContent step={activeStep} />
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {activeStep == 0 && (
                    <>
                      {user.usrJbrnId === 0 ? (
                        <>
                          {stateChecker ? (
                            <>
                              <Button
                                disabled
                                variant="contained"
                                sx={{ textTransform: "none" }}
                              >
                                <CircularProgress size={17} color="inherit" />
                                &nbsp;Adding...
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                onClick={handleStep1}
                                className="next-btn"
                              >
                                Next
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Button onClick={handleStep1} className="next-btn">
                            Next
                          </Button>
                        </>
                      )}
                    </>
                  )}

                  {/* {
                    activeStep == 0 &&
                    <Button onClick={handleStep1} className="next-btn"
                    >
                      Next
                    </Button>
                  } */}

                  {activeStep == 1 && (
                    <Button onClick={handleStep2} className="next-btn">
                      Next
                    </Button>
                  )}
                  {activeStep == 2 && (
                    <Button onClick={handleStep3} className="next-btn">
                      Next
                    </Button>
                  )}
                  {activeStep == 3 && (
                    <Button
                      onClick={handleStep4}
                      className="next-btn apply-btn"
                    >
                      Next
                    </Button>
                  )}
                  {activeStep == 4 && (
                    <Button
                      onClick={handleStep5}
                      className="next-btn apply-btn"
                    >
                      Next
                    </Button>
                  )}
                  {activeStep == 5 && (
					<>
                    <Button
                      onClick={handleClickOpen}
                      className="next-btn apply-btn"
                    >
                      Submit expression of demand
                    </Button>

					<Button
					onClick={handleStep6}
					className="next-btn apply-btn"
					sx={{ml:3,background:'#00BABA !important'}}
				  >
					Save as draft
				  </Button>
				  </>
                  )}
                  {/* {
                    activeStep == 6 &&
                    <Button onClick={handleClickOpen} className="next-btn apply-btn">
                      Submit expression of demand
                    </Button>
                  } */}
                  {/*{*/}
                  {/*    activeStep == 7 &&*/}
                  {/*    <Button onClick={handleApply} className="next-btn apply-btn">*/}
                  {/*      Submit expression of demand*/}
                  {/*    </Button>*/}
                  {/*}*/}
                </Box>
              </React.Fragment>
            </div>
          </Box>

          <CssVarsProvider />
        </Grid>
        <ConfirmationDialog
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          handleApply={handleApply}
        />
      </Grid>
    </>
  );
};

export default OnboardWsp;
