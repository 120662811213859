import { Button, Checkbox, TextField, Chip, Typography, FormHelperText } from '@mui/joy'
import {
    Divider, IconButton,

} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Article, AttachFile, CloudUploadOutlined, PictureAsPdf } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { cleanupStep5Audited2, onbaordAudited2Step5, reset, setStep5Audited2 } from '../../features/onboard_partner/onboardPartnerSlice';
import { formatPath, previewPaper, subString } from '../../utils';
import TypographySub from './TypographySub';


const AuditedReport2 = ({ formData }) => {
    const { step5Audited2, } = useSelector((state) => state.onboardPartner)
    const [fileAudited2, setFileAudited2] = useState(step5Audited2)
    const { nameAudited2, dateAudited2, file_base64Audited2, typeAudited2 } = fileAudited2;
    const [isUpdatingAudited2, setIsUpdatingAudited2] = useState(false);
    const dispatch = useDispatch();
    const [fetchedAudited2, setFetchedAudited2] = useState(formData?.projAudiAccFile2)


    const removeFetchedAudited2 = () => {
        setFetchedAudited2('')
    }
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // Audited2 pdf
    const [errorAudited2, setErrorAudited2] = useState('')
    const [file, setFile] = useState(undefined)

    const handleAudited2 = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;

        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorAudited2('Please upload a PDF file not more than 10mb ');
            setFileAudited2('');
            return;
        }
        setFile(_file);
        setErrorAudited2('')
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            // Create an object containing image information.
            let imageObj = {
                nameAudited2: _file.name,
                dateAudited2: _date,
                typeAudited2: _file.type,
                file_base64Audited2: base64String.toString()
            };

            setFileAudited2(imageObj)
            setIsUpdatingAudited2(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordAudited2Step5(formData));
    }

    const removeAudited2 = () => {
        setFileAudited2({
            nameAudited2: '',
            dateAudited2: '',
            typeAudited2: '',
            file_base64Audited2: undefined,
        });
        setIsUpdatingAudited2(true);
        dispatch(cleanupStep5Audited2());
    }


    useEffect(() => {
        if (!isUpdatingAudited2) {
        } else {
            dispatch(setStep5Audited2(fileAudited2));
        }
        dispatch(reset());
    }, [fileAudited2, isUpdatingAudited2])
    return (
        <>
            <TypographySub title="PDF of past audit report" sub="pdf" superS="true" />
            {
                fetchedAudited2 ? (
                    <>
                        <Chip
                            variant="soft"
                            className="file-chip-primary"
                            color="primary"
                            size="sm"
                            startDecorator={<AttachFile size="sm" />}
                            onClick={() => previewPaper(fetchedAudited2)}
                        >
                            {formatPath(fetchedAudited2)}
                        </Chip>
                        <IconButton
                            aria-label="close"
                            onClick={removeFetchedAudited2}
                            sx={{
                                color: (theme) => theme.palette.grey[500], mr: '6px'
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </>) : (
                    <>
                        {
                            !nameAudited2 ? (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                                        border: 'none',
                                        // border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '50px'
                                    }}>
                                        <label className='' style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                                            <div className='mb-2' style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', backgroundColor: '#F7F7F7' }}>
                                                <div style={{ backgroundColor: '#A0A0A0', height: '100%', display: 'flex', alignItems: 'center', marginRight: '20px', width: '52px' }}>
                                                    <CloudUploadOutlined sx={{ display: 'flex', fontSize: '27px', justifyContent: 'center', alignItems: 'center', mx: '12px', color: '#ffff', backgroundColor: '#A0A0A0 !important' }} />
                                                </div>
                                                {
                                                    errorAudited2 ? (
                                                        <Typography component="h6" className="upload-sub-label-error">
                                                            {errorAudited2}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="caption" className="upload-sub-label">
                                                            Click here to upload
                                                        </Typography>
                                                    )
                                                }
                                            </div>
                                            <input
                                                type="file"
                                                name='file'
                                                accept=".pdf"
                                                webkitdirectory
                                                onChange={handleAudited2}
                                                style={{ width: '0px', height: '0px' }}
                                            />

                                        </label>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{
                                        display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between',
                                        border: '1px solid var(--color-primary-light)',
                                        width: '337px',
                                        height: '55px'
                                    }}>
                                        <div style={{ backgroundColor: 'inherit', height: '100%', display: 'flex', alignItems: 'center', marginRight: '5px', width: '52px' }}>
                                            <span style={{ margin: "0px 6px" }}>
                                                {
                                                    typeAudited2 === "application/pdf" ? (
                                                        <PictureAsPdf sx={{
                                                            fontSize: '40px',
                                                            color: 'red'
                                                        }} />
                                                    ) : (
                                                        <Article sx={{
                                                            fontSize: '40px',
                                                            color: '#1976d2'
                                                        }} />
                                                    )
                                                }

                                            </span>
                                            <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'var(--color-primary-light) !important' }} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <Typography component="h6" className="fmw-upload-typography">
                                                {subString(nameAudited2, 25)}
                                            </Typography>


                                            <Typography variant="caption" className="upload-sub-label">
                                                uploaded on  {_date}
                                            </Typography>
                                        </div>

                                        <div>
                                            <IconButton
                                                aria-label="close"
                                                onClick={removeAudited2}
                                                sx={{
                                                    color: (theme) => theme.palette.grey[500], mr: '6px'
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }

        </>
    )
}

export default AuditedReport2
