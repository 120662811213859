import { Chip, FormHelperText, Input } from '@mui/joy';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setEmailErrorDetails, setPhoneErrorDetails, setTechDetails } from '../../../features/tech_adv/techAdvSlice';
import { useEffect } from 'react';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormPhoneError from '../../../components/FormPhoneError';
import { searchPhoneNumber } from '../../../features/auth/authSlice';

const BasicDetails = () => {
  const dispatch = useDispatch();
  const { techDetails, errorEmail, errorPhone } = useSelector(state => state.techAdv)
  const {messagePhone} = useSelector(state =>state.auth)
  const [isUpdating, setIsUpdating] = useState(false);
  const [passwordError, setPasswordErr] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");;
  const [error, setError] = useState(errorEmail);
  const { user } = useSelector(state => state.auth);
  const [formData, setFormData] = useState(techDetails);
  const { usrEmail, usrFirstName, usrLastName, usrEncryptedPassword, usrConfirmPassword, usrMobileNumber } = formData;
  const [phoneNo, setPhoneNo] = useState(usrMobileNumber);
  const [isValidPhone, setIsValidPhone] = useState(errorPhone);

  const [focus, setFocus] = useState(false);
  const validatePhoneNumber = () => {
    const kenyanPhoneNumberRegex = /^254[17]\d{8}$|^2547\d{8}$/;
    setIsValidPhone(kenyanPhoneNumberRegex.test(phoneNo));
    setIsUpdating(true);
    setFocus(false);
    dispatch(searchPhoneNumber({
      userPhone: phoneNo
    }))
  };
  const handleChangePhone = (e) => {
    setPhoneNo(e);
  }
  const handleFocus = (e) => {
    setFocus(true);
  }
  const [focusEmail, setFocusEmail] = useState('')
  const isValidEmail = (usrEmail) => {
    return /\S+@\S+\.\S+/.test(usrEmail);
  }
  const handleValidateEmail = (e) => {
    if (!isValidEmail(usrEmail)) {
      setError(true)
    } else {
      setError(false)
    }
    setIsUpdating(true);
    setFocusEmail(false);
  }
  const handleFocusEmail = (e) => {
    setFocusEmail(true);
  }

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
    setIsUpdating(true);
  }



  useEffect(() => {
    if (!isUpdating) {
      // TODO::
    } else {
      const data = {
        ...formData,
        usrMobileNumber: phoneNo
      }
      dispatch(setTechDetails(data));
      dispatch(setEmailErrorDetails(error))
      dispatch(setPhoneErrorDetails(isValidPhone))
    }
  }, [user, dispatch, isUpdating, formData, error, isValidPhone])


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            First name of contact person <span className="star">*</span>
          </Typography>
          <Input
            name="usrFirstName"
            value={usrFirstName}
            onChange={handleChange}
            label=""
            placeholder="First name of contact person *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Last name of contact person <span className="star">*</span>
          </Typography>
          <Input
            name="usrLastName"
            value={usrLastName}
            onChange={handleChange}
            label=""
            placeholder="Last name of contact person *"
            size="lg"
            className="fmw-input-joy"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-checkbox-label-error" : "fmw-checkbox-label"}
            sx={{ mb: ".5rem" }}
          >
            Phone number <span className="star">*</span>
          </Typography>
          {phoneNo && messagePhone == "OK" && !focus &&
            <FormPhoneError
              title="Account already exists" />
          }
          <ReactPhoneInput
            defaultCountry="ke"
            country="ke"
            className={(!isValidPhone || messagePhone == "OK") && (!focus) ? "flags-error" : "flags"}
            inputClass={(!isValidPhone || messagePhone == "OK") && (!focus) ? "fmw-phone-error" : "fmw-phone flags"}
            required
            searchClass="search-class"
            searchStyle={{
              margin: "0",
              width: "100% !important",
              height: "80px !important",
            }}
            enableSearchField
            disableSearchIcon
            value={phoneNo}
            onChange={handleChangePhone}
            onBlur={validatePhoneNumber}
            onFocus={handleFocus}
          />
          {phoneNo && !isValidPhone && !focus && 
           <FormPhoneError
           title="Enter a valid phone number" />
       }


        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h6"
            className="fmw-checkbox-label"
            sx={{ mb: ".5rem" }}
          >
            Email <span className="star">*</span>
          </Typography>
          <Input
            name="usrEmail"
            value={usrEmail}
            onChange={handleChange}
            onBlur={handleValidateEmail}
            onFocus={handleFocusEmail}
            label=""
            type="email"
            placeholder="Enter email address *"
            size="lg"
            className="fmw-input-joy"
          />
          {usrEmail && error && !focusEmail && <FormHelperText className="upload-sub-label-error">Invalid email address</FormHelperText>}
        </Grid>
      </Grid>
    </>
  );
}

export default BasicDetails;
