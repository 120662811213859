import React, { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import "react-phone-input-2/lib/style.css";
import { Input, Textarea, Typography } from "@mui/joy";
import placeholder from "../../../../assets/placeholder.jpg";

import { Avatar, Box, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  postPdfInteractions,
  resetLoader,
  getPdfInteractions,
} from "../../../../features/expression_demand/expressionDemandSlice";
import { dateConverter, formatImgPath } from "../../../../utils";
import ScrollToBottom from "../../../messages/scrollToBottom";

const Comments = ({ currentPage, setCurrentPage }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { pdfData, pdfInteractions } = useSelector(
    (state) => state.expressionDemandData
  );

  const docLength = pdfInteractions.length;

  const [comments, setComments] = useState([]);

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("Here is the comment", comments);
    console.log(user.usrId, user.usrFullNames);
    const formData = {
      eveProjId: pdfData.projaProjId,
      eveTitle: user.usrFullNames,
      eveDesc: comments,
      eveCreatedBy: user.usrId,
      eveProjaId: pdfData.projaId,
      evePage: currentPage,
    };
    // const data = JSON.stringify(formData);
    // console.log(data)
    await dispatch(postPdfInteractions(formData));
    // if(isSuccess){
    await setComments("");
    const msgs = await dispatch(getPdfInteractions(pdfData.projaId));
    console.log(msgs.payload);
    dispatch(resetLoader());
    // }
  };

  const PageHandler = (page) => {
    setCurrentPage(Number(page));
  };

  useEffect(() => {}, [pdfInteractions, pdfData]);

  console.log(pdfInteractions);

  const InteractionElement = pdfInteractions
    .map((data, index) => (
      <Box sx={{ ml: 2, mt: 1, mb: 1 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 1 }}>
          <Avatar
            src={formatImgPath(data.accIcon) ?? placeholder}
            alt="equityBank"
            sx={{ objectFit: "contained" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: "#00C3E6" }}>
              {data.accTradeName}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography sx={{ fontSize: 11, color: "#7E7E7E" }}>
                By {data.eveTitle}
              </Typography>
              <Typography sx={{ fontSize: 11, color: "#7E7E7E" }}>
                on {dateConverter(data.eveCreatedDate)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{ fontSize: 14, color: "#A0A0A0", p: 1.5, textAlign: "justify" }}
        >
          {data.eveDesc}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => PageHandler(data.evePage)}
          sx={{
            fontSize: 14,
            color: "#000",
            mb: 2,
            ml: 1.5,
            textTransform: "capitalize",
          }}
        >
          Page {data.evePage}
        </Button>
        <ScrollToBottom />

        {docLength === index && (
          <>
            <Box
              sx={{ width: "2px", height: "15px", mt: 1.5, bgcolor: "#E4E4E4" }}
            >
              {}
            </Box>
            <Box
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
                mt: 1.5,
                bgcolor: "#E4E4E4",
              }}
            >
              {}
            </Box>
            <Box
              sx={{ width: "2px", height: "15px", mb: 1.5, bgcolor: "#E4E4E4" }}
            >
              {}
            </Box>
          </>
        )}
      </Box>
    ))
    .reverse();

  return (
    <div style={{ position: "relative", width: "100%", height: "80%", my: 10 }}>
      <Typography sx={{ mt: 1, ml: 2 }}>Discussions</Typography>
      {InteractionElement}
      <form onSubmit={submitHandler}>
        <div
          style={{
            position: "fixed",
            width: "25%",
            bottom: "8%",
            right: "4%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            placeholder="Write comments Here"
            style={{
              width: "90%",
              padding: 20,
              border: 2,
              borderColor: "#E4E4E4",
              color: "#646464",
              borderRadius: 10,
              outline: "none",
              background: "#F7F7F7",
            }}
          />
          <Button type="submit">
            <SendIcon fontSize="large" sx={{ color: "#00BABA" }} />
          </Button>
        </div>
      </form>
      <Box sx={{ mt: 5 }}>
        <br />
      </Box>
    </div>
  );
};

export default Comments;
