import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { cashConverter, dateConverter } from '../../../../utils';
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function FinancialTable({data}) {
  const rows = [
    createData('As on', data?.projFinDate ? dateConverter(data?.projFinDate) : "N/A"),
    createData('Cash in hand (petty cash)', cashConverter(data?.projHandCash) ),
    createData('Cash in bank account(s)', cashConverter(data?.projBankCash)),
    createData('Total debtors (Money owed to project)', cashConverter(data?.projTotalDebtors)),
    createData('Total liquid assets [(a) + (b) + (c)]', cashConverter(data?.projLiquidAssets) ),
    createData('Liabilities(Money owed by project to others)', cashConverter(data?.projLiabilities) ),
    createData('Net financial position [(d) - (e)]',  cashConverter(data?.projNetFinances)),
    createData('Type of billing system', data?.projBillingSystem ),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic' >
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important',width:'50%' }}>
                {row.name}
              </TableCell>
              <TableCell align="left" >{row.calories}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    </TableContainer>
  );
}