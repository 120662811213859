import React, { useState, useEffect } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import {
  Assignment,
  ExpandLess,
  Campaign,
  ExpandMore,
  Layers,
  StarBorder,
  Circle,
  Dashboard,
  Home,
  BarChart,
  Message,
  AccountCircle,
  Balance,
} from "@mui/icons-material";
import { Collapse, List, Typography } from "@mui/material";
import { Link, NavLink } from "react-router-dom";

const PartnerListItems = () => {
  const [nlOpen, setnlOpen] = useState(false);
  const toggleNestedList = () => {
    setnlOpen(!nlOpen);
    setnlOpen1(false);
    setnlOpen2(false);
  };
  const [nlOpen1, setnlOpen1] = useState(false);
  const toggleNestedList1 = () => {
    setnlOpen1(!nlOpen1);
    setnlOpen(false);
    setnlOpen2(false);
  };
  const [nlOpen2, setnlOpen2] = useState(false);
  const toggleNestedList2 = () => {
    setnlOpen2(!nlOpen2);
    setnlOpen(false);
    setnlOpen1(false);
  };

  const toggleList = () => {
    setnlOpen(false);
    setnlOpen1(false);
    setnlOpen2(false);
  };

  console.log(window.location.href);
  console.log(window.location.pathname);

  const nlOpen1Urls = [
    "/support-project",
    "/deal-sourcing",
    "/project-preparation",
  ];
  const nlOpen2Urls = ["/viable-projects", "/deals", "/bids"];
  const nlOpenUrls = [
    "/loan-perfection",
    "/project-development",
    "/post-implementation",
    "/outcome-verification",
  ];

  useEffect(() => {
    if (nlOpen1Urls.includes(window.location.pathname)) {
      setnlOpen1(!nlOpen1);
      setnlOpen(false);
      setnlOpen2(false);
    } else if (nlOpen2Urls.includes(window.location.pathname)) {
      setnlOpen2(!nlOpen2);
      setnlOpen(false);
      setnlOpen1(false);
    } else if (nlOpenUrls.includes(window.location.pathname)) {
      setnlOpen(!nlOpen);
      setnlOpen1(false);
      setnlOpen2(false);
    } else {
      setnlOpen1(false);
      setnlOpen(false);
      setnlOpen2(false);
    }
  }, []);

  return (
    <>
      <ListItemButton
        className={
          nlOpen1
            ? `is-expandable lib-active  ${nlOpen1 && "expanded"}`
            : `is-expandable  ${nlOpen1 && "expanded"}`
        }
        onClick={toggleNestedList1}
      >
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <ListItemText primary='Pipelines' />
        {nlOpen1 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen1}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen1 && "open"}`}
      >
        <List component='div' disablePadding>
          <NavLink
            to='/new-project'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton className='' sx={{ pl: 2 }}>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='New Project' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/support-project'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton className='' sx={{ pl: 2 }}>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Support my project' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/deal-sourcing'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Deal sourcing' />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/project-preparation'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Project preparation' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <ListItemButton
        className={
          nlOpen2
            ? `is-expandable lib-active  ${nlOpen2 && "expanded"}`
            : `is-expandable  ${nlOpen2 && "expanded"}`
        }
        onClick={toggleNestedList2}
      >
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary='Deal room' />
        {nlOpen2 ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen2}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen2 && "open"}`}
      >
        <List component='div' disablePadding>
          <NavLink
            to='/viable-projects'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton className='' sx={{ pl: 2 }}>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Viable projects' />
            </ListItemButton>
          </NavLink>

          <NavLink
            to='/bids'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Bids' />
            </ListItemButton>
          </NavLink>

          <NavLink
            to='/deals'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Deals' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>
      <NavLink
        to='/loan-perfection'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText primary='Loan perfection' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>

      <ListItemButton
        className={
          nlOpen
            ? `is-expandable lib-active  ${nlOpen && "expanded"}`
            : `is-expandable  ${nlOpen && "expanded"}`
        }
        onClick={toggleNestedList}
      >
        <ListItemIcon>
          <Layers />
        </ListItemIcon>
        <ListItemText primary='Projects' />
        {nlOpen ? <ExpandLess /> : <ExpandMore />}
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={nlOpen}
        timeout='auto'
        unmountOnExit
        className={`collapsible-menu ${nlOpen && "open"}`}
      >
        <List component='div' disablePadding>
          <NavLink
            to='/project-development'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Project development' />
            </ListItemButton>
          </NavLink>

          <NavLink
            to='/post-implementation'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Post implementation' />
            </ListItemButton>
          </NavLink>

          <NavLink
            to='/outcome-verification'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton>
              <ListItemIcon>
                <Circle />
              </ListItemIcon>
              <ListItemText primary='Outcome verification' />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <NavLink
        to='/messages'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Message />
          </ListItemIcon>
          <ListItemText primary='Messages' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/messages'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary='Reports' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>

      <NavLink
        to='/advisor-account'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary='My account' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/expression-demand/wash'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Balance />
          </ListItemIcon>
          <ListItemText primary='Wash Prospects' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
      <NavLink
        to='/support'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton className='' sx={{ pl: 2 }}>
          <ListItemIcon>
            <Campaign />
          </ListItemIcon>
          <ListItemText primary='Support' />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>
    </>
  );
};

export default PartnerListItems;
