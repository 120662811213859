import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import ImageInput from '../../../components/committed_project/ImageInput';
import ChoiceSelector from '../ChoiceSelector';
import { Textarea, Typography } from '@mui/joy';
import { useState } from 'react';
import FileHandler from '../../../components/custom_components/FileHandler';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../../features/committed_project/committedSlice';
import { useEffect } from 'react';

export default function BillingTable({ viewOnly }) {
	let { id } = useParams();
	const { committedd } = useSelector((state) => state.committedProj);
	const dispatch = useDispatch();

	const [formData, setFormData] = useState(committedd);
	const [filename, setfilename] = useState(committedd?.wrBtTransitionPlanFile);

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	async function fetchNewData() {
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);

		await setFormData(resp?.payload[0]);
	}

	async function handleBillingPlan() {
		await dispatch(saveCommittedDetails(formData));
		const resp = await dispatch(
			fetchCommittedDetails({
				wrProjId: id,
			}),
		);
	}

	function getBsTransUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			wrBtTransitionPlanFile: data,
		}));
	}

	useEffect(() => {
		handleBillingPlan();
	}, [formData]);

	useEffect(() => {
		if (committedd?.wrBtTransitionPlanFile) {
			setfilename(committedd?.wrBtTransitionPlanFile);
		}
	}, [committedd?.wrBtTransitionPlanFile]);

	useEffect(() => {}, [formData, id, filename, committedd]);

	useEffect(() => {
		fetchNewData();
	}, []);

	return (
		<>
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ background: 'inherit' }}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow className='table'>
							<TableCell>Item</TableCell>
							<TableCell align='left'>Details</TableCell>
							<TableCell align='left'>File</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className='basic'>
						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Existing billing system
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									choiceName={'wrBtBillingPlan'}
									choiceValue={formData?.wrBtBillingPlan}
									getChoiceData={handleChange}
								/>
							</TableCell>

							<TableCell
								component='th'
								scope='row'
								sx={{ width: '33.33%' }}
							></TableCell>
						</TableRow>

						<TableRow>
							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								Billing transition plan provided
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<ChoiceSelector
									disabled={viewOnly}
									getChoiceData={handleChange}
									choiceName={'wrBtTransitionPlan'}
									choiceValue={formData?.wrBtTransitionPlan}
								/>
							</TableCell>

							<TableCell component='th' scope='row' sx={{ width: '33.33%' }}>
								<FileHandler
									disabled={viewOnly}
									getFileUrl={getBsTransUrl}
									title={
										threeValues?.length ? threeValues[1] : 'Attach billing'
									}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
