import React, { useEffect, useState } from "react";
import { Typography } from "@mui/joy";
import { Box, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material";
import SingleItem from "../../components/single_item";
import { useLocation, useParams } from "react-router-dom";
import TextareaComment from "../../components/TextareaComment";
import ParticularsTable from "./ParticularsTable";
import SingleAttachment from "../../components/single_attachment";
import FileButton from "../../../../components/button/FileButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectScore, postScoreStep, setProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import SingleAttachmentComplete from "../../components/SingleAttachmentComplete";
import { toast } from "react-toastify";
import CustomAccordion from "../../../../components/accordion/custom_accordion";
import Institutes from "./institutes";
import { fetchInstitution } from "../../../../features/onboard_partner/onboardPartnerSlice";

const ApplicantParticulars = () => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  var commentType =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Morbi tristique senectus et netus et malesuada fames. Scelerisque eleifend donec pretium vulputate. Sagittis nisl rhoncus mattis rhoncus urna. Aliquam ut porttitor leo a diam sollicitudin tempor. Eget felis eget nunc lobortis mattis aliquam faucibus.";
  const [formData, setFormData] = useState({
    trust: '',
    comment1: ''
  })
  const { trust, comment1 } = formData
  const handleChangeInput = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  };
  const category = location.pathname.split('/')[3]
  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );

  const { id } = useParams();
  const data = projectData?.find(
    (item) => item?.projId === Number(id)
  );
  const dispatch = useDispatch();
  const stepName = "CUSTOMER_DETAIL";
  const [comment, setComment] = useState("");
  const [onBlur, setOnBlur] = useState(true);
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});
  const { scores } = useSelector((state) => state.expressionDemandData);
  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }

  const matrixFields = [
    "projIndividualActiveConn",
    "projInstitutionCommercialConn",
    "projNumberWaterKiosks",
  ];

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
    const res = await dispatch(fetchInstitution({
      projId: data.projId
    }));
    console.log(res.payload);
  };

  useEffect(() => {
    fetchProjectScores();

  }, []);



  const updateMatrix = () => {
    setMatrixInfo({});
    setUserMatrixInfo({});
    setUserComment({});
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
    });
    scores[0]?.sctComment === null
      ? setComment("")
      : setComment(scores[0]?.sctComment);
  };

  useEffect(() => {
    updateMatrix();
  }, [scores]);

  const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);

  const submitStep = () => {
    console.log("Submit Is working========");
    if (comment === "") {
      return;
    }
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: total,
    };
    const res = dispatch(postScoreStep(formData));
    toast.success("Comment saved successfully ");
  };

  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const {
    projIndividualActiveConn,
    projIndividualClosedConn,
    projArea,
    projLat,
    projLong,
    projTotalPopulation,
    projAproxPopulationServed,
    projInstitutionCommercialConn,
    projNumberHouseholdsServed,
    projNumberWaterKiosks,
    projMapAreaFile,
    projKioskConnFile,
    projInstConnFile,
    projIndActiveConnMbrFile,
    projPermitFile,
    projIndActiveConnCustFile,
  } = data;

  const customerDetails = [
    createData(
      "Individual active connections",
      projIndividualActiveConn,
      "projIndividualActiveConn",
      10
    ),

    createData(
      "Institution or commercial connections",
      projInstitutionCommercialConn,
      "projInstitutionCommercialConn",
      10
    ),
    createData(
      "Number of water kiosks",
      projNumberWaterKiosks,
      "projNumberWaterKiosks",
      10
    ),

  ];

  const customerDetails2 = [
    createData(
      "Individual closed connections",
      projIndividualClosedConn,
      "projIndividualClosedConn"
    ),
    createData(
      "Number of households served",
      projNumberHouseholdsServed,
      "projNumberHouseholdsServed"
    ),
    createData(
      "Approximate population served",
      projAproxPopulationServed,
      "projAproxPopulationServed"
    ),
    createData(
      "Approximate total population in service area",
      projTotalPopulation,
      "projTotalPopulation"
    ),
    createData(
      <>
        Service provision area (km<sup>2</sup>)
      </>,
      projArea,
      "projArea"
    ),
    createData(
      "Grid reference of office / infrastructure",
      `Latitude: ${projLat ?? "N/A"}, longitude: ${projLong ?? "N/A"}`,
      "projLat"
    ),
  ];

  const customerAttachments = [
    createData(
      "PDF of map of service area(pdf)",
      projMapAreaFile,
      "projMapAreaFile"
    ),
    createData(
      "PDF of sample kiosks contract(pdf)",
      projKioskConnFile,
      "projKioskConnFile"
    ),
    createData(
      "PDF of sample Institution contract(pdf)",
      projInstConnFile,
      "projInstConnFile"
    ),
    createData(
      "PDF of list of members or accounts(pdf)",
      projIndActiveConnMbrFile,
      "projIndActiveConnMbrFile"
    ),
    createData(
      "PDF of sample customer contract(pdf)",
      projIndActiveConnCustFile,
      "projIndActiveConnCustFile"
    ),
    createData(
      "PDF of WRA Permit(pdf)",
      projPermitFile,
      "projPermitFile"
    ),
  ];



  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      {
        category == 'complete' || category == 'draft' || category == "incomplete" || category == "screened" ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Particulars of the applicant
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <ParticularsTable data={data} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Particulars of the applicant
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Table>
                  <TableBody>
                    {
                      customerDetails2?.map((row) => (
                        <TableRow
                        >
                          <TableCell component='th' scope='row'
                            className="gap-5"
                            sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <span>{row?.name}</span>
                            <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                            <span>{row?.description}</span>
                          </TableCell>

                        </TableRow>
                      ))
                    }
                  </TableBody>

                  <TableBody>
                    {
                      customerAttachments?.map((row) => (
                        <TableRow
                        >
                          <TableCell component='th' scope='row'
                            className="gap-5"
                            sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <span>{row?.name}</span>
                            <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                            <span> <SingleAttachmentComplete
                              title={row?.description}
                              tableFile="true"
                            /></span>
                          </TableCell>

                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>

                {
                  customerDetails?.map((row) => (
                    <SingleItem
                      row={row}
                      userComment={userComment}
                      setUserComment={setUserComment}
                      setMatrixInfo={setMatrixInfo}
                      matrixInfo={matrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={data?.projId}
                      setOnblur={setOnBlur}
                      onBlur={onBlur}
                      maxScores={row.maxScores}
                      status={category}
                      comment={comment}
                      setComment={setComment}
                      setUserMatrixInfo={setUserMatrixInfo}
                      userMatrixInfo={userMatrixInfo}

                    />
                  ))
                }

              </Box>
            </AccordionDetails>
          </Accordion>

        )
      }
      {projInstitutionCommercialConn === "Institution" ? < CustomAccordion
        expanded={expanded === 'panel2'}
        handleChange={handleChange('panel2')}
        title="Institutions Details"
      >
        <Institutes />
      </CustomAccordion> : null}

      {
        (category === "COMMITTED" ||
          category === "DEAL_SOURCING" ||
          category == 'Assistance' ||
          category == 'poor' ||
          category === "Viable" ||
          category === "Partner") &&
        <TextareaComment
          onChange={(e) => setComment(e.target.value)}
          comment={comment}
          submitStep={submitStep}
        />
      }

    </Box >
  );
};

export default ApplicantParticulars;
