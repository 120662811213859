import { Box, Divider, Typography, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ExpresssionDetailTable from "./tables/ExpresssionDetailTable";
import Interactions from "./components/interactions/Interactions";
import ToolTips from "./components/ToolTips";
import AddFilesTable from "./components/AddFilesTable";
import "./components/interactions/events.css";
import { Button } from "@mui/joy";
import { getExpressionDemandData } from "../../features/expression_demand/expressionDemandSlice";
import ScrollToBottom from "../messages/scrollToBottom";

const ExpressionDetailPage = () => {
  const [expressLoading, setExpressLoading] = useState(false);
  const { id, status } = useParams();
  const dispatch = useDispatch();
  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );
  const { loading, filterParam } = useSelector(
    (state) => state.expressionDemandData
  );
  const { user } = useSelector((state) => state.auth);

  const singleProjectData = projectData?.find(
    (item) => item?.projId === Number(id)
  );
  const navigate = useNavigate();

  function navigateToSheet() {
    navigate(`/submit-termsheet/${id}`);
  }

  const fetchExpressData = async () => {
    setExpressLoading(true);
    const res = await dispatch(getExpressionDemandData({ projId: id }));
    console.log(res);
    setExpressLoading(false);
  };

  useEffect(() => {
    fetchExpressData();
  }, [id]);

  useEffect(() => {
    const body = document.querySelector("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, [projectData]);

  return (
    <>
      {/* <ScrollToBottom /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#00BABA",
          mb: 1,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            textTransform: "capitalize",
            fontSize: "20px",
          }}
        >
          {singleProjectData?.accTradeName}
        </Typography>

        {user.usrJbrnId === 0 &&
        singleProjectData?.projStatus == "DEAL_SOURCING" ? (
          <Button
            className="fmw-btn-primary btn-fmw"
            variant="contained"
            sx={{ mx: 4, color: "white", textTransform: "capitalize" }}
            onClick={() => navigateToSheet()}
          >
            Offer termsheet
          </Button>
        ) : null}
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxHeight: "80vh",
          justifyContent: "center",
          mt: 1,
          position: "relative",
          height: "fit-content",
        }}
      >
        {expressLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <Box
            sx={{
              width: `${
                status === "Viable" || status === "Partner" ? "100%" : "70%"
              }`,
              mr: 1,
            }}
          >
            <ExpresssionDetailTable
              projectData={singleProjectData ?? {}}
              status={status}
            />
          </Box>
        )}
        {(status === "COMMITTED" || status === "DEAL_SOURCING") && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                minHeight: "inherit",
              }}
            ></Divider>
            <Box
              sx={{ width: "30%", mr: 1, ml: 1, position: "sticky", right: 0 }}
            >
              <AddFilesTable dataId={id} status={status} />
              <Interactions />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ExpressionDetailPage;
