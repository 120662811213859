import { Input, Typography } from "@mui/joy";
import { Box, Button, TableCell, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { postProjectScore } from "../../../features/expression_demand/expressionDemandSlice";
import { useLocation, useParams } from "react-router-dom";

const UserMatrix = ({
  setMatrixInfo,
  matrixInfo,
  setOnblur,
  name,
  stepName,
  onBlur,
  id,
  maxScores,
  system,
}) => {
  const dispatch = useDispatch();
  const {id:paramId} = useParams();
  const location = useLocation();
  const category = location.pathname.split('/')[3];
  const changeHandler = (e) => {
    const inputValue = parseInt(e.target.value);
    const data = {
      scProjId: paramId,
      scUsrScore: inputValue,
      scMethod: name,
    };
    const formData = { data, stepName };
    if (inputValue > maxScores) {
      e.target.setCustomValidity("Value must be less than or equal to 100");
      toast.error(`Maximum score is ${maxScores}`);
      !matrixInfo[name] &&
        setMatrixInfo((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
    } else {
      e.target.setCustomValidity("");

      const res = dispatch(postProjectScore(formData));
      setMatrixInfo((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    }
  };

 
  return (
    <>
{
  category == 'Partner' ? (
     <Typography sx={{color:'var(--color-technical)'}}>
      {matrixInfo[name] ? matrixInfo[name] : "N/A"}
     </Typography>
  ):(
    <Input
    type="number"
    value={matrixInfo[name]}
    onChange={changeHandler}
    onInvalid={(e) =>
      e.target.setCustomValidity("Please enter a valid number")
    }
    inputProps={{
      max: 10,
      step: 1,
      pattern: "\\d+",
    }}
    error={!!(matrixInfo[name] && matrixInfo[name] > maxScores)}
    helperText={
      matrixInfo[name] && matrixInfo[name] > maxScores
        ? `Value must be less than or equal to ${maxScores}`
        : ""
    }
    className="fmw-input-joy-score"
  />
  )

}
       
      </>
  );
};

export default UserMatrix;
