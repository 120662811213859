import { Box, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { Input, Textarea, Typography } from "@mui/joy";
import React, { useState } from "react";
import FileButton from "../../../components/button/FileButton";
import RatingsStar from "./RatingsStar";
import { formatPath, previewPaper } from "../../../utils";
import { BorderColor } from "@mui/icons-material";
import SingleComment from "../../expression_demand/components/SingleComment";
import UserMatrix from "../../expression_demand/components/UserMatrix";
import { useLocation } from "react-router-dom";


 
const SingleAttachment = ({
  // title, name, value, comment, score, tableFile, onChange, scoreComment, titleLabel, isUpdating, handleRemoveUpdating, 
  overallComment, title, value, valueInput, noCommentScore,
  setUserComment, name, userComment, stepName, id, setOnblur, onBlur, maxScores, status, comment, setComment,
  setMatrixInfo, matrixInfo, row, tableFile,
  userMatrixInfo, setUserMatrixInfo



}) => {
  const [editing, setEditing] = useState(false)
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  console.log(matrixInfo[name] + "***********************SCORE**********************************");
  return (
    <>    {
      category == 'Partner' ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {
            !tableFile ? (
              <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0, display: 'flex', alignItems: 'center', textAlign: 'center' }}  >
                {row?.name}
                <FileButton title={formatPath(row?.description)} titlePreview={row?.description} viable="true" />

              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0 }}  >
                <FileButton title={formatPath(row?.description)} titlePreview={row?.description} />
              </Typography>
            )
          }
          {
            userComment[name] ? (<>
              <Typography sx={{ mt: 1 }}>{userComment[name]}</Typography>
            </>) : (
              <Typography sx={{ mt: 1 }}>No comment</Typography>
            )
          }

          {
            (!tableFile && maxScores) && (
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  mb: 2,
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px' }}>System score: {matrixInfo[name]}</Typography>
                <Box
                  sx={{
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                    bgColor: "red",
                    gap: 2,
                  }}
                >
                  <Typography sx={{
                    display: "flex",
                    alignItems: "center", gap: 2,
                    color: '#0873B9'
                  }}>
                    Technical advisor score:
                    <UserMatrix
                      setMatrixInfo={setUserMatrixInfo}
                      matrixInfo={userMatrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={id}
                      maxScores={row.maxScores}
                    />
                  </Typography>
                </Box>
              </Box>
            )
          }
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {
            !tableFile ? (
              <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0, display: 'flex', alignItems: 'center', textAlign: 'center' }}  >
                {row?.name}
                <FileButton title={formatPath(row?.description)} titlePreview={row?.description} viable="true" />
                {
                  userComment[name] && (
                    <Tooltip title="Edit comment" arrow placement="top" >
                      <IconButton onClick={() => setEditing(!editing)} >
                        <BorderColor sx={{ fontWeight: 'inherit', fontSize: 'inherit', color: 'var(--color-technical)' }} />
                      </IconButton>
                    </Tooltip>
                  )
                }
              </Typography>
            ) : (
              <Typography sx={{ fontWeight: 700, fontSize: "16px", my: 0 }}  >
                <FileButton title={formatPath(row?.description)} titlePreview={row?.description} />
              </Typography>
            )
          }
          {
            (userComment[name] && (!editing)) ? (<>
              <Typography  >{userComment[name]}</Typography>
            </>) : (
              <SingleComment
                userComment={userComment}
                setUserComment={setUserComment}
                name={name}
                stepName={stepName}
                id={id}
                setOnblur={setOnblur}
                onBlur={onBlur}
                maxScores={maxScores}
                status={status}
                comment={comment}
                setComment={setComment}
                setEditing={setEditing}
              />
            )
          }

          {
            (!tableFile && maxScores) && (
              <Box
                sx={{
                  display: "flex",
                  mt: 1,
                  mb: 2,
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: 'var(--color-blue-grey)', fontWeight: 500, fontSize: '14px' }}>System score: {matrixInfo[name]}</Typography>
                <Box
                  sx={{
                    width: "300px",
                    display: "flex",
                    alignItems: "center",
                    bgColor: "red",
                    gap: 2,
                  }}
                >
                  <Typography sx={{
                    display: "flex",
                    alignItems: "center", gap: 2
                  }}>
                    Your Score:
                    <UserMatrix
                      setMatrixInfo={setUserMatrixInfo}
                      matrixInfo={userMatrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={id}
                      maxScores={row.maxScores}
                    />
                  </Typography>
                </Box>
              </Box>
            )
          }
        </Box>
      )
    }
    </>

  );
};

export default SingleAttachment;
