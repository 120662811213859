import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { setStep5, setStep5Date } from '../../../features/onboard_partner/onboardPartnerSlice';
import { dateForHumans } from '../../../utils';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { fetchWspApplications } from '../../../features/wsp/wspSlice';
import { Skeleton } from '@mui/material';
const FinancialDatePicker = ({wspApplications}) => {
  const { step5,step5Date } = useSelector((state) => state.onboardPartner)
  const [isUpdating, setIsUpdating] = useState(false);
  const { isLoadingWsp } = useSelector((state) => state.wsp)
  const [valueFinancial, setValueFinancial] = useState(wspApplications[0].projFinDate || step5Date.projFinDate);
  const dispatch = useDispatch();
 
 
  useEffect(() => {
    if (!isUpdating) {
      // dispatch(fetchWspApplications())
    } else {
      dispatch(setStep5Date({
        projFinDate: dateForHumans(valueFinancial.$d),
      }))
    }
  }, [dispatch, isUpdating,valueFinancial]);

  return (
    <>
    {
      isLoadingWsp ?(<> <Skeleton width={350} height={89} /></>):(<> <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD-MM-YYYY"
          disableFuture="true"
          value={valueFinancial}
          onChange={(newValue) => {
            setValueFinancial(newValue);
            setIsUpdating(true);
          }}
          PopperProps={{
            placement: "bottom-end",
          }}
          renderInput={(params) => <TextField fullWidth  {...params}
            size="small"
            className="fmw-date-picker"
            />}
        />
      
      </LocalizationProvider></>)
    }
     

    </>
  );
}
export default FinancialDatePicker;