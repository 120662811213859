import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import wspService from './wspService';


const initialState = {
    wsp: {},
    wspAccount: {},
    wspProject: {},
    sendProjId: "",
    isError: false,
    isProcessing: false,
    isLoading: false,
    isLoadingWsp: false,
    isLoadingWspPartner: false,
    isSuccess: false,

    isErrorBas: false,
    isProcessingBas: false,
    isLoadingBas: false,
    isSuccessBas: false,

    isErrorAc: false,
    isProcessingAc: false,
    isLoadingAc: false,
    isSuccessAc: false,

    isErrorFinal: false,
    isProcessingFinal: false,
    isLoadingFinal: false,
    isSuccessFinal: false,

    isErrorSend: false,
    isProcessingSend: false,
    isLoadingSend: false,
    isSuccessSend: false,

    isErrorResend: false,
    isProcessingResend: false,
    isLoadingResend: false,
    isSuccessResend: false,


    message: '',
    messageBas: '',
    messageAc: '',
    messageFinal: '',
    sendOtpCode: {
        usrId: '',
        usrSalt: ''
    },
    OTP: '',
    basicDetails: {
        usrId: null,
        usrEmail: '',
        usrFirstName: '',
        usrLastName: '',
        usrNationalId: '',
        usrFullNames: '',
        usrMobileNumber: '',
        usrAltFirstName: '',
        usrAltLastName: '',
        usrAltPhoneNo: '',
        accTypeId: 1
    },
    businessDetails: {
        accId: null,
        accTradeName: '',
        accRegNo: '',
        accLocation: '',
        accZipCode: '',
        accBizType: 'water',
        accServices: '',
    },
    services: {
        waterKiosk: '',
        exhauster: '',
        refillStations: '',
        sewerConn: '',
        yardTaps: '',
        ablution: '',
        connections: '',
    },
    projectDesc: {
        projId: null,
        projDesc: '',
        projCost: '',
        projAccId: '',
        proReqAccId: '',
        projImpact: '',
        usrEncryptedPassword: '',
        usrConfirmPassword: '',
    },
    societyImpacts: {
        increasedConnections: '',
        affordableCleanWater: '',
        reducingOperationCost: '',
    },
    errorEmail: true,
    errorPassword: null,
    errorPhone: true,
    errorAltPhone: true,
    isCleanupForm: false,
    isCleanupFormWithPartner: false,
    wspApplications: [],
    wspApplicationsPartner: [],

}

export const registerWsp = createAsyncThunk('wsp/register', async (formData, thunkAPI) => {
    try {
        return await wspService.registerWsp(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const registerBizDetails = createAsyncThunk('wsp/register/biz-details', async (formData, thunkAPI) => {
    try {
        return await wspService.registerBizDetails(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const registerProjectDetails = createAsyncThunk('wsp/register/project-details', async (formData, thunkAPI) => {
    try {
        return await wspService.registerProjectDetails(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const fetchWspApplications = createAsyncThunk('wsp/applications', async (projId, thunkAPI) => {
    try {
        //const partnerAccId = thunkAPI.getState().wsp.wsp.usrAccId;
        const partnerAccId = thunkAPI.getState().auth.user.usrAccId;
        const accId = thunkAPI.getState().auth.user.usrAccId;
        if (accId && thunkAPI.getState().auth.user.usrJbrnId === 1) {
            return await wspService.fetchWspApplications(accId)
        }
        else if (projId) {
            return await wspService.fetchWspApplicationsPartnerOne(projId)
        }
        else if (!projId && partnerAccId) {
            return await wspService.fetchWspApplicationsPartner(partnerAccId)
        }
        else { }

    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchWspApplicationsPartner
export const fetchWspApplicationsPartner = createAsyncThunk('wsp/applicationsPartner', async (_, thunkAPI) => {
    const partnerAccId = thunkAPI.getState().auth.user.usrAccId;
    try {
        return await wspService.fetchWspApplicationsPartner(partnerAccId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});



export const sendOTP = createAsyncThunk('wsp/sendOtp', async (formData, thunkAPI) => {
    try {
        return await wspService.sendOTP(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const RefetchOTP = createAsyncThunk('wsp/register/verify-otp', async (Id, thunkAPI) => {
    try {
        // const Id = thunkAPI.getState().auth.user.usrSalt;
        return await wspService.verifyOTP(Id)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


export const wspSlice = createSlice({
    name: 'wsp',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isLoadingWsp = false
            state.isLoadingWspPartner = false
            state.isProcessing = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.messageBas = ''
            state.messageAc = ''
            state.messageFinal = ''
            state.isCleanupForm = false
            state.isCleanupFormWithPartner = false
            state.isErrorBas = false
            state.isProcessingBas = false
            state.isLoadingBas = false
            state.isSuccessBas = false
            state.isErrorAc = false
            state.isProcessingAc = false
            state.isLoadingAc = false
            state.isSuccessAc = false
            state.isErrorFinal = false
            state.isProcessingFinal = false
            state.isLoadingFinal = false
            state.isSuccessFinal = false

            state.isErrorSend = false
            state.isProcessingSend = false
            state.isLoadingSend = false
            state.isSuccessSend = false

            state.isErrorResend = false
            state.isProcessingResend = false
            state.isLoadingResend = false
            state.isSuccessResend = false
        },
        setBasicDetails: (state, action) => {
            state.basicDetails = action.payload
        },
        setProjIdSend: (state, action) => {
            state.sendProjId = action.payload
        },

        setBusinessDetails: (state, action) => {
            state.businessDetails = action.payload
        },
        setServices: (state, action) => {
            state.services = action.payload
        },
        setProjectDesc: (state, action) => {
            // state.projectDesc = action.payload
            state.projectDesc.projDesc = action.payload.projDesc
            state.projectDesc.projCost = action.payload.projCost
            state.projectDesc.usrEncryptedPassword = action.payload.usrEncryptedPassword
            state.projectDesc.usrConfirmPassword = action.payload.usrConfirmPassword
        },
        setSocietyImpacts: (state, action) => {
            state.societyImpacts = action.payload
        },
        setPasswordErrorDetails: (state, action) => {
            state.errorPassword = action.payload
        },
        setEmailErrorDetails: (state, action) => {
            state.errorEmail = action.payload
        },
        setPhoneErrorDetails: (state, action) => {
            state.errorPhone = action.payload
        },
        setPhoneAltErrorDetails: (state, action) => {
            state.errorAltPhone = action.payload
        },

        cleanupForm: (state) => {
            state.basicDetails = initialState.basicDetails
            state.sendProjId = initialState.sendProjId
            state.businessDetails = initialState.businessDetails
            state.services = initialState.services
            state.projectDesc = initialState.projectDesc
            state.wsp = initialState.wsp
            state.wspAccount = initialState.wspAccount
            state.wspProject = initialState.wspProject
            state.societyImpacts = initialState.societyImpacts
            state.errorEmail = initialState.errorEmail
            state.errorPassword = initialState.errorPassword
            state.errorPhone = initialState.errorPhone
            state.errorAltPhone = initialState.errorAltPhone
            state.wspApplications = initialState.wspApplications

        },
        cleanupFormWithPartner: (state) => {
            state.basicDetails = initialState.basicDetails
            state.sendProjId = initialState.sendProjId
            state.businessDetails = initialState.businessDetails
            state.services = initialState.services
            state.projectDesc = initialState.projectDesc
            state.wsp = initialState.wsp
            state.wspAccount = initialState.wspAccount
            state.wspProject = initialState.wspProject
            state.societyImpacts = initialState.societyImpacts
            state.errorEmail = initialState.errorEmail
            state.errorPassword = initialState.errorPassword
            state.errorPhone = initialState.errorPhone
            state.errorAltPhone = initialState.errorAltPhone
            state.wspApplications = initialState.wspApplications

        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(registerWsp.pending, (state) => {
                state.isProcessingBas = true
            })
            .addCase(registerWsp.fulfilled, (state, action) => {
                state.isProcessingBas = false
                state.isSuccessBas = true
                state.wsp = action.payload
                state.messageBas = "Registraction step1 successfully."
            })
            .addCase(registerWsp.rejected, (state, action) => {
                state.isProcessingBas = false
                state.isErrorBas = true
                // state.wsp={}
                state.messageBas = action.payload
            })


            .addCase(registerBizDetails.pending, (state) => {
                state.isProcessingAc = true
            })
            .addCase(registerBizDetails.fulfilled, (state, action) => {
                state.isProcessingAc = false
                state.isSuccessAc = true
                state.wspAccount = action.payload
                state.messageAc = "Business details registered successfully."
            })
            .addCase(registerBizDetails.rejected, (state, action) => {
                state.isProcessingAc = false
                state.isErrorAc = true
                state.wspAccount = {}
                state.messageAc = action.payload
            })

            .addCase(registerProjectDetails.pending, (state) => {
                state.isLoadingFinal = true
            })
            .addCase(registerProjectDetails.fulfilled, (state, action) => {
                state.isLoadingFinal = false
                state.isSuccessFinal = true
                state.wspProject = action.payload
                state.messageFinal = "Great! Project registered successfully."
                state.isCleanupForm = true
            })
            .addCase(registerProjectDetails.rejected, (state, action) => {
                state.isLoadingFinal = false
                state.isErrorFinal = true
                state.wspProject = {}
                state.messageFinal = action.payload
                state.isCleanupForm = false
            })

            //resend_otp

            .addCase(RefetchOTP.pending, (state) => {
                state.isLoadingResend = true
            })
            .addCase(RefetchOTP.fulfilled, (state, action) => {
                state.isLoadingResend = false
                state.isSuccessResend = true
                //  state.OTP=action.payload
                state.message = "OTP sent successfully."
            })
            .addCase(RefetchOTP.rejected, (state, action) => {
                state.isLoadingResend = false
                state.isErrorResend = true
                state.message = action.payload
            })

            //send_otp

            .addCase(sendOTP.pending, (state) => {
                state.isLoadingSend = true
            })
            .addCase(sendOTP.fulfilled, (state, action) => {
                state.isLoadingSend = false
                state.isSuccessSend = true
                //  state.sendOtpCode=action.payload
                state.message =
									'Account verified successfully,Proceed to login ';
            })
            .addCase(sendOTP.rejected, (state, action) => {
                state.isLoadingSend = false
                state.isErrorSend = true
                // state.message = action.payload
                state.message = "ERROR SEND OTP IN TECHNICAL ADVISOR/WSP/PARTNER"
 
            })

            .addCase(fetchWspApplications.pending, (state) => {
                state.isLoadingWsp = true
            })
            .addCase(fetchWspApplications.fulfilled, (state, action) => {
                state.isLoadingWsp = false
                state.isSuccess = true
                state.wspApplications = action.payload
            })
            .addCase(fetchWspApplications.rejected, (state, action) => {
                state.isLoadingWsp = false
                state.isError = true
                state.wspApplications = []
                state.message = action.payload
            })

            .addCase(fetchWspApplicationsPartner.pending, (state) => {
                state.isLoadingWspPartner = true
            })
            .addCase(fetchWspApplicationsPartner.fulfilled, (state, action) => {
                state.isLoadingWspPartner = false
                state.isSuccess = true
                state.wspApplicationsPartner = action.payload
            })
            .addCase(fetchWspApplicationsPartner.rejected, (state, action) => {
                state.isLoadingWspPartner = false
                state.isError = true
                state.wspApplicationsPartner = []
                state.message = action.payload
            })




    }
})

export const { reset,
    setBasicDetails,
    setProjIdSend,
    setBusinessDetails,
    setServices,
    setSocietyImpacts,
    setProjectDesc,
    setPasswordErrorDetails,
    setEmailErrorDetails,
    setPhoneErrorDetails,
    setPhoneAltErrorDetails,
    cleanupForm,
    cleanupFormWithPartner,
} = wspSlice.actions
export default wspSlice.reducer