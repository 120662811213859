import React, { useEffect, useState } from 'react'
import { Checkbox, Chip, TextField, Typography } from '@mui/joy'
import { Divider, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import FlatRate from '../../../components/Tables/FlatRate';
import Volumetric from '../../../components/Tables/Volumetric';
import { setStep5, setStep5Tariff, setStep5TariffMultiple, setStep5TariffType } from '../../../features/onboard_partner/onboardPartnerSlice';
import { fetchWspApplications } from '../../../features/wsp/wspSlice';

const TariffSysytem = ({ wspApplications }) => {
  const dispatch = useDispatch();

  const { step5Tariff, step5TariffType, step5TariffMultiple } = useSelector(state => state.onboardPartner)

  const [formData, setFormData] = useState(wspApplications[0] || step5Tariff);
  const [typeData, setTypeData] = useState(wspApplications[0] || step5TariffType);
  // const [typeMultipleData, setTypeMultipleData] = useState(step5TariffMultiple);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingType, setIsUpdatingType] = useState(false);
  const [isUpdatingTypeMultiple, setIsUpdatingTypeMultiple] = useState(false);
  const [isChecked, setIsChecked] = useState(false);


  const { projTariffSystem } = formData;
  const { projTariffType } = typeData;
  // const { projTariffSystemFlatRate, projTariffSystemVolumetric } = typeMultipleData;

  // wspApplications[0].projTariffSystem ? wspApplications[0].projTariffSystem.split(",") : []

  const handleSelectChangeSystem = (e) => {
    if (e.target.checked) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
      setIsUpdating(true);
      setIsUpdatingType(false);
      setIsUpdatingTypeMultiple(false)
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.name]: ''
      }))
      setIsUpdating(true);
      setIsUpdatingType(false);
      setIsUpdatingTypeMultiple(false)
    }
  };

  const handleSelectChangeType = (e) => {
    if (e.target.checked) {
      setTypeData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
      setIsUpdatingType(true);
      setIsUpdating(false);
      setIsUpdatingTypeMultiple(false)
    } else {
      setTypeData((prevState) => ({
        ...prevState,
        [e.target.name]: ''
      }))
      setIsUpdatingType(true);
      setIsUpdating(false);
      setIsUpdatingTypeMultiple(false)
    }
  };

  // const handleSelectChangeTypeMultiple = (e) => {
  //   if (e.target.checked) {
  //     setTypeMultipleData((prevState) => ({
  //       ...prevState,
  //       [e.target.name]: e.target.value,
  //     }))
  //     setIsUpdatingTypeMultiple(true)
  //     setIsUpdatingType(false);
  //     setIsUpdating(false);
  //   } else {
  //     setTypeMultipleData((prevState) => ({
  //       ...prevState,
  //       [e.target.name]: ''
  //     }))
  //     setIsUpdatingTypeMultiple(true)
  //     setIsUpdatingType(false);
  //     setIsUpdating(false);
  //   }
  // };



 

  useEffect(() => {
    if (isUpdating) {
      dispatch(setStep5Tariff(formData))
    }
     if (isUpdatingType) {
      dispatch(setStep5TariffType(typeData))
    }
    //  if (isUpdatingTypeMultiple) {
    //   dispatch(setStep5TariffMultiple(typeMultipleData))
    // }
    else {
    }
  }, [dispatch, isUpdating, isUpdatingType, isUpdatingTypeMultiple, formData, typeData])

  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Typography component="h6" className="fmw-onboard-partner-label" style={{ marginBottom: '1rem' }}>
            Is the tariff the same for every customer ? cc
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Checkbox
                disabled={isChecked}
                name="projTariffType"
                checked={typeData.projTariffType == "Yes"}
                value="Yes"
                onChange={handleSelectChangeType}
                color="primary"
                label="Yes"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>

            <Grid item xs={6}>
              <Checkbox
                disabled={isChecked}
                name="projTariffType"
                checked={typeData.projTariffType == "No"}
                value="No"
                onChange={handleSelectChangeType}
                color="primary"
                label="No"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>

          </Grid>
        </Grid>

        {projTariffType && (
          <Grid item xs={12}>
            <Typography component="h6" className="fmw-checkbox-label" style={{ marginBottom: '1rem' }}>
              Tariff system in place
              {
                projTariffType == "No" ? (
                  <Typography variant={"span"} sx={{ my: 2, fontSize: '13px', color: '#777' }}>
                    (Fill both flat rate and volumetric tables) </Typography>
                ) : (null)
              }

            </Typography>
            <Grid container spacing={0}>
              {
                projTariffType == "Yes" &&  (
                  <>
                    <Grid item xs={6}>
                      <Checkbox
                        disabled={isChecked}
                        name="projTariffSystem"
                        checked={formData.projTariffSystem == "Flat rate"}
                        value="Flat rate"
                        onChange={handleSelectChangeSystem}
                        color="primary"
                        label="Flat rate (same rate every month)"
                        size="lg"
                        variant="soft"
                        className="fmw-checkbox-label"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Checkbox
                        disabled={isChecked}
                        name="projTariffSystem"
                        checked={formData.projTariffSystem == "Volumetric"}
                        value="Volumetric"
                        onChange={handleSelectChangeSystem}
                        color="primary"
                        label="Volumetric (Based on volume consumed)"
                        size="lg"
                        variant="soft"
                        className="fmw-checkbox-label"
                      />
                    </Grid>
                  </>
                ) 
                // :
                //  (
                //   <>
                //     <Grid item xs={6}>
                //       <Checkbox
                //         disabled={isChecked}
                //         name="projTariffSystemFlatRate"
                //         checked={true}
                //         value="Flat rate"
                //         onChange={handleSelectChangeTypeMultiple}
                //         color="primary"
                //         label="Flat rate (same rate every month)"
                //         size="lg"
                //         variant="soft"
                //         className="fmw-checkbox-label"
                //       />
                //     </Grid>

                //     <Grid item xs={6}>
                //       <Checkbox
                //         disabled={isChecked}
                //         name="projTariffSystemVolumetric"
                //         checked={true}
                //         value="Volumetric"
                //         onChange={handleSelectChangeTypeMultiple}
                //         color="primary"
                //         label="Volumetric (Based on volume consumed)"
                //         size="lg"
                //         variant="soft"
                //         className="fmw-checkbox-label"
                //       />
                //     </Grid>
                //   </>
                // )
              }


            </Grid>
          </Grid>
        )}

        {(projTariffSystem && projTariffType == "Yes") ? (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              {(projTariffSystem == "Flat rate") && (
                <FlatRate wspApplications={wspApplications} />
              )}

              {(projTariffSystem == "Volumetric") && (
                <Volumetric wspApplications={wspApplications} />
              )}

            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={0}>
               
                <FlatRate wspApplications={wspApplications} />
              

              
                <Volumetric wspApplications={wspApplications} />
               
            </Grid>
          </Grid>
        )
        }




        {/* {(projTariffSystem && projTariffType) && (
          <Grid item xs={12}>
            <Grid container spacing={0}>
              {(projTariffSystem == "Flat rate") && (
                <FlatRate wspApplications={wspApplications} />
              )}

              {(projTariffSystem == "Volumetric") && (
                <Volumetric wspApplications={wspApplications} />
              )}

            </Grid>
          </Grid>
        )} */}
      </Grid>
    </>
  )
}

export default TariffSysytem