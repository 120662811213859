import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from 'react-toastify';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { FormHelperText, Grid, Input, Typography } from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';
import { reset, updateManagementTool } from '../../features/onboard_partner/onboardPartnerSlice';
import { useEffect } from 'react';
import { formDataFromObject } from '../../utils';
import { CircularProgress } from '@mui/material';
import { ManagementToolData} from '../../Step3Data';
import ManagementToolFile from '../onboard_wsp_files/ManagementToolFile';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const ManagementToolEdit = ({ open, handleClickOpen, handleClose, toolEdited }) => {

    const dispatch = useDispatch();
    const { isLoading, isSuccessUpdateManagementTool, isLoadingUpdateManagementTool, isErrorManagementTool, message,step2MgmtToolPdf } = useSelector((state) => state.onboardPartner)
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState('')

    const handleChange = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
   
    const updateToolsDetails = () => {
        dispatch(updateManagementTool({
            mtId: formData.mtId,
            mtType: formData.mtType,
            mtFile: step2MgmtToolPdf.length > 0 ? (step2MgmtToolPdf) : (null)
        }));
    }


    useEffect(() => {
        setFormData(toolEdited)
        if (isSuccessUpdateManagementTool) {
            toast.success('Management tool updated successfully ');
            handleClose();
        }
        if (isErrorManagementTool && message) {
            toast.warning(message)
        }
        return () => {
            dispatch(reset())
        }

    }, [dispatch, toolEdited, isSuccessUpdateManagementTool, isErrorManagementTool, message])
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth="md"
            >
                {
                    isLoading ? (<><p>loading...</p></>)
                        : (<>
                            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="h6" className="fmw-checkbox-label" style={{ display: 'flex', alignItems: 'center' }}>Editing:
                                    <Typography sx={{ ml: 1 }} component="p" className="fmw-onboard-partner-sub-label-modal">{formData?.mtType}</Typography>
                                </Typography>

                            </BootstrapDialogTitle>
                            <DialogContent dividers>

                                <Grid item xs={6}>
                                    <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                                    Type of tool
                                    </Typography>
                                    <Select
                                        className="fmw-select"
                                        onChange={(e, newval) => {
                                            handleChange({
                                                target: {
                                                    value: newval,
                                                    name: "mtType"
                                                }
                                            })
                                        }}
                                        placeholder="Select tool *"
                                        indicator={<KeyboardArrowDown />}
                                        size='lg'
                                        name="mtType"
                                        value={formData?.mtType ? formData?.mtType : ''}
                                        sx={{
                                            width: '100%',
                                            [`& .${selectClasses.indicator}`]: {
                                                transition: '0.2s',
                                                [`&.${selectClasses.expanded}`]: {
                                                    transform: 'rotate(-180deg)',
                                                },
                                            },
                                        }}
                                    >
                                        {ManagementToolData?.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <ManagementToolFile formData={toolEdited}/>
                                </Grid>


                            </DialogContent>
                            <DialogActions>
                                {!isLoadingUpdateManagementTool ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                                        onClick={updateToolsDetails}
                                    >
                                        Update Management tools
                                    </Button>
                                ) :

                                    (
                                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                                            <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                                        </Button>
                                    )}
                            </DialogActions></>)


                }
            </BootstrapDialog>
        </div>
    );
}
export default ManagementToolEdit