import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg';
import leftArrow from '../../assets/chevron-left.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Checkbox, Form, Input, Spin } from 'antd';
import AuthSideImage from '../../components/auth/AuthSideImage';
import PasswordChecklist from 'react-password-checklist';
import circle from '../../assets/Image 258.png';
import {
	saveWspBusiness,
	saveWspDirector,
	saveWspProject,
	saveWspRegistration,
	setWspPassword,
} from '../../features/wsp_registration/wspRegSlice';
import { toast } from 'react-toastify';

function PasswordField() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [form] = Form.useForm();
	const formRef = useRef(null);

	const {
		projectDescription,
		basicDetails,
		businessDetails,
		managementDetails,
		wspLoading,
	} = useSelector((state) => state.wspReg);

	const [password, setPassword] = useState('');

	const onChange = (e) => {
		console.log(`checked = ${e.target.checked}`);
	};

	console.log(
		projectDescription,
		basicDetails,
		businessDetails,
		managementDetails,
	);
	const updatedProjectDescription = {
		...projectDescription,
		projCost: projectDescription.projCost.replace(/,/g, ''),
	  };
	  

	const onFinish = async (data) => {
		const isPasswordValid =
			password.length >= 8 && // Minimum length
			/\d/.test(password) && // At least one digit
			/[A-Z]/.test(password) && // At least one uppercase letter
			/[!@#$%^&*()_+[\]{};':"\\|,.<>/?]+/.test(password); // At least one special character

		if (!isPasswordValid) {
			toast.warning(
				'Password does not meet the required conditions or does not match',
			);
			return;
		} else {
			let usrCopy = { ...basicDetails, usrEncryptedPassword: password };
			await delete usrCopy.usrAltFullNames;
			 
			let projCopy = {
				...updatedProjectDescription,
				projIncomeExpenditureFile: businessDetails.projIncomeExpenditureFile,
				projRegCertFile: businessDetails.projRegCertFile,
			};
			const usrRes = await dispatch(saveWspRegistration(usrCopy));

			console.log('usrRes', usrRes);

			if (usrRes?.payload?.success) {
				let bizCopy = {
					...businessDetails,
					accId: usrRes?.payload?.jsonData?.usrAccId,
				};
				delete bizCopy.attFile;
				delete bizCopy.attFile2;
				delete bizCopy.projIncomeExpenditureFile;
				delete bizCopy.projRegCertFile;

				const bizObj = await dispatch(saveWspBusiness(bizCopy));
 

				if (bizObj?.payload?.success) {
					 
					 projCopy.projAccId = bizObj?.payload?.jsonData?.accId;

					const projObj = await dispatch(saveWspProject(projCopy));

		

					if (projObj?.payload?.success) {
						const mngCopy = [...managementDetails];

						let newMngCopy = mngCopy?.map((item) => {
							// delete item?.attFile;
							return { ...item, dirProjId: projObj?.payload?.jsonData?.projId };
						});

						await newMngCopy?.forEach((item) => {
							dispatch(saveWspDirector(item));
						});

						await toast.success(
							'Registration Success. Please login to continue',
						);
						await navigate('/login');
					} else {
						toast.warning("Error occurred while saving project description, please try again");
					}
				} else {
					toast.warning("Error occurred while saving business details, please try again");
				}
			} else {
				toast.warning("Error occurred while saving basic details, please try again");
			}
		}
	};

	useEffect(() => {}, [
		projectDescription,
		basicDetails,
		businessDetails,
		managementDetails,
	]);

	return (
		<>
			<div className='lg:grid lg:grid-cols-6'>
				<div className='bg-[#226cbf] w-full h-[100vh] col-span-2 relative hidden lg:block'>
					<AuthSideImage hideStepper={false} hideText={false} current={4} />
				</div>
				<div className='lg:col-span-4'>
					<div
						className='border border-darkBlue h-[44px] w-[44px] rounded-full mt-[48px] 
          ml-[48px] items-center justify-center flex'
						onClick={() => navigate('/signup-wsp/project-description')}
					>
						<img src={leftArrow} alt='backarrow' /> 
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
						}}
					>
						<img src={logo} alt='logo' style={{ marginTop: '10px' }} />

						<div class='text-center text-blackest text-[28px] font-bold font-dmSans leading-[44px] mt-[23px] '>
							Lastly, let’s set up your password
						</div>
						<div class='w-[497px] text-center text-gray text-[14px] font-normal font-dmSans leading-tight mt-[23px]'>
							Almost there.
							<br />
							Set a strong password to secure your account.
						</div>
						<div className='flex justify-center'>
							<Form
								layout='vertical'
								ref={formRef}
								name='control-ref'
								onFinish={onFinish}
								className='lg:w-[700px] w-auto max-w-[100%] mt-[33px]'
								form={form}
								initialValues={{
									requiredMarkValue: false,
								}}
								requiredMark={false}
							>
								<div className='grid grid-cols-1 mb-[-23px]'>
									<Form.Item
										name='usrEncryptedPassword'
										rules={[
											{
												required: true,
												message: 'Please add password',
											},
										]}
									>
										<Input.Password
											onChange={(e) => setPassword(e.target.value)}
											placeholder='Create password'
											className='rounded-[8px] h-[52px] w-full border border-black'
										/>
									</Form.Item>

									<PasswordChecklist
										className='font-dmSans text-gray font-[400] text-[13px] grid grid-cols-2 gap-4 items-center'
										rules={['minLength', 'specialChar', 'number', 'capital']}
										minLength={8}
										value={password}
										messages={{
											minLength: 'A minimum of 8 characters',
											specialChar: 'At least one symbol (~!@$&)',
											number: 'At least one number (0-9)',
											capital: 'At least one upper case letter (A-Z)',
										}}
										iconComponents={{
											ValidIcon: (
												<svg
													xmlns='http://www.w3.org/2000/svg'
													fill='none'
													viewBox='0 0 24 24'
													stroke-width='1.5'
													stroke='currentColor'
													className='w-6 h-6 text-darkBlue'
												>
													<path
														stroke-linecap='round'
														stroke-linejoin='round'
														d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
													/>
												</svg>
											),
											InvalidIcon: (
												<img
													src={circle}
													className='h-[22px] mr-2 inline-block align-text-bottom'
												/>
											),
										}}
										validColor='#147CBC'
										iconSize={5}
									/>
								</div>

								<div className='text-center mt-[103px]'>
									<button
										disabled={wspLoading}
										className='w-[172px] h-[52px] px-5 py-2.5 bg-darkBlue rounded-[52px] justify-center items-center gap-2 inline-flex
                text-white text-[18px] font-medium font-dmSans leading-normal'
										type='submit'
									>
										{wspLoading ? <Spin /> : 'Continue'}
									</button>
								</div>
							</Form>
						</div>
						<div className="absolute bottom-1 mt-10 mb-[30px] text-center text-darkBlue text-[14px] font-normal font-['Inter'] leading-snug">
							Privacy Policy • Terms and Conditions
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PasswordField;
