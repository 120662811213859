import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { cashConverter, dateConverter } from "../../../utils";
import SearchField from "../../../components/search_field/SearchField";

const ProjectCard = ({ projectItem, category }) => {
  const navigate = useNavigate();
  const {
    accTradeName,
    projCost,
    projStatus,
    projLoanAmount,
    accUpdatedAt,
    projId,
  } = projectItem;

  const myStyles = {
    color: "#17C0C0",
  };
  const altStyle = {
    color: "inherit",
  };

  return (
    <Grid item sm={12} md={12}>
       {/* <SearchField /> */}
      <Card variant="outlined" sx={{ color: "fff", pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "#A9A9A9",
            fontSize: 4,
            p: 0.6,
            textTransform: "capitalize",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              fontSize: "11px",
              alignItems: "center",
              gap: "1px",
            }}
          >
            {projStatus === "ACTIVE" ? "Active" : "Pending"}
            <FiberManualRecordIcon
              fontSize="inherit"
              style={projStatus === "ACTIVE" ? myStyles : altStyle}
            />
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            {dateConverter(accUpdatedAt)}
          </Typography>
        </Box>
        <Divider />
        <CardContent>
          <Box sx={{ display: "flex", flexDirection: "column", px: 2, pt: 2 }}>
            <Typography
              sx={{ fontWeight: "bold", color: "#484848", fontSize: "12px" }}
            >
              Project:
            </Typography>
            <Typography
              sx={{
                color: "#A9A9A9",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {accTradeName}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", px: 2, py: 1 }}>
            <Typography sx={{ color: "#A9A9A9", fontSize: 12 }}>
              Project application cost
            </Typography>
            <Typography
              sx={{
                color: "#1A78E2",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {cashConverter(projLoanAmount)}
            </Typography>
          </Box>

          <Button
            onClick={() =>
              navigate(`/expression-details/${projId}/${category}`)
            }
            style={{
              backgroundColor: "#00BABA",
              margin: 10,
              textTransform: "capitalize",
              width: "fit-content",
            }}
            variant="contained"
          >
            view Project
          </Button>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProjectCard;
