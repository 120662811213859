import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { EmailShareButton, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappIcon, EmailIcon, WhatsappShareButton } from 'react-share';
export function SocialMediaDropdown() {

  const {
    user,
    newUsers,
    isLoadingNewUser,
    isSuccessDelete,
    isLoadingDelete,
    isSuccessNewUser,
  } = useSelector((state) => state.auth);

  const shareUrl = `https://fundmywater.com/login/${user.usrId}`;
  const title = "Unlock the Power of Sharing: Share and Earn Rewards!";



  // const FacebookIcon = generateShareIcon('facebook');
  // const TwitterIcon = generateShareIcon('twitter');
  // const WhatsappIcon = generateShareIcon('whatsapp');
  // const EmailIcon = generateShareIcon('email');


  return (

    <Box sx={{ display: 'flex', gap: 2 }}>
      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon size={32} round color='red' />
      </FacebookShareButton >

      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <EmailShareButton url={shareUrl} subject={title} body="Check out this amazing content!">
        <EmailIcon size={32} round />
      </EmailShareButton>

    </Box>

  );
}
