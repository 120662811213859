import { Avatar, Card, CardHeader, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

function ChartHeader({handleFetchFile}) {
	const user = useSelector((state) => state.messages.currentChat);
	function capitalizeWords(str) {
		if (!str)
			return (
				<Skeleton
					animation={false}
					variant='text'
					width={90}
					sx={{ fontSize: '1rem' }}
				/>
			);
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	}
	
	function handleFileChange(e){
		handleFetchFile(e.target.files[0])
	}

	return (
		<>
			{user && Object.keys(user) ? (
				<Card
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: '3px solid #f4f4f4',
						boxShadow: 'none',
					}}
				>
					<CardHeader
						sx={{ fontWeight: 'bold', p: '10px', ml: '13px' }}
						avatar={
							<Avatar
								sx={{ bgcolor: '#00baba', width: 35, height: 35 }}
								aria-label=''
							>
								{Object.keys(user) && user.withParty ? (
									user.withParty.substring(0, 1).toUpperCase()
								) : (
									<Skeleton
										variant='circular'
										animation={false}
										width={50}
										height={50}
									/>
								)}
							</Avatar>
						}
						title={capitalizeWords(user.withParty)}
					/>
					<IconButton
						sx={{ width: 39, height: 39, mr: '20px', color: 'black' }}
						aria-label='upload picture'
						component='label'
					>
						<input name="file" onChange={e => handleFileChange(e)} multiple={false} hidden type='file' />
						<AttachFileIcon sx={{ transform: 'scaleX(-1)' }} />
					</IconButton>
				</Card>
			) : (
				<Card
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						borderBottom: '3px solid #f4f4f4',
						boxShadow: 'none',
						p: 2,
					}}
				>
					<b>Start a conversation</b>
				</Card>
			)}
		</>
	);
}

export default ChartHeader;
