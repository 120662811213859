import {
	Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Typography, Button,  } from '@mui/joy';
import { FilePresentOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function LetterOfIntent() {
	const navigate = useNavigate();

	const ViewTermsheet = (e) => {
		navigate(`/view-termsheet/${234}`);
	};

	return (
		<>
			<Typography component='h3' className='fmw-page-title'>
				Letter of Intent
			</Typography>

			<div
				style={{
					display: 'flex',
                    flexDirection: 'column',
                    marginTop: '15px',
					justifyContent: 'space-between',
				}}
			>

				<Grid
					style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '10px', }}
				>
                    <Typography
						component='p'
						className='term'
						sx={{
							fontSize: '1rem',
							color: '#00BABA',
							fontWeight: 'bold',
						}}
					>
						View Letter of Intent pdf{' '}
					</Typography>
					<FilePresentOutlined
						sx={{ mr: 1, fontSize: '2rem', color: '#00BABA' }}
						onClick={ViewTermsheet}
					/>
					
				</Grid>

                <Button className='next-btn apply-btn'>Open Bank Account</Button>
			</div>
		</>
	);
}
