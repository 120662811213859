import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const saveTermsheet = async (formData) => {
	const response = await axios.post(
		`${API_URL}/usr/saveLoanTermCondition.action`,
		formData,
	);

	if (!response.data.success) {
		return Promise.reject(response.data.message);
	}

	return response.data.jsonData;
};

const fetchBidsByUserProj = async (formData) => {
	const response = await axios.get(
		`${API_URL}/usr/fetchLoanTermConditions.action?ltcProjId=${formData?.ltcProjId}&ltcAccId=${formData?.ltcAccId}`,
	);
	if (!response.data.success) {
		return Promise.reject(response.data.message);
	}

	return response.data.data.result;
};

const fetchLoanTermConditionsPartnerBids = async ({ ltcAccId }) => {
	const response = await axios.get(
		`${API_URL}/usr/fetchLoanTermConditions.action?ltcAccId=${ltcAccId}`,
	);
	if (!response.data.success) {
		return Promise.reject(response.data.message);
	}

	return response.data.data.result;
};

const fetchLoanTermConditionsPartnerBidsSingle = async ({ ltcId }) => {
	const response = await axios.get(
		`${API_URL}/usr/fetchLoanTermConditions.action?ltcId=${ltcId}`,
	);
	if (!response.data.success) {
		return Promise.reject(response.data.message);
	}

	return response.data.data.result;
};
// updateLoanTermConditionsPartnerBids
const updateLoanTermConditionsPartnerBids = async (formData) => {
	const response = await axios.post(
		`${API_URL}/usr/saveLoanTermCondition.action`,
		formData,
	);

	if (!response.data.success) {
		return Promise.reject(response.data.message);
	}
	return response.data.jsonData;
};

const dealSourcingService = {
	saveTermsheet,
	fetchLoanTermConditionsPartnerBids,
	fetchLoanTermConditionsPartnerBidsSingle,
	updateLoanTermConditionsPartnerBids,
    fetchBidsByUserProj,
};
export default dealSourcingService;
