import {

  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Box,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Textarea } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import ConfirmationModal from "../../../pages/expression_demand/components/ConfrimationModal";
import NoTableDataComponent from "../../../expression_demand/tables/NoTableDataComponent";
import { fetchProjectScore, fetchScoreStep, postScoreStep, postProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import ConfirmationModal from "../../../expression_demand/components/ConfrimationModal";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material"
import TextareaComment from "../../components/TextareaComment";
const FinalTable = () => {
  const stepName = "FINAL_VERDICT";
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const location = useLocation()
  const category = location.pathname.split('/')[3]

  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );

  const { id } = useParams();

  const data = projectData?.find(
    (item) => item?.projId === Number(id)
  );


  const { projectScore, stepScore, loading } = useSelector(
    (state) => state.expressionDemandData
  );

  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const totalScore = stepScore?.reduce((accumulator, currentValue) => {
    if (currentValue.sctStep === "FINAL_VERDICT") {
      return accumulator;
    }
    return accumulator + currentValue.sctTotalScore;
  }, 0);

  const fetchProjectScores = async () => {
    const info = { projId: data.projId, page: stepName };
    const res = await dispatch(fetchProjectScore(info));
    const step = await dispatch(fetchScoreStep(info));
  };

  const updateComment = (scoresList) => {
    scoresList?.forEach((step) => {
      if (step.sctStep === "FINAL_VERDICT") {
        setComment(step.sctComment ?? "");
      }
    });
  };

  const finalData = [];

  stepScore &&
    stepScore?.forEach((item) => {
      let newData = {};
      if (item.sctStep === "GOVERNANCE_INFO") {
        newData = {
          name: "Governance information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "PROJECT_DESCRIPTION") {
        newData = {
          name: "Existing Project Description & Infrastructure",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "CUSTOMER_DETAIL") {
        newData = {
          name: "Customer Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "LOAN_INFO") {
        newData = {
          name: "Loan Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item.sctStep === "FINANCIAL_SUMMARY") {
        newData = {
          name: "Financial Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      }
      if (item.sctStep !== "FINAL_VERDICT") {
        finalData.push(newData);
      }

      // return newData;
    });

  const submitToCommit = async () => {
    setMessage("Are you sure you want to send a letter of intent");
    setOpenModal(true);
    setType("COMMITTED");
  };

  const submitToDealRoom = async () => {
    setMessage("Are you sure you want to send the project to deal room");
    setOpenModal(true);
    setType("DEAL_SOURCING");
  };


  const sendToActiveState = async () => {
    setMessage("Are you sure you want to Enable the Project to be Editable");
    setOpenModal(true);
    setType("DRAFT");
  };

  const sendToWashProjects = async () => {
    setMessage("Are you sure you want to Reject the project and send it to Washed Projects");
    setOpenModal(true);
    setType("WASH");
  };

  const submitStep = () => {
    if (comment === "") {
      toast.error("Kindly provide your final verdict");
      return;
    }
    setMessage("Are you sure you want to send the project to pipelines");
    setOpenModal(true);
    setType("VIABLE");
    const rawData = {
      scProjId: data.projId,
      scScore: totalScore,
      scMethod: stepName,
    };
    const saveData = { data, stepName };
    const formData = {
      sctStep: stepName,
      sctProjId: data.projId,
      sctComment: comment,
      sctTotalScore: totalScore,
    };
    const res = dispatch(postScoreStep(formData));
    dispatch(postProjectScore(saveData));
  };

  useEffect(() => {
    updateComment(stepScore);
  }, [stepScore]);

  useEffect(() => {
    fetchProjectScores();
  }, []);

  return (
    <>
      {/* <Typography
        sx={{
          my: 2,
          color: "#0069DF",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Final verdict
      </Typography> */}
      {loading && finalData?.length === 0 ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className='fmw-accrdion-table fmw-accrd'
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
              className='fmw-accrdion-svg'
            />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >

            <Typography
              className='accordion-title'
              sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important', fontSize: '18px', fontWeight: 500 }}>
              <DragIndicator className='fmw-accrdion-drag-svg' sx={{ marginRight: '1.25rem' }} />
              Final verdict
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0px !important' }}>
            <Table>
              <TableHead sx={{ height: "20px" }}>
                <TableRow
                >
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Step
                  </TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Comments
                  </TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Total score
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
              >
                {finalData?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                    }}
                    style={{ borderBottom: "1px solid #E4E4E4" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderLeft: "1px solid #E4E4E4",
                        borderRight: "1px solid #E4E4E4",
                        fontSize: '16px', fontWeight: '400'
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderLeft: "1px solid #E4E4E4",
                        borderRight: "1px solid #E4E4E4",
                        fontSize: '16px', fontWeight: '400',
                      }}
                    >
                      {row.comment}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                        fontSize: '16px', fontWeight: '400',
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {row.marks}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>

      )}
      {!loading && finalData?.length === 0 && <NoTableDataComponent />}

      {finalData?.length > 0 && <Button
        sx={{
          backgroundColor: '#44BA41 !important', width: '244px', height: '50px',
          fontSize: '18px', fontWeight: 500, borderRadius: '0px!important', mt: 5, mb: 7
        }}

      >
        {!loading &&
          finalData?.length > 0 &&
          `Total score: ${totalScore} Points`}

      </Button>}
      <Box sx={{ display: 'flex', m: 'auto', gap: '20vw', }}>
        {(
          category == 'draft' ||
          category == 'incomplete' ||
          category == 'complete' ||
          category == "Viable" ||
          category == "poor" ||
          category == "Assistance") && <Box>Enable WSP to update application <Button onClick={sendToActiveState} sx={{ bgcolor: '#0873b9', ml: 2 }}>Enable Edit</Button> </Box>}
        {/* {( */}
        {/* category == "Viable" || */}
        {/* category == "poor" || */}
        {/* category == "Assistance") && <Box>Reject project and send to Wash Projects <Button onClick={sendToWashProjects} sx={{ bgcolor: '#ED1C24', ml: 2 }}>Send To Wash</Button> </Box>} */}
      </Box>
      {(category === "Viable" ||
        category === "Partner" ||
        category === "COMMITTED" ||
        category == "poor" ||
        category == "Assistance" ||
        category === "DEAL_SOURCING") && (
          <>

            <Typography
              sx={{
                my: 2,
                fontSize: '18px',
                color: "#222",
                fontWeight: "500",
                textAlign: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              {(category == "Viable" || category == "Partner" || category == "poor" || category == "Assistance") ? "Give your final verdict" : "Expert review"}
            </Typography>
            <TextareaComment
              onChange={(e) => setComment(e.target.value)}
              comment={comment}
              submitStep={submitStep}
            />
          </>
        )}


      <Typography
        sx={{
          my: 2,
          color: "#0069DF",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {(category == "Viable" || category == "poor" || category == "Assistance") ? (
          <Button
            onClick={submitStep}
            sx={{
              textTransform: "capitalize", textAlign: "center", my: 2, borderRadius: "6px", width: "240px", height: "45px",
              fontSize: '16px', fontWeight: 500
            }}
          >
            send to pipeline
          </Button>
        ) : category === "Partner" ? (
          <Button
            onClick={submitToCommit}
            sx={{ textTransform: "capitalize", textAlign: "center", my: 2 }}
          >
            Offer Letter of Intent
          </Button>
        ) : category === "COMMITTED" ? (
          <Button
            onClick={submitToDealRoom}
            sx={{ textTransform: "capitalize", textAlign: "center", my: 2 }}
          >
            send to Deal Room
          </Button>
        ) : (
          <p></p>
        )}
      </Typography>

      <ConfirmationModal
        open={openModal}
        message={message}
        setOpenModal={setOpenModal}
        projId={data.projId}
        type={type}
      />
    </>
  );
};

export default FinalTable;
