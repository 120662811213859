// substring -truncates a string given number of characters
export const subString = (str = "", limit = 0) => {
  if (!str) return;
  return str.length > limit ? `${str.substring(0, limit)}...` : str;
};

// convert timestamp to human readable format
export const dateForHumans = (timestamp) => {
  if (!timestamp) return;
  let date = new Date(timestamp);

  let year = date.getFullYear();
  let month = ("0" + (date.getMonth() + 1)).slice(-2);

  // Getting short month name (e.g. "Oct")
  // let month = date.toLocaleString('default', { month: 'short' });
  let day = ("0" + date.getDate()).slice(-2);

  let hour = ("0" + date.getHours()).slice(-2);
  let min = ("0" + date.getMinutes()).slice(-2);
  let sec = ("0" + date.getSeconds()).slice(-2);

  // ${timeAmPm(timestamp)}
  return `${year}-${month}-${day}`;
};

export const timeAmPm = (timestamp) => {
  if (!timestamp) return;

  let time = new Date(timestamp).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return time;
};

// formatPreqQuestions
export const formatPreqQuestions = ({ questions, answers }) => {
  // if (questions.length) {
  for (let i = 0; i < questions.length; i++) {
    questions[i]["value"] = "";
    if (answers) {
      for (let j = 0; j < answers.length; j++) {
        if (questions[i]["lineNo"] == answers[j]["questionId"]) {
          questions[i]["value"] = answers[j]["answer"];
        }
      }
    }
  }
  // }
  return questions;
};

// formDataFromObject(obj)
export const formDataFromObject = (obj) => {
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

// formatGridRows(rows)
export const formatGridRows = (rows) => {
  let _rows = [];
  if (rows.length) {
    for (let i = 0; i < rows.length; i++) {
      let _row = { ...rows[i], id: rows[i]["lineNo"] };
      _rows.push(_row);
    }
  }
  return _rows;
};

// microsoft dynamics nav arr/list cleanup
export const arrCleanUp = (arr, key) => {
  // if (arr.length) {
  //     arr.filter(item => item[key] != 0)
  // }
  return arr.filter((item) => item[key] != 0);
};

// get last segment of a path
export const formatPath = (path) => {
  if (!path) return "";
  return path.substring(path.lastIndexOf("_") + 1);
};

// remove null values from an object
export const objCleanUp = (obj) => {
  if (!Object.keys(obj).length) return;

  Object.keys(obj).forEach((key) => {
    if (obj[key] == "" || obj[key] == null) {
      delete obj[key];
    }
  });

  return obj;
};
const getFileType = (file) => {
  return file?.substring(file?.lastIndexOf(".") + 1);
};

// export const previewPaper = (doc) => {
//   let link = formatImgPath(doc);
//   if (
//     getFileType(doc) == "docx" ||
//     getFileType(doc) == "doc" ||
//     getFileType(doc) == "pdf"
//   ) {
//     window.open(`http://docs.google.com/viewerng/viewer?url=${link}`, "_self");
//   } else {
//     window.open(link, "_self");
//   }
// };

export const previewPaper = (doc) => {
  let link = formatImgPath(doc);
  console.log(
    doc,
    "***********************************DOC TYPE***************************************"
  );
  if (
    getFileType(doc) == "docx" ||
    getFileType(doc) == "doc" ||
    getFileType(doc) == "pdf"
  ) {
    window.open(`${link}`, "_blank");
  } else {
    window.open(link, "_blank");
  }
};

export const numberWithCommas = (money) => {
  return Number(money)
    .toLocaleString({
      style: "currency",
      currency: "Kes",
    })
    .toLowerCase();
};

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function cashConverter(money) {
  return capitalize(
    Number(money)
      .toLocaleString("en-US", {
        style: "currency",
        currency: "Kes",
      })
      .toLowerCase()
  );
}

export function dateConverter(timeStamp) {
  const date = new Date(timeStamp); // Convert to milliseconds
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function handleOpenDoc(file) {
  window.open(`${file}`, "", "width=500,height=700");
}

export const formatImgPath = (path) => {
  if (
    path === null ||
    path === "" ||
    path === undefined ||
    typeof path === "object"
  ) {
    return path;
  }

  if (!path.startsWith("./")) {
    return path;
  }
  const host = window.location.host;
  const protocol = window.location.protocol;
  // const domain = "backoffice.fundmywater.com";
  const domain = "fundmywater.com";

  if (host === "localhost:3000") {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  }

  if (host === `${domain}:8080`) {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  }

  return path; // Return the path parameter if none of the conditions apply
};

export const formatPdfPath = (path) => {
  if (
    path === null ||
    path === "" ||
    path === undefined ||
    typeof path === "object"
  ) {
    return path;
  }

  if (!path.startsWith("./")) {
    return path;
  }
  const host = window.location.host;
  const protocol = window.location.protocol;
  // const domain = "backoffice.fundmywater.com";
  const domain = "fundmywater.com";

  if (host === "localhost:3000") {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  }

  if (host === `${domain}:8080`) {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  }

  return path; // Return the path parameter if none of the conditions apply
};
export function toSentenceCase(str) {
  if (str === undefined || str === null || str.length === 0) {
    return "";
  }

  const trimmed = str.trim();
  if (trimmed.length === 0) {
    return "";
  }

  const firstChar = trimmed.charAt(0);
  const rest = trimmed.slice(1);

  return firstChar.toUpperCase() + rest.toLowerCase();
}

export const formatPhoneNumber = (phone) => {
  const phoneNumber = phone;
  const formattedNumber = phoneNumber?.replace(/(\d{3})(?=\d)/g, "$1 ");
  const finalNumber = "+" + formattedNumber;
  return finalNumber;
};

 

export const removeCommas = (numberString) => {
 
  if (typeof numberString !== 'string' || numberString === null || numberString === undefined) {
 
    return numberString;
  }

  return numberString.replace(/,/g, '');
}