import { Error } from '@mui/icons-material'
import { FormHelperText } from '@mui/joy'
import React from 'react'

function FormPhoneError({title}) {
  return (
    <>
       <FormHelperText className="upload-sub-label-error">
            <Error sx={{mr:1,fontSize:'1.1rem !important'}}/>
           {title}</FormHelperText>
    </>
  )
}

export default FormPhoneError
