import React from 'react';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';
import { Button } from '@mui/joy';

export default function EventCard() {
	return (
		<>
			<Box sx={{ width: '100%', flexGrow: 1 }}>
				<img
					className='eventcard-img'
					src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzI9ua_6LvzXRDNEHwVonutt8r29z1rWTq3FHHORXP6qR3Huj6kF-rylxfQNX__pqEwMc&usqp=CAU'
					srcSet='https://images.unsplash.com/photo-1502657877623-f66bf489d236?auto=format&fit=crop&w=800&dpr=2 2x'
					loading='lazy'
					alt=''
				/>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mt: 1,
						alignItems: 'start',
					}}
				>
					<div className='events-date'>
						<Box
							sx={{
								width: 'fit',
								textAlign: 'center',
								border: '1px solid #2c2c2c',
								borderRadius: '2px',
								px: '10px',
								py: '2px',
							}}
						>
							<Typography
								sx={{}}
								level='h6'
								fontWeight='lg'
								textColor='#2c2c2c'
							>
								Feb
							</Typography>
							<Typography
								sx={{ fontWeight: '900' }}
								level='p'
								fontWeight='sm'
								textColor='#00c3e6'
							>
								31
							</Typography>
						</Box>
					</div>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'right',
                            alignItems: 'end'
						}}
					>
						<span className='event-short-line'></span>
						<Typography sx={{}} level='p' fontWeight='lg' textColor='#2c2c2c'>
							November 29th 2023
						</Typography>

						<Typography sx={{}} level='p' fontWeight='lg' textColor='#2c2c2c'>
							December 29th 2022
						</Typography>
					</Box>
				</Box>
				<Typography
					sx={{ py: 1 }}
					level='h6'
					fontWeight='lg'
					textColor='#00c3e6'
				>
					Borehole as a solution
				</Typography>

				<Typography
					sx={{ fontSize: '13.5px' }}
					level='p'
					fontWeight='xs'
					textColor='#2c2c2c'
				>
					Improve water quality by reducing pollution, eliminating dumbing and
					minimizing release of harzadous chemicals and materials, halving the
					proportion on untreated wastewater.
				</Typography>

				<Button
					className=''
					sx={{
						my: 3,
						font: 500,
						backgroundColor: '#26deff',
						width: '158px',
						borderRadius: '2px',
					}}
				>
					Read More
				</Button>
			</Box>
		</>
	);
}
