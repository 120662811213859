import React, { useEffect, useState } from 'react'
import { Checkbox, Chip, TextField, Typography } from '@mui/joy'
import { Divider, Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import OtherCharges from '../../../components/Tables/OtherCharges';
import { setStep5OtherCharges } from '../../../features/onboard_partner/onboardPartnerSlice';
import { fetchWspApplications } from '../../../features/wsp/wspSlice';

const TariffOtherCharges = ({wspApplications}) => {
  const dispatch = useDispatch();

  const { step5OtherCharges } = useSelector(state => state.onboardPartner)
  // const { wspApplications } = useSelector((state) => state.wsp)
  const [formData, setFormData] = useState(wspApplications[0] || step5OtherCharges);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChecked, setIsChecked] = useState(false);


  const { projOtherCharges, accBizType } = formData;


  const handleSelectChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
    // setIsChecked(isChecked);
    
    setIsUpdating(true);
  }



  useEffect(() => {
    if (!isUpdating) {
      // setTimeout(() => {
      //   dispatch((fetchWspApplications()))
      // }, 800)
    } else {
      dispatch(setStep5OtherCharges(formData))
    }
  }, [dispatch, isUpdating, formData])


  return (
    <>
      <Grid container spacing={2}>

        <Grid item xs={12}>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Checkbox
                disabled={isChecked}
                name="projOtherCharges"
                checked={formData.projOtherCharges == "Yes"}
                value="Yes"
                onChange={handleSelectChange}
                color="primary"
                label="Yes"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>

            <Grid item xs={6}>
              <Checkbox
                disabled={isChecked}
                name="projOtherCharges"
                checked={formData.projOtherCharges == "No"}
                value="No"
                onChange={handleSelectChange}
                color="primary"
                label="No"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>

          </Grid>
        </Grid>

        {projOtherCharges && (
          <Grid item xs={12}>

            <Grid container spacing={0}>
              {(projOtherCharges == "Yes") && (
                <OtherCharges wspApplications={wspApplications}/>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default TariffOtherCharges