import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChats, resetChat } from '../../features/message/chatUserSlice';
import Chat from './chat';

const url = process.env.REACT_APP_API_BASE_URL;

function DefaultChatMsg({fileData}) {
	const dispatch = useDispatch();
	const {chats, chatArray} = useSelector((state) => state.messages);
	const [loading, setloading] = useState(false)

	let allChats;

	if (chatArray?.length) {
		allChats = chats
	}else{
		allChats = []
	}

	let chatCopy = [...allChats];
	const activeChats = chatCopy.sort(
		(a, b) => a.msgCreatedTime - b.msgCreatedTime,
	);

	const currentActiveChat =
		useSelector((state) => state.messages.currentChat) || {};

	function fetchChatData() {
		setloading(true)
		if (!currentActiveChat.chId) return [];
		fetch(
			`${url}/usr/fetchMsgMessageQueues.action?msgChId=${currentActiveChat.chId}`,
		)
			.then((res) => res.json())
			.then((data) => {
				dispatch(handleChats(data.data.result))
				setloading(false)
			});
	}

	useEffect(() => {
		if (currentActiveChat.chId) {
			fetchChatData();
		} else {
			dispatch(resetChat());
		}
	}, [currentActiveChat]);

	return (
		<>
			<Chat fileData={fileData} messages={activeChats} />	
		</>
	);
}

export default DefaultChatMsg;


