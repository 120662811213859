import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchInvoice } from "../../features/save/saveSlice";
import { dateForHumans } from "../../utils";

function InvoiceList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving, invData } = useSelector((state) => state.save);
  const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);

  async function fetchfetchInvoiceData() {
    dispatch(
      fetchInvoice({
        projId: wspApplications?.[0]?.projId,
      })
    );
  }

  useEffect(() => {
    fetchfetchInvoiceData();
  }, []);

  const handleAddInvoice = () => {
    navigate("/project-development");
  };

  const handleView = (item) => {
    navigate(`/invoice-view/${item?.pjdId}`);
  };

  console.log("invData", invData);
  return (
    <div>
      <div className="flex justify-between w-[50%]">
        <div className="dvt-date"> Invoice List</div>
        <div
          className="dvt-date justify-end cursor-pointer !text-[#078247]"
          onClick={handleAddInvoice}
        >
          Add new Invoice
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        {invData &&
          invData?.map((item) => (
            <div
              className="inv-card p-3 mt-10 cursor-pointer"
              onClick={() => handleView(item)}
            >
              <div className="flex items-center justify-between">
                <div className="dvt-date">
                  {item?.accTradeName ?? wspApplications?.[0]?.accTradeName}
                </div>
                <div className="cursor-pointer">View</div>
              </div>

              <div className="inv-status flex items-center mt-[.5rem] gap-x-5">
                {item?.pjdDesc ?? "PENDING"}
                <span className="inv-status-desc">
                  | {dateForHumans(item?.pjdCreatedDate)}
                </span>
              </div>
              <div className="mt-[.63rem] inv-paid">
                {item?.pjdStatus ?? "NOT PAID"}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default InvoiceList;
