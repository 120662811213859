import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, TextField } from '@mui/material';
import { Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { onboardPartner } from '../../features/onboard_partner/onboardPartnerSlice';
import { fetchWspApplications, reset } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';
import { useState } from 'react';


const FlatRate = ({ wspApplications,id }) => {
  const {  isLoading,sendProjId, isSuccess } = useSelector((state) => state.wsp)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'customer',
      headerName: 'Type of customer',
      width: 250,
      sortable: false
    },
    {
      field: 'rate',
      type: 'number',
      headerName: 'Rate (KES/month)',
      width: 200,
      sortable: false,
      editable: true,
      renderCell: (params) => {
        return (
          <TextField
            id="standard-basic"
            type="number"
            variant="standard"
            value={params.row.rate}
          />
        )
      }
    },


  ];

  const rows = [
    { id: 1, rate: wspApplications[0].projIndividualRate == -1 ? "" : wspApplications[0].projIndividualRate, customer: 'Individual' },
    { id: 2, rate: wspApplications[0].projKioskRate == -1 ? "" : wspApplications[0].projKioskRate, customer: 'Kiosk' },
    { id: 3, rate: wspApplications[0].projInstitution == -1 ? "" : wspApplications[0].projInstitution, customer: 'Institution (School, HCF)' },
    { id: 4, rate: wspApplications[0].projCommercial == -1 ? "" :  wspApplications[0].projCommercial, customer: 'Commercial' },
  ];
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
   
    const id = updatedRow.id
    const rate = updatedRow.rate ? updatedRow.rate : "-1";
  
    const formdata = new FormData();
    formdata.append("projId", wspApplications[0].projId);
    // formdata.append("id", updatedRow.id);
    // formdata.append("customer", updatedRow.customer);
    formdata.append("projIndividualRate", rate);
    // projIndividualRate
    // dispatch(onboardPartner(formdata));
    if (id == 1) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projIndividualRate: rate
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
     
    }

    if (id == 2) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projKioskRate: rate
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }

    if (id == 3) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projInstitution: rate
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }

    if (id == 4) {
      dispatch(onboardPartner({
        projId: wspApplications[0].projId,
        projCommercial: rate
      }))
      if (user.usrJbrnId === 1) {
        setTimeout(() => {
          dispatch((fetchWspApplications()))
        }, 800)
      } else {
        setTimeout(() => {
          dispatch((fetchWspApplications({
            projId: sendProjId
          })))
        }, 800)
      }
    }

    return updatedRow;
  };



  // useEffect(() => {
  //   dispatch(fetchWspApplications())
  //   return () => {
  //     dispatch(reset())
  //   }

  // }, [dispatch])


  return (
    <>
      <Typography sx={{ my: 3, mt: 0 }} component="body2" className="fmw-divider" />
      <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
        Fill the flat rate table below <span className="star">*</span>
      </Typography>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <DataGrid
          autoHeight
          rows={rows}
          getRowId={(row) => row.id}
          columns={columns}
          processRowUpdate={processRowUpdate}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          loading={isLoading}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
        />
      </Paper>
    </>
  );
}

export default FlatRate
