import React, { useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import placeholder from "../../assets/placeholder.jpg";
import PropTypes from "prop-types";
import { BorderColorOutlined } from "@mui/icons-material";
import DialogActions from "@material-ui/core/DialogActions";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import { styled } from "@mui/material/styles";
import Button from "@material-ui/core/Button";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Grid, Typography, Input } from "@mui/joy";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContentText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  editProfileImage,
  fetchAccountInfo,
  updateProfileImage,
} from "../../features/tech_adv/techAdvSlice";
import { formatImgPath } from "../../utils";
import { loginDetails } from "../../features/auth/authSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function EditAccountDialog({
  open,
  setOpen,
  accId,
  user,
  formData,
  setFormData,
  openDialog,
}) {
  const { isSuccessBasic, orgDetails, techDetails } = useSelector(
    (state) => state.techAdv
  );
  const dispatch = useDispatch();
  const descriptionElementRef = useRef(null);
  const [scroll, setScroll] = useState("paper");
  const [fileUrl, setFileUrl] = useState("");
  const [image, setImage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    const response = await dispatch(editProfileImage(formData));
    setFileUrl(response.payload);
  };

  const handleProfileImageUpdate = async () => {
    const data = JSON.stringify({ accId, accIcon: fileUrl });
    const response = await dispatch(updateProfileImage(data));
    let localImg = await formatImgPath(orgDetails.accIcon);

    setImage(localImg);
  };

  const handleProfile = useCallback(async () => {
    let localImg = await formatImgPath(orgDetails.accIcon);
    setImage(localImg);

    // dispatch(fetchAccountInfo(user.usrId));
  }, [orgDetails, accId, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    const finalData = { usrId: user.usrId, accId, ...formData };
    const data = JSON.stringify(finalData);
    const response = await dispatch(updateProfileImage(data));

    dispatch(fetchAccountInfo(user.usrId));
    if (isSuccessBasic) {
      toast.success("Account updated successfully");
      handleClose();
      const userData = await dispatch(loginDetails());
    }
  };

  useEffect(() => {
    handleProfile();
  }, [orgDetails, formData, dispatch, handleProfile]);

  useEffect(() => {}, [open]);

  useEffect(() => {
    if (fileUrl !== "" && open) {
      handleProfileImageUpdate();
    }
  }, [fileUrl, open]);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {}, [openDialog]);

  return (
    <>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        width="fullscreen"
        fullWidth
      > */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          Edit Account
        </BootstrapDialogTitle>
        <DialogContent
          sx={{ width: "content-fit" }}
          dividers={scroll === "paper"}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "148px",
                  height: "148px",
                  borderRadius: "50%",
                  background: "#F7F7F7",
                  top: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <label htmlFor="file-input">
                  <img
                    src={typeof image === "object" ? placeholder : image}
                    alt="placeHolder"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      width: "148px",
                      height: "148px",
                    }}
                  />
                </label>
                <input
                  onChange={handleEditImage}
                  id="file-input"
                  type="file"
                  style={{ display: "none" }}
                />
                <BorderColorOutlined
                  sx={{
                    position: "absolute",
                    color: "#17C0C0",
                    opacity: 2,
                  }}
                />
              </div>

              <Typography sx={{ fontSize: "18px", fontWeight: "bold", mt: 2 }}>
                Edit Company Details
              </Typography>
              <Grid container spacing={2} sx={{ flexDirection: "column" }}>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Input
                    value={formData.accTradeName}
                    onChange={onChange}
                    name="accTradeName"
                    label=""
                    placeholder="Edit Company Name *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "600px", mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Input
                    value={formData.accPhoneNumber}
                    onChange={onChange}
                    name="accPhoneNumber"
                    label=""
                    placeholder="Add Company Phone Number *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <Input
                    value={formData.accLocation}
                    onChange={onChange}
                    name="accLocation"
                    label=""
                    placeholder="Add Business location*"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={formData.accZipCode}
                    onChange={onChange}
                    name="accZipCode"
                    label=""
                    placeholder="Add Business zip code *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    type="email"
                    value={formData.accEmail}
                    onChange={onChange}
                    name="accEmail"
                    label=""
                    placeholder="Add Company email *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
              </Grid>
              <Typography sx={{ fontSize: "18px", fontWeight: "bold", mt: 2 }}>
                Contact Person Information
              </Typography>
              <Grid
                container
                fullWidth
                spacing={2}
                sx={{ flexDirection: "column" }}
              >
                <Grid item fullWidth>
                  <Input
                    value={formData.usrFirstName}
                    onChange={onChange}
                    name="usrFirstName"
                    label=""
                    placeholder="Edit First Name *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item fullWidth>
                  <Input
                    value={formData.usrLastName}
                    onChange={onChange}
                    name="usrLastName"
                    label=""
                    placeholder="Edit Last Name *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item fullWidth>
                  <Input
                    value={formData.usrFullNames}
                    onChange={onChange}
                    name="usrFullNames"
                    label=""
                    placeholder="Edit user Full Name *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item fullWidth>
                  <Input
                    value={formData.usrTitle}
                    onChange={onChange}
                    name="usrTitle"
                    label=""
                    placeholder="Edit user Title *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "600px", mt: 2 }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={user.usrEmail}
                    name="usrEmail"
                    label=""
                    placeholder="Edit Email *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={user.usrMobileNumber}
                    name="usrMobileNumber"
                    label=""
                    placeholder="Edit Phone Number *"
                    size="lg"
                    className="fmw-input-joy"
                    sx={{ width: "100%", mt: 2 }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "capitalize", bgcolor: "red" }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={handleSubmit}
            color="primary"
          >
            update
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditAccountDialog;
