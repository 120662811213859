import { Box, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
	LocationOn,
	AccessTime,
	House,
	HouseSiding,
	CheckCircleOutline,
	RoomOutlined,
} from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Input, Typography } from '@mui/joy';
import PageTitle from '../../components/page_title/PageTitle';
import { getExpressionDemandData } from '../../features/expression_demand/expressionDemandSlice';
import { cashConverter, formatPhoneNumber } from '../../utils';
import FileHandler from '../../components/custom_components/FileHandler';
import "./project.css"
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const LoanPerfectionDetails = () => {
	const { loanPerfectionDetails } = useSelector((state) => state.project);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({});
	const [filename, setfilename] = useState('');

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			bspProjId: id,
			[e.target.name]: e.target.value,
		}));
	};

	function getFileUrl(data) {
		setFormData((prevState) => ({
			...prevState,
			bspPlanFile: data,
		}));
	}

	let threeValues = filename?.substring(filename?.lastIndexOf('/')).split('_');

	useEffect(() => {}, [loanPerfectionDetails]);

	console.log(loanPerfectionDetails);

	return (
		<>
			<>
				<Box sx={{ width: '100%' }}>
					<PageTitle
						currentPage='Loan perfection'
						subSubTitle={loanPerfectionDetails?.wsp}
						subTitle={'Projects'}
						urlSubTitle={'/loan-perfection'}
					/>
					<Box
						sx={{
							display: 'flex',
							gap: 1,
							alignItems: 'center',
							my: '24px',
						}}
					>
						<Typography
							className='fmw-technical-label-committed'
							sx={{ pr: 3, borderRight: '3px solid' }}
						>
							{loanPerfectionDetails?.wsp}
						</Typography>

						<RoomOutlined
							sx={{
								fontSize: '48px !important',
								color: 'var(--color-technical)',
							}}
						/>
						<Typography className='fmw-technical-label-committed'>
							{loanPerfectionDetails?.accCounty ? loanPerfectionDetails?.accCounty + " County" : "N/A"} 
						</Typography>
					</Box>

					<Typography sx={{ fontSize: '18px', mt: '48px', fontWeight: 700 }}>
						Project valuation
					</Typography>
					<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '3px' }}>
						{cashConverter(
							loanPerfectionDetails?.projCost
								? loanPerfectionDetails?.projCost
								: loanPerfectionDetails?.projEstCost,
						)}
					</Typography>
					<Divider sx={{ margin: '34px 0px' }} />
					<div className='flex items-center'>
						<div>
							<Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
								Loan amount offered
							</Typography>
							<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '3px' }}>
								KES 3,400,000
							</Typography>
						</div>

						<div className='mx-20'>
							<Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
								Interest rate
							</Typography>
							<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '3px' }}>
								16%
							</Typography>
						</div>

						<div>
							<Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
								Loan term
							</Typography>
							<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '3px' }}>
								16 (Months)
							</Typography>
						</div>
					</div>

					<Divider sx={{ margin: '34px 0px' }} />

					<Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
						Description
					</Typography>
					<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '3px' }}>
						The water utility is managed by Maji Safi Supplies, in addition to
						being responsible for all its maintenance, construction and
						operation are also for sewerage collection, treatment and disposal,
						road service and construction, town planning, and all the main
						utility services to the population. Maji Safi intends to convert the
						existing intermittent water supply into (24x7) continuous water
						supply by improving and upscaling the existing system, reducing
						present NRW, and improving the service level benchmark.
					</Typography>

					<Typography sx={{ fontSize: '18px', fontWeight: 700, mt: 4 }}>
						Impact
					</Typography>
					<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '10px' }}>
						Access to clean water
					</Typography>

					<Typography sx={{ fontSize: '16px', fontWeight: 400, mt: '5px' }}>
						Entrepreneurship
					</Typography>

					<div className='my-14 flex'>
						<d className='w-[324px] py-4 border-2 border-[#CFF0FC] text-[18px] font-semibold text-center item-center'>
							Scale of repayment
						</d>
						<button disabled className='w-[105px] bg-[#44BA41] text-white text-[20px]'>
							5
						</button>
					</div>

					<TableContainer
						component={Paper}
						elevation={0}
						sx={{ background: 'inherit' }}
					>
						<Typography sx={{ fontSize: '18px', fontWeight: 700, mb: 3 }}>
							Term sheet
						</Typography>

						<Table sx={{ minWidth: 650 }} aria-label='simple table'>
							<TableHead>
								{/* <TableRow className='table'>
									<TableCell align='left'>Item</TableCell>
									<TableCell align='left'>Details</TableCell>
								</TableRow> */}
							</TableHead>
							<TableBody
								className='basic'
								sx={{
									borderBottom: '2px solid var(--color-light-blue) !important',
								}}
							>
								<TableRow>
									<TableCell
										component='th'
										scope='row'
										sx={{ width: '33.33%' }}
									>
										Loan (KES)
									</TableCell>

									<TableCell component='th' scope='row' sx={{ width: '50%' }}>
										<Input
											type='number'
											onChange={handleChange}
											// onBlur={handleBusinessPlan}

											name='bspLoan'
											// {...register('bspLoan')}
											value={formData?.bspLoan}
											className='fmw-input-joy-mitigation'
										/>
										{/* <ImageInput /> */}
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell
										component='th'
										scope='row'
										sx={{ width: '33.33%' }}
									>
										Interest Rate (%)
									</TableCell>

									<TableCell component='th' scope='row' sx={{ width: '50%' }}>
										<Input
											type='number'
											onChange={handleChange}
											// onBlur={handleBusinessPlan}

											name='bspRateAve'
											// {...register('bspRateAve')}
											value={formData?.bspRateAve}
											className='fmw-input-joy-mitigation'
										/>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell
										component='th'
										scope='row'
										sx={{ width: '33.33%' }}
									>
										Loan Term (months)
									</TableCell>

									<TableCell component='th' scope='row' sx={{ width: '50%' }}>
										<Input
											type='number'
											onChange={handleChange}
											// onBlur={handleBusinessPlan}

											name='bspLoanTerm'
											// {...register('bsp_int_ave')}
											value={formData?.bspLoanTerm}
											className='fmw-input-joy-mitigation'
										/>
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell
										component='th'
										scope='row'
										sx={{ width: '33.33%' }}
									>
										Term sheet attachment
									</TableCell>

									<TableCell component='th' scope='row' sx={{ width: '50%' }}>
										<FileHandler
											getFileUrl={getFileUrl}
											title={threeValues?.length ? threeValues[1] : 'View file'}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>

					<Typography sx={{ fontSize: '17px', mt: '28px', fontWeight: 400, mb: 1 }}>
						Upload Disbursement note
					</Typography>

					<section>
						<form action='' method='POST' enctype='multipart/form-data'>
							<div class='container'>
								<div class='row'>
									<div class='col-md-12'>
										<div class='form-group'>
											<div class='preview-zone hidden'>
												<div class='box box-solid'>
													<div class='box-header with-border'>
														<div>
															<b>Preview</b>
														</div>
														<div class='box-tools pull-right'>
															<button
																type='button'
																class='btn btn-danger btn-xs remove-preview'
															>
																<i class='fa fa-times'></i> Reset This Form
															</button>
														</div>
													</div>
													<div class='box-body'></div>
												</div>
											</div>
											<div class='dropzone-wrapper'>
												<div class='dropzone-desc'>
													<CloudUploadOutlinedIcon sx={{fontSize: '24px'}} />
													<p className='text-[14px]'>Drag & Drop or Choose file to upload <br /> (.PDF)</p>
												</div>
												<input type='file' name='img_logo' class='dropzone' />
											</div>
										</div>
									</div>
								</div>

								<div class='row'>
									<div class='col-md-12'>
										{/* <button type='submit' class='btn btn-primary pull-right'>
											Upload
										</button> */}
									</div>
								</div>
							</div>
						</form>
					</section>

					<div className='flex justify-center mt-10 mb-20'>
						<button className='btn w-[240px] bg-[#0873B9] text-white py-5 rounded-lg '>Submit</button>
					</div>
				</Box>
			</>
		</>
	);
};

export default LoanPerfectionDetails;
