import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, } from '@mui/material';
import { Apps, AttachFile, DragIndicator, ExpandMore, KeyboardArrowDown } from '@mui/icons-material';
import ImageInput from './ImageInput';
import { Checkbox, Option, Radio, Select, Typography, selectClasses } from '@mui/joy';
import { useState } from 'react';
import { AdequacyData, PositionData, reliabilityData } from '../../PositionData';
import StatutoryNema from './StatutoryNema';
import WaterRisks from './WaterRisks';
import StatutoryWater from './StatutoryWater';
import Hydrological from './Hydrological';
import WaterAnalysis from './WaterAnalysis';
import WaterDemandTable from './WaterDemandTable';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCommittedDetails, setSpecialMinute, setWaterQuality, setWrSelectors } from '../../features/committed_project/committedSlice';
import { setWaterResources } from '../../features/committed_project/committedSlice';

export default function CommittedProject() {
  const [expanded, setExpanded] = React.useState('panel1');
  const [isUpdating, setIsUpdating] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const dispatch = useDispatch();
  const { id } = useParams();

  const { specialMinute, wrSelectors, waterQuality, waterResources,
    committedd, isErrorCommitted, isProcessingCommitted, isSuccessCommitted, messageCommitted
  } = useSelector((state) => state.committedProj)
 
 
  const [selectedValue, setSelectedValue] = useState(waterResources?.wrSpecialMin || specialMinute);

  // const {wrSpecialMin}=selectedValue

  // console.log("selectedValue333", wrSpecialMin)
  const handleRadioChange = (value) => {
    setSelectedValue(value);
    setIsUpdating(true);
  };
  const handleRadioClick = (value) => {
    if (selectedValue === value) {
      setSelectedValue(null);
    } else {
      setSelectedValue(value);
    }
    setIsUpdating(true);
  };

  const [formDatas, setFormDatas] = useState(waterResources || wrSelectors)
  const handleChangeSelect = (e) => {
    setFormDatas((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setIsUpdating(true);
  };
  // wspApplications[0].projPurpose ? wspApplications[0].projPurpose.split(",") : []
  const [waterQualityData, setWaterQualityData] = useState(waterResources?.wrWaterQuality ? waterResources?.wrWaterQuality?.split(",") : [])
 
  const handleChangeCheckbox = (e) => {
    if (findWaterQualityItem(e.target.value)) {
      const newArr = waterQualityData?.filter(item => item != e.target.value)
      setWaterQualityData(newArr)
      setIsUpdating(true);
      console.log(waterQualityData);
    } else {
      const newArr = [...waterQualityData]
      newArr?.push(e.target.value)
      setWaterQualityData(newArr)
      setIsUpdating(true);
    }
  };

  const fetchCommittedData = async () => {
    const res = await dispatch(fetchCommittedDetails({ wrProjId: id }));
    await dispatch(setWaterResources(res?.payload[0]))
  }

  const findWaterQualityItem = str => {
    const data = waterQualityData?.length && waterQualityData?.find(item => item == str)
    if (data) {
      return true
    } else {
      return false
    }
  }


  useEffect(() => {
    if (!isUpdating) {
    } else {
      dispatch(setSpecialMinute(selectedValue))
      dispatch(setWrSelectors(formDatas))
      dispatch(setWaterQuality(waterQualityData))
    }
  }, [selectedValue, isUpdating, formDatas, waterQualityData]);


  useEffect(() => {
    fetchCommittedData()
    setFormDatas(waterResources)
    setSelectedValue(waterResources)
    setWaterQualityData(waterResources?.wrWaterQuality?.split(",") ?? [])
  }, [])



  useEffect(() => {
    setFormDatas(waterResources)
    setSelectedValue(waterResources?.wrSpecialMin)
    setWaterQualityData(waterResources?.wrWaterQuality?.split(",") ?? [])
  }, [committedd, waterResources])

  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}
        sx={{ backgroundColor: expanded === 'panel1' ? '#fff' : 'inherit' }}
        className='fmw-accrdion fmw-accrd' >
        <AccordionSummary
          className='accordion'
          expandIcon={<ExpandMoreIcon sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '50%', flexShrink: 0, }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Special minute for loan application
          </Typography>

        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: '4rem', paddingTop: '0px !important', display: 'flex', flexDirection: 'column', mb: 0 }}>
          <Box sx={{ display: 'flex', gap: 20 }}>
            <Radio
              size="lg"
              label="Provided"
              className='fmw-radio-label'
              value="provided"
              checked={selectedValue == 'provided'}
              onChange={() => handleRadioChange('provided')}
              onClick={() => handleRadioClick('provided')}
              slotProps={{ input: { 'aria-label': 'provided' } }}
            />
            <Radio
              size="lg"
              label="Not Provided"
              className='fmw-radio-label'
              value="notProvided"
              checked={selectedValue == 'notProvided'}
              onChange={() => handleRadioChange('notProvided')}
              onClick={() => handleRadioClick('notProvided')}
              slotProps={{ input: { 'aria-label': 'notProvided' } }}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <ImageInput />
          </Box>

        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0}
        sx={{ backgroundColor: expanded === 'panel2' ? '#fff' : 'inherit' }}
        className='fmw-accrdion fmw-accrd' >
        <AccordionSummary
          className='accordion'
          expandIcon={<ExpandMoreIcon sx={{ color: expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '50%', flexShrink: 0, }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Water resource
          </Typography>

        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: '4rem', paddingTop: '0px !important', display: 'flex', flexDirection: 'column', mb: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
                Adequacy of water resource
              </Typography>
              <Select
                className='fmw-select'
                onChange={(e, newval) => {
                  handleChangeSelect({
                    target: {
                      value: newval,
                      name: 'wrWaterAdequacy',
                    },
                  });
                }}
                placeholder='Select position *'
                indicator={<KeyboardArrowDown />}
                size='lg'
                name='wrWaterAdequacy'
                value={formDatas?.wrWaterAdequacy ? formDatas?.wrWaterAdequacy : ''}
                sx={{
                  width: '100%',
                  [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                      transform: 'rotate(-180deg)',
                    },
                  },
                }}
              >
                {AdequacyData.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item xs={6}>

              <Typography component="h6" className="fmw-checkbox-label" sx={{ mb: '1rem' }}>
                Seasonal reliability
              </Typography>

              <Select
                className='fmw-select'
                onChange={(e, newval) => {
                  handleChangeSelect({
                    target: {
                      value: newval,
                      name: 'wrWaterReliablity',
                    },
                  });
                }}
                placeholder='Select position *'
                indicator={<KeyboardArrowDown />}
                size='lg'
                name='wrWaterReliablity'
                value={formDatas?.wrWaterReliablity ? formDatas?.wrWaterReliablity : ''}
                sx={{
                  width: '100%',
                  [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                      transform: 'rotate(-180deg)',
                    },
                  },
                }}
              >
                {reliabilityData.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>

            </Grid>


            <Grid item xs={12}>
              <Typography component="h6" className="fmw-checkbox-label-committed" sx={{ mb: '1rem' }}>
                Water Quality
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                color="primary"
                name="sufficientDemand"
                checked={findWaterQualityItem('WQ sufficient for demand')}
                value="WQ sufficient for demand"
                onChange={handleChangeCheckbox}
                label="WQ sufficient for demand"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                color="primary"
                name="treatmentWorks"
                checked={findWaterQualityItem('Treatment works provided')}
                value="Treatment works provided"
                onChange={handleChangeCheckbox}
                label="Treatment works provided"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>

            <Grid item xs={4}>
              <Checkbox
                color="primary"
                name="affectDemand"
                checked={findWaterQualityItem('WQ likely to affect demand')}
                value="WQ likely to affect demand"
                onChange={handleChangeCheckbox}
                label="WQ likely to affect demand"
                size="lg"
                variant="soft"
                className="fmw-checkbox-label"
              />
            </Grid>
            <StatutoryWater />
            <StatutoryNema />
            <WaterRisks />
            <Hydrological />
            <WaterAnalysis />
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} elevation={0}
        sx={{ backgroundColor: expanded === 'panel3' ? '#fff' : 'inherit', }}
        className='fmw-accrdion fmw-accrd' >
        <AccordionSummary
          className='accordion'
          expandIcon={<ExpandMoreIcon sx={{ color: expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)' }}
            className='fmw-accrdion-svg'
          />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography
            className='gap-5 accordion-title'
            sx={{ width: '50%', flexShrink: 0, }}>
            <DragIndicator className='fmw-accrdion-drag-svg' />
            Water demand assessment
          </Typography>

        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: '0px' }}>
          <WaterDemandTable />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}