import React, { useState } from 'react'
import { InstitutionTypeData, OperationalStaffData, TypeStaffData } from '../../Step3Data'
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Article, AttachFile, CloudUploadOutlined, Create, Delete, KeyboardArrowDown, PictureAsPdf } from '@mui/icons-material';
import {
    CircularProgress, Divider, FormControl, FormLabel, Grid,
    IconButton, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton
} from '@mui/material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Chip, Input, Typography } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupStep4Institution, deteteInstitution, fetchInstitution, onbaordInstitutionPdf, reset, saveInstitution, saveStaff } from '../../features/onboard_partner/onboardPartnerSlice';
import OperationalStaffEdit from '../onboard_partner_modals/OperationalStaffEdit';
import { fetchWspApplications } from '../../features/wsp/wspSlice';
import { useEffect } from 'react';
import { formatPath, previewPaper, subString } from '../../utils';
import CloseIcon from '@mui/icons-material/Close';
import InstitutionEdit from '../onboard_partner_modals/InstitutionEdit';

function InstitutionTable() {
    const dispatch = useDispatch();
    const { isLoadingInstitution, institutionDetailsTable, isSuccessDelete, isSuccessUpdateInstitution,step4InstitutionPdf } = useSelector((state) => state.onboardPartner)
    const { wspApplications, isLoadingWsp } = useSelector((state) => state.wsp)

   

    const {  step4Institution,  } = useSelector((state) => state.onboardPartner)
    const [fileInstitution, setFileInstitution] = useState(step4Institution)
    const { nameInstitution, dateInstitution, file_base64Institution, typeInstitution } = fileInstitution;
    const [isUpdating, setIsUpdating] = useState(false);

 
   
    
    const current = new Date();
    const year = current.getFullYear();
    const month = current.toLocaleString('default', { month: 'short' });
    const day = ("0" + current.getDate()).slice(-2);
    const _date = `${month} ${day},${year}`;


    // Institution pdf
    const [errorInstitution, setErrorInstitution] = useState('')
    const [file, setFile] = useState(undefined)

    const handleInstitution = (e) => {
        const _imgArr = [];
        const _file = e.target.files[0];
        if (!_file) return;

        const MAX_FILE_SIZE = 1e+7  //10mb 
        const allowedExtensions = /(\.pdf)$/i;
        if (!allowedExtensions.exec(_file.name) || _file.size > MAX_FILE_SIZE) {
            setErrorInstitution('Please upload a PDF file not more than 10mb ');
            setFileInstitution('');
            return;
        }
        setFile(_file);
        setErrorInstitution('')
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

            // Create an object containing image information.
            let imageObj = {
                nameInstitution: _file.name,
                dateInstitution: _date,
                typeInstitution: _file.type,
                file_base64Institution: base64String.toString()
            };

            setFileInstitution(imageObj)
            setIsUpdating(true)
        };
        reader.readAsDataURL(_file);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(onbaordInstitutionPdf(formData));
    }

    const removeInstitution = () => {
        setFileInstitution({
            nameInstitution: '',
            dateInstitution: '',
            typeInstitution: '',
            file_base64Institution: undefined,
        });
        setIsUpdating(true);
        dispatch(cleanupStep4Institution());
    }


    
    const [institutionData, setInstitutionData] = useState({
        instNo: '',
        instName: null,
    });
    const { instNo, instName } = institutionData;

    const saveInstitutionn = (e) => {
        if (!nameInstitution || !instNo || !instName) {
            toast.warning("Please fill all Institution Details")
        } else {
            dispatch(saveInstitution({
                instProjId: wspApplications[0].projId,
                instAttachment: step4InstitutionPdf.length > 0 ? step4InstitutionPdf : null,
                instNo: instNo,
                instName: instName,
            }));
            setInstitutionData({ instNo: '', instName: null });
            setFileInstitution({ nameInstitution: '' });
        }
    }
    const handleChangeInstitution = (e) => {
        e.target.value < 0 ? (e.target.value = 0) :
            setInstitutionData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
            }))
        setIsUpdating(true);
    };
    const handleDeleteInstitution = (id) => {
        dispatch(deteteInstitution({
            instId: id
        }));
        setIsUpdating(false);
    }
    const [institution, setInstitutionn] = useState()
    const [openInstitutionn, setOpenInstitutionn] = React.useState(false);

    const handleClickOpenInstitutionn = () => {
        setOpenInstitutionn(true);
    };
    const handleCloseInstitutionn = () => {
        setOpenInstitutionn(false);
    };
    useEffect(() => {
        if (!isUpdating && isSuccessDelete) {
            dispatch(fetchInstitution({
                projId: wspApplications[0].projId
            }));
        } else if (!isUpdating || isSuccessUpdateInstitution) {
            setTimeout(() => {
                dispatch(fetchInstitution({
                    projId: wspApplications[0].projId
                }));
            }, 800)
            // dispatch(fetchWspApplications())
        } else if (!isUpdating) {
            setTimeout(() => {
                dispatch(fetchInstitution({
                    projId: wspApplications[0].projId
                }));
            }, 800)

            dispatch(fetchWspApplications())
        }
        else {
        }
        return () => {
            dispatch(reset())
        }
    }, [dispatch, isUpdating, isSuccessDelete, isSuccessUpdateInstitution]);

    return (
        <>
            <Grid item xs={12}>
                <Typography
            component="h6"
            className="fmw-onboard-partner-label"
            style={{ marginBottom: "0.5rem" }}
          >
            Institution Details
          </Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Type of institution
                </Typography>
                <Select
                    className="fmw-select"
                    onChange={(e, newval) => {
                        handleChangeInstitution({
                            target: {
                                value: newval,
                                name: "instName"
                            }
                        })
                    }}
                    placeholder="Select contract*"
                    indicator={<KeyboardArrowDown />}
                    size='lg'
                    name="instName"
                    value={instName}

                    sx={{
                        width: '100%',
                        [`& .${selectClasses.indicator}`]: {
                            transition: '0.2s',
                            [`&.${selectClasses.expanded}`]: {
                                transform: 'rotate(-180deg)',
                            },
                        },
                    }}
                >
                    {InstitutionTypeData.map((option) => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>

            </Grid>

            <Grid item xs={4}>
                <Typography component="p" className="fmw-onboard-partner-sub-label" sx={{ mb: '.5rem' }}>
                    Institution number
                </Typography>
                <Input
                    label=""
                    type="number"
                    value={instNo}
                    name="instNo"
                    onChange={handleChangeInstitution}
                    size="lg"
                    className="fmw-input-joy"
                />
            </Grid>

            <Grid item xs={4}>
					<Typography
						component='h6'
						className='fmw-checkbox-label'
						sx={{ mb: '.5rem' }}
					>
						Attach institution attachment<span className='star'>*</span>
					</Typography>
					{!nameInstitution ? (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									border: 'none',
									// border: '1px solid var(--color-primary-light)',
									width: '337px',
									height: '46px',
								}}
							>
								<label
									className=''
									style={{ cursor: 'pointer', width: '100%', height: '100%' }}
								>
									<div
										className='mb-2'
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
											height: '100%',
											backgroundColor: '#F7F7F7',
										}}
									>
										<div
											style={{
												backgroundColor: '#A0A0A0',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												marginRight: '20px',
												width: '52px',
											}}
										>
											<CloudUploadOutlined
												sx={{
													display: 'flex',
													fontSize: '27px',
													justifyContent: 'center',
													alignItems: 'center',
													mx: '12px',
													color: '#ffff',
													backgroundColor: '#A0A0A0 !important',
												}}
											/>
										</div>
										{errorInstitution ? (
											<Typography
												component='h6'
												className='upload-sub-label-error'
											>
												{errorInstitution}
											</Typography>
										) : (
											<Typography
												variant='caption'
												className='upload-sub-label'
											>
												Click here to upload
											</Typography>
										)}
									</div>
									<input
										type='file'
										name='file'
										value={nameInstitution}
										accept='.pdf'
										webkitdirectory
										onChange={handleInstitution}
										style={{ width: '0px', height: '0px' }}
									/>
								</label>
							</div>
						</>
					) : (
						<>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									justifyContent: 'space-between',
									border: '1px solid var(--color-primary-light)',
									width: '337px',
									height: '46px',
								}}
							>
								<div
									style={{
										backgroundColor: 'inherit',
										height: '100%',
										display: 'flex',
										alignItems: 'center',
										marginRight: '5px',
										width: '52px',
									}}
								>
									<span style={{ margin: '0px 6px' }}>
										{typeInstitution === 'application/pdf' ? (
											<PictureAsPdf
												sx={{
													fontSize: '40px',
													color: '#f44336',
												}}
											/>
										) : typeInstitution === 'image/png' ||
											'image/jpeg' ||
											'image/gif' ? (
											<Image
												sx={{
													fontSize: '40px',
													color: '#263238',
												}}
											/>
										) : (
											<Article
												sx={{
													fontSize: '40px',
													color: '#1976d2',
												}}
											/>
										)}
									</span>
									<Divider
										orientation='vertical'
										flexItem
										sx={{
											backgroundColor:
												'var(--color-primary-light) !important',
										}}
									/>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<Typography
										component='h6'
										className='fmw-upload-typography'
									>
										{subString(nameInstitution, 25)}
									</Typography>

									<Typography variant='caption' className='upload-sub-label'>
										uploaded on {_date}
									</Typography>
								</div>

								<div>
									<IconButton
										aria-label='close'
										onClick={removeInstitution}
										sx={{
											color: (theme) => theme.palette.grey[500],
											mr: '6px',
										}}
									>
										<CloseIcon />
									</IconButton>
								</div>
							</div>
						</>
					)}
				</Grid>



            <Grid item md={6} xs={12} >
            </Grid>
            <Grid item md={6} xs={12} sx={{ mt: 1 }} >
                <FormControl fullWidth variant={"standard"}>
                    <FormLabel htmlFor="my-input" variant={"subtitle"}>NOTE: You can add as many institution types as possible*</FormLabel>
                    {!isLoadingInstitution ? (
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{ textTransform: 'capitalize', backgroundColor: 'var(--bg-primary) !important' }}
                            onClick={saveInstitutionn}
                        >
                            {
                                institutionDetailsTable?.length ? ' Add Institution Type' : 'Save Institution Type'
                            }

                        </Button>
                    ) : (
                        <Button disabled variant="contained" sx={{ textTransform: 'none' }}>
                            <CircularProgress size={17} color="inherit" />&nbsp;Adding...
                        </Button>
                    )}
                </FormControl>
            </Grid>

            {isLoadingInstitution ? (
                <>
                    <Skeleton width={1450} height={30} />
                    <Skeleton width={1450} height={30} />
                </>
            ) : (
                <>
                    {institutionDetailsTable?.length ? (<>
                        <Divider sx={{ my: 2 }} />
                        <TableContainer >
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow  >
                                        <TableCell>Type of institution</TableCell>
                                        <TableCell align="left" >Number</TableCell>
                                        <TableCell align="left" >File</TableCell>
                                        <TableCell align="left">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {institutionDetailsTable.length && institutionDetailsTable.map((inst) => (
                                        <TableRow
                                            key={inst.instId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {inst.instName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {inst.instNo}
                                            </TableCell>
                                            <TableCell align='left'>
																<Chip
																	variant='soft'
																	className='file-chip-primary'
																	color='primary'
																	size='sm'
																	startDecorator={<AttachFile size='sm' />}
																	onClick={() =>
																		previewPaper(inst.instAttachment)
																	}
																>
																	{formatPath(inst.instAttachment)}
																</Chip>
															</TableCell>

                                            <TableCell align="left">
                                                <IconButton aria-label="delete" size="small" sx={{ mr: .5 }} onClick={() => setInstitutionn(inst)}>
                                                    <Create fontSize="small" onClick={() => handleClickOpenInstitutionn()} />
                                                </IconButton>

                                                <IconButton aria-label="delete" size="small">
                                                    <Delete fontSize="small" onClick={() => handleDeleteInstitution(inst.instId)} />
                                                </IconButton>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    ) : (<></>)}

                </>
            )}
            <InstitutionEdit open={openInstitutionn} handleClickOpen={handleClickOpenInstitutionn} handleClose={handleCloseInstitutionn} institutionEdited={institution} />
        </>
    )
}

export default InstitutionTable
