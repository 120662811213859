import { Radio, Typography } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setWaterCompliance } from '../../features/committed_project/committedSlice';
import ImageInput from './ImageInput';

function StatutoryWater() {
    const { waterCompliance, committedd,waterResources,
    } = useSelector((state) => state.committedProj)
    const dispatch = useDispatch()
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedValueStatutory, setSelectedValueStatutory] = useState(waterResources?.wrWraCompliance || waterCompliance);
    
    const handleRadioChangeStatutory = (value) => {
        setSelectedValueStatutory(value);
        setIsUpdating(true);
    };
    const handleRadioClickStatutory = (value) => {
        if (selectedValueStatutory === value) {
            setSelectedValueStatutory(null);
        } else {
            setSelectedValueStatutory(value);
        }
        setIsUpdating(true);
    };



    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(setWaterCompliance(selectedValueStatutory))
        }
    }, [dispatch, isUpdating, selectedValueStatutory]);

    useEffect(() => {
        setSelectedValueStatutory(waterResources)
      }, [committedd])
    
     
      useEffect(() => {
        setSelectedValueStatutory(waterResources?.wrWraCompliance)
      }, [committedd, waterResources])
    return (
        <>
            <Grid item xs={12} sx={{ mt: '1rem' }}>
                <Typography component="h6" className="fmw-checkbox-label-committed" sx={{ mb: '.8rem' }}>
                    Statutory compliance - WATER
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Radio
                    size="lg"
                    label="Full compliance with WRA"
                    className='fmw-radio-label'
                    value="Full compliance with WRA"
                    checked={selectedValueStatutory === 'Full compliance with WRA'}
                    onChange={() => handleRadioChangeStatutory('Full compliance with WRA')}
                    onClick={() => handleRadioClickStatutory('Full compliance with WRA')}
                    slotProps={{ input: { 'aria-label': 'Full compliance with WRA' } }}
                />
            </Grid>
            <Grid item xs={4}>
                <Radio
                    size="lg"
                    label="Compliance with WRA in process"
                    className='fmw-radio-label'
                    value="Compliance with WRA in process"
                    checked={selectedValueStatutory === 'Compliance with WRA in process'}
                    onChange={() => handleRadioChangeStatutory('Compliance with WRA in process')}
                    onClick={() => handleRadioClickStatutory('Compliance with WRA in process')}
                    slotProps={{ input: { 'aria-label': 'Compliance with WRA in process' } }}
                />
            </Grid>

            <Grid item xs={4}>
                <Radio
                    size="lg"
                    label="WQ likely to affect demand"
                    className='fmw-radio-label'
                    value="WQ likely to affect demand"
                    checked={selectedValueStatutory === 'WQ likely to affect demand'}
                    onChange={() => handleRadioChangeStatutory('WQ likely to affect demand')}
                    onClick={() => handleRadioClickStatutory('WQ likely to affect demand')}
                    slotProps={{ input: { 'aria-label': 'WQ likely to affect demand' } }}
                />
            </Grid>
            <Grid item xs={4}>
                <Box sx={{ mt: '13px' }}>
                    <ImageInput title="WRA authorization"/>
                </Box>
            </Grid>

            <Grid item xs={4}>
                <Box sx={{ mt: '13px' }}>
                    <ImageInput title="WRA permit"/>
                </Box>
            </Grid>
        </>
    )
}

export default StatutoryWater
